import JSCookie from 'js-cookie'

const sessionPrefix = process.env.VUE_APP_SESSION_PREFIX
const tokenCookieName = `${sessionPrefix}:token`
const langCookieName = `${sessionPrefix}:lang`

export const TokenSession = {
    set: token => JSCookie.set(tokenCookieName, token, { secure: true }),
    unset: () => JSCookie.remove(tokenCookieName),
    get: () => {
        const token = JSCookie.get(tokenCookieName)
        if (!token) return null
        return typeof token === 'object' ? token : JSON.parse(token)
    },
    formatToken: token => ({
        type: token.type || 'Bearer',
        token: token.token,
        refreshToken: token.refreshToken,
    }),
}

export const LangSession = {
    set: token => JSCookie.set(langCookieName, token, { secure: true }),
    unset: () => JSCookie.remove(langCookieName),
    get: () => JSCookie.get(langCookieName),
}

export default {
    Token: TokenSession,
    Lang: LangSession,
}
