<template>
    <div class="Organizations">
        <div class="Actions flex row-stretch-center mb-1">
            <WButton
                iconLeft="plus-circle"
                @click="handleNewItem"
            >
                {{ $t('organizations.new') }}
            </WButton>
            <WButtonGroup
                :value="groupOwnerType"
                :options="groupOwnerTypes"
                @input="handleChageGroupOwnerType"
            />
        </div>
        <div class="Container">
            <OrganizationsLists
                ref="table"
                :rows="organizations"
                :total="total"
                :search="header.search"
                :loading="isFetching"
                @onNewItem="handleNewItem"
                @onDelete="handleDeleteItem"
                @onUpdate="handleUpdateItem"
                @onClick="handleClickItem"
                @onFetchData="fetchData"
            />
        </div>
        <ModalRequest
            ref="delPrDialog"
            :title="$t('organizations.delete')"
            :text="$t('organizations.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @onAccept="deleteOrganization"
        />
        <ModalNameDesc
            ref="editPrDialog"
            withoutDesc
            :title="$t('organizations.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            @onSubmit="updateOrganization"
        />
        <ModalNameDesc
            ref="newPrDialog"
            withoutDesc
            :title="$t('organizations.new')"
            :action="$t('actions.create')"
            :loading="isLoading"
            @onSubmit="createOrganization"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { ModalRequest, ModalNameDesc } from '@/components'
import { dialogMixin } from '@/mixins'
import { OrganizationsLists } from './sections'

export default {
    name: 'Organizations',
    components: {
        ModalRequest,
        ModalNameDesc,
        OrganizationsLists,
    },
    mixins: [dialogMixin],
    props: {
        filters: VueTypes.shape({
            search: VueTypes.string.def(''),
            archived: VueTypes.bool.def(false),
        }),
    },
    data () {
        return {
            organizations: undefined,
            total: undefined,
            isFetching: false,
            isLoading: false,
            showAllOrganizations: true,
            groupOwnerTypes: [
                { label: this.$t('organizations.createdByMe'), value: 'createdByMe' },
                { label: this.$t('organizations.sharedWithMe'), value: 'sharedWithMe' },
            ],
            groupOwnerType: 'createdByMe',
            header: {
                search: '',
                archived: false,
            },
        }
    },
    computed: {
        params () {
            const pagination = this.$refs.table.getPagination()
            return {
                ...this.filters,
                ...pagination,
                shared: this.groupOwnerType !== 'createdByMe',
                onlyActive: this.onlyActive,
            }
        },
    },
    watch: {
        filters (value) {
            if (value) this.fetchData()
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        handleChageGroupOwnerType ({ value }) {
            this.groupOwnerType = value
            this.fetchData()
        },
        fetchData () {
            const formattedParams = this.formatParams(this.params)
            this.isFetching = true
            apiRequest(Api().organizations.list(formattedParams))
                .then(({ data, total }) => {
                    this.organizations = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        formatParams (params) {
            const {
                onlyActive, archived, search, page, rowsPerPage, shared,
            } = params
            return {
                shared,
                onlyActive,
                search: search || null,
                includeArchived: !!archived,
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
            }
        },
        async createOrganization ({ name }) {
            this.isLoading = true
            apiRequest(Api().organizations.create({ name }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.created.text'))
                    this.fetchData()
                    this.$refs.newPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async updateOrganization ({ uuid, name, description }) {
            this.isLoading = true
            apiRequest(Api().organizations.update({ organizationUid: uuid, params: { name } }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.updated.text'))
                    this.fetchData()
                    this.$refs.editPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async deleteOrganization () {
            const { uuid } = this.selected
            this.isLoading = true
            apiRequest(Api().organizations.delete({ organizationUid: uuid }))
                .then(() => {
                    notifySuccess(this.$t('organizations.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.delPrDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleClickItem (organization) {
            const { uuid } = organization
            this.$router.push({ name: 'Organization', params: { organizationId: uuid } })
        },
    },
}
</script>
