import AuthService from '@/services/auth'

export default ({ router }) => {
    router.beforeEach((to, from, next) => {
        if (!AuthService.user()) {
            const loginUrl = AuthService.instance().createLoginUrl()
            return window.location.replace(loginUrl)
        }

        return next()
    })
}
