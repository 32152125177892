import axios from 'axios'
import { noop } from 'lodash'
import { TokenSession } from '@/services/session'
import AuthService from '@/services/auth'
import { STATUS, logger } from './utils'

const History = (() => {
    let value = []
    const same = (request, config) => request.url === config.url && request.method === config.method
    const push = config => value.push(config)
    const remove = config => (value = value.filter(request => !same(request, config)))
    const exist = config => !!value.find(request => same(request, config))

    return {
        get: () => value,
        push,
        remove,
        exist,
    }
})()

const requestSuccessHandler = (config) => {
    const { params } = config
    const { length } = History.get()

    if (params?.polling && length) {
        return new Promise(noop)
    }

    History.push(config)
    return config
}

const requestErrorHandler = error => Promise.reject(error)

const responseSuccessHandler = ({ response = {}, actions = {} }) => {
    const {
        request = {}, config, data, status,
    } = response
    History.remove(config)
    logger({
        origin: 'API - RESPONSE',
        endpoint: request.responseURL,
        response: JSON.stringify(data),
        status: status || request.status,
    })
    return response
}

const responseErrorHandler = ({
    error = {}, setAuthorization, actions = {}, refreshTokenUrl = '/auth/token',
}) => {
    const normalizeError = typeof error === 'object' ? error : {}
    const { response = {}, request = {}, config: originalRequest } = normalizeError
    const { status } = response
    const { responseURL: endpoint } = request
    History.remove(originalRequest)

    logger({ origin: `API - ERROR ${status}`, endpoint, status })

    if ((!error.response || error.response.status === STATUS.UNAUTHORIZED) && !originalRequest.retry) {
        originalRequest.retry = true
        const oldToken = TokenSession.get()
        if (oldToken) {
            return AuthService.refreshToken()
                .then(({ ok, data }) => {
                    if (ok && data.token) {
                        const tokenFormatted = TokenSession.formatToken(data)
                        originalRequest.headers.Authorization = `${tokenFormatted.type} ${tokenFormatted.token}`
                        return axios(originalRequest)
                    }
                    return false
                })
                .catch(errorRefresh => errorRefresh)
        }
    }

    return error
}

export default {
    request: {
        success: requestSuccessHandler,
        error: requestErrorHandler,
    },
    response: {
        success: responseSuccessHandler,
        error: responseErrorHandler,
    },
}
