<template>
    <div
        id="error-container"
        class="APIError flex flex-center col-center"
    >
        <WIcon
            icon="alert-circle"
            color="red"
            :size="48"
            class="mb-1"
        />
        <WText
            tag="h3"
            :size="36"
            class="mb-3"
        >
            {{ $t('error.notfound.text') }}
        </WText>
        <WButton
            class="pl-1 pr-1"
            @click="handleClickRetry"
        >
            {{ $t('actions.retry') }}
        </WButton>
    </div>
</template>
<script>
import { Api, apiRequest, notifyError } from '@/api'

export default {
    name: 'APIError',
    methods: {
        handleClickRetry () {
            apiRequest(Api().organizations.list())
                .then(() => this.$router.push({ name: 'Main' }))
                .catch(notifyError)
        },
    },
}
</script>
