var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "WSpinner", class: _vm.spinnerClass }, [
        _c("div", { staticClass: "dot1" }),
        _c("div", { staticClass: "dot2" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }