var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Events" }, [
    _c("div", { staticClass: "Container flex row-stretch" }, [
      _c(
        "div",
        { staticClass: "Screen flex-1" },
        [
          _c(
            "div",
            { staticClass: "Header mb-1" },
            [
              _c(
                "div",
                { staticClass: "Summary" },
                [
                  _c("EventsBox", {
                    attrs: {
                      title: _vm.$t("events.currentUse"),
                      color: "primary",
                      value: _vm.currrent
                    }
                  }),
                  _c("EventsBox", {
                    staticClass: "mt-1",
                    attrs: { title: _vm.$t("events.maxEvents"), value: _vm.max }
                  })
                ],
                1
              ),
              _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _vm.chart
                  ? _c(
                      "div",
                      { staticClass: "Card" },
                      [
                        _c(
                          "WText",
                          { staticClass: "mb-1", attrs: { size: 18 } },
                          [_vm._v(" " + _vm._s(_vm.$t("events.events")) + " ")]
                        ),
                        _c(
                          "EventsChart",
                          _vm._b(
                            {
                              staticClass: "EventsChart",
                              attrs: { height: 210 }
                            },
                            "EventsChart",
                            _vm.chart,
                            false
                          )
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showHistoric,
                    expression: "showHistoric"
                  }
                ],
                staticClass: "Card"
              },
              [
                _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                  _vm._v(" " + _vm._s(_vm.$t("events.historic")) + " ")
                ]),
                _c("EventsHistoric", {
                  on: { onReady: _vm.handleHistoricReady }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }