export default api => ({
    checkModel: ({
        datasetId,
        bpmnId,
        params,
    }) => api.post(`/datasets/conformance/tool/${datasetId}/bpmn/${bpmnId}`, params),
    checkJsonModel: ({ datasetUID, params }) => api.post(`/datasets/conformance/tool/${datasetUID}/json/`, params),
    transitions: ({
        datasetId,
        bpmnId,
        params,
    }) => api.post(`/datasets/conformance/violation/${datasetId}/transitions/bpmn/${bpmnId}`, params),
    activities: ({
        datasetId,
        bpmnId,
        params,
    }) => api.post(`/datasets/conformance/violation/${datasetId}/activity/bpmn/${bpmnId}`, params),
    rootCause: ({
        datasetId,
        bpmnId,
        params,
    }) => api.post(`/datasets/conformance/root/${datasetId}/bpmn/${bpmnId}`, params),
})
