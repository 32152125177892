<template>
    <div
        v-if="isOpen"
        class="FilterModal"
    >
        <WIcon
            v-if="filters && selectedFilter.label"
            clickable
            icon="arrow-left"
            color="primary"
            class="BackArrow"
            @click="handleBack"
        />
        <WIcon
            v-if="filters && !selectedFilter.label && !mountedFilter"
            clickable
            icon="arrow-left"
            color="primary"
            class="BackArrow"
            @click="closeModal"
        />
        <WText
            class="pt-3"
            align="center"
            weight="bold"
        >
            {{ $t('visualization.filters.title') }}
        </WText>
        <div
            v-if="filters && !selectedFilter.label && !mountedFilter"
            ref="FiltersContainer"
            class="FiltersContainer"
        >
            <FilterModalSection
                :options="filters.ACTIVITIES"
                :title="$t('visualization.filters.activities.title')"
                @onClickFilter="handleClickFilter"
            />
            <FilterModalSection
                :options="filters.ARCS"
                :title="$t('visualization.filters.arcs.title')"
                @onClickFilter="handleClickFilter"
            />
            <FilterModalSection
                :options="filters.ATTRIBUTES"
                :title="$t('visualization.filters.attributes.title')"
                @onClickFilter="handleClickFilter"
            />
            <FilterModalSection
                :options="filters.DATETIME"
                :title="$t('visualization.filters.date.title')"
                @onClickFilter="handleClickFilter"
            />
            <FilterModalSection
                :options="filters.DURATION"
                :title="$t('visualization.filters.duration.title')"
                @onClickFilter="handleClickFilter"
            />
        </div>
        <transition
            @enter="enterElement"
            @leave="leaveElement"
            @after-leave="afterLeaveElement"
        >
            <AttributesSection
                v-if="filters && selectedFilter.id && selectedFilter.id.includes('ATTRIBUTE')"
                :attributes="attributes"
                :categories="activities"
                :attributeValues="attrValues"
                :selectedFilter="selectedFilter"
                @onSaveAttributes="handleSaveAttributes"
            />
            <div
                v-else-if="filters && selectedFilter.label"
                class="pl-1 pr-1 mt-3"
            >
                <WCard
                    class="FilterSection"
                    bordered
                >
                    <div class="DateSection flex col-top-left pa-1">
                        <WText
                            class="pb-1"
                            align="center"
                            weight="bold"
                        >
                            {{ selectedFilter.label }}
                        </WText>
                        <div class="DataContainer">
                            <div class="selectStyle">
                                <QSelect
                                    v-if="filterKey === 'TRACE_STARTS_WITH_ACTIVITY' ||
                                        filterKey === 'TRACE_ENDS_WITH_ACTIVITY'
                                        || filterKey === FILTERS_TYPES.ACTIVITY_DURATION"
                                    v-model="filterValue.category"
                                    dense
                                    useInput
                                    outlined
                                    :multiple="isMultiple"
                                    behavior="menu"
                                    :label="$t('visualization.filters.activities.label')"
                                    :rules="[value => validators.required(value)]"
                                    :options="activityOptions"
                                    @filter="activityFilterFn"
                                    @input="canSaveActivityArcFilter"
                                />
                                <QSelect
                                    v-if="'TRACE_CONTAINS_ACTIVITIES' === filterKey ||
                                        'TRACE_NOT_CONTAINS_ACTIVITIES' ===filterKey"
                                    v-model="filterValue.category"
                                    class="selectStyle"
                                    dense
                                    useInput
                                    outlined
                                    hideSelected
                                    :options="activityOptions"
                                    behavior="menu"
                                    :multiple="isMultiple"
                                    :label="$t('visualization.filters.activities.label')"
                                    :rules="[value => validators.required(value)]"
                                    @filter="activityFilterFn"
                                    @input="canSaveActivityArcFilter"
                                />
                                <QSelect
                                    v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                    v-model="filterValue.activitySource"
                                    dense
                                    useInput
                                    outlined
                                    behavior="menu"
                                    :label="$t('visualization.filters.activities.label')"
                                    :rules="[value => validators.required(value)]"
                                    :options="activityOptions"
                                    @filter="activityFilterFn"
                                />
                                <QSelect
                                    v-if="FILTERS_TYPES.ACTIVITIES_DURATION === filterKey"
                                    v-model="filterValue.activityTarget"
                                    dense
                                    useInput
                                    outlined
                                    behavior="menu"
                                    :label="$t('visualization.filters.activities.label')"
                                    :rules="[value => validators.required(value)]"
                                    :options="activityOptions"
                                    @filter="activityFilterFn"
                                />
                            </div>
                            <div
                                v-if="( filterKey === 'TRACE_CONTAINS_ACTIVITIES' ||
                                    filterKey === 'TRACE_NOT_CONTAINS_ACTIVITIES') && filterValue.category"
                            >
                                <div
                                    v-for="(selected, index) in filterValue.category"
                                    :key="index"
                                >
                                    <q-chip
                                        :label="selected"
                                        removable
                                        color="primary"
                                        textColor="white"
                                        @remove="deleteIndexValue(index, filterValue.category)"
                                    >
                                        {{ selected.label }}
                                    </q-chip>
                                </div>
                            </div>
                        </div>
                        <div />
                        <div
                            class="flex"
                        >
                            <QSelect
                                v-if="ARC_FILTERS.includes(filterKey) || filterKey === FILTERS_TYPES.ARC_DURATION"
                                v-model="filterValue.arc"
                                dense
                                useInput
                                outlined
                                hideSelected
                                :multiple="isMultiple"
                                behavior="menu"
                                :label="$t('visualization.filters.arcs.label')"
                                :rules="[value => validators.required(value)]"
                                :options="arcOptions"
                                @filter="arcFilterFn"
                                @input="canSaveActivityArcFilter"
                            />
                            <div
                                v-if="(filterKey === 'TRACE_CONTAINS_ARCS' ||
                                    filterKey === 'TRACE_NOT_CONTAINS_ARCS') && filterValue.arc"
                            >
                                <div
                                    v-for="(selected, index) in filterValue.arc"
                                    :key="index"
                                >
                                    <q-chip
                                        :label="selected"
                                        removable
                                        color="primary"
                                        textColor="white"
                                        @remove="deleteIndexValue(index, filterValue.arc)"
                                    >
                                        {{ selected.label }}
                                    </q-chip>
                                </div>
                            </div>
                            <div
                                v-if="DATE_FILTERS.includes(filterKey)"
                                class="flex row-left-center flex-nowrap"
                            >
                                <QDate
                                    v-model="filterValue.date"
                                    mask="YYYY-MM-DD HH:mm:ss"
                                    @input="canSaveTraceDateFilter"
                                />
                                <QTime
                                    v-model="filterValue.date"
                                    class="ml-1"
                                    withSeconds
                                    mask="YYYY-MM-DD HH:mm:ss"
                                />
                            </div>
                            <QSelect
                                v-if="DURATION_FILTERS.some((f) => f.id === filterKey)"
                                ref="constraints"
                                v-model="filterValue.constraints"
                                dense
                                class="Field flex-1 mb-1"
                                outlined
                                behavior="menu"
                                :label="$t('visualization.filters.constraints.label')"
                                :options="ATTRIBUTTE_TYPES.QUANTITY_ATTRIBUTE"
                            />
                            <QInput
                                v-if="DURATION_FILTERS.some((f) => f.id === filterKey)"
                                ref="modelLabel"
                                v-model="filterValue.duration"
                                :label="$t('visualization.filters.duration.label')"
                                stackLabel
                                mask="####D ##h:##m:##s"
                                outlined
                                fillMask
                                hint="Mask: 0000D 00h:00m:00s"
                            />
                        </div>
                    </div>
                    <div class="CardFooter pr-1">
                        <WButton
                            @click="handleSaveFilter"
                        >
                            {{ $t('visualization.filters.add') }}
                        </WButton>
                    </div>
                </WCard>
            </div>
        </transition>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import groupBy from 'lodash/groupBy'
import anime from 'animejs'
import { Api, apiRequest, notifyError } from '@/api'
import { validatorsMixin, filtersStorageMixin } from '@/mixins'
import FilterModalSection from './components/FilterModalSection.vue'
import AttributesSection from './components/AttributesSection.vue'
import {
    ACTIVITY_FILTERS, DATE_FILTERS, FILTERS_TYPES, ATTRIBUTTE_TYPES, ARC_FILTERS,
} from './filtersEntity'

export default {
    name: 'FilterModal',
    components: { FilterModalSection, AttributesSection },
    mixins: [validatorsMixin, filtersStorageMixin],
    props: {
        isOpen: VueTypes.bool.def(false),
        attributes: VueTypes.array,
        processId: VueTypes.oneOfType([String, Number]),
    },
    data () {
        return {
            canSaveFilter: false,
            filters: undefined,
            selectedFilter: {},
            filterKey: '',
            mountedFilter: false,
            filterValue: {
                category: null,
                date: '',
                duration: '0000D 00h:00m:00s',
                constraints: null,
                arc: null,
                variant: null,
                loopName: null,
                activitySource: null,
                activityTarget: null,
            },
            activities: undefined,
            activityOptions: [],
            activityTargetOptions: [],
            arcs: undefined,
            arcOptions: [],
            attrValues: undefined,
            timeout: null,
        }
    },
    computed: {
        isMultiple () {
            const { selectedFilter } = this
            return !([
                FILTERS_TYPES.TRACE_ENDS_WITH_ACTIVITY,
                FILTERS_TYPES.TRACE_STARTS_WITH_ACTIVITY,
                FILTERS_TYPES.ACTIVITY_DURATION,
                FILTERS_TYPES.ARC_DURATION,
                FILTERS_TYPES.ACTIVITIES_DURATION,
            ].includes(selectedFilter.id))
        },
    },
    watch: {
        isOpen (open) {
            if (open) {
                this.getFilters()
                this.getActivities()
                this.getArcs()
                this.mountedFilter = false
            }
        },
        visualizationFilters () {
            this.getFilters()
        },
    },
    beforeMount () {
        this.ATTRIBUTTE_TYPES = ATTRIBUTTE_TYPES
        this.ACTIVITY_FILTERS = ACTIVITY_FILTERS
        this.ARC_FILTERS = ARC_FILTERS
        this.FILTERS_TYPES = FILTERS_TYPES
        this.DURATION_FILTERS = [
            {
                label: this.$t('visualization.filters.duration.filters.trace'),
                type: 'DURATION',
                id: 'TRACE_DURATION',
                options: { GREATER_THAN: 'TRACE_DURATION_GREATER_THAN', LESSER_THAN: 'TRACE_DURATION_LESS_THAN' },
            },
            {
                label: this.$t('visualization.filters.duration.filters.activity'),
                type: 'DURATION',
                id: 'ACTIVITY_DURATION',
                options: { GREATER_THAN: 'ACTIVITY_DURATION_GREATER_THAN', LESSER_THAN: 'ACTIVITY_DURATION_LESS_THAN' },
            },
            {
                label: this.$t('visualization.filters.duration.filters.activities'),
                type: 'DURATION',
                id: 'ACTIVITIES_DURATION',
                options: { GREATER_THAN: 'ACTIVITIES_DURATION_GREATER_THAN', LESSER_THAN: 'ACTIVITIES_DURATION_LESS_THAN' },
            },
            {
                label: this.$t('visualization.filters.duration.filters.arc'),
                type: 'DURATION',
                id: 'ARC_DURATION',
                options: { GREATER_THAN: 'ARC_DURATION_GREATER_THAN', LESSER_THAN: 'ARC_DURATION_LESSER_THAN' },
            },
        ]
        this.DATE_FILTERS = DATE_FILTERS
        this.ATTR_FILTERS = [
            {
                label: this.$t('visualization.filters.attributes.filters.numericalField'), type: 'ATTRIBUTE', id: 'NUMERIC_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.ctgField'), type: 'ATTRIBUTE', id: 'CATEGORICAL_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.booleanField'), type: 'ATTRIBUTE', id: 'BOOLEAN_ATTRIBUTE',
            },
            {
                label: this.$t('visualization.filters.attributes.filters.dateTimeField'), type: 'ATTRIBUTE', id: 'DATETIME_ATTRIBUTE',
            },
        ]
    },
    mounted () {
        this.getFilters()
        this.getActivities()
        this.getArcs()
        this.getAttributeValues()
        this.listenFilterByVariant()
        this.listenFilterByLoop()
    },
    methods: {
        getFilters () {
            apiRequest(Api().visualizations.filters())
                .then((res) => {
                    const filtersIds = (this.visualizationFilters || []).map(({ id }) => id)
                    const sanitizedFilters = this.formatFilters(res)
                    const formattedFilters = sanitizedFilters.map(filter => (filtersIds.includes(filter.id) && !filter.repeatable ? { ...filter, readOnly: true } : filter))

                    const filtersByType = groupBy(formattedFilters, 'kind')
                    this.filters = { ...filtersByType, ATTRIBUTES: this.ATTR_FILTERS, DURATION: this.DURATION_FILTERS }
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        getActivities () {
            const { processId } = this
            const params = { filters: [] }
            apiRequest(Api().visualizations.activities({ processId, params }))
                .then((res) => {
                    const activities = [...res.map(activity => activity.name)]
                    this.activities = activities
                    this.activityOptions = activities
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        listenFilterByVariant () {
            this.$root.$on('filterByVariant', (variant) => {
                this.filterValue.variant = variant
                this.filterKey = 'TRACE_IS_IN_VARIANTS'
                this.selectedFilter.id = 'TRACE_IS_IN_VARIANTS'
                this.canSaveActivityArcFilter(this.filterValue.variant)
                this.handleSaveFilter()
            })
        },
        listenFilterByLoop () {
            this.$root.$on('filterByLoop', (loopVariants) => {
                this.filterValue.variant = loopVariants.variant
                this.filterKey = 'TRACE_IS_IN_LOOP_VARIANTS'
                this.selectedFilter.id = 'TRACE_IS_IN_LOOP_VARIANTS'
                this.filterValue.loopName = loopVariants.loopName
                this.canSaveActivityArcFilter(this.filterValue.variant)
                this.handleSaveFilter()
            })
        },
        getArcs () {
            const { processId } = this
            const params = {}
            apiRequest(Api().visualizations.arcs({ processId, params }))
                .then((res) => {
                    this.arcs = res
                    this.arcOptions = res
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        getAttributeValues () {
            const { processId } = this
            apiRequest(Api().visualizations.attributes({ processId }))
                .then((res) => {
                    this.attrValues = res.filter(attr => attr.values.length <= 1000)
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        arcFilterFn (val, update) {
            update(() => {
                const newValue = val.toLowerCase()
                this.arcOptions = this.arcs.filter(v => v.toLowerCase().indexOf(newValue) > -1)
            })
        },
        activityFilterFn (val, update) {
            update(() => {
                const newValue = val.toLowerCase()
                this.activityOptions = this.activities.filter(v => v.toLowerCase().indexOf(newValue) > -1)
            })
        },
        formatFilters (filters = []) {
            return filters.map(({
                id, label, kind, repeatable,
            }) => ({
                kind, id, label, repeatable,
            }))
        },
        handleClickFilter (filter) {
            this.selectedFilter = filter
            this.filterKey = filter.id
        },
        handleSaveFilter () {
            const customDurationFilter = this.DURATION_FILTERS.find(f => f.id === this.selectedFilter.id)
            if (this.selectedFilter.id === 'ACTIVITIES_DURATION' &&
                (!this.filterValue?.activitySource || !this.filterValue?.activityTarget)) {
                return
            }
            if (customDurationFilter) {
                const quantityAttribute = this.filterValue.constraints.value
                // Check the duration is not 0000D 00h:00m:00s and that it follows that format
                const regexDuration = /^(?!0+D 0+h:0+m:0+s)(\d{4}D) (\d{2}h:\d{2}m:\d{2}s)$/
                if (regexDuration.test(this.filterValue?.duration)) {
                    this.$emit('onSaveFilter', { ...this.selectedFilter, id: customDurationFilter.options[quantityAttribute], type: this.filterValue })
                }
            } else if (this.canSaveFilter) {
                this.$emit('onSaveFilter', { ...this.selectedFilter, type: this.filterValue })
                this.resetFilter()
                this.getFilters()
            }
        },
        handleSaveAttributes (attributes) {
            this.$emit('onSaveFilter', { attributes, type: this.selectedFilter.id })
            this.resetFilter()
        },
        handleBack () {
            this.resetFilter()
        },
        resetFilter () {
            this.canSaveFilter = false
            this.selectedFilter = {}
            this.filterValue = {
                category: null,
                date: '',
                duration: '0000D 00h:00m:00s',
                constraints: null,
                arc: null,
                variant: null,
                loopName: null,
            }
        },
        close () {
            this.resetFilter()
            this.mountedFilter = false
        },
        enterElement (el, done) {
            this.mountedFilter = true
            anime({
                targets: el,
                translateX: [400, 0],
                opacity: [0, 1],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        leaveElement (el, done) {
            anime({
                targets: el,
                translateX: [0, 400],
                opacity: [1, 0],
                easing: 'easeInOutSine',
                duration: 700,
                complete: done,
            })
        },
        beforeDestroy () {
            clearTimeout(this.timeout)
        },
        afterLeaveElement () {
            this.mountedFilter = false
        },
        resetFilterValue () {
            this.filterValue.category = null
        },
        deleteIndexValue (index, filterValuesArray) {
            filterValuesArray.splice(index, 1)
            this.canSaveActivityArcFilter(filterValuesArray)
        },
        closeModal () {
            this.$emit('close')
        },
        canSaveActivityArcFilter (value) {
            if (value) {
                const checkArray = Array.isArray(value)
                if (checkArray && !value.length) {
                    this.canSaveFilter = false
                } else {
                    this.canSaveFilter = true
                }
            } else {
                this.canSaveFilter = false
            }
        },
        canSaveTraceDateFilter (value) {
            this.canSaveFilter = value ?? false
        },
    },
}
</script>
<style lang="scss" scoped>
.FilterModal {
    position: absolute;
    top: $subheader-height;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 2002;
    background: white;
    .FilterSection {
        height: 100%;
        .DataContainer {
            width: 300px;
        }
        .CardFooter {
            text-align: right;
        }
    }
   .BackArrow {
        position: absolute;
        top: 30px;
        color: $black;
        font-size: 24px;
        opacity: .65;
        cursor: pointer;
    }
    .BackArrow {
        left: 50px;
    }
}

.DataContainer{
    display:flex;
    flex-direction: row;
}
.selectStyle{
    min-width: 100%;
    width: 100%;
}

</style>
