<template>
    <div class="Sidebar">
        <SimpleContextualInfo
            class="pt-2"
            :totalInfo="infoTotal"
            :filteredInfo="infoFiltered"
            :totalFilters="totalFilters.length"
            :readOnly="readOnly"
            @onApplyFilter="onApply"
        />
        <div class="FiltersContainer">
            <WText
                class="FiltersTitle pt-1"
                align="center"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.filters.total', { total: (filterHiddenTotalFilters || []).length }) }}
            </WText>
            <div class="Actions mt-1">
                <div class="flex row-center mb-1">
                    <div class="Action">
                        <WButton
                            v-if="!addMode"
                            size="sm"
                            iconRight="filter"
                            :iconSize="10"
                            :disabled="readOnly"
                            @click="handleManageFilters"
                        >
                            {{ $t('visualization.filters.manage') }}
                        </WButton>
                        <WButton
                            v-if="addMode"
                            size="sm"
                            iconRight="close"
                            :iconSize="10"
                            color="warning"
                            @click="handleCloseModal"
                        >
                            {{ $t('visualization.cancel') }}
                        </WButton>
                    </div>
                    <div class="Action">
                        <WButton
                            size="sm"
                            iconRight="trash"
                            :iconSize="10"
                            :disabled="readOnly"
                            @click="handleResetFilters"
                        >
                            {{ $t('visualization.filters.reset') }}
                        </WButton>
                    </div>
                    <div class="Action">
                        <!-- TODO: refactor --->
                        <WButton
                            size="sm"
                            iconRight="list"
                            :iconSize="10"
                            color="primary"
                            :disabled="readOnly"
                            @click="showing = !showing"
                        >
                            {{ $t('visualization.filters.sets') }}
                        </WButton>
                        <div
                            v-if="showing"
                            class="FiltersSetsModalContainer flex"
                        >
                            <div
                                v-if="!isLoading"
                                class="FilterSetsContainer"
                            >
                                <div class="SetsModal">
                                    <WText
                                        align="center"
                                        class="pb-1"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.filters.filterSets.name') }}
                                    </WText>
                                    <QInput
                                        v-model="search"
                                        dense
                                        outlined
                                        type="search"
                                        :label="$t('visualization.filters.filterSets.search.placeholder')"
                                        class="Search search-input float-left pb-1"
                                        @keyup.enter="handleSearchVariant(search)"
                                    >
                                        <template v-slot:append>
                                            <WIcon
                                                v-if="search"
                                                id="clean"
                                                icon="close"
                                                :size="20"
                                                clickable
                                                @click="handleResetVariant"
                                            />
                                            <WIcon
                                                id="search"
                                                icon="plus-circle"
                                                :size="20"
                                                color="primary"
                                                clickable
                                                @click="handleSearchVariant(search)"
                                            />
                                        </template>
                                    </QInput>
                                    <div
                                        v-if="!isLoading && (sets || []).length"
                                        class="SetsContainer"
                                    >
                                        <div
                                            v-for="set in sets"
                                            :key="set.uuid"
                                            :style="getSetStyles(set.name)"
                                            class="RowSet flex row-stretch-center"
                                            @click="handleClickSet(set.name)"
                                        >
                                            <WText
                                                align="center"
                                            >
                                                {{ set.name }}
                                            </WText>
                                            <div class="flex">
                                                <WIcon
                                                    icon="filter"
                                                    :clickable="!!selectedSet"
                                                    color="primary"
                                                    :size="16"
                                                    @click="handleSetFilters(set.name)"
                                                />
                                                <WIcon
                                                    icon="trash"
                                                    color="primary"
                                                    :size="16"
                                                    clickable
                                                    @click="handleDeleteSet(set.uuid)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <EmptyList
                                        v-else
                                        :text="$t('visualization.filters.filterSets.notifications.noResult.title')"
                                        class="mt-2 mb-2"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="selectedSet"
                                class="FiltersContained"
                            >
                                <WText
                                    align="center"
                                    class="pb-1"
                                    weight="bold"
                                    color="primary"
                                >
                                    Filters contained
                                </WText>
                                <div
                                    v-if="(filterByName[selectedSet][0].filters || []).length"
                                    class="FiltersContainedList"
                                >
                                    <div
                                        v-for="(filterSet, index) in filterByName[selectedSet][0].filters"
                                        :key="index"
                                        class="Filters flex"
                                    >
                                        <div class="flex">
                                            <FilterIcon
                                                :id="getIconId(filterSet)"
                                                :height="30"
                                                :width="30"
                                            />
                                            <div class="FilterLabel">
                                                <QTooltip
                                                    contentClass="bg-primary text-uppercase"
                                                    maxWidth="350px"
                                                >
                                                    {{ formatLabelValue(filterSet).tooltip }}
                                                </QTooltip>
                                                <WText
                                                    uppercase
                                                    :size="12"
                                                >
                                                    {{ formatLabelValue(filterSet).label }}
                                                </WText>
                                                <WText
                                                    :size="12"
                                                    weight="bold"
                                                >
                                                    {{ formatLabelValue(filterSet).value }}
                                                </WText>
                                                <WText
                                                    v-if="formatLabelValue(filterSet).additionalValue"
                                                    :size="12"
                                                    weight="bold"
                                                >
                                                    {{ formatLabelValue(filterSet).additionalValue }}
                                                </WText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <EmptyList
                                    v-else
                                    :title="$t('visualization.filters.filterSets.notifications.noResult.noFilters')"
                                    class="mt-2 mb-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="(totalFilters || []).length"
                class="FilterList t-0-5"
            >
                <div
                    v-for="(filter, index) in totalFilters"
                    :key="`${filter.uuid}-${index}`"
                    class="Filters flex"
                >
                    <div
                        v-if="!filter.hidden"
                        class="flex"
                    >
                        <FilterIcon
                            :id="getIconId(filter)"
                            :height="30"
                            :width="30"
                        />
                        <div class="FilterLabel">
                            <QTooltip
                                contentClass="bg-primary text-uppercase"
                                maxWidth="300px"
                            >
                                {{ formatLabelValue(filter).tooltip }}
                            </QTooltip>
                            <WText
                                uppercase
                                :size="12"
                                :color="getColor(filter)"
                            >
                                {{ formatLabelValue(filter).label }}
                            </WText>
                            <WText
                                :size="12"
                                weight="bold"
                                :color="getColor(filter)"
                            >
                                {{ formatLabelValue(filter).value }}
                            </WText>
                            <WText
                                v-if="formatLabelValue(filter).additionalValue && formatLabelValue(filter).additionalValue !== 'null-null'"
                                :size="12"
                                weight="bold"
                                :color="getColor(filter)"
                            >
                                {{ formatLabelValue(filter).additionalValue }}
                            </WText>
                        </div>
                    </div>
                    <WIcon
                        v-if="!filter.hidden"
                        class="DeleteIcon"
                        icon="trash-2"
                        :size="18"
                        :clickable="!readOnly"
                        color="primary"
                        @click="handleDeleteClick(filter)"
                    />
                </div>
            </div>
            <div v-else>
                <WText
                    class="Empty pt-1"
                    :size="12"
                    align="center"
                >
                    {{ $t('visualization.filters.empty') }}
                </WText>
            </div>
        </div>
        <ModalRequest
            ref="delSetDialog"
            :title="$t('visualization.filters.filterSets.delete.title')"
            :text="$t('visualization.filters.filterSets.delete.description')"
            @onAccept="deleteFilterSet"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import 'moment-duration-format'
import { mediaQueryMixin, filtersMixin, filtersStorageMixin } from '@/mixins'
import { convertSeconds } from '@/utils/dates'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { EmptyList, ModalRequest } from '@/components'
import {
    ATTRIBUTTE_TYPES, ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, FILTERS_TYPES, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'
import { SimpleContextualInfo } from '../components'
import FilterIcon from './FilterModal/components/FilterIcon.vue'

export default {
    name: 'Sidebar',
    components: {
        FilterIcon, EmptyList, ModalRequest, SimpleContextualInfo,
    },
    mixins: [mediaQueryMixin, filtersStorageMixin, filtersMixin],
    props: {
        tabs: VueTypes.array,
        showAvatar: VueTypes.bool.def(true),
        addMode: VueTypes.bool.def(false),
        processId: VueTypes.oneOfType([String, Number]),
        infoFiltered: VueTypes.object,
        infoTotal: VueTypes.object,
        readOnly: VueTypes.bool.def(false),
    },
    data () {
        return {
            showing: false,
            search: '',
            sets: [],
            isLoading: false,
            selectedSet: '',
            variants: undefined,
        }
    },
    computed: {
        totalFilters () {
            const { visualizationFilters } = this
            return this.formatFilters(visualizationFilters)
        },
        filterByName () {
            return groupBy(this.sets, 'name')
        },
    },
    watch: {
        showing (show) {
            if (show) {
                this.getDatasetFilters(this.processId)
                this.selectedSet = ''
            }
        },
    },
    mounted () {
        this.getVariants()
        this.$root.$on('refreshVariantsNamesAtSideBar', () => {
            this.getVariants()
        })
        this.$root.$on('checkForVariantFilter', () => {
            this.checkForVariantFilter()
        })
        this.$root.$on('checkForLoopFilter', () => {
            this.checkForLoopFilter()
        })
    },
    methods: {
        checkForLoopFilter () {
            const { visualizationFilters } = this
            const loopFilter = visualizationFilters.find(filter => (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)))
            this.$root.$emit('hasLoopFilter', loopFilter !== undefined)
        },
        checkForVariantFilter () {
            const { visualizationFilters } = this
            const variantFilter = visualizationFilters.find(filter => (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)))
            this.$root.$emit('hasVariantFilter', variantFilter !== undefined)
        },
        async getVariants (param) {
            this.isLoading = true
            const params = {}
            apiRequest(Api().visualizations.variants({ processId: this.$route.params.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        formatFilters (filters) {
            return (filters || []).map(({
                label, id, key, ...rest
            }) => ({
                id, label, value: key, ...rest,
            }))
        },
        handleDeleteClick (selectedFilter) {
            this.$emit('onDeleteClick', selectedFilter)
        },
        handleManageFilters () {
            this.resetSetsForm()
            this.$emit('onManageFilter')
        },
        handleCloseModal () {
            this.$emit('onCancel')
        },
        handleResetFilters () {
            if (this.totalFilters.length > 0) {
                this.$emit('onReset')
            }
        },
        formatValue (value) {
            if (!value) return ''
            return typeof value === 'string' ? value : (value || []).join(', ')
        },
        formatLabelValue (filter = {}) {
            const formattedLabel = this.$t(`visualization.filters.labels.${filter.id}`)

            if (ACTIVITY_FILTERS.includes(filter.kind) || ACTIVITY_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ARC_FILTERS.includes(filter.kind) || ARC_FILTERS.includes(filter.id)) {
                const value = typeof filter.value === 'string' ? filter.value : (filter.value || []).join(', ')
                const formattedValue = value || (filter.values || []).join(', ')
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (DURATION_FILTERS.includes(filter.kind) || DURATION_FILTERS.includes(filter.id)) {
                let formattedValue = filter.kind
                    ? moment.duration(filter.value || filter.values[0]).format('d[D] h[h]:m[m]:s[s]')
                    : filter.value
                if (filter.id.includes('ACTIVITIES')) {
                    formattedValue = filter.kind
                        ? moment.duration(filter.values[filter.values.length - 1]).format('d[D] h[h]:m[m]:s[s]')
                        : filter.value
                    if (filter.id.endsWith('LESS_THAN')) {
                        formattedValue = `< ${formattedValue}`
                    } else if (filter.id.endsWith('GREATER_THAN')) {
                        formattedValue = `> ${formattedValue}`
                    }
                }
                const additionalValue = (filter.type || {}).arc || filter.arc || (filter.type || {}).category || filter.activity ||
                      (filter.type && `${(filter.type || {}).activitySource}-${(filter.type || {}).activityTarget}`) || (filter.values && `${filter.values[0]}-${filter.values[1]}`)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    additionalValue,
                    tooltip: `${formattedLabel}: ${formattedValue}${additionalValue ? ` | ${additionalValue}` : ''}`,
                }
            } if (DATE_FILTERS.includes(filter.kind) || DATE_FILTERS.includes(filter.id)) {
                const formattedValue = filter.value || filter.values[0]
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (ATTRIBUTES_FILTERS.includes(filter.kind) || ATTRIBUTES_FILTERS.includes(filter.id)) {
                const isBooleanType = ((filter.kind || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN) || (filter.id || '').includes(FILTERS_TYPES.ATTRIB_BOOLEAN))
                if (isBooleanType) {
                    if (filter.kind) {
                        const bool = ATTRIBUTTE_TYPES.BOOLEAN_ATTRIBUTE.find(a => a.value === filter.id) || {}
                        const formattedValue = typeof filter.attribute === 'string' ? `${filter.attribute} - ${bool.label}` : filter.label
                        return {
                            label: formattedValue,
                            value: '',
                            tooltip: formattedValue,
                        }
                    }
                    return {
                        label: filter.label,
                        value: '',
                        tooltip: filter.label,
                    }
                }

                const attributes = []
                Object.values(ATTRIBUTTE_TYPES).forEach((attribute) => {
                    attribute.forEach((type) => {
                        attributes.push(type)
                    })
                })
                const attributeFormatterLabel = filter.kind ? `${filter.attribute} - ${(attributes.find(a => a.value === filter.id)).label}` : filter.label
                const formattedValue = filter.value || filter.values[0]
                return {
                    label: attributeFormatterLabel,
                    value: formattedValue,
                    tooltip: `${attributeFormatterLabel}: ${formattedValue}`,
                }
            } if (VARIANT_FILTERS.includes(filter.kind) || VARIANT_FILTERS.includes(filter.id)) {
                let formattedValue = ' '
                if (this.variants !== undefined) {
                    filter.value?.constructor === Array
                        ? formattedValue = filter.value.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            return variant ? variant.name : null
                        })
                        : formattedValue = filter.values.map((id) => {
                            const variant = this.variants.find(v => v.id === id)
                            return variant ? variant.name : null
                        })
                    formattedValue = formattedValue.join(', ')
                }
                this.$root.$emit('hasVariantFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            } if (LOOP_FILTERS.includes(filter.kind) || LOOP_FILTERS.includes(filter.id)) {
                const formattedValue = filter.type.loopName
                this.$root.$emit('hasLoopFilter', true)
                return {
                    label: formattedLabel,
                    value: formattedValue,
                    tooltip: `${formattedLabel}: ${formattedValue}`,
                }
            }
            return {
                label: filter.label,
                value: filter.value,
                tooltip: `${filter.label}: ${filter.value}`,
            }
        },
        getColor ({ pending, toDelete }) {
            if (toDelete) return 'red'
            if (pending) return 'gray03'
            return ''
        },
        formatDuration (duration) {
            return convertSeconds(duration)
        },
        formatDate (value) {
            const isValid = value ?? undefined
            return isValid ? this.date(value) : this.$t('visualization.filters.date.noData')
        },
        resetSetsForm () {
            this.search = ''
            this.showing = false
            this.selectedSet = ''
        },
        getDatasetFilters (datasetId) {
            this.isLoading = true
            apiRequest(Api().datasets.getDatasetFilters({ datasetId }))
                .then(({ data }) => { this.sets = data })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        registerDatasetFilters (name, datasetId = this.processId) {
            const { visualizationFilters } = this
            const filters = this.formatFiltersParam(visualizationFilters)
            const params = { filters, name }
            apiRequest(Api().datasets.registerDatasetFilters({ datasetId, params }))
                .then((res) => {
                    this.getDatasetFilters(datasetId)
                    notifySuccess(this.$t('visualization.filters.filterSets.register.success'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleSearchVariant (search) {
            this.search = ''
            this.registerDatasetFilters(search)
        },
        handleResetVariant () {
            this.search = ''
        },
        handleClickSet (name) {
            this.selectedSet = name
        },
        getSetStyles (name) {
            return {
                ...(this.selectedSet === name
                    ? { background: 'rgba(42, 143, 229, 0.6)' } : {}),
            }
        },
        deleteFilterSet () {
            const { processId, filterId } = this
            this.selectedSet = ''
            this.deleteFiltersSet(processId, filterId)
        },
        handleDeleteSet (filterId) {
            this.filterId = filterId
            this.$refs.delSetDialog.open()
        },
        deleteFiltersSet (datasetId, filterId) {
            apiRequest(Api().datasets.deleteFiltersSet({ datasetId, filterId }))
                .then(() => {
                    notifySuccess(this.$t('visualization.filters.filterSets.delete.success'))
                    this.getDatasetFilters(datasetId)
                    this.$refs.delSetDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        getIconId (filterSet) {
            return ATTRIBUTES_FILTERS.includes(filterSet.kind) ? filterSet.kind : filterSet.id
        },
        formatFiltersToSave (filters) {
            return filters.map((f, index) => ({
                label: f.label || f.id,
                pending: false,
                uuid: `${f.id}_${index}`,
                value: this.formatValue(f.values),
                ...f,
            }))
        },
        handleSetFilters (selectedSet) {
            const { filterByName = {} } = this
            const { filters } = (filterByName || {})[selectedSet][0]
            this.selectedSet = ''
            this.showing = false
            const formattedFilters = this.formatFiltersToSave(filters)
            this.setVisualizationFilters(formattedFilters)
            this.$emit('onReplaceFilters')
        },
        onApply () {
            this.$emit('onApply')
        },
        filterHiddenTotalFilters () {
            return this.totalFilters.filter(f => !f.hidden)
        },
    },
}
</script>
<style scoped lang="scss">
.Filters {
    padding: 10px 0;
    flex-wrap: nowrap;
    .FilterLabel {
        padding-left: 15px;
        .WText {
            width: 165px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .DeleteIcon {
        padding-left: 5px;
    }
}
.FilterIcon {
    background:  $primary;
    border-radius: 5px;
    padding: 3px;
}
.FiltersContainer {
    max-height: 50vh;
    overflow-y: auto;
    .FiltersTitle {
        position: sticky;
        top: 0;
        background: white;
        padding-bottom: 5px;
    }
    .FilterList {
        padding: 0 10px;
        .Filters {
            align-items: center;
            justify-content: space-around;
        }
    }
    .Empty {
        padding: 0 15px;
    }
}
.Actions {
    width: 85%;
    margin: 0 auto;
    text-align: center;
    .WIcon {
        border-radius: 5px;
        padding: 5px;
        background: $gray-05;
    }
    .Action {
        margin: 0 2px;
    }
    .ActionsButtons {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-evenly;

        .WButton {
            width: auto;
            height: auto;
        }
    }
}
.FiltersSetsModalContainer {
    margin-left: 100px;
    margin-top: -45px;
    position: absolute;
    z-index: 2003;
}
.FilterSetsContainer {
    background: white;
    border: 1px solid $primary;
    border-radius: 6px;
    display: block;
    padding: 10px;
    z-index: 10000;
    border-style: solid;
    max-height: 330px;
    width: 300px;

    .WIcon {
        background: transparent;
    }
    .SetsModal {
        display: grid;
        grid-template-columns: 1fr;
        .SetsContainer {
            overflow-y: auto;
            max-height: 180px;
            .RowSet {
                padding: 3px;
                &:hover {
                    cursor: pointer;
                    background: lighten($primary, 40%);
                }
            }
        }
    }
}
.FiltersContained {
    background: white;
    border: 1px solid $primary;
    border-radius: 6px;
    margin-left: 15px;
    padding: 10px;
    max-height: 330px;
    width: 300px;
    .FiltersContainedList {
        display: grid;
        overflow-y: auto;
        max-height: 180px;
    }
}

</style>
