import { render, staticRenderFns } from "./PowerBIConnector.vue?vue&type=template&id=61135190&scoped=true&"
import script from "./PowerBIConnector.vue?vue&type=script&lang=js&"
export * from "./PowerBIConnector.vue?vue&type=script&lang=js&"
import style0 from "./PowerBIConnector.vue?vue&type=style&index=0&id=61135190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61135190",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/porto/inverbis/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61135190')) {
      api.createRecord('61135190', component.options)
    } else {
      api.reload('61135190', component.options)
    }
    module.hot.accept("./PowerBIConnector.vue?vue&type=template&id=61135190&scoped=true&", function () {
      api.rerender('61135190', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/screens/Project/Process/Visualization/sections/PowerBIConnector.vue"
export default component.exports