var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    staticClass: "Upload",
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("projects.modals.upload.title")) + " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "Wrapper" },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("projects.modals.upload.text")))
                ]),
                _c("QUploader", {
                  staticClass: "Uploader mt-1 mb-2",
                  attrs: {
                    flat: "",
                    bordered: "",
                    accept: ".csv",
                    sendRaw: "",
                    color: "grey-1",
                    textColor: "primary",
                    autoUpload: true,
                    headers: _vm.headers,
                    method: _vm.method,
                    url: _vm.url
                  },
                  on: {
                    finish: _vm.uploadFinished,
                    start: _vm.uploadStarted,
                    uploaded: _vm.uploadSuccess
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }