<template>
    <div class="UserForm">
        <div class="Row">
            <div class="Field">
                <QInput
                    ref="name"
                    v-model="value.name"
                    lazyRules
                    dense
                    outlined
                    maxlength="100"
                    :label="$t('forms.user.name.label')"
                    :placeholder="$t('forms.user.name.placeholder')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.name.label') })]"
                />
            </div>
            <div class="Field">
                <QInput
                    ref="surname"
                    v-model="value.surname"
                    lazyRules
                    dense
                    outlined
                    maxlength="100"
                    :label="$t('forms.user.surname.label')"
                    :placeholder="$t('forms.user.surname.placeholder')"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.surname.label') })]"
                />
            </div>
        </div>
        <div class="Row">
            <div class="Field">
                <QInput
                    ref="email"
                    v-model="value.email"
                    lazyRules
                    dense
                    outlined
                    type="email"
                    :label="$t('forms.user.email.label')"
                    :placeholder="$t('forms.user.email.placeholder')"
                    :rules="[
                        value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.email.label') }),
                        value => validators.validEmail(value) || $t('validation.email', { attribute: $t('forms.user.email.label') })
                    ]"
                />
            </div>
            <div class="Field">
                <QSelect
                    ref="locale"
                    :value="locale"
                    lazyRules
                    dense
                    outlined
                    behavior="menu"
                    class="Locales"
                    :label="$t('forms.user.locale.label')"
                    :placeholder="$t('forms.user.locale.placeholder')"
                    :options="locales"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.locale.label') })]"
                    @input="handleChangeLocale"
                />
            </div>
        </div>
        <div class="Row">
            <div class="Field">
                <QSelect
                    ref="locationFormat"
                    :value="locationFormat"
                    lazyRules
                    dense
                    outlined
                    behavior="menu"
                    class="LocationFormats"
                    :label="$t('forms.user.locationFormat.label')"
                    :placeholder="$t('forms.user.locationFormat.placeholder')"
                    :options="locationFormats"
                    :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('forms.user.locationFormat.label') })]"
                    @input="handleChangeLocationFormat"
                >
                    <template v-slot:option="scope">
                        <QItem
                            v-bind="scope.itemProps"
                            v-on="scope.itemEvents"
                        >
                            <QItemSection>
                                <QItemLabel>{{ scope.opt.label }}</QItemLabel>
                                <QItemLabel caption>
                                    {{ scope.opt.description }}
                                </QItemLabel>
                            </QItemSection>
                        </QItem>
                    </template>
                </QSelect>
            </div>
            <div class="flex row-right-center">
                <WButton
                    id="submit"
                    :disabled="disabled"
                    @click="handleSubmit"
                >
                    {{ $t('forms.user.submit') }}
                </WButton>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import Config from '@/config'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'UserForm',
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            name: VueTypes.string,
            surname: VueTypes.string,
            email: VueTypes.string,
            locale: VueTypes.string,
            locationFormat: VueTypes.string,
        }).def({}).loose,
        disabled: VueTypes.bool.def(false),
        locales: VueTypes.array.def(function () {
            const { locales } = Config.i18n
            return locales.map(val => ({ label: this.$t(`forms.user.locales.${val}`), value: val }))
        }),
        locationFormats: VueTypes.array.def(function () {
            const { locationFormats } = Config.i18n
            return locationFormats.map(val => ({
                label: this.$t(`forms.user.locationFormats.${val}`),
                value: val,
                description: `${this.$d(new Date(), 'long', val === 'eu' ? 'es-ES' : val)}   ${this.$n(123456, 'decimal', val === 'eu' ? 'de-DE' : val)}`,
            }))
        }),
    },
    computed: {
        locale () {
            return this.$t(`forms.user.locales.${this.value.locale}`)
        },
        locationFormat () {
            const { locationFormat } = this.value || {}
            return this.locationFormats.find(({ value }) => value === locationFormat) || locationFormat
        },
    },
    methods: {
        handleChangeLocale ({ value: locale }) {
            this.$emit('input', { ...this.value, locale })
        },
        handleChangeLocationFormat ({ value: locationFormat }) {
            this.$emit('input', { ...this.value, locationFormat })
        },
        handleSubmit () {
            if (this.validate()) {
                this.$emit('onSubmit')
                this.locales = Config.i18n.locales.map(value => ({ label: this.$t(`forms.user.locales.${value}`, this.locale), value }))
            }
        },
        validate () {
            const fields = ['name', 'surname', 'email', 'locale', 'locationFormat']
            return !fields.filter(field => !this.$refs[field].validate()).length
        },
    },
}
</script>
<style scoped lang="scss">
.UserForm {
    .Row {
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );

        & + & {
            margin-top: 15px;
        }

        .Field {
            margin-bottom: 10px;
        }
    }
}
</style>
