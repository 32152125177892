<template>
    <div class="Step">
        <div class="row justify-center">
            <WCard
                v-if="(steps || []).length"
                flat
                bordered
                class="col-8 col-lg-4"
            >
                <CollapseItem
                    icon="settings"
                    :title="steps[0].title"
                    :opened="collapses[steps[0].name]"
                    @onClick="handleClick(steps[0].name)"
                >
                    <div
                        v-if="value.settings"
                        class="pa-1"
                    >
                        <WCard
                            flat
                            bordered
                        >
                            <div
                                v-if="value.settings.name"
                                class="pa-0-5"
                            >
                                <WText>
                                    {{ $t('wizard.name') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ value.settings.name }}
                                </WText>
                            </div>
                            <Divider />
                            <div
                                v-if="value.settings.description"
                                class="pa-0-5"
                            >
                                <WText>
                                    {{ $t('wizard.description') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ value.settings.description }}
                                </WText>
                            </div>
                        </WCard>
                    </div>
                </CollapseItem>
                <CollapseItem
                    icon="file-text"
                    :title="steps[1].title"
                    :opened="collapses[steps[1].name]"
                    @onClick="handleClick(steps[1].name)"
                >
                    <div
                        v-if="value.file"
                        class="pa-1"
                    >
                        <WCard
                            flat
                            bordered
                        >
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('wizard.file') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ value.file.name }}
                                </WText>
                            </div>
                        </WCard>
                    </div>
                </CollapseItem>
            </WCard>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Divider, CollapseItem } from '@/components'

export default {
    name: 'StepSummary',
    components: {
        Divider,
        CollapseItem,
    },
    props: {
        value: VueTypes.shape({
            datasetId: VueTypes.string.isRequired,
            settings: VueTypes.any.isRequired,
            file: VueTypes.any.isRequired,
        }).loose,
        steps: VueTypes.arrayOf(VueTypes.shape({
            title: VueTypes.string,
            icon: VueTypes.string,
        }).loose),
    },
    data () {
        const collapses = (this.steps || []).reduce((acc, item, index) => ({ ...acc, [item.name]: !index }), {})
        return {
            collapses,
        }
    },
    methods: {
        handleClick (key) {
            this.collapses = {
                ...this.collapses,
                [key]: !this.collapses[key],
            }
        },
    },
}
</script>
<style scoped lang="scss">
.CollapseItem + .CollapseItem {
    border-top: 1px solid $border-color;
}
</style>
