import pathToRegex from 'path-to-regexp'

/**
 * Axios methods
 */
export const METHODS = {
    GET: 'onGet',
    POST: 'onPost',
    PUT: 'onPut',
    DELETE: 'onDelete',
    PATCH: 'onPatch',
}

/**
 * Path to regex
 * @params endpoint = {}
 * @example '/customers/:id'
 */
export const normalizePath = path => ({
    path,
    regex: pathToRegex(path),
})

/**
 * Normalize request
 * @params endpoint = {}
 * @params axiosAdapter = m
 * @example m.onGet('/customers/:id')
*/
export const normalizeRequest = (endpoint, axiosAdapter) => {
    const method = METHODS[endpoint.method] || METHODS.GET
    return axiosAdapter[method](endpoint.regex)
}

/**
 * Normalize response
 * @params endpoint = { method, path, transform }
 * @property {string} method - GET|POST|PUT|DELETE|PATCH
 * @property {string} path - Path ('/customers/:id')
 * @property {(Object|func)} transform - Response data or function data
 * @params axiosAdapter = m
 * @example m.reply([200, {}])
*/
export const normalizeResponse = (endpoint, axiosAdapter) => {
    const noop = (data = {}) => [200, endpoint.transform || data]
    const transform = typeof endpoint.transform === 'function' ? endpoint.transform : noop
    return axiosAdapter.reply(response => transform({ ...response, ...endpoint }))
}

/**
 * Generate mocks and responses
 * @params endpoints = { }
 * @example { mocks: {}, defaultResponses: {} }
 */
export const normalizeMocks = endpoints => ({
    ...Object.keys(endpoints).reduce((acc, key) => {
        const path = normalizePath((endpoints[key] || {}).path || '')
        const endpoint = { ...endpoints[key], ...path }

        return {
            ...acc,
            mocks: {
                ...acc.mocks,
                [key]: normalizeRequest.bind(this, endpoint),
            },
            defaultResponses: {
                ...acc.defaultResponses,
                [key]: normalizeResponse.bind(this, endpoint),
            },
        }
    }, {
        mocks: {},
        defaultResponses: {},
    }),
})

export default {
    normalizePath,
    normalizeRequest,
    normalizeResponse,
    normalizeMocks,
}
