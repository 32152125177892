var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [
        _c("QSelect", {
          staticClass: "selectDuration",
          attrs: {
            dense: "",
            options: _vm.durationOptions,
            label: _vm.$t(
              "visualization.customPlotPanels.frequencyDuration.selectDuration"
            )
          },
          on: { input: _vm.getData },
          model: {
            value: _vm.duration,
            callback: function($$v) {
              _vm.duration = $$v
            },
            expression: "duration"
          }
        }),
        _c("QSelect", {
          staticClass: "selectRange",
          attrs: {
            dense: "",
            options: _vm.rangeOptions,
            label: _vm.$t(
              "visualization.customPlotPanels.frequencyDuration.selectRange"
            )
          },
          on: { input: _vm.getData },
          model: {
            value: _vm.range,
            callback: function($$v) {
              _vm.range = $$v
            },
            expression: "range"
          }
        }),
        _c(
          "div",
          [
            _c("WIcon", {
              key: "dropdown",
              staticClass: "openIcon",
              attrs: {
                icon: "more-vertical",
                size: 30,
                color: "primary",
                clickable: ""
              },
              on: { click: _vm.setSelected }
            }),
            _vm.selected
              ? _c("Dropdown", {
                  attrs: {
                    direction: "bottomRight",
                    opened: !!_vm.selected,
                    options: _vm.metricOptions
                  },
                  on: { onClick: _vm.handleClickOption },
                  model: {
                    value: _vm.title,
                    callback: function($$v) {
                      _vm.title = $$v
                    },
                    expression: "title"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "chart-container" },
      [
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.barChartOptions, autoresize: "" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }