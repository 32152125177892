export default {
    new: 'Crear grupo',
    delete: 'Eliminar grupo',
    edit: 'Editar grupo',
    showActives: 'Mostrar grupos de trabajo activos',
    sharedWithMe: 'Compartidos conmigo',
    createdByMe: 'Creados por mi',
    notifications: {
        noResult: {
            title: 'No se han encontrado resultados',
            text: 'No se ha encontrado ningún grupo de trabajo para tu búsqueda.',
        },
        empty: {
            title: 'No se han encontrado grupos de trabajo',
            text: 'Puedes crear tu primer grupo de trabajo.',
        },
        created: {
            text: 'Grupo de trabajo creado correctamente.',
        },
        updated: {
            text: 'Grupo de trabajo actualizado correctamente.',
        },
        deleted: {
            text: 'Grupo de trabajo eliminado correctamente.',
        },
    },

    modals: {
        delete: {
            text: '¿Seguro que quieres eliminar el grupo de trabajo "{name}"?',
        },
    },
}
