<template>
    <WText
        v-if="website"
        :size="12"
        class="Powered"
    >
        Powered By <a
            :href="website"
            target="_blank"
        ><WText
            tag="span"
            weight="semi"
            :size="12"
        >
            {{ powered }}
        </WText></a>
    </WText>
</template>

<script>
import VueTypes from 'vue-types'
import Config from '@/config'

export default {
    name: 'Powered',
    props: {
        powered: VueTypes.string.def(Config?.project?.powered),
        website: VueTypes.string.def(Config?.project?.website),
    },
}
</script>
<style scoped lang="scss">
.Powered {
    @media screen and (max-width: $md) {
        display: none;
    }
}
</style>
