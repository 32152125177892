<template>
    <div>
        <div>
            <div class="Tittle">
                {{ $t('booster.prediction.predictionHighlights.name') }}
            </div>
            <PredictionBoosterMetricsPanel />
        </div>
        <div>
            <QCard />
        </div>
    </div>
</template>
<script>
import PredictionBoosterMetricsPanel from './PredictionBoosterMetricsPanel.vue'

export default {
    name: 'PredictionHighlights',
    components: { PredictionBoosterMetricsPanel },
    props: {},
    methods: {},
}
</script>
<style scoped lang="scss">
    .Tittle {
        margin-bottom: 3vh;
    }
</style>
