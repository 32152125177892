<template>
    <fragment>
        <slot :loading="isLoading" />
    </fragment>
</template>
<script>
import {
    Api, Headers, apiRequest, notifyError,
} from '@/api'
import AuthService from '@/services/auth'
import { changeLocale } from '@/lang/i18n'

const normalizedLocale = {
    es: 'es-ES',
    en: 'en-US',
}

export default {
    name: 'AppProvider',
    provide () {
        return {
            App: this,
        }
    },
    data () {
        return {
            user: undefined,
            enviroment: undefined,
            isLoading: false,
        }
    },
    beforeMount () {
        this.fetchUser()
        this.getInfo()
    },
    methods: {
        async fetchUser () {
            this.isLoading = true
            apiRequest(Api().users.me())
                .then(this.setUser)
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async setUser (user) {
            const { locale } = user
            this.user = { ...this.user, ...user }
            this.setLocale(locale)
        },
        async getInfo () {
            this.isLoading = true
            apiRequest(Api().enviroments.getInfo())
                .then(data => (this.enviroment = data))
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async setLocale (locale) {
            // Change vue-i18n
            changeLocale(locale)
            // Change Accept-Language HTTP Header
            if (normalizedLocale[locale]) {
                Headers.set({ 'Accept-Language': normalizedLocale[locale] })
            }
            // Change quasar components
            try {
                let formattedLocale = locale
                if (formattedLocale.includes('en')) formattedLocale = formattedLocale.replace('en', 'en-us')
                await import(`quasar/lang/${formattedLocale}`).then(lang => (this.$q?.lang.set(lang.default)))
            } catch (err) {
                console.warn('Requested Quasar Language Pack does not exist', err)
            }
        },
        signout () {
            AuthService.signout({ storage: this.$storage })
        },
        dateLocationFormat (date, type) {
            if (date === undefined) return ''
            const locationFormat = () => {
                if (this.user) {
                    return this.user.locationFormat === 'eu' ? 'es-ES' : this.user.locationFormat
                }
                return 'en'
            }
            return this.$d(new Date(date), type, locationFormat())
        },
        numberLocationFormat (number) {
            if (number === undefined) return ''
            const locationFormat = () => {
                if (this.user) {
                    return this.user.locationFormat === 'eu' ? 'de-DE' : this.user.locationFormat
                }
                return 'en'
            }
            if (typeof number === typeof 0) {
                return this.$n(number, 'decimal', locationFormat())
            }
            if (number.includes('K') || number.includes('M')) {
                return number
            }
            return this.$n(parseFloat(number), 'decimal', locationFormat())
        },
    },
}
</script>
