var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.datasetInfo
    ? _c(
        "div",
        [
          _c(
            "WCard",
            { staticClass: "Personal mb-2", attrs: { bordered: "" } },
            [
              _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("projects.datasets.settings.general.nameTitle")
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "q-pa-md" }, [
                _c(
                  "div",
                  { staticClass: "row q-gutter-md items-center" },
                  [
                    _c("QInput", {
                      ref: "nameField",
                      staticClass: "col",
                      attrs: {
                        lazyRules: "",
                        dense: "",
                        outlined: "",
                        maxlength: "60",
                        label: _vm.$t(
                          "projects.datasets.settings.general.nameField"
                        ),
                        rules: [
                          function(value) {
                            return (
                              _vm.validators.required(value) ||
                              _vm.$t("validation.required", {
                                attribute: _vm.$t(
                                  "projects.datasets.general.updates.value"
                                )
                              })
                            )
                          }
                        ]
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "after",
                            fn: function() {
                              return [
                                _c(
                                  "WButton",
                                  { on: { click: _vm.handleSubmit } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "projects.datasets.settings.general.updateName"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2112453772
                      ),
                      model: {
                        value: _vm.datasetName,
                        callback: function($$v) {
                          _vm.datasetName = $$v
                        },
                        expression: "datasetName"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "WCard",
            { staticClass: "Personal mb-2", attrs: { bordered: "" } },
            [
              _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("projects.datasets.settings.general.generalTitle")
                    ) +
                    " "
                )
              ]),
              _c("div", { staticClass: "row q-gutter-md" }, [
                _c("div", { staticClass: "column col-2" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projects.datasets.settings.general.sourceType"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "col text-primary" }, [
                    _vm._v(" " + _vm._s(_vm.datasetInfo.source) + " ")
                  ])
                ]),
                _vm.datasetInfo.fileName
                  ? _c("div", { staticClass: "column col-5" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "projects.datasets.settings.general.sourceName"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "col text-primary file-name" }, [
                        _vm._v(" " + _vm._s(_vm.datasetInfo.fileName) + " ")
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "column col" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("projects.datasets.settings.general.creation")
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "col text-primary" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.App.dateLocationFormat(
                            _vm.datasetInfo.createDate,
                            "long"
                          )
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column col" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projects.datasets.settings.general.lastUpdate"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "col text-primary" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.App.dateLocationFormat(
                            _vm.datasetInfo.tst,
                            "long"
                          )
                        ) +
                        " "
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _vm.moreInfo
            ? _c(
                "WCard",
                { staticClass: "Personal mb-2", attrs: { bordered: "" } },
                [
                  _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projects.datasets.settings.general.metricsTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "row q-gutter-md" },
                    _vm._l(Object.keys(_vm.moreInfo), function(
                      infoItem,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: "info-" + index,
                          staticClass: "q-pa-xs col-xs-2 col-sm-2 col-md-2"
                        },
                        [
                          _c(
                            "QCard",
                            [
                              _c("QCardSection", { staticClass: "text-left" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "projects.datasets.properties." +
                                          infoItem
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("QSeparator"),
                              _c(
                                "QCardSection",
                                {
                                  staticClass:
                                    "flex flex-center text-primary text-h4 bg-grey-2"
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.App.numberLocationFormat(
                                          _vm.moreInfo[infoItem]
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }