<template>
    <div
        class="EventsBox"
        :class="[color]"
    >
        <WText
            :size="18"
            class="mb-1"
        >
            {{ title }}
        </WText>
        <div class="Amount">
            <Counter
                :size="32"
                :value="value"
                :duration="duration"
                color="primary"
                :formatValue="formatNumber"
            />
            <WText
                :size="12"
                color="gray02"
                weight="semi"
                uppercase
            >
                {{ caption }}
            </WText>
        </div>
    </div>
</template>
<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { Counter } from '@/components'
import { formatNumber } from '@/utils/formatNumber'

export default {
    name: 'EventsBox',
    components: {
        Counter,
    },
    props: {
        title: VueTypes.string,
        caption: VueTypes.string.def(function () {
            return this.$t('general.events')
        }),
        value: VueTypes.number.isRequired,
        color: VueTypes.oneOf(['primary', '']).def(''),
        duration: VueTypes.number.def(1000),
    },
    methods: {
        formatNumber (value) {
            return formatNumber(value, {
                decimals: 2, decimalSeparator: '.', compress: true, trimRight: true,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.EventsBox {
    padding: 20px;
    border: 1px solid $border-color;
    border-radius: $border-radius;

    &.primary {
        background: rgba($primary, .1)
    }
}
</style>
