<template>
    <div
        class="SettingsModal"
    >
        <div
            ref="SettingsContainer"
            class="SettingsContainer"
        >
            <SettingsModalSection
                v-if="settings"
                :values="settings"
                @SettingsConfig="saveSettings"
            />
            <div
                v-else
                class="flex justify-center items-center"
                style="height:400px;"
            >
                <QCircularProgress
                    indeterminate
                    rounded
                    size="50px"
                    color="primary"
                    class="q-ma-md"
                />
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import SettingsModalSection from './components/SettingsModalSection.vue'
import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
} from '@/api'

export default {
    name: 'SettingsModal',
    components: { SettingsModalSection },
    mixins: [],
    props: {
        attributes: VueTypes.array,
        processId: VueTypes.oneOfType([String, Number]),
        settings: VueTypes.oneOfType([Array, Object]),
    },
    data () {
        return {
            /* selection: {
                caseCompletion: {
                    endingActivities: [],
                    occurringActivities: [],
                    startingActivities: [],
                },
                cost: {
                    unit: 'euro',
                    value: 0,
                },
                sla: {
                    unit: 'minutes',
                    value: 0,
                },
                updatesEnabled: true,
            }, */
        }
    },
    computed: {
    },
    watch: {
    },
    mounted () {
    },
    methods: {
        saveSettings (settings) {
            let settingsToSave = {}
            if (typeof settings.data !== 'string') {
                settingsToSave = {
                    [settings.type]: {
                        ...settings.data,
                    },
                }
            } else {
                settingsToSave = {
                    [settings.type]: settings.data,
                }
            }
            apiRequest(Api().datasets.update({
                datasetId: this.$route.params.processId,
                params: settingsToSave,
            }))
                .then(() => {
                    this.$emit('settingsSaved')
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                })
                .catch(notifyError)
        },
    },
}
</script>
<style lang="scss" scoped>
.SettingsModal {
    min-height: fit-content;
    margin-bottom: 20px;
    top: $subheader-height;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 2002;
    background: white;

}
</style>
