export default {
    rows: [
        {
            variantId: '#1',
            baseDimensionValue: 9999,
            dimensions: [
                {
                    id: 'DURATION_AVERAGE',
                    value: 98889799,
                },
            ],
        },
        {
            variantId: '#563',
            baseDimensionValue: 8999,
            dimensions: [
                {
                    id: 'DURATION_AVERAGE',
                    value: 9889799,
                },
            ],
        },
        {
            variantId: '#344',
            baseDimensionValue: 7999,
            dimensions: [
                {
                    id: 'DURATION_AVERAGE',
                    value: 988897743,
                },
            ],
        },
        {
            variantId: '#795',
            baseDimensionValue: 5999,
            dimensions: [
                {
                    id: 'DURATION_AVERAGE',
                    value: 65889799,
                },
            ],
        },
        {
            variantId: '#456',
            baseDimensionValue: 3999,
            dimensions: [
                {
                    id: 'DURATION_AVERAGE',
                    value: 988897998,
                },
            ],
        },
    ],
}
