export default {
    new: 'Invite new user',
    available: 'Available notifications',
    revoke: 'Revoke notification',
    cancel: 'Cancel notification',
    showActives: 'Show viewed notifications',

    notifications: {
        noResult: {
            title: 'No result found',
            text: 'No notifications found for your search.',
        },
        empty: {
            title: 'No notifications found',
            text: 'Any notifications generated by this platform will be listed in this view.',
        },
        seen: {
            text: 'Notification successfully marked as seen.',
        },
    },
}
