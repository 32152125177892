import Transforms from './transforms'

const endpoints = {
    list: {
        path: `/organizations`,
        transform: Transforms.list,
    },
    get: {
        path: `/organizations/:organizationUid`,
        transform: Transforms.get,
    },
    create: {
        method: 'POST',
        path: `/organizations`,
    },
    edit: {
        method: 'PUT',
        path: '/organizations/:organizationUid',
        transform: Transforms.edit,
    },
    delete: {
        method: 'DELETE',
        path: '/organizations/:organizationUid',
        transform: Transforms.delete,
    },
}

export default {
    name: 'organizations',
    endpoints,
}
