import Vue from 'vue'

import 'quasar/dist/quasar.ie.polyfills'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import {
    QKnob,
    QFab,
    QFabAction,
    QBadge,
    QBanner,
    QBtn,
    Notify,
    Quasar,
    QCheckbox,
    QBtnToggle,
    QCard,
    QForm,
    QIcon,
    QInput,
    QLinearProgress,
    QCardSection,
    QRadio,
    QSelect,
    QSeparator,
    QStep,
    QStepper,
    QTable,
    QTabs,
    QTab,
    QTabPanel,
    QTabPanels,
    QToggle,
    QTooltip,
    QTh,
    QTd,
    QTr,
    QUploader,
    QPagination,
    QDate,
    QTime,
    QCircularProgress,
    QFile,
    QDialog,
    QBar,
    QSpace,
    QExpansionItem,
    QCardActions,
    QScrollArea,
    QItemSection,
    QAvatar,
    QItem,
    QChip,
    QField,
    QSlider,
    QPopupEdit,
    QPopupProxy,
    QMenu,
    QList,
    QItemLabel,
    QOptionGroup,
    QToolbar,
    QToolbarTitle,
} from 'quasar'

Vue.use(Quasar, {
    config: {},
    components: {
        QKnob,
        QFab,
        QFabAction,
        QBadge,
        QBanner,
        QBtnToggle,
        QCard,
        QBtn,
        QCardSection,
        QCheckbox,
        QForm,
        QInput,
        QIcon,
        QLinearProgress,
        QRadio,
        QSelect,
        QSeparator,
        QStep,
        QStepper,
        QTabs,
        QTab,
        QTable,
        QTabPanel,
        QTabPanels,
        QToggle,
        QTooltip,
        QTh,
        QTd,
        QTr,
        QUploader,
        QPagination,
        QDate,
        QTime,
        QCircularProgress,
        QFile,
        QDialog,
        QBar,
        QSpace,
        QExpansionItem,
        QCardActions,
        QScrollArea,
        QItemSection,
        QAvatar,
        QItem,
        QChip,
        QField,
        QSlider,
        QPopupEdit,
        QPopupProxy,
        QMenu,
        QList,
        QItemLabel,
        QOptionGroup,
        QToolbar,
        QToolbarTitle,
    },
    plugins: {
        Notify,
    },
})
