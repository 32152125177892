var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WCard", class: _vm.cardClass }, [
    _vm.disabled || _vm.loading
      ? _c("div", { staticClass: "Overlay" })
      : _vm._e(),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "Spinner" },
          [_c("WSpinner", { attrs: { visible: "" } })],
          1
        )
      : _vm._e(),
    _vm.$slots.header
      ? _c("div", { staticClass: "Header" }, [_vm._t("header")], 2)
      : _vm._e(),
    _c("div", { staticClass: "Body" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }