<template>
    <div class="SettingsContainer">
        <div
            class="Settings flex row-left-center"
            :class="{'closeSettings': !openSlaSettings && !openCCSettings && !openCaseCompletionSettings && !openPredictionAlgorithms,
                     'openSettings': openSlaSettings || openCCSettings || openCaseCompletionSettings || openPredictionAlgorithms }"
        >
            <WCard
                shadow
                :class="{ 'container-open': openSlaSettings}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleSLASettings ()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <WIcon
                            icon="clock"
                            color="primary"
                            :size="40"
                        />
                        <div class="flex-wrap text-card-container">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.sla.name') }}
                            </WText>
                            <WText
                                v-if="values.sla"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ values.sla.value }} {{ $t('visualization.settings.sla.units.'+values.sla.unit) }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <WIcon
                            v-if="!openSlaSettings"
                            icon="chevron-right"
                        />
                        <WIcon
                            v-if="openSlaSettings"
                            icon="chevron-left"
                        />
                    </div>
                </div>
            </WCard>
            <WCard
                shadow
                :class="{ 'container-open': openCaseCompletionSettings}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleCaseCompletionSettings ()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <div class="caseCompletionIconContainer">
                            <WIcon
                                icon="trace"
                                color="primary"
                                :size="50"
                            />
                        </div>
                        <div class="caseCompletionContainer">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.caseCompletion.name') }}
                            </WText>
                            <WText
                                v-if="values.caseCompletion && values.caseCompletion.startingActivities.length === 0
                                    && values.caseCompletion.occurringActivities.length === 0
                                    && values.caseCompletion.endingActivities.length === 0"
                                class="pl-1 textCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.caseCompletionConditionsNoConf') }}
                            </WText>
                            <WText
                                v-else
                                class="pl-1 textCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ $t('visualization.settings.caseCompletionConditionsConf') }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <WIcon
                            v-if="!openCaseCompletionSettings"
                            icon="chevron-right"
                        />
                        <WIcon
                            v-if="openCaseCompletionSettings"
                            icon="chevron-left"
                        />
                    </div>
                </div>
            </WCard>
            <WCard
                shadow
                :class="{ 'container-open': openCCSettings}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handleCCSettings ()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <WIcon
                            icon="money_increase"
                            color="primary"
                            :size="40"
                        />
                        <div class="flex-wrap">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.costs.name') }}
                            </WText>
                            <WText
                                v-if="values.cost"
                                class="pl-1 textCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ App.numberLocationFormat(values.cost.value) }} {{ $t('visualization.settings.costs.units.'+values.cost.unit) }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <WIcon
                            v-if="!openCCSettings"
                            icon="chevron-right"
                        />
                        <WIcon
                            v-if="openCCSettings"
                            icon="chevron-left"
                        />
                    </div>
                </div>
            </WCard>
            <WCard
                shadow
                :class="{ 'container-open': openPredictionAlgorithms}"
            >
                <div
                    class="CardInfo row-left-center"
                    @click="handlePredictionAlgorithm ()"
                >
                    <div
                        class="Item flex row-left-center flex-nowrap card-container"
                    >
                        <QIcon
                            size="xl"
                            color="primary"
                            name="batch_prediction"
                        />
                        <div class="flex-wrap text-card-container">
                            <WText
                                class="pl-1"
                                typo="h3"
                                tag="p"
                                weight="bold"
                            >
                                {{ $t('visualization.settings.predictionAlgorithm.name') }}
                            </WText>
                            <WText
                                v-if="values.sla"
                                class="pl-1 slaCost"
                                color="primary"
                                tag="p"
                                weight="medium"
                            >
                                {{ values.predictionAlgorithm }}
                            </WText>
                        </div>
                    </div>
                    <div class="Item rightIcon">
                        <WIcon
                            v-if="!openPredictionAlgorithms"
                            icon="chevron-right"
                        />
                        <WIcon
                            v-if="openPredictionAlgorithms"
                            icon="chevron-left"
                        />
                    </div>
                </div>
            </WCard>
        </div>
        <div>
            <SlaSettings
                ref="SlaSettings"
                class="SlaSettings"
                :isOpen="openSlaSettings"
                :values="values.sla"
                @SLAConfig="saveSettings"
            />
            <CostCurrencySettings
                ref="CostCurrencySettings"
                class="SlaSettings"
                :values="values.cost"
                :isOpen="openCCSettings"
                @CostCurrencyConfig="saveSettings"
            />
            <CaseCompletionConditions
                ref="CaseCompletionConditions"
                class="caseCompletionSettings"
                :isOpen="openCaseCompletionSettings"
                :values="values.caseCompletion"
                @CaseCompletionConditionsConfig="saveSettings"
            />
            <PredictionAlgorithmSettings
                ref="PredictionAlgorithm"
                class="SlaSettings"
                :isOpen="openPredictionAlgorithms"
                :values="{ predictionAlgorithm: values.predictionAlgorithm,
                           sequenceModelStatus: values.sequenceModelStatus,
                           durationModelStatus: values.durationModelStatus,
                }"
                @PredictionAlgorithm="saveSettings"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import SlaSettings from './SlaSettings.vue'
import CostCurrencySettings from './CostCurrencySettings.vue'
import CaseCompletionConditions from './CaseCompletionConditions.vue'
import PredictionAlgorithmSettings from './PredictionAlgorithmSettings.vue'

export default {
    name: 'SettingsModalSection',
    emits: ['SettingsConfig'],
    components: {
        SlaSettings,
        CostCurrencySettings,
        CaseCompletionConditions,
        PredictionAlgorithmSettings,
    },
    inject: ['App'],
    props: {
        values: VueTypes.object,
        title: VueTypes.string,
    },
    data () {
        return {
            inputOptions: false,
            openSlaSettings: false,
            openCCSettings: false,
            openCaseCompletionSettings: false,
            openPredictionAlgorithms: false,
            isOpen: false,
            predictionAlgorithm: null,
        }
    },
    methods: {
        saveSettings (settings) {
            console.log('Settings guardadas')
            console.log(settings)
            this.$emit('SettingsConfig', settings)
        },
        handleSLASettings () {
            this.openSlaSettings = !this.openSlaSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
        },
        handleCCSettings () {
            this.openCCSettings = !this.openCCSettings
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
        },
        handleCaseCompletionSettings () {
            this.openCaseCompletionSettings = !this.openCaseCompletionSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openPredictionAlgorithms) {
                this.openPredictionAlgorithms = false
            }
        },
        handlePredictionAlgorithm () {
            this.openPredictionAlgorithms = !this.openPredictionAlgorithms
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.arrowContainer{
    width:45%;
}
.titleContainer{
    width:65%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 10px;
}
.headerContainer{
    display:flex;
    padding-bottom: 20px;
}
.title {
text-align: center;
}
    .SettingsContainer {
        width: 100%;
        margin: 0 auto;
        padding-top: 30px;
    }
    .openSettings{
        width: 40%;;
    }
    .closeSettings{
        width:100%;
    }
    .Settings {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        float: left;
        .Buttons {
            height: 5vh;
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 10px;
        }
        .WCard {
            height: 100%;
            align-items: center;
            width: 100%;
            margin-right: 10px;
            &::v-deep .Body {
                width: 100%;
                padding: 0;
                .Item {
                    padding: 20px;
                }
            }
            .CardInfo {
                width: 100%;
                display: inline-flex;
            }
        }

        .rightIcon {
            display: flex;
            justify-content: flex-end;
        }
        .WCard:hover{
            box-shadow: 0px 0px 1px 1px $alpha-primary;
            border: solid 1px rgba(68, 68, 68, 0.2);}
        .WCard {
            background-color: #f5f5f5;
        }

        .card-container{
            width:100%;
        }
        .container-open {
            background-color: #ffffff;
            border: solid 1px rgba($black, 0.2);          }
          .caseCompletionContainer {
            margin-left: -30px;
        }
        .caseCompletionIconContainer {
            transform: translate(-15px, 0px);
        }
        @media screen and (min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $lg) {
            grid-template-columns: repeat(3, minmax(10px, 280px));
        }
        @media screen and (min-width: $xl) {
            grid-template-columns: repeat(4, minmax(10px, 280px));
        }
    }
</style>
