var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Variants" },
    [
      _c(
        "QExpansionItem",
        {
          staticStyle: { color: "var(--q-color-primary) !important" },
          attrs: {
            defaultOpened: "",
            dense: "",
            denseToggle: "",
            icon: "alt_route",
            headerStyle:
              "padding-bottom: 10px; padding-top: 8px; font-weight: 600;",
            label: _vm.$t("visualization.variants")
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-center q-my-sm" },
            [
              _c("QBtnToggle", {
                staticClass: "q-mt-sm border-toggle",
                attrs: {
                  toggleColor: "primary",
                  noCaps: "",
                  unelevated: "",
                  options: _vm.variantTabs
                },
                model: {
                  value: _vm.variantTab,
                  callback: function($$v) {
                    _vm.variantTab = $$v
                  },
                  expression: "variantTab"
                }
              })
            ],
            1
          ),
          _c(
            "QTabPanels",
            {
              attrs: {
                animated: "",
                transitionNext: "fade",
                transitionPrev: "fade"
              },
              model: {
                value: _vm.variantTab,
                callback: function($$v) {
                  _vm.variantTab = $$v
                },
                expression: "variantTab"
              }
            },
            [
              _c("QTabPanel", { attrs: { name: "FREQUENCY" } }, [
                _c("div", { staticClass: "mt-1 q-mx-sm" }, [
                  _c("div", { staticClass: "flex-row justify-between mb-1" }, [
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "6px" } },
                      [
                        _c("WText", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("visualization.availableVariants")
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("WButton", {
                          staticClass: "w-full",
                          class: { "active-filter": _vm.isVariantFilterActive },
                          staticStyle: { "justify-content": "center" },
                          attrs: {
                            size: "sm",
                            color: "secondary",
                            iconLeft: "filter",
                            iconSize: 20,
                            disabled:
                              _vm.isVariantFilterActive || _vm.disabledButton
                          },
                          on: { click: _vm.toggleFrequencyVariantFilter }
                        }),
                        _c(
                          "QTooltip",
                          {
                            staticClass: "Tooltip",
                            attrs: {
                              maxWidth: "500px",
                              contentClass: "text-primary",
                              contentStyle:
                                "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                              anchor: "center right",
                              self: "center left"
                            }
                          },
                          [
                            _c("WText", { attrs: { color: "primary" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isVariantFilterActive
                                      ? _vm.$t(
                                          "visualization.filterVariantsApplied"
                                        )
                                      : _vm.$t("visualization.filterVariants")
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "Labels flex row-stretch-top cursor-pointer"
                    },
                    [
                      _c(
                        "QTooltip",
                        {
                          attrs: {
                            anchor: "center left",
                            self: "center right",
                            offset: [10, 10],
                            contentClass: "bg-primary"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("visualization.editVariants")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "QChip",
                        {
                          attrs: {
                            square: "",
                            textColor: "white",
                            color: "primary",
                            outline: ""
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.minValue) + " ")]
                      ),
                      _c(
                        "QChip",
                        {
                          staticClass: "text-weight-bold",
                          attrs: {
                            square: "",
                            textColor: "white",
                            color: _vm.percentageColor
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatNumber(_vm.sliderValue) +
                                  " (" +
                                  _vm.percentageValue +
                                  "%)"
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "QChip",
                        {
                          attrs: {
                            square: "",
                            textColor: "white",
                            color: "secondary",
                            outline: ""
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.formatNumber(_vm.maxValue)) + " "
                          )
                        ]
                      ),
                      _c(
                        "QPopupEdit",
                        { attrs: { value: undefined } },
                        [
                          _c("QInput", {
                            ref: "textinput",
                            attrs: {
                              value: _vm.sliderValue,
                              type: "number",
                              label: _vm.$t("visualization.modelLabel"),
                              max: _vm.maxValue,
                              min: _vm.minValue,
                              disable:
                                _vm.isVariantsLoading ||
                                !_vm.minValue ||
                                _vm.disabledButton,
                              debounce: 600,
                              rules: [
                                function(value) {
                                  return (
                                    _vm.validators.minValue(
                                      value,
                                      _vm.minValue
                                    ) ||
                                    _vm.$t("validation.minValue", {
                                      attribute: _vm.$t(
                                        "visualization.variants"
                                      ),
                                      min: _vm.minValue
                                    })
                                  )
                                },
                                function(value) {
                                  return (
                                    _vm.validators.maxValue(
                                      value,
                                      _vm.maxValue
                                    ) ||
                                    _vm.$t("validation.maxValue", {
                                      attribute: _vm.$t(
                                        "visualization.variants"
                                      ),
                                      max: _vm.maxValue
                                    })
                                  )
                                }
                              ]
                            },
                            on: { input: _vm.handleChangeTextInput }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12 q-pr-md" },
                      [
                        _c("QSlider", {
                          staticClass: "q-ma-sm",
                          attrs: {
                            value: _vm.sliderValue,
                            color: _vm.percentageColor,
                            labelValue: _vm.variantLabel,
                            min: _vm.minValue,
                            max: _vm.maxValue,
                            disable:
                              _vm.isVariantsLoading ||
                              !_vm.minValue ||
                              _vm.disabledButton
                          },
                          on: {
                            change: _vm.handleChangedModel,
                            input: _vm.handleChangeSliderValue
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("QTabPanel", { attrs: { name: "INDIVIDUAL" } }, [
                _c(
                  "div",
                  { staticClass: "flex row-stretch-center flex-nowrap mt-1" },
                  [
                    _c("WText", { staticClass: "pr-0-5" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("visualization.variants")) + " "
                      )
                    ]),
                    _c("QInput", {
                      staticClass: "Search search-input float-left",
                      attrs: {
                        dense: "",
                        outlined: "",
                        type: "search",
                        disable: _vm.disabledButton,
                        label: _vm.$t("actions.search")
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSearchVariant(_vm.variantSearch)
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _vm.variantSearch
                                ? _c("WIcon", {
                                    attrs: {
                                      id: "clean",
                                      icon: "close",
                                      size: 20,
                                      clickable: ""
                                    },
                                    on: { click: _vm.handleResetVariantSearch }
                                  })
                                : _vm._e(),
                              _c("WIcon", {
                                attrs: {
                                  id: "search",
                                  icon: "search1",
                                  size: 20,
                                  clickable: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSearchVariant(
                                      _vm.variantSearch
                                    )
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.variantSearch,
                        callback: function($$v) {
                          _vm.variantSearch = $$v
                        },
                        expression: "variantSearch"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "scroll" },
                  [
                    _vm.isVariantsLoading
                      ? _c("WLoading", { attrs: { visible: "" } })
                      : _vm._l(_vm.variants, function(item) {
                          return [
                            _c(
                              "div",
                              {
                                key: item.id,
                                staticClass:
                                  "flex flex-row flex-nowrap justify-between"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "Bar mt-0-5 w-full",
                                    class: {
                                      selected:
                                        item.id === _vm.activeVariant ||
                                        _vm.selectedMultipleVariants.some(
                                          function(v) {
                                            return v.id === item.id
                                          }
                                        )
                                    },
                                    staticStyle: {
                                      "max-width": "80%!important"
                                    },
                                    attrs: {
                                      href: "#",
                                      disabled: _vm.disabledButton
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.handleChangeVariant(item)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "flex row w-full",
                                        staticStyle: { "padding-bottom": "5px" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex col-sm-8" },
                                          [
                                            _c(
                                              "WText",
                                              {
                                                attrs: {
                                                  weight: "semi",
                                                  size: 12
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.name !== undefined
                                                        ? item.name.replace(
                                                            "Variant",
                                                            _vm.$t(
                                                              "visualization.variant"
                                                            )
                                                          )
                                                        : item.name
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _vm.selectedMultipleVariants.some(
                                              function(v) {
                                                return v.id === item.id
                                              }
                                            )
                                              ? _c("QBtn", {
                                                  staticClass: "q-mr-sm",
                                                  style: { marginLeft: "5px" },
                                                  attrs: {
                                                    flat: "",
                                                    square: "",
                                                    icon: "edit",
                                                    size: "10px",
                                                    color: "white",
                                                    textColor: "primary",
                                                    padding: "0px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.handleRenameVariant(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex col-sm-4 items-end",
                                            style: { justifyContent: "end" }
                                          },
                                          [
                                            _c(
                                              "WText",
                                              {
                                                attrs: {
                                                  weight: "semi",
                                                  size: 12
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.App.numberLocationFormat(
                                                        _vm.getAbsFrequency(
                                                          item.absFrequency
                                                        ) * 100
                                                      )
                                                    ) +
                                                    "% "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("QLinearProgress", {
                                      staticClass: "LinearProgress",
                                      attrs: {
                                        size: "10px",
                                        value: _vm.getAbsFrequency(
                                          item.absFrequency
                                        )
                                      }
                                    }),
                                    _c(
                                      "QTooltip",
                                      {
                                        staticClass: "Tooltip",
                                        attrs: {
                                          maxWidth: "500px",
                                          contentClass: "text-primary",
                                          contentStyle:
                                            "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                                          anchor: "center right",
                                          self: "center left"
                                        }
                                      },
                                      [
                                        _c(
                                          "WText",
                                          { attrs: { color: "primary" } },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.name !== undefined
                                                      ? item.name.replace(
                                                          "Variant",
                                                          _vm.$t(
                                                            "visualization.variant"
                                                          )
                                                        )
                                                      : item.name
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _vm._v(
                                              ": " +
                                                _vm._s(item.absFrequency) +
                                                " " +
                                                _vm._s(
                                                  _vm.$t("visualization.cases")
                                                ) +
                                                " (" +
                                                _vm._s(
                                                  _vm._f("percentage")(
                                                    _vm.getAbsFrequency(
                                                      item.absFrequency
                                                    )
                                                  )
                                                ) +
                                                "%) "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                !_vm.checkVariantIsSelected(item.id)
                                  ? _c("WButton", {
                                      staticClass: "ml-1 self-end fit-content",
                                      attrs: {
                                        color: "secondary",
                                        iconRight: "plus",
                                        iconSize: 12
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAddMultipleVariant(
                                            item
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm.checkVariantIsSelected(item.id)
                                  ? _c("WButton", {
                                      staticClass: "ml-1 self-end fit-content",
                                      attrs: {
                                        color: "secondary",
                                        iconRight: "minus",
                                        iconSize: 12
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDeleteMultipleVariant(
                                            item
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }),
                    !_vm.isVariantsLoading && !_vm.showVariants
                      ? _c(
                          "div",
                          { staticClass: "flex col-center mt-2" },
                          [
                            _c("Badge", {
                              staticClass: "row-center pt-0-5 pb-0-5 mb-0-5",
                              attrs: {
                                icon: "alert-triangle",
                                label: _vm.$t(
                                  "visualization.notifications.notFound.text",
                                  { type: _vm.$t("visualization.variants") }
                                ),
                                color: "warning",
                                textColor: "black"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _vm.showVariants
                  ? _c(
                      "div",
                      { staticClass: "flex flex-col row-center pt-1" },
                      [
                        _c("QPagination", {
                          attrs: {
                            max: _vm.variantPagination.max,
                            directionLinks: true,
                            disabled: _vm.disabledButton,
                            maxPages: 4,
                            size: "10px",
                            boundaryNumbers: true
                          },
                          on: { input: _vm.handleChangeVariantsWithPagination },
                          model: {
                            value: _vm.variantPagination.start,
                            callback: function($$v) {
                              _vm.$set(_vm.variantPagination, "start", $$v)
                            },
                            expression: "variantPagination.start"
                          }
                        }),
                        _c("div", { staticClass: "mt-1 flex-row self-end" }, [
                          _c(
                            "div",
                            [
                              _c("WButton", {
                                staticClass: "w-full",
                                class: {
                                  "active-filter": _vm.isVariantFilterActive
                                },
                                staticStyle: { "justify-content": "center" },
                                attrs: {
                                  size: "sm",
                                  color: "secondary",
                                  iconLeft: "filter",
                                  iconSize: 20,
                                  disabled:
                                    _vm.isVariantFilterActive ||
                                    _vm.disabledButton
                                },
                                on: { click: _vm.toggleIndividualVariantFilter }
                              }),
                              _c(
                                "QTooltip",
                                {
                                  staticClass: "Tooltip",
                                  attrs: {
                                    maxWidth: "500px",
                                    contentClass: "text-primary",
                                    contentStyle:
                                      "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                                    anchor: "center right",
                                    self: "center left"
                                  }
                                },
                                [
                                  _c("WText", { attrs: { color: "primary" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isVariantFilterActive
                                            ? _vm.$t(
                                                "visualization.filterVariantsApplied"
                                              )
                                            : _vm.$t(
                                                "visualization.filterVariants"
                                              )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "WButton",
                                {
                                  staticClass: "w-full",
                                  staticStyle: { "justify-content": "center" },
                                  attrs: { size: "sm", color: "secondary" },
                                  on: { click: _vm.handleResetMultipleVariants }
                                },
                                [
                                  _c("QIcon", {
                                    attrs: { name: "history", size: "24px" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "QTooltip",
                                {
                                  staticClass: "Tooltip",
                                  attrs: {
                                    maxWidth: "500px",
                                    contentClass: "text-primary",
                                    contentStyle:
                                      "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                                    anchor: "center right",
                                    self: "center left"
                                  }
                                },
                                [
                                  _c("WText", { attrs: { color: "primary" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.resetMultipleVariants"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-row my-1" },
                          [
                            _vm._l(_vm.selectedMultipleVariants, function(
                              multipleVariant,
                              index
                            ) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "multipleVariant.name-" + index,
                                    staticStyle: { "max-width": "200px" }
                                  },
                                  [
                                    _c(
                                      "QChip",
                                      {
                                        attrs: {
                                          removable: "",
                                          dense: "",
                                          size: "md",
                                          color: "primary",
                                          textColor: "white"
                                        },
                                        on: {
                                          remove: function($event) {
                                            return _vm.handleDeleteMultipleVariant(
                                              multipleVariant
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "ellipsis" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.selectedMultipleVariants.find(
                                                  function(v) {
                                                    return (
                                                      v.id ===
                                                      multipleVariant.id
                                                    )
                                                  }
                                                ).name
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("Divider"),
      _c(
        "QExpansionItem",
        {
          ref: "loopExpansionItem",
          staticStyle: { color: "var(--q-color-primary) !important" },
          attrs: {
            dense: "",
            denseToggle: "",
            icon: "360",
            headerStyle:
              "padding-bottom: 10px; padding-top: 10px; font-weight: 600;",
            label: _vm.$t("visualization.nLoops")
          }
        },
        [
          _c(
            "div",
            { staticClass: "flex row-stretch-center flex-nowrap mt-1" },
            [
              _c("WText", { staticClass: "pr-0-5" }, [
                _vm._v(" " + _vm._s(_vm.$t("visualization.nLoops")) + " ")
              ]),
              _c("QInput", {
                staticClass: "Search search-input float-left",
                attrs: {
                  dense: "",
                  outlined: "",
                  type: "search",
                  label: _vm.$t("actions.search"),
                  disable: _vm.disabledButton
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSearchLoops(_vm.loopSearch)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _vm.loopSearch
                          ? _c("WIcon", {
                              attrs: {
                                id: "clean",
                                icon: "close",
                                size: 20,
                                clickable: ""
                              },
                              on: { click: _vm.handleResetLoopSearch }
                            })
                          : _vm._e(),
                        _c("WIcon", {
                          attrs: {
                            id: "search",
                            icon: "search1",
                            size: 20,
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSearchLoops(_vm.loopSearch)
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.loopSearch,
                  callback: function($$v) {
                    _vm.loopSearch = $$v
                  },
                  expression: "loopSearch"
                }
              })
            ],
            1
          ),
          _c("Tabs", {
            staticClass: "Tabs",
            attrs: { tabs: _vm.loopTabs, horizontal: "" },
            on: { input: _vm.handleChangeLoopTab },
            model: {
              value: _vm.loopTab,
              callback: function($$v) {
                _vm.loopTab = $$v
              },
              expression: "loopTab"
            }
          }),
          _c(
            "QTabPanels",
            {
              model: {
                value: _vm.loopTab,
                callback: function($$v) {
                  _vm.loopTab = $$v
                },
                expression: "loopTab"
              }
            },
            [
              _c(
                "QTabPanel",
                {
                  staticClass: "model-tab-panel",
                  attrs: { name: _vm.loopTab }
                },
                [
                  _c(
                    "div",
                    { staticClass: "scroll" },
                    [
                      _vm.isLoopsLoading
                        ? _c("WLoading", { attrs: { visible: "" } })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showLoops,
                              expression: "showLoops"
                            }
                          ]
                        },
                        _vm._l(_vm.loops, function(item) {
                          return _c(
                            "a",
                            {
                              key: item.id,
                              staticClass: "Bar mt-0-5",
                              class: { selected: item.id === _vm.activeLoopId },
                              attrs: {
                                disabled: _vm.disabledButton,
                                href: "#"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.handleChangeLoop(item.id)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex row",
                                  staticStyle: { "padding-bottom": "5px" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex col-sm-9" },
                                    [
                                      _c(
                                        "WText",
                                        { attrs: { weight: "semi", size: 12 } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.name !== undefined
                                                  ? item.name.replace(
                                                      "Loop",
                                                      _vm.$t(
                                                        "visualization.loop"
                                                      )
                                                    )
                                                  : item.name
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _vm.loopVisible &&
                                      item.id === _vm.activeLoopId
                                        ? _c("QBtn", {
                                            staticClass: "q-mr-sm",
                                            style: { marginLeft: "8px" },
                                            attrs: {
                                              flat: "",
                                              square: "",
                                              icon: "visibility_off",
                                              size: "10px",
                                              color: "white",
                                              textColor: "primary",
                                              padding: "0px",
                                              disable:
                                                item.id !== _vm.activeLoopId
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.handleHideLoop()
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.loopVisible &&
                                      item.id === _vm.activeLoopId
                                        ? _c("QBtn", {
                                            staticClass: "q-mr-sm",
                                            style: { marginLeft: "8px" },
                                            attrs: {
                                              flat: "",
                                              square: "",
                                              icon: "visibility",
                                              size: "10px",
                                              color: "white",
                                              textColor: "primary",
                                              padding: "0px",
                                              disable:
                                                item.id !== _vm.activeLoopId
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.handleShowLoop()
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex col-sm-3 items-end",
                                      style: { justifyContent: "end" }
                                    },
                                    [
                                      _c(
                                        "WText",
                                        { attrs: { weight: "semi", size: 12 } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.App.numberLocationFormat(
                                                  _vm.getAbsFrequency(
                                                    item.absFrequency
                                                  ) * 100
                                                )
                                              ) +
                                              "% "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c("QLinearProgress", {
                                staticClass: "LinearProgress",
                                attrs: {
                                  size: "10px",
                                  value: _vm.getAbsFrequency(item.absFrequency)
                                }
                              }),
                              _c(
                                "QTooltip",
                                {
                                  staticClass: "Tooltip",
                                  attrs: {
                                    maxWidth: "500px",
                                    contentClass: "text-primary",
                                    contentStyle:
                                      "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                                    anchor: "center right",
                                    self: "center left"
                                  }
                                },
                                [
                                  _c("WText", { attrs: { color: "primary" } }, [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.name !== undefined
                                              ? item.name.replace(
                                                  "Loop",
                                                  _vm.$t("visualization.loop")
                                                )
                                              : item.name
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _vm._v(
                                      ": " +
                                        _vm._s(
                                          _vm.$t("visualization.loopsFound")
                                        ) +
                                        " " +
                                        _vm._s(item.absFrequency) +
                                        " " +
                                        _vm._s(_vm.$t("visualization.cases")) +
                                        " (" +
                                        _vm._s(
                                          _vm._f("percentage")(
                                            _vm.getAbsFrequency(
                                              item.absFrequency
                                            )
                                          )
                                        ) +
                                        "%) "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      !_vm.showLoops
                        ? _c(
                            "div",
                            { staticClass: "flex col-center mt-2 mb-1" },
                            [
                              _c("Badge", {
                                staticClass: "row-center pt-0-5 pb-0-5 mb-0-5",
                                attrs: {
                                  icon: "alert-triangle",
                                  label: _vm.$t(
                                    "visualization.notifications.notFound.text",
                                    { type: _vm.$t("visualization.loops") }
                                  ),
                                  color: "warning",
                                  textColor: "black"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.showLoops
                    ? _c(
                        "div",
                        { staticClass: "flex row-center pt-1 mb-1" },
                        [
                          _c("QPagination", {
                            attrs: {
                              max: _vm.loopPagination.max,
                              directionLinks: true,
                              maxPages: 4,
                              disable: _vm.disabledButton,
                              size: "11.5px",
                              boundaryNumbers: true
                            },
                            on: { input: _vm.handleChangeLoopPagination },
                            model: {
                              value: _vm.loopPagination.start,
                              callback: function($$v) {
                                _vm.$set(_vm.loopPagination, "start", $$v)
                              },
                              expression: "loopPagination.start"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }