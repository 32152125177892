<template>
    <WCard
        bordered
        class="Password mb-2"
    >
        <WText
            :size="18"
            class="mb-1"
        >
            {{ $t('settings.subscription.text') }}
        </WText>

        <div class="Wrapper">
            <WText
                class="pb-0-5 mb-1"
                v-html="isActiveSubscription ? $t('settings.subscription.active.yes') : $t('settings.subscription.active.no')"
            />
            <div class="flex row-left-center">
                <WButton
                    :loading="loadingPayment"
                    :disabled="loadingPayment"
                    @click="handlePaymentClick"
                >
                    {{ isActiveSubscription ? $t('settings.subscription.goTo') : $t('settings.subscription.reactivate') }}
                </WButton>
                <WButton
                    v-if="isActiveSubscription"
                    class="ml-1"
                    outline
                    :loading="loadingCancel"
                    :disabled="loadingCancel"
                    @click="handleCancelSubscription"
                >
                    {{ $t('settings.subscription.cancel') }}
                </WButton>
            </div>
        </div>
        <ModalRequest
            ref="recoveryDialog"
            :title="$t('settings.subscription.modal.title')"
            :text="$t('settings.subscription.modal.text')"
            @onAccept="handleAcceptCancel"
        />
    </WCard>
</template>

<script>
import VueTypes from 'vue-types'
import { ModalRequest } from '@/components'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'

export default {
    name: 'Payments',
    components: {
        ModalRequest,
    },
    props: {
        value: VueTypes.shape({
            subscription: VueTypes.shape({
                active: VueTypes.bool.def(false),
            }).loose,
        }).def({}).loose,
    },
    data () {
        return {
            loadingPayment: false,
            loadingCancel: false,
        }
    },
    computed: {
        isActiveSubscription () {
            const { subscription } = this.value
            return subscription?.active
        },
    },
    methods: {
        handlePaymentClick () {
            const { isActiveSubscription } = this
            if (isActiveSubscription) this.fetchPortalSession()
            else this.fetchActivateSubscription()
        },
        async fetchPortalSession () {
            this.loadingPayment = true
            apiRequest(Api().payments.portalSession())
                .then(({ url }) => { window.location.href = url })
                .catch(notifyError)
                .finally(() => (this.loadingPayment = false))
        },
        async fetchActivateSubscription () {
            this.loadingPayment = true
            apiRequest(Api().payments.activateSubscription())
                .then(() => {
                    this.$emit('onUpdatedSubscription')
                    notifySuccess(this.$t('settings.subscription.notifications.reactivate'))
                })
                .catch(notifyError)
                .finally(() => (this.loadingPayment = false))
        },
        handleAcceptCancel () {
            this.loadingCancel = true
            apiRequest(Api().payments.cancelSubscription())
                .then(() => {
                    this.$refs.recoveryDialog.close()
                    this.$emit('onUpdatedSubscription')
                    notifySuccess(this.$t('settings.subscription.notifications.cancel'))
                })
                .catch(notifyError)
                .finally(() => (this.loadingCancel = false))
        },
        handleCancelSubscription () {
            this.$refs.recoveryDialog.open()
        },

    },
}
</script>
