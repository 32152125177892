<template>
    <div>
        <div
            class="Visualization Container flex row-stretch"
        >
            <Sidebar
                ref="Sidebar"
                :showAvatar="false"
                :addMode="openFilterModal"
                :infoTotal="infoTotal"
                :infoFiltered="infoFiltered"
                :displayedInfo="displayedInfo"
                :processId="processId"
                :readOnly="!disabledButton"
                @onManageFilter="handleManageFilters"
                @onDeleteClick="handleDeleteFilter"
                @onApply="handleApplyFilters"
                @onReplaceFilters="handleApplyFilters"
                @onCancel="handleCancelAddFilters"
                @onReset="handleResetFilters"
            />
            <div class="Screen pos-r flex-1">
                <QTabs
                    v-model="tab"
                    align="left"
                    dense
                    noCaps
                    activeColor="primary"
                    indicatorColor="primary"
                    class="Tabs text-grey-8"
                    @input="handleChangeTab"
                >
                    <QTab
                        v-for="(crtab,idx) in getTabs"
                        :key="idx"
                        :name="crtab.id"
                        :label="crtab.label"
                        :icon="crtab.icon"
                    >
                        <QTooltip
                            contentClass="bg-grey-3 text-black"
                        >
                            {{ crtab.tooltip }}
                        </QTooltip>
                    </QTab>
                </QTabs>
                <DataNavigator
                    v-if="tab === 'DASHBOARD'"
                    class="DataNavigator"
                    :processId="processId"
                />
                <Alerts
                    v-else-if="tab === 'ALERTS'"
                    :processId="processId"
                    class="Wrapper"
                />
                <ContextComparison
                    v-else-if="tab === 'COMPARISON'"
                    :processId="processId"
                    :filteredInfo="infoFiltered"
                    :sections="sectionsContext"
                    class="Wrapper"
                    @sectionList="updateSectionsList"
                />
                <div
                    v-else
                    id="view-wrapper"
                    class="Wrapper flex row-center-top"
                >
                    <TracesSection
                        v-if="tab === 'TRACES'"
                        class="TracesSection"
                        :processId="processId"
                        :filteredTraces="filteredTraces"
                        :attributes="attributes"
                    />
                    <Conformance
                        v-else-if="tab === 'CONFORMANCE'"
                        class="Conformance"
                        :processId="processId"
                        :projectId="$route.params.projectId"
                        :attributes="attributes"
                    />
                    <Predictions
                        v-else-if="tab === 'PREDICTIONS'"
                        ref="Predictions"
                        v-model="model"
                        :processId="processId"
                    />
                    <Assistant
                        v-else-if="tab === 'ASSISTANT'"
                        :processId="processId"
                        class="Wrapper"
                    />
                    <SettingsModal
                        v-else-if="tab === 'SETTINGS'"
                        ref="SettingsModal"
                        :processId="processId"
                        :settings="settings"
                        @settingsSaved="getCosts"
                    />
                    <div
                        v-else
                        ref="mainContainer"
                        class="flex w-full ChargingHeight"
                    >
                        <div
                            ref="panelsContainer"
                            class="FixedPanels column q-gutter-xs"
                        >
                            <ChartContextualInfo
                                class="FixedBar col"
                                :totalInfo="infoTotal"
                                :filteredInfo="infoFiltered"
                                :displayedInfo="displayedInfo"
                                :percentageMode="percentageDisplay.value"
                                :currency="currency"
                                :costPerHour="costPerHour"
                            />
                            <LoopMetricsPanel
                                v-if="(tab==='LOOPS')"
                                v-show="toggleLoopMetrics"
                                class="FixedBar col"
                                :displayedInfo="displayedInfo"
                                :chartInfo="chart"
                                :currency="currency"
                                :costPerHour="costPerHour"
                            />
                            <CustomPlotPanels
                                v-if="(attributes && chart)"
                                :attributes="attributes"
                                :processId="processId"
                                :chart="chart"
                                :title="attributePanelOption"
                                :filters="formatFiltersParam(visualizationFilters)"
                                @titleSel="changeOptionPanel1"
                            />
                        </div>
                        <div class="ChartContainer flex-1">
                            <div class="Actions flex row-stretch-center">
                                <div
                                    v-if="chart"
                                    class="flex row-stretch-center"
                                >
                                    <QBtn
                                        v-if="tab==='LOOPS'"
                                        icon="rotate_left"
                                        unelevated
                                        :color="toggleLoopMetrics ? 'primary' : 'white'"
                                        :textColor="toggleLoopMetrics ? 'white' : 'primary'"
                                        class="q-mr-sm"
                                        @click="toggleLoopMetricsPanel"
                                    >
                                        <QTooltip
                                            contentClass="bg-grey-3 text-black"
                                        >
                                            {{ $t('visualization.showLoopMetrics') }}
                                        </QTooltip>
                                    </QBtn>
                                    <QFab
                                        ref="percentageFab"
                                        label="%"
                                        labelPosition="left"
                                        flat
                                        square
                                        :icon="percentageDisplay.icon"
                                        :activeIcon="percentageDisplay.icon"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        @click="toggleFab('percentageFab')"
                                    >
                                        <template v-slot:tooltip>
                                            <QTooltip
                                                contentClass="bg-grey-3 text-black"
                                            >
                                                {{ $t('visualization.changePercentage') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in percOptions"
                                            :key="idx"
                                            color="grey-2"
                                            textColor="primary"
                                            :icon="action.icon"
                                            :label="$t(action.label)"
                                            labelPosition="left"
                                            externalLabel
                                            @click="setPercentageMode(action)"
                                        />
                                    </QFab>
                                    <QFab
                                        ref="directionFab"
                                        flat
                                        square
                                        :icon="directionDisplay.icon"
                                        :activeIcon="directionDisplay.icon"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        @click="toggleFab('directionFab')"
                                    >
                                        <template v-slot:tooltip>
                                            <QTooltip
                                                contentClass="bg-grey-3 text-black"
                                            >
                                                {{ $t('visualization.changeGraphDirection') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in directionOptions"
                                            :key="idx"
                                            color="grey-2"
                                            textColor="primary"
                                            :icon="action.icon"
                                            @click="setDirectionMode(action)"
                                        />
                                    </QFab>
                                    <QFab
                                        ref="statsFab"
                                        labelPosition="left"
                                        flat
                                        square
                                        :label="statsDisplay.abbreviation"
                                        icon="schedule"
                                        activeIcon="schedule"
                                        color="grey-2"
                                        textColor="primary"
                                        direction="down"
                                        padding="sm"
                                        class="q-mr-sm"
                                        @click="toggleFab('statsFab')"
                                    >
                                        <template v-slot:tooltip>
                                            <QTooltip
                                                contentClass="bg-grey-3 text-black"
                                            >
                                                {{ $t('visualization.changeGraphMetric') }}
                                            </QTooltip>
                                        </template>
                                        <QFabAction
                                            v-for="(action,idx) in statsOptions"
                                            :key="idx"
                                            labelPosition="left"
                                            :label="action.label"
                                            color="grey-2"
                                            textColor="primary"
                                            @click="setStatsMode(action)"
                                        />
                                    </QFab>
                                    <QBtn
                                        icon="adjust"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="handleCenterChart"
                                    >
                                        <QTooltip
                                            contentClass="bg-grey-3 text-black"
                                        >
                                            {{ $t('visualization.centerChart') }}
                                        </QTooltip>
                                    </QBtn>
                                    <QBtn
                                        icon="schema"
                                        unelevated
                                        color="white"
                                        textColor="primary"
                                        class="q-mr-sm"
                                        @click="saveBpmnModel"
                                    >
                                        <QTooltip
                                            contentClass="bg-grey-3 text-black"
                                        >
                                            {{ $t('visualization.bpmnSave') }}
                                        </QTooltip>
                                    </QBtn>
                                </div>
                            </div>
                            <WLoading
                                v-if="!settedChart || variantsLoading"
                                visible
                            />
                            <Graph
                                v-else-if="chart && (chart.activities || []).length && (Object.keys(chart) || []).length"
                                ref="chart"
                                class="Graph"
                                :data="chart"
                                :model="model"
                                :color="color"
                                parent="view-wrapper"
                            />
                            <div
                                v-else
                                class="EmptyModel"
                            >
                                {{ $t('visualization.filters.emptyModel') }}
                            </div>
                        </div>
                        <div
                            class="Options"
                            :class="{ visible: visibleOptions }"
                        >
                            <QTabPanels
                                v-if="processId"
                                v-model="tab"
                                animated
                                transitionNext="fade"
                                transitionPrev="fade"
                            >
                                <QTabPanel name="MODEL">
                                    <Variants
                                        ref="MODEL"
                                        v-model="model"
                                        :processId="processId"
                                        :info="infoTotal"
                                        :filteredInfo="infoFiltered"
                                        :percentageMode="percentageDisplay.value"
                                        :disabledButton="!disabledButton"
                                        @onCenterChart="handleCenterChart"
                                        @onLoadChart="handleSetChart"
                                        @onColorLoops="handleColorChartLoops"
                                        @onReset="handleResetModel"
                                        @variantsLoading="loadingControl"
                                        @onOpenRenameModal="handleOpenRenameModal"
                                        @onChangedVariant="settedChart = false"
                                        @onChangedVariantTab="handleChangeVariantTab"
                                    />
                                </QTabPanel>
                                <QTabPanel name="LOOPS">
                                    <Loops
                                        v-model="model"
                                        :processId="processId"
                                        :info="infoTotal"
                                        :filteredInfo="infoFiltered"
                                        :percentageMode="percentageDisplay.value"
                                        :disabledButton="!disabledButton"
                                        @onCenterChart="handleCenterChart"
                                        @onLoadChart="handleSetChart"
                                        @onReset="handleResetModel"
                                        @variantsLoading="loadingControl"
                                    />
                                </QTabPanel>
                            </QTabPanels>
                        </div>
                    </div>
                </div>
                <FilterModal
                    v-if="processId"
                    ref="FilterModal"
                    :processId="processId"
                    :attributes="attributes"
                    :isOpen="openFilterModal"
                    @close="handleCancelAddFilters"
                    @onSaveFilter="handleAddFilter"
                />
            </div>
            <CreateModel
                ref="modelModal"
                @save="generateModel"
            />
            <RenameModel
                ref="renameVariantModal"
                @save="renameVariant"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import Graph from '@/components/Graph/Graph.vue'
import { filtersStorageMixin } from '@/mixins'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import CreateModel from './components/CreateModel.vue'
import RenameModel from './components/RenameModel.vue'
import ChartContextualInfo from './components/Contextualnfo/ChartContextualInfo.vue'
import LoopMetricsPanel from './components/Contextualnfo/LoopMetricsPanel.vue'

import {
    Sidebar,
    Alerts,
    Variants,
    Loops,
    DataNavigator,
    FilterModal,
    Conformance,
    SettingsModal,
    Predictions,
    Assistant,
} from './sections'
import ContextComparison from './sections/ContextComparison/ContextComparison.vue'
import TracesSection from './sections/Traces/TracesSection.vue'
import {
    ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS,
} from './sections/FilterModal/filtersEntity'
import CustomPlotPanels from './components/Contextualnfo/CustomPlotPanels.vue'

const DEFAULT_TAB = 'MODEL'
const DEFAULT_PERCENTAGE_MODE = {
    label: 'visualization.percentageTotal',
    value: 'TOTAL',
    icon: 'storage',
}
const DEFAULT_CURRENCY = {
    value: 'euro',
    label: 'Euro (€)',
}
const DEFAULT_COST_PER_HOUR = 0
const INITIAL_MODEL = {
    variant: 1,
    variants: 1,
    heatMapValue: 'none',
    directionValue: 'TB',
    statsValue: 'averages',
    values: 'both',
}
const EMPTY_MODEL_INFO = {
    activities: undefined,
    cases: undefined,
    duration: undefined,
    maxEndDateTime: '',
    minStartDateTime: '',
    variants: undefined,
}
const DIRECTION_ICONS = {
    horizontal: 'east',
    vertical: 'south',
}
const DEFAULT_DIRECTION = {
    value: 'TB',
    icon: DIRECTION_ICONS.vertical,
}
const DEFAULT_VARIANT_TAB = 'FREQUENCY'

export default {
    components: {
        Alerts,
        Loops,
        TracesSection,
        Conformance,
        Variants,
        Sidebar,
        Graph,
        DataNavigator,
        FilterModal,
        CreateModel,
        RenameModel,
        ChartContextualInfo,
        LoopMetricsPanel,
        SettingsModal,
        Predictions,
        ContextComparison,
        CustomPlotPanels,
        Assistant,
    },
    mixins: [filtersStorageMixin],
    props: {
        process: VueTypes.any,
    },
    data () {
        return {
            panelsContainerObserver: undefined,
            resizeTimeout: null,
            processId: undefined,
            chart: undefined,
            color: undefined,
            settedChart: true,
            disabledButton: true,
            variantsLoading: false,
            variantRenamedId: undefined,
            tab: DEFAULT_TAB,
            variantTab: DEFAULT_VARIANT_TAB,
            model: INITIAL_MODEL,
            openFilterModal: false,
            visibleOptions: false,
            imageTemp: undefined,
            attributes: undefined,
            info: undefined,
            infoTotal: undefined,
            infoFiltered: undefined,
            displayedInfo: undefined,
            filteredTraces: 0,
            percentageDisplay: DEFAULT_PERCENTAGE_MODE,
            directionDisplay: DEFAULT_DIRECTION,
            statsDisplay: {
                abbreviation: 'avg',
                value: 'averages',
                label: this.$t('visualization.filters.contextualInfo.avg'),
            },
            toggleLoopMetrics: true,
            currency: DEFAULT_CURRENCY,
            costPerHour: DEFAULT_COST_PER_HOUR,
            settings: undefined,
            loading: true,
            showPredictions: true,
            sectionsContext: [],
            comparationFilters: [],
            currentFilters: [],
            attributePanelOption: { key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), icon: 'query_stats' },
            frequencyPanelOption: { key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), icon: 'timelapse' },
            activeFab: null,
        }
    },
    computed: {
        getTabs () {
            return [
                {
                    id: 'MODEL',
                    label: this.$t('visualization.tabs.process'),
                    icon: 'account_tree',
                    tooltip: this.$t('visualization.tabs.tooltip.process'),
                },
                {
                    id: 'LOOPS',
                    label: this.$t('visualization.tabs.loops'),
                    icon: '360',
                    tooltip: this.$t('visualization.tabs.tooltip.loops'),
                },
                {
                    id: 'CONFORMANCE',
                    label: this.$t('visualization.tabs.conformance'),
                    icon: 'fact_check',
                    tooltip: this.$t('visualization.tabs.tooltip.conformance'),
                },
                {
                    id: 'DASHBOARD',
                    label: this.$t('visualization.tabs.metrics'),
                    icon: 'bar_chart',
                    tooltip: this.$t('visualization.tabs.tooltip.metrics'),
                },
                {
                    id: 'COMPARISON',
                    label: this.$t('visualization.tabs.contextComparison'),
                    icon: 'compare',
                    tooltip: this.$t('visualization.tabs.tooltip.contextComparison'),
                },
                {
                    id: 'PREDICTIONS',
                    label: this.$t('visualization.tabs.predictions'),
                    icon: 'monitor_heart',
                    tooltip: this.$t('visualization.tabs.tooltip.predictions'),
                },
                {
                    id: 'ALERTS',
                    label: this.$t('visualization.tabs.alerts'),
                    icon: 'notifications',
                    tooltip: this.$t('visualization.tabs.tooltip.alerts'),
                },
                {
                    id: 'TRACES',
                    label: this.$t('visualization.tabs.traces'),
                    icon: 'list',
                    tooltip: this.$t('visualization.tabs.tooltip.traces'),
                },
                {
                    id: 'ASSISTANT',
                    label: this.$t('visualization.tabs.assistant'),
                    icon: 'psychology',
                    tooltip: this.$t('visualization.tabs.tooltip.assistant'),
                },
                {
                    id: 'SETTINGS',
                    label: this.$t('visualization.tabs.settings'),
                    icon: 'settings',
                    tooltip: this.$t('visualization.tabs.tooltip.settings'),
                },
            ]
        },
        percOptions () {
            return [
                {
                    label: 'visualization.percentageTotal',
                    value: 'TOTAL',
                    icon: 'storage',
                },
                {
                    label: 'visualization.percentageFiltered',
                    value: 'FILTERED',
                    icon: 'filter_alt',
                },
            ]
        },
        curOptions () {
            return [
                {
                    label: 'Euro (€)',
                    value: 'euro',
                },
                {
                    label: 'Dollar ($)',
                    value: 'dollar',
                },
                {
                    label: 'Pound (£)',
                    value: 'pound',
                },
            ]
        },
        directionOptions () {
            return [
                {
                    value: 'LR',
                    icon: DIRECTION_ICONS.horizontal,
                },
                {
                    value: 'TB',
                    icon: DIRECTION_ICONS.vertical,
                },
            ]
        },
        statsOptions () {
            return [
                {
                    abbreviation: 'sd',
                    value: 'variances',
                    label: this.$t('visualization.filters.contextualInfo.sd'),
                },
                {
                    abbreviation: 'avg',
                    value: 'averages',
                    label: this.$t('visualization.filters.contextualInfo.avg'),
                },
                {
                    abbreviation: 'median',
                    value: 'median',
                    label: this.$t('visualization.filters.contextualInfo.med'),
                },
                {
                    abbreviation: 'min',
                    value: 'min',
                    label: this.$t('visualization.filters.contextualInfo.min'),
                },
                {
                    abbreviation: 'max',
                    value: 'max',
                    label: this.$t('visualization.filters.contextualInfo.max'),
                },
            ]
        },
    },
    watch: {
        tab (a) {
            if (a === 'MODEL' || a === 'LOOPS') {
                this.panelsContainerObserver = new ResizeObserver(this.onResize)
                setTimeout(() => {
                    if (this.$refs.panelsContainer) {
                        this.panelsContainerObserver.observe(this.$refs.panelsContainer)
                    }
                }, 50)
            }
        },
    },
    mounted () {
        const { processId, tab } = this.$route.params
        this.getModelInfoFiltered()
        this.getTotalInfo()
        this.processId = processId
        this.getConfigurations()
        this.getCosts()
        if (tab) {
            this.tab = tab
        }
        if (this.tab === 'MODEL' || this.tab === 'LOOPS') {
            this.panelsContainerObserver = new ResizeObserver(this.onResize)
            setTimeout(() => {
                if (this.$refs.panelsContainer) {
                    this.panelsContainerObserver.observe(this.$refs.panelsContainer)
                }
            }, 50)
        }
    },
    beforeDestroy () {
        this.panelsContainerObserver.unobserve(this.$refs.panelsContainer)
        clearTimeout(this.resizeTimeout)
    },
    methods: {
        onResize () {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout)
            }
            this.resizeTimeout = setTimeout(() => {
                if (this.$refs.mainContainer) {
                    this.$refs.mainContainer.style.height = `${this.$refs.panelsContainer.offsetHeight + 20}px`
                }
            }, 50)
        },
        handleSetChart (data) {
            this.disabledButton = false
            this.getModelInfoDisplayed({ variantCount: data?.variants, variantUid: data?.variantId, loopUid: data?.loopId })
            const formattedData = this.formatChartData(data)
            this.chart = data ? { ...formattedData } : undefined
            this.color = undefined
        },
        handleColorChartLoops (data) {
            if (data !== undefined) {
                const formattedData = this.formatChartData(data)
                this.color = data ? { ...formattedData } : undefined
            } else {
                this.color = undefined
            }
        },
        formatChartData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },
        formatAttributes (attributes = {}) {
            return [...Object.keys(attributes)].map(key => ({ label: key, value: key }))
        },
        handleCenterChart () {
            const { chart } = this.$refs
            if (chart) chart.centerGraph()
        },
        handleChangeTab (tab) {
            const { tab: actualTab } = this.$route.params
            if (tab === actualTab) return
            if (tab) {
                this.$router.replace({ ...this.$router, params: { tab } }).catch(() => {})
            }
            this.settedChart = false
            if (tab !== 'MODEL' && tab !== 'LOOPS') {
                this.disabledButton = true
            } else {
                this.disabledButton = false
            }
            this.handleResetModel()
        },
        handleResetModel () {
            this.model = { ...INITIAL_MODEL }
            this.model.statsValue = this.statsDisplay.value
        },
        handleAddFilter (selected) {
            if (ACTIVITY_FILTERS.includes(selected.id)) {
                const activityFilter = {
                    ...selected,
                    key: selected.type.category,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(activityFilter)
            }
            if (DATE_FILTERS.includes(selected.id)) {
                const dateFilter = {
                    ...selected,
                    key: selected.type.date,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(dateFilter)
            }
            if (DURATION_FILTERS.includes(selected.id)) {
                const durationFilter = {
                    ...selected,
                    key: selected.type.duration,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(durationFilter)
            }
            if (ARC_FILTERS.includes(selected.id)) {
                const arcFilter = {
                    ...selected,
                    key: selected.type.arc,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(arcFilter)
            }
            if (ATTRIBUTES_FILTERS.includes(selected.type)) {
                const attributesFilter = selected.attributes.map((attr, i) => ({
                    ...attr,
                    value: attr.value,
                    label: `${attr.attribute.value} - ${attr.constraints.label}`,
                    id: selected.type,
                    uuid: `${selected.type}_${Math.floor(Math.random() * 10000)}_${i}`,
                }))
                this.addMultipleFilters(attributesFilter)
            }
            if (VARIANT_FILTERS.includes(selected.id)) {
                const variantFilter = {
                    ...selected,
                    key: selected.type.variant,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(variantFilter)
            }
            if (LOOP_FILTERS.includes(selected.id)) {
                const variantFilter = {
                    ...selected,
                    key: selected.type.variant,
                    uuid: `${selected.id}_${Math.floor(Math.random() * 10000)}`,
                }
                this.addVisualizationFilters(variantFilter)
            }
            this.handleApplyFilters()
        },
        handleManageFilters () {
            this.openFilterModal = !this.openFilterModal
        },
        handleDeleteFilter (filter) {
            this.updateFilters(filter)
            this.handleApplyFilters()
            this.$root.$emit('checkForVariantFilter')
        },
        handleCancelAddFilters () {
            this.openFilterModal = false
            this.handleApplyFilters()
        },
        handleResetFilters () {
            this.setVisualizationFilters([])
            this.handleApplyFilters()
        },
        async handleApplyFilters () {
            this.openFilterModal = false
            const sanitizedFilters = (this.visualizationFilters || []).map(f => ({ ...f, pending: false }))
            const filteredFilters = sanitizedFilters.filter(f => !f.toDelete)
            this.setVisualizationFilters(filteredFilters)
            if (this.tab === DEFAULT_TAB) {
                await this.getModelInfoFiltered()
                if (this.variantTab === 'FREQUENCY') {
                    this.$refs.MODEL.fetchModel(INITIAL_MODEL.variant)
                    this.model = { ...this.model, variant: 1 }
                } else if (this.variantTab === 'INDIVIDUAL') {
                    this.$refs.MODEL.selectedMultipleVariants = []
                    this.$refs.MODEL.getVariants()
                    this.model = { ...this.model, variant: 1 }
                }
                this.tab = DEFAULT_TAB
            } else {
                const a = this.tab
                this.tab = undefined
                await this.getModelInfoFiltered()
                this.tab = a
            }
        },
        getModelInfoDisplayed ({ loopUid, variantCount, variantUid }) {
            this.settedChart = false
            this.disabledButton = false
            if (loopUid === undefined && !variantCount && !variantUid) {
                this.displayedInfo = EMPTY_MODEL_INFO
                return
            }
            const { processId } = this.$route.params
            const { visualizationFilters } = this
            const filters = this.formatFiltersParam(visualizationFilters)
            this.currentFilters = filters
            const params = {
                filters,
                ...(loopUid !== undefined ? { loopUid } : {}),
                ...(variantCount ? { variantCount } : {}),
                ...(variantUid ? { variantUid } : {}),

            }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.displayedInfo = data
                })
                .catch(notifyError)
                .finally(() => {
                    this.settedChart = true
                    if (!this.variantsLoading) {
                        this.disabledButton = true
                    }
                })
        },
        async getModelInfoFiltered () {
            this.settedChart = false
            this.disabledButton = false
            const { processId } = this.$route.params
            const { visualizationFilters } = this
            const filters = this.formatFiltersParam(visualizationFilters)
            const params = { filters }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.filteredTraces = data?.cases || 0
                    this.infoFiltered = data
                })
                .catch(notifyError)
                .finally(() => {
                    this.settedChart = true
                    if (!this.variantsLoading) {
                        this.disabledButton = true
                    }
                })
            if (this.checkIfEnterTest) {
                this.$router.push({ name: 'WizardChooseApproach' })
            }
        },
        getTotalInfo () {
            const { processId } = this.$route.params
            const params = { filters: [] }
            apiRequest(Api().visualizations.modelInfo({ processId, params }))
                .then((data) => {
                    this.infoTotal = data
                })
                .catch(notifyError)
        },
        getConfigurations () {
            const { processId } = this
            apiRequest(Api().datasets.getConfigurations({ datasetId: processId }))
                .then(({ data }) => {
                    const [datasetConfig] = data
                    this.attributes = datasetConfig.columns
                })
                .catch(notifyError)
        },
        saveBpmnModel () {
            this.$refs.modelModal.open()
        },
        async generateModel ({ modelName }) {
            this.isLoading = true
            this.$refs.modelModal.close()
            const arcs = (this.chart.arcs || []).map(({
                source, target, sourceId, targetId, ...rest
            }) => ({
                ...rest,
                source: sourceId,
                target: targetId,
                sourceId: source,
                targetId: target,
            }))
            const modelData = {
                name: modelName,
                projectUid: this.$route.params.projectId,
                datasetUid: this.processId,
                activities: this.chart.activities,
                arcs,
            }
            apiRequest(Api().models.create({
                params: modelData,
            }))
                .then(() => {
                    notifySuccess(this.$t('visualization.bpmnOK'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        setPercentageMode (mode) {
            this.percentageDisplay = mode
        },
        setDirectionMode (mode) {
            this.directionDisplay = mode
            this.model.directionValue = this.directionDisplay.value
        },
        setStatsMode (mode) {
            this.statsDisplay = mode
            this.model.statsValue = this.statsDisplay.value
        },
        toggleLoopMetricsPanel () {
            this.toggleLoopMetrics = !this.toggleLoopMetrics
        },
        updateCostPerHour (value) {
            this.costPerHour = Number(value)
        },
        updateCurrency (value) {
            this.currency = value
        },
        getCosts () {
            apiRequest(Api().datasets.get({ datasetId: this.$route.params.processId }))
                .then(({ data }) => {
                    [this.settings] = data
                    if (data[0].cost) {
                        this.currency = { value: data[0].cost.unit }
                        this.costPerHour = data[0].cost.value
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateSectionsList (sectionsList) {
            this.sectionsContext = sectionsList
        },
        changeOptionPanel1 (newOption) {
            this.attributePanelOption = newOption
        },
        changeOptionPanel2 (newOption) {
            this.frequencyPanelOption = newOption
        },
        handleOpenRenameModal (data) {
            this.$refs.renameVariantModal.open()
            this.variantRenamedId = data.variantId
        },
        async renameVariant ({ newVariantName }) {
            if (this.$refs.MODEL && this.$refs.MODEL.renameVariant) {
                this.$refs.renameVariantModal.close()
                this.$refs.MODEL.renameVariant({ newVariantName, variantRenamedId: this.variantRenamedId })
            }
        },
        loadingControl (load) {
            this.variantsLoading = load
            if (this.settedChart && !this.variantsLoading) {
                this.disabledButton = true
            }
            if (this.variantsLoading) {
                this.disabledButton = false
            }
        },
        handleChangeVariantTab (newVariantTab) {
            this.variantTab = newVariantTab
        },
        toggleFab (fab) {
            if (this.activeFab === fab) {
                this.$refs[fab].hide()
                this.activeFab = null
            } else {
                this.$refs[fab].show()
                if (this.activeFab) {
                    this.$refs[this.activeFab].hide()
                }
                this.activeFab = fab
            }
        },
    },
}
</script>
<style scoped lang="scss">
$options-width: 300px;
$visualization-sidebar-width: 310px;

.Visualization {
  overflow: hidden;
}

.Container {
    flex-wrap: nowrap;
    min-height: calc(100vh - #{$subheader-height})
}

.Sidebar {
    width: 100%;
    top: $subheader-height;
    padding-top: $subheader-height - 20px;
    left: 0;
    overflow-x: hidden;
    max-width: $visualization-sidebar-width;
    min-width: $visualization-sidebar-width;
    border-right: 1px solid $border-color;
    transition: $transition;
    background: $white;
    @media screen and (min-width: $md) {
        max-width: $visualization-sidebar-width;
        min-width: $visualization-sidebar-width;
    }
}

.Report {
    margin-left: 200px;
    margin-top: $subheader-height;
}
.DownloadGraph {
    position: absolute;
    right: 20px;
    top: 100px;
}
.ChargingHeight {
    min-height:  calc(100vh - #{$subheader-height} - #{$header-height});
}
.Graph {
    height: auto;
}
.ChartContainer {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
    .Actions {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        &::v-deep .WButton {
            padding: 7px 10px;
        }
    }
    .EmptyModel {
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.Options {
    position: absolute;
    bottom: 0;
    right: 0;
    width: $options-width;
    margin-right: -#{$options-width};
    border-left: 1px solid $border-color;
    background: $options-bg;
    transition: $transition;
    z-index: 2;

    @media screen and (min-width: $md) {
        position: relative;
        margin-right: 0;
    }

    &.visible {
        margin-right: 0px;
    }

    &Close {
        position: absolute;
        margin-top: 20px;
        margin-right: 20px;
        z-index: 1;
    }

    &Btn, &Close {
        @media screen and (min-width: $md) {
            display: none;
        }
    }
}

.Screen {
    &::v-deep .q-tab-panel {
        padding: 0;
    }
    & > .Wrapper {
        /* position: relative; */
        .FixedBar {
          background: $primary;
          padding: 5px;
          border-radius: 5px;
        }

        .FixedPanels {
          position: absolute;
          margin-top: 10px;
          margin-left: 10px;
        }
    }
}
.WLoading {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    &::v-deep .Backdrop {
        opacity: 1 !important;
    }
}
.Tabs {
    margin: 0;
    margin-top: $subheader-height;
    border-bottom: 1px solid $gray-05;
    &::v-deep .Container {
        padding: 0;
        .Tabs {
            display: flex;
            .Tab {
                width: fit-content;
                min-height: inherit;
                padding: 15px;
            }
        }
    }
}
</style>
