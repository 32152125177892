var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "SelectContainer" },
      [
        _c("label", { staticClass: "Label" }, [
          _vm._v(_vm._s(_vm.$t("booster.stateView.overallPerformance")))
        ]),
        _c("QSelect", {
          staticClass: "Select",
          attrs: {
            dense: "",
            outlined: "",
            options: _vm.attributes.map(function(i) {
              return i.name
            })
          },
          on: { input: _vm.getValue },
          model: {
            value: _vm.selectedAttribute,
            callback: function($$v) {
              _vm.selectedAttribute = $$v
            },
            expression: "selectedAttribute"
          }
        }),
        _vm.visible
          ? _c("QSelect", {
              staticClass: "Select",
              attrs: { outlined: "", dense: "", options: _vm.options },
              on: {
                input: function($event) {
                  return _vm.getData({
                    attribute: _vm.selectedAttribute,
                    value: _vm.selectedValue
                  })
                }
              },
              model: {
                value: _vm.selectedValue,
                callback: function($$v) {
                  _vm.selectedValue = $$v
                },
                expression: "selectedValue"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "GraphSelectContainer" }, [
      _c(
        "div",
        { staticClass: "ChartContainer" },
        [
          _c("v-chart", {
            staticClass: "Chart",
            attrs: { option: _vm.rendimientoGlobal, autoresize: "" }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "PercentagesContainer" },
      [
        _c("QCard", { staticClass: "Percentage1" }, [
          _c(
            "div",
            { staticClass: "text-h5", staticStyle: { color: "#00BF7B" } },
            [_c("strong", [_vm._v(_vm._s(_vm.slaCompliance) + " %")])]
          ),
          _c("div", { staticClass: "text-subtitle1" }, [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.slaComplianceCases) +
                  " " +
                  _vm._s(_vm.$t("booster.stateView.cases"))
              )
            ])
          ])
        ]),
        _c("QCard", { staticClass: "Percentage2" }, [
          _c(
            "div",
            { staticClass: "text-h5", staticStyle: { color: "#F94751" } },
            [_c("strong", [_vm._v(" " + _vm._s(_vm.slaNonCompliance) + " % ")])]
          ),
          _c("div", { staticClass: "text-subtitle1" }, [
            _c("strong", [
              _vm._v(
                _vm._s(_vm.slaNonComplianceCases) +
                  " " +
                  _vm._s(_vm.$t("booster.stateView.cases"))
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }