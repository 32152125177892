export default {
    required: '{attribute} is required.',
    integer: '{attribute} must be a whole number.',
    numeric: '{attribute} must be a number.',
    decimal: '{attribute} must be a decimal number.',
    minValue: '{attribute} must be more than {min}.',
    maxValue: '{attribute} must be less than {max}.',
    minLength: '{attribute} must be less than {min}.',
    maxLength: '{attribute} must be more than {max}.',
    rangeLength: 'Must be between {min} and {max} characters.',
    between: 'Must be between {min} and {max}.',
    email: '{attribute} is required.',
    sameAs: '{attribute} must be same that {eq}.',
    requiredUppercase: '{attribute} must include uppercase letters.',
    regex: '{attribute} is not valid.',
    allowedChars: '{attribute} can only include numbers, letters, spaces, dash and underscore.',
}
