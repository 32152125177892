// extracted from https://github.com/timothygebhard/js-colormaps
import colorMap from '@/utils/colormap'
import Colors from '@/styles/colors'

const enforceBounds = (num) => {
    if (num < 0) return 0
    if (num > 1) return 1
    return num
}
const interpolateLinearly = (x) => {
    const values = colorMap.autumn
    // Split values into four lists
    const xValues = []
    const rValues = []
    const gValues = []
    const bValues = []

    for (let i = 0; i < values.length; i += 1) {
        xValues.push(values[i][0])
        rValues.push(values[i][1][0])
        gValues.push(values[i][1][1])
        bValues.push(values[i][1][2])
    }

    let i = 1
    while (xValues[i] < x) {
        i += 1
    }
    i -= 1

    const width = Math.abs(xValues[i] - xValues[i + 1])
    const scalingFactor = (x - xValues[i]) / width

    // Get the new color values though interpolation
    const r = rValues[i] + scalingFactor * (rValues[i + 1] - rValues[i])
    const g = gValues[i] + scalingFactor * (gValues[i + 1] - gValues[i])
    const b = bValues[i] + scalingFactor * (bValues[i + 1] - bValues[i])

    return [enforceBounds(r), enforceBounds(g), enforceBounds(b)]
}
const generateColorScale = (color, length = 5) => Array.from({ length }, (i, n) => colorMap.lightenDarkenColor(color, 15 * n)).reverse()
const generateNumScale = (num, length = 5) => Math.round(enforceBounds(num) * (length - 1))

const ColorMapService = {
    enforceBounds,
    interpolateLinearly,
    getColor (num) {
        const [rc, gc, bc] = interpolateLinearly(1 - num) // colorMap is inverted
        return `rgb(${Math.round(255 * rc)}, ${Math.round(255 * gc)}, ${Math.round(255 * bc)})`
    },
    getFreqColor (num) {
        return generateColorScale(Colors.graph)[generateNumScale(num)]
    },
    getDurColor (num) {
        return generateColorScale(Colors.graph)[generateNumScale(num)]
    },
}

export const getColorArray = (num) => {
    const result = []
    for (let i = 0; i < num; i += 1) {
        const letters = '0123456789ABCDEF'.split('')
        let color = '#'
        for (let j = 0; j < 6; j += 1) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        result.push(color)
    }
    return result
}

export default ColorMapService
