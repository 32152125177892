var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NotificationList" },
    [
      _c("div", { staticClass: "flex row-stretch-center mb-1" }, [
        _c(
          "label",
          { staticClass: "flex row-left-center" },
          [
            _c("WSwitch", {
              attrs: { name: "visibility", value: _vm.activateVisibility },
              on: { input: _vm.handleVisibility }
            }),
            _c("WText", { staticClass: "ml-1", attrs: { size: 12 } }, [
              _vm._v(" " + _vm._s(_vm.$t("notifications.showActives")) + " ")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("QInput", {
              attrs: {
                outlined: "",
                type: "date",
                label: _vm.$t("general.startAt"),
                stackLabel: "",
                maxlength: "500"
              },
              on: { input: _vm.handleSelectedDate },
              model: {
                value: _vm.filters.startDate,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "startDate", $$v)
                },
                expression: "filters.startDate"
              }
            }),
            _c("QInput", {
              staticClass: "pl-1",
              attrs: {
                outlined: "",
                stackLabel: "",
                type: "date",
                label: _vm.$t("general.endAt")
              },
              on: { input: _vm.handleSelectedDate },
              model: {
                value: _vm.filters.endDate,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "endDate", $$v)
                },
                expression: "filters.endDate"
              }
            })
          ],
          1
        )
      ]),
      _c("Table", {
        ref: "table",
        staticClass: "Table",
        attrs: {
          columns: _vm.cols,
          data: _vm.normalizeRows,
          total: _vm.total,
          loading: _vm.loading,
          hideHeader: _vm.hideHeader,
          rowKey: "uuid"
        },
        on: { "table-request": _vm.handleFetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "QTr",
                  [
                    _c(
                      "QTd",
                      { key: "type" },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.type) + " ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "title", staticClass: "td-title" },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.title) + " ")
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(
                            " Created on " +
                              _vm._s(
                                _vm.App.dateLocationFormat(
                                  row.createdAt,
                                  "long"
                                )
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "description", staticClass: "td-desc" },
                      [
                        _c("span", { staticClass: "td-desc ellipsis" }, [
                          _vm._v(_vm._s(row.organization || "-"))
                        ]),
                        row.organization
                          ? _c(
                              "QTooltip",
                              {
                                attrs: {
                                  contentClass: "bg-secondary",
                                  maxWidth: "300px"
                                }
                              },
                              [_vm._v(" " + _vm._s(row.organization) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("QTd", { key: "seenAt", staticClass: "td-seenAt" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            !!row.seenAt
                              ? _vm.App.dateLocationFormat(row.seenAt, "long")
                              : "-"
                          )
                        )
                      ])
                    ]),
                    _c(
                      "QTd",
                      { key: "content", staticClass: "td-content" },
                      [
                        _c("WIcon", {
                          attrs: {
                            icon: "file-text",
                            size: 24,
                            color: "secondary",
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleClick(row)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "common", staticClass: "td-common" },
                      [
                        row.options
                          ? _c(
                              "fragment",
                              [
                                _c(
                                  "WButton",
                                  {
                                    attrs: {
                                      color: "link",
                                      disabled: !!row.seenAt
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.handleEmmit(_vm.getOption(row), row)
                                      }
                                    }
                                  },
                                  [
                                    _c("WIcon", {
                                      attrs: {
                                        icon: _vm.getOption(row).icon,
                                        size: 24,
                                        color: "secondary"
                                      }
                                    }),
                                    !!_vm.getOption(row).label
                                      ? _c(
                                          "QTooltip",
                                          {
                                            attrs: {
                                              contentClass: "bg-secondary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getOption(row).label
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "EmptyList",
                  _vm._b(
                    { staticClass: "mt-2 mb-2" },
                    "EmptyList",
                    _vm.$t("notifications.notifications.empty"),
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }