<template>
    <div class="Preview">
        <div class="flex row-stretch-top">
            <Tracking
                :step="step"
                :length="(fields || []).length"
                class="flex-1 mb-1"
            />
            <div class="flex row-center">
                <div class="flex row-center">
                    <WText>
                        {{ $t('wizard.preview.decimalCharacter') }}
                    </WText>
                    <QInput
                        v-model="decimalCharacter"
                        class="InputCharacter"
                        dense
                        outlined
                        type="text"
                        @input="handleChangeCharacter"
                    />
                </div>
                <div class="flex row-center pl-1">
                    <WText>
                        {{ $t('wizard.preview.thousandCharacter') }}
                    </WText>
                    <QInput
                        v-model="thousandCharacter"
                        class="InputCharacter"
                        dense
                        outlined
                        type="text"
                        @input="handleChangeCharacter"
                    />
                </div>
            </div>
        </div>
        <QTable
            bordered
            flat
            hideBottom
            :columns="normalizeColumns"
            :data="rows"
            :loading="loading"
            :pagination="pagination"
            rowKey="index"
            separator="cell"
            class="table-list"
        >
            <template v-slot:header="props">
                <QTr
                    v-if="selectable"
                    :props="props"
                    class="FakeRow"
                >
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                        <div
                            v-if="col.checked || col.hovered"
                            class="Field flex row-stretch-center"
                            :class="{ optional: (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).optional}"
                        >
                            <WText
                                weight="semi"
                                tag="span"
                                color="white"
                                :size="14"
                            >
                                {{ (fields[col.checkedIndex] || fields[col.hoveredIndex] || {}).label }}
                            </WText>
                            <WIcon
                                :size="18"
                                icon="x"
                                color="white"
                                clickable
                                @click="handleClickRow({ col }, false)"
                            />
                        </div>
                    </QTh>
                </QTr>
                <QTr :props="props">
                    <QTh
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                        class="Header"
                        :class="{
                            hovered: hovered === col.field,
                            cursor: col.checked,
                            disabled: !col.checked && disabledChecked
                        }"
                        @mouseover="handeleRowMouseOver({ col }, $event)"
                        @mouseleave="handleRowMouseEnter({ col }, $event)"
                        @click="handleClickRow({ col }, $event)"
                    >
                        {{ col.label }}
                    </QTh>
                </QTr>
            </template>

            <template v-slot:body-cell="props">
                <QTd
                    :props="props"
                    class="Item"
                    :class="{
                        hovered: hovered === props.col.field,
                        cursor: props.col.checked,
                        disabled: !props.col.checked && disabledChecked
                    }"
                    @mouseover="handeleRowMouseOver(props, $event)"
                    @mouseleave="handleRowMouseEnter(props, $event)"
                    @click="handleClickRow(props, $event)"
                >
                    {{ props.value }}
                </QTd>
            </template>

            <template v-slot:bottom-row="props">
                <QTr
                    v-if="selectable"
                    :props="props"
                    class="FakeRow"
                >
                    <QTd>&nbsp;</QTd>
                </QTr>
            </template>
        </QTable>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import Tracking from './Tracking.vue'

const FIELD_KEYS = {
    TRACE: 'traceId',
    ACTIVITY: 'activity',
    START: 'start',
    END: 'end',
}

const LIMIT = 4

export default {
    name: 'Preview',
    components: {
        Tracking,
    },
    props: {
        value: VueTypes.any,
        columns: VueTypes.any,
        rows: VueTypes.any,
        loading: VueTypes.bool.def(false),
        selectable: VueTypes.bool.def(true),
    },
    data () {
        return {
            hovered: undefined,
            checkeds: [],
            fieldKeys: FIELD_KEYS,
            thousandCharacter: '',
            decimalCharacter: '',
            pagination: {
                page: 0,
                rowsPerPage: 0,
            },
        }
    },
    computed: {
        disabledChecked () {
            return this.checkeds.filter(col => !!col).length >= LIMIT
        },
        normalizeColumns () {
            return (this.columns || []).map((col) => {
                const { field } = col
                const hovered = this.hovered === field && !this.disabledChecked
                const hoveredIndex = hovered ? this.indexEmpty() : undefined
                const checked = this.isChecked(field)
                const checkedIndex = checked ? this.indexChecked(field) : undefined

                return {
                    ...col,
                    hovered,
                    hoveredIndex,
                    checked,
                    checkedIndex,
                }
            })
        },
        step () {
            const numberOfSteps = this.checkeds.filter(Boolean).length
            return !this.disabledChecked ? {
                num: numberOfSteps + 1,
                ...this.fields[this.indexEmpty()],
            } : undefined
        },
        fields () {
            return [
                {
                    key: FIELD_KEYS.TRACE,
                    label: this.$t('wizard.trace'),
                    tooltip: this.$t('wizard.preview.tooltips.TRACE'),
                },
                {
                    key: FIELD_KEYS.ACTIVITY,
                    label: this.$t('wizard.activity'),
                    tooltip: this.$t('wizard.preview.tooltips.ACTIVITY'),
                },
                {
                    key: FIELD_KEYS.START,
                    label: this.$t('wizard.startTime'),
                    tooltip: this.$t('wizard.preview.tooltips.START'),
                },
                {
                    key: FIELD_KEYS.END,
                    label: this.$t('wizard.endTime'),
                    optional: true,
                    tooltip: this.$t('wizard.preview.tooltips.END'),
                },
            ]
        },
    },
    mounted () {
        this.initialize()
    },
    methods: {
        initialize () {
            this.checkeds = this.fields.map(item => (this.value[item.key] || {}).value || undefined)
        },
        isChecked (field) {
            return this.checkeds.includes(field)
        },
        indexChecked (field) {
            return this.checkeds.findIndex(f => f === field)
        },
        indexEmpty () {
            return this.checkeds.findIndex(f => f === undefined)
        },
        getSlotOfField (field) {
            const currentSlot = this.indexChecked(field)
            return currentSlot !== -1 ? currentSlot : this.indexEmpty()
        },
        handeleRowMouseOver ({ col }, event) {
            this.hovered = col.field
        },
        handleRowMouseEnter ({ col }, event) {
            this.hovered = undefined
        },
        handleClickRow ({ col }) {
            const checked = this.isChecked(col.field)
            if (!this.disabledChecked) this.handleClickCol(col, !checked)
            else if (checked) this.handleClickCol(col, false)
        },
        handleClickCol (col, checked) {
            const { field, label } = col
            const slot = this.getSlotOfField(field)
            const fieldKey = (this.fields[slot] || {}).key
            const value = {
                fieldKey, field, value: field, label, slot, checked,
            }
            this.handleInput(value)
        },
        handleChangeCharacter () {
            const { thousandCharacter, decimalCharacter } = this
            this.$emit('input', { ...this.value, thousandCharacter, decimalCharacter })
        },
        handleInput ({
            fieldKey, field, value, label, slot, checked,
        } = {}) {
            const checkeds = [...this.checkeds.slice(0, slot), checked ? field : undefined, ...this.checkeds.slice(slot + 1)]
            const newValue = { ...(this.value || {}), [fieldKey]: checked ? { label, value } : undefined }
            this.checkeds = checkeds
            this.notifyInput(newValue)

            return newValue
        },
        notifyInput (value) {
            this.$emit('input', value)
        },
    },
}
</script>
<style scoped lang="scss">
.table-list {
    &::v-deep {
        flex: 1;
        color: $table-color !important;
        background: transparent !important;

        .q-table {
            thead th {
                position: sticky;
                z-index: 1;
                color: $table-head-color;
                font-size: $table-head-size;
                background: $table-head-bg;
            }

            tbody {
                td {
                    padding: 15px;
                    min-width: 130px;

                    &:before {
                        background: transparent;
                    }
                }
            }
        }

        .q-table__bottom {
            background: $table-head-bg;
        }
    }

    &.bordered {
        &::v-deep {
            .q-table__bottom {
                border: 0;
            }
        }
    }
}

.Header {
    &.hovered:not(.disabled) {
        cursor: pointer;

        &::v-deep .q-checkbox__bg {
            border-color: $white;
        }
    }
}

.FakeRow {
    background: $table-head-fake-bg;

    th {
        padding: 0 2px;
        border-left: 0;
        border-right: 0;
        background: $table-head-fake-bg !important;
    }
}

.Item {
    &.cursor {
        cursor: pointer
    }
    &.disabled {
        cursor: not-allowed;
    }
    &.hovered:not(.disabled) {
        background: rgba($primary, 0.05);
        cursor: pointer
    }
}

.Checkbox {
    &::v-deep .q-checkbox__inner {
        display: none;
    }
}

.Field {
    padding: 8px;
    border-radius: $border-radius;
    color: $white;
    background: $secondary;

    &.optional {
        background: $tertiary;
    }
}
.InputCharacter {
    width: 50px;
    margin-left: 10px;
}
</style>
