var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-5 q-pa-sm" },
          [
            _c("div", { staticClass: "text-h6 text-center q-mb-sm" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "visualization.conformance.fulfillment.percentageTitle"
                    )
                  ) +
                  " "
              )
            ]),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.pieChartData, autoresize: "" }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-7 q-pa-sm" },
          [
            _c("div", { staticClass: "text-h6 text-center q-mb-sm" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "visualization.conformance.fulfillment.percentageTimeTitle"
                    )
                  ) +
                  " "
              )
            ]),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.barChartData, autoresize: "" }
            }),
            _c(
              "div",
              { staticClass: "flex items-center justify-center" },
              [
                _c("QSelect", {
                  staticStyle: { width: "50%" },
                  attrs: {
                    options: _vm.timeUnits,
                    label: _vm.$t(
                      "visualization.conformance.fulfillment.selectUnit"
                    )
                  },
                  on: { input: _vm.changeTimeUnit },
                  model: {
                    value: _vm.timeModel,
                    callback: function($$v) {
                      _vm.timeModel = $$v
                    },
                    expression: "timeModel"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("QLinearProgress", {
              attrs: { indeterminate: "", color: "primary" }
            })
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }