var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Table", {
    ref: "table",
    staticClass: "Table",
    attrs: {
      columns: _vm.columns,
      data: _vm.rows,
      total: _vm.total,
      loading: _vm.isLoading,
      hideHeader: !(_vm.rows || []).length,
      rowKey: "id"
    },
    on: { "table-request": _vm.fetchData },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function(ref) {
          var row = ref.row
          return [
            _c(
              "QTr",
              { attrs: { noHover: true } },
              [
                _c(
                  "QTd",
                  { key: "organization", staticClass: "td-organization" },
                  [
                    _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                      _vm._v(" " + _vm._s(row.organization.name) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "QTd",
                  { key: "project", staticClass: "td-project" },
                  [
                    _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                      _vm._v(" " + _vm._s(row.project.name) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "QTd",
                  { key: "dataset", staticClass: "td-dataset" },
                  [
                    _c(
                      "WText",
                      { staticClass: "ellipsis", attrs: { ellipsis: "" } },
                      [_vm._v(" " + _vm._s(row.dataset.fileName) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "QTd",
                  { key: "events", staticClass: "td-project" },
                  [
                    _c("WText", { attrs: { size: 14 } }, [
                      _vm._v(" " + _vm._s(row.measurement) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "QTd",
                  { key: "date", staticClass: "td-date" },
                  [
                    _c("WText", { attrs: { size: 14, color: "gray02" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("date")(row.date, "DD/MM/YYYY")) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "empty",
        fn: function() {
          return [
            _c("EmptyList", {
              staticClass: "mt-2 mb-2",
              attrs: {
                title: _vm.$t("events.emptyListTitle"),
                text: _vm.$t("events.emptyListText")
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }