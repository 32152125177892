import Data from './data'

export default {
    list: ({ params }) => [200, Data.list],
    get: ({ params }) => {
        const { data } = Data.list
        return [200, data[0]]
    },
    activities: () => [200, Data.activitiesList.activities],
}
