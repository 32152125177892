<template>
    <div class="Datasets">
        <Transition
            name="fade"
            mode="out-in"
        >
            <div v-if="!openSettingsModal">
                <div class="Actions flex row-left-center mb-1">
                    <WButton
                        color="primary"
                        iconLeft="plus-circle"
                        :disabled="isProjectArchived"
                        @click="handleCreateItem"
                    >
                        {{ $t('projects.datasets.new') }}
                    </WButton>
                    <WButton
                        outline
                        color="primary"
                        iconLeft="upload-cloud"
                        class="ml-1"
                        @click="handleClickImport"
                    >
                        {{ $t('projects.datasets.import') }}
                    </WButton>
                </div>
                <div class="Container">
                    <DatasetsListCards
                        ref="table"
                        :rows="datasets"
                        :total="total"
                        :search="(filters || {}).search"
                        :archived="isProjectArchived"
                        :loading="isFetching"
                        @onNewItem="handleCreateItem"
                        @onDelete="handleDeleteItem"
                        @onUpload="handleUploadItem"
                        @onWizardQuery="handleQueryItem"
                        @onFetchData="fetchData"
                        @onVisualization="handleGoToVisualization"
                        @onSettings="handleOpenSettings"
                    />
                </div>
            </div>
            <SettingsModal
                v-else
                ref="settingsModal"
                :datasetInfo="datasetSettings"
                :isOpen="openSettingsModal"
                @UpdateDatasetName="handleUpdateItem"
                @SetUpdates="handleSetItemUpdates"
                @CloseSettings="handleCloseSettings"
            />
        </Transition>
        <ModalRequest
            ref="deleteDialog"
            :title="$t('projects.datasets.delete')"
            :text="$t('projects.datasets.modals.delete.text', { name: (selected || {}).name })"
            :loading="isLoading"
            @onAccept="archiveDataset"
        />

        <ModalNameDesc
            ref="newDialog"
            :title="$t('projects.datasets.new')"
            :action="$t('actions.create')"
            :loading="isLoading"
            @onSubmit="createDataset"
        />

        <ModalNameDesc
            ref="updateDialog"
            :title="$t('projects.datasets.edit')"
            :action="$t('actions.save')"
            :loading="isLoading"
            withoutDesc
            @onSubmit="updateDataset"
        />

        <UploadDialog
            ref="uploadDialog"
            @upload-action="fetchData"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { pollingMixin, filtersStorageMixin } from '@/mixins'
import { ModalRequest, ModalNameDesc } from '@/components'
import { WIZARD } from '@/entities'
import { DatasetsListCards, UploadDialog, SettingsModal } from './sections'

const DEFAULT_VISUALIZATION = 'MODEL'

export default {
    name: 'Datasets',
    components: {
        ModalRequest,
        DatasetsListCards,
        ModalNameDesc,
        UploadDialog,
        SettingsModal,
    },
    mixins: [pollingMixin, filtersStorageMixin],
    props: {
        projectId: VueTypes.string,
        isProjectArchived: VueTypes.bool,
        filters: VueTypes.shape({
            search: VueTypes.string,
            archived: VueTypes.oneOfType([VueTypes.string, VueTypes.bool]),
        }),
    },
    data () {
        return {
            firstTime: true,
            datasets: undefined,
            total: 0,
            isFetching: false,
            datasetSettings: undefined,
            openSettingsModal: false,
            selected: undefined,
            isLoading: false,
        }
    },
    computed: {
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...(this.filters || {}),
                ...pagination,
            }
        },
    },
    watch: {
        filters: {
            handler (value) {
                if (value) this.fetchData()
            },
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        handleCreateItem () {
            this.$storage.remove(WIZARD.KEYS.DATASET)
            this.$router.push({ name: 'WizardDataset', params: { projectId: this.projectId } })
        },
        handleQueryItem ({ uuid }) {
            this.$storage.remove(WIZARD.KEYS.QUERY)
            this.$router.push({ name: 'WizardQuery', params: { projectId: this.projectId, datasetId: uuid } })
        },
        handleClickImport () {
            this.$storage.remove(WIZARD.KEYS.IMPORT)
            this.$router.push({ name: 'WizardImport', params: { projectId: this.projectId } })
        },
        handleDeleteItem (row) {
            this.selected = row
            this.$refs.deleteDialog.open()
        },
        handleUpdateItem (name) {
            this.updateDataset(name)
        },
        handleSetItemUpdates (updateInfo) {
            this.setDatasetUpdates(updateInfo)
        },
        handleUploadItem ({ uuid }) {
            this.$refs.uploadDialog.open(uuid)
        },
        handleOpenSettings (row) {
            this.datasetSettings = row
            this.openSettingsModal = true
        },
        handleCloseSettings () {
            this.openSettingsModal = false
            this.datasetSettings = undefined
        },
        handleGoToDataset ({ uuid: datasetId }) {
            this.$router.push({ name: 'Dataset', params: { projectId: this.projectId, datasetId } })
        },
        async fetchData ({ polling = false } = {}) {
            const {
                archived, search, page, rowsPerPage,
            } = this.params
            const params = {
                projectUid: this.projectId,
                includeArchived: !!archived,
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || null,
                polling,
            }

            if (!(this.datasets || []).length) this.isFetching = true
            apiRequest(Api().datasets.list(params))
                .then(({ data, total }) => {
                    this.datasets = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        async createDataset ({ name, description }) {
            this.isLoading = true
            apiRequest(Api().datasets.create({ projectUid: this.projectId, name, description }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.created.text'))
                    this.fetchData()
                    this.$refs.newDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async updateDataset (name) {
            const { uuid: datasetId } = this.datasetSettings
            this.isLoading = true
            apiRequest(Api().datasets.update({ datasetId, params: { name } }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.updated.text'))
                    this.fetchData()
                    this.datasetSettings.name = name
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        async setDatasetUpdates (newSettings) {
            const { uuid: datasetId } = this.datasetSettings
            this.isLoading = true
            apiRequest(Api().datasets.update({ datasetId, params: newSettings }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.updated.text'))
                    this.fetchData()
                    this.datasetSettings = {
                        ...this.datasetSettings,
                        ...newSettings,
                    }
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        async archiveDataset () {
            const { uuid: datasetId } = this.selected
            this.isLoading = true
            apiRequest(Api().datasets.delete({ datasetId }))
                .then(() => {
                    notifySuccess(this.$t('projects.datasets.notifications.deleted.text'))
                    this.fetchData()
                    this.$refs.deleteDialog.close()
                    this.selected = undefined
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleGoToVisualization ({ uuid: processId, name, opened }) {
            const { projectId } = this
            this.setVisualizationFilters([])
            if (!opened) {
                this.$router.push({
                    name: 'WizardChooseApproach',
                    params: {
                        projectId,
                        processId,
                        name,
                        tab: DEFAULT_VISUALIZATION,
                    },
                })
            } else {
                this.$router.push({ name: 'ProcessVisualization', params: { projectId, processId, tab: DEFAULT_VISUALIZATION } })
            }
        },
    },
}
</script>
