var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: { isOpen: _vm.opened },
    on: {
      close: function($event) {
        _vm.opened = false
      }
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("settings.token.modal.title")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "flex col-top-stretch" },
              [
                _c(
                  "div",
                  { staticClass: "Form mb-1" },
                  [
                    _c("QInput", {
                      ref: "name",
                      staticClass: "Field mb-1",
                      attrs: {
                        outlined: "",
                        label: "Email",
                        type: "email",
                        rules: [
                          function(value) {
                            return (
                              _vm.validators.validEmail(value) ||
                              _vm.$t("invitations.valid")
                            )
                          }
                        ]
                      },
                      model: {
                        value: _vm.userInfo.name,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "name", $$v)
                        },
                        expression: "userInfo.name"
                      }
                    }),
                    _c("QInput", {
                      attrs: {
                        outlined: "",
                        type: _vm.isPwd ? "text" : "password",
                        label: _vm.$t("settings.token.modal.password")
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "append",
                          fn: function() {
                            return [
                              _c("WIcon", {
                                attrs: {
                                  icon: _vm.isPwd ? "eye" : "eye-off",
                                  size: 16,
                                  clickable: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.isPwd = !_vm.isPwd
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.userInfo.password,
                        callback: function($$v) {
                          _vm.$set(_vm.userInfo, "password", $$v)
                        },
                        expression: "userInfo.password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "WButton",
                  {
                    staticClass: "ml-1",
                    attrs: { id: "submit" },
                    on: { click: _vm.handleSubmit }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("settings.token.modal.btn")) + " "
                    )
                  ]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }