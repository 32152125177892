var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Step" }, [
    _c(
      "div",
      { staticClass: "Wrapper" },
      [
        _c("div", [_vm._v(_vm._s(_vm.$t("projects.modals.upload.text")))]),
        _vm.token
          ? _c("QUploader", {
              staticClass: "Uploader mt-1 mb-0-5",
              class: {
                error: _vm.error,
                length: !!_vm.length,
                uploaded: _vm.isUploaded
              },
              attrs: {
                flat: "",
                bordered: "",
                accept: ".csv",
                sendRaw: "",
                color: "grey-1",
                textColor: "primary",
                label: _vm.$t("actions.uploadFile"),
                autoUpload: "",
                headers: _vm.headers,
                method: _vm.method,
                multiple: false,
                url: _vm.url
              },
              on: {
                added: _vm.handleAdd,
                removed: _vm.handleRemove,
                start: _vm.handleStarted,
                finish: _vm.handleFinished,
                uploaded: _vm.handleSuccess
              }
            })
          : _vm._e(),
        _vm.error
          ? _c("WText", { attrs: { size: 12, color: "red" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("general.file")
                    })
                  ) +
                  " "
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }