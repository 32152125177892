<template>
    <div class="Sidebar">
        <div
            class="Icon"
            @click="setView('OVERVIEW')"
        >
            <QIcon
                :class="{ Active: tab === 'OVERVIEW'}"
                size="lg"
                name="travel_explore"
            />
        </div>
        <div
            :class="{ Active: tab === 'PREDICTION'}"
            class="Icon"
        >
            <QIcon
                size="lg"
                name="batch_prediction"
            />
        </div>
        <div
            :class="{ Active: tab === 'IMPROVEMENT'}"
            class="Icon"
        >
            <QIcon
                size="lg"
                name="query_stats"
            />
        </div>
        <!-- <div
            :class="{ Active: tab === 'PREDICTION'}"
            class="Icon"
            @click="setView('PREDICTION')"
        >
            <QIcon
                size="lg"
                name="batch_prediction"
            />
        </div>
        <div
            :class="{ Active: tab === 'IMPROVEMENT'}"
            class="Icon"
            @click="setView('IMPROVEMENT')"
        >
            <QIcon
                size="lg"
                name="query_stats"
            />
        </div> -->
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'Sidebar',
    props: {
        tab: VueTypes.string,
    },
    computed: {
        getTabs () {
            return [
                { id: 'OVERVIEW', label: this.$t('booster.tabs.overview') },
                { id: 'PREDICTION', label: this.$t('booster.tabs.prediction') },
                { id: 'IMPROVEMENT', label: this.$t('booster.tabs.improvement') },
            ]
        },
    },
    methods: {
        setView (value) {
            this.$emit('tab', value)
        },
    },
}
</script>
<style scoped lang="scss">
    .Icon {
        padding-top: 1vh;
    }
    .Active {
        color: $primary;
    }
</style>
