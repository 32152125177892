var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-center" }, [
    _c("div", { staticClass: "col-8 justify-center q-pa-lg" }, [
      _c("div", { staticClass: "Title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("wizard.boosterWizard.performanceBooster.title")) +
            " "
        )
      ]),
      _c("div", { staticClass: "Title" }, [
        _c("h6", [
          _vm._v(
            _vm._s(
              _vm.$t(
                "wizard.boosterWizard.performanceBooster.costAndCaseCompletion.description"
              )
            )
          )
        ])
      ]),
      _c("div", { staticClass: "SettingsContainer" }, [
        _c(
          "div",
          {
            staticClass: "Settings flex row-left-center",
            class: {
              closeSettings:
                !_vm.openCCSettings && !_vm.openCaseCompletionSettings,
              openSettings: _vm.openCCSettings || _vm.openCaseCompletionSettings
            }
          },
          [
            _c(
              "WCard",
              {
                class: { "container-open": _vm.openCCSettings },
                attrs: { shadow: "" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "CardInfo row-left-center",
                    on: {
                      click: function($event) {
                        _vm.handleCCSettings()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "Item flex row-left-center flex-nowrap card-container"
                      },
                      [
                        _c("WIcon", {
                          attrs: {
                            icon: "money_increase",
                            color: "primary",
                            size: 40
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "flex-wrap" },
                          [
                            _c(
                              "WText",
                              {
                                staticClass: "pl-1",
                                attrs: { typo: "h3", tag: "p", weight: "bold" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.costs.name"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _vm.values.cost
                              ? _c(
                                  "WText",
                                  {
                                    staticClass: "pl-1 textCost",
                                    attrs: {
                                      color: "primary",
                                      tag: "p",
                                      weight: "medium"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.values.cost.value) +
                                        " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.settings.costs.units." +
                                              _vm.values.cost.unit
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Item rightIcon" },
                      [
                        !_vm.openCCSettings
                          ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                          : _vm._e(),
                        _vm.openCCSettings
                          ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "WCard",
              {
                class: { "container-open": _vm.openCaseCompletionSettings },
                attrs: { shadow: "" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "CardInfo row-left-center",
                    on: {
                      click: function($event) {
                        _vm.handleCaseCompletionSettings()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "Item flex row-left-center flex-nowrap card-container"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "caseCompletionIconContainer" },
                          [
                            _c("WIcon", {
                              attrs: {
                                icon: "trace",
                                color: "primary",
                                size: 50
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "caseCompletionContainer" },
                          [
                            _c(
                              "WText",
                              {
                                staticClass: "pl-1",
                                attrs: { typo: "h3", tag: "p", weight: "bold" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.caseCompletion.name"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            !_vm.caseCompletionFilled
                              ? _c(
                                  "WText",
                                  {
                                    staticClass: "pl-1 textCost",
                                    attrs: {
                                      color: "primary",
                                      tag: "p",
                                      weight: "medium"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.settings.caseCompletionConditionsNoConf"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "WText",
                                  {
                                    staticClass: "pl-1 textCost",
                                    attrs: {
                                      color: "primary",
                                      tag: "p",
                                      weight: "medium"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.settings.caseCompletionConditionsConf"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "Item rightIcon" },
                      [
                        !_vm.openCaseCompletionSettings
                          ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                          : _vm._e(),
                        _vm.openCaseCompletionSettings
                          ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ],
          1
        ),
        _vm.values && Object.keys(_vm.values).length > 0
          ? _c(
              "div",
              [
                _c("CostCurrencySettings", {
                  ref: "CostCurrencySettings",
                  attrs: {
                    values: _vm.values.cost,
                    isOpen: _vm.openCCSettings,
                    saveButton: false
                  },
                  on: { CostCurrencyConfig: _vm.saveSettings }
                }),
                _c("CaseCompletionConditions", {
                  ref: "CaseCompletionConditions",
                  attrs: {
                    isOpen: _vm.openCaseCompletionSettings,
                    values: _vm.values.caseCompletion,
                    saveButton: false
                  },
                  on: { CaseCompletionConditionsConfig: _vm.saveSettings }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "row justify-center mt-1" }, [
        !_vm.caseCompletionFilled
          ? _c(
              "div",
              { staticClass: "Warning rounded-borders q-pa-md" },
              [
                _c("QIcon", {
                  staticClass: "Icon",
                  attrs: { name: "info", color: "white", size: "40px" }
                }),
                _c("WText", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "wizard.boosterWizard.performanceBooster.costAndCaseCompletion.warning"
                        )
                      ) +
                      " "
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "row justify-between mt-1" },
        [
          _c(
            "WButton",
            {
              staticStyle: { "padding-inline": "40px" },
              attrs: { color: "primary" },
              on: { click: _vm.handleReturn }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.back"
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "WButton",
            {
              staticClass: "BoostButton",
              staticStyle: { "padding-inline": "40px" },
              on: { click: _vm.uploadData }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.boost"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }