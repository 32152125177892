import loops from './loops'
import model from './model'
import modelInfo from './modelInfo'
import dashboard from './dashboard'

export default {
    loops,
    model,
    modelInfo,
    dashboard,
}
