var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag ? _vm.tag : _vm.tagByTypo[_vm.typo],
    { tag: "Component", staticClass: "WText", class: _vm.typoClass },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }