var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chartInfo
    ? _c(
        "div",
        { staticClass: "ContextualInfo" },
        [
          _c(
            "QExpansionItem",
            {
              attrs: {
                defaultOpened: "",
                dark: "",
                dense: "",
                denseToggle: "",
                expandSeparator: "",
                icon: "rotate_left",
                label: _vm.$t("visualization.loopMetrics.metricsTitle")
              }
            },
            [
              _c("div", { staticClass: "row q-col-gutter-sm" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("span", { staticClass: "ContextualElementName" }, [
                      _vm._v(
                        _vm._s(_vm.$t("visualization.loopMetrics.avgTime"))
                      )
                    ]),
                    _c(
                      "QCard",
                      { staticClass: "bg-grey-2", attrs: { flat: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "q-pa-sm text-center text-weight-bold"
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.averageLoopTimeFormatted) + " "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-6" },
                  [
                    _c("span", { staticClass: "ContextualElementName" }, [
                      _vm._v(
                        _vm._s(_vm.$t("visualization.loopMetrics.avgCost"))
                      )
                    ]),
                    _c(
                      "QCard",
                      { staticClass: "bg-grey-2", attrs: { flat: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "q-pa-sm text-center text-weight-bold text-secondary"
                          },
                          [_vm._v(" " + _vm._s(_vm.averageCostFormatted) + " ")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _c("span", { staticClass: "ContextualElementName" }, [
                      _vm._v(
                        _vm._s(_vm.$t("visualization.loopMetrics.percentTime"))
                      )
                    ]),
                    _c(
                      "QLinearProgress",
                      {
                        attrs: {
                          size: "25px",
                          value: _vm.averagePercentage,
                          color: "orange-7",
                          trackColor: "grey-2",
                          rounded: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "absolute-full flex flex-center" },
                          [
                            _c("QBadge", {
                              attrs: {
                                color: "white",
                                textColor: "primary",
                                label:
                                  (_vm.averagePercentage * 100).toFixed(0) + "%"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c("span", { staticClass: "ContextualElementName" }, [
                    _vm._v(
                      _vm._s(_vm.$t("visualization.loopMetrics.estTotalEffort"))
                    )
                  ]),
                  _c("div", { staticClass: "row q-col-gutter-sm" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6" },
                      [
                        _c(
                          "QCard",
                          { staticClass: "bg-grey-2", attrs: { flat: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "q-pa-sm text-center text-weight-bold"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.estimatedTotalEffortTimeFormatted
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6" },
                      [
                        _c(
                          "QCard",
                          { staticClass: "bg-grey-2", attrs: { flat: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "q-pa-sm text-center text-weight-bold text-secondary"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.estimatedTotalEffortCostFormatted +
                                        " " +
                                        _vm.curOptions[_vm.currency.value]
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _c("span", { staticClass: "ContextualElementName" }, [
                      _vm._v(
                        _vm._s(_vm.$t("visualization.loopMetrics.afterRemoval"))
                      )
                    ]),
                    _c(
                      "QCard",
                      { staticClass: "col bg-grey-2", attrs: { flat: "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "q-pa-sm text-center text-weight-bold"
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.estimatedRemovedEffortFormatted)
                              )
                            ]),
                            _c("QIcon", { attrs: { name: "arrow_right" } }),
                            _c("span", { staticClass: "text-secondary" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.estimatedRemovedCost +
                                    " " +
                                    _vm.curOptions[_vm.currency.value]
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }