<template>
    <div class="Sidebar flex col-stretch">
        <Logo />
        <div
            v-if="(tabs || []).length"
            class="Tabs flex-1"
        >
            <Tabs
                :value="value"
                :tabs="tabs"
                @input="handleChangeTab"
            />
        </div>
        <div class="Bottom">
            <UserAvatar :hideFullName="isMobile" />
            <Powered class="mt-1" />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    UserAvatar, Logo, Powered,
} from '@/components'
import { mediaQueryMixin } from '@/mixins'
import Tabs from '../Tabs/Tabs.vue'

export default {
    name: 'Sidebar',
    components: {
        UserAvatar,
        Logo,
        Powered,
        Tabs,
    },
    mixins: [mediaQueryMixin],
    props: {
        value: VueTypes.string,
        tabs: VueTypes.array,
    },
    methods: {
        handleChangeTab (value) {
            this.$emit('input', value)
        },
    },
}
</script>
<style scoped lang="scss">
.Sidebar {
    width: 100%;
    max-width: $sidebar-min-width;
    background: $sidebar-bg;
    border-right: 1px solid $border-color;
    transition: $transition;

    @media screen and (min-width: $md) {
        max-width: $sidebar-width;
    }

    .Tabs {
        max-width: $sidebar-min-width;

        @media screen and (min-width: $md) {
            max-width: 100%;
        }
    }

    .Bottom {
        width: 100%;
        padding: 20px;
    }
}
</style>
