var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Sidebar" }, [
    _c(
      "div",
      {
        staticClass: "Icon",
        on: {
          click: function($event) {
            return _vm.setView("OVERVIEW")
          }
        }
      },
      [
        _c("QIcon", {
          class: { Active: _vm.tab === "OVERVIEW" },
          attrs: { size: "lg", name: "travel_explore" }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Icon", class: { Active: _vm.tab === "PREDICTION" } },
      [_c("QIcon", { attrs: { size: "lg", name: "batch_prediction" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "Icon", class: { Active: _vm.tab === "IMPROVEMENT" } },
      [_c("QIcon", { attrs: { size: "lg", name: "query_stats" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }