var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    staticClass: "ModalRequest",
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "WText",
                {
                  staticClass: "mb-0",
                  attrs: { tag: "h3", size: 16, weight: "semi" }
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            ]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "Wrapper" },
                [
                  _vm.text
                    ? _c("WText", { staticClass: "mt-0" }, [
                        _vm._v(" " + _vm._s(_vm.text) + " ")
                      ])
                    : _vm._e(),
                  _vm._t("default")
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "flex row-right-center pb-1" },
                [
                  _c(
                    "WButton",
                    {
                      attrs: { id: "cancel", color: "none" },
                      on: { click: _vm.handleClickCancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.cancel) + " ")]
                  ),
                  _c(
                    "WButton",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        id: "submit",
                        loading: _vm.loading,
                        disabled: _vm.loading
                      },
                      on: { click: _vm.handleClickAccept }
                    },
                    [_vm._v(" " + _vm._s(_vm.accept) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }