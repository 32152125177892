<template>
    <div>
        <WText
            v-if="title"
            typo="h4"
            tag="h1"
            class="Title"
        >
            {{ title }}
        </WText>
        <WText
            tag="p"
        >
            {{ description }}
        </WText>
        <InsightExamples :examples="examples" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import InsightExamples from './InsightExamples.vue'

export default {
    name: 'InsightModelSection',
    components: {
        InsightExamples,
    },
    props: {
        title: VueTypes.string,
        description: VueTypes.string.isRequired,
        examples: VueTypes.arrayOf(VueTypes.string),
    },
}
</script>
<style lang="scss" scoped>
.Title {
    margin-bottom: 5px;
}
</style>
