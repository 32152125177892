const info = {
    defaultMaxEvents: 10000,
    defaultMaxUploadSize: 20,
    mongoDb: 'dev',
    name: 'dev',
    public: true,
    subdomain: 'dev',
}

export default {
    getInfo: info,
}
