import {
    Project,
    ProjectDatasets,
    ProjectModels,
    Wizard,
    WizardDataset,
    WizardQuery,
    WizardImport,
    SAPImport,
    Process,
    WizardChooseApproach,
    WizardUpload,
    WizardSLA,
} from '@/screens'

import SimpleLayout from '@/layouts/Simple.vue'

export default [
    {
        path: '',
        name: 'ProjectDetail',
        component: Project,
        redirect: { name: 'ProjectDatasets' },
        children: [
            {
                path: 'datasets',
                name: 'ProjectDatasets',
                component: ProjectDatasets,
            },
            {
                path: 'models',
                name: 'ProjectModels',
                component: ProjectModels,
            },
            {
                path: 'sap',
                name: 'SAPImport',
                component: SAPImport,
            },
        ],
    },
    {
        path: 'discoveries/:processId',
        name: 'Process',
        component: SimpleLayout,
        children: [
            {
                path: 'visualization/:tab?',
                name: 'ProcessVisualization',
                component: Process,
                meta: {
                    output: 'visualization',
                },
            },
            {
                path: 'booster/:subview?',
                name: 'ProcessBooster',
                component: Process,
                meta: {
                    output: 'booster',
                },
            },
        ],
    },
    {
        path: 'wizard',
        name: 'Wizard',
        component: Wizard,
        redirect: { name: 'WizardImport' },
        children: [
            {
                path: 'dataset',
                name: 'WizardDataset',
                component: WizardDataset,
            },
            {
                path: 'import',
                name: 'WizardImport',
                component: WizardImport,
            },
            {
                path: 'query/:datasetId?',
                name: 'WizardQuery',
                component: WizardQuery,
            },
            {
                path: 'chooseApproach/:processId?',
                name: 'WizardChooseApproach',
                component: WizardChooseApproach,
            },
            {
                path: 'changeSLA/:processId?',
                name: 'WizardSLA',
                component: WizardSLA,
            },
            {
                path: 'costAndCase/:processId?',
                name: 'WizardUpload',
                component: WizardUpload,
            },
        ],
    },
]
