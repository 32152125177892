<template>
    <div>
        <QCard class="QCard">
            <QTabs
                v-model="tab"
                dense
                animated
                noCaps
                style="height:fit-content;"
                transitionNext="fade"
                transitionPrev="fade"
                class="text-grey"
                activeColor="primary"
                indicatorColor="primary"
                align="justify"
            >
                <QTab
                    v-for="(tabName, index) in attributes"
                    :key="index"
                    :name="tabName"
                    :label="tabName"
                />
            </QTabs>
            <QSeparator />
            <QTabPanels
                v-model="tab"
                animated
                style="flex-grow: 1;"
            >
                <QTabPanel
                    v-for="(tabName, index) in attributes"
                    :key="index"
                    class="TabPanel"
                    :name="tabName"
                    :label="tabName"
                >
                    <PerformanceByResource
                        v-if="selectedOptions"
                        :tab="tab"
                        :selectedOptions="selectedOptions"
                    />
                </QTabPanel>
            </QTabPanels>
        </QCard>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import PerformanceByResource from './PerformanceByResource.vue'

export default {
    name: 'PerformanceDashboardByResource',
    components: {
        PerformanceByResource,
    },
    props: {
        selectedOptions: VueTypes.object,
        attributes: VueTypes.array,

    },
    data () {
        return {
            tab: undefined,
        }
    },
    watch: {
        attributes: {
            handler (newValue, oldValue) {
                // eslint-disable-next-line prefer-destructuring
                this.tab = this.attributes[0]
            },
        },
    },
    mounted () {},
    methods: {},
}
</script>
<style scoped lang="scss">
.QCard {
    height: 100%;
    display: flex;
    flex-direction:column;
}
.q-tab-panels {
    height: 92%;
    &::v-deep .q-panel {
        overflow: visible !important;
    }
}
.q-tab-panel {
    padding: 0px 16px !important;
    overflow: hidden;
}
</style>
