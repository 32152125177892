var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Process" }, [
    _c("div", { staticClass: "Header flex row-stretch-center" }, [
      _c(
        "div",
        { staticClass: "HeaderLeft flex row-center" },
        [
          _c("WIcon", {
            staticClass: "mr-1",
            attrs: {
              icon: "chevron-left",
              size: 28,
              color: "primary",
              clickable: ""
            },
            on: { click: _vm.handleBack }
          }),
          _c("Breadcrumb")
        ],
        1
      ),
      _c("div", { staticClass: "HeaderRight" }, [
        _c(
          "div",
          { staticClass: "button-container flex-row-center" },
          [
            _c(
              "WButton",
              {
                attrs: { size: "sm", direction: "bottomLeft" },
                on: { click: _vm.toggleView }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.viewMode === "visualization"
                        ? _vm.$t("booster.visualizationToBooster")
                        : _vm.$t("booster.customAnalysis")
                    ) +
                    " "
                )
              ]
            ),
            _c("UserAvatar", {
              staticClass: "ml-1",
              attrs: { direction: "bottomLeft" }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "Container flex row-stretch" },
      [
        _c(_vm.viewMode === "visualization" ? "Visualization" : "Overview", {
          tag: "component",
          staticClass: "flex-1"
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }