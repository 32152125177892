var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "WCard",
        { staticClass: "DatasetCard", attrs: { flat: "", bordered: "" } },
        [
          _c(
            "div",
            { staticClass: "flex row-left-top flex-nowrap q-pa-sm h-full" },
            [
              _c(
                "div",
                {
                  staticClass: "AvatarTitle cursor-pointer",
                  on: { click: _vm.handleGoToVisualization }
                },
                [_c("p", [_vm._v(_vm._s(_vm.getInitial(_vm.row.name)))])]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "DatasetContent q-px-sm flex-1 flex flex-col col-between-left h-full cursor-pointer",
                  on: { click: _vm.handleGoToVisualization }
                },
                [
                  [
                    _c(
                      "p",
                      { staticClass: "DatasetCardName text-weight-bold" },
                      [_vm._v(" " + _vm._s(_vm.row.name) + " ")]
                    ),
                    _c("p", { staticClass: "DatasetCardName" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("general.updateDate")) +
                          ": " +
                          _vm._s(
                            _vm.App.dateLocationFormat(_vm.row.tst, "long")
                          ) +
                          " "
                      )
                    ])
                  ],
                  _c(
                    "div",
                    { staticClass: "flex items-center q-mt-sm" },
                    [
                      _vm.row.source
                        ? _c("QBadge", {
                            staticClass: "q-pa-sm q-mr-sm",
                            attrs: {
                              label: _vm.row.source,
                              color: _vm.sourceColors[_vm.row.source],
                              textColor: "white"
                            }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "flex-1" }, [
                        _vm.row.state.identifier === "ERROR" ||
                        (_vm.row.state.identifier === "SUBMITTED" &&
                          !_vm.row.uploaded)
                          ? _c(
                              "div",
                              [
                                _c("WIcon", {
                                  attrs: {
                                    icon: _vm.statusList.ERROR.icon,
                                    size: 24,
                                    color: _vm.statusList.ERROR.color
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _vm.showProgressBar
                                  ? _c(
                                      "QLinearProgress",
                                      {
                                        attrs: {
                                          size: "30px",
                                          indeterminate: "",
                                          value: 1,
                                          color:
                                            _vm.statusList[
                                              _vm.row.state.identifier
                                            ].color,
                                          rounded: "",
                                          trackColor: "white"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "absolute-full flex flex-center"
                                          },
                                          [
                                            _c("QBadge", {
                                              attrs: {
                                                label:
                                                  _vm.statusList[
                                                    _vm.row.state.identifier
                                                  ].label,
                                                color:
                                                  _vm.statusList[
                                                    _vm.row.state.identifier
                                                  ].color,
                                                textColor: "white"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm.statusList[_vm.row.state.identifier]
                                      .icon
                                  ? _c("WIcon", {
                                      attrs: {
                                        icon:
                                          _vm.statusList[
                                            _vm.row.state.identifier
                                          ].icon,
                                        size: 24,
                                        color:
                                          _vm.statusList[
                                            _vm.row.state.identifier
                                          ].color
                                      }
                                    })
                                  : _c("QBadge", {
                                      staticClass: "q-pa-sm",
                                      attrs: {
                                        label:
                                          _vm.statusList[
                                            _vm.row.state.identifier
                                          ].label,
                                        color:
                                          _vm.statusList[
                                            _vm.row.state.identifier
                                          ].color,
                                        textColor: "white"
                                      }
                                    })
                              ],
                              1
                            )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _c("WIcon", {
                key: "dropdown",
                attrs: {
                  icon: "more-vertical",
                  size: 24,
                  color: "primary",
                  clickable: ""
                },
                on: {
                  click: function($event) {
                    return _vm.setSelected(_vm.row, $event)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.selected
        ? _c("Dropdown", {
            attrs: {
              opened: !!_vm.selected,
              options: (_vm.selected.options || {}).dropdown,
              coords: _vm.selected.coords
            },
            on: { onClose: _vm.cleanSelected, onClick: _vm.handleClickOption }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }