var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SettingsModal" }, [
    _c(
      "div",
      { ref: "SettingsContainer", staticClass: "SettingsContainer" },
      [
        _vm.settings
          ? _c("SettingsModalSection", {
              attrs: { values: _vm.settings },
              on: { SettingsConfig: _vm.saveSettings }
            })
          : _c(
              "div",
              {
                staticClass: "flex justify-center items-center",
                staticStyle: { height: "400px" }
              },
              [
                _c("QCircularProgress", {
                  staticClass: "q-ma-md",
                  attrs: {
                    indeterminate: "",
                    rounded: "",
                    size: "50px",
                    color: "primary"
                  }
                })
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }