import Vue from 'vue'
import Router from 'vue-router'

import Config from '@/config'
import { APIError } from '@/screens'
import MainLayout from '@/layouts/Main.vue'

import dashboardRoutes from './routes/dashboard'
import organizationsRoutes from './routes/organizations'

Vue.use(Router)

const router = new Router({
    mode: Config.vueRouter.mode,
    base: Config.vueRouter.base,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Main',
            component: MainLayout,
            redirect: { name: 'Dashboard' },
            children: [
                ...dashboardRoutes(),
                ...organizationsRoutes(),
                {
                    path: 'error',
                    name: 'Error',
                    component: APIError,
                },
            ],
        },
        {
            path: '*',
            redirect: { name: 'Dashboard' },
        },
    ],
})

export default router
