var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InsightRestrictions pa-1" },
    [
      _c("WText", { attrs: { tag: "span" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("wizard.query.insight.restrictions.description")) +
            " "
        )
      ]),
      _c("InsightExamples", {
        attrs: {
          examples: _vm.$t("wizard.query.insight.restrictions.operations")
        }
      }),
      _c(
        "WText",
        { staticClass: "mt-1 mb-1", attrs: { tag: "p", weight: "bold" } },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("wizard.query.insight.restrictions.examples.title")
              ) +
              " "
          )
        ]
      ),
      _c(
        "WCard",
        { attrs: { shadow: "" } },
        _vm._l(
          _vm.$t("wizard.query.insight.restrictions.examples.items"),
          function(e) {
            return _c(
              "WText",
              { key: e, attrs: { tag: "p", weight: "medium" } },
              [_vm._v(" " + _vm._s(e) + " ")]
            )
          }
        ),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }