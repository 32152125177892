export default {
    new: 'New working group',
    delete: 'Delete working group',
    edit: 'Edit working group',
    showActives: 'Show active working groups',
    sharedWithMe: 'Shared with me',
    createdByMe: 'Created by me',
    notifications: {
        noResult: {
            title: 'No result found',
            text: 'No working groups found for your search.',
        },
        empty: {
            title: 'No working groups found',
            text: 'There is no notification to show currently.',
        },
        created: {
            text: 'Working groups created successfully.',
        },
        updated: {
            text: 'Working groups updated successfully.',
        },
        deleted: {
            text: 'Working groups deleted successfully.',
        },
    },

    modals: {
        delete: {
            text: 'Are you sure you want to delete the working group "{name}"?',
        },
    },
}
