const invitations = [
    {
        acceptedAt: '2020-05-21T10:18:39',
        cancelledAt: '2020-05-18T10:18:39',
        email: 'test@waavi.com',
        invitedAt: '2020-05-11T10:18:39',
        revokedAt: '',
        status: 'INVITED',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        acceptedAt: '2020-05-21T10:18:39',
        cancelledAt: '2020-05-20T10:18:39',
        email: 'test1@waavi.com',
        invitedAt: '2020-05-11T10:18:39',
        revokedAt: '2020-05-12T10:18:39',
        status: 'REVOKED',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
    },
    {
        acceptedAt: '2020-05-21T10:18:39',
        cancelledAt: '2020-05-20T10:18:39',
        email: 'test2@waavi.com',
        invitedAt: '2020-05-11T10:18:39',
        revokedAt: '2020-05-12T10:18:39',
        status: 'ACCEPTED',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
    },
    {
        acceptedAt: '2020-05-21T10:18:39',
        cancelledAt: '2020-05-20T10:18:39',
        email: 'test3@waavi.com',
        invitedAt: '2020-05-11T10:18:39',
        revokedAt: '2020-05-12T10:18:39',
        status: 'CANCELLED',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
    },
]

export default {
    data: invitations,
    total: invitations.length,
    maxInvitations: invitations.length * 2,
}
