var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Step" }, [
    _c(
      "div",
      { staticClass: "Wrapper" },
      [
        _c("NameDescForm", {
          ref: "form",
          attrs: { loading: _vm.isLoading, dense: false },
          model: {
            value: _vm.value.settings,
            callback: function($$v) {
              _vm.$set(_vm.value, "settings", $$v)
            },
            expression: "value.settings"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }