export default {
    data: [
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '169037d8-ae34-4c91-954f-fae8a606e75e',
        },
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '7aa714d6-b860-46dc-8b0e-77a6b63e26b4',
        },
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '5e7da6fd-41b8-4ef8-a507-0bd638870c76',
        },
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '52bdfaa2-ede2-47c7-ba92-40ef9968fdd9',
        },
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '2c7eb1e3-7321-4614-b39f-27c231e3315a',
        },
        {
            absFrequency: 1.0,
            name: 'Loop 0.16666666666666666',
            relativeFrequency: 0.1667,
            uuid: '2c2a8e8a-f410-413d-9ce8-c9e9f9655ab2',
        },
    ],
    total: 6,
}
