var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Model pr-1 pl-1 pt-1" },
    [
      _c("WText", { staticClass: "mb-1" }, [
        _vm._v(" " + _vm._s(_vm.$t("visualization.availableVariants")) + " ")
      ]),
      _c(
        "div",
        { staticClass: "Labels flex row-stretch-top cursor-pointer" },
        [
          _c(
            "QTooltip",
            {
              attrs: {
                anchor: "center left",
                self: "center right",
                offset: [10, 10],
                contentClass: "bg-primary"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("visualization.editVariants")) + " ")]
          ),
          _c(
            "QChip",
            {
              attrs: {
                square: "",
                textColor: "white",
                color: "primary",
                outline: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.minValue) + " ")]
          ),
          _c(
            "QChip",
            {
              staticClass: "text-weight-bold",
              attrs: {
                square: "",
                textColor: "white",
                color: _vm.percentageColor
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatNumber(_vm.sliderValue) +
                      " (" +
                      _vm.percentageValue +
                      "%)"
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "QChip",
            {
              attrs: {
                square: "",
                textColor: "white",
                color: "secondary",
                outline: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.formatNumber(_vm.maxValue)) + " ")]
          ),
          _c(
            "QPopupEdit",
            { attrs: { value: undefined } },
            [
              _c("QInput", {
                ref: "textinput",
                attrs: {
                  value: _vm.sliderValue,
                  type: "number",
                  label: _vm.$t("visualization.modelLabel"),
                  max: _vm.maxValue,
                  min: _vm.minValue,
                  disable: _vm.isLoading || !_vm.minValue || _vm.disabledButton,
                  debounce: 600,
                  rules: [
                    function(value) {
                      return (
                        _vm.validators.minValue(value, _vm.minValue) ||
                        _vm.$t("validation.minValue", {
                          attribute: _vm.$t("visualization.variants"),
                          min: _vm.minValue
                        })
                      )
                    },
                    function(value) {
                      return (
                        _vm.validators.maxValue(value, _vm.maxValue) ||
                        _vm.$t("validation.maxValue", {
                          attribute: _vm.$t("visualization.variants"),
                          max: _vm.maxValue
                        })
                      )
                    }
                  ]
                },
                on: { input: _vm.handleChangeTextInput }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 q-pr-md" },
          [
            _c("QSlider", {
              staticClass: "q-ma-sm",
              attrs: {
                value: _vm.sliderValue,
                color: _vm.percentageColor,
                labelValue: _vm.variantLabel,
                min: _vm.minValue,
                max: _vm.maxValue,
                disable: _vm.isLoading || !_vm.minValue || _vm.disabledButton
              },
              on: {
                change: _vm.handleChangedModel,
                input: _vm.handleChangeSliderValue
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "WButton",
            {
              staticClass: "w-full",
              class: { "active-filter": _vm.isVariantFilterActive },
              staticStyle: { "justify-content": "center" },
              attrs: {
                outline: "",
                size: "sm",
                iconLeft: "filter",
                iconSize: 10,
                disabled: _vm.isVariantFilterActive || _vm.disabledButton
              },
              on: { click: _vm.toggleVariantFilter }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isVariantFilterActive
                      ? _vm.$t("visualization.filterVariantsApplied")
                      : _vm.$t("visualization.filterVariants")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }