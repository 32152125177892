var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "QCard",
        { staticClass: "QCard" },
        [
          _c(
            "QTabs",
            {
              staticClass: "text-grey",
              staticStyle: { height: "fit-content" },
              attrs: {
                dense: "",
                animated: "",
                noCaps: "",
                transitionNext: "fade",
                transitionPrev: "fade",
                activeColor: "primary",
                indicatorColor: "primary",
                align: "justify"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.attributes, function(tabName, index) {
              return _c("QTab", {
                key: index,
                attrs: { name: tabName, label: tabName }
              })
            }),
            1
          ),
          _c("QSeparator"),
          _c(
            "QTabPanels",
            {
              staticStyle: { "flex-grow": "1" },
              attrs: { animated: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.attributes, function(tabName, index) {
              return _c(
                "QTabPanel",
                {
                  key: index,
                  staticClass: "TabPanel",
                  attrs: { name: tabName, label: tabName }
                },
                [
                  _vm.selectedOptions
                    ? _c("PerformanceByResource", {
                        attrs: {
                          tab: _vm.tab,
                          selectedOptions: _vm.selectedOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }