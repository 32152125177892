var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("WText", { staticClass: "q-mb-sm", attrs: { size: 18 } }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("visualization.alerts.definition.createScreen.newAlert")
            ) +
            " "
        )
      ]),
      _c("WCard", { staticClass: "q-mb-sm", attrs: { bordered: "" } }, [
        _c("div", { staticClass: "q-pa-md" }, [
          _c("div", { staticClass: "row q-gutter-md items-center" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("QInput", {
                  ref: "name",
                  attrs: {
                    lazyRules: "",
                    outlined: "",
                    stackLabel: "",
                    maxlength: "100",
                    label: _vm.$t("visualization.alerts.definition.name"),
                    rules: [
                      function(value) {
                        return (
                          _vm.validators.required(value) ||
                          _vm.$t("validation.required", {
                            attribute: _vm.$t(
                              "visualization.alerts.definition.name"
                            )
                          })
                        )
                      }
                    ]
                  },
                  model: {
                    value: _vm.nameFld,
                    callback: function($$v) {
                      _vm.nameFld = $$v
                    },
                    expression: "nameFld"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("QField", {
                  attrs: {
                    label: _vm.$t("visualization.alerts.definition.type"),
                    stackLabel: "",
                    borderless: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "control",
                      fn: function() {
                        return [
                          _c("QBtnToggle", {
                            staticClass: "q-mt-sm",
                            attrs: {
                              toggleColor: "primary",
                              unelevated: "",
                              noCaps: "",
                              options: _vm.typeOptions
                            },
                            on: { input: _vm.setDefaultSeverity },
                            model: {
                              value: _vm.typeTgl,
                              callback: function($$v) {
                                _vm.typeTgl = $$v
                              },
                              expression: "typeTgl"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("QField", {
                  attrs: {
                    label: _vm.$t("visualization.alerts.definition.severity"),
                    stackLabel: "",
                    borderless: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "control",
                      fn: function() {
                        return [
                          _c("QBtnToggle", {
                            staticClass: "q-mt-sm",
                            attrs: {
                              toggleColor: _vm.getSeverityColor(
                                _vm.severityTgl
                              ),
                              toggleTextColor: "black",
                              push: "",
                              unelevated: "",
                              noCaps: "",
                              options:
                                _vm.typeTgl === _vm.DEFAULT_TYPE
                                  ? _vm.severityOptions
                                  : _vm.riskOptions
                            },
                            model: {
                              value: _vm.severityTgl,
                              callback: function($$v) {
                                _vm.severityTgl = $$v
                              },
                              expression: "severityTgl"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _vm.typeTgl !== "PREDICTION"
              ? _c(
                  "div",
                  { staticClass: "col-sm-12" },
                  [
                    _c("QSelect", {
                      ref: "filters",
                      attrs: {
                        options: _vm.formattedFilterSets,
                        label: _vm.$t(
                          "visualization.alerts.definition.filterSet"
                        ),
                        stackLabel: "",
                        rules: [
                          function(value) {
                            return (
                              _vm.validators.required(value) ||
                              _vm.$t("validation.required", {
                                attribute: _vm.$t(
                                  "visualization.alerts.definition.filterSet"
                                )
                              })
                            )
                          }
                        ]
                      },
                      model: {
                        value: _vm.fSet,
                        callback: function($$v) {
                          _vm.fSet = $$v
                        },
                        expression: "fSet"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "WCard",
        { staticClass: "q-mb-md", attrs: { bordered: "" } },
        [
          _c("QField", {
            attrs: {
              label: _vm.$t(
                "visualization.alerts.definition.createScreen.sendEmail"
              ),
              stackLabel: "",
              borderless: ""
            },
            scopedSlots: _vm._u([
              {
                key: "control",
                fn: function() {
                  return [
                    _c("QBtnToggle", {
                      staticClass: "q-mt-sm",
                      attrs: {
                        toggleColor: "primary",
                        unelevated: "",
                        noCaps: "",
                        options: _vm.sendOptions
                      },
                      model: {
                        value: _vm.sendTgl,
                        callback: function($$v) {
                          _vm.sendTgl = $$v
                        },
                        expression: "sendTgl"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("Transition", { attrs: { mode: "out-in", appear: "" } }, [
            _vm.sendTgl === "other"
              ? _c(
                  "div",
                  [
                    _c("QInput", {
                      ref: "mail",
                      attrs: {
                        lazyRules: "",
                        outlined: "",
                        stackLabel: "",
                        maxlength: "100",
                        label: _vm.$t(
                          "visualization.alerts.definition.createScreen.notifyTo"
                        ),
                        rules: [
                          function(value) {
                            return (
                              _vm.validators.required(value) ||
                              _vm.$t("validation.required", {
                                attribute: _vm.$t(
                                  "visualization.alerts.definition.createScreen.notifyTo"
                                )
                              })
                            )
                          }
                        ]
                      },
                      model: {
                        value: _vm.emailAdrs,
                        callback: function($$v) {
                          _vm.emailAdrs = $$v
                        },
                        expression: "emailAdrs"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex q-gutter-md justify-end" },
        [
          _c("QBtn", {
            attrs: {
              label: _vm.$t(
                "visualization.alerts.definition.createScreen.cancel"
              ),
              color: "primary",
              noCaps: "",
              outline: ""
            },
            on: { click: _vm.cancel }
          }),
          _c("QBtn", {
            attrs: {
              label: _vm.$t(
                "visualization.alerts.definition.createScreen.addAlert"
              ),
              color: "primary",
              noCaps: "",
              disabled: !_vm.saveBtnStatus
            },
            on: { click: _vm.create }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }