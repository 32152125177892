<template>
    <div>
        <QTabPanels
            v-model="tab"
            animated
            transitionNext="fade"
            transitionPrev="fade"
        >
            <QTabPanel name="TABLE">
                <TracesTable
                    ref="tracesTable"
                    class="TracesTable mt-1"
                    :columns="columns"
                    :rows="rows"
                    :loading="isLoading"
                    :total="total"
                    :filteredTraces="filteredTraces"
                    @table-request="handleFetchData"
                    @download="openExport"
                    @switchView="toggleView"
                    @datasetFromFiltered="generateDataset"
                />
                <AttributeSelector
                    ref="attrSelector"
                    :attrs="attributes"
                    @export="handleDownload"
                />
            </QTabPanel>
            <QTabPanel name="PLOT">
                <TracesPlot
                    :processId="processId"
                    :activities="activities"
                    :colors="colors"
                    @switchView="toggleView"
                />
            </QTabPanel>
        </QTabPanels>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { filtersStorageMixin } from '@/mixins'
import { download } from '@/services/download'
import TracesTable from './components/TracesTable.vue'
import AttributeSelector from './components/AttributeSelector.vue'
import TracesPlot from './components/TracesPlot.vue'
import { getColorArray } from '@/services/colormap'

const DEFAULT_TAB = 'TABLE'
const TABS = ['TABLE', 'PLOT']

export default {
    name: 'TracesSection',
    components: { TracesTable, AttributeSelector, TracesPlot },
    mixins: [filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filteredTraces: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        attributes: VueTypes.array,
    },
    data () {
        return {
            columns: undefined,
            rows: undefined,
            total: 0,
            isLoading: false,
            tab: DEFAULT_TAB,
            activities: null,
            colors: null,
        }
    },
    computed: {
        params () {
            const pagination = this.$refs.tracesTable.getPagination()
            return {
                ...pagination,
            }
        },
    },
    mounted () {
        this.getTraces(this.params)
        this.getActivities()
    },
    methods: {
        async getActivities () {
            const datasetId = this.processId
            const filters = this.formatFiltersParam(this.visualizationFilters)
            const params = { filters }
            apiRequest(Api().datasets.activities({ datasetId, params }))
                .then((data) => {
                    this.activities = data.map(x => x.id).sort()
                    this.colors = getColorArray(this.activities.length)
                })
                .catch(notifyError)
        },
        getTraces (p) {
            const { processId } = this
            const params = this.formatParams(p)
            this.isLoading = true
            apiRequest(Api().visualizations.traces({ processId, params }))
                .then(({ data, total }) => {
                    if (!data.length) {
                        this.rows = data
                        this.total = total
                        return
                    }
                    this.columns = this.formatColumns(data)
                    this.rows = this.formatRows(data)
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async generateDataset (name) {
            const datasetId = this.processId
            const filters = this.formatFiltersParam(this.visualizationFilters)
            const params = { filters, name }
            notifySuccess(this.$t('visualization.traces.creatingDataset'))
            apiRequest(Api().datasets.createChild({ datasetId, params }))
                .then(() => {
                    notifySuccess(this.$t('visualization.traces.datasetCreated'))
                })
                .catch(notifyError)
        },
        formatParams (params) {
            const { page, rowsPerPage, search } = params
            const filters = this.formatFiltersParam(this.visualizationFilters)
            return {
                filters,
                ...(rowsPerPage ? { start: page - 1 } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                search: search || '',
            }
        },
        formatRows (data) {
            return data.map(({ attributes, ...restData }) => ({ ...restData, ...attributes }))
        },
        formatColumns (data) {
            const [{ attributes, ...rest }] = data
            return [
                ...Object.keys(rest).map(f => this.formatField(f, 'primary')),
                ...Object.keys(attributes).map(attrKey => this.formatField(attrKey, 'attribute')).sort((a, b) => a.name.localeCompare(b.name)),
            ]
        },
        formatField (field, type) {
            return {
                name: field,
                label: field,
                field,
                align: 'center',
                format: val => (val ?? '-'),
                headerClasses: `${type === 'primary' ? 'text-white bg-primary' : 'bg-grey-4'}`,
                classes: type === 'primary' ? 'bg-light-blue-1 ellipsis' : 'bg-grey-1 ellipsis',
                style: 'max-width: 500px',
                headerStyle: 'max-width: 300px',
            }
        },
        handleFetchData (data) {
            this.getTraces(data)
        },
        openExport () {
            this.$refs.attrSelector.open()
        },
        handleDownload (selAttrs) {
            this.isLoading = true
            notifySuccess(this.$t('visualization.traces.downloadStarted'))
            const filters = this.formatFiltersParam(this.visualizationFilters)
            const attributes = selAttrs
            apiRequest(Api().visualizations.downloadTraces({ processId: this.processId, params: { filters, attributes } }))
                .then((data) => {
                    download({ name: this.processId, data })
                    notifySuccess(this.$t('visualization.traces.downloadOK'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        toggleView (value) {
            [this.tab] = TABS.filter(x => x !== value)
        },
    },
}
</script>
