import Vue from 'vue/dist/vue'
import VueTypes from 'vue-types'
import Utils from '../utils'

const styles = {
    container: {
        display: 'inline-block',
        'white-space': 'nowrap',
    },
    content: {
        display: 'inline-block',
        padding: '2px 6px',
    },
}

export default Vue.extend({
    name: 'Arc',
    props: {
        id: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        className: VueTypes.string,
        name: VueTypes.string,
        statistics: VueTypes.any,
        model: VueTypes.shape({
            values: VueTypes.any,
        }).loose,
    },
    computed: {
        modelType () {
            return (this.model || {}).values || Utils.graphValues.NONE
        },
        value () {
            return Utils.getInfoModel(this.statistics, this.model)
        },
        color () {
            return Utils.getColor(this.statistics.overlap)
        },
        dataSets () {
            return Object.keys(this.colors || []).reduce(
                (acc, key) => ({
                    ...acc,
                    [`data-${key}`]: this.colors[key],
                }),
                {},
            )
        },
    },
    beforeMount () {
        this.styles = styles
    },
    template: `
        <div :id="id" class="Arc" :class="[className]" :style="styles.container" v-bind="dataSets">
            <div v-if="statistics && value" class="node-value" >
                <span :class="[ 'span-' + modelType ]" :style="styles.content" v-html="value"></span>
            </div>
        </div>
    `,
})
