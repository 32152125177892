<template>
    <div class="Container">
        <div class="Card">
            <QCard
                class="MeetingBox Section"
                flat
            >
                <QCardSection class="text-h6 text-weight-bold text-center">
                    {{ numFormatter(meetingSLA) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="MeetingPercent">{{ meetingSLAPercent }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection text-h6">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                {{ $t('booster.prediction.predictionHighlights.meetingSLA') }}
                <span class="MeetingColor">
                    {{ $t('booster.prediction.predictionHighlights.meetingSLAColor') }}
                </span>
            </span>
        </div>
        <div class="Card">
            <QCard
                class="UnderRiskBox Section"
                flat
            >
                <QCardSection class="text-h6 text-weight-bold text-center">
                    {{ numFormatter(underRisk) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="UnderRiskPercent">{{ underRiskPercent }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection text-h6">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                <span class="UnderRiskColor">
                    {{ $t('booster.prediction.predictionHighlights.missingSLAColor') }}
                </span>
                {{ $t('booster.prediction.predictionHighlights.missingSLA') }}
            </span>
        </div>
        <div class="Card">
            <QCard
                class="ExceedingBox Section"
                flat
            >
                <QCardSection class="text-h6 text-weight-bold text-center">
                    {{ numFormatter(exceeding) }}
                    {{ $t('booster.prediction.predictionHighlights.cases') }}
                    <span class="ExceedingPercent">{{ exceedingPercent }}%</span>
                </QCardSection>
            </QCard>
            <span class="Section TextSection text-h6">
                {{ $t('booster.prediction.predictionHighlights.ongoingCases') }}
                {{ $t('booster.prediction.predictionHighlights.exceeding') }}
                <span class="ExceedingColor">
                    {{ $t('booster.prediction.predictionHighlights.exceedingColor') }}
                </span>
            </span>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'PredictionBoosterMetricsPanel',
    props: {
        meetingSLA: VueTypes.number.def(15827),
        meetingSLAPercent: VueTypes.number.def(45),
        underRisk: VueTypes.number.def(6254),
        underRiskPercent: VueTypes.number.def(20),
        exceeding: VueTypes.number.def(11245),
        exceedingPercent: VueTypes.number.def(5),
    },
    computed: {
    },
    methods: {
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
    },
}
</script>
<style scoped lang="scss">
    .Container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .Section {
        width: 50%;
    }
    .TextSection {
        padding-left: 1vw;
    }
    .Card {
        width: 100%;
        margin-bottom: 2vh;
        display: flex;
    }
    .MeetingBox {
        border: 3px solid #04c48c;
    }
    .UnderRiskBox {
        border: 3px solid #fc8a28;
    }
    .ExceedingBox {
        border: 3px solid #fb2332;
    }
    .MeetingPercent {
        color: #04c48c;
        padding-left: 1vw;
    }
    .UnderRiskPercent {
        color: #fc8a28;
        padding-left: 1vw;
    }
    .ExceedingPercent {
        color: #fb2332;
        padding-left: 1vw;
    }
    .MeetingColor {
        color: #04c48c;
    }
    .UnderRiskColor {
        color: #fc8a28;
    }
    .ExceedingColor {
        color: #fb2332;
    }
</style>
