<!-- eslint-disable prefer-destructuring -->
<template>
    <div>
        <div class="Title">
            {{ $t('wizard.boosterWizard.chooseApproach.title') }}
        </div>
        <div class="Options">
            <div class="Option">
                <h6>{{ $t('wizard.boosterWizard.chooseApproach.performanceBooster') }}</h6>
            </div>
            <div class="Option">
                <h6>{{ $t('wizard.boosterWizard.chooseApproach.customAnalysis') }}</h6>
            </div>
        </div>
        <div class="Options">
            <div class="Option Images">
                <q-btn
                    @click="handleGoToWizardBooster"
                >
                    <img :src="require(`@/assets/images/performance_booster.png`)">
                </q-btn>
            </div>
            <div class="Option Images">
                <q-btn
                    @click="handleGoToVisualization"
                >
                    <img :src="require(`@/assets/images/manual_analysis.png`)">
                </q-btn>
            </div>
        </div>
        <div class="Options">
            <div class="OptionDescription">
                {{ $t('wizard.boosterWizard.chooseApproach.performanceBoosterDescription') }}
            </div>
            <div class="OptionDescription">
                {{ $t('wizard.boosterWizard.chooseApproach.customAnalysisDescription') }}
            </div>
        </div>
    </div>
</template>
<script>
import {
    Api, apiRequest, notifyError,
} from '@/api'

const DEFAULT_VISUALIZATION = 'MODEL'

export default {
    name: 'WizardChooseApproach',
    data () {
        return {
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            name: this.$route.params.name,
            tab: this.$route.params.tab,
        }
    },
    mounted () {
    },
    methods: {
        handleGoToVisualization () {
            apiRequest(Api().datasets.setOpened({
                datasetId: this.$route.params.processId,
            }))
                .then(() => {
                    this.$router.push({ name: 'ProcessVisualization', params: { projectId: this.projectId, processId: this.processId, tab: DEFAULT_VISUALIZATION } })
                })
                .catch(notifyError)
        },
        handleGoToWizardBooster () {
            this.$router.push({ name: 'WizardSLA', params: { projectId: this.projectId, processId: this.processId, name: this.name } })
        },
    },
}
</script>
<style scoped lang="scss">
    .Head {
        margin-left: 10vw;
    }
    .Title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin: 6vh;
    }
    .Options {
        display: flex;
        justify-content: space-around;
        text-align: center;
        margin: 2vh;
    }
    .OptionDescription {
        width: 25% !important;
        font-size: 16px;
    }

    .Images :hover {
        color: $secondary;
    }

    .Images img {
        max-width: 250px;
    }
</style>
