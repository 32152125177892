var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Badge",
      class: [_vm.color, { clickable: _vm.clickable }],
      on: {
        click: function($event) {
          _vm.clickable && _vm.$emit("onClick")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "flex row-left-center" },
        [
          _vm.icon
            ? _c("WIcon", {
                attrs: {
                  icon: _vm.icon,
                  size: _vm.iconSize,
                  color: _vm.textColor
                }
              })
            : _vm._e(),
          _c(
            "WText",
            {
              attrs: {
                tag: "span",
                typo: "span",
                weight: "semi",
                size: _vm.size,
                color: _vm.textColor
              }
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          ),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }