var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Sidebar flex col-stretch" },
    [
      _c("Logo"),
      (_vm.tabs || []).length
        ? _c(
            "div",
            { staticClass: "Tabs flex-1" },
            [
              _c("Tabs", {
                attrs: { value: _vm.value, tabs: _vm.tabs },
                on: { input: _vm.handleChangeTab }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "Bottom" },
        [
          _c("UserAvatar", { attrs: { hideFullName: _vm.isMobile } }),
          _c("Powered", { staticClass: "mt-1" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }