import Vue from 'vue'

import '@/components/registerAsGlobal'
import '@/plugins'
import '@/config/VueValuesStorage'
import i18n from '@/lang/i18n'
import router from '@/router'
import routerBeforeEach from '@/router/beforeEach'
import { create as createApi } from '@/api'
import AuthService from '@/services/auth'

import App from './App.vue'
import moment from './utils/moment'

Vue.config.productionTip = false
console.log('mode', process.env.NODE_ENV)
Vue.prototype.$DEV = process.env.NODE_ENV === 'development'

routerBeforeEach({ router })

createApi()

moment()

AuthService.signin()
    .then(({ ok }) => {
        if (!ok) window.location.reload()
        else console.info('Authenticated Success')

        new Vue({
            router,
            i18n,
            render: h => h(App),
        }).$mount('#app')
    })
    .catch((error) => {
        console.info('Authenticated Failed', error)
    })
