var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Step" }, [
    _c(
      "div",
      { staticClass: "row justify-center" },
      [
        (_vm.steps || []).length
          ? _c(
              "WCard",
              {
                staticClass: "col-8 col-lg-4",
                attrs: { flat: "", bordered: "" }
              },
              [
                _c(
                  "CollapseItem",
                  {
                    attrs: {
                      icon: "settings",
                      title: _vm.steps[0].title,
                      opened: _vm.collapses[_vm.steps[0].name]
                    },
                    on: {
                      onClick: function($event) {
                        return _vm.handleClick(_vm.steps[0].name)
                      }
                    }
                  },
                  [
                    _vm.value.settings
                      ? _c(
                          "div",
                          { staticClass: "pa-1" },
                          [
                            _c(
                              "WCard",
                              { attrs: { flat: "", bordered: "" } },
                              [
                                _vm.value.settings.name
                                  ? _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("wizard.name")) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.value.settings.name
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("Divider"),
                                _vm.value.settings.description
                                  ? _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("wizard.description")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.value.settings.description
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "CollapseItem",
                  {
                    attrs: {
                      icon: "file-text",
                      title: _vm.steps[1].title,
                      opened: _vm.collapses[_vm.steps[1].name]
                    },
                    on: {
                      onClick: function($event) {
                        return _vm.handleClick(_vm.steps[1].name)
                      }
                    }
                  },
                  [
                    _vm.value.file
                      ? _c(
                          "div",
                          { staticClass: "pa-1" },
                          [
                            _c("WCard", { attrs: { flat: "", bordered: "" } }, [
                              _c(
                                "div",
                                { staticClass: "pa-0-5" },
                                [
                                  _c("WText", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("wizard.file")) + " "
                                    )
                                  ]),
                                  _c(
                                    "WText",
                                    { attrs: { size: 12, color: "gray02" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.value.file.name) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }