<template>
    <div
        v-if="isOpen"
        class="CaseCompletionSettings"
    >
        <div class="CaseCompletion">
            <WText
                class="title"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.caseCompletion.name') }}
            </WText>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.startingActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="startingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="activities"
                        @input="saveWithoutButton"
                    />
                </div>
                <div
                    v-for="(selected, index) in startingA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'startingA' )"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.occurringActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="occurringA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="activities"
                        @input="saveWithoutButton"
                    />
                </div>
                <div
                    v-for="(selected, index) in occurringA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'occurringA')"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div class="container">
                <WText
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.settings.endingActivities') }}
                </WText>
                <div class="OptionsGroup">
                    <QSelect
                        v-model="endingA"
                        outlined
                        multiple
                        dense
                        hideSelected
                        class="Options"
                        labelColor="primary"
                        color="primary"
                        :label="$t('visualization.settings.activities')"
                        :options="activities"
                        @input="saveWithoutButton"
                    />
                </div>
                <div
                    v-for="(selected, index) in endingA"
                    :key="index"
                >
                    <QChip
                        removable
                        color="primary"
                        textColor="white"
                        @remove="deleteIndexValue(index, 'endingA')"
                    >
                        {{ selected }}
                    </Qchip>
                </div>
            </div>
            <div
                v-if="saveButton"
                class="row justify-end mt-1"
            >
                <WButton
                    class="buttons"
                    outline
                    color="primary"
                    @click="saveWithButton"
                >
                    {{ $t('visualization.filters.save') }}
                </WButton>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'CaseCompletionConditions',
    emits: ['CaseCompletionConditionsConfig'],
    components: {},
    mixins: [],
    props: {
        saveButton: VueTypes.bool.def(true),
        isOpen: VueTypes.bool.def(false),
        attributes: VueTypes.array,
        values: VueTypes.object,
    },
    data () {
        return {
            inputOptions: false,
            startingA: [],
            occurringA: [],
            endingA: [],
            timeout: null,
            activities: null,
            optionValue: 'id',
            optionLabel: 'label',
            title: "$t('visualization.settings.cost.name')",
        }
    },
    computed: {
    },
    watch: {
    },
    beforeMount () {
    },
    mounted () {
        this.getActivities()
        this.checkIfValues()
        this.saveWithoutButton()
    },
    methods: {
        checkIfValues () {
            if (this.values) {
                this.endingA = this.values.endingActivities
                this.occurringA = this.values.occurringActivities
                this.startingA = this.values.startingActivities
            }
        },
        saveWithoutButton () {
            if (!this.saveButton) {
                this.saveCaseCC()
            }
        },
        saveWithButton () {
            this.saveCaseCC()
        },
        saveCaseCC () {
            const caseCompletion = {
                endingActivities: this.endingA ? this.endingA : [],
                occurringActivities: this.occurringA ? this.occurringA : [],
                startingActivities: this.startingA ? this.startingA : [],
            }

            this.$emit('CaseCompletionConditionsConfig', { data: caseCompletion, type: 'caseCompletion' })
        },
        async getActivities () {
            const datasetId = this.$route.params.processId
            const params = { filters: [] }
            apiRequest(Api().datasets.activities({ datasetId, params }))
                .then((data) => {
                    this.activities = data.map(act => act.id)
                })
                .catch(notifyError)
        },
        deleteIndexValue (index, nombre) {
            if (nombre === 'startingA') {
                this.startingA.splice(index, 1)
            } else if (nombre === 'occurringA') {
                this.occurringA.splice(index, 1)
            } else {
                this.endingA.splice(index, 1)
            }
            this.saveCaseCC()
        },
    },
}
</script>
<style lang="scss" scoped>
.CaseCompletionSettings {
    top: $subheader-height;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    display: flex;
    .CaseCompletion {
        width:100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
    }
    .title {
        margin-bottom: 10px;
    }
    .OptionsGroup {
        display: flex;
        gap: 10px;
        padding: 10px 0;
    }
    .Options {
        width: 60%;
    }
    .container {
        width:auto;
        padding: 30px;
        border: solid 1px rgba($black, 0.2);
        border-radius: 5px;
        margin-top: 10px;
        justify-content: center;
    }

    .buttons {
        width: 80px;
    }
}
</style>
