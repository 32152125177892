import OrganizationLayout from '@/layouts/Organization.vue'
import SimpleLayout from '@/layouts/Simple.vue'

import {
    Projects,
    Notifications,
    Invitations,
} from '@/screens'

import projectsRoutes from './projects'

export default meta => [
    {
        path: 'organizations/:organizationId',
        name: 'Organization',
        component: OrganizationLayout,
        redirect: { name: 'Projects' },
        children: [
            {
                path: 'projects',
                name: 'Projects',
                component: Projects,
            },
            {
                path: 'invitations',
                name: 'Invitations',
                component: Invitations,
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: Notifications,
            },
        ],
    },
    {
        path: 'organizations/:organizationId/projects/:projectId',
        name: 'Project',
        component: SimpleLayout,
        redirect: { name: 'ProjectDetail' },
        children: projectsRoutes,
    },
]
