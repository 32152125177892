<template>
    <div class="Loops pr-1 pl-1 pt-1">
        <div class="flex row-stretch-center flex-nowrap">
            <WText class="pr-0-5">
                {{ $t('visualization.nLoops') }}
            </WText>
            <QInput
                v-model="search"
                dense
                outlined
                type="search"
                :label="$t('actions.search')"
                :disable="disabledButton"
                class="Search search-input float-left"
                @keyup.enter="handleSearchLoops(search)"
            >
                <template v-slot:append>
                    <WIcon
                        v-if="search"
                        id="clean"
                        icon="close"
                        :size="20"
                        clickable
                        @click="handleResetSearch"
                    />
                    <WIcon
                        id="search"
                        icon="search1"
                        :size="20"
                        clickable
                        @click="handleSearchLoops(search)"
                    />
                </template>
            </QInput>
        </div>
        <Tabs
            v-model="tab"
            class="Tabs"
            :tabs="tabs"
            horizontal
            @input="handleChangeTab"
        />

        <QTabPanels v-model="tab">
            <QTabPanel
                class="model-tab-panel"
                :name="tab"
            >
                <div class="scroll">
                    <WLoading
                        v-if="isLoading"
                        visible
                    />
                    <div v-show="showLoops">
                        <a
                            v-for="(item) in loops"
                            :key="item.id"
                            :disabled="disabledButton"
                            href="#"
                            class="Bar mt-0-5"
                            :class="{ selected: item.id === activeLoop }"
                            @click.prevent="handleChangeLoop(item.id)"
                        >
                            <div class="flex row-stretch-center">
                                <WText
                                    weight="semi"
                                    :size="12"
                                >
                                    {{ item.name.replace('Loop', $t('visualization.loop')) }}
                                </WText>
                                <WText
                                    weight="semi"
                                    :size="12"
                                >
                                    {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                </WText>
                            </div>
                            <QLinearProgress
                                size="10px"
                                :value="getAbsFrequency(item.absFrequency)"
                                class="LinearProgress"
                            />
                            <QTooltip
                                class="Tooltip"
                                maxWidth="500px"
                                contentClass="text-primary"
                                contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                anchor="center right"
                                self="center left"
                            >
                                <WText
                                    color="primary"
                                >
                                    <strong>
                                        {{ item.name.replace('Loop', $t('visualization.loop')) }}
                                    </strong>: {{ $t('visualization.loopsFound') }} {{ item.absFrequency }} {{ $t('visualization.cases') }}
                                    ({{ getAbsFrequency(item.absFrequency) | percentage }}%)
                                </WText>
                            </QTooltip>
                        </a>
                    </div>
                    <div
                        v-if="!showLoops"
                        class="flex col-center mt-2"
                    >
                        <Badge
                            icon="alert-triangle"
                            :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.loops') })"
                            color="warning"
                            textColor="black"
                            class="row-center pt-0-5 pb-0-5 mb-0-5"
                        />
                    </div>
                </div>
                <div
                    v-if="showLoops"
                    class="flex row-center pt-1"
                >
                    <QPagination
                        v-model="pagination.start"
                        :max="pagination.max"
                        :directionLinks="true"
                        :maxPages="4"
                        :disable="disabledButton"
                        size="11.5px"
                        :boundaryNumbers="true"
                        @input="handleChangePagination"
                    />
                </div>
                <div class="mt-1">
                    <WButton
                        outline
                        class="w-full"
                        style="justify-content: center;"
                        size="sm"
                        iconLeft="filter"
                        :iconSize="10"
                        :disabled="isLoopFilterActive || disabledButton || emptyLoops"
                        :class="{ 'active-filter': isLoopFilterActive }"
                        @click="toggleLoopFilter"
                    >
                        {{ isLoopFilterActive ? $t('visualization.filterLoopApplied') : $t('visualization.filterLoop') }}
                    </WButton>
                </div>
            </QTabPanel>
        </QTabPanels>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { Badge, Tabs } from '@/components'
import { filtersStorageMixin, filtersMixin } from '@/mixins'

const INITIAL_LOOPS_PAGINATION = { start: 0, limit: 8 }

const TABS = {
    LOOPS: 'loops',
    SELF_LOOPS: 'self-loops',
}

export default {
    components: {
        Badge,
        Tabs,
    },
    inject: ['App'],
    mixins: [filtersStorageMixin, filtersMixin],
    props: {
        value: VueTypes.shape({
            heatMapValue: VueTypes.string,
        }).loose,
        info: VueTypes.object,
        filteredInfo: VueTypes.object,
        percentageMode: VueTypes.string,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        disabledButton: VueTypes.bool.def(false),
    },
    data () {
        return {
            activeLoop: undefined,
            loops: undefined,
            isLoading: undefined,
            isFetching: undefined,
            search: '',
            pagination: {
                start: 1,
                max: INITIAL_LOOPS_PAGINATION.limit,
            },
            tab: TABS.LOOPS,
            loopvariants: undefined,
            isLoopFilterActive: false,
            emptyLoops: false,
        }
    },
    computed: {
        showLoops () {
            return !this.isLoading && (this.loops || []).length
        },
        tabs () {
            return [
                { id: TABS.LOOPS, label: this.$t('visualization.nLoops') },
                { id: TABS.SELF_LOOPS, label: this.$t('visualization.selfLoops') },
            ]
        },
    },
    watch: {
        isFetching: 'emitVariantsLoading',
        isLoading: 'emitVariantsLoading',
    },
    mounted () {
        this.checkForLoopFilter()
        if (this.processId) this.getLoops()
    },
    methods: {
        checkForLoopFilter () {
            this.$root.$emit('checkForVariantFilter')
            this.$root.$on('hasLoopFilter', (variantFilter) => {
                this.isLoopFilterActive = variantFilter
            })
        },
        toggleLoopFilter () {
            if (!this.isLoopFilterActive) {
                this.filterByLoop()
            }
        },
        filterByLoop () {
            const formattedValue = (this.$t('visualization.filterLoopText') + this.activeLoop)
            this.$root.$emit('filterByLoop', { variant: this.loopvariants, loopName: formattedValue })
        },
        handleDirectionChange (value) {
            this.directionValue = value
            this.handleInput('directionValue', { value: value ? 'LR' : 'TB' })
        },
        handleInput (key, { value }) {
            this.$emit('input', { ...(this.value || {}), [key]: value })
        },
        handleChangeTab (tab) {
            this.pagination.start = 1
            this.tab = tab
            this.getLoops()
        },
        handleChangeLoop (id) {
            this.activeLoop = id
            this.getLoop(id)
        },
        normalizeLoops (loops) {
            return (loops || []).map(loop => ({ ...loop, value: loop.relativeFrequency }))
        },
        async getLoops (param) {
            const self = this.tab === TABS.SELF_LOOPS
            this.isLoading = true
            const params = this.normalizeParams(param, self)
            apiRequest(Api().visualizations.loops({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    const [first] = data
                    this.loops = this.normalizeLoops(data)
                    this.pagination.max = this.normalizePaginationMax(total)
                    if (first) this.handleChangeLoop(first.id)
                    else if (data.length === 0) {
                        this.emptyLoops = true
                        this.$emit('onLoadChart', { variant: [], variantId: [this.processId] })
                    } else this.$emit('onLoadChart')
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoading = false
                })
        },
        normalizePaginationMax (value) {
            const max = Math.round(value / INITIAL_LOOPS_PAGINATION.limit)
            return max || 1
        },
        handleChangePagination (start) {
            this.getLoops({ start: start - 1, search: this.search || '' })
        },
        getAbsFrequency (absFrequency) {
            const { cases } = this.percentageMode === 'TOTAL' ? this.info : this.filteredInfo
            return cases ? absFrequency / cases : 0
        },
        normalizeParams (param, self) {
            const filters = this.formatFiltersParam(this.visualizationFilters)
            return {
                filters,
                ...INITIAL_LOOPS_PAGINATION,
                start: this.pagination.start - 1,
                ...(self ? { self } : {}),
                ...(typeof param === 'string' ? {} : param),
            }
        },
        handleResetSearch () {
            this.search = ''
            this.pagination.start = 1
            this.getLoops({ search: this.search, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleSearchLoops (search) {
            this.pagination.start = 1
            this.getLoops({ search, start: INITIAL_LOOPS_PAGINATION.start })
        },
        emitVariantsLoading () {
            if (!this.isFetching && !this.isLoading) {
                this.$emit('variantsLoading', false)
            } else {
                this.$emit('variantsLoading', true)
            }
        },
        async getLoop (loopId) {
            this.isFetching = true
            const filters = this.formatFiltersParam(this.visualizationFilters)
            const params = { filters }
            apiRequest(Api().visualizations.loop({ processId: this.processId, loopId, params }))
                .then(({ data }) => {
                    const [variant] = data || []
                    this.loopvariants = variant.compliantVariants
                    this.$emit('onLoadChart', { ...variant, loopId })
                })
                .catch(notifyError)
                .finally(() => {
                    this.isFetching = false
                })
        },
    },
}
</script>
<style scoped lang="scss">
.scroll {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.Tabs {
    margin-top: 10px;
}

.Bar {
    display: block;

    &:hover, &.selected {
        .WText { color: $black; }
        .LinearProgress { color: $accent; }
    }

    .WText {
        color: $gray-02;
        margin-bottom: 5px;
    }

    .LinearProgress {
        color: $secondary;
        border-radius: $border-radius;
        transition: $transition;
    }
}

.Stats{
    flex-wrap: nowrap;
}
.WButton {
    padding: 7px;
}

.WSwitch {
  height: 20px;
  width: 34px;
  &::v-deep .Slider {
    position: absolute;
    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      right: 2px;
      top: 50%;
      transform: translate(0px, -50%);
      border-radius: 50%;
      background-color: $white;
      transition: 0.4s;
    }
  }
  &::v-deep input {
    &:checked:not(.disabled) {
      & + .Slider {
        &:before {
          transform: translate(16px, -50%);
        }
      }
    }
  }
}

.Tabs {
    margin-bottom: 15px;
    &::v-deep {
        .Container {
            padding: 0;
        }
        .Tab {
            min-height: auto;
        }
    }
}
</style>
