var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [!_vm.preview ? _c("WLoading", { attrs: { visible: "" } }) : _vm._e()],
        1
      ),
      _vm.preview
        ? _c(
            "div",
            { staticClass: "Step" },
            [
              _c(
                "Preview",
                _vm._b(
                  {
                    attrs: { value: _vm.value.config, loading: _vm.isLoading },
                    on: { input: _vm.handleInput }
                  },
                  "Preview",
                  _vm.preview,
                  false
                )
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }