import { render, staticRenderFns } from "./SimpleContextualInfo.vue?vue&type=template&id=448c9801&"
import script from "./SimpleContextualInfo.vue?vue&type=script&lang=js&"
export * from "./SimpleContextualInfo.vue?vue&type=script&lang=js&"
import style0 from "./SimpleContextualInfo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/porto/inverbis/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('448c9801')) {
      api.createRecord('448c9801', component.options)
    } else {
      api.reload('448c9801', component.options)
    }
    module.hot.accept("./SimpleContextualInfo.vue?vue&type=template&id=448c9801&", function () {
      api.rerender('448c9801', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/screens/Project/Process/Visualization/components/Contextualnfo/SimpleContextualInfo.vue"
export default component.exports