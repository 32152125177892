<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('visualization.bpmnModal.title') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-ma-md"
                onSubmit="return false;"
                @submit="handleSaveModel"
            >
                <QInput
                    ref="name"
                    v-model="modelName"
                    :label="$t('visualization.bpmnModal.name')"
                    outlined
                    stackLabel
                    dense
                    :rules="[val => !!val || 'Field is required']"
                />
                <QBtn
                    :label="$t('visualization.bpmnModal.submit')"
                    noCaps
                    color="primary"
                    unelevated
                    type="submit"
                />
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal } from '@/components'

export default {
    name: 'Upload',
    emits: [
        'save',
    ],
    components: {
        Modal,
    },
    data () {
        return {
            opened: false,
            modelName: undefined,
        }
    },
    computed: {
    },
    methods: {
        open () {
            this.reset()
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            this.modelName = undefined
            this.file = undefined
        },
        handleSaveModel () {
            if (this.$refs.name.validate()) {
                this.$emit('save', {
                    modelName: this.modelName,
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &::v-deep .Dialog {
        width: 100%;
        max-width: 400px;
    }
}
</style>
