<template>
    <div
        v-if="notShowBooster2"
        class="Prediction"
    >
        <PredictionHighlights class="PredictionSection" />
        <PredictedPerformance class="PredictionSection" />
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { PredictedPerformance, PredictionHighlights } from './components'

export default {
    name: 'Prediction',
    components: {
        PredictionHighlights,
        PredictedPerformance,
    },
    props: {
        processId: VueTypes.string,
    },
    data () {
        return {
            notShowBooster2: false,
        }
    },
    mounted () {
        console.log('ProcessID en prediction')
        console.log(this.processId)
    },
}
</script>
<style scoped>

    .Prediction {
        padding-top: 6vh;
        padding-left: 1vw;
        padding-right: 1vw;
        position: relative;
        display: flex;
        justify-content: space-around;
    }
    .PredictionSection {
        max-width: 50%;
        /* position: relative; */
        display: flex;
        /* justify-content: space-between; */
    }
</style>
