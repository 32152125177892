var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!(_vm.datasetInfo.errors || []).length
    ? _c(
        "div",
        { staticClass: "column" },
        _vm._l(_vm.datasetInfo.errors, function(error, idx) {
          return _c(
            "QBanner",
            {
              key: idx,
              staticClass: "col bg-negative text-white q-ma-sm",
              scopedSlots: _vm._u(
                [
                  {
                    key: "avatar",
                    fn: function() {
                      return [
                        _c("QIcon", {
                          attrs: { name: "error", color: "white" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [_vm._v(" " + _vm._s(error) + " ")]
          )
        }),
        1
      )
    : _vm.datasetInfo.state.identifier === "ERROR" ||
      (_vm.datasetInfo.state.identifier === "SUBMITTED" &&
        !_vm.datasetInfo.uploaded)
    ? _c(
        "div",
        [
          _c(
            "QBanner",
            {
              staticClass: "bg-negative text-white q-ma-sm",
              scopedSlots: _vm._u([
                {
                  key: "avatar",
                  fn: function() {
                    return [
                      _c("QIcon", { attrs: { name: "error", color: "white" } })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("projects.datasets.generalError")) + " "
              )
            ]
          )
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "QBanner",
            {
              staticClass: "bg-positive text-white q-ma-sm",
              scopedSlots: _vm._u([
                {
                  key: "avatar",
                  fn: function() {
                    return [
                      _c("QIcon", {
                        attrs: { name: "check_circle", color: "white" }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("projects.datasets.settings.errors.noErrors")) +
                  " "
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }