<template>
    <div>
        <WCard
            flat
            bordered
            class="DatasetCard"
        >
            <div class="flex row-left-top flex-nowrap q-pa-sm h-full">
                <div
                    class="AvatarTitle cursor-pointer"
                    @click="handleGoToVisualization"
                >
                    <p>{{ getInitial(row.name) }}</p>
                </div>
                <div
                    class="DatasetContent q-px-sm flex-1 flex flex-col col-between-left h-full cursor-pointer"
                    @click="handleGoToVisualization"
                >
                    <template>
                        <p class="DatasetCardName text-weight-bold">
                            {{ row.name }}
                        </p>
                        <p class="DatasetCardName">
                            {{ $t('general.updateDate') }}: {{ App.dateLocationFormat(row.tst, 'long') }}
                        </p>
                    </template>
                    <div class="flex items-center q-mt-sm">
                        <QBadge
                            v-if="row.source"
                            :label="row.source"
                            :color="sourceColors[row.source]"
                            textColor="white"
                            class="q-pa-sm q-mr-sm"
                        />
                        <div class="flex-1">
                            <div v-if="row.state.identifier === 'ERROR' || row.state.identifier === 'SUBMITTED' && !row.uploaded">
                                <WIcon
                                    :icon="statusList.ERROR.icon"
                                    :size="24"
                                    :color="statusList.ERROR.color"
                                />
                            </div>
                            <div v-else>
                                <QLinearProgress
                                    v-if="showProgressBar"
                                    size="30px"
                                    indeterminate
                                    :value="1"
                                    :color="statusList[row.state.identifier].color"
                                    rounded
                                    trackColor="white"
                                >
                                    <div class="absolute-full flex flex-center">
                                        <QBadge
                                            :label="statusList[row.state.identifier].label"
                                            :color="statusList[row.state.identifier].color"
                                            textColor="white"
                                        />
                                    </div>
                                </QLinearProgress>
                                <WIcon
                                    v-else-if="statusList[row.state.identifier].icon"
                                    :icon="statusList[row.state.identifier].icon"
                                    :size="24"
                                    :color="statusList[row.state.identifier].color"
                                />
                                <QBadge
                                    v-else
                                    :label="statusList[row.state.identifier].label"
                                    :color="statusList[row.state.identifier].color"
                                    textColor="white"
                                    class="q-pa-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <WIcon
                    key="dropdown"
                    icon="more-vertical"
                    :size="24"
                    color="primary"
                    clickable
                    @click="setSelected(row, $event)"
                />
            </div>
        </WCard>
        <Dropdown
            v-if="selected"
            :opened="!!selected"
            :options="(selected.options || {}).dropdown"
            :coords="selected.coords"
            @onClose="cleanSelected"
            @onClick="handleClickOption"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import i18n from '@/lang/i18n'
import { Dropdown } from '@/components'
import { filtersMixin } from '@/mixins'

const STATES_DATA = {
    RUNNING: {
        label: i18n.t('projects.datasets.states.running'), color: '$primary', useBadge: true,
    },
    SUBMITTED: {
        label: i18n.t('projects.datasets.states.submitted'), color: '$primary', useBadge: true,
    },
    FINISHED: {
        label: i18n.t('projects.datasets.states.finished'), color: 'green', icon: 'check-circle', useBadge: false,
    },
    ERROR: {
        label: i18n.t('projects.datasets.states.error'), color: 'red', icon: 'alert-triangle', useBadge: false,
    },
    CANCELLED: {
        label: i18n.t('projects.datasets.states.cancelled'), color: 'red', useBadge: true,
    },
    TIMEOUT: {
        label: i18n.t('projects.datasets.states.timeout'), color: 'yellow', useBadge: true,
    },
    default: {
        label: i18n.t('projects.datasets.states.timeout'), color: '$primary', useBadge: true,
    },
}
const STATES_KEYS = {
    RUNNING: 'RUNNING',
    SUBMITTED: 'SUBMITTED',
    FINISHED: 'FINISHED',
    ERROR: 'ERROR',
    CANCELLED: 'CANCELLED',
    TIMEOUT: 'TIMEOUT',
}

const SOURCE_COLORS = {
    CSV: 'cyan-10',
    EASYVISTA: 'amber-8',
    DENODO: 'teal-6',
}

export default {
    name: 'DatasetCard',
    components: {
        Dropdown,
    },
    inject: ['App'],
    mixins: [filtersMixin],
    props: {
        row: VueTypes.any,
    },
    data () {
        return {
            statusList: STATES_DATA,
            sourceColors: SOURCE_COLORS,
            showInfoCard: false,
            showErrorsCard: false,
            selected: undefined,
            moreInfo: {},
            moreInfoErrorMessage: '',
            isLoadingMoreInfo: false,
        }
    },
    computed: {
        showProgressBar () {
            const { uploaded, state } = this.row
            return uploaded && (state.identifier === STATES_KEYS.SUBMITTED || state.identifier === STATES_KEYS.RUNNING)
        },
    },
    methods: {
        getInitial (name) {
            const [letter] = name || ''
            return letter || '-'
        },
        setSelected (row, event) {
            const { clientX: x, clientY: y } = event
            setTimeout(() => (this.selected = { coords: { x, y }, ...row }), 100)
        },
        cleanSelected () {
            this.selected = undefined
        },
        handleClickOption (option) {
            if (option.event) {
                this.handleEmmit(option.event, this.selected)
            }
            this.cleanSelected()
        },
        handleEmmit (event) {
            this.$emit('handleEmmit', event)
        },
        handleGoToVisualization () {
            const { uploaded, state } = this.row
            if (uploaded && state.identifier === STATES_KEYS.FINISHED) this.handleEmmit('onVisualization', this.row)
        },
    },
}
</script>
<style scoped lang="scss">
.DatasetCard {
  overflow: hidden;
  height: 100%;
  background: $gray-06;
}
.AvatarTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    min-width: 80px;
    min-height: 80px;
    height: 100%;
    background: $primary;
    border-radius: $border-radius;
    p {
      font-size: 32px;
      line-height: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
    }
}
.WCard::v-deep .Body {
  height: 100%;
  position: relative;
}
.QBadge {
  padding: 10px
}
.h-full {
  height: 100%;
}
.ToggleCard {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $gray-06;
  display: flex;
  justify-content: space-between;
  transition: all .3s ease-in-out;
  color: $white;
}
.DatasetContent {
  max-width: 100%;
  overflow: hidden;
}
.DatasetCardName {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
