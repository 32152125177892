import StepRelevantFields from './StepRelevantFields/StepRelevantFields.vue'
import StepKeyFields from './StepKeyFields/StepKeyFields.vue'
import StepReport from './StepReport/StepReport.vue'
import StepDataset from './StepDataset/StepDataset.vue'

export default {
    StepRelevantFields,
    StepKeyFields,
    StepReport,
    StepDataset,
}
