<template>
    <div class="Step">
        <div class="row justify-center">
            <WCard
                v-if="(steps || []).length"
                flat
                bordered
                class="col-8 col-lg-4"
            >
                <CollapseItem
                    icon="key"
                    :title="'Data set'"
                    :opened="collapses[steps[0].name]"
                    @onClick="handleClick(steps[0].name)"
                >
                    <div
                        v-if="value.config"
                        class="pa-1"
                    >
                        <WCard
                            flat
                            bordered
                        >
                            <div
                                v-if="config.traceId"
                                class="pa-0-5"
                            >
                                <WText>
                                    {{ $t('wizard.trace') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ config.traceId }}
                                </WText>
                            </div>
                            <Divider />
                            <div
                                v-if="config.activity"
                                class="pa-0-5"
                            >
                                <WText>
                                    {{ $t('wizard.activity') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ config.activity }}
                                </WText>
                            </div>
                            <Divider />
                            <div
                                v-if="config.start"
                                class="pa-0-5"
                            >
                                <WText>
                                    {{ $t('wizard.startTime') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ config.start }} ({{ config.startFormat }})
                                </WText>
                            </div>
                            <fragment v-if="endFormat">
                                <Divider />
                                <div class="pa-0-5">
                                    <WText>
                                        {{ $t('wizard.endTime') }}
                                    </WText>
                                    <WText
                                        :size="12"
                                        color="gray02"
                                    >
                                        {{ end || '' }} {{ endFormat }}
                                    </WText>
                                </div>
                            </fragment>
                        </WCard>
                    </div>
                </CollapseItem>
                <CollapseItem
                    v-if="steps[1]"
                    icon="file-text"
                    :title="steps[1].title"
                    :opened="collapses[steps[1].name]"
                    @onClick="handleClick(steps[1].name)"
                >
                    <WCard
                        flat
                        bordered
                        class="ma-1"
                    >
                        <div class="pa-0-5">
                            <WText>
                                {{ $t('wizard.query.mode') }}
                            </WText>
                            <WText
                                :size="12"
                                color="gray02"
                            >
                                {{ value.definition.mode }}
                            </WText>
                        </div>
                        <Divider />
                        <div class="pa-0-5">
                            <WText>
                                {{ $t('wizard.query.query') }}
                            </WText>
                            <WText
                                :size="12"
                                color="gray02"
                            >
                                {{ value.definition.query }}
                            </WText>
                        </div>
                        <fragment v-if="value.definition.restrictions">
                            <Divider />
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('wizard.query.restrictions') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ value.definition.restrictions }}
                                </WText>
                            </div>
                        </fragment>
                    </WCard>
                </CollapseItem>
                <CollapseItem
                    v-if="steps[2]"
                    icon="check-circle"
                    :title="steps[2].title"
                    :opened="collapses[steps[2].name]"
                    @onClick="handleClick(steps[2].name)"
                >
                    <div
                        v-if="value.report"
                        class="pa-1"
                    >
                        <WCard
                            flat
                            bordered
                        >
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('wizard.report.name') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ value.report.name }}
                                </WText>
                            </div>
                            <Divider />
                            <div class="pa-0-5">
                                <WText>
                                    {{ $t('general.description') }}
                                </WText>
                                <WText
                                    :size="12"
                                    color="gray02"
                                >
                                    {{ (value.report || {}).description || '' }}
                                </WText>
                            </div>
                            <fragment v-if="value.report.type">
                                <Divider />
                                <div class="pa-0-5">
                                    <WText>
                                        {{ $t('wizard.report.type') }}
                                    </WText>
                                    <WText
                                        :size="12"
                                        color="gray02"
                                    >
                                        {{ value.report.type.label }}
                                    </WText>
                                </div>
                            </fragment>
                        </WCard>
                    </div>
                </CollapseItem>
            </WCard>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Divider, CollapseItem } from '@/components'
import { Api, apiRequest, notifyError } from '@/api'

export default {
    name: 'StepSummary',
    components: {
        Divider,
        CollapseItem,
    },
    props: {
        value: VueTypes.shape({
            config: VueTypes.any.isRequired,
            definition: VueTypes.shape({
                mode: VueTypes.string.isRequired,
            }).loose,
            report: VueTypes.any.isRequired,
        }).loose,
        datasetId: VueTypes.string,
        steps: VueTypes.arrayOf(VueTypes.shape({
            title: VueTypes.string,
            icon: VueTypes.string,
        }).loose),
    },
    data () {
        const collapses = (this.steps || []).reduce((acc, item, index) => ({ ...acc, [item.name]: !index }), {})
        return {
            collapses,
            config: this.value.config || {},
            isLoading: false,
        }
    },
    computed: {
        end () {
            return this.config?.end || ''
        },
        endFormat () {
            return this.config.endFormat
        },
    },
    mounted () {
        this.getConfigurations()
    },
    methods: {
        handleClick (key) {
            this.collapses = {
                ...this.collapses,
                [key]: !this.collapses[key],
            }
        },
        async getConfigurations () {
            this.isLoading = true
            const datasetId = this.value.datasetId || this.datasetId
            apiRequest(Api().datasets.getConfigurations({ datasetId }))
                .then(({ data }) => {
                    const [datasetConfig] = data
                    this.config = {
                        ...this.config,
                        start: datasetConfig.startTimeColumn.column,
                        activity: datasetConfig.activityColumn.column,
                        endFormat: datasetConfig.endTimeColumn?.format,
                        end: datasetConfig.endTimeColumn?.column,
                        name: 'default',
                        startFormat: datasetConfig.startTimeColumn?.format,
                        traceId: datasetConfig.traceColumn.column,
                        uid: datasetConfig.uuid,
                    }
                    this.$emit('input', { ...this.value, config: this.config })
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">
.CollapseItem + .CollapseItem {
    border-top: 1px solid $border-color;
}
</style>
