<template>
    <div class="AccountScreen">
        <WCard
            bordered
            class="Personal mb-2"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('settings.sections.personal') }}
            </WText>

            <div class="Wrapper">
                <WText
                    class="pb-0-5 mb-1"
                    v-html="$t('settings.personal.text', { name: project })"
                />
                <UserForm
                    v-model="user"
                    :disabled="isLoading"
                    @onSubmit="update"
                />
            </div>
        </WCard>
        <WCard
            bordered
            class="Password mb-2"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('settings.sections.security') }}
            </WText>

            <div class="Wrapper">
                <WText class="pb-0-5 mb-1">
                    {{ $t('settings.security.text') }}
                </WText>
                <WButton
                    :disabled="isLoading"
                    @click="handleClickRecoveryPassword"
                >
                    {{ $t('settings.security.btn') }}
                </WButton>
            </div>
        </WCard>

        <Payments
            v-if="enviroment.public"
            :value="App.user"
            @onUpdatedSubscription="handleUpdatedSubscription"
        />

        <ModalRequest
            ref="recoveryDialog"
            :title="$t('settings.modals.recovery.title')"
            :text="$t('settings.modals.recovery.text')"
            @onAccept="recoveryPassword"
        />
    </div>
</template>
<script>
import Config from '@/config'
import {
    Api, apiRequest, notifySuccess, notifyError,
} from '@/api'
import { ModalRequest } from '@/components'
import { UserForm } from '@/forms'
import { Payments } from './sections'

export default {
    name: 'AccountScreen',
    inject: ['App'],
    components: {
        UserForm,
        ModalRequest,
        Payments,
    },
    data () {
        const { user, enviroment } = this.App
        return {
            user: { ...user },
            enviroment: { ...enviroment },
            isLoading: false,
        }
    },
    watch: {
        'App.user': {
            handler (value) {
                if (value) this.user = value
            },
            immediate: true,
        },
    },
    beforeCreate () {
        const { name } = Config.project || {}
        this.project = name
    },
    methods: {
        handleClickRecoveryPassword () {
            this.$refs.recoveryDialog.open()
        },
        async update () {
            this.isLoading = true
            apiRequest(Api().users.update(this.user))
                .then(() => {
                    this.App.setUser(this.user)
                    notifySuccess(this.$t('settings.notifications.personal.text'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        handleUpdatedSubscription () {
            this.App.fetchUser()
        },
        async recoveryPassword () {
            this.isLoading = true
            apiRequest(Api().users.resetPassword())
                .then(() => {
                    this.$refs.recoveryDialog.close()
                    notifySuccess(this.$t('settings.notifications.resetPassword.text'))
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
