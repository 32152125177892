<template>
    <div class="Events">
        <div class="Container flex row-stretch">
            <div class="Screen flex-1">
                <div class="Header mb-1">
                    <div class="Summary">
                        <EventsBox
                            :title="$t('events.currentUse')"
                            color="primary"
                            :value="currrent"
                        />
                        <EventsBox
                            :title="$t('events.maxEvents')"
                            class="mt-1"
                            :value="max"
                        />
                    </div>
                    <Transition
                        name="fade"
                        mode="out-in"
                    >
                        <div
                            v-if="chart"
                            class="Card"
                        >
                            <WText
                                :size="18"
                                class="mb-1"
                            >
                                {{ $t('events.events') }}
                            </WText>
                            <EventsChart
                                v-bind="chart"
                                :height="210"
                                class="EventsChart"
                            />
                        </div>
                    </Transition>
                </div>

                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        v-show="showHistoric"
                        class="Card"
                    >
                        <WText
                            :size="18"
                            class="mb-1"
                        >
                            {{ $t('events.historic') }}
                        </WText>
                        <EventsHistoric @onReady="handleHistoricReady" />
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { Api, apiRequest, notifyError } from '@/api'
import { filtersMixin } from '@/mixins'
import { EventsBox, EventsChart, EventsHistoric } from './components'

const MONTHS = moment.monthsShort()
const DEFAULT_VALUES = Array.from({ length: MONTHS.length }).fill(0)

export default {
    name: 'Events',
    components: {
        EventsBox,
        EventsChart,
        EventsHistoric,
    },
    mixins: [filtersMixin],
    data () {
        return {
            chart: undefined,
            currrent: 0,
            max: 0,
            showHistoric: false,
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        handleHistoricReady () {
            this.showHistoric = true
        },
        normalizeChart (chart) {
            return {
                categories: MONTHS,
                values: chart.reduce((acc, { date, measurement }) => {
                    const month = Number(this.date(date, 'M') || 0)
                    const selected = month - 1
                    // eslint-disable-next-line no-param-reassign
                    acc[selected] = (acc[selected] || 0) + measurement
                    return acc
                }, [...DEFAULT_VALUES]),
            }
        },
        async getData (projectId) {
            this.isLoading = true
            apiRequest(Api().eventConsumption.get())
                .then(({ histogram = [], currentEventConsumption = this.current, maxAllowedEvents = this.max } = {}) => {
                    this.chart = this.normalizeChart(histogram)
                    this.currrent = currentEventConsumption
                    this.max = maxAllowedEvents
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Events {
    .Header {
        display: grid;
        grid-row-gap: 20px;

        @media screen and (min-width: $md) {
            grid-template-columns: 230px auto;
            grid-column-gap: 20px;
        }
    }

    .Card {
        padding: 20px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
    }
}
</style>
