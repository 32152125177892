var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "QDialog",
    {
      attrs: {
        persistent: "",
        transitionShow: "slide-up",
        transitionHide: "slide-down"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "QCard",
        { staticClass: "ModelPreview" },
        [
          _c(
            "QBar",
            { staticClass: "bg-primary text-white" },
            [
              _c("div", [_vm._v(_vm._s(_vm.name))]),
              _c("QSpace"),
              _c("QBtn", {
                attrs: { dense: "", flat: "", icon: "close" },
                on: {
                  click: function($event) {
                    return _vm.$emit("closePreview")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "QCardSection",
            [_c("BpmnViewer", { attrs: { data: _vm.xmlData } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }