var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalRequest",
    {
      ref: "dialog",
      staticClass: "Dialog",
      attrs: { title: _vm.title, accept: _vm.accept, autoClose: _vm.autoClose },
      on: { onCancel: _vm.close, onAccept: _vm.handleSubmit }
    },
    [
      _c(
        "div",
        { staticClass: "Form" },
        [
          _c("QInput", {
            ref: "email",
            attrs: {
              lazyRules: "",
              type: "email",
              dense: "",
              hint: _vm.$t("invitations.hint"),
              label: "Email",
              maxlength: "100",
              outlined: "",
              rules: [
                function(value) {
                  return (
                    _vm.validators.validEmail(value) ||
                    _vm.$t("invitations.valid")
                  )
                }
              ]
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = $$v
              },
              expression: "email"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }