<template>
    <Dropdown
        :options="options"
        :direction="direction"
        @onClick="handleClickAction"
    >
        <div class="flex row-left-center flex-nowrap">
            <div class="User">
                {{ initialName }}
            </div>

            <WText
                v-if="!hideFullName"
                tag="span"
                weight="semi"
                ellipsis
                class="ml-1"
            >
                {{ fullName }}
            </WText>
        </div>
    </Dropdown>
</template>

<script>
import VueTypes from 'vue-types'
import { Dropdown } from '@/components'

const ACTIONS = {
    ACCOUNT: 'ACCOUNT',
    EVENTS: 'EVENTS',
    DOC: 'DOC',
    SIGNOUT: 'SIGNOUT',
}

export default {
    name: 'UserAvatar',
    inject: ['App'],
    components: {
        Dropdown,
    },
    props: {
        direction: VueTypes.string.def('topRight'),
        hideFullName: VueTypes.bool.def(false),
    },
    computed: {
        initialName () {
            const [letter] = this.fullName || ''
            return letter || 'U'
        },
        fullName () {
            const { name } = this.App?.user || {}
            return name || ''
        },
        options () {
            return [
                {
                    id: 'account', icon: 'user1', iconColor: 'primary', key: ACTIONS.ACCOUNT, label: this.$t('general.account'),
                },
                {
                    id: 'apis', icon: 'code', iconColor: 'primary', key: ACTIONS.DOC, label: this.$t('general.apis'),
                },
                {
                    id: 'signout', icon: 'exit', iconColor: 'primary', key: ACTIONS.SIGNOUT, label: this.$t('general.signout'),
                },
            ]
        },
    },
    methods: {
        handleClickAction ({ key }) {
            const actions = {
                [ACTIONS.ACCOUNT]: () => this.$router.push({ name: 'Account' }).catch(() => {}),
                [ACTIONS.DOC]: () => this.$router.push({ name: 'Doc' }).catch(() => {}),
                [ACTIONS.SIGNOUT]: this.App.signout,
            }

            if (actions[key]) actions[key]()
        },
    },
}
</script>
<style scoped lang="scss">
.User {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $tertiary;
    border-radius: $border-radius;
    font-size: 18px;
    font-weight: 600;
}
</style>
