var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ModelListCards" },
    [
      _c("Table", {
        ref: "table",
        attrs: {
          data: _vm.normalizeRows,
          loading: _vm.loading,
          total: _vm.total,
          hideHeader: "",
          rowKey: "uuid"
        },
        on: { "table-request": _vm.handleFetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("ModelCard", {
                  attrs: { row: row },
                  on: {
                    handleEmit: function($event) {
                      return _vm.handleEmit($event, row)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "EmptyList",
                  _vm._b(
                    {
                      staticClass: "mt-2 mb-2",
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "WButton",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    iconLeft: "upload-cloud",
                                    disabled: _vm.archived
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("onNewItem")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projects.models.upload")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    "EmptyList",
                    _vm.emptyMessage,
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }