export default {
    events: 'Events',
    historic: 'History',
    currentUse: 'Current use',
    maxEvents: 'Max events',
    lines: 'Lines',

    emptyListTitle: 'No result found',
    emptyListTexy: 'We did not find any events.',
}
