export default {
    visualizationToBooster: 'Impulsor de procesos',
    customAnalysis: 'Análisis personalizado',
    performanceBoosterTitle: 'Impulsor de rendimiento',
    boosterDescription: 'Comprende como se ejecutan realmente tus procesos',
    overview: {
        process: 'Vista general de procesos',
    },
    prediction: {
        process: 'Predicción de procesos',
        predictionHighlights: 'Aspectos destacados de la predicción',
    },
    stateView: {
        performanceBy: 'Rendimiento por ',
        rightClick: 'Clic derecho en una barra para mostrar grafo',
        leadTimesBy: 'Duración media por ',
        timeSpentPerCase: 'Tiempo medio invertido por caso',
        performanceComparison: 'comparación de rendimiento',
        leadTimes: ' duración media',
        cases: ' cases',
        selectFilteringElements: 'Elementos seleccionados para filtrado de recursos',
        evolutionOverTime: 'Evolución del rendimiento',
        overallPerformance: 'Rendimiento general por:',
        activity: 'Actividad',
        transition: 'Transición',
        variant: 'Variante',
        months: 'meses',
        weeks: 'semanas',
        all: 'Todos',
        below: 'Cumple SLA',
        above: 'Incumple SLA',
        slaCompliance: 'Cumple SLA',
        noTraces: 'No hay  trazas para los filtros actuales',
        performanceByClick: 'Haz clic en una barra del gráfico de la izquierda para seleccionar',
    },
    improvement: {
        process: 'Mejora de proceso',
    },
    tabs: {
        overview: 'Vista general',
        prediction: 'Predicción',
        improvement: 'Mejora',
    },
}
