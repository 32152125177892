<template>
    <Collapse
        :opened="opened"
        class="CollapseItem"
    >
        <template #header>
            <button
                class="Header flex row-stretch-center"
                :disabled="disabled"
                @click="$emit('onClick')"
            >
                <div class="Title flex row-left-center">
                    <WIcon
                        v-if="icon"
                        :icon="icon"
                        :size="24"
                        color="secondary"
                        class="mr-1"
                    />
                    <WText
                        v-if="title"
                        tag="span"
                        :color="color"
                    >
                        {{ title }}
                    </WText>
                </div>
                <WIcon
                    :icon="`chevron-${opened ? 'down' : 'up'}`"
                    :size="24"
                    color="grayMedium"
                    class="Arrow"
                />
            </button>
            <slot name="header" />
        </template>
        <template #default>
            <slot />
        </template>
    </Collapse>
</template>

<script>
import VueTypes from 'vue-types'
import Collapse from '../Collapse/Collapse.vue'

export default {
    name: 'CollapseItem',
    components: {
        Collapse,
    },
    props: {
        opened: VueTypes.bool.def(false),
        title: VueTypes.string,
        icon: VueTypes.string,
        color: VueTypes.string,
        disabled: VueTypes.bool.def(false),
    },
}
</script>
<style scoped lang="scss">
.Header {
    width: 100%;
    padding: 15px 20px;
    border: 0;
    border-bottom: 1px solid $border-color;
    background: transparent;
    outline: none;
    transition: $transition;
    cursor: pointer;

    &:hover {
        background: rgba($primary, 0.1)
    }
}
</style>
