var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WCard",
    { staticClass: "Password mb-2", attrs: { bordered: "" } },
    [
      _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
        _vm._v(" " + _vm._s(_vm.$t("settings.subscription.text")) + " ")
      ]),
      _c(
        "div",
        { staticClass: "Wrapper" },
        [
          _c("WText", {
            staticClass: "pb-0-5 mb-1",
            domProps: {
              innerHTML: _vm._s(
                _vm.isActiveSubscription
                  ? _vm.$t("settings.subscription.active.yes")
                  : _vm.$t("settings.subscription.active.no")
              )
            }
          }),
          _c(
            "div",
            { staticClass: "flex row-left-center" },
            [
              _c(
                "WButton",
                {
                  attrs: {
                    loading: _vm.loadingPayment,
                    disabled: _vm.loadingPayment
                  },
                  on: { click: _vm.handlePaymentClick }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isActiveSubscription
                          ? _vm.$t("settings.subscription.goTo")
                          : _vm.$t("settings.subscription.reactivate")
                      ) +
                      " "
                  )
                ]
              ),
              _vm.isActiveSubscription
                ? _c(
                    "WButton",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        outline: "",
                        loading: _vm.loadingCancel,
                        disabled: _vm.loadingCancel
                      },
                      on: { click: _vm.handleCancelSubscription }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.subscription.cancel")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "recoveryDialog",
        attrs: {
          title: _vm.$t("settings.subscription.modal.title"),
          text: _vm.$t("settings.subscription.modal.text")
        },
        on: { onAccept: _vm.handleAcceptCancel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }