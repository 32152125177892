const user = {
    name: 'Peter',
    surname: 'Linch',
    email: 'peter@inverbis.com',
    locale: 'es',
    subscription: {
        active: true,
    },
    timezone: '',
}

export default {
    me: params => [200, user],
    list: params => [200, Array.from({ length: 5 }, i => user)],
    update: ({ data }) => [200, JSON.parse(data)],
}
