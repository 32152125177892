var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["message", { dark: _vm.dark }],
      staticStyle: { "white-space": "pre-wrap" }
    },
    [
      _c("p", { staticClass: "time" }, [
        _vm._v(" " + _vm._s(_vm.date(_vm.time)) + " ")
      ]),
      _vm._v(" " + _vm._s(_vm.text) + " ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }