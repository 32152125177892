<template>
    <QCard
        flat
        bordered
    >
        <QCardSection>
            <QSelect
                v-model="selectedValue"
                :options="usableAttribs"
                @input="notifyAttrChange"
            />
        </QCardSection>
        <QCardSection class="CauseListing">
            <QList v-if="infoData">
                <QItem
                    v-for="(item, idx) in infoData.data"
                    :key="idx"
                >
                    <QItemSection>
                        <QItemLabel>
                            {{ item.attributeValue }}
                        </QItemLabel>
                        <QLinearProgress
                            :value="elementPercentage(item.attributeCount,total)"
                            size="20px"
                            rounded
                            color="secondary"
                        />
                        <QItemLabel class="text-caption text-italic">
                            {{ `${App.numberLocationFormat(item.attributeCount)} ${occurenceLiteral(item.attributeCount)}` }}
                        </QItemLabel>
                    </QItemSection>
                </QItem>
            </QList>
        </QCardSection>
    </QCard>
</template>
<script>
import VueTypes from 'vue-types'

export default {
    name: 'CategoricalCause',
    components: {
    },
    inject: ['App'],
    emits: ['changed'],
    props: {
        usableAttribs: VueTypes.array,
        defaultValue: VueTypes.string,
        totalEvents: VueTypes.number,
        causeIndex: VueTypes.number,
        attrData: VueTypes.object,
    },
    data () {
        return {
            selectedValue: undefined,
            infoData: null,
            total: null,
        }
    },
    computed: {
    },
    watch: {
        attrData () {
            this.infoData = this.attrData
            this.total = this.totalEvents
        },
    },
    mounted () {
        this.selectedValue = this.defaultValue
        this.infoData = this.attrData
        this.total = this.totalEvents
    },
    methods: {
        notifyAttrChange (value) {
            this.$emit('changed', { value, idx: this.causeIndex })
        },
        occurenceLiteral (value) {
            return value !== 1 ? this.$t('visualization.conformance.issuesView.occurrences') : this.$t('visualization.conformance.issuesView.occurrence')
        },
        elementPercentage (occurrences, total) {
            return occurrences / total
        },
    },
}
</script>
<style scoped lang="scss">
.CauseListing {
    overflow: scroll;
    height: 300px;
}
</style>
