var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Conformance q-mt-md" }, [
    _vm.models && _vm.models.length > 0
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "flex justify-center items-center q-ma-md" },
              [
                _c("WText", { staticClass: "q-mr-md", attrs: { size: 18 } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("visualization.conformance.chooseBpmn")) +
                      ": "
                  )
                ]),
                _c("QSelect", {
                  staticStyle: { "min-width": "250px" },
                  attrs: { options: _vm.models, stackLabel: "" },
                  on: { input: _vm.setConformanceModel },
                  model: {
                    value: _vm.chosenModel,
                    callback: function($$v) {
                      _vm.chosenModel = $$v
                    },
                    expression: "chosenModel"
                  }
                })
              ],
              1
            ),
            _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.chosenModel && _vm.xmlData && !_vm.isLoading
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 q-pa-sm" },
                          [_c("BpmnViewer", { attrs: { data: _vm.xmlData } })],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex flex-center q-ma-sm" },
                        [
                          _c("QBtnToggle", {
                            staticClass: "q-mt-sm border-toggle",
                            attrs: {
                              toggleColor: "primary",
                              noCaps: "",
                              unelevated: "",
                              options: _vm.tabs
                            },
                            model: {
                              value: _vm.currentTab,
                              callback: function($$v) {
                                _vm.currentTab = $$v
                              },
                              expression: "currentTab"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "QTabPanels",
                        {
                          attrs: {
                            animated: "",
                            transitionNext: "fade",
                            transitionPrev: "fade"
                          },
                          model: {
                            value: _vm.currentTab,
                            callback: function($$v) {
                              _vm.currentTab = $$v
                            },
                            expression: "currentTab"
                          }
                        },
                        [
                          _c(
                            "QTabPanel",
                            { attrs: { name: "OVERVIEW" } },
                            [
                              _c("Overview", {
                                attrs: {
                                  processId: _vm.processId,
                                  modelId: _vm.chosenModel.value
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "QTabPanel",
                            { attrs: { name: "ISSUES" } },
                            [
                              _c("Issues", {
                                attrs: {
                                  processId: _vm.processId,
                                  modelId: _vm.chosenModel.value
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "QTabPanel",
                            { attrs: { name: "ROOT_CAUSE" } },
                            [
                              _c("RootCause", {
                                attrs: {
                                  processId: _vm.processId,
                                  modelId: _vm.chosenModel.value,
                                  attribs: _vm.attributes
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "flex justify-center items-center",
                      staticStyle: { height: "400px" }
                    },
                    [
                      _c("QCircularProgress", {
                        staticClass: "q-ma-md",
                        attrs: {
                          indeterminate: "",
                          rounded: "",
                          size: "50px",
                          color: "primary"
                        }
                      })
                    ],
                    1
                  )
            ])
          ],
          1
        )
      : !_vm.isLoading
      ? _c("div", [
          _c(
            "div",
            { staticClass: "flex justify-center items-cemter q-ma-md" },
            [
              _c("WText", { attrs: { size: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.conformance.noModels")) +
                    " "
                )
              ])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }