var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "WButton",
      class: _vm.buttonClass,
      attrs: { disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "Wrapper" },
        [
          _c("WSpinner", {
            attrs: {
              visible: _vm.loading,
              color: _vm.spinnerColor,
              small: "",
              noMargin: ""
            }
          }),
          _vm.iconLeft
            ? _c("WIcon", {
                staticClass: "Icon",
                attrs: { icon: _vm.iconLeft, size: _vm.iconSize }
              })
            : _vm._e(),
          _c("span", [_vm._t("default")], 2),
          _vm.iconRight
            ? _c("WIcon", {
                staticClass: "Icon",
                attrs: { icon: _vm.iconRight, size: _vm.iconSize }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }