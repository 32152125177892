var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Tabs" }, [
    _c("div", { staticClass: "Container" }, [
      _c(
        "div",
        { staticClass: "Tabs", class: { horizontal: _vm.horizontal } },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c(
            "button",
            {
              key: "tab-" + index,
              staticClass: "Tab",
              class: [
                _vm.type,
                {
                  active: _vm.value === tab.id,
                  disabled: tab.disabled,
                  horizontal: _vm.horizontal
                }
              ],
              attrs: { disabled: tab.disabled },
              on: {
                click: function($event) {
                  return _vm.handleClickItem(tab)
                }
              }
            },
            [
              tab.icon
                ? _c("WIcon", {
                    staticClass: "mr-1",
                    attrs: { icon: tab.icon, size: 24 }
                  })
                : _vm._e(),
              _c("span", [_vm._v(_vm._s(tab.label))]),
              tab.badge
                ? _c("Badge", {
                    staticClass: "Badge",
                    attrs: { color: tab.badge.color, label: tab.badge.label }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }