var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c("RouterLink", { staticClass: "Logo", attrs: { to: "/" } }, [
        _c("img", { attrs: { src: _vm.logo, alt: _vm.name } })
      ])
    : _c(
        "div",
        { staticClass: "Logo LogoMin" },
        [
          _c(
            "RouterLink",
            { attrs: { to: "/" } },
            [
              _c("WIcon", {
                attrs: { icon: "menu1", size: 24, color: "primary" }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }