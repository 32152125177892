const datasets = [{
    createDate: '2020-06-22T08:37:07',
    tst: '2020-06-22T08:37:07',
    description: 'Description',
    fileName: '1000_2001_Procure to Pay_Procure to Pay_603_E.csv',
    name: 'Data set 2',
    parsed: true,
    uploaded: true,
    uuid: '83d26144-344b-4747-96d6-fc311f181f71',
},
{
    createDate: '2020-06-11T11:18:04',
    tst: '2020-06-11T11:49:05',
    description: 'asdad',
    fileName: 'BPI17-5M.csv',
    name: 'Data set 2',
    parsed: true,
    uploaded: true,
    uuid: '1f1b6dac-e710-4159-8773-b35596fc5867',
}]

export default {
    data: datasets,
    total: datasets.length,
}
