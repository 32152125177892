export default {
    new: 'New project',
    delete: 'Delete project',
    edit: 'Edit project',

    sections: {
        datasets: 'Data sets',
        models: 'Models',
        discovery: 'Discovery',
        queries: 'Insights',
    },

    notifications: {
        noResult: {
            title: 'No result found',
            text: 'No projects found for your search.',
        },
        empty: {
            title: 'No projects found',
            text: 'You can create your first project.',
        },
        created: {
            text: 'Project created successfully.',
        },
        updated: {
            text: 'Project updated successfully.',
        },
        deleted: {
            text: 'Project deleted successfully.',
        },
        archived: {
            text: 'Project archived successfully.',
        },
    },

    modals: {
        delete: {
            text: 'Are you sure you want to delete project "{name}"?',
        },
        upload: {
            title: 'Upload data',
            text: 'Choose a .csv file to upload',
        },
    },

    datasets: {
        new: 'New data set',
        delete: 'Delete data set',
        edit: 'Edit data set',
        infoText: 'Data set information',
        errors: 'Errors',
        errorMessage: 'More information for this data set is currently unavailable.',
        generalError: 'An unidentified error occurred when processing the source data. Please contact the system administrator.',

        import: 'Connectors',
        discovery: 'Discovery',
        query: 'Insight',

        properties: {
            events: 'Events',
            activities: 'Activities',
            arcs: 'Arcs',
            traces: 'Traces',
            variants: 'Variants',
            createdAt: 'Created',
            sourceType: 'Source type',
            source: 'Source',
        },
        notifications: {
            noResult: {
                title: 'No result found',
                text: 'We did not find any data sets for your search.',
            },
            empty: {
                title: 'No data sets found',
                text: 'You can create or import your first data set.',
            },
            created: {
                text: 'Data set information processed successfully.',
            },
            updated: {
                text: 'Data set updated successfully.',
            },
            deleted: {
                text: 'Data set deleted successfully.',
            },
        },
        states: {
            running: 'PROCESSING',
            submitted: 'SUBMITTING',
            finished: 'READY',
            error: 'ERROR',
            cancelled: 'CANCELLED',
            timeout: 'TIMED OUT',
        },

        settings: {
            title: 'Data set configuration',
            back: 'Back to data sets view',
            tabs: {
                general: 'General',
                updates: 'Real time updates',
                errors: 'Errors',
            },
            general: {
                nameTitle: 'Data set name',
                nameField: 'Name',
                updateName: 'Update',
                generalTitle: 'General information',
                sourceType: 'Source type',
                sourceName: 'Source name',
                creation: 'Creation date',
                lastUpdate: 'Last update',
                metricsTitle: 'Log and process metrics',
                events: 'Events',
                activities: 'Activities',
                cases: 'Cases',
                variants: 'Variants',
                arcs: 'Arcs',
            },
            updates: {
                pushTitle: 'Push updates',
                pushEnabled: 'Push data updates through API are enabled',
                pushDisabled: 'Push data updates through API are disabled',
                pullTitle: 'Pull updates',
                pullToggle: 'Enable pull data updates',
                frequency: 'Update frequency (applies since last update)',
                value: 'Value',
                unit: 'Unit',
                evalValue: 'Only numeric values up to 60 are allowed',
                timeGranularity: {
                    minutes: 'minutes',
                    hours: 'hours',
                    days: 'days',
                    weeks: 'weeks',
                    months: 'months',
                },
                saveChanges: 'Save changes',
                notReady: 'This data set is not in a ready state and cannot be updated',
            },
            errors: {
                noErrors: 'No errors found for this data set',
            },
            okMessage: 'Settings updated correctly',
            failMessage: 'Check configuration values before saving changes',
        },

        modals: {
            delete: {
                text: 'Are you sure you want to delete data set "{name}"?',
            },
            discovery: {
                text: 'Are you sure you want to create new discovery?',
            },
        },
    },

    models: {
        upload: 'Upload BPMN',
        empty: {
            title: 'No models found',
            text: 'You can import your first BPMN model',
        },
        edit: 'Update model name',
        delete: 'Delete model',
        preview: 'Preview model',
        notifications: {
            noResult: {
                title: 'No result found',
                text: 'We did not find any models for your search.',
            },
            empty: {
                title: 'No models found',
                text: 'You can import your first model.',
            },
            created: {
                text: 'Model imported successfully.',
            },
            updated: {
                text: 'Model updated successfully.',
            },
            deleted: {
                text: 'Model deleted successfully.',
            },
        },
        source: {
            uploaded: 'UPLOADED',
            dataset: 'DATA SET',
        },
        modals: {
            delete: {
                text: 'Are you sure you want to delete this model?',
            },
            upload: {
                title: 'Upload BPMN model',
                name: 'Model name',
                file: 'Choose a BPMN file',
                submit: 'Import',
                rejected: 'Only BPMN files up to 1MB are allowed.',
            },
        },
    },

    discoveries: {
        new: 'New discovery',
        cancel: 'Cancel discovery',
        delete: 'Delete discovery',
        edit: 'Edit discovery',

        notifications: {
            noResult: {
                title: 'No result found',
                text: 'No discoveries found for your search.',
            },
            empty: {
                title: 'No discoveries found',
                text: 'You can perform your first discovery.',
            },
            created: {
                text: 'Discovery created successfully.',
            },
            updated: {
                text: 'Discovery updated successfully.',
            },
            deleted: {
                text: 'Discovery deleted successfully.',
            },
            archived: {
                text: 'Discovery archived successfully.',
            },
        },

        modals: {
            cancel: {
                text: 'Are you sure you want to cancel this discovery?',
            },
            delete: {
                text: 'Are you sure you want to delete this discovery?',
            },
        },
    },

    queries: {
        new: 'New insight',
        cancel: 'Cancel insight',
        delete: 'Delete insight',
        edit: 'Edit insight',

        notifications: {
            noResult: {
                title: 'No results found',
                text: 'No insights found for your search.',
            },
            empty: {
                title: 'No insights found',
                text: 'You can create your first insight.',
            },
            created: {
                text: 'Insight created successfully.',
            },
            updated: {
                text: 'Insight updated successfully.',
            },
            deleted: {
                text: 'Insight deleted successfully.',
            },
            cancel: {
                text: 'Insight canceled successfully.',
            },
            download: {
                text: 'Insight downloaded successfully.',
            },
        },
        states: {
            running: 'PROCESSING',
            submitted: 'SUBMITTING',
            finished: 'READY',
            error: 'ERROR',
        },

        modals: {
            cancel: {
                text: 'Are you sure you want to cancel this insight?',
            },
            delete: {
                text: 'Are you sure you want to delete this insight?',
            },
        },
    },
}
