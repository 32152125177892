<template>
    <div
        :id="id"
        class="WRangeSlider"
        :class="{ error }"
    >
        <VueSlider
            ref="slider"
            :value="inputValue"
            :data="data.length > 0 ? data : null"
            :minRange="minRange"
            :maxRange="maxRange"
            :interval="interval"
            :min="min"
            :max="max"
            :disabled="disabled"
            :enableCross="enableCross"
            :fixed="fixed"
            :lazy="lazy"
            :height="height"
            :width="processedWidth"
            :dotSize="dotSize"
            :bgStyle="bgStyle"
            :dotOptions="dotOptions"
            :railStyle="railStyle"
            :processStyle="processStyle"
            :tooltip="tooltip"
            :tooltipPlacement="tooltipDir"
            :direction="direction"
            :tooltipFormatter="tooltipFormatter"
            v-on="inputListeners"
        />
        <Transition
            name="fade"
            :duration="500"
        >
            <WText
                v-if="error"
                class="WRangeSliderError"
                typo="span"
                size="10"
                color="error"
            >
                {{ error }}
            </WText>
        </Transition>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import VueSlider from 'vue-slider-component'
import { includes, merge, debounce } from 'lodash'
import Colors from '@/styles/colors'

const colors = {
    primary: Colors.primary,
    secondary: Colors.secondary,
    bg: Colors.quaternary,
}

export default {
    name: 'WRangeSlider',
    components: {
        VueSlider,
    },
    props: {
        id: VueTypes.string,
        value: VueTypes.oneOfType([Object, String, Array, Number]).isRequired,
        direction: VueTypes.oneOf(['ltr', 'rtl', 'ttb', 'btt']).def('ltr'),
        min: VueTypes.number.isRequired,
        max: VueTypes.number.isRequired,
        disabled: VueTypes.bool.def(false),
        tooltip: VueTypes.oneOf(['none', 'always', 'focus']).def('focus'),
        tooltipDir: VueTypes.oneOf(['', 'top', 'bottom', 'left', 'right']).def(undefined),
        enableCross: VueTypes.bool.def(true),
        lazy: VueTypes.bool.def(false),
        fixed: VueTypes.bool.def(false),
        data: VueTypes.array.def([]),
        interval: VueTypes.number.def(1),
        minRange: VueTypes.number.def(undefined),
        maxRange: VueTypes.number.def(undefined),
        error: VueTypes.string.def(''),
        height: VueTypes.number.def(3),
        dotSize: VueTypes.number.def(11),
        width: VueTypes.oneOfType([String, Number]).def(undefined),
        tooltipFormatter: VueTypes.func.def(val => val),
        debounceTime: VueTypes.number.def(500),
        colors: VueTypes.shape({
            primary: VueTypes.string,
            secondary: VueTypes.string,
            bg: VueTypes.string,
        }).def(colors).loose,
    },
    data () {
        return {
            inputValue: undefined,
        }
    },
    computed: {
        inputListeners () {
            return merge({}, this.$listeners, {
                change: debounce((value) => {
                    this.$emit('input', value, this.id)
                }, this.debounceTime),
            })
        },
        dotOptions () {
            const commonStyle = {
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: '#ffffff',
                borderStyle: 'solid',
                borderWidth: '3px',
                boxShadow: 'none',
                cursor: 'pointer',
            }
            return [
                {
                    style: {
                        ...commonStyle,
                        borderColor: !this.isPrimaryFirst ? colors.secondary : colors.primary,
                    },
                },
                {
                    style: {
                        ...commonStyle,
                        borderColor: !this.isPrimaryFirst ? colors.primary : colors.secondary,
                    },
                },
            ]
        },
        railStyle () {
            return {
                backgroundColor: colors.bg,
            }
        },
        processStyle () {
            const styleObject = {
                backgroundColor: colors.primary,
                backgroundImage: `linear-gradient(to right, ${colors.secondary}, ${colors.primary})`,
            }
            if (this.isVertical) {
                styleObject.backgroundImage = `linear-gradient(to top, ${colors.secondary}, ${colors.primary})`
            }
            return styleObject
        },
        processedWidth () {
            return this.isVertical ? 3 : this.width || undefined
        },
        bgStyle () {
            return { background: colors.bg }
        },
        isVertical () {
            return this.direction === 'ttb' || this.direction === 'btt'
        },
        isRange () {
            return Array.isArray(this.value)
        },
        isPrimaryFirst () {
            return !((includes(['ltr', 'btt'], this.direction) && this.isRange) || (this.direction === 'ttb' && !this.isRange))
        },
    },
    watch: {
        value: {
            handler: function handlerValue (value) {
                this.inputValue = value || ''
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss">
.WRangeSlider {
    margin-top: 15px;
    margin-bottom: 15px;

    .vue-slider-dot-tooltip-inner {
        min-width: 20px;
        padding: 2px 8px;
        border: 1px solid $primary;
        border-radius: 5px;
        background-color: $white;
        color: $gray-01;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        box-sizing: content-box;
        white-space: nowrap;
        &:after,
        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px;
        }
    }

    .vue-slider-dot-tooltip-inner-top {
        &:after,
        &:before {
            left: 50%;
            transform: translate(-50%, 0);
        }
        &:after {
            top: 95.25%;
            border-top-color: $white;
        }
        &:before {
            top: 100%;
            border-top-color: $primary;
        }
    }

    .vue-slider-dot-tooltip-inner-bottom {
        &:after,
        &:before {
            left: 50%;
            transform: translate(-50%, 0);
        }
        &:after {
            bottom: 97%;
            border-bottom-color: $white;
        }
        &:before {
            bottom: 100%;
            border-bottom-color: $primary;
        }
    }
    .vue-slider-dot-tooltip-inner-left {
        &:after,
        &:before {
            top: 50%;
            transform: translate(0, -50%);
        }
        &:after {
            left: 97%;
            border-left-color: $white;
        }
        &:before {
            left: 100%;
            border-left-color: $primary;
        }
    }
    .vue-slider-dot-tooltip-inner-right {
        &:after,
        &:before {
            top: 50%;
            transform: translate(0, -50%);
        }
        &:after {
            right: 97%;
            border-right-color: $white;
        }
        &:before {
            right: 100%;
            border-right-color: $primary;
        }
    }

    .vue-slider-dot-tooltip-wrapper {
        opacity: 0;
        transition: all 0.3s;
    }
    .vue-slider-dot-tooltip-wrapper-show {
        opacity: 1;
    }

    .vue-slider-tooltip:after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border: 6px solid transparent\0;
        border-top-color: $white;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }
    .vue-slider-dot {
        z-index: 1;
    }
    .vue-slider-process {
        z-index: 0;
    }
    .vue-slider-piecewise {
        z-index: -2;
    }
    .vue-slider::after {
        z-index: -2;
    }

    &.error {
        margin-bottom: 0;
        .WRangeSliderError {
            display: inline-block;
            margin-bottom: 15px;
            margin-left: 5px;
        }
    }
}
</style>
