import getInfo from './data'

const endpoints = {
    getInfo: {
        path: '/environments/info',
        transform: [200, getInfo],
    },
}

export default {
    name: 'enviroments',
    endpoints,
}
