var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row q-ma-md" }, [
    !_vm.slaNotCompleted
      ? _c("div", [
          !_vm.ready
            ? _c(
                "div",
                [
                  _vm.interval
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("visualization.predictions.loadingMessage")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _c("WLoading", { attrs: { visible: "" } })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "PredictionList col-sm-9" }, [
            _vm.ready
              ? _c(
                  "div",
                  { staticClass: "row q-gutter-sm" },
                  [
                    _c("PredictionMetricsPanel", {
                      staticClass: "col",
                      attrs: { noFinished: _vm.total }
                    }),
                    _c("PredictionMetricsPanel", {
                      staticClass: "col",
                      attrs: { noRisk: _vm.noRisk, noFinished: _vm.total }
                    }),
                    _c("PredictionMetricsPanel", {
                      staticClass: "col",
                      attrs: { risk: _vm.risk, noFinished: _vm.total }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "PredictionList col-12 row" },
            [
              _vm.ready
                ? _c("PredictionTable", {
                    ref: "table",
                    staticClass: "col-sm-9 mt-1",
                    attrs: {
                      columns: _vm.columns,
                      rows: _vm.rows,
                      loading: _vm.isLoading,
                      total: _vm.total
                    },
                    on: {
                      download: _vm.handleDownload,
                      selectedId: _vm.caseMetrics,
                      update: _vm.update,
                      ready: _vm.selectFirstRow
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "col-sm-3 GraphColumn" }, [
                _c(
                  "div",
                  {
                    ref: "view-wrapper",
                    staticClass: "GraphHolder flex items-center q-ml-md"
                  },
                  [
                    !_vm.newVariant &&
                    _vm.chart &&
                    (_vm.chart.activities || []).length &&
                    (Object.keys(_vm.chart) || []).length
                      ? _c("Graph", {
                          ref: "chart",
                          staticClass: "Graph",
                          attrs: {
                            data: _vm.chart,
                            model: _vm.model,
                            parent: "view-wrapper"
                          }
                        })
                      : _vm._e(),
                    _vm.newVariant
                      ? _c("div", { staticClass: "col text-center text-h6" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("visualization.predictions.newVariant")
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      : _c("div", [
          _c("div", [_vm._v(" " + _vm._s(_vm.slaNotCompleted) + " ")])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }