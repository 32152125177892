<template>
    <div class="row justify-center">
        <div class="col-8 justify-center q-pa-lg">
            <div class="Title">
                {{ $t('wizard.boosterWizard.performanceBooster.title') }}
            </div>
            <div class="Title">
                <h6>{{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.description') }}</h6>
            </div>
            <div class="SettingsContainer">
                <div
                    class="Settings flex row-left-center"
                    :class="{'closeSettings': !openCCSettings && !openCaseCompletionSettings,
                             'openSettings': openCCSettings || openCaseCompletionSettings }"
                >
                    <WCard
                        shadow
                        :class="{ 'container-open': openCCSettings}"
                    >
                        <div
                            class="CardInfo row-left-center"
                            @click="handleCCSettings ()"
                        >
                            <div
                                class="Item flex row-left-center flex-nowrap card-container"
                            >
                                <WIcon
                                    icon="money_increase"
                                    color="primary"
                                    :size="40"
                                />
                                <div class="flex-wrap">
                                    <WText
                                        class="pl-1"
                                        typo="h3"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.settings.costs.name') }}
                                    </WText>
                                    <WText
                                        v-if="values.cost"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ values.cost.value }} {{ $t('visualization.settings.costs.units.'+values.cost.unit) }}
                                    </WText>
                                </div>
                            </div>
                            <div class="Item rightIcon">
                                <WIcon
                                    v-if="!openCCSettings"
                                    icon="chevron-right"
                                />
                                <WIcon
                                    v-if="openCCSettings"
                                    icon="chevron-left"
                                />
                            </div>
                        </div>
                    </WCard>
                    <WCard
                        shadow
                        :class="{ 'container-open': openCaseCompletionSettings}"
                    >
                        <div
                            class="CardInfo row-left-center"
                            @click="handleCaseCompletionSettings ()"
                        >
                            <div
                                class="Item flex row-left-center flex-nowrap card-container"
                            >
                                <div class="caseCompletionIconContainer">
                                    <WIcon
                                        icon="trace"
                                        color="primary"
                                        :size="50"
                                    />
                                </div>
                                <div class="caseCompletionContainer">
                                    <WText
                                        class="pl-1"
                                        typo="h3"
                                        tag="p"
                                        weight="bold"
                                    >
                                        {{ $t('visualization.settings.caseCompletion.name') }}
                                    </WText>
                                    <WText
                                        v-if="!caseCompletionFilled"
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsNoConf') }}
                                    </WText>
                                    <WText
                                        v-else
                                        class="pl-1 textCost"
                                        color="primary"
                                        tag="p"
                                        weight="medium"
                                    >
                                        {{ $t('visualization.settings.caseCompletionConditionsConf') }}
                                    </WText>
                                </div>
                            </div>
                            <div class="Item rightIcon">
                                <WIcon
                                    v-if="!openCaseCompletionSettings"
                                    icon="chevron-right"
                                />
                                <WIcon
                                    v-if="openCaseCompletionSettings"
                                    icon="chevron-left"
                                />
                            </div>
                        </div>
                    </WCard>
                </div>
                <div v-if="values && Object.keys(values).length > 0">
                    <CostCurrencySettings
                        ref="CostCurrencySettings"
                        :values="values.cost"
                        :isOpen="openCCSettings"
                        :saveButton="false"
                        @CostCurrencyConfig="saveSettings"
                    />
                    <CaseCompletionConditions
                        ref="CaseCompletionConditions"
                        :isOpen="openCaseCompletionSettings"
                        :values="values.caseCompletion"
                        :saveButton="false"
                        @CaseCompletionConditionsConfig="saveSettings"
                    />
                </div>
            </div>
            <div class="row justify-center mt-1">
                <div
                    v-if="!caseCompletionFilled"
                    class="Warning rounded-borders q-pa-md"
                >
                    <QIcon
                        class="Icon"
                        name="info"
                        color="white"
                        size="40px"
                    />
                    <WText>
                        {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.warning') }}
                    </WText>
                </div>
            </div>
            <div class="row justify-between mt-1">
                <WButton
                    color="primary"
                    style="padding-inline: 40px;"
                    @click="handleReturn"
                >
                    {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.back') }}
                </WButton>
                <WButton
                    class="BoostButton"
                    style="padding-inline: 40px;"
                    @click="uploadData"
                >
                    {{ $t('wizard.boosterWizard.performanceBooster.costAndCaseCompletion.buttons.boost') }}
                </WButton>
            </div>
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import CostCurrencySettings from '../../Project/Process/Visualization/sections/SettingsModal/components/CostCurrencySettings.vue'
import CaseCompletionConditions from '../../Project/Process/Visualization/sections/SettingsModal/components/CaseCompletionConditions.vue'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'

// const DEFAULT_VISUALIZATION = 'MODEL'

export default {
    name: 'WizardUpload',
    components: {
        CostCurrencySettings,
        CaseCompletionConditions,
    },
    props: {
    },
    data () {
        return {
            values: { cost: {}, caseCompletion: {} },
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            name: this.$route.params.name,
            openCCSettings: false,
            openCaseCompletionSettings: false,
            settings: VueTypes.oneOfType([Array, Object]),
            caseCompletionFilled: false,
        }
    },
    mounted () {
        this.getSettings()
    },
    methods: {
        saveSettings (settings) {
            this.values[settings.type] = { ...settings.data }
            this.checkCaseCompletion()
        },
        getSettings () {
            apiRequest(Api().datasets.get({
                datasetId: this.$route.params.processId,
            }))
                .then((data) => {
                    // eslint-disable-next-line prefer-destructuring
                    this.values = data.data[0]
                })
                .catch(notifyError)
        },
        handleCCSettings () {
            this.openCCSettings = !this.openCCSettings
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
            if (this.openCaseCompletionSettings) {
                this.openCaseCompletionSettings = false
            }
        },
        handleCaseCompletionSettings () {
            this.openCaseCompletionSettings = !this.openCaseCompletionSettings
            if (this.openCCSettings) {
                this.openCCSettings = false
            }
            if (this.openSlaSettings) {
                this.openSlaSettings = false
            }
        },
        handleReturn () {
            this.$router.push({ name: 'WizardSLA', params: { projectId: this.projectId, processId: this.processId } })
        },
        uploadData () {
            apiRequest(Api().datasets.update({
                datasetId: this.$route.params.processId,
                params: this.values,
            }))
                .then(() => {
                    notifySuccess(this.$t('visualization.settings.settingsSaved'))
                    this.setOpened()
                    this.getSettings()
                    this.checkCaseCompletion()
                    this.$router.push({ name: 'ProcessBooster', params: { projectId: this.projectId, tab: 'subview' } })
                })
                .catch(notifyError)
        },
        setOpened () {
            apiRequest(Api().datasets.setOpened({
                datasetId: this.processId,
            }))
                .catch(notifyError)
        },
        checkCaseCompletion () {
            if (
                this.values.caseCompletion &&
                this.values.caseCompletion.endingActivities &&
                this.values.caseCompletion.occurringActivities &&
                this.values.caseCompletion.startingActivities
            ) {
                console.log('Casos completos')
                console.log(this.values.caseCompletion)
                if (
                    this.values.caseCompletion.endingActivities.length > 0 ||
                    this.values.caseCompletion.occurringActivities.length > 0 ||
                    this.values.caseCompletion.startingActivities.length > 0
                ) {
                    this.caseCompletionFilled = true
                } else {
                    this.caseCompletionFilled = false
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.Head {
        margin-left: 10vw;
    }
.Title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin-top: 4vh;
    }
.Warning {
    width: 80%;
    background-color: #ffe48c;
    display: flex;
    align-items: center;
    .Icon {
        margin-left: 1vw;
        margin-right: 1vw;
    }
}
.BoostButton {
    border-color: #2596be !important;
    background-color: #2596be !important;
}
    .SettingsContainer {
        width: 100%;
        margin: 0 auto;
        min-height: 300px;
    }
    .openSettings{
        width: 40%;
    }
    .closeSettings{
        width:100%;
    }
    .Settings {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        float: left;
        .Buttons {
            height: 5vh;
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 10px;
        }
        .WCard {
            height: 100%;
            align-items: center;
            width: 100%;
            margin-right: 10px;
            &::v-deep .Body {
                width: 100%;
                padding: 0;
                .Item {
                    padding: 20px;
                }
            }
            .CardInfo {
                width: 100%;
                display: inline-flex;
            }
        }

        .rightIcon {
            display: flex;
            justify-content: flex-end;
        }
        .WCard:hover{
            box-shadow: 0px 0px 1px 1px $alpha-primary;
            border: solid 1px rgba(68, 68, 68, 0.2);}
        .WCard {
            background-color: #f5f5f5;
        }

        .card-container{
            width:100%;
        }
        .container-open {
            background-color: #ffffff;
            border: solid 1px rgba($black, 0.2);          }
          .caseCompletionContainer {
            margin-left: -30px;
        }
        .caseCompletionIconContainer {
            transform: translate(-15px, 0px);
        }
        @media screen and (min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $lg) {
            grid-template-columns: repeat(3, minmax(10px, 280px));
        }
        @media screen and (min-width: $xl) {
            grid-template-columns: repeat(4, minmax(10px, 280px));
        }
    }
</style>
