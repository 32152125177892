var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProjectsList" },
    [
      _c("Table", {
        ref: "table",
        staticClass: "Table",
        attrs: {
          columns: _vm.cols,
          data: _vm.normalizeRows,
          total: _vm.total,
          loading: _vm.loading,
          hideHeader: _vm.hideHeader,
          rowKey: "uuid"
        },
        on: { "table-request": _vm.handleFetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "QTr",
                  { staticClass: "cursor-pointer" },
                  [
                    _c(
                      "QTd",
                      {
                        key: "code",
                        staticClass: "td-code",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToProject(row)
                          }
                        }
                      },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.code) + " ")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "name",
                        staticClass: "td-name",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToProject(row)
                          }
                        }
                      },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.name) + " ")
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("general.createdAt")) +
                              " " +
                              _vm._s(
                                _vm.App.dateLocationFormat(
                                  row.createDate,
                                  "long"
                                )
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "description",
                        staticClass: "td-desc",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToProject(row)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "td-desc ellipsis" }, [
                          _vm._v(_vm._s(row.description || "-"))
                        ]),
                        row.description
                          ? _c(
                              "QTooltip",
                              {
                                attrs: {
                                  contentClass: "bg-primary text-body2",
                                  maxWidth: "300px"
                                }
                              },
                              [_vm._v(" " + _vm._s(row.description) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "tst",
                        staticClass: "td-tst",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToProject(row)
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.tst !== ""
                                ? _vm.App.dateLocationFormat(row.tst, "long")
                                : "-"
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "QTd",
                      {
                        key: "deleteDate",
                        staticClass: "td-deleted",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToProject(row)
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !!row.deleteDate
                                ? _vm.App.dateLocationFormat(
                                    row.deleteDate,
                                    "long"
                                  )
                                : "-"
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "QTd",
                      { key: "actions", staticClass: "Actions align-right" },
                      [
                        _c("WIcon", {
                          attrs: {
                            icon: "more-vertical",
                            size: 24,
                            color: "primary",
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.setSelected(row, $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "EmptyList",
                  _vm._b(
                    {
                      staticClass: "mt-2 mb-2",
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "WButton",
                                {
                                  staticClass: "mt-1",
                                  attrs: { iconLeft: "plus-circle" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("onNewItem")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("projects.new")) + " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    "EmptyList",
                    _vm.emptyMessage,
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.selected
        ? _c("Dropdown", {
            attrs: {
              opened: !!_vm.selected,
              options: _vm.selected.options,
              coords: _vm.selected.coords
            },
            on: { onClose: _vm.cleanSelected, onClick: _vm.handleClickOption }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }