var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InsightModel pa-1" },
    [
      _c("WText", { staticClass: "pb-1", attrs: { tag: "p" } }, [
        _vm._v(
          " " + _vm._s(_vm.$t("wizard.query.insight.model.description")) + " "
        )
      ]),
      _c("InsightModelSection", {
        attrs: {
          title: _vm.$t("wizard.query.insight.model.seq.title"),
          description: _vm.$t("wizard.query.insight.model.seq.description"),
          examples: _vm.$t("wizard.query.insight.model.seq.examples")
        }
      }),
      _c("InsightModelSection", {
        attrs: {
          title: _vm.$t("wizard.query.insight.model.switch.title"),
          description: _vm.$t("wizard.query.insight.model.switch.description"),
          examples: _vm.$t("wizard.query.insight.model.switch.examples")
        }
      }),
      _c("InsightModelSection", {
        attrs: {
          title: _vm.$t("wizard.query.insight.model.fork.title"),
          description: _vm.$t("wizard.query.insight.model.fork.description"),
          examples: _vm.$t("wizard.query.insight.model.fork.examples")
        }
      }),
      _c("InsightModelSection", {
        attrs: {
          title: _vm.$t("wizard.query.insight.model.loop.title"),
          description: _vm.$t("wizard.query.insight.model.loop.description"),
          examples: _vm.$t("wizard.query.insight.model.loop.examples")
        }
      }),
      _c("InsightModelSection", {
        staticClass: "custom",
        attrs: {
          description: _vm.$t("wizard.query.insight.model.custom.description"),
          examples: _vm.$t("wizard.query.insight.model.custom.examples")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }