<template>
    <Component
        :is="tag ? tag : tagByTypo[typo]"
        :class="typoClass"
        class="WText"
    >
        <slot />
    </Component>
</template>

<script>
import VueTypes from 'vue-types'

const fontSizes = [8, 10, 12, 14, 16, 20, 22, 24, 30, 32, 36, 48, 60]
const typos = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body', 'p', 'span', 'label']

export default {
    name: 'WText',
    props: {
        typo: VueTypes.oneOf(typos).def('p'),
        tag: VueTypes.oneOf(['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'label']).optional,
        color: VueTypes.oneOf(
            [
                'primary',
                'secondary',
                'tertiary',
                'white',
                'black',
                'yellow',
                'red',
                'gray01',
                'gray02',
                'gray03',
                'gray04',
                'accent',
                'success',
                '',
            ],
        ).optional,
        align: VueTypes.oneOf(['left', 'center', 'right', '']).def('left'),
        size: VueTypes.oneOf([...fontSizes, ...fontSizes.map(String)]).optional,
        weight: VueTypes.oneOf(['light', 'medium', 'semi', 'bold', '']).def(''),
        font: VueTypes.oneOf(['sans']).def('sans'),
        capitalize: VueTypes.bool.def(false),
        uppercase: VueTypes.bool.def(false),
        italic: VueTypes.bool.def(false),
        underline: VueTypes.bool.def(false),
        ellipsis: VueTypes.bool.def(false),
    },
    data () {
        return {
            tagByTypo: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                p: 'p',
                span: 'span',
                body: 'p',
                label: 'label',
            },
        }
    },
    computed: {
        typoClass () {
            return [
                this.typo,
                this.color,
                this.weight,
                this.align,
                this.size ? `fs${this.size}` : undefined,
                this.font,
                {
                    capitalize: this.capitalize,
                    ellipsis: this.ellipsis,
                    underline: this.underline,
                    uppercase: this.uppercase,
                    italic: this.italic,
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.WText {
    &.p {
        color: $body-color;
        font-family: $font-family-sans-serif;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        line-height: 1.4;
    }

    &.span {
        color: $body-color;
        font-family: $font-family-sans-serif;
        font-size: $font-size;
        font-weight: $font-weight-normal;
        line-height: 1.4;
    }

    &.h1,
    &.h2,
    &.h3,
    &.h4,
    &.h5,
    &.h6 {
        margin-bottom: $headings-margin-bottom;
        color: $black;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        line-height: 1.2;
    }

    &.h1 {
        font-size: 24px;
        line-height: 24px;
    }
    &.h2 {
        font-size: 18px;
    }
    &.h3 {
        font-size: 16px;
        line-height: 15px;
    }
    &.h4 {
        font-size: 14px;
    }
    &.h5 {
        font-size: 12px;
        letter-spacing: 1px;
    }
    &.h6 {
        letter-spacing: 1px;
        font-size: 10px;
    }

    &.p, &.span, &.label {
        $font-sizes: ('8', '10', '12', '14', '16', '18', '20', '22', '24', '30', '32', '36', '48', '60');
        @for $i from 0 to length($font-sizes) {
            $size: unquote(nth($font-sizes, $i+1));
            &.fs#{$size} {
                font-size: #{$size}px;
            }
        }
    }

    &.label {
        display: inline-block;
        margin-bottom: 8px;
    }

    // Colors
    &.white {
        color: $white;
    }
    &.primary {
        color: $primary;
    }
    &.secondary {
        color: $secondary;
    }
    &.tertiary {
        color: $tertiary;
    }
    &.yellow {
        color: $warning;
    }
    &.red {
        color: $danger;
    }
    &.black {
        color: $black;
    }
    &.gray01 {
        color: $gray-01;
    }
    &.gray02 {
        color: $gray-02;
    }
    &.gray03 {
        color: $gray-03;
	}
	&.gray04 {
        color: $gray-04;
    }
    &.accent {
        color: $accent;
    }
    &.success {
        color: $success;
    }

    // Weight
    &.light {
        font-weight: $font-weight-light;
    }
    &.medium {
        font-weight: $font-weight-normal;
    }
    &.semi {
        font-weight: $font-weight-semibold;
    }
    &.bold {
        font-weight: $font-weight-bold;
    }

    // Align
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }

    // Font
    &.sans {
        font-family: $font-family-sans-serif;
    }

    // Other styling
    &.uppercase {
        text-transform: uppercase;
    }
    &.italic {
        font-style: italic;
    }
    &.underline {
        text-decoration: underline;
    }
    &.capitalize {
        text-transform: capitalize;
    }

    &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
