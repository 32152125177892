import Data from './data'

export default {
    list: ({ params }) => [200, Data.list],
    get: ({ url, baseURL, regex }) => {
        const path = url.replace(baseURL, '')
        const [, queryId] = path.match(regex)
        if (queryId === 'modes') {
            return [200, Data.modes]
        }

        return [200, Data.get]
    },
    modeReportTypes: () => [200, Data.modeReportTypes],
}
