<template>
    <div class="Model pr-1 pl-1 pt-1">
        <WText class="mb-1">
            {{ $t('visualization.availableVariants') }}
        </WText>
        <div class="Labels flex row-stretch-top cursor-pointer">
            <QTooltip
                anchor="center left"
                self="center right"
                :offset="[10, 10]"
                contentClass="bg-primary"
            >
                {{ $t('visualization.editVariants') }}
            </QTooltip>
            <QChip
                square
                textColor="white"
                color="primary"
                outline
            >
                {{ minValue }}
            </QChip>
            <QChip
                square
                textColor="white"
                :color="percentageColor"
                class="text-weight-bold"
            >
                {{ `${formatNumber(sliderValue)} (${percentageValue}%)` }}
            </QChip>
            <QChip
                square
                textColor="white"
                color="secondary"
                outline
            >
                {{ formatNumber(maxValue) }}
            </QChip>
            <QPopupEdit :value="undefined">
                <QInput
                    ref="textinput"
                    :value="sliderValue"
                    type="number"
                    :label="$t('visualization.modelLabel')"
                    :max="maxValue"
                    :min="minValue"
                    :disable="isLoading || !minValue || disabledButton"
                    :debounce="600"
                    :rules="[
                        value => validators.minValue(value, minValue)|| $t('validation.minValue', { attribute: $t('visualization.variants'), min: minValue }),
                        value => validators.maxValue(value, maxValue)|| $t('validation.maxValue', { attribute: $t('visualization.variants'), max: maxValue }),
                    ]"
                    @input="handleChangeTextInput"
                />
            </QPopupEdit>
        </div>
        <div class="row">
            <div class="col-sm-12 q-pr-md">
                <QSlider
                    :value="sliderValue"
                    class="q-ma-sm"
                    :color="percentageColor"
                    :labelValue="variantLabel"
                    :min="minValue"
                    :max="maxValue"
                    :disable="isLoading || !minValue || disabledButton"
                    @change="handleChangedModel"
                    @input="handleChangeSliderValue"
                />
            </div>
        </div>
        <div class="mt-1">
            <WButton
                outline
                class="w-full"
                style="justify-content: center;"
                size="sm"
                iconLeft="filter"
                :iconSize="10"
                :disabled="isVariantFilterActive || disabledButton"
                :class="{ 'active-filter': isVariantFilterActive }"
                @click="toggleVariantFilter"
            >
                {{ isVariantFilterActive ? $t('visualization.filterVariantsApplied') : $t('visualization.filterVariants') }}
            </WButton>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { validatorsMixin, filtersStorageMixin } from '@/mixins'

const MIN_VALUE = 1
export default {
    name: 'Model',
    mixins: [validatorsMixin, filtersStorageMixin],
    props: {
        value: VueTypes.shape({
            heatMapValue: VueTypes.string,
            directionValue: VueTypes.string,
            statsValue: VueTypes.string,
            values: VueTypes.string,
            variant: VueTypes.number,
            variants: VueTypes.number,
            duration: VueTypes.any,
        }).loose,
        info: VueTypes.object,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        disabledButton: VueTypes.bool.def(false),
        percentageMode: VueTypes.string,
        directionDisplay: VueTypes.string,
        statsDisplay: VueTypes.string,
    },
    data () {
        return {
            isLoading: false,
            isFetching: false,
            selectedKpi: undefined,
            heatMapValue: false,
            directionValue: false,
            sliderValue: (this.value || {}).variant || 1,
            percentageValue: Math.round((this.value || {}).variant / this.maxValue * 100) || 1,
            variants: undefined,
            isVariantFilterActive: false,
        }
    },
    computed: {
        maxValue () {
            const { variants } = this.info || {}
            return variants || 1
        },
        variantLabel () {
            return `${this.sliderValue} (${this.percentageValue}%)`
        },
        arrayMarkerLabel () {
            return [
                { value: this.minValue, label: this.minValue },
                { value: this.maxValue, label: this.maxValue },
            ]
        },
        percentageColor () {
            if (this.percentageValue >= 75) {
                return 'secondary'
            }
            return 'primary'
        },
    },
    watch: {
        value (val) {
            this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
        },
        isLoading: 'emitVariantsLoading',
        isFetching: 'emitVariantsLoading',
        maxValue: {
            handler () {
                this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
                this.handleChangeSliderValue((this.value || {}).variant || 1)
            },
            immediate: true,
        },
    },
    beforeMount () {
        this.minValue = MIN_VALUE
    },
    async mounted () {
        this.checkForVariantFilter()
        const { variant } = this.value
        if (this.processId) {
            await this.fetchModel(variant)
        }
    },
    destroyed () {
    },
    methods: {
        checkForVariantFilter () {
            this.$root.$emit('checkForVariantFilter')
            this.$root.$on('hasVariantFilter', (variantFilter) => {
                this.isVariantFilterActive = variantFilter
            })
        },
        toggleVariantFilter () {
            if (!this.isVariantFilterActive) {
                this.getVariants()
            }
        },
        getVariants () {
            this.isLoading = true
            const params = { start: 0, limit: this.sliderValue }
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data.map(item => item.id)
                    this.filterByVariant()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        filterByVariant () {
            const variantIds = this.variants.slice(0, this.sliderValue)
            this.$root.$emit('filterByVariant', variantIds)
        },
        handleHeatMapChange (type, value) {
            this.handleInput(type, { value: value ? 'freq' : 'none' })
        },
        handleDirectionChange (value) {
            this.directionValue = value
            this.handleInput('directionValue', { value: value ? 'LR' : 'TB' })
        },
        handleInput (key, { value }) {
            this.$emit('input', { ...(this.value || {}), [key]: value })
        },
        handleChangeSliderValue (value) {
            this.sliderValue = value
            this.percentageValue = Math.round(value / this.maxValue * 100)
        },
        formatNumber (num) {
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
        handleChangeTextInput (value) {
            this.$refs.textinput.validate(value)
            if (!this.$refs.textinput.hasError) {
                this.handleChangedModel(value)
            }
        },
        handleChangedModel (value) {
            this.handleChangeSliderValue(Number(value))
            const { variants = 1 } = this.info
            if (value > variants || value < 1) return
            const newValue = Number(value)

            this.$emit('onChangedVariant', newValue)
            this.handleInput('variant', { value: newValue })
            // DOC: Fix animation on change big input values, added delay to move slider component
            setTimeout(() => this.getModel(newValue), 500)
        },
        emitVariantsLoading () {
            if (!this.isFetching && !this.isLoading) {
                this.$emit('variantsLoading', false)
            } else {
                this.$emit('variantsLoading', true)
            }
        },
        async fetchModel (variant) {
            await this.getModel(variant)
        },
        async getModel (variants) {
            const { selectedKpi, processId } = this
            const filters = this.formatFiltersParam(this.visualizationFilters)
            this.isFetching = true
            const params = { variants, filters }
            apiRequest(Api().visualizations.model({ processId, params }))
                .then(({ data }) => {
                    const [graph] = data || []
                    this.$emit('onLoadChart', { ...graph, variants })
                    this.handleInput('kpi', { value: selectedKpi })
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
    },
}
</script>
<style lang="scss" scoped>
.Stats{
    flex-wrap: nowrap;
}
.WButton {
    padding: 7px;
}
.WSwitch {
    height: 20px;
    width: 34px;
    &::v-deep .Slider {
        position: absolute;
        &:before {
            position: absolute;
            content: '';
            height: 14px;
            width: 14px;
            left: 3px;
            right: 2px;
            top: 50%;
            transform: translate(0px, -50%);
            border-radius: 50%;
            background-color: $white;
            transition: 0.4s;
        }
    }
    &::v-deep input {
        &:checked:not(.disabled) {
            & + .Slider {
                &:before {
                    transform: translate(16px, -50%);
                }
            }
        }
    }
}
</style>
