var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "WRangeSlider",
      class: { error: _vm.error },
      attrs: { id: _vm.id }
    },
    [
      _c(
        "VueSlider",
        _vm._g(
          {
            ref: "slider",
            attrs: {
              value: _vm.inputValue,
              data: _vm.data.length > 0 ? _vm.data : null,
              minRange: _vm.minRange,
              maxRange: _vm.maxRange,
              interval: _vm.interval,
              min: _vm.min,
              max: _vm.max,
              disabled: _vm.disabled,
              enableCross: _vm.enableCross,
              fixed: _vm.fixed,
              lazy: _vm.lazy,
              height: _vm.height,
              width: _vm.processedWidth,
              dotSize: _vm.dotSize,
              bgStyle: _vm.bgStyle,
              dotOptions: _vm.dotOptions,
              railStyle: _vm.railStyle,
              processStyle: _vm.processStyle,
              tooltip: _vm.tooltip,
              tooltipPlacement: _vm.tooltipDir,
              direction: _vm.direction,
              tooltipFormatter: _vm.tooltipFormatter
            }
          },
          _vm.inputListeners
        )
      ),
      _c(
        "Transition",
        { attrs: { name: "fade", duration: 500 } },
        [
          _vm.error
            ? _c(
                "WText",
                {
                  staticClass: "WRangeSliderError",
                  attrs: { typo: "span", size: "10", color: "error" }
                },
                [_vm._v(" " + _vm._s(_vm.error) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }