const event = {
    dataset: {
        createDate: '2020-05-21T10:18:39',
        deleteDate: '2020-05-21T10:18:39',
        tst: 'string',
        description: 'string',
        filename: '1000_2001_Procure to Pay_Procure to Pay_603_E.csv',
        name: 'string',
        parsed: true,
        uploaded: true,
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    date: '2020-09-01',
    measurement: 0,
    organization: {
        createDate: '2020-05-21T10:18:39',
        deleteDate: '2020-05-21T10:18:39',
        tst: 'string',
        description: 'string',
        owner: true,
        name: 'Organization 1',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    project: {
        createDate: '2020-05-21T10:18:39',
        deleteDate: '2020-05-21T10:18:39',
        tst: 'string',
        code: 'string',
        description: 'string',
        name: 'Project name',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
}

const data = Array.from({ length: 5 }).fill(event)

export default {
    data,
    total: data.length,
}
