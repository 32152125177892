export default {
    data () {
        return {
            selected: undefined,
        }
    },
    methods: {
        handleNewItem () {
            this.$refs.newPrDialog.open()
        },
        handleDeleteItem ({ uuid, name }) {
            this.selected = { uuid, name }
            this.$refs.delPrDialog.open()
        },
        handleUpdateItem ({ uuid, name, description }) {
            this.$refs.editPrDialog.open({ uuid, name, description })
        },
    },
}
