var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      staticClass: "EmptyList",
      class: [_vm.data.class, _vm.data.staticClass],
      style: _vm.data.style
    },
    [
      _vm._t("header"),
      _vm.props.icon
        ? _c("WIcon", {
            attrs: {
              icon: _vm.props.icon,
              color: _vm.props.iconColor,
              size: _vm.props.iconSize
            }
          })
        : _vm._e(),
      _vm.props.title
        ? _c(
            "WText",
            { attrs: { color: _vm.props.color, size: "20", align: "center" } },
            [_vm._v(" " + _vm._s(_vm.props.title) + " ")]
          )
        : _vm._e(),
      _vm.props.text
        ? _c(
            "WText",
            {
              staticClass: "Text",
              attrs: { color: _vm.props.color, size: "14", align: "center" }
            },
            [_vm._v(" " + _vm._s(_vm.props.text) + " ")]
          )
        : _vm._e(),
      _vm._t("footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }