import { render, staticRenderFns } from "./SAPImport.vue?vue&type=template&id=2b668270&scoped=true&"
import script from "./SAPImport.vue?vue&type=script&lang=js&"
export * from "./SAPImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b668270",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/porto/inverbis/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b668270')) {
      api.createRecord('2b668270', component.options)
    } else {
      api.reload('2b668270', component.options)
    }
    module.hot.accept("./SAPImport.vue?vue&type=template&id=2b668270&scoped=true&", function () {
      api.rerender('2b668270', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/screens/Wizard/Import/SAPImport.vue"
export default component.exports