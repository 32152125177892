var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "CostCurrencySettings" }, [
        _c(
          "div",
          { staticClass: "CostCurrency" },
          [
            _c(
              "WText",
              {
                staticClass: "mb-1",
                attrs: { weight: "bold", color: "primary" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.settings.costs.name")) +
                    " "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "OptionsGroup row justify-start" },
              [
                _c("QInput", {
                  staticClass: "col",
                  attrs: {
                    standout: "",
                    outlined: "",
                    type: "number",
                    labelColor: "primary",
                    label: _vm.$t("visualization.settings.costs.value"),
                    color: "primary",
                    autogrow: _vm.inputOptions
                  },
                  on: { input: _vm.saveWithoutButton },
                  model: {
                    value: _vm.number,
                    callback: function($$v) {
                      _vm.number = $$v
                    },
                    expression: "number"
                  }
                }),
                _c("QSelect", {
                  staticClass: "col",
                  attrs: {
                    outlined: "",
                    labelColor: "primary",
                    color: "primary",
                    label: _vm.$t("visualization.settings.costs.unit"),
                    options: _vm.curOptions
                  },
                  on: { input: _vm.saveWithoutButton },
                  model: {
                    value: _vm.unit,
                    callback: function($$v) {
                      _vm.unit = $$v
                    },
                    expression: "unit"
                  }
                })
              ],
              1
            ),
            _vm.saveButton
              ? _c(
                  "div",
                  { staticClass: "flex justify-end mt-1" },
                  [
                    _c(
                      "WButton",
                      {
                        staticClass: "buttons",
                        attrs: { outline: "", color: "primary" },
                        on: { click: _vm.saveWithButton }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("visualization.filters.save")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }