<template>
    <div class="HeaderList flex row-stretch-center">
        <div class="HeaderLeft">
            <slot name="headerLeft" />
        </div>
        <div class="HeaderRight flex row-stretch-center">
            <slot name="headerRight" />
            <label
                v-if="enableArchived"
                class="flex row-left-center"
            >
                <WText
                    :size="12"
                    class="mr-1"
                >
                    {{ $t('general.archived') }}
                </WText>
                <WSwitch
                    id="visibility"
                    name="visibility"
                    :value="value.archived"
                    :disable="disabled"
                    @input="handleInput('archived', $event)"
                />
            </label>
            <Transition
                name="fade"
                mode="out-in"
            >
                <QInput
                    v-if="search"
                    v-model="value.search"
                    dense
                    outlined
                    type="search"
                    :label="$t('actions.search')"
                    class="Search search-input float-left"
                    @keyup.enter="handleInput('search', value.search)"
                >
                    <template v-slot:append>
                        <WIcon
                            v-if="value.search"
                            id="clean"
                            icon="close"
                            :size="20"
                            clickable
                            @click="handleClean"
                        />
                        <WIcon
                            id="search"
                            icon="search1"
                            :size="20"
                            clickable
                            @click="handleInput('search', value.search)"
                        />
                    </template>
                </QInput>
            </Transition>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

const ENABLE_ARCHIVED = false

export default {
    name: 'HeaderList',
    props: {
        value: VueTypes.shape({
            archived: VueTypes.bool,
            search: VueTypes.string,
        }),
        search: VueTypes.bool.def(true),
        disabled: VueTypes.bool.def(false),
        enableArchived: VueTypes.bool.def(ENABLE_ARCHIVED),
    },
    methods: {
        handleClean () {
            this.$emit('input', { ...this.value, search: '' })
        },
        handleInput (key, value) {
            this.$emit('input', { ...this.value, [key]: value })
        },
    },
}
</script>
<style scoped lang="scss">
.Search {
    width: 275px;
}
</style>
