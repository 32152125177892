<template>
    <div
        id="bpmn"
        ref="viewer"
    />
</template>
<script>
import VueTypes from 'vue-types'
import BpmnViewer from 'bpmn-js/lib/NavigatedViewer'
import { notifyError } from '@/api'

export default {
    name: 'BpmnViewer',
    components: {
    },
    props: {
        data: VueTypes.string,
    },
    data () {
        return {
            viewer: undefined,
        }
    },
    computed: {
    },
    watch: {
        data () {
            try {
                this.viewer.importXML(this.data).then(() => {
                    this.viewer.get('canvas').zoom('fit-viewport')
                })
            } catch (err) {
                this.handleError(err)
            }
        },
    },
    mounted () {
        this.viewer = new BpmnViewer({
            container: '#bpmn',
        })
        try {
            this.viewer.importXML(this.data).then(() => {
                this.viewer.get('canvas').zoom('fit-viewport')
            })
        } catch (err) {
            this.handleError(err)
        }
    },
    methods: {
        handleError (err) {
            notifyError(this.$t('visualization.bpmnNotShowable: ') + err)
        },
    },
}
</script>
<style scoped lang="scss">
    #bpmn {
        height: 350px;
        width: 100%;
    }
</style>
