var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "assistant_window" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "loading-container" },
          [
            _vm.isLoading
              ? _c("WLoading", { attrs: { visible: "" } })
              : _vm._e()
          ],
          1
        )
      : _c("div", { staticClass: "chat", attrs: { visible: "" } }, [
          _vm.noChat
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "q-pa-lg q-gutter-md" },
                  [
                    _c(
                      "QBanner",
                      {
                        staticClass: "bg-warning text-white w-half",
                        staticStyle: { "max-width": "70%", margin: "0 auto" },
                        attrs: { rounded: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "avatar",
                              fn: function() {
                                return [
                                  _c("QIcon", {
                                    staticStyle: { "font-size": "3em" },
                                    attrs: { name: "warning" }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "action",
                              fn: function() {
                                return [
                                  _c("QBtn", {
                                    staticClass:
                                      "bg-white text-warning text-weight-bold q-my-md",
                                    attrs: {
                                      label: _vm.$t("assistant.upload")
                                    },
                                    on: { click: _vm.toggleCheckbox }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2912181974
                        )
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("assistant.labsDisclaimer")) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _c("div", { staticClass: "q-ma-sm row justify-between" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "QBtn",
                      {
                        staticClass: "q-mr-sm",
                        attrs: {
                          color: "primary",
                          icon: "refresh",
                          noCaps: ""
                        },
                        on: { click: _vm.restartConversation }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("assistant.restartConversation")) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "QBtn",
                      {
                        staticClass: "q-mr-sm",
                        attrs: { color: "primary", icon: "upload", noCaps: "" },
                        on: { click: _vm.openModal }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("assistant.reuploadDataset")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col align-right" },
                  [
                    _c(
                      "QChip",
                      {
                        attrs: {
                          square: "",
                          textColor: "white",
                          icon: "warning",
                          color: "warning"
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("assistant.labs")) + " "),
                        _c(
                          "QTooltip",
                          {
                            attrs: {
                              anchor: "bottom middle",
                              self: "center middle",
                              offset: [20, 20],
                              contentClass: "bg-primary"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("assistant.labsDisclaimer")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
          !_vm.noChat
            ? _c("div", { staticClass: "chatContainer" }, [
                !_vm.noChat
                  ? _c("div", { staticClass: "messagesShow" }, [
                      _vm.showModal
                        ? _c("div", { staticClass: "modal-overlay" }, [
                            _vm.showModal
                              ? _c("div", { staticClass: "modal" }, [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("assistant.reuploadWarning")
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-center q-my-sm" },
                                    [
                                      _c(
                                        "WButton",
                                        {
                                          staticClass: "button",
                                          staticStyle: { "margin-right": "5%" },
                                          attrs: { color: "primary" },
                                          on: { click: _vm.reuploadDataset }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("assistant.yes")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "WButton",
                                        {
                                          staticClass: "button",
                                          attrs: { color: "primary" },
                                          on: { click: _vm.closeModal }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("assistant.no")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "messageList" },
                        [
                          _vm.isThinking
                            ? _c("div", { staticClass: "thinking" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("assistant.thinking"))
                                ),
                                _c("span", { staticClass: "dots" }, [
                                  _vm._v(".")
                                ])
                              ])
                            : _vm._e(),
                          _vm._l(_vm.messages, function(message) {
                            return _c("Message", {
                              key: message.messageId,
                              class: [
                                "message",
                                {
                                  "user-message": !(
                                    message.messageAuthor === "User"
                                  )
                                },
                                {
                                  right: message.messageAuthor === "User",
                                  "other-message":
                                    message.messageAuthor !== "User"
                                }
                              ],
                              attrs: {
                                time: message.messageTimestamp,
                                dark: message.messageAuthor === "User",
                                text: _vm.getMessageText(
                                  message.messageContent
                                ),
                                author: message.author
                              }
                            })
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "chatBoxContainer" },
                        [
                          _c("ChatBox", {
                            staticClass: "chat-box",
                            on: { submit: _vm.onSubmit }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }