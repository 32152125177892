import Transforms from './transforms'

const endpoints = {
    me: {
        path: `/me`,
        transform: Transforms.me,
    },
    update: {
        method: 'PUT',
        path: `/me`,
        transform: Transforms.update,
    },
    resetPassword: {
        method: 'POST',
        path: `/me/reset-password`,
    },
    list: {
        path: `/users`,
        transform: Transforms.list,
    },
}

export default {
    name: 'users',
    endpoints,
}
