<template>
    <div
        class="Collapse"
        :style="styles"
    >
        <slot name="header" />

        <FadeHeightTransition>
            <div v-if="opened">
                <slot />
            </div>
        </FadeHeightTransition>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { FadeHeightTransition } from '../Transitions'

export default {
    name: 'Collapse',
    components: {
        FadeHeightTransition,
    },
    props: {
        opened: VueTypes.bool.def(false),
        styles: VueTypes.object,
    },
}
</script>
