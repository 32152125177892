<template>
    <div class="Dashboard">
        <div class="Container flex row-stretch flex-nowrap">
            <Sidebar
                v-model="tab"
                :tabs="tabs"
                @input="handleChangeTab"
            />

            <div class="Wrapper">
                <Transition
                    name="main-router"
                    mode="out-in"
                >
                    <div
                        v-if="meta.header"
                        class="Header flex row-stretch-center"
                    >
                        <div class="Breadcrumbs">
                            <Breadcrumb />
                        </div>
                        <div class="Actions flex">
                            <HeaderList
                                v-model="filters"
                                :search="meta.search"
                            />
                        </div>
                    </div>
                </Transition>

                <div
                    class="Screen flex-1"
                    :class="{ isDesktop: !isMobile }"
                >
                    <Transition
                        name="main-router"
                        mode="out-in"
                    >
                        <RouterView :filters="filters" />
                    </Transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Breadcrumb, HeaderList, Sidebar } from '@/components'
import { mediaQueryMixin } from '@/mixins'

export default {
    name: 'Dashboard',
    components: {
        Breadcrumb,
        HeaderList,
        Sidebar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            tab: '',

            filters: {
                search: '',
                archived: false,
            },
        }
    },
    computed: {
        meta () {
            return this.$route?.meta
        },
        tabs () {
            return [
                {
                    id: 'organizations',
                    icon: 'airplay',
                    label: this.$t('navigation.organizations'),
                    screen: 'Organizations',
                },
                {
                    id: 'events',
                    icon: 'bar-chart',
                    label: this.$t('navigation.events'),
                    screen: 'Events',
                },
            ]
        },
    },
    mounted () {
        this.tab = this.getTabName()
    },
    updated () {
        this.tab = this.getTabName()
    },
    methods: {
        handleChangeTab (value) {
            const tab = this.tabs.find(({ id }) => id === value)
            if (tab && !tab.disabled) this.$router.push({ name: tab.screen }).catch((e) => {})
        },
        getTabName () {
            const { name } = this.$route
            return name.toLowerCase()
        },
    },
}
</script>
<style scoped lang="scss">
.Dashboard {
    & > .Container {
        min-height: calc(100vh);

        & > .Wrapper {

            & > .Header {
                position: sticky;
                top: 0;
                background: $white;
                z-index: 50;
                height: $subheader-height;
                padding: 0 25px;
                border-bottom: 1px solid $border-color;
                box-sizing: border-box;
            }
        }
    }

    .Screen {
        width: calc(100vw - #{$sidebar-min-width});
        padding: 25px;

        &.isDesktop {
            width: calc(100vw - #{$sidebar-width});
        }
    }

    .Breadcrumbs {
        position: fixed;
    }

    .Sidebar {
        position: sticky;
        height: 100vh;
        top: 0;
    }
}
</style>
