var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "APIError flex flex-center col-center",
      attrs: { id: "error-container" }
    },
    [
      _c("WIcon", {
        staticClass: "mb-1",
        attrs: { icon: "alert-circle", color: "red", size: 48 }
      }),
      _c("WText", { staticClass: "mb-3", attrs: { tag: "h3", size: 36 } }, [
        _vm._v(" " + _vm._s(_vm.$t("error.notfound.text")) + " ")
      ]),
      _c(
        "WButton",
        { staticClass: "pl-1 pr-1", on: { click: _vm.handleClickRetry } },
        [_vm._v(" " + _vm._s(_vm.$t("actions.retry")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }