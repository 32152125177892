<template>
    <div v-if="datasetInfo">
        <WCard
            bordered
            class="Personal mb-2"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('projects.datasets.settings.general.nameTitle') }}
            </WText>
            <div
                class="q-pa-md"
            >
                <div
                    class="row q-gutter-md items-center"
                >
                    <QInput
                        ref="nameField"
                        v-model="datasetName"
                        lazyRules
                        dense
                        outlined
                        maxlength="60"
                        :label="$t('projects.datasets.settings.general.nameField')"
                        :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('projects.datasets.general.updates.value') })]"
                        class="col"
                    >
                        <template v-slot:after>
                            <WButton
                                @click="handleSubmit"
                            >
                                {{ $t('projects.datasets.settings.general.updateName') }}
                            </WButton>
                        </template>
                    </QInput>
                </div>
            </div>
        </WCard>
        <WCard
            bordered
            class="Personal mb-2"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('projects.datasets.settings.general.generalTitle') }}
            </WText>
            <div class="row q-gutter-md">
                <div
                    class="column col-2"
                >
                    <div class="col">
                        {{ $t(`projects.datasets.settings.general.sourceType`) }}
                    </div>
                    <div class="col text-primary">
                        {{ datasetInfo.source }}
                    </div>
                </div>
                <div
                    v-if="datasetInfo.fileName"
                    class="column col-5"
                >
                    <div class="col">
                        {{ $t(`projects.datasets.settings.general.sourceName`) }}
                    </div>
                    <div class="col text-primary file-name">
                        {{ datasetInfo.fileName }}
                    </div>
                </div>
                <div
                    class="column col"
                >
                    <div class="col">
                        {{ $t(`projects.datasets.settings.general.creation`) }}
                    </div>
                    <div class="col text-primary">
                        {{ App.dateLocationFormat(datasetInfo.createDate, 'long') }}
                    </div>
                </div>
                <div
                    class="column col"
                >
                    <div class="col">
                        {{ $t(`projects.datasets.settings.general.lastUpdate`) }}
                    </div>
                    <div class="col text-primary">
                        {{ App.dateLocationFormat(datasetInfo.tst, 'long') }}
                    </div>
                </div>
            </div>
        </WCard>
        <WCard
            v-if="moreInfo"
            bordered
            class="Personal mb-2"
        >
            <WText
                :size="18"
                class="mb-1"
            >
                {{ $t('projects.datasets.settings.general.metricsTitle') }}
            </WText>
            <div class="row q-gutter-md">
                <div
                    v-for="(infoItem, index) in Object.keys(moreInfo)"
                    :key="`info-${index}`"
                    class="q-pa-xs col-xs-2 col-sm-2 col-md-2"
                >
                    <QCard>
                        <QCardSection class="text-left">
                            {{ $t(`projects.datasets.properties.${infoItem}`) }}
                        </QCardSection>
                        <QSeparator />
                        <QCardSection class="flex flex-center text-primary text-h4 bg-grey-2">
                            <div>{{ App.numberLocationFormat(moreInfo[infoItem]) }}</div>
                        </QCardSection>
                    </QCard>
                </div>
            </div>
        </WCard>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest } from '@/api'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'General',
    inject: ['App'],
    mixins: [validatorsMixin],
    props: {
        datasetInfo: VueTypes.any,
    },
    emits: ['UpdateDatasetName'],
    data () {
        return {
            moreInfo: null,
            moreInfoErrorMessage: null,
            datasetName: this.datasetInfo.name,
        }
    },
    watch: {
        datasetInfo: {
            handler () {
                this.fetchMoreInfo()
            },
            immediate: true,
        },
    },
    methods: {
        fetchMoreInfo () {
            apiRequest(Api().datasets.getInfo({ datasetId: this.datasetInfo.uuid }))
                .then((data) => {
                    const info = {}
                    Object.keys(data).forEach((k) => {
                        info[k] = this.numFormatter(data[k])
                    })
                    this.moreInfo = info
                    this.moreInfoErrorMessage = ''
                })
                .catch((errors = {}) => {
                // const { errorMessage } = errors.result.response.data
                    this.moreInfoErrorMessage = this.$t(`projects.datasets.errorMessage`)
                })
                .finally(() => {
                })
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
        handleSubmit () {
            this.$emit('UpdateDatasetName', this.datasetName)
        },
    },
}
</script>

<style scoped>

.file-name {
    -ms-word-break: break-all;
    word-break: break-all;
 /* Non standard for webkit */
     word-break: break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}
.submitButton {
    height: 40px;
}
</style>
