var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Tittle" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("span", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("booster.prediction.predictedPerformance.name")) +
              " "
          )
        ]),
        _c("QSelect", {
          attrs: { dense: "", outlined: "", options: _vm.performanceByOptions },
          model: {
            value: _vm.predictedPerformanceBy,
            callback: function($$v) {
              _vm.predictedPerformanceBy = $$v
            },
            expression: "predictedPerformanceBy"
          }
        }),
        _c("WButtonGroup", {
          attrs: {
            value: _vm.versusVision,
            options: _vm.groupVersusVisionOptions
          },
          on: { input: _vm.changeVersusVision }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }