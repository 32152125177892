import Transforms from './transforms'

const endpoints = {
    model: {
        method: 'POST',
        path: `/datasets/:processId/models`,
        transform: Transforms.model,
    },
    modelInfo: {
        method: 'POST',
        path: `/datasets/:processId/models/info`,
        transform: Transforms.modelInfo,
    },
    loops: {
        method: 'POST',
        path: `/datasets/:processId/loops`,
        transform: Transforms.loops,
    },
    dashboard: {
        method: 'POST',
        path: '/datasets/:processId/dashboards',
        transform: Transforms.dashboard,
    },
    filters: {
        path: '/datasets/filters',
        transform: [200],
    },
    activities: {
        method: 'POST',
        path: '/datasets/:processId/activities',
        transform: [200],
    },
}

export default {
    name: 'visualizations',
    endpoints,
}
