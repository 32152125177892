var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DataNavigator" }, [
    _c(
      "div",
      { staticClass: "ButtonsContainer" },
      [
        _c(
          "div",
          { staticClass: "WButtonGroupContainer" },
          [
            _c("QBtnToggle", {
              staticClass: "q-mt-sm border-toggle",
              attrs: {
                toggleColor: "primary",
                noCaps: "",
                unelevated: "",
                options: _vm.tabs
              },
              on: { input: _vm.handleChangeDashboard },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            })
          ],
          1
        ),
        _c(
          "WButton",
          {
            staticClass: "PowerBIButton",
            attrs: { outline: "" },
            on: { click: _vm.openModal }
          },
          [
            _c("QIcon", { attrs: { name: "stacked_bar_chart", size: "2em" } }),
            _vm._v(
              " " + _vm._s(_vm.$t("visualization.powerBIDialog.powerBI")) + " "
            )
          ],
          1
        ),
        _vm.modalVisible
          ? _c(
              "div",
              { ref: "modal", staticClass: "PowerBIContainer" },
              [
                _c("PowerBIConnector", {
                  staticClass: "PowerBIContent",
                  attrs: { processId: _vm.processId },
                  on: { closeModal: _vm.closeModal }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "WButton",
          {
            attrs: {
              outline: "",
              iconLeft: "download",
              disabled: _vm.isDownloading,
              loading: _vm.isDownloading
            },
            on: { click: _vm.handleDownload }
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("visualization.dashboard.download")) + " "
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "VARIANTS",
            expression: "tab === 'VARIANTS'"
          }
        ],
        staticClass: "ChartsContainer pb-1"
      },
      [
        _c(
          "div",
          {
            staticClass: "flex flex-nowrap",
            style: { "flex-direction": _vm.isDesktop ? "row" : "column" }
          },
          [
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.variantSortedByFrequency }
            }),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.variantSortedByDurationAVG }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex flex-nowrap",
            style: { "flex-direction": _vm.isDesktop ? "row" : "column" }
          },
          [
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.variantSortedByDurationMax }
            }),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.variantSortedByDurationMin }
            })
          ],
          1
        ),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.variantSortedByDurationSTD }
        }),
        _c("v-chart", {
          staticClass: "chart VariantsOver",
          attrs: { option: _vm.variantsOver }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "ACTIVITIES",
            expression: "tab === 'ACTIVITIES'"
          }
        ],
        staticClass: "ChartsContainer pb-1"
      },
      [
        _c(
          "div",
          {
            staticClass: "flex flex-nowrap",
            style: { "flex-direction": _vm.isDesktop ? "row" : "column" }
          },
          [
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.activitiesSortedByFrequency }
            }),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.activitiesSortedByDurationAVG }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flex flex-nowrap",
            style: { "flex-direction": _vm.isDesktop ? "row" : "column" }
          },
          [
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.activitiesSortedByDurationMax }
            }),
            _c("v-chart", {
              staticClass: "chart",
              attrs: { option: _vm.activitiesSortedByDurationMin }
            })
          ],
          1
        ),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.activitiesSortedByDurationSTD }
        })
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "TRANSITIONS",
            expression: "tab === 'TRANSITIONS'"
          }
        ],
        staticClass: "ChartsContainer Transitions pb-1"
      },
      [
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.transitionsSortedByFrequency }
        }),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.transitionsSortedByDurationAVG }
        }),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.transitionsSortedByDurationMax }
        }),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.transitionsSortedByDurationMin }
        }),
        _c("v-chart", {
          staticClass: "chart",
          attrs: { option: _vm.transitionsSortedByDurationSTD }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }