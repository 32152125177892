<template>
    <div>
        <WText
            class="q-mb-sm"
            :size="18"
        >
            {{ $t('visualization.alerts.definition.createScreen.newAlert') }}
        </WText>
        <WCard
            bordered
            class="q-mb-sm"
        >
            <div
                class="q-pa-md"
            >
                <div
                    class="row q-gutter-md items-center"
                >
                    <div class="col-sm-12">
                        <QInput
                            ref="name"
                            v-model="nameFld"
                            lazyRules
                            outlined
                            stackLabel
                            maxlength="100"
                            :label="$t('visualization.alerts.definition.name')"
                            :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.alerts.definition.name') })]"
                        />
                    </div>
                    <div class="col-sm-12">
                        <QField
                            :label="$t('visualization.alerts.definition.type')"
                            stackLabel
                            borderless
                        >
                            <template v-slot:control>
                                <QBtnToggle
                                    v-model="typeTgl"
                                    toggleColor="primary"
                                    unelevated
                                    noCaps
                                    :options="typeOptions"
                                    class="q-mt-sm"
                                    @input="setDefaultSeverity"
                                />
                            </template>
                        </QField>
                    </div>
                    <div class="col-sm-12">
                        <QField
                            :label="$t('visualization.alerts.definition.severity')"
                            stackLabel
                            borderless
                        >
                            <template v-slot:control>
                                <QBtnToggle
                                    v-model="severityTgl"
                                    :toggleColor="getSeverityColor(severityTgl)"
                                    toggleTextColor="black"
                                    push
                                    unelevated
                                    noCaps
                                    :options="typeTgl === DEFAULT_TYPE ? severityOptions: riskOptions"
                                    class="q-mt-sm"
                                />
                            </template>
                        </QField>
                    </div>
                    <div
                        v-if="typeTgl !== 'PREDICTION'"
                        class="col-sm-12"
                    >
                        <QSelect
                            ref="filters"
                            v-model="fSet"
                            :options="formattedFilterSets"
                            :label="$t('visualization.alerts.definition.filterSet')"
                            stackLabel
                            :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.alerts.definition.filterSet') })]"
                        />
                    </div>
                </div>
            </div>
        </WCard>
        <WCard
            bordered
            class="q-mb-md"
        >
            <QField
                :label="$t('visualization.alerts.definition.createScreen.sendEmail')"
                stackLabel
                borderless
            >
                <template v-slot:control>
                    <QBtnToggle
                        v-model="sendTgl"
                        toggleColor="primary"
                        unelevated
                        noCaps
                        :options="sendOptions"
                        class="q-mt-sm"
                    />
                </template>
            </QField>
            <Transition
                mode="out-in"
                appear
            >
                <div v-if="sendTgl === 'other'">
                    <QInput
                        ref="mail"
                        v-model="emailAdrs"
                        lazyRules
                        outlined
                        stackLabel
                        maxlength="100"
                        :label="$t('visualization.alerts.definition.createScreen.notifyTo')"
                        :rules="[value => validators.required(value) || $t('validation.required', { attribute: $t('visualization.alerts.definition.createScreen.notifyTo') })]"
                    />
                </div>
            </Transition>
        </WCard>
        <div class="flex q-gutter-md justify-end">
            <QBtn
                :label="$t('visualization.alerts.definition.createScreen.cancel')"
                color="primary"
                noCaps
                outline
                @click="cancel"
            />
            <QBtn
                :label="$t('visualization.alerts.definition.createScreen.addAlert')"
                color="primary"
                noCaps
                :disabled="!saveBtnStatus"
                @click="create"
            />
        </div>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'
import { notify } from '@/api'

const DEFAULT_SEVERITY = 'LOW'
const DEFAULT_SEND = 'user'
const DEFAULT_TYPE = 'STANDARD'

export default {
    name: 'NewAlert',
    emits: ['create', 'cancel'],
    mixins: [validatorsMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filterSets: VueTypes.array,
    },
    data () {
        return {
            DEFAULT_TYPE,
            nameFld: undefined,
            fSet: undefined,
            emailAdrs: null,
            severityTgl: DEFAULT_SEVERITY,
            typeTgl: DEFAULT_TYPE,
            sendTgl: DEFAULT_SEND,
            saveBtnStatus: true,
        }
    },
    computed: {
        severityOptions () {
            return [{
                value: 'LOW',
                label: this.$t('visualization.alerts.detected.severityRiskLabels.LOW'),
            }, {
                value: 'MEDIUM',
                label: this.$t('visualization.alerts.detected.severityRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('visualization.alerts.detected.severityRiskLabels.HIGH'),
            }]
        },
        riskOptions () {
            return [{
                value: 'MEDIUM',
                label: this.$t('visualization.alerts.detected.predictionRiskLabels.MEDIUM'),
            }, {
                value: 'HIGH',
                label: this.$t('visualization.alerts.detected.predictionRiskLabels.HIGH'),
            }]
        },
        typeOptions () {
            return [{
                value: 'STANDARD',
                label: this.$t('visualization.alerts.definition.types.STANDARD'),
            },
            {
                value: 'PREDICTION',
                label: this.$t('visualization.alerts.definition.types.PREDICTION'),
            }]
        },
        sendOptions () {
            return [{
                value: 'user',
                label: this.$t('visualization.alerts.definition.createScreen.user'),
            },
            {
                value: 'other',
                label: this.$t('visualization.alerts.definition.createScreen.other'),
            },
            {
                value: 'notSend',
                label: this.$t('visualization.alerts.definition.createScreen.notSend'),
            }]
        },
        formattedFilterSets () {
            return this.filterSets.map(fSet => ({ label: fSet.name, value: fSet.uuid }))
        },
    },
    methods: {
        getSeverityColor (severity) {
            const colors = {
                LOW: 'amber-11',
                MEDIUM: 'orange-11',
                HIGH: 'red-11',
            }
            return colors[severity]
        },
        setDefaultSeverity () {
            this.severityTgl = this.typeTgl === DEFAULT_TYPE ? 'LOW' : 'MEDIUM'
        },
        cancel () {
            this.$emit('cancel')
        },
        hasValidationErrors () {
            this.$refs.name.validate()
            let errorCheck
            if (this.typeTgl === 'STANDARD') {
                this.$refs.filters.validate()
                errorCheck = this.$refs.filters.hasError
            }
            errorCheck = this.$refs.name.hasError
            if (this.sendTgl === 'other') {
                if (this.$refs.mail) {
                    this.$refs.mail.validate()
                }
                errorCheck = errorCheck || this.$refs.mail.hasError
            }
            return errorCheck
        },
        create () {
            if (!this.hasValidationErrors()) {
                this.saveBtnStatus = false
                const data = {
                    name: this.nameFld,
                    filterSets: this.typeTgl === 'STANDARD' ? [this.fSet.label] : [],
                    severity: this.severityTgl,
                    riskSLA: this.typeTgl === 'PREDICTION' ? this.severityTgl : null,
                    type: this.typeTgl,
                    mailToCreatingUser: this.sendTgl === 'user',
                    mailToOtherUser: this.sendTgl === 'other',
                    mailToNotify: this.emailAdrs ? this.emailAdrs : '',
                }
                this.$emit('create', data)
            } else {
                notify({ message: this.$t('visualization.alerts.notifications.validateError') })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.toggle {
  border: 1px solid $primary
}
.AlertTypes {
        padding: 0;
}
</style>
