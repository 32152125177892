export default {
    data: [
        {
            duration: {
                avgCompliantDuration: 0,
                avgNonCompliantDuration: 1428400688,
                maxCompliantDuration: -1,
                maxNonCompliantDuration: 4818939520,
                minCompliantDuration: -1,
                minNonCompliantDuration: 7332,
                sdCompliantDuration: 0,
                sdNonCompliantDuration: 0,
            },
            summary: {
                averageFitness: 1,
                compliant: 1,
                compliantPercentage: 1,
                nonCompliant: 3361,
                nonCompliantPercentage: 1,
            },
            texts: {
                duration: 'The average cycle duration of the analyzed cases is 2.4 week(s). ',
                general: 'None of the cases comply with the provided model.',
            },
            title: 'asdasdasd',
            type: {
                description: 'STANDARD CONFORMANCE PROCESS REPORT',
                identifier: 'STANDARD_CONFORMANCE',
                name: 'STANDARD_CONFORMANCE',
            },
            uuid: 'e73c350b-1f71-4474-82e2-95492745cb3d',
        },
    ],
    total: 1,
}
