var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Tracking" },
    [
      !!_vm.step
        ? _c(
            "fragment",
            [
              _c(
                "WText",
                { staticClass: "mb-0-5", attrs: { weight: "semi", size: 18 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("wizard.preview.step")) +
                      " " +
                      _vm._s(_vm.step.num) +
                      "/" +
                      _vm._s(_vm.length) +
                      ": "
                  ),
                  _c("WText", { attrs: { tag: "span", size: 18 } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("wizard.preview.select")) +
                        ' "' +
                        _vm._s(_vm.step.label) +
                        '" ' +
                        _vm._s(_vm.$t("wizard.preview.column")) +
                        " "
                    )
                  ]),
                  _vm.step.optional
                    ? _c(
                        "WText",
                        { attrs: { tag: "span", color: "tertiary", size: 18 } },
                        [
                          _vm._v(
                            " (" +
                              _vm._s(_vm.$t("wizard.preview.optional")) +
                              ") "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("WText", [_vm._v(" " + _vm._s(_vm.step.tooltip) + " ")])
            ],
            1
          )
        : _c(
            "fragment",
            [
              _c(
                "WText",
                {
                  staticClass: "mb-0-5",
                  attrs: { size: 18, weight: "semi", color: "primary" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("wizard.preview.done.title")) + " "
                  )
                ]
              ),
              _c("WText", [
                _vm._v(" " + _vm._s(_vm.$t("wizard.preview.done.text")) + " ")
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }