var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "apexchart",
    _vm._b(
      { ref: "eventsChart", staticClass: "Chart" },
      "apexchart",
      _vm.chartConfig,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }