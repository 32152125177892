import Transforms from './transforms'

const endpoints = {
    list: {
        path: `/notifications`,
        transform: Transforms.list,
    },
    create: {
        method: 'POST',
        path: `/notifications/:notificationUid/seen`,
        transform: Transforms.create,
    },
}

export default {
    name: 'notifications',
    endpoints,
}
