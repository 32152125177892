const projects = [
    {
        createDate: '2020-05-21T10:18:39',
        deleteDate: '2020-05-21T10:18:39',
        tst: '2020-05-21T10:18:39',
        code: 'CD000001',
        description: 'Description 1',
        name: 'Project 1',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
]

export default {
    data: projects,
    total: projects.length,
}
