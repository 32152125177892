var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Project" },
    [
      _c(
        "div",
        { staticClass: "Container flex row-stretch flex-nowrap" },
        [
          _c("Sidebar", {
            attrs: { value: _vm.$route.name, tabs: _vm.tabs },
            on: { input: _vm.handleChangeTab }
          }),
          _c("div", { staticClass: "Wrapper" }, [
            _c("div", { staticClass: "Header flex row-stretch-center" }, [
              _c("div", { staticClass: "Breadcrumbs" }, [_c("Breadcrumb")], 1),
              _c(
                "div",
                { staticClass: "Actions flex" },
                [
                  _c("HeaderList", {
                    attrs: { disabled: _vm.isProjectArchived },
                    on: { input: _vm.handleChangeQuery },
                    model: {
                      value: _vm.filters,
                      callback: function($$v) {
                        _vm.filters = $$v
                      },
                      expression: "filters"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              {
                staticClass: "Screen pb-5",
                class: { isDesktop: !_vm.isMobile }
              },
              [
                _vm.projectId
                  ? _c(
                      "Transition",
                      { attrs: { name: "main-router", mode: "out-in" } },
                      [
                        _c("RouterView", {
                          attrs: {
                            projectId: _vm.projectId,
                            query: _vm.query,
                            filters: _vm.filters,
                            isProjectArchived: _vm.isProjectArchived
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "delPrDialog",
        attrs: {
          title: _vm.$t("projects.delete"),
          text: _vm.$t("projects.modals.delete.text", {
            name: (_vm.project || {}).name
          }),
          loading: _vm.isLoading
        },
        on: { onAccept: _vm.archiveProject }
      }),
      _c("ModalNameDesc", {
        ref: "editPrDialog",
        attrs: {
          action: _vm.$t("actions.save"),
          title: _vm.$t("projects.edit"),
          loading: _vm.isLoading
        },
        on: { onSubmit: _vm.updateProject }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }