<template>
    <!-- <div class="Container"> -->
    <div class="Tittle">
        <div class="row">
            <span>
                {{ $t('booster.prediction.predictedPerformance.name') }}
            </span>
            <QSelect
                v-model="predictedPerformanceBy"
                dense
                outlined
                :options="performanceByOptions"
            />
            <WButtonGroup
                :value="versusVision"
                :options="groupVersusVisionOptions"
                @input="changeVersusVision"
            />
        </div>
    </div>
    <!-- </div> -->
</template>
<script>

export default {
    name: 'PredictedPerformance',
    props: {},
    data () {
        return {
            predictedPerformanceBy: 'Team',
            performanceByOptions: [{
                label: 'Team',
                value: 'team',
            }],
            versusVision: null,
            groupVersusVisionOptions: [
                {
                    label: 'SLA',
                    value: 'sla',
                },
                {
                    label: 'Duration',
                    value: 'duration',
                },
            ],
        }
    },
    methods: {},
}
</script>
<style scoped lang="scss">
    .Container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    .row {
        display: flex;
        justify-content: space-around;
        align-content: flex-start;
        align-items: center;
        padding: 1vw;
        padding-top: 0;
        &::v-deep .q-field__control {
            height: 40px;
            .q-field__append {
                height: 25px;
            }
        }
    }
</style>
