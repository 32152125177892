<template>
    <QMenu
        :offset="[0,5]"
        @before-hide="notifyHidden"
    >
        <QCard
            class="q-ma-sm q-pa-sm"
            flat
        >
            <div class="column q-gutter-sm">
                <QInput
                    ref="costConfInput"
                    :value="avgCostConf"
                    :label="$t('visualization.loopMetrics.avgCostConf')"
                    type="number"
                    :rules="[
                        value => validators.minValue(value, minValue)|| $t('validation.minValue', { attribute: $t('visualization.loopMetrics.avgCostConf'), min: minValue }),
                        value => validators.maxValue(value, maxValue)|| $t('validation.maxValue', { attribute: $t('visualization.loopMetrics.avgCostConf'), max: maxValue })
                    ]"
                    @input="setCostConf"
                />
                <QSelect
                    :value="currency"
                    :label="$t('visualization.loopMetrics.currency')"
                    :options="currencyOptions"
                    @input="setCurrency"
                />
            </div>
        </QCard>
    </QMenu>
</template>
<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'VisualizationSettings',
    mixins: [validatorsMixin],
    emits: ['hiding', 'currency', 'cost'],
    props: {
        currency: VueTypes.object,
        currencyOptions: VueTypes.array,
        avgCostConf: VueTypes.number,
    },
    data () {
        return {
            minValue: 0,
            maxValue: 100000,
        }
    },
    computed: {
    },
    methods: {
        notifyHidden () {
            this.$emit('hiding')
        },
        setCurrency (value) {
            this.$emit('currency', value)
        },
        setCostConf (value) {
            this.$refs.costConfInput.validate(value)
            if (!this.$refs.costConfInput.hasError) {
                this.$emit('cost', value)
            }
        },
    },
}
</script>
<style>
</style>
