var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Tracking" },
    [
      !!_vm.step
        ? _c(
            "fragment",
            [
              _c(
                "WText",
                { staticClass: "mb-0-5", attrs: { weight: "semi", size: 18 } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("wizard.preview.relevantAttributesTitle")) +
                      " (" +
                      _vm._s(_vm.step.num) +
                      "/" +
                      _vm._s(_vm.length) +
                      "): "
                  )
                ]
              ),
              _c("WText", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("wizard.preview.relevantAttributesDescription")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        : _c(
            "fragment",
            [
              _c(
                "WText",
                {
                  staticClass: "mb-0-5",
                  attrs: { size: 18, weight: "semi", color: "primary" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("wizard.preview.done.title")) + " "
                  )
                ]
              ),
              _c("WText", [
                _vm._v(" " + _vm._s(_vm.$t("wizard.preview.done.text")) + " ")
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }