var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DatasetsList" },
    [
      _c("Table", {
        ref: "table",
        staticClass: "Table",
        attrs: {
          columns: _vm.cols,
          data: _vm.normalizeRows,
          loading: _vm.loading,
          total: _vm.total,
          hideHeader: _vm.hideHeader,
          rowKey: "uuid"
        },
        on: { "table-request": _vm.handleFetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "QTr",
                  {
                    class: {
                      "cursor-pointer":
                        row.uploaded && row.state.identifier === "FINISHED"
                    }
                  },
                  [
                    _c(
                      "QTd",
                      {
                        key: "name",
                        staticClass: "td-name",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToVisualization(row)
                          }
                        }
                      },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.name) + " ")
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("general.createdAt")) +
                              " " +
                              _vm._s(_vm._f("date")(row.createDate)) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "filename",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToVisualization(row)
                          }
                        }
                      },
                      [
                        _c(
                          "WText",
                          { staticClass: "ellipsis", attrs: { ellipsis: "" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.uploaded ? row.fileName : "-") +
                                " "
                            ),
                            row.uploaded
                              ? _c(
                                  "QTooltip",
                                  { attrs: { contentClass: "bg-secondary" } },
                                  [_vm._v(" " + _vm._s(row.fileName) + " ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "common" },
                      [
                        _c(
                          "fragment",
                          [
                            _vm.manageActionsOptions(row) ===
                            _vm.ACTION_TYPES.UPLOADED
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex row-left-center flex-nowrap"
                                  },
                                  _vm._l((row.options || {}).common, function(
                                    option,
                                    index
                                  ) {
                                    return _c(
                                      "WButton",
                                      {
                                        key: option.key,
                                        class: { "ml-0-5": index },
                                        attrs: { color: "link" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleEmmit(option, row)
                                          }
                                        }
                                      },
                                      [
                                        _c("WIcon", {
                                          attrs: {
                                            icon: option.icon,
                                            size: 24,
                                            color: "secondary"
                                          }
                                        }),
                                        _c(
                                          "QTooltip",
                                          {
                                            attrs: {
                                              contentClass: "bg-secondary"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(option.label) + " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : _vm.manageActionsOptions(row) ===
                                _vm.ACTION_TYPES.PROCESSING
                              ? _c("Badge", {
                                  attrs: {
                                    label: _vm.$t("general.processing"),
                                    color: "info"
                                  }
                                })
                              : _vm.ACTION_TYPES.WITHOUT_ACTION
                              ? _c(
                                  "WText",
                                  { attrs: { size: 12, color: "gray02" } },
                                  [_vm._v(" - ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "description",
                        staticClass: "td-desc",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToVisualization(row)
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(row.description || "-"))
                        ]),
                        row.description
                          ? _c(
                              "QTooltip",
                              {
                                attrs: {
                                  contentClass: "bg-secondary",
                                  maxWidth: "300px"
                                }
                              },
                              [_vm._v(" " + _vm._s(row.description) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      {
                        key: "tst",
                        staticClass: "td-tst",
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToVisualization(row)
                          }
                        }
                      },
                      [
                        row.tst
                          ? _c("span", [
                              _vm._v(_vm._s(_vm._f("date")(row.tst)))
                            ])
                          : _c("span", [_vm._v("-")])
                      ]
                    ),
                    _c(
                      "QTd",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleGoToVisualization(row)
                          }
                        }
                      },
                      [
                        _c(
                          "QLinearProgress",
                          {
                            attrs: {
                              size: "35px",
                              indeterminate: _vm.getIndeterminateProgressState(
                                row.state.identifier,
                                row.uploaded
                              ),
                              value: 1,
                              color: _vm.getProgressColor(
                                row.state.identifier,
                                row.uploaded
                              ),
                              rounded: "",
                              trackColor: "white"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "absolute-full flex flex-center" },
                              [
                                _c("QBadge", {
                                  attrs: {
                                    label: _vm.getBadgeLabel(
                                      row.state.identifier,
                                      row.uploaded
                                    ),
                                    color: _vm.getProgressColor(
                                      row.state.identifier,
                                      row.uploaded
                                    ),
                                    textColor: "white"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "dropdown", staticClass: "Actions align-right" },
                      [
                        _c("WIcon", {
                          attrs: {
                            icon: "more-vertical",
                            size: 24,
                            color: "primary",
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.setSelected(row, $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "EmptyList",
                  _vm._b(
                    {
                      staticClass: "mt-2 mb-2",
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "WButton",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    iconLeft: "plus-circle",
                                    disabled: _vm.archived
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("onNewItem")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projects.datasets.new")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    "EmptyList",
                    _vm.emptyMessage,
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.selected
        ? _c("Dropdown", {
            attrs: {
              opened: !!_vm.selected,
              options: (_vm.selected.options || {}).dropdown,
              coords: _vm.selected.coords
            },
            on: { onClose: _vm.cleanSelected, onClick: _vm.handleClickOption }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }