var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Preview" },
    [
      _c(
        "div",
        { staticClass: "flex row-stretch-top" },
        [
          _c("Tracking", {
            staticClass: "flex-1 mb-1",
            attrs: { step: _vm.step, length: (_vm.fields || []).length }
          })
        ],
        1
      ),
      _c("QTable", {
        staticClass: "table-list",
        attrs: {
          bordered: "",
          flat: "",
          hideBottom: "",
          columns: _vm.normalizeColumns,
          data: _vm.rows,
          loading: _vm.loading,
          pagination: _vm.pagination,
          rowKey: "index",
          separator: "cell"
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function(props) {
              return [
                _vm.selectable
                  ? _c(
                      "QTr",
                      { staticClass: "FakeRow", attrs: { props: props } },
                      _vm._l(props.cols, function(col) {
                        return _c(
                          "QTh",
                          { key: col.name, attrs: { props: props } },
                          [
                            col.checked || col.hovered
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "Field flex row-stretch-center",
                                    class: {
                                      optional: (
                                        _vm.fields[col.checkedIndex] ||
                                        _vm.fields[col.hoveredIndex] ||
                                        {}
                                      ).optional
                                    }
                                  },
                                  [
                                    _c(
                                      "WText",
                                      {
                                        attrs: {
                                          weight: "semi",
                                          tag: "span",
                                          color: "white",
                                          size: 14
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                _vm.fields[col.checkedIndex] ||
                                                _vm.fields[col.hoveredIndex] ||
                                                {}
                                              ).label
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c("WIcon", {
                                      attrs: {
                                        size: 18,
                                        icon: "x",
                                        color: "white",
                                        clickable: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          ) {
                                            return null
                                          }
                                          return _vm.handleClickRow(
                                            { col: col },
                                            false
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "QTr",
                  { attrs: { props: props } },
                  _vm._l(props.cols, function(col, index) {
                    return _c(
                      "QTh",
                      {
                        key: col.name,
                        staticClass: "Header",
                        class: {
                          hovered: _vm.hovered === col.field,
                          cursor: col.checked,
                          disabled:
                            (!col.checked && _vm.disabledChecked) ||
                            col.previouslyChecked
                        },
                        attrs: { props: props },
                        on: {
                          mouseover: function($event) {
                            return _vm.handeleRowMouseOver({ col: col }, $event)
                          },
                          mouseleave: function($event) {
                            return _vm.handleRowMouseEnter({ col: col }, $event)
                          },
                          click: function($event) {
                            if ($event.target !== $event.currentTarget) {
                              return null
                            }
                            return _vm.handleClickRow({ col: col }, $event)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(col.label) + " "),
                        _c("QSelect", {
                          staticClass:
                            "col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md",
                          attrs: {
                            value: col.type,
                            disable:
                              (!col.checked && _vm.disabledChecked) ||
                              col.previouslyChecked,
                            lazyRules: "",
                            dense: "",
                            outlined: "",
                            behavior: "menu",
                            options: _vm.typeOptions(col.type)
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateColumnType(col)
                            },
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          },
                          model: {
                            value: _vm.modifiedColumns[index].type,
                            callback: function($$v) {
                              _vm.$set(_vm.modifiedColumns[index], "type", $$v)
                            },
                            expression: "modifiedColumns[index].type"
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]
            }
          },
          {
            key: "body-cell",
            fn: function(props) {
              return [
                _c(
                  "QTd",
                  {
                    staticClass: "Item",
                    class: {
                      hovered: _vm.hovered === props.col.field,
                      cursor: props.col.checked,
                      disabled:
                        (!props.col.checked && _vm.disabledChecked) ||
                        props.col.previouslyChecked
                    },
                    attrs: { props: props },
                    on: {
                      mouseover: function($event) {
                        return _vm.handeleRowMouseOver(props, $event)
                      },
                      mouseleave: function($event) {
                        return _vm.handleRowMouseEnter(props, $event)
                      },
                      click: function($event) {
                        return _vm.handleClickRow(props, $event)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(props.value) + " ")]
                )
              ]
            }
          },
          {
            key: "bottom-row",
            fn: function(props) {
              return [
                _vm.selectable
                  ? _c(
                      "QTr",
                      { staticClass: "FakeRow", attrs: { props: props } },
                      [_c("QTd", [_vm._v(" ")])],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }