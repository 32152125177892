var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contextComparison pr-1 pl-1 pt-1" }, [
    _c(
      "div",
      { staticClass: "buttonContainer" },
      [
        _c(
          "WButton",
          {
            staticClass: "button",
            attrs: { color: "primary", iconLeft: "plus-circle" },
            on: { click: _vm.addSection }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("visualization.contextComparison.addContext")) +
                " "
            )
          ]
        ),
        _c(
          "WButton",
          {
            staticClass: "button",
            attrs: { color: "primary", iconLeft: "download" },
            on: { click: _vm.downloadCSV }
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.$t("visualization.contextComparison.downloadCSV")) +
                " "
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "cardsContainer" },
      [
        _vm.sections.length === 0
          ? _c("div", { staticClass: "noDataContainer" }, [
              _c("div", { staticClass: "noData" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.contextComparison.noData")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "noDataExplanation" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "visualization.contextComparison.noDataExplanation"
                      )
                    ) +
                    " "
                )
              ])
            ])
          : _vm._e(),
        _vm._l(_vm.sections, function(section) {
          return _c(
            "div",
            {
              key: section.sectionId,
              staticClass: "contextComparisonContainer"
            },
            [
              _c(
                "div",
                { staticClass: "row justify-between items-center" },
                [
                  _c(
                    "span",
                    { staticClass: "q-mt-sm q-ml-md text-weight-bold" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("visualization.contextComparison.comparison") +
                            " " +
                            section.sectionId
                        )
                      )
                    ]
                  ),
                  _c("WIcon", {
                    staticClass: "q-ma-sm",
                    attrs: {
                      clickable: "",
                      icon: "x-circle",
                      color: "primary",
                      size: 30
                    },
                    on: {
                      click: function($event) {
                        return _vm.removeSection(section)
                      }
                    }
                  })
                ],
                1
              ),
              _c("ContextComparisonCard", {
                staticClass: "cardSize",
                attrs: {
                  filteredInfo: _vm.filteredInfo,
                  sectionAssociated: section,
                  processId: _vm.processId
                },
                on: {
                  filterSelected: _vm.saveFilterSelected,
                  csvPosition: _vm.CSVList
                }
              })
            ],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }