const reportTypes = [
    {
        title: 'Model 1',
        description: 'Model description',
        extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        type: {
            name: 'Model 1',
            description: 'string',
            identifier: 'STANDARD_COMPLIANCE',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        title: 'Model 2',
        description: 'Model description',
        extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        type: {
            name: 'Model 2',
            description: 'string',
            identifier: 'STANDARD_COMPLIANCE',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        title: 'Model 3',
        description: 'Model description',
        extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        type: {
            name: 'Model 3',
            description: 'string',
            identifier: 'STANDARD_COMPLIANCE',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
]

export default reportTypes
