/* eslint-disable max-len */
export default {
    data: [
        {
            elements: {
                alternative: {
                    absoluteFrequency: 1203,
                    activities: [
                        {
                            name: 'START_PROCESS',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'Start_process',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'O_Create Offer',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'O_Created',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'O_Sent (mail and online)',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'O_Returned',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'O_Accepted',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'End_process',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                        {
                            name: 'END_PROCESS',
                            statistics: {
                                durationAvg: 0,
                                durationSd: 0,
                                frequency: 1203,
                            },
                        },
                    ],
                    arcs: [
                        {
                            name: 'START_PROCESS - Start_process',
                            source: 'START_PROCESS',
                            statistics: {
                                durationAvg: 1,
                                durationSd: 0,
                                frequency: 1203,
                            },
                            target: 'Start_process',
                        },
                        {
                            name: 'Start_process - O_Create Offer',
                            source: 'Start_process',
                            statistics: {
                                durationAvg: 1,
                                durationSd: 0,
                                frequency: 1203,
                            },
                            target: 'O_Create Offer',
                        },
                        {
                            name: 'O_Create Offer - O_Created',
                            source: 'O_Create Offer',
                            statistics: {
                                durationAvg: 648.6924355777223,
                                durationSd: 117.75303559070593,
                                frequency: 1203,
                            },
                            target: 'O_Created',
                        },
                        {
                            name: 'O_Created - O_Sent (mail and online)',
                            source: 'O_Created',
                            statistics: {
                                durationAvg: 1378633.9343308397,
                                durationSd: 16258336.232726254,
                                frequency: 1203,
                            },
                            target: 'O_Sent (mail and online)',
                        },
                        {
                            name: 'O_Sent (mail and online) - O_Returned',
                            source: 'O_Sent (mail and online)',
                            statistics: {
                                durationAvg: 807622104.8212801,
                                durationSd: 460916182.4002391,
                                frequency: 1203,
                            },
                            target: 'O_Returned',
                        },
                        {
                            name: 'O_Returned - O_Accepted',
                            source: 'O_Returned',
                            statistics: {
                                durationAvg: 408401703.6791355,
                                durationSd: 535485923.98085886,
                                frequency: 1203,
                            },
                            target: 'O_Accepted',
                        },
                        {
                            name: 'O_Accepted - End_process',
                            source: 'O_Accepted',
                            statistics: {
                                durationAvg: 1,
                                durationSd: 0,
                                frequency: 1203,
                            },
                            target: 'End_process',
                        },
                        {
                            name: 'End_process - END_PROCESS',
                            source: 'End_process',
                            statistics: {
                                durationAvg: 1,
                                durationSd: 0,
                                frequency: 1203,
                            },
                            target: 'END_PROCESS',
                        },
                    ],
                    duration: 5182717469,
                    endTime: 1485864918597,
                    relativeFrequency: 0.35792918774174354,
                    startTime: 1480682201128,
                    uuid: '1bada24f-b1cb-40cf-8322-430220a0d1d0',
                },
                duration: {
                    topFiveActivities: {
                        avg: {
                            data: [
                                0,
                                0,
                                0,
                                0,
                                0,
                            ],
                            names: [
                                'O_Create Offer',
                                'O_Created',
                                'O_Sent (mail and online)',
                                'O_Returned',
                                'O_Accepted',
                            ],
                        },
                        sd: {
                            data: [
                                0,
                                0,
                                0,
                                0,
                                0,
                            ],
                            names: [
                                'O_Create Offer',
                                'O_Created',
                                'O_Sent (mail and online)',
                                'O_Returned',
                                'O_Accepted',
                            ],
                        },
                    },
                    topFiveAlternatives: {
                        avg: {
                            data: [
                                2070495918.7435896,
                                1794486942.0129032,
                                1496225617.6,
                                1435883012.379747,
                                1251350777.3,
                            ],
                            names: [
                                'Variant 3',
                                'Variant 8',
                                'Variant 15',
                                'Variant 9',
                                'Variant 10',
                            ],
                        },
                        sd: {
                            data: [
                                1627594750.6687312,
                                1030018267.2566146,
                                945623429.0033765,
                                940876693.6278843,
                                881840371.6751661,
                            ],
                            names: [
                                'Variant 15',
                                'Variant 9',
                                'Variant 3',
                                'Variant 11',
                                'Variant 8',
                            ],
                        },
                    },
                    topFiveTransitions: {
                        avg: {
                            data: [
                                1435737420.987342,
                                1028873564.8354838,
                                2068640579.9257295,
                                989724170.2117647,
                                1046544103.5714285,
                            ],
                            names: [
                                'O_Sent (mail and online) - O_Cancelled',
                                'O_Sent (mail and online) - O_Refused',
                                'O_Sent (online only) - O_Refused',
                                'O_Returned - O_Cancelled',
                                'O_Sent (online only) - O_Cancelled',
                            ],
                        },
                        sd: {
                            data: [
                                1029941865.6271677,
                                952486400.8893139,
                                946644387.563855,
                                928325895.9622127,
                                821930803.5179516,
                            ],
                            names: [
                                'O_Sent (mail and online) - O_Refused',
                                'O_Returned - O_Cancelled',
                                'O_Sent (mail and online) - O_Cancelled',
                                'O_Sent (online only) - O_Cancelled',
                                'O_Sent (online only) - O_Refused',
                            ],
                        },
                    },
                },
                frequency: {
                    topFiveActivities: {
                        data: [
                            3361,
                            3361,
                            3012,
                            1835,
                            1467,
                        ],
                        names: [
                            'O_Create Offer',
                            'O_Created',
                            'O_Sent (mail and online)',
                            'O_Returned',
                            'O_Cancelled',
                        ],
                    },
                    topFiveAlternatives: {
                        data: [
                            1203,
                            1131,
                            300,
                            155,
                            127,
                        ],
                        names: [
                            'Variant 1',
                            'Variant 3',
                            'Variant 2',
                            'Variant 8',
                            'Variant 4',
                        ],
                    },
                },
                general: {
                    processAvgDuration: 1428400690.2975304,
                    processBehaviour: {
                        percentage: 0.6944361797084202,
                        variants: 2,
                    },
                },
            },
            text: {
                language: 'en',
                texts: {
                    duration: 'The process has an average duration of 2.4 week(s), with a deviation of +- 2.4 week(s). 1963 of the analyzed cases conform to this average. The remaining 1398 are composed of 498 cases that can be considered clearly shorter, and 900 clearly longer. The charts below show information about the process elements that are responsible for the longer execution times. The activities have no apparent registered duration, which means that they are instant or that either the starting or the ending timestamp is not available in the source data. In this case, it is strongly suggested to review the following transitions between activities, as they incur a high duration and/or deviation: O_Returned - O_Cancelled, O_Sent (mail and online) - O_Refused, O_Sent (online only) - O_Refused, O_Sent (online only) - O_Cancelled and O_Sent (mail and online) - O_Cancelled. The process alternatives Variant 9, Variant 3, Variant 8 and Variant 15 are especially relevant in terms of duration and/or deviation.',
                    frequency: 'Since activities do not have a registered duration, only frequency can be used to measure the importance of activities in terms of repetition throughout all the analyzed process cases. In particular, the most frequents activities are: O_Returned, O_Sent (mail and online), O_Cancelled, O_Created and O_Create Offer. Likewise, these process alternatives should be revised: Variant 3, Variant 8 and Variant 1.',
                    general: '69.44% of the events in the log correspond to 2 different alternative executions of the process. On average, the process completes in 2.4 week(s), with a duration that ranges from 7.3 second(s) to 8.0 week(s).',
                },
                type: 'discovery',
            },
            title: 'asdadasd',
            type: {
                description: 'STANDARD DISCOVERY PROCESS REPORT',
                identifier: 'STANDARD_DISCOVERY',
                name: 'STANDARD_DISCOVERY',
            },
            uuid: 'd50987e5-c0fe-4489-bd20-d7d67e992201',
        },
    ],
    total: 1,
}
