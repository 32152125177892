<template>
    <i
        :class="iconClass"
        :style="iconStyles"
        class="WIcon"
        @click="$emit('click', $event)"
    />
</template>

<script>
import VueTypes from 'vue-types'

const colors = ['', 'white', 'primary', 'secondary', 'dark', 'grayMedium', 'black', 'yellow', 'red', 'green', 'blue']
export default {
    name: 'WIcon',
    props: {
        icon: VueTypes.string.isRequired.def(''),
        color: VueTypes.oneOf(colors).def(''),
        size: VueTypes.number.def(0),
        clickable: VueTypes.bool.def(false),
    },
    data () {
        return {}
    },
    computed: {
        iconClass () {
            return [
                [
                    `wicon-${this.icon}`,
                    this.color,
                    this.clickable ? 'clickable' : '',
                ],
            ]
        },
        iconStyles () {
            const styles = {}
            if (this.size) styles.fontSize = `${this.size}px`
            return styles
        },
    },
}
</script>

<style scoped lang="scss">
.WIcon {
    vertical-align: middle;
    color: $body-color;
    transition: all .3s ease-in-out;

    &.white {
        color: $white;
    }
    &.primary {
        color: $primary;
    }
    &.secondary {
        color: $secondary;
    }
    &.black {
        color: $black;
    }
    &.dark {
        color: $gray-01;
    }
    &.grayMedium {
        color: $gray-medium;
	}
    &.yellow {
        color: $warning;
    }
	&.red {
		color: $danger;
    }
    &.green {
        color: $success;
    }
    &.blue {
        color: $info;
    }
    &.clickable {
        cursor: pointer;
        &:hover{
            opacity: 0.5;
        }
    }
}
</style>
