var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-center" }, [
    _c("div", { staticClass: "col-8 justify-center q-pa-lg" }, [
      _c("div", { staticClass: "row justify-center title" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("wizard.boosterWizard.performanceBooster.title")) +
            " "
        )
      ]),
      _c("div", { staticClass: "row justify-center title" }, [
        _c("h6", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("wizard.boosterWizard.performanceBooster.description")
              ) +
              " "
          )
        ])
      ]),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "row items-center q-mt-lg" },
            [
              _c("div", {
                staticClass: "square bg-green-3 border-green q-mx-md"
              }),
              _c("WText", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("wizard.boosterWizard.sla.compliance")) +
                    " "
                )
              ]),
              _c("div", { staticClass: "square bg-red-3 border-red q-mx-md" }),
              _c("WText", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("wizard.boosterWizard.sla.nonCompliance")) +
                    " "
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row q-my-md" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-green-3 border-green q-pa-lg text-center text-white text-weight-bolder",
            class:
              "col-" +
              (_vm.nonSlaCompliancePct === 0
                ? 12
                : _vm.getColWith(_vm.slaCompliancePct)) +
              "\n                " +
              (_vm.slaCompliancePct === 0 ? "hidden" : "")
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.slaComplianceAbs +
                    " " +
                    _vm.$t("wizard.boosterWizard.sla.cases") +
                    " (" +
                    _vm.slaCompliancePct +
                    "%)"
                ) +
                " "
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "bg-red-3 border-red q-pa-lg text-center text-white text-weight-bolder",
            class:
              "col-" +
              (_vm.slaCompliancePct === 0
                ? 12
                : 12 - _vm.getColWith(_vm.slaCompliancePct)) +
              "\n                " +
              (_vm.nonSlaCompliancePct === 0 ? "hidden" : "")
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.nonSlaComplianceAbs +
                    " " +
                    _vm.$t("wizard.boosterWizard.sla.cases") +
                    " (" +
                    _vm.nonSlaCompliancePct +
                    "%)"
                ) +
                " "
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "row justify-between" },
        [
          _c("WText", { staticClass: "mb-1", attrs: { color: "dark" } }, [
            _vm._v(" " + _vm._s("Min: " + _vm.min) + " ")
          ]),
          _c("WText", { staticClass: "mb-1", attrs: { color: "dark" } }, [
            _vm._v(" " + _vm._s("Max: " + _vm.max) + " ")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "WCard",
            {
              staticClass: "col q-pa-lg rounded-borders",
              attrs: { shadow: "" }
            },
            [
              _c(
                "WText",
                {
                  staticClass: "mb-1",
                  attrs: { weight: "bold", color: "dark" }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("wizard.boosterWizard.sla.name")) + " "
                  )
                ]
              ),
              _c("WText", { staticClass: "mb-1", attrs: { color: "dark" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("wizard.boosterWizard.sla.description")) +
                    " "
                )
              ]),
              _c(
                "div",
                { staticClass: "OptionsGroup row justify-around" },
                [
                  _c("QInput", {
                    staticClass: "col-5",
                    attrs: {
                      standout: "",
                      outlined: "",
                      type: "number",
                      labelColor: "dark",
                      color: "dark",
                      label: _vm.$t("visualization.settings.sla.number")
                    },
                    on: { input: _vm.updateSLA },
                    model: {
                      value: _vm.number,
                      callback: function($$v) {
                        _vm.number = $$v
                      },
                      expression: "number"
                    }
                  }),
                  _c("QSelect", {
                    staticClass: "col-5",
                    attrs: {
                      outlined: "",
                      labelColor: "dark",
                      color: "dark",
                      label: _vm.$t("visualization.settings.sla.unit"),
                      options: _vm.slaUnits
                    },
                    on: { input: _vm.updateSLA },
                    model: {
                      value: _vm.unit,
                      callback: function($$v) {
                        _vm.unit = $$v
                      },
                      expression: "unit"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row justify-between mt-1" },
        [
          _c(
            "WButton",
            {
              staticStyle: { "padding-inline": "40px" },
              attrs: { type: "submit", color: "primary" },
              on: { click: _vm.backBtn }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("wizard.boosterWizard.sla.backButton")) +
                  " "
              )
            ]
          ),
          _c(
            "WButton",
            {
              staticStyle: { "padding-inline": "40px" },
              attrs: { type: "submit", color: "primary" },
              on: { click: _vm.nextBtn }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("wizard.boosterWizard.sla.nextButton")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }