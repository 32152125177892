<!-- eslint-disable prefer-destructuring -->
<template>
    <div class="row justify-center">
        <div class="col-8 justify-center q-pa-lg">
            <div class="row justify-center title">
                {{ $t('wizard.boosterWizard.performanceBooster.title') }}
            </div>
            <div class="row justify-center title">
                <h6>
                    {{ $t('wizard.boosterWizard.performanceBooster.description') }}
                </h6>
            </div>
            <div
                v-if="loading"
                class="row items-center q-mt-lg"
            >
                <div class="square bg-green-3 border-green q-mx-md" />
                <WText>
                    {{ $t('wizard.boosterWizard.sla.compliance') }}
                </WText>
                <div class="square bg-red-3 border-red q-mx-md" />
                <WText>
                    {{ $t('wizard.boosterWizard.sla.nonCompliance') }}
                </WText>
            </div>
            <div class="row q-my-md">
                <div
                    :class="`col-${nonSlaCompliancePct === 0 ? 12 : getColWith(slaCompliancePct)}
                    ${slaCompliancePct === 0 ? 'hidden' : ''}`"
                    class="bg-green-3 border-green q-pa-lg text-center text-white text-weight-bolder"
                >
                    {{ `${slaComplianceAbs} ${$t('wizard.boosterWizard.sla.cases')} (${slaCompliancePct}%)` }}
                </div>
                <div
                    :class="`col-${slaCompliancePct === 0 ? 12 : 12-getColWith(slaCompliancePct)}
                    ${nonSlaCompliancePct === 0 ? 'hidden' : ''}`"
                    class="bg-red-3 border-red q-pa-lg text-center text-white text-weight-bolder"
                >
                    {{ `${nonSlaComplianceAbs} ${$t('wizard.boosterWizard.sla.cases')} (${nonSlaCompliancePct}%)` }}
                </div>
            </div>
            <div class="row justify-between">
                <WText
                    class="mb-1"
                    color="dark"
                >
                    {{ `Min: ${min}` }}
                </WText>
                <WText
                    class="mb-1"
                    color="dark"
                >
                    {{ `Max: ${max}` }}
                </WText>
            </div>
            <div class="row">
                <WCard
                    shadow
                    class="col q-pa-lg rounded-borders"
                >
                    <WText
                        class="mb-1"
                        weight="bold"
                        color="dark"
                    >
                        {{ $t('wizard.boosterWizard.sla.name') }}
                    </WText>
                    <WText
                        class="mb-1"
                        color="dark"
                    >
                        {{ $t('wizard.boosterWizard.sla.description') }}
                    </WText>
                    <div class="OptionsGroup row justify-around">
                        <QInput
                            v-model="number"
                            standout
                            outlined
                            class="col-5"
                            type="number"
                            labelColor="dark"
                            color="dark"
                            :label="$t('visualization.settings.sla.number')"
                            @input="updateSLA"
                        />
                        <QSelect
                            v-model="unit"
                            outlined
                            class="col-5"
                            labelColor="dark"
                            color="dark"
                            :label="$t('visualization.settings.sla.unit')"
                            :options="slaUnits"
                            @input="updateSLA"
                        />
                    </div>
                </WCard>
            </div>
            <div class="row justify-between mt-1">
                <WButton
                    type="submit"
                    color="primary"
                    style="padding-inline: 40px;"
                    @click="backBtn"
                >
                    {{ $t('wizard.boosterWizard.sla.backButton') }}
                </WButton>
                <WButton
                    type="submit"
                    color="primary"
                    style="padding-inline: 40px;"
                    @click="nextBtn"
                >
                    {{ $t('wizard.boosterWizard.sla.nextButton') }}
                </WButton>
            </div>
        </div>
    </div>
</template>
<script>
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'WizardSLA',
    data () {
        return {
            projectId: this.$route.params.projectId,
            processId: this.$route.params.processId,
            name: this.$route.params.name,
            tab: this.$route.params.tab,
            number: 0,
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            max: 0,
            min: 0,
            slaComplianceAbs: 0,
            slaCompliancePct: 0,
            nonSlaComplianceAbs: 0,
            nonSlaCompliancePct: 0,
            loading: true,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    mounted () {
        const params = { filters: [] }
        apiRequest(Api().visualizations.modelInfo({ processId: this.processId, params }))
            .then((data) => {
                const [num, unit] = this.formatDuration(data.duration.med)
                this.number = num
                this.unit.value = unit
                this.unit.label = this.$t(`visualization.settings.sla.units.${unit}`)
                const [maxNum, maxUnit] = this.formatDuration(data.duration.max)
                this.max = `${maxNum} ${this.$t(`visualization.settings.sla.units.${maxUnit}`)}`
                const [minNum, minUnit] = this.formatDuration(data.duration.min)
                this.min = `${minNum} ${this.$t(`visualization.settings.sla.units.${minUnit}`)}`
                this.updateSLA()
            })
            .catch(notifyError)
    },
    methods: {
        updateSLA () {
            const settingsToSave = {
                sla: {
                    unit: this.unit.value ? this.unit.value : this.unit,
                    value: this.number,
                },
            }
            apiRequest(Api().datasets.update({
                datasetId: this.processId,
                params: settingsToSave,
            }))
                .then(() => {
                    this.getSLAPercentages()
                })
                .catch(notifyError)
        },
        getSLAPercentages () {
            apiRequest(Api().datasets.overall({
                processId: this.processId,
                params: { attribute: null, value: null },
            })).then((data) => {
                this.slaComplianceAbs = data.kpis.slaComplianceAbsolute
                this.slaCompliancePct = data.kpis.slaCompliancePercentage
                this.nonSlaComplianceAbs = data.kpis.slaNonComplianceAbsolute
                this.nonSlaCompliancePct = data.kpis.slaNonCompliancePercentage
            })
        },
        getColWith (percentage) {
            let res = Math.round(percentage * 0.12)
            res = res > 11 ? 11 : res
            res = res < 1 ? 1 : res
            return res
        },
        formatDuration (duration) {
            if (duration < 3601) {
                return [Math.round(duration / 60), 'minutes']
            }
            if (duration < 180000) {
                return [Math.round(duration / 3600), 'hours']
            }
            if (duration < 604800) {
                return [Math.round(duration / 86400), 'days']
            }
            if (duration < 2419000) {
                return [Math.round(duration / 604800), 'weeks']
            }
            return [Math.round(duration / 2419000), 'months']
        },
        nextBtn () {
            this.$router.push({ name: 'WizardUpload', params: { projectId: this.projectId, processId: this.processId, name: this.name } })
        },
        backBtn () {
            this.$router.push({ name: 'WizardChooseApproach', params: { projectId: this.projectId, processId: this.processId } })
        },
    },
}
</script>
<style scoped lang="scss">
    .border-red {
        border: 4px solid red;
    }
    .border-green {
        border: 4px solid limegreen;
    }
    .border-grey {
        border: 2px solid grey;
    }
    .square {
        height: 1.5rem;
        aspect-ratio: 1;
    }
    .title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        margin-top: 4vh;
    }
</style>
