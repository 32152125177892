var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Sidebar" },
    [
      _c("SimpleContextualInfo", {
        staticClass: "pt-2",
        attrs: {
          totalInfo: _vm.infoTotal,
          filteredInfo: _vm.infoFiltered,
          totalFilters: _vm.totalFilters.length,
          readOnly: _vm.readOnly
        },
        on: { onApplyFilter: _vm.onApply }
      }),
      _c(
        "div",
        { staticClass: "FiltersContainer" },
        [
          _c(
            "WText",
            {
              staticClass: "FiltersTitle pt-1",
              attrs: { align: "center", weight: "bold", color: "primary" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("visualization.filters.total", {
                      total: (_vm.filterHiddenTotalFilters || []).length
                    })
                  ) +
                  " "
              )
            ]
          ),
          _c("div", { staticClass: "Actions mt-1" }, [
            _c("div", { staticClass: "flex row-center mb-1" }, [
              _c(
                "div",
                { staticClass: "Action" },
                [
                  !_vm.addMode
                    ? _c(
                        "WButton",
                        {
                          attrs: {
                            size: "sm",
                            iconRight: "filter",
                            iconSize: 10,
                            disabled: _vm.readOnly
                          },
                          on: { click: _vm.handleManageFilters }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("visualization.filters.manage")) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.addMode
                    ? _c(
                        "WButton",
                        {
                          attrs: {
                            size: "sm",
                            iconRight: "close",
                            iconSize: 10,
                            color: "warning"
                          },
                          on: { click: _vm.handleCloseModal }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("visualization.cancel")) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "Action" },
                [
                  _c(
                    "WButton",
                    {
                      attrs: {
                        size: "sm",
                        iconRight: "trash",
                        iconSize: 10,
                        disabled: _vm.readOnly
                      },
                      on: { click: _vm.handleResetFilters }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("visualization.filters.reset")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "Action" },
                [
                  _c(
                    "WButton",
                    {
                      attrs: {
                        size: "sm",
                        iconRight: "list",
                        iconSize: 10,
                        color: "primary",
                        disabled: _vm.readOnly
                      },
                      on: {
                        click: function($event) {
                          _vm.showing = !_vm.showing
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("visualization.filters.sets")) + " "
                      )
                    ]
                  ),
                  _vm.showing
                    ? _c(
                        "div",
                        { staticClass: "FiltersSetsModalContainer flex" },
                        [
                          !_vm.isLoading
                            ? _c(
                                "div",
                                { staticClass: "FilterSetsContainer" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "SetsModal" },
                                    [
                                      _c(
                                        "WText",
                                        {
                                          staticClass: "pb-1",
                                          attrs: {
                                            align: "center",
                                            weight: "bold"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "visualization.filters.filterSets.name"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c("QInput", {
                                        staticClass:
                                          "Search search-input float-left pb-1",
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          type: "search",
                                          label: _vm.$t(
                                            "visualization.filters.filterSets.search.placeholder"
                                          )
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleSearchVariant(
                                              _vm.search
                                            )
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _vm.search
                                                    ? _c("WIcon", {
                                                        attrs: {
                                                          id: "clean",
                                                          icon: "close",
                                                          size: 20,
                                                          clickable: ""
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.handleResetVariant
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c("WIcon", {
                                                    attrs: {
                                                      id: "search",
                                                      icon: "plus-circle",
                                                      size: 20,
                                                      color: "primary",
                                                      clickable: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleSearchVariant(
                                                          _vm.search
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2209276970
                                        ),
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      }),
                                      !_vm.isLoading && (_vm.sets || []).length
                                        ? _c(
                                            "div",
                                            { staticClass: "SetsContainer" },
                                            _vm._l(_vm.sets, function(set) {
                                              return _c(
                                                "div",
                                                {
                                                  key: set.uuid,
                                                  staticClass:
                                                    "RowSet flex row-stretch-center",
                                                  style: _vm.getSetStyles(
                                                    set.name
                                                  ),
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleClickSet(
                                                        set.name
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "WText",
                                                    {
                                                      attrs: { align: "center" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(set.name) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "flex" },
                                                    [
                                                      _c("WIcon", {
                                                        attrs: {
                                                          icon: "filter",
                                                          clickable: !!_vm.selectedSet,
                                                          color: "primary",
                                                          size: 16
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleSetFilters(
                                                              set.name
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("WIcon", {
                                                        attrs: {
                                                          icon: "trash",
                                                          color: "primary",
                                                          size: 16,
                                                          clickable: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleDeleteSet(
                                                              set.uuid
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            0
                                          )
                                        : _c("EmptyList", {
                                            staticClass: "mt-2 mb-2",
                                            attrs: {
                                              text: _vm.$t(
                                                "visualization.filters.filterSets.notifications.noResult.title"
                                              )
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.selectedSet
                            ? _c(
                                "div",
                                { staticClass: "FiltersContained" },
                                [
                                  _c(
                                    "WText",
                                    {
                                      staticClass: "pb-1",
                                      attrs: {
                                        align: "center",
                                        weight: "bold",
                                        color: "primary"
                                      }
                                    },
                                    [_vm._v(" Filters contained ")]
                                  ),
                                  (
                                    _vm.filterByName[_vm.selectedSet][0]
                                      .filters || []
                                  ).length
                                    ? _c(
                                        "div",
                                        { staticClass: "FiltersContainedList" },
                                        _vm._l(
                                          _vm.filterByName[_vm.selectedSet][0]
                                            .filters,
                                          function(filterSet, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "Filters flex"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "flex" },
                                                  [
                                                    _c("FilterIcon", {
                                                      attrs: {
                                                        id: _vm.getIconId(
                                                          filterSet
                                                        ),
                                                        height: 30,
                                                        width: 30
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "FilterLabel"
                                                      },
                                                      [
                                                        _c(
                                                          "QTooltip",
                                                          {
                                                            attrs: {
                                                              contentClass:
                                                                "bg-primary text-uppercase",
                                                              maxWidth: "350px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatLabelValue(
                                                                    filterSet
                                                                  ).tooltip
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "WText",
                                                          {
                                                            attrs: {
                                                              uppercase: "",
                                                              size: 12
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatLabelValue(
                                                                    filterSet
                                                                  ).label
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "WText",
                                                          {
                                                            attrs: {
                                                              size: 12,
                                                              weight: "bold"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatLabelValue(
                                                                    filterSet
                                                                  ).value
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _vm.formatLabelValue(
                                                          filterSet
                                                        ).additionalValue
                                                          ? _c(
                                                              "WText",
                                                              {
                                                                attrs: {
                                                                  size: 12,
                                                                  weight: "bold"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.formatLabelValue(
                                                                        filterSet
                                                                      )
                                                                        .additionalValue
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("EmptyList", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: {
                                          title: _vm.$t(
                                            "visualization.filters.filterSets.notifications.noResult.noFilters"
                                          )
                                        }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          (_vm.totalFilters || []).length
            ? _c(
                "div",
                { staticClass: "FilterList t-0-5" },
                _vm._l(_vm.totalFilters, function(filter, index) {
                  return _c(
                    "div",
                    {
                      key: filter.uuid + "-" + index,
                      staticClass: "Filters flex"
                    },
                    [
                      !filter.hidden
                        ? _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("FilterIcon", {
                                attrs: {
                                  id: _vm.getIconId(filter),
                                  height: 30,
                                  width: 30
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "FilterLabel" },
                                [
                                  _c(
                                    "QTooltip",
                                    {
                                      attrs: {
                                        contentClass:
                                          "bg-primary text-uppercase",
                                        maxWidth: "300px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatLabelValue(filter).tooltip
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "WText",
                                    {
                                      attrs: {
                                        uppercase: "",
                                        size: 12,
                                        color: _vm.getColor(filter)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatLabelValue(filter).label
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "WText",
                                    {
                                      attrs: {
                                        size: 12,
                                        weight: "bold",
                                        color: _vm.getColor(filter)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatLabelValue(filter).value
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.formatLabelValue(filter)
                                    .additionalValue &&
                                  _vm.formatLabelValue(filter)
                                    .additionalValue !== "null-null"
                                    ? _c(
                                        "WText",
                                        {
                                          attrs: {
                                            size: 12,
                                            weight: "bold",
                                            color: _vm.getColor(filter)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatLabelValue(filter)
                                                  .additionalValue
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      !filter.hidden
                        ? _c("WIcon", {
                            staticClass: "DeleteIcon",
                            attrs: {
                              icon: "trash-2",
                              size: 18,
                              clickable: !_vm.readOnly,
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteClick(filter)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                [
                  _c(
                    "WText",
                    {
                      staticClass: "Empty pt-1",
                      attrs: { size: 12, align: "center" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("visualization.filters.empty")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "delSetDialog",
        attrs: {
          title: _vm.$t("visualization.filters.filterSets.delete.title"),
          text: _vm.$t("visualization.filters.filterSets.delete.description")
        },
        on: { onAccept: _vm.deleteFilterSet }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }