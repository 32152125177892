/* eslint-disable max-len */
import i18n from '@/lang/i18n'

export const WIZARD_INITAL_STATE = {
    service: undefined,
    connection: {
        type: '',
        host: null,
        database: null,
        port: null,
        user: null,
        password: null,
        jdbc: null,
    },
    workflow: {
        workflows: undefined,
        traces: false,
        events: false,
    },
}

export const WIZAR_MOCKS = {
    connection: {
        host: '127.0.0.1',
        database: 'test',
        port: '3306',
        username: 'root',
        password: 'toor',
        driver: {
            className: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
            identifier: 'MSSQL',
            key: 'MSSQL',
            label: 'MSSQL (com.microsoft.sqlserver.jdbc.SQLServerDriver)',
        },
        connectionChain: 'jdbc:sqlserver://inverbisev.database.windows.net:1433;databaseName=EVO_DATA50005;user=easyvuser@inverbisev;password=Itil1234;encrypt=true;trustServerCertificate=false;hostNameInCertificate=*.database.windows.net;loginTimeout=30;',
        // connectionChain: 'jdbc:sqlserver://inverbisev.database.windows.net:1433;databaseName=EVO_DATA50005;user=50005@inverbisev;password=Prueba1234;encrypt=true;trustServerCertificate=false;hostNameInCertificate=*.database.windows.net;loginTimeout=30;',
        restService: 'http://13.95.144.96:9090/server/admin/public',
    },
    workflow: {
        workflows: [
            {
                key: 'w-1',
                label: 'Workflow 1',
                value: 'w-1',
            },
            {
                key: 'w-2',
                label: 'Workflow 2',
                value: 'w-2',
            },
        ],
        traces: true,
        events: false,
    },
    service: {
        allowJdbc: true,
        allowRest: true,
        identifier: 'SERVICENOW',
        name: 'ServiceNow',
        key: 'SERVICENOW',
        label: 'ServiceNow',
    },
}

export const config = options => ({
    title: i18n.t('wizard.configs.import.title'),
    step: 'service',
    steps: [
        {
            name: 'service',
            component: 'StepService',
            title: i18n.t('wizard.configs.import.steps.1'),
            icon: 'storage',
            mock: 'service',
            next: 'submitStep1',
        },
        {
            name: 'connection',
            component: 'StepConnection',
            title: i18n.t('wizard.configs.import.steps.2'),
            icon: 'vpn_key',
            mock: 'connection',
            prev: true,
            next: 'submitStep2',
        },
        {
            name: 'workflow',
            component: 'StepWorkflow',
            title: i18n.t('wizard.configs.import.steps.3'),
            icon: 'add_comment',
            mock: 'workflow',
            prev: 'prevStep3',
            next: 'submitStep3',
        },
        {
            name: 'summary',
            component: 'StepSummary',
            title: i18n.t('wizard.configs.import.steps.4'),
            icon: 'settings',
            prev: true,
            next: 'submitStep4',
            end: true,
        },
    ],
})

const Setup = options => ({
    config: config(options),
    initialState: WIZARD_INITAL_STATE,
    mocks: WIZAR_MOCKS,
})

export default Setup
