<template>
    <div
        v-if="visible"
        :class="spinnerClass"
        class="WSpinner"
    >
        <div class="dot1" />
        <div class="dot2" />
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'WSpinner',
    props: {
        color: VueTypes.oneOf(['primary', 'secondary', 'white', 'grayMedium']).def('primary'),
        noMargin: VueTypes.bool.def(false),
        small: VueTypes.bool.def(false),
        visible: VueTypes.bool.def(false),
    },
    computed: {
        spinnerClass () {
            return [
                this.color,
                {
                    small: this.small,
                    noMargin: this.noMargin,
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.WSpinner {
    position: relative;
    height: 40px;
    width: 40px;
    margin: 50px auto;
    text-align: center;
    &.noMargin {
        margin: 0;
    }
    &.small {
        height: 15px;
        width: 15px;
    }
    -webkit-animation: sk-rotate 2.0s infinite linear;
    animation: sk-rotate 2.0s infinite linear;

    .dot1, .dot2 {
        position: absolute;
        top: 0;
        display: inline-block;
        height: 60%;
        width: 60%;
        border-radius: 100%;
        background-color: $gray-01;

        -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
        animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .dot2 {
        top: auto;
        bottom: 0;
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    &.primary {
        .dot1, .dot2 {
            background-color: $primary;
        }
    }

    &.secondary {
        .dot1, .dot2 {
            background-color: $secondary;
        }
    }

    &.white {
        .dot1, .dot2 {
            background-color: $white;
        }
	}

	&.grayMedium {
		.dot1, .dot2 {
			background-color: $gray-medium;
		}
	}
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
</style>
