<template>
    <div class="Variants">
        <QExpansionItem
            defaultOpened
            dense
            denseToggle
            icon="alt_route"
            headerStyle="padding-bottom: 10px; padding-top: 8px; font-weight: 600;"
            :label="$t('visualization.variants')"
            style=" color: var(--q-color-primary) !important;"
        >
            <div class="flex flex-center q-my-sm">
                <QBtnToggle
                    v-model="variantTab"
                    toggleColor="primary"
                    noCaps
                    unelevated
                    :options="variantTabs"
                    class="q-mt-sm border-toggle"
                />
            </div>
            <QTabPanels
                v-model="variantTab"
                animated
                transitionNext="fade"
                transitionPrev="fade"
            >
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <QTabPanel name="FREQUENCY">
                    <div class="mt-1 q-mx-sm">
                        <div class="flex-row justify-between mb-1">
                            <div style="padding-top: 6px">
                                <WText>
                                    {{ $t('visualization.availableVariants') }}
                                </WText>
                            </div>
                            <div>
                                <WButton
                                    class="w-full"
                                    style="justify-content: center;"
                                    size="sm"
                                    color="secondary"
                                    iconLeft="filter"
                                    :iconSize="20"
                                    :disabled="isVariantFilterActive || disabledButton"
                                    :class="{ 'active-filter': isVariantFilterActive }"
                                    @click="toggleFrequencyVariantFilter"
                                />
                                <QTooltip
                                    class="Tooltip"
                                    maxWidth="500px"
                                    contentClass="text-primary"
                                    contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                    anchor="center right"
                                    self="center left"
                                >
                                    <WText
                                        color="primary"
                                    >
                                        {{ isVariantFilterActive ? $t('visualization.filterVariantsApplied') : $t('visualization.filterVariants') }}
                                    </WText>
                                </QTooltip>
                            </div>
                        </div>
                        <div class="Labels flex row-stretch-top cursor-pointer">
                            <QTooltip
                                anchor="center left"
                                self="center right"
                                :offset="[10, 10]"
                                contentClass="bg-primary"
                            >
                                {{ $t('visualization.editVariants') }}
                            </QTooltip>
                            <QChip
                                square
                                textColor="white"
                                color="primary"
                                outline
                            >
                                {{ minValue }}
                            </QChip>
                            <QChip
                                square
                                textColor="white"
                                :color="percentageColor"
                                class="text-weight-bold"
                            >
                                {{ `${formatNumber(sliderValue)} (${percentageValue}%)` }}
                            </QChip>
                            <QChip
                                square
                                textColor="white"
                                color="secondary"
                                outline
                            >
                                {{ formatNumber(maxValue) }}
                            </QChip>
                            <QPopupEdit :value="undefined">
                                <QInput
                                    ref="textinput"
                                    :value="sliderValue"
                                    type="number"
                                    :label="$t('visualization.modelLabel')"
                                    :max="maxValue"
                                    :min="minValue"
                                    :disable="isVariantsLoading || !minValue || disabledButton"
                                    :debounce="600"
                                    :rules="[
                                        value => validators.minValue(value, minValue)|| $t('validation.minValue', { attribute: $t('visualization.variants'), min: minValue }),
                                        value => validators.maxValue(value, maxValue)|| $t('validation.maxValue', { attribute: $t('visualization.variants'), max: maxValue }),
                                    ]"
                                    @input="handleChangeTextInput"
                                />
                            </QPopupEdit>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 q-pr-md">
                                <QSlider
                                    :value="sliderValue"
                                    class="q-ma-sm"
                                    :color="percentageColor"
                                    :labelValue="variantLabel"
                                    :min="minValue"
                                    :max="maxValue"
                                    :disable="isVariantsLoading || !minValue || disabledButton"
                                    @change="handleChangedModel"
                                    @input="handleChangeSliderValue"
                                />
                            </div>
                        </div>
                    </div>
                </QTabPanel>
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <!-- -----------------------------------  -->
                <QTabPanel name="INDIVIDUAL">
                    <div class="flex row-stretch-center flex-nowrap mt-1">
                        <WText class="pr-0-5">
                            {{ $t('visualization.variants') }}
                        </WText>
                        <QInput
                            v-model="variantSearch"
                            dense
                            outlined
                            type="search"
                            :disable="disabledButton"
                            :label="$t('actions.search')"
                            class="Search search-input float-left"
                            @keyup.enter="handleSearchVariant(variantSearch)"
                        >
                            <template v-slot:append>
                                <WIcon
                                    v-if="variantSearch"
                                    id="clean"
                                    icon="close"
                                    :size="20"
                                    clickable
                                    @click="handleResetVariantSearch"
                                />
                                <WIcon
                                    id="search"
                                    icon="search1"
                                    :size="20"
                                    clickable
                                    @click="handleSearchVariant(variantSearch)"
                                />
                            </template>
                        </QInput>
                    </div>
                    <div class="scroll">
                        <WLoading
                            v-if="isVariantsLoading"
                            visible
                        />
                        <template
                            v-for="(item) in variants"
                            v-else
                        >
                            <div
                                :key="item.id"
                                class="flex flex-row flex-nowrap justify-between"
                            >
                                <a
                                    href="#"
                                    class="Bar mt-0-5 w-full"
                                    style="max-width: 80%!important;"
                                    :disabled="disabledButton"
                                    :class="{ selected: item.id === activeVariant || selectedMultipleVariants.some(v => v.id === item.id) }"
                                    @click.prevent="handleChangeVariant(item)"
                                >
                                    <div
                                        class="flex row w-full"
                                        style="padding-bottom: 5px"
                                    >
                                        <div class="flex col-sm-8">
                                            <WText
                                                weight="semi"
                                                :size="12"
                                            >
                                                {{ item.name !== undefined ? item.name.replace('Variant', $t('visualization.variant')) : item.name }}
                                            </WText>
                                            <QBtn
                                                v-if="selectedMultipleVariants.some(v => v.id === item.id)"
                                                flat
                                                square
                                                icon="edit"
                                                size="10px"
                                                color="white"
                                                textColor="primary"
                                                class="q-mr-sm"
                                                padding="0px"
                                                :style="{ marginLeft: '5px'}"
                                                @click.stop="handleRenameVariant(item.id)"
                                            />
                                        </div>
                                        <div
                                            class="flex col-sm-4 items-end"
                                            :style="{ justifyContent: 'end' }"
                                        >
                                            <WText
                                                weight="semi"
                                                :size="12"
                                            >
                                                {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                            </WText>
                                        </div>
                                    </div>
                                    <QLinearProgress
                                        size="10px"
                                        :value="getAbsFrequency(item.absFrequency)"
                                        class="LinearProgress"
                                    />
                                    <QTooltip
                                        class="Tooltip"
                                        maxWidth="500px"
                                        contentClass="text-primary"
                                        contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                        anchor="center right"
                                        self="center left"
                                    >
                                        <WText
                                            color="primary"
                                        >
                                            <strong>
                                                {{ item.name !== undefined ? item.name.replace('Variant', $t('visualization.variant')) : item.name }}
                                            </strong>: {{ item.absFrequency }} {{ $t('visualization.cases') }} ({{ getAbsFrequency(item.absFrequency) | percentage }}%)
                                        </WText>
                                    </QTooltip>
                                </a>
                                <WButton
                                    v-if="!checkVariantIsSelected(item.id)"
                                    color="secondary"
                                    class="ml-1 self-end fit-content"
                                    iconRight="plus"
                                    :iconSize="12"
                                    @click="handleAddMultipleVariant(item)"
                                />
                                <WButton
                                    v-if="checkVariantIsSelected(item.id)"
                                    color="secondary"
                                    class="ml-1 self-end fit-content"
                                    iconRight="minus"
                                    :iconSize="12"
                                    @click="handleDeleteMultipleVariant(item)"
                                />
                            </div>
                        </template>

                        <div
                            v-if="!isVariantsLoading && !showVariants"
                            class="flex col-center mt-2"
                        >
                            <Badge
                                icon="alert-triangle"
                                :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.variants') })"
                                color="warning"
                                textColor="black"
                                class="row-center pt-0-5 pb-0-5 mb-0-5"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showVariants"
                        class="flex flex-col row-center pt-1"
                    >
                        <QPagination
                            v-model="variantPagination.start"
                            :max="variantPagination.max"
                            :directionLinks="true"
                            :disabled="disabledButton"
                            :maxPages="4"
                            size="10px"
                            :boundaryNumbers="true"
                            @input="handleChangeVariantsWithPagination"
                        />
                        <div class="mt-1 flex-row self-end">
                            <div>
                                <WButton
                                    class="w-full"
                                    style="justify-content: center;"
                                    size="sm"
                                    color="secondary"
                                    iconLeft="filter"
                                    :iconSize="20"
                                    :disabled="isVariantFilterActive || disabledButton"
                                    :class="{ 'active-filter': isVariantFilterActive }"
                                    @click="toggleIndividualVariantFilter"
                                />
                                <QTooltip
                                    class="Tooltip"
                                    maxWidth="500px"
                                    contentClass="text-primary"
                                    contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                    anchor="center right"
                                    self="center left"
                                >
                                    <WText
                                        color="primary"
                                    >
                                        {{ isVariantFilterActive ? $t('visualization.filterVariantsApplied') : $t('visualization.filterVariants') }}
                                    </WText>
                                </QTooltip>
                            </div>
                            <div>
                                <WButton
                                    class="w-full"
                                    style="justify-content: center;"
                                    size="sm"
                                    color="secondary"
                                    @click="handleResetMultipleVariants"
                                >
                                    <QIcon
                                        name="history"
                                        size="24px"
                                    />
                                </WButton>
                                <QTooltip
                                    class="Tooltip"
                                    maxWidth="500px"
                                    contentClass="text-primary"
                                    contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                    anchor="center right"
                                    self="center left"
                                >
                                    <WText
                                        color="primary"
                                    >
                                        {{ $t('visualization.resetMultipleVariants') }}
                                    </WText>
                                </QTooltip>
                            </div>
                        </div>
                        <div
                            class="flex flex-row my-1"
                        >
                            <template
                                v-for="(multipleVariant, index) in selectedMultipleVariants"
                            >
                                <div
                                    :key="`multipleVariant.name-${index}`"
                                    style="max-width:200px;"
                                >
                                    <QChip
                                        removable
                                        dense
                                        size="md"
                                        color="primary"
                                        textColor="white"
                                        @remove="handleDeleteMultipleVariant(multipleVariant)"
                                    >
                                        <div
                                            class="ellipsis"
                                        >
                                            {{ selectedMultipleVariants.find(v => v.id === multipleVariant.id).name }}
                                        </div>
                                    </QChip>
                                </div>
                            </template>
                        </div>
                    </div>
                </QTabPanel>
            </QTabPanels>
        </QExpansionItem>
        <Divider />
        <QExpansionItem
            ref="loopExpansionItem"
            dense
            denseToggle
            icon="360"
            headerStyle="padding-bottom: 10px; padding-top: 10px; font-weight: 600;"
            :label="$t('visualization.nLoops')"
            style=" color: var(--q-color-primary) !important;"
        >
            <div class="flex row-stretch-center flex-nowrap mt-1">
                <WText class="pr-0-5">
                    {{ $t('visualization.nLoops') }}
                </WText>
                <QInput
                    v-model="loopSearch"
                    dense
                    outlined
                    type="search"
                    :label="$t('actions.search')"
                    :disable="disabledButton"
                    class="Search search-input float-left"
                    @keyup.enter="handleSearchLoops(loopSearch)"
                >
                    <template v-slot:append>
                        <WIcon
                            v-if="loopSearch"
                            id="clean"
                            icon="close"
                            :size="20"
                            clickable
                            @click="handleResetLoopSearch"
                        />
                        <WIcon
                            id="search"
                            icon="search1"
                            :size="20"
                            clickable
                            @click="handleSearchLoops(loopSearch)"
                        />
                    </template>
                </QInput>
            </div>
            <Tabs
                v-model="loopTab"
                class="Tabs"
                :tabs="loopTabs"
                horizontal
                @input="handleChangeLoopTab"
            />
            <QTabPanels v-model="loopTab">
                <QTabPanel
                    class="model-tab-panel"
                    :name="loopTab"
                >
                    <div class="scroll">
                        <WLoading
                            v-if="isLoopsLoading"
                            visible
                        />
                        <div v-show="showLoops">
                            <a
                                v-for="(item) in loops"
                                :key="item.id"
                                :disabled="disabledButton"
                                href="#"
                                class="Bar mt-0-5"
                                :class="{ selected: item.id === activeLoopId }"
                                @click.prevent="handleChangeLoop(item.id)"
                            >
                                <div
                                    class="flex row"
                                    style="padding-bottom: 5px"
                                >
                                    <div class="flex col-sm-9">
                                        <WText
                                            weight="semi"
                                            :size="12"
                                        >
                                            {{ item.name !== undefined ? item.name.replace('Loop', $t('visualization.loop')) : item.name }}
                                        </WText>
                                        <QBtn
                                            v-if="loopVisible && item.id === activeLoopId"
                                            flat
                                            square
                                            icon="visibility_off"
                                            size="10px"
                                            color="white"
                                            textColor="primary"
                                            class="q-mr-sm"
                                            padding="0px"
                                            :style="{ marginLeft: '8px'}"
                                            :disable="item.id !== activeLoopId"
                                            @click.stop="handleHideLoop()"
                                        />
                                        <QBtn
                                            v-if="!loopVisible && item.id === activeLoopId"
                                            flat
                                            square
                                            icon="visibility"
                                            size="10px"
                                            color="white"
                                            textColor="primary"
                                            class="q-mr-sm"
                                            padding="0px"
                                            :style="{ marginLeft: '8px'}"
                                            :disable="item.id !== activeLoopId"
                                            @click.stop="handleShowLoop()"
                                        />
                                    </div>
                                    <div
                                        class="flex col-sm-3 items-end"
                                        :style="{ justifyContent: 'end' }"
                                    >
                                        <WText
                                            weight="semi"
                                            :size="12"
                                        >
                                            {{ App.numberLocationFormat(getAbsFrequency(item.absFrequency)*100) }}%
                                        </WText>
                                    </div>
                                </div>
                                <QLinearProgress
                                    size="10px"
                                    :value="getAbsFrequency(item.absFrequency)"
                                    class="LinearProgress"
                                />
                                <QTooltip
                                    class="Tooltip"
                                    maxWidth="500px"
                                    contentClass="text-primary"
                                    contentStyle="font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;"
                                    anchor="center right"
                                    self="center left"
                                >
                                    <WText
                                        color="primary"
                                    >
                                        <strong>
                                            {{ item.name !== undefined ? item.name.replace('Loop', $t('visualization.loop')) : item.name }}
                                        </strong>: {{ $t('visualization.loopsFound') }} {{ item.absFrequency }} {{ $t('visualization.cases') }}
                                        ({{ getAbsFrequency(item.absFrequency) | percentage }}%)
                                    </WText>
                                </QTooltip>
                            </a>
                        </div>
                        <div
                            v-if="!showLoops"
                            class="flex col-center mt-2 mb-1"
                        >
                            <Badge
                                icon="alert-triangle"
                                :label="$t('visualization.notifications.notFound.text', { type: $t('visualization.loops') })"
                                color="warning"
                                textColor="black"
                                class="row-center pt-0-5 pb-0-5 mb-0-5"
                            />
                        </div>
                    </div>
                    <div
                        v-if="showLoops"
                        class="flex row-center pt-1 mb-1"
                    >
                        <QPagination
                            v-model="loopPagination.start"
                            :max="loopPagination.max"
                            :directionLinks="true"
                            :maxPages="4"
                            :disable="disabledButton"
                            size="11.5px"
                            :boundaryNumbers="true"
                            @input="handleChangeLoopPagination"
                        />
                    </div>
                </QTabPanel>
            </QTabPanels>
        </QExpansionItem>
        <Divider />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { Badge, Tabs, Divider } from '@/components'
import { filtersStorageMixin, filtersMixin, validatorsMixin } from '@/mixins'

const MIN_VALUE = 1
const INITIAL_VARIANTS_PAGINATION = { start: 0, limit: 8 }
const INITIAL_LOOPS_PAGINATION = { start: 0, limit: 8 }
const DEFAULT_VARIANT_TAB = 'FREQUENCY'
const TABS = {
    LOOPS: 'loops',
    SELF_LOOPS: 'self-loops',
}

export default {
    components: {
        Badge,
        Tabs,
        Divider,
    },
    inject: ['App'],
    mixins: [filtersStorageMixin, filtersMixin, validatorsMixin],
    props: {
        value: VueTypes.shape({
            heatMapValue: VueTypes.string,
            values: VueTypes.string,
            variant: VueTypes.number,
        }).loose,
        info: VueTypes.object,
        filteredInfo: VueTypes.object,
        percentageMode: VueTypes.string,
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        disabledButton: VueTypes.bool.def(false),
    },
    data () {
        return {
            variantSearch: '',
            loopSearch: '',
            activeVariantId: undefined,
            activeVariant: undefined,
            selectedMultipleVariants: [],
            activeLoopId: undefined,
            activeLoop: undefined,
            selectedKpi: undefined,
            variants: undefined,
            loops: undefined,
            loopPagination: {
                start: 1,
                max: INITIAL_LOOPS_PAGINATION.limit,
            },
            variantPagination: {
                start: 1,
                max: INITIAL_VARIANTS_PAGINATION.limit,
            },
            isVariantsLoading: undefined,
            isLoopsLoading: undefined,
            isFetching: undefined,
            isVariantFilterActive: false,
            loopTab: TABS.LOOPS,
            variantTab: DEFAULT_VARIANT_TAB,
            loopVisible: true,
            sliderValue: (this.value || {}).variant || 1,
            percentageValue: Math.round((this.value || {}).variant / this.maxValue * 100) || 1,
        }
    },
    computed: {
        showVariants () {
            return !this.isVariantsLoading && (this.variants || []).length
        },
        showLoops () {
            return !this.isLoopsLoading && (this.loops || []).length
        },
        loopTabs () {
            return [
                { id: TABS.LOOPS, label: this.$t('visualization.nLoops') },
                { id: TABS.SELF_LOOPS, label: this.$t('visualization.selfLoops') },
            ]
        },
        variantTabs () {
            return [{
                value: 'FREQUENCY',
                label: this.$t('visualization.aggregate'),
            },
            {
                value: 'INDIVIDUAL',
                label: this.$t('visualization.individual'),
            }]
        },
        maxValue () {
            const { variants } = this.filteredInfo ? this.filteredInfo : this.info
            return variants || 1
        },
        variantLabel () {
            return `${this.sliderValue} (${this.percentageValue}%)`
        },
        arrayMarkerLabel () {
            return [
                { value: this.minValue, label: this.minValue },
                { value: this.maxValue, label: this.maxValue },
            ]
        },
        percentageColor () {
            if (this.percentageValue >= 75) {
                return 'secondary'
            }
            return 'primary'
        },
    },
    watch: {
        isFetching: 'emitVariantsLoading',
        isVariantsLoading: 'emitVariantsLoading',
        value (val) {
            this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
        },
        maxValue: {
            handler () {
                this.minValue = this.maxValue === 1 ? 0 : MIN_VALUE
                this.handleChangeSliderValue((this.value || {}).variant || 1)
            },
            immediate: true,
        },
        variantTab (selectedVariantTab) {
            this.activeVariant = undefined
            this.selectedMultipleVariants = []
            const { loopExpansionItem } = this.$refs
            if (loopExpansionItem && loopExpansionItem.showing) {
                loopExpansionItem.toggle()
            }
            if (selectedVariantTab === 'FREQUENCY') {
                this.handleChangedModel(this.minValue)
                this.$emit('onChangedVariantTab', 'FREQUENCY')
            } else if (selectedVariantTab === 'INDIVIDUAL') {
                this.retryGetVariants()
                this.$emit('onChangedVariantTab', 'INDIVIDUAL')
            }
        },
    },
    beforeMount () {
        this.minValue = MIN_VALUE
    },
    async mounted () {
        this.checkForVariantFilter()
        const { variant } = this.value
        if (this.processId) {
            if (this.variantTab === 'FREQUENCY') {
                await this.fetchModel(variant)
            } else if (this.variantTab === 'INDIVIDUAL') {
                await this.getVariants()
            }
        }
    },
    methods: {
        checkForVariantFilter () {
            this.$root.$emit('checkForVariantFilter')
            this.$root.$on('hasVariantFilter', (variantFilter) => {
                this.isVariantFilterActive = variantFilter
            })
        },
        toggleIndividualVariantFilter () {
            if (!this.isVariantFilterActive) {
                this.filterByIndividualVariant()
                this.selectedMultipleVariants = []
                this.getVariants()
            }
        },
        filterByIndividualVariant () {
            this.$root.$emit('filterByVariant', this.selectedMultipleVariants.map(v => v.id))
        },
        handleChangeVariantsWithPagination (start) {
            this.refreshVariantsList({ start: start - 1, search: this.variantSearch })
        },
        handleResetVariantSearch () {
            this.variantSearch = ''
            this.variantPagination.start = 1
            this.getVariants({ search: this.variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        handleSearchVariant (variantSearch) {
            this.variantPagination.start = 1
            this.getVariants({ search: variantSearch, start: INITIAL_VARIANTS_PAGINATION.start })
        },
        retryGetVariants () {
            this.variantSearch = ''
            this.getVariants()
        },
        handleChangeVariantPagination (variant, oldVariants) {
            if (this.selectedMultipleVariants.length === 1 && this.selectedMultipleVariants.some(v => v.id === oldVariants[0]?.id)) {
                this.activeVariant = variant
                this.activeVariantId = variant.id
                this.selectedMultipleVariants.pop()
                this.selectedMultipleVariants.push(variant)
            } else if (this.selectedMultipleVariants.length === 0) {
                this.activeVariant = variant
                this.activeVariantId = variant.id
                this.selectedMultipleVariants.push(variant)
            }
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        handleChangeVariant (variant) {
            this.activeVariantId = variant.id
            this.selectedMultipleVariants.pop()
            this.selectedMultipleVariants.indexOf(variant) === -1 &&
                this.selectedMultipleVariants.push(variant)
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        handleAddMultipleVariant (variant) {
            this.activeVariantId = variant.id
            this.selectedMultipleVariants.push(variant)
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        handleDeleteMultipleVariant (variant) {
            if (this.selectedMultipleVariants.length > 1) {
                this.selectedMultipleVariants = this.selectedMultipleVariants.filter(v => v.id !== variant.id)
                this.activeVariantId = this.selectedMultipleVariants.find(v => true).id
                this.getMultipleVariants(this.selectedMultipleVariants)
            }
        },
        getAbsFrequency (absFrequency) {
            const { cases } = this.percentageMode === 'TOTAL' ? this.info : this.filteredInfo
            return cases ? absFrequency / cases : 0
        },
        emitVariantsLoading () {
            if (!this.isFetching && !this.isVariantsLoading) {
                this.$emit('variantsLoading', false)
            } else {
                this.$emit('variantsLoading', true)
            }
        },
        normalizeVariantPaginationMax (value) {
            return Math.ceil(value / INITIAL_VARIANTS_PAGINATION.limit)
        },
        normalizeVariantParams (param) {
            const filters = this.formatFiltersParam(this.visualizationFilters)
            return {
                filters,
                ...INITIAL_VARIANTS_PAGINATION,
                ...(param || {}),
            }
        },
        normalizeParamsMultipleVariants (param) {
            const filters = { filters: this.formatFiltersParam(this.visualizationFilters) }
            return {
                filters,
                ...INITIAL_VARIANTS_PAGINATION,
                ...(param || {}),
            }
        },
        async getVariants (param) {
            this.isVariantsLoading = true
            this.emitVariantsLoading(true)
            const params = this.normalizeVariantParams(param)
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    if (data.length === 0) {
                        this.$emit('onLoadChart', { variant: [], variantId: [this.processId] })
                    }
                    const oldVariants = this.variants
                    const [first] = data
                    this.variants = data
                    this.variantPagination.max = this.normalizeVariantPaginationMax(total)
                    if (first) this.handleChangeVariantPagination(first, oldVariants)
                })
                .catch(notifyError)
                .finally(() => {
                    this.isVariantsLoading = false
                })
        },
        async getMultipleVariants (variants) {
            const variantsIds = variants.map(v => v.id)
            const { processId } = this
            this.isFetching = true
            const params = this.normalizeParamsMultipleVariants({
                variantsUids: variantsIds,
            })
            apiRequest(Api().visualizations.multipleVariants({ processId, params }))
                .then(({ data }) => {
                    const [variant] = data || []
                    this.$emit('onLoadChart', { ...variant, variantId: this.selectedMultipleVariants.map(v => v.id) })
                    this.getLoops()
                    this.activeVariant = variant
                    this.activeLoopId = undefined
                })
                .catch(notifyError)
                .finally(() => {
                    this.isFetching = false
                })
        },
        handleRenameVariant (variantId) {
            this.$emit('onOpenRenameModal', { variantId })
        },
        checkVariantIsSelected (variantId) {
            return this.selectedMultipleVariants.some(v => v.id === variantId)
        },
        handleResetMultipleVariants () {
            this.selectedMultipleVariants = this.selectedMultipleVariants.filter(v => v.id === this.activeVariantId)
            this.getMultipleVariants(this.selectedMultipleVariants)
        },
        async renameVariant ({ newVariantName, variantRenamedId }) {
            this.isVariantsLoading = true
            const { processId } = this
            const variantId = this.selectedMultipleVariants.find(v => v.id === variantRenamedId).id
            const params = { name: newVariantName }
            apiRequest(Api().visualizations.renameVariant({ processId, variantId, params }))
                .then(() => {
                    this.refreshVariantsList({ start: this.variantPagination.start - 1, search: this.variantSearch }).then(() => {
                        notifySuccess(this.$t('visualization.variantRenameOK'))
                    })
                    this.$root.$emit('refreshVariantsNamesAtSideBar')
                })
                .catch(notifyError)
                .finally(() => (this.isVariantsLoading = false))
        },
        async refreshVariantsList (param) {
            const params = this.normalizeVariantParams(param)
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data
                    this.selectedMultipleVariants = this.selectedMultipleVariants.map((variant) => {
                        const name = this.variants.find(v => variant.id === v.id)?.name
                        return { ...variant, name: name || variant.name }
                    })
                    this.variantPagination.max = this.normalizeVariantPaginationMax(total)
                })
                .catch(notifyError)
                .finally(() => (this.isVariantsLoading = false))
        },
        /* ***************************  */
        /*            LOOPS             */
        /* ***************************  */
        handleSearchLoops (loopSearch) {
            this.loopPagination.start = 1
            this.getLoops({ search: loopSearch, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleResetLoopSearch () {
            this.loopSearch = ''
            this.loopPagination.start = 1
            this.getLoops({ search: this.loopSearch, start: INITIAL_LOOPS_PAGINATION.start })
        },
        handleChangeLoopTab (loopTab) {
            this.loopPagination.start = 1
            this.loopTab = loopTab
            this.activeLoopId = undefined
            this.activeLoop = undefined
            this.getLoops()
            this.$emit('onColorLoops', { ...this.activeVariant, variantId: this.activeVariantId })
        },
        handleChangeLoop (id) {
            this.activeLoopId = id
            this.loopVisible = true
            this.getLoop(id)
        },
        handleChangeLoopPagination (start) {
            this.getLoops({ start: start - 1, search: this.loopSearch || '' })
        },
        normalizeLoopParams (param, self) {
            const filters = this.formatFiltersParam(this.visualizationFilters)
            return {
                filters,
                ...INITIAL_LOOPS_PAGINATION,
                start: this.loopPagination.start - 1,
                ...(self ? { self } : {}),
                ...(typeof param === 'string' ? {} : param),
                variantsId: this.selectedMultipleVariants.map(v => v.id),
            }
        },
        handleHideLoop () {
            this.loopVisible = false
            this.$emit('onColorLoops', { ...this.activeVariant })
        },
        handleShowLoop () {
            this.loopVisible = true
            this.$emit('onColorLoops', { ...this.activeLoop })
        },
        normalizeLoops (loops) {
            return (loops || []).map(loop => ({ ...loop, value: loop.relativeFrequency }))
        },
        normalizeLoopPaginationMax (value) {
            const max = Math.round(value / INITIAL_LOOPS_PAGINATION.limit)
            return max || 1
        },
        async getLoops (param) {
            this.isLoopsLoading = true
            this.loopVisible = true
            const self = this.loopTab === TABS.SELF_LOOPS
            const params = this.normalizeLoopParams(param, self)
            apiRequest(Api().visualizations.loops({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.loops = this.normalizeLoops(data)
                    this.loopPagination.max = this.normalizeLoopPaginationMax(total)
                })
                .catch(notifyError)
                .finally(() => {
                    this.isLoopsLoading = false
                })
        },
        async getLoop (loopId) {
            const params = this.normalizeLoopParams()
            apiRequest(Api().visualizations.loop({ processId: this.processId, loopId, params }))
                .then(({ data }) => {
                    const [loop] = data || []
                    this.activeLoop = loop
                    if (this.activeVariant) {
                        this.activeLoop.activities = this.activeVariant.activities
                        this.activeLoop.frequency = this.activeVariant.frequency
                        this.activeLoop.arcs = this.activeVariant.arcs
                        this.activeLoop.duration = this.activeVariant.duration
                    }
                    this.$emit('onColorLoops', { ...this.activeLoop })
                })
                .catch(notifyError)
                .finally(() => {
                })
        },
        /* ***************************  */
        /*    VARIANTS BY FREQUENCY     */
        /* ***************************  */
        toggleFrequencyVariantFilter () {
            if (!this.isVariantFilterActive) {
                this.getFrequencyVariants()
            }
        },
        getFrequencyVariants () {
            this.isVariantsLoading = true
            const params = { start: 0, limit: this.sliderValue }
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data, total }) => {
                    this.variants = data.map(item => item.id)
                    this.filterByFrequencyVariant()
                })
                .catch(notifyError)
                .finally(() => (this.isVariantsLoading = false))
        },
        filterByFrequencyVariant () {
            const variantIds = this.variants.slice(0, this.sliderValue)
            this.$root.$emit('filterByVariant', variantIds)
        },
        handleChangeSliderValue (value) {
            this.sliderValue = value
            this.percentageValue = Math.round(value / this.maxValue * 100)
        },
        formatNumber (num) {
            if (num > 999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
        handleChangeTextInput (value) {
            this.$refs.textinput.validate(value)
            if (!this.$refs.textinput.hasError) {
                this.handleChangedModel(value)
            }
        },
        handleChangedModel (value) {
            this.handleChangeSliderValue(Number(value))
            const { variants = 1 } = this.info
            if (value > variants || value < 1) return
            const newValue = Number(value)

            this.$emit('onChangedVariant', newValue)
            // DOC: Fix animation on change big input values, added delay to move slider component
            setTimeout(() => this.getModel(newValue), 500)
        },
        async fetchModel (variant) {
            await this.getModel(variant)
        },
        async getModel (variants) {
            const { processId } = this
            const filters = this.formatFiltersParam(this.visualizationFilters)
            this.isFetching = true
            const params = { variants, filters }
            apiRequest(Api().visualizations.model({ processId, params }))
                .then(({ data }) => {
                    const [graph] = data || []
                    this.$emit('onLoadChart', { ...graph, variants })
                    this.getFrequencyVariantsIds(variants)
                    this.activeVariant = graph
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        async getFrequencyVariantsIds (variantNumber) {
            const params = this.normalizeVariantParams({ limit: variantNumber })
            apiRequest(Api().visualizations.variants({ processId: this.processId, params }))
                .then(({ data }) => {
                    const variants = data
                    this.selectedMultipleVariants = variants.slice(0, variantNumber)
                    this.getLoops()
                    this.activeLoopId = undefined
                })
                .catch(notifyError)
                .finally(() => {
                })
        },
    },
}
</script>
<style scoped lang="scss">
.Variants {
    height: fit-content;
    max-height: 90vh;
    overflow: auto;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.scroll {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.Search {
    width: 200px;
}

.Bar {
    display: block;

    &:hover, &.selected {
        .WText { color: $black; }
        .LinearProgress { color: $accent; }
    }

    .WText {
        color: $gray-02;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 70%;
        margin-bottom: 0px;
    }

    .LinearProgress {
        color: $secondary;
        border-radius: $border-radius;
        transition: $transition;
    }
}
.WButton {
    padding: 7px;
}

.WSwitch {
  height: 20px;
  width: 34px;
  &::v-deep .Slider {
    position: absolute;
    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      right: 2px;
      top: 50%;
      transform: translate(0px, -50%);
      border-radius: 50%;
      background-color: $white;
      transition: 0.4s;
    }
  }
  &::v-deep input {
    &:checked:not(.disabled) {
      & + .Slider {
        &:before {
          transform: translate(16px, -50%);
        }
      }
    }
  }
}

.Stats{
    flex-wrap: nowrap;
}

.WLoading {
    top: 0;
    left: 0;
}

.Tabs {
    margin-top: 10px;
    margin-bottom: 15px;
    &::v-deep {
        .Container {
            padding: 0;
        }
        .Tab {
            min-height: auto;
        }
    }
}

.border-toggle {
    border: 1px solid $primary;
}
</style>
