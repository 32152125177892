import i18n from '@/lang/i18n'

export const WIZARD_INITAL_STATE = {
    configurationUuid: undefined,
    config: {
        name: 'default',
        activity: null,
        end: null,
        endFormat: '',
        start: null,
        startFormat: '',
        traceId: null,
        uid: null,
    },
    definition: {
        mode: undefined,
    },
    report: {
        name: null,
        description: null,
        type: {
            label: 'Standard conformance',
            value: 'STANDARD_CONFORMANCE',
        },
    },
}

export const WIZAR_MOCKS = {
    config: {
        name: 'default',
        activity: {
            aliasName: 'col_1',
            displayName: 'event',
            align: 'left',
            field: 'col_1',
            label: 'event',
            name: 'col_1',
            headerClasses: 'bg-primary text-white',
        },
        end: null,
        endFormat: '',
        start: {
            aliasName: 'col_2',
            displayName: 'startTime',
            align: 'left',
            field: 'col_2',
            label: 'startTime',
            name: 'col_2',
            headerClasses: 'bg-primary text-white',
        },
        startFormat: 'yyyy/MM/dd HH:mm:ss.SSS',
        traceId: {
            aliasName: 'col_0',
            displayName: 'case',
            align: 'left',
            field: 'col_0',
            label: 'case',
            name: 'col_0',
            headerClasses: 'bg-primary text-white',
        },
        uid: null,
    },
    definition: {
        mode: 'STRICT_SUB_PROCESS',
        query: 'SEQ("O_Created")',
        restrictions: '',
    },
    report: {
        name: 'Report name mock',
        description: 'Report description name',
        type: {
            label: 'Standard conformance',
            value: 'STANDARD_CONFORMANCE',
        },
    },
}

export const config = (options) => {
    const { datasetId } = options
    return {
        title: i18n.t('wizard.configs.query.title'),
        step: datasetId ? 'settings' : 'dataset',
        steps: [
            ...(!datasetId ? [{
                name: 'dataset',
                component: 'StepDataset',
                title: i18n.t('wizard.configs.query.steps.0'),
                icon: 'save_alt',
                next: 'submitStep0',
            }] : []),
            {
                name: 'settings',
                component: 'StepQuery',
                title: i18n.t('wizard.configs.query.steps.1'),
                icon: 'add_comment',
                mock: 'definition',
                prev: true,
                next: 'submitStep1',
                end: true,
            },
        ],
    }
}

const Setup = options => ({
    config: config(options),
    initialState: WIZARD_INITAL_STATE,
    mocks: WIZAR_MOCKS,
})

export default Setup
