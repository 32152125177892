<template>
    <div class="StateView">
        <div
            class="FirstMainContainer"
        >
            <OverallPerformance
                v-if="attributes"
                :attributes="attributes"
                class="OverallPerformance"
            />
            <div
                v-else
                class="OverallPerformance"
            />
            <ProcessElementsPanel
                class="PerformanceActivities"
                @selectedOpt="selectedOpt"
            />
        </div>
        <PerformanceDashboardByResource
            :selectedOptions="selectedOptions"
            :attributes="favouriteAttr"
            class="SecondMainContainer"
        />
    </div>
</template>

<script>
import {
    Api, apiRequest, notifyError,
} from '@/api'

import OverallPerformance from './OverallPerformance.vue'
import ProcessElementsPanel from './ProcessElementsPanel.vue'
import PerformanceDashboardByResource from './PerformanceDashboardByResource.vue'

export default {
    name: 'StateView',
    components: {
        PerformanceDashboardByResource,
        ProcessElementsPanel,
        OverallPerformance,
    },
    props: {
    },
    data () {
        return {
            attributes: null,
            response: null,
            selectedOptions: {},
            favouriteAttr: undefined,
        }
    },
    mounted () {
        this.getAttributes()
    },
    methods: {
        getValues () {
            // eslint-disable-next-line prefer-destructuring
            const processId = this.$route.params.processId
            apiRequest(Api().visualizations.attributes({ processId }))
                .then((data) => {
                    this.response = data
                    this.attributes = data.filter(item => this.favouriteAttr.includes(item.name))
                    this.attributes.unshift({ name: this.$t('booster.stateView.all') })
                })
                .catch(notifyError)
        },
        getAttributes () {
            // eslint-disable-next-line prefer-destructuring
            const processId = this.$route.params.processId
            apiRequest(Api().booster.favouriteAttributes({ processId, params: {} }))
                .then((data) => {
                    console.log(data)
                    this.favouriteAttr = data
                    // eslint-disable-next-line prefer-destructuring
                    // this.tab = data[0]
                })
                .catch(notifyError)
                .finally(this.getValues)
        },
        selectedOpt (data) {
            this.selectedOptions = data
        },
    },
}
</script>
<style scoped lang="scss">
.StateView {
   display: flex;
    margin:0 auto;
    height:100%;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .FirstMainContainer {
    width: 59%;
    height: 100% !important;
    display: flex;
    margin:0 auto;
    margin-right: 1%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .OverallPerformance{
    width:100%;
    height: 35%;
    display: flex;
    margin:0 auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .PerformanceActivities {
    width:100%;
    height: 65%;
  }
  .SecondMainContainer {
    width: 40%;
    max-height: 100%;
}

</style>
