var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StateView" },
    [
      _c(
        "div",
        { staticClass: "FirstMainContainer" },
        [
          _vm.attributes
            ? _c("OverallPerformance", {
                staticClass: "OverallPerformance",
                attrs: { attributes: _vm.attributes }
              })
            : _c("div", { staticClass: "OverallPerformance" }),
          _c("ProcessElementsPanel", {
            staticClass: "PerformanceActivities",
            on: { selectedOpt: _vm.selectedOpt }
          })
        ],
        1
      ),
      _c("PerformanceDashboardByResource", {
        staticClass: "SecondMainContainer",
        attrs: {
          selectedOptions: _vm.selectedOptions,
          attributes: _vm.favouriteAttr
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }