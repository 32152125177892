var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Dashboard" }, [
    _c(
      "div",
      { staticClass: "Container flex row-stretch flex-nowrap" },
      [
        _c("Sidebar", {
          attrs: { tabs: _vm.tabs },
          on: { input: _vm.handleChangeTab },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        }),
        _c(
          "div",
          { staticClass: "Wrapper" },
          [
            _c(
              "Transition",
              { attrs: { name: "main-router", mode: "out-in" } },
              [
                _vm.meta.header
                  ? _c(
                      "div",
                      { staticClass: "Header flex row-stretch-center" },
                      [
                        _c(
                          "div",
                          { staticClass: "Breadcrumbs" },
                          [_c("Breadcrumb")],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "Actions flex" },
                          [
                            _c("HeaderList", {
                              attrs: { search: _vm.meta.search },
                              model: {
                                value: _vm.filters,
                                callback: function($$v) {
                                  _vm.filters = $$v
                                },
                                expression: "filters"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "Screen flex-1",
                class: { isDesktop: !_vm.isMobile }
              },
              [
                _c(
                  "Transition",
                  { attrs: { name: "main-router", mode: "out-in" } },
                  [_c("RouterView", { attrs: { filters: _vm.filters } })],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }