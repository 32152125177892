var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.renameVariantModal.title")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "QForm",
              {
                staticClass: "align-center q-gutter-md q-ma-md",
                attrs: { onSubmit: "return false;" },
                on: { submit: _vm.handleRenameVariant }
              },
              [
                _c("QInput", {
                  ref: "name",
                  attrs: {
                    label: _vm.$t("visualization.renameVariantModal.name"),
                    outlined: "",
                    stackLabel: "",
                    dense: "",
                    rules: [
                      function(val) {
                        return (
                          !!val || _vm.$t("visualization.variantRenameRequired")
                        )
                      },
                      function(val) {
                        return (
                          (val.length >= 1 && val.length <= 100) ||
                          _vm.$t("visualization.variantRenameLengthError")
                        )
                      }
                    ]
                  },
                  model: {
                    value: _vm.newVariantName,
                    callback: function($$v) {
                      _vm.newVariantName = $$v
                    },
                    expression: "newVariantName"
                  }
                }),
                _c("QBtn", {
                  attrs: {
                    label: _vm.$t("visualization.renameVariantModal.submit"),
                    noCaps: "",
                    color: "primary",
                    unelevated: ""
                  },
                  on: { click: _vm.handleRenameVariant }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }