var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Overview Container flex flex-1" },
    [
      _c("Sidebar", {
        ref: "Sidebar",
        attrs: { tab: _vm.tab },
        on: { tab: _vm.handleTabChange }
      }),
      _c("div", { staticClass: "Screen flex-1" }, [
        _c("div", { staticClass: "Titles" }, [
          _c(
            "div",
            { staticClass: "Title" },
            [
              _c("div", { staticClass: "TitleText" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("booster.performanceBoosterTitle")) + " "
                ),
                _c("div", { staticClass: "Description" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("booster.boosterDescription")) + " "
                  )
                ])
              ]),
              _c("QSpace"),
              _c("div", { staticClass: "RightText" }, [
                _vm._v(" " + _vm._s(_vm.tabTitle) + " ")
              ])
            ],
            1
          )
        ]),
        _vm.tab === "OVERVIEW"
          ? _c(
              "div",
              { staticClass: "ScreenOptions" },
              [_c("StateView", { ref: "StateView" })],
              1
            )
          : _vm._e(),
        _vm.tab === "PREDICTION"
          ? _c(
              "div",
              { staticClass: "ScreenOptions" },
              [
                _c("Prediction", {
                  ref: "Prediction",
                  attrs: { processId: _vm.processId }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.tab === "IMPROVEMENT"
          ? _c("div", { staticClass: "ScreenOptions" }, [_c("Improvement")], 1)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }