import Data from './data'

const endpoints = {
    activitiesSorted: {
        method: 'POST',
        path: `/data-navigator/views/activities-sorted`,
        transform: Data.activitiesSorted,
    },
    transitionsSorted: {
        method: 'POST',
        path: `/data-navigator/views/transitions-sorted`,
        transform: Data.transitionsSorted,
    },
    variantsOver: {
        method: 'POST',
        path: `/data-navigator/views/variants-over`,
        transform: Data.variantsOver,
    },
    variantsSorted: {
        method: 'POST',
        path: `/data-navigator/views/variants-sorted`,
        transform: Data.variantsSorted,
    },
}

export default {
    name: 'dataNavigator',
    endpoints,
}
