const activities = [
    {
        id: '1',
        name: 'Standard Purchase Order',
    },
    {
        id: '2',
        name: 'Standard Purchase Order 2',
    },
    {
        id: '3',
        name: 'Standard Purchase Order 3',
    },
    {
        id: '4',
        name: 'Standard Purchase Order 4',
    },
]

export default {
    activities,
}
