export default {
    new: 'Invite new user',
    available: 'Available invitations',
    revoke: 'Revoke invitation',
    cancel: 'Cancel invitation',
    hint: 'New user\'s email address',
    valid: 'Enter a valid email',
    modal: {
        title: 'Revoke invitation',
        text: 'Are you sure you want to revoke invitation?',
    },
    notifications: {
        noResult: {
            title: 'No result found',
            text: 'No invitations found for your search.',
        },
        empty: {
            title: 'No invitations found.',
            text: 'You can create your first invitation.',
        },
        canceled: {
            text: 'Invitation canceled successfully.',
        },
        revoked: {
            text: 'Invitation revoked successfully.',
        },
        registered: {
            text: 'Invitation registered successfully.',
        },
    },

    INVITED: 'INVITED',
    ACCEPTED: 'ACCEPTED',
    CANCELLED: 'CANCELLED',
    REVOKED: 'REVOKED',
}
