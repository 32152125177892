var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "WIcon",
    class: _vm.iconClass,
    style: _vm.iconStyles,
    on: {
      click: function($event) {
        return _vm.$emit("click", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }