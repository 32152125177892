var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ContextualInfo" },
    [
      _c(
        "QExpansionItem",
        _vm._b(
          {
            attrs: {
              denseToggle: "",
              defaultOpened: "",
              expandSeparator: "",
              dense: "",
              label: _vm.title.label
            }
          },
          "QExpansionItem",
          { icon: _vm.title.icon },
          false
        ),
        [
          _vm.title.key === "FD"
            ? _c(
                "div",
                [
                  _c("FrequencyDurationPanel", {
                    attrs: {
                      currentTitle: _vm.title,
                      processId: _vm.processId,
                      filters: _vm.filters,
                      chart: _vm.chart
                    },
                    on: { title: _vm.handleClickOption }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.title.key === "AI"
            ? _c(
                "div",
                [
                  _c("AttributeInformationPanel", {
                    attrs: {
                      currentTitle: _vm.title,
                      attributes: _vm.attributes,
                      processId: _vm.processId,
                      filters: _vm.filters,
                      chart: _vm.chart
                    },
                    on: { title: _vm.handleClickOption }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }