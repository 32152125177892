var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Notifications" }, [
    _c(
      "div",
      { staticClass: "Container" },
      [
        _c("NotificationList", {
          ref: "table",
          attrs: {
            rows: _vm.notifications,
            total: _vm.total,
            loading: _vm.isFetching
          },
          on: {
            onFetchData: _vm.fetchData,
            onVisualization: _vm.handleVisualization,
            onChangeVisibility: _vm.fetchFilteredData,
            onFilterDate: _vm.fetchFilteredData,
            onClickContent: _vm.handleClickContent
          }
        }),
        _c("Modal", {
          attrs: { isOpen: !!_vm.notificationInfo },
          on: { close: _vm.handleCloseModal },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c(
                    "WText",
                    {
                      staticClass: "mb-0",
                      attrs: { tag: "h3", size: 16, weight: "semi" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.content")) + " ")]
                  )
                ]
              },
              proxy: true
            },
            {
              key: "body",
              fn: function() {
                return [
                  _c("WText", {
                    domProps: {
                      innerHTML: _vm._s(_vm.notificationInfo.content)
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }