var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "WIconSwitch" },
    _vm._l(_vm.options, function(option, index) {
      return _c("WIcon", {
        key: index,
        class: { selected: _vm.value === option.value },
        attrs: {
          icon: option.icon,
          size: 16,
          color: _vm.value === option.value ? "white" : "black"
        },
        on: {
          click: function($event) {
            _vm.value !== option.value ? _vm.$emit("input", option.value) : ""
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }