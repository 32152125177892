var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Datasets" },
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          !_vm.openSettingsModal
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "Actions flex row-left-center mb-1" },
                  [
                    _c(
                      "WButton",
                      {
                        attrs: {
                          color: "primary",
                          iconLeft: "plus-circle",
                          disabled: _vm.isProjectArchived
                        },
                        on: { click: _vm.handleCreateItem }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("projects.datasets.new")) + " "
                        )
                      ]
                    ),
                    _c(
                      "WButton",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          outline: "",
                          color: "primary",
                          iconLeft: "upload-cloud"
                        },
                        on: { click: _vm.handleClickImport }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("projects.datasets.import")) + " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "Container" },
                  [
                    _c("DatasetsListCards", {
                      ref: "table",
                      attrs: {
                        rows: _vm.datasets,
                        total: _vm.total,
                        search: (_vm.filters || {}).search,
                        archived: _vm.isProjectArchived,
                        loading: _vm.isFetching
                      },
                      on: {
                        onNewItem: _vm.handleCreateItem,
                        onDelete: _vm.handleDeleteItem,
                        onUpload: _vm.handleUploadItem,
                        onWizardQuery: _vm.handleQueryItem,
                        onFetchData: _vm.fetchData,
                        onVisualization: _vm.handleGoToVisualization,
                        onSettings: _vm.handleOpenSettings
                      }
                    })
                  ],
                  1
                )
              ])
            : _c("SettingsModal", {
                ref: "settingsModal",
                attrs: {
                  datasetInfo: _vm.datasetSettings,
                  isOpen: _vm.openSettingsModal
                },
                on: {
                  UpdateDatasetName: _vm.handleUpdateItem,
                  SetUpdates: _vm.handleSetItemUpdates,
                  CloseSettings: _vm.handleCloseSettings
                }
              })
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "deleteDialog",
        attrs: {
          title: _vm.$t("projects.datasets.delete"),
          text: _vm.$t("projects.datasets.modals.delete.text", {
            name: (_vm.selected || {}).name
          }),
          loading: _vm.isLoading
        },
        on: { onAccept: _vm.archiveDataset }
      }),
      _c("ModalNameDesc", {
        ref: "newDialog",
        attrs: {
          title: _vm.$t("projects.datasets.new"),
          action: _vm.$t("actions.create"),
          loading: _vm.isLoading
        },
        on: { onSubmit: _vm.createDataset }
      }),
      _c("ModalNameDesc", {
        ref: "updateDialog",
        attrs: {
          title: _vm.$t("projects.datasets.edit"),
          action: _vm.$t("actions.save"),
          loading: _vm.isLoading,
          withoutDesc: ""
        },
        on: { onSubmit: _vm.updateDataset }
      }),
      _c("UploadDialog", {
        ref: "uploadDialog",
        on: { "upload-action": _vm.fetchData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }