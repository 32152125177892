import VueValuesStore from 'vue-values'
import moment from 'moment'
import {
    ACTIVITY_FILTERS, DURATION_FILTERS, DATE_FILTERS, ATTRIBUTES_FILTERS, ARC_FILTERS, VARIANT_FILTERS, LOOP_FILTERS,
} from '@/screens/Project/Process/Visualization/sections/FilterModal/filtersEntity'

export default {
    computed: {
        visualizationFilters () {
            return VueValuesStore.value.get('visualizationFilters')
        },
    },
    methods: {
        setVisualizationFilters (selected) {
            VueValuesStore.value.set('visualizationFilters', selected)
        },
        addVisualizationFilters (selected) {
            const { visualizationFilters } = this
            VueValuesStore.value.set('visualizationFilters', [...(visualizationFilters || []), selected])
        },
        addMultipleFilters (allSelected) {
            const { visualizationFilters } = this
            VueValuesStore.value.set('visualizationFilters', [...(visualizationFilters || []), ...allSelected])
        },
        updateFilters (filters) {
            const cached = this.visualizationFilters || []
            const formattedFilters = cached
                .map(f => (f.uuid === filters.uuid ? { ...f, toDelete: true } : f))
            this.setVisualizationFilters(formattedFilters)
        },
        getVisualizationFilters () {
            return VueValuesStore.value.get('filters')
        },
        resetStorage () {
            VueValuesStore.value.set('visualizationFilters', [])
        },
        formatFiltersParam (filters = []) { // TODO REFACTOR!!
            const sanitizedFilters = filters.filter(f => !f.pending)
            return sanitizedFilters.map(({
                id, key, kind, values, type = {}, activity, arc, attribute, constraints, ...rest
            }) => {
                let filter = { id }
                if (ACTIVITY_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (ARC_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (DATE_FILTERS.includes(id)) {
                    const value = key || rest?.value
                    filter = { ...filter, values: [moment(value).format('YYYY-MM-DDTHH:mm:ss')] }
                }
                if (DURATION_FILTERS.includes(id)) {
                    let formattedValues = ''
                    if (!key) {
                        if (rest?.uuid.includes('ACTIVITIES_DURATION_GREATER_THAN') || rest?.uuid.includes('ACTIVITIES_DURATION_LESS_THAN')) {
                            formattedValues = rest?.value.split(', ')
                        } else {
                            formattedValues = [rest?.value]
                        }
                    } else {
                        const [date, time] = key.split(' ')
                        const [days] = this.dateFormatter(date)
                        const [hours, minutes, seconds] = this.dateFormatter(time, ':')
                        const duration = {
                            days, hours, minutes, seconds,
                        }
                        const formattedKey = this.getDuration(duration)
                        if (id === 'ACTIVITIES_DURATION_GREATER_THAN' || id === 'ACTIVITIES_DURATION_LESS_THAN') {
                            formattedValues = [type.activitySource, type.activityTarget, formattedKey]
                        } else {
                            formattedValues = [formattedKey]
                        }
                    }

                    if (type.category || activity) filter = { ...filter, values: formattedValues, activity: type.category || activity }
                    else if (type.arc || arc) filter = { ...filter, values: formattedValues, arc: type.arc || arc }
                    else filter = { ...filter, values: formattedValues }
                }
                if (ATTRIBUTES_FILTERS.includes(kind) || ATTRIBUTES_FILTERS.includes(id)) {
                    let attrValues = []
                    if (constraints?.value === 'TRACE_ATTRIBUTES_CTG_IN' || constraints?.value === 'TRACE_ATTRIBUTES_CTG_NOT_IN') {
                        attrValues = rest?.value ? rest?.value : []
                    } else {
                        attrValues = rest?.value ? [
                            id === 'attrib_date' ? moment(rest?.value).format('YYYY-MM-DDTHH:mm:ss') : rest?.value,
                        ] : []
                    }
                    filter = {
                        ...(activity?.value !== '*' ? { activity: activity?.value ? activity.value : activity } : {}),
                        attribute: attribute?.value ? attribute?.value : attribute,
                        id: constraints?.value ? constraints.value : id,
                        values: attrValues,
                    }
                }

                if (VARIANT_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                if (LOOP_FILTERS.includes(id)) {
                    const val = typeof key === 'string' ? [key] : key
                    filter = { ...filter, values: values || val }
                }
                return filter
            })
        },
        getDuration (duration) {
            return moment.duration(duration).toISOString()
        },
        dateFormatter (dates, splitBy = '/') {
            return dates.split(splitBy).map(t => t.slice(0, -1))
        },
    },
}
