var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row justify-between items-center q-pr-md" },
      [
        _c("WText", { staticClass: "mb-1", attrs: { size: 20 } }, [
          _vm._v(" SAP Extractor ")
        ]),
        _c(
          "QBtn",
          {
            attrs: { square: "", icon: "arrow_back", color: "primary" },
            on: { click: _vm.backtoConnectors }
          },
          [
            _c(
              "QTooltip",
              {
                attrs: {
                  contentClass: "bg-primary",
                  anchor: "center left",
                  self: "center right"
                }
              },
              [_vm._v(" Back to connector list ")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row items-center q-pr-md" },
      [
        _c(
          "WCard",
          { staticClass: "Personal mb-2", attrs: { bordered: "" } },
          [
            _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
              _vm._v(" ABAP Scripts ")
            ]),
            _c("p", [
              _vm._v(
                " Given the actual variety in SAP implementations through SAP customers, Inverbis offers two ABAP scripts that cover two standard processes, order to cash and procure to pay. These can be used to extract relevant process data in a format already prepared for analysis with Inverbis. "
              )
            ])
          ],
          1
        ),
        _c(
          "WCard",
          { staticClass: "Personal mb-2", attrs: { bordered: "" } },
          [
            _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
              _vm._v(" Choose your SAP process ")
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "q-pa-xs col-xs-12 col-sm-6 col-md-6" },
                [
                  _c(
                    "QCard",
                    { staticClass: "bg-grey-2" },
                    [
                      _c(
                        "QCardSection",
                        { staticClass: "text-h6 align-center" },
                        [
                          _c("QRadio", {
                            attrs: {
                              val: "p2p",
                              label: "Purchase to Pay",
                              color: "secondary"
                            },
                            model: {
                              value: _vm.connector,
                              callback: function($$v) {
                                _vm.connector = $$v
                              },
                              expression: "connector"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "QCardSection",
                        {
                          staticClass: "text-primary",
                          attrs: { horizontal: "" }
                        },
                        [
                          _c(
                            "QCardSection",
                            [
                              _c("QIcon", {
                                staticStyle: { "font-size": "3em" },
                                attrs: { name: "payments" }
                              })
                            ],
                            1
                          ),
                          _c("QCardSection", [
                            _c("p", [
                              _vm._v(
                                " Extract process data from your SAP purchases from purchase requisitions to the final payment. "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "q-pa-xs col-xs-12 col-sm-6 col-md-6" },
                [
                  _c(
                    "QCard",
                    { staticClass: "bg-grey-2" },
                    [
                      _c(
                        "QCardSection",
                        { staticClass: "text-h6 align-center" },
                        [
                          _c("QRadio", {
                            attrs: {
                              val: "o2c",
                              label: "Order to Cash",
                              color: "secondary"
                            },
                            model: {
                              value: _vm.connector,
                              callback: function($$v) {
                                _vm.connector = $$v
                              },
                              expression: "connector"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "QCardSection",
                        {
                          staticClass: "text-primary",
                          attrs: { horizontal: "" }
                        },
                        [
                          _c(
                            "QCardSection",
                            [
                              _c("QIcon", {
                                staticStyle: { "font-size": "3em" },
                                attrs: { name: "sell" }
                              })
                            ],
                            1
                          ),
                          _c("QCardSection", [
                            _c("p", [
                              _vm._v(
                                " Extract process data from your SAP sales from the order creation to the final billing. "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "column items-center q-mt-md" },
              [
                _c("QCheckbox", {
                  staticClass: "q-mt-md",
                  attrs: {
                    label:
                      "I agree not to share or distribute Inverbis's SAP Extractor scripts partly or totally without written consent from Inverbis"
                  },
                  model: {
                    value: _vm.license,
                    callback: function($$v) {
                      _vm.license = $$v
                    },
                    expression: "license"
                  }
                }),
                _c("QBtn", {
                  staticClass: "q-mt-md",
                  attrs: {
                    label: "Download",
                    color: "primary",
                    icon: "download",
                    noCaps: "",
                    disabled: _vm.disabledDownload
                  },
                  on: { click: _vm.download }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }