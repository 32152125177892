var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ActivitiesContainer" },
    [
      _c("WText", { staticClass: "mb-1" }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c(
        "div",
        { staticClass: "Activities flex row-left-center" },
        _vm._l(_vm.options, function(ref) {
          var label = ref.label
          var id = ref.id
          var readOnly = ref.readOnly
          return _c(
            "WCard",
            { key: id, attrs: { shadow: "", disabled: readOnly } },
            [
              _c(
                "div",
                {
                  staticClass: "Item flex row-left-center flex-nowrap",
                  on: {
                    click: function($event) {
                      return _vm.handleClickFilter({
                        label: label,
                        id: id,
                        readOnly: readOnly
                      })
                    }
                  }
                },
                [
                  _c("FilterIcon", { attrs: { id: id } }),
                  _c(
                    "WText",
                    {
                      staticClass: "pl-1",
                      attrs: { tag: "p", weight: "medium" }
                    },
                    [_vm._v(" " + _vm._s(label) + " ")]
                  )
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }