/* eslint-disable import/prefer-default-export */
export const convertSeconds = (seconds) => {
    let h
    let m = seconds ? Math.floor(seconds / 60) : 0
    const s = seconds ? seconds % 60 : 0
    h = m ? Math.floor(m / 60) : 0
    m %= 60
    const d = h ? Math.floor(h / 24) : 0
    h %= 24
    return {
        d, h, m, s,
    }
}
