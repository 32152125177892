export default {
    events: 'Eventos',
    historic: 'Histórico',
    currentUse: 'Uso actual',
    maxEvents: 'Máximo de eventos',
    lines: 'Líneas',

    emptyListTitle: 'No hay resultados',
    emptyListText: 'No se ha encontrado ningún evento.',
}
