<template>
    <Transition
        name="fade"
        :duration="500"
    >
        <div
            v-if="visible"
            :class="position"
            class="WLoading"
        >
            <div
                :style="backdropStyles"
                :class="backdropClass"
                class="Backdrop"
            />
            <WSpinner
                visible
                noMargin
                :color="spinnerColor"
                class="Spinner"
            />
            <h4
                v-if="text"
                class="Text"
            >
                {{ text }}
            </h4>
            <slot />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'WLoading',
    props: {
        color: VueTypes.oneOf(['primary', 'secondary', 'tertiary']).def('primary'),
        position: VueTypes.oneOf(['relative', 'absolute', 'fixed']).def('absolute'),
        text: VueTypes.string.def(''),
        opacity: VueTypes.number.def(0.8),
        visible: VueTypes.bool.def(false),
    },
    computed: {
        backdropStyles () {
            return {
                opacity: this.opacity,
            }
        },
        backdropClass () {
            return [
                this.color,
            ]
        },
        spinnerColor () {
            return this.color === 'tertiary' ? 'white' : this.color
        },
    },
}
</script>

<style scoped lang="scss">
$z-index-loading: 2000;

.WLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: $z-index-loading;

    &.relative { position: relative;}
    &.absolute {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &.fixed {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
}

.Backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $z-index-loading + 1;
    opacity: 0.8;

    &.primary { background: $loader-bg;}
    &.secondary { background: $white;}
    &.tertiary { background: $gray-01;}
}

.Spinner {
    z-index: $z-index-loading + 100;
}

.Text {
    margin-top: 45px;
    z-index: $z-index-loading + 2;
}
</style>
