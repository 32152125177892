var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      attrs: { options: _vm.options, direction: _vm.direction },
      on: { onClick: _vm.handleClickAction }
    },
    [
      _c(
        "div",
        { staticClass: "flex row-left-center flex-nowrap" },
        [
          _c("div", { staticClass: "User" }, [
            _vm._v(" " + _vm._s(_vm.initialName) + " ")
          ]),
          !_vm.hideFullName
            ? _c(
                "WText",
                {
                  staticClass: "ml-1",
                  attrs: { tag: "span", weight: "semi", ellipsis: "" }
                },
                [_vm._v(" " + _vm._s(_vm.fullName) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }