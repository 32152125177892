import Keycloak from 'keycloak-js'
import { noop } from 'lodash'
import Config from '@/config'
import { setAuthorization, removeAuthorization } from '@/api'

let keycloak

/**
 * Sign in
 */
const signin = async () => new Promise((resolve, reject) => {
    const instance = Keycloak(Config.keycloak)
    keycloak = instance

    keycloak
        .init({
            onLoad: 'login-required',
            checkLoginIframe: false,
        })
        .then(async (auth) => {
            const { isTokenExpired } = keycloak

            if (isTokenExpired()) await refreshToken()
            else login(keycloak)

            // Debugging Keycloak
            if (Config.isDev) {
                keycloak.onAuthError = (data) => {
                    console.log('onAuthError', data)
                }

                keycloak.onAuthRefreshError = (data) => {
                    console.log('onAuthRefreshError', data)
                }

                keycloak.onTokenExpired = (data) => {
                    console.log('onTokenExpired', data)
                }

                keycloak.onAuthLogout = (data) => {
                    console.log('onAuthLogout', data)
                }
            }

            resolve({ ok: auth, data: keycloak })
        })
        .catch((error) => {
            reject(error)
        })
})

/**
 * Login user
 * @param {Object} keycloak - keycloak instance
 */
const login = ({ token, tokenParsed }) => {
    const { typ: type } = tokenParsed
    setAuthorization({ type, token })
}

/**
 * Signout
 */
const signout = ({ storage } = {}) => {
    if (storage) storage.clean()
    removeAuthorization()
    if (keycloak) keycloak.logout()
}

/**
 * Refresh token
 */
const refreshToken = async () => {
    if (keycloak) {
        return new Promise((resolve, reject) => {
            keycloak
                .updateToken(70)
                .then((refreshed) => {
                    if (refreshed) console.warn('Token was successfully refreshed')
                    else console.warn('Token is still valid')
                    login(keycloak)
                    resolve({ ok: true, data: keycloak })
                })
                .catch((error) => {
                    console.warn('Failed to refresh the token, or the session has expired')
                    signout()
                    reject(error)
                })
        })
    }

    return noop()
}

const instance = () => keycloak

const user = () => {
    if (keycloak) {
        const { tokenParsed } = keycloak
        const {
            name: fullName, given_name: name, family_name: surname, email,
        } = tokenParsed
        return {
            fullName,
            name,
            surname,
            email,
        }
    }

    return undefined
}

/**
 * Account manager
 */
const accountManagement = () => {
    if (keycloak) keycloak.accountManagement()
}

export default {
    signin,
    signout,
    refreshToken,
    instance,
    user,
    accountManagement,
}
