export default {
    sections: {
        personal: 'Ajustes personales',
        security: 'Seguridad',
        apis: 'Gestionar tokens',
        token: 'Token disponible',
    },

    personal: {
        text: `Edita tu información personal y configura la visibilidad de tu cuenta <strong>{name}</strong>.`,
    },

    security: {
        text: `Te enviaremos un correo electrónico con las instrucciones para restablecer tu contraseña.`,
        btn: 'Recuperar contraseña',
    },

    apis: {
        text: 'En esta vista puedes gestionar los tokens para trabajar e integrarte con InVerbis a través de su API REST.',
        generate: 'Generar token',
        cancel: 'Revocar token',
    },

    token: {
        tooltip: 'Copiar token al portapapeles',
        success: 'Token copiado con éxito',
        cancelled: 'Token revocado con éxito',
        modal: {
            title: 'Información del usuario',
            password: 'Contraseña',
            btn: 'Enviar',
        },
    },

    subscription: {
        text: 'Suscripción',
        active: {
            yes: `Tu suscripción está <strong>ACTIVA</strong> actualmente.`,
            no: `Suscripción <strong>INACTIVA</strong>, puedes reactivarla a continuación.`,
        },
        goTo: 'Acceder al portal',
        reactivate: 'Reactivar',
        cancel: 'Cancelar',
        modal: {
            title: 'Cancelar suscripción',
            text: 'Al cancelar la cuenta se procederá a facturar todo el consumo pendiente. ¿Continuar?',
        },
        notifications: {
            reactivate: 'Suscripción reactivada con éxito.',
            cancel: 'Suscripción cancelada con éxito.',
        },
    },

    notifications: {
        personal: {
            text: 'Información personal actualizada correctamente.',
        },
        resetPassword: {
            text: 'Te enviaremos un mensaje de correo electrónico con tu nueva contraseña.',
        },
    },

    modals: {
        recovery: {
            title: 'Recuperar contraseña',
            text: '¿Seguro que quieres recuperar tu contraseña?',
        },
    },
}
