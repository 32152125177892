<template>
    <div
        class="WCard"
        :class="cardClass"
    >
        <div
            v-if="disabled || loading"
            class="Overlay"
        />
        <div
            v-if="loading"
            class="Spinner"
        >
            <WSpinner
                visible
            />
        </div>
        <div
            v-if="$slots.header"
            class="Header"
        >
            <slot name="header" />
        </div>
        <div class="Body">
            <slot />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'WCard',
    props: {
        backgroundColor: VueTypes.oneOf(['', 'white']).def(''),
        disabled: VueTypes.bool.def(false),
        loading: VueTypes.bool.def(false),
        bordered: VueTypes.bool.def(false),
        shadow: VueTypes.bool.def(false),
        flat: VueTypes.bool.def(false),
    },
    computed: {
        cardClass () {
            return [
                this.backgroundColor,
                { shadow: this.shadow },
                { bordered: this.bordered },
                { flat: this.flat },
                { disabled: this.disabled },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.WCard {
    position: relative;
    border-radius: $border-radius;
    background: $card-bg;

    &.white {
        background: $white
    }

    &.shadow {
        box-shadow: 0 2px 5px 0 rgba($black, 0.2);
        border: solid 1px rgba($black, 0.2);
    }

    &.bordered {
        border: 1px solid $border-color;
    }

    & > .Header, & > .Body {
        padding: 20px;
    }

    &.flat {
        & > .Header, & > .Body {
            padding: 0px;
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
}

.Overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: $gray-light;
    opacity: .5;
}

.Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);
}
</style>
