var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Table", class: { bordered: !_vm.loading } },
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_vm.loading ? _c("WLoading", { attrs: { visible: "" } }) : _vm._e()],
        1
      ),
      _c("QTable", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading"
          }
        ],
        staticClass: "Wrapper",
        class: { bordered: !_vm.length },
        attrs: {
          flat: "",
          columns: _vm.columns,
          data: _vm.data,
          totalRows: _vm.total,
          rowKey: _vm.rowKey,
          rowsPerPageOptions: _vm.rowsPerPageOptions,
          pagination: _vm.pagination,
          hideHeader: _vm.hideHeader,
          hideBottom: _vm.hideBottom
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          },
          request: _vm.emitRequest
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function(props) {
                return [_vm._t("content", null, { row: props.row })]
              }
            },
            {
              key: "no-data",
              fn: function(noData) {
                return [_vm._t("empty", null, { data: noData })]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }