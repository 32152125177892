var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "fade", duration: 500 } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "WLoading", class: _vm.position },
          [
            _c("div", {
              staticClass: "Backdrop",
              class: _vm.backdropClass,
              style: _vm.backdropStyles
            }),
            _c("WSpinner", {
              staticClass: "Spinner",
              attrs: { visible: "", noMargin: "", color: _vm.spinnerColor }
            }),
            _vm.text
              ? _c("h4", { staticClass: "Text" }, [
                  _vm._v(" " + _vm._s(_vm.text) + " ")
                ])
              : _vm._e(),
            _vm._t("default")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }