var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MainContainer" }, [
    _c(
      "div",
      { staticClass: "Container" },
      [
        _c(
          "div",
          { staticClass: "TopContainer" },
          [
            _c(
              "div",
              { staticClass: "ChartTitle" },
              [
                _c("strong", { staticClass: "PrimaryColor" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("booster.stateView.performanceBy")) +
                      " " +
                      _vm._s(_vm.$t("booster.stateView." + _vm.selected))
                  )
                ]),
                _vm.selected === "variant"
                  ? _c(
                      "QIcon",
                      {
                        staticClass: "GraphIcon",
                        attrs: {
                          color: "primary",
                          size: "xs",
                          name: "account_tree"
                        }
                      },
                      [
                        _c("QTooltip", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("booster.stateView.rightClick")) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("QSpace"),
            _c("QBtnToggle", {
              staticStyle: { height: "fit-content" },
              attrs: {
                toggleColor: "primary",
                noCaps: "",
                dense: "",
                size: "0.8em",
                padding: "2px 2px",
                options: _vm.sort
              },
              on: {
                input: function($event) {
                  return _vm.asignData(_vm.performanceByResult)
                }
              },
              model: {
                value: _vm.sortDirection.value,
                callback: function($$v) {
                  _vm.$set(_vm.sortDirection, "value", $$v)
                },
                expression: "sortDirection.value"
              }
            }),
            _c("QBtnToggle", {
              staticStyle: { height: "fit-content", "margin-left": "5px" },
              attrs: {
                toggleColor: "primary",
                noCaps: "",
                dense: "",
                size: "0.8em",
                padding: "2px 5px",
                options: _vm.options
              },
              on: {
                input: function($event) {
                  return _vm.asignData(_vm.performanceByResult)
                }
              },
              model: {
                value: _vm.optionSel,
                callback: function($$v) {
                  _vm.optionSel = $$v
                },
                expression: "optionSel"
              }
            })
          ],
          1
        ),
        _vm.isLoadingPerfBy
          ? _c("WLoading", { staticClass: "WLoading", attrs: { visible: "" } })
          : _c(
              "div",
              {
                staticClass: "ChartContainer",
                attrs: { oncontextmenu: "return false;" }
              },
              [
                _c("v-chart", {
                  ref: "chart2",
                  staticClass: "Chart",
                  attrs: {
                    id: "chart2",
                    autoresize: "",
                    option: _vm.performanceByOptions
                  },
                  on: {
                    click: _vm.emitValue,
                    contextmenu: _vm.handleChartMouseMove
                  }
                }),
                _c(
                  "QDialog",
                  {
                    model: {
                      value: _vm.showTooltip,
                      callback: function($$v) {
                        _vm.showTooltip = $$v
                      },
                      expression: "showTooltip"
                    }
                  },
                  [
                    _c(
                      "QCard",
                      { staticClass: "Card" },
                      [
                        _c(
                          "div",
                          { staticClass: "GraphHeader" },
                          [
                            _c("h6", [
                              _vm._v(" " + _vm._s(_vm.currentElement))
                            ]),
                            _c("QSpace"),
                            _c("QBtn", {
                              attrs: {
                                icon: "close",
                                flat: "",
                                round: "",
                                dense: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showTooltip = false
                                }
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.ready
                          ? _c("WLoading", {
                              staticClass: "GraphLoading",
                              attrs: { visible: "" }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "GraphHolder flex",
                            attrs: { id: "view-wrapper" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  right: "0",
                                  margin: "5px"
                                }
                              },
                              [
                                _c("QBtn", {
                                  staticClass: "q-mr-sm",
                                  attrs: {
                                    icon: "adjust",
                                    unelevated: "",
                                    color: "white",
                                    textColor: "primary"
                                  },
                                  on: { click: _vm.handleCenterChart }
                                }),
                                _c(
                                  "QTooltip",
                                  {
                                    attrs: {
                                      contentClass: "bg-grey-3 text-black"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("visualization.centerChart")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "flex-grow": "1" } },
                              [
                                _vm.ready &&
                                _vm.chart &&
                                (_vm.chart.activities || []).length &&
                                (Object.keys(_vm.chart) || []).length
                                  ? _c("Graph", {
                                      ref: "chart",
                                      attrs: {
                                        data: _vm.chart,
                                        model: _vm.model,
                                        parent: "view-wrapper"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Container" },
      [
        _c(
          "div",
          { staticClass: "TopContainer" },
          [
            _c("div", { staticClass: "ChartTitle" }, [
              _vm.selected === "activity"
                ? _c("strong", { staticClass: "PrimaryColor" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("booster.stateView.timeSpentPerCase")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.selected === "transition"
                ? _c("strong", { staticClass: "PrimaryColor" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("booster.stateView.timeSpentPerCase")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.selected === "variant"
                ? _c("strong", { staticClass: "PrimaryColor" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("booster.stateView.leadTimesBy")) +
                        " " +
                        _vm._s(_vm.$t("booster.stateView." + _vm.selected))
                    )
                  ])
                : _vm._e()
            ]),
            _c("QSpace"),
            _c("QBtnToggle", {
              staticStyle: { height: "fit-content" },
              attrs: {
                toggleColor: "primary",
                noCaps: "",
                dense: "",
                size: "0.8em",
                padding: "2px 2px",
                options: _vm.sort
              },
              on: { input: _vm.leadTimesBy },
              model: {
                value: _vm.sortDirection2.value,
                callback: function($$v) {
                  _vm.$set(_vm.sortDirection2, "value", $$v)
                },
                expression: "sortDirection2.value"
              }
            })
          ],
          1
        ),
        _vm.isLoadingLeadTimes
          ? _c("WLoading", { staticClass: "WLoading", attrs: { visible: "" } })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "ChartContainer" },
          [
            !_vm.isLoadingLeadTimes
              ? _c("v-chart", {
                  staticClass: "Chart",
                  attrs: { option: _vm.leadTimesOptions, autoresize: "" }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }