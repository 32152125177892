var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "QMenu",
    { attrs: { offset: [0, 5] }, on: { "before-hide": _vm.notifyHidden } },
    [
      _c("QCard", { staticClass: "q-ma-sm q-pa-sm", attrs: { flat: "" } }, [
        _c(
          "div",
          { staticClass: "column q-gutter-sm" },
          [
            _c("QInput", {
              ref: "costConfInput",
              attrs: {
                value: _vm.avgCostConf,
                label: _vm.$t("visualization.loopMetrics.avgCostConf"),
                type: "number",
                rules: [
                  function(value) {
                    return (
                      _vm.validators.minValue(value, _vm.minValue) ||
                      _vm.$t("validation.minValue", {
                        attribute: _vm.$t(
                          "visualization.loopMetrics.avgCostConf"
                        ),
                        min: _vm.minValue
                      })
                    )
                  },
                  function(value) {
                    return (
                      _vm.validators.maxValue(value, _vm.maxValue) ||
                      _vm.$t("validation.maxValue", {
                        attribute: _vm.$t(
                          "visualization.loopMetrics.avgCostConf"
                        ),
                        max: _vm.maxValue
                      })
                    )
                  }
                ]
              },
              on: { input: _vm.setCostConf }
            }),
            _c("QSelect", {
              attrs: {
                value: _vm.currency,
                label: _vm.$t("visualization.loopMetrics.currency"),
                options: _vm.currencyOptions
              },
              on: { input: _vm.setCurrency }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }