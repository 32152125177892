var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Models" },
    [
      _c(
        "div",
        { staticClass: "Actions flex row-left-center mb-1" },
        [
          _c(
            "WButton",
            {
              attrs: {
                outline: "",
                color: "primary",
                iconLeft: "upload-cloud"
              },
              on: { click: _vm.handleUploadItem }
            },
            [_vm._v(" " + _vm._s(_vm.$t("projects.models.upload")) + " ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Container" },
        [
          _c("ModelListCards", {
            ref: "table",
            attrs: {
              rows: _vm.models,
              total: _vm.total,
              search: (_vm.filters || {}).search,
              archived: _vm.isProjectArchived,
              loading: _vm.isFetching
            },
            on: {
              onNewItem: _vm.handleUploadItem,
              onDelete: _vm.handleDeleteItem,
              onUpdate: _vm.handleUpdateItem,
              onDownload: _vm.handleDownloadItem,
              onFetchData: _vm.fetchData,
              onVisualization: _vm.handleViewModel
            }
          })
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "deleteDialog",
        attrs: {
          title: _vm.$t("projects.models.delete"),
          text: _vm.$t("projects.models.modals.delete.text", {
            name: (_vm.selected || {}).name
          }),
          loading: _vm.isLoading
        },
        on: { onAccept: _vm.archiveModel }
      }),
      _c("ModalNameDesc", {
        ref: "updateDialog",
        attrs: {
          title: _vm.$t("projects.models.edit"),
          action: _vm.$t("actions.save"),
          loading: _vm.isLoading,
          withoutDesc: ""
        },
        on: { onSubmit: _vm.updateModel }
      }),
      _c("UploadDialog", {
        ref: "uploadDialog",
        on: { import: _vm.uploadModel, rejectedFile: _vm.notifyRejected }
      }),
      _c("ModelPreview", {
        attrs: { show: _vm.bpmnShown, bpmnData: _vm.selected },
        on: { closePreview: _vm.handleCloseModelPreview }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }