<template>
    <div class="InvitationsList">
        <Table
            ref="table"
            :columns="cols"
            :data="normalizeRows"
            :total="total"
            :loading="loading"
            :hideHeader="hideHeader"
            rowKey="uuid"
            class="Table"
            @table-request="handleFetchData"
        >
            <template v-slot:content="{ row }">
                <QTr>
                    <QTd>
                        <Badge
                            :label="$t(`invitations.${row.status}`)"
                            :color="getBadgeType(row.status)"
                        />
                    </QTd>
                    <QTd
                        key="email"
                        class="td-email"
                    >
                        <WText
                            :size="14"
                        >
                            {{ row.email }}
                        </WText>
                    </QTd>
                    <QTd
                        key="date"
                        class="td-date"
                    >
                        <span>
                            {{ App.dateLocationFormat(getDate(row)|date(), 'long') }}
                        </span>
                    </QTd>
                    <QTd
                        key="actions"
                        class="Actions align-right"
                    >
                        <WButton
                            v-if="row.action"
                            color="error"
                            outline
                            size="sm"
                            @click="handleEmmit(row)"
                        >
                            {{ row.action.label }}
                        </WButton>
                    </QTd>
                </QTr>
            </template>

            <template #empty>
                <EmptyList
                    :title="$t('invitations.notifications.noResult.title')"
                    :text="$t('invitations.notifications.noResult.text')"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            class="mt-1"
                            iconLeft="plus-circle"
                            @click="$emit('onNewItem')"
                        >
                            {{ $t('invitations.new') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>
    </div>
</template>

<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { Table, EmptyList, Badge } from '@/components'
import { filtersMixin } from '@/mixins'

const STATES = {
    INVITED: 'INVITED',
    ACCEPTED: 'ACCEPTED',
    CANCELLED: 'CANCELLED',
    REVOKED: 'REVOKED',
}

export default {
    name: 'InvitationsList',
    components: {
        Table,
        EmptyList,
        Badge,
    },
    inject: ['App'],
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
    },
    computed: {
        cols () {
            return [
                ...this.columns,
                {
                    name: 'status',
                    label: this.$t('general.status'),
                    align: 'left',
                },
                {
                    name: 'email',
                    label: this.$t('general.email'),
                    align: 'left',
                    width: 150,
                },
                {
                    name: 'date',
                    label: this.$t('general.createdAt'),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: '',
                    align: 'right',
                },
            ]
        },
        hideHeader () {
            return !(this.rows || []).length
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                ...this.generateOptionsRow(row),
            }))
        },
    },
    methods: {
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        generateOptionsRow ({ status }) {
            return {
                ...(status === STATES.INVITED ? { action: { label: this.$t('invitations.revoke'), event: 'onRevokeInvitation' } } : {}),
                ...(status === STATES.ACCEPTED ? { action: { label: this.$t('invitations.cancel'), event: 'onCancelInvitation' } } : {}),
            }
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        getBadgeType (value) {
            const types = {
                [STATES.INVITED]: 'info',
                [STATES.ACCEPTED]: 'success',
                [STATES.REVOKED]: 'error',
                [STATES.CANCELLED]: 'error',
                default: 'primary',
            }
            return types[value] || types.default
        },
        getDate (row) {
            const types = {
                [STATES.INVITED]: row.invitedAt,
                [STATES.ACCEPTED]: row.acceptedAt,
                [STATES.REVOKED]: row.revokedAt,
                [STATES.CANCELLED]: row.cancelledAt,
            }
            return types[row.status]
        },
        handleEmmit ({ action, uuid }) {
            this.$emit(action.event, uuid)
        },
    },

}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 165px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
