<template>
    <Modal
        :isOpen="opened"
        class="ModalRequest"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ title }}
            </WText>
        </template>
        <template #body>
            <div class="Wrapper">
                <WText
                    v-if="text"
                    class="mt-0"
                >
                    {{ text }}
                </WText>
                <slot />
            </div>
            <div class="flex row-right-center pb-1">
                <WButton
                    id="cancel"
                    color="none"
                    @click="handleClickCancel"
                >
                    {{ cancel }}
                </WButton>
                <WButton
                    id="submit"
                    class="ml-1"
                    :loading="loading"
                    :disabled="loading"
                    @click="handleClickAccept"
                >
                    {{ accept }}
                </WButton>
            </div>
        </template>
    </Modal>
</template>
<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import Modal from '@/components/Modal/Modal.vue'

export default {
    name: 'ModalRequest',
    components: {
        Modal,
    },
    props: {
        title: VueTypes.string,
        text: VueTypes.string,
        cancel: VueTypes.string.def(function () {
            return this.$t('actions.cancel')
        }),
        accept: VueTypes.string.def(function () {
            return this.$t('actions.confirm')
        }),
        loading: VueTypes.bool.def(false),
        autoClose: VueTypes.bool.def(true),
    },
    data () {
        return {
            opened: false,
        }
    },
    methods: {
        open (form = {}) {
            this.opened = true
        },
        close () {
            this.opened = false
            this.$emit('onClose')
        },
        handleClickCancel () {
            if (this.autoClose) this.close()
            this.$emit('onCancel')
        },
        handleClickAccept () {
            if (this.autoClose) this.close()
            this.$emit('onAccept')
        },
    },
}
</script>
<style scoped lang="scss">
.ModalRequest {
    z-index: 99999;
    &::v-deep .Dialog {
        width: 100%;
        max-width: 500px;
    }

    .Wrapper {
        margin: 30px 0;
    }
}
</style>
