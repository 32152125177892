<template>
    <div
        v-if="isOpen"
        class="SlaSettings"
    >
        <div class="SLA">
            <WText
                class="mb-1"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.sla.name') }}
            </WText>
            <div class="OptionsGroup row justify-start">
                <QInput
                    v-model="number"
                    standout
                    outlined
                    class="col"
                    type="number"
                    labelColor="primary"
                    color="primary"
                    :label="$t('visualization.settings.sla.number')"
                    :autogrow="inputOptions"
                />
                <QSelect
                    v-model="unit"
                    outlined
                    class="col"
                    labelColor="primary"
                    color="primary"
                    :label="$t('visualization.settings.sla.unit')"
                    :options="slaUnits"
                />
            </div>
            <div class="row justify-end mt-1">
                <WButton
                    class="buttons"
                    outline
                    type="submit"
                    color="primary"
                    @click="saveSLA"
                >
                    {{ $t('visualization.filters.save') }}
                </WButton>
            </div>
        </div>
    </div>
</template>

<script>
import { QInput } from 'quasar'
import VueTypes from 'vue-types'

export default {
    name: 'SlaSettings',
    emits: ['SLAConfig'],
    components: { QInput },
    mixins: [],
    props: {
        isOpen: VueTypes.bool.def(false),
        values: VueTypes.object,
    },
    data () {
        return {
            inputOptions: false,
            unit: { value: 'minutes', label: this.$t('visualization.settings.sla.units.minutes') },
            number: 0,
            timeout: null,
        }
    },
    computed: {
        slaUnits () {
            return [
                {
                    label: this.$t('visualization.settings.sla.units.minutes'),
                    value: 'minutes',
                },
                {
                    label: this.$t('visualization.settings.sla.units.hours'),
                    value: 'hours',
                },
                {
                    label: this.$t('visualization.settings.sla.units.days'),
                    value: 'days',
                },
                {
                    label: this.$t('visualization.settings.sla.units.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.$t('visualization.settings.sla.units.months'),
                    value: 'months',
                },
            ]
        },
    },
    watch: {
    },
    beforeMount () {
    },
    mounted () {
        this.checkIfValues()
    },
    methods: {
        checkIfValues () {
            if (this.values) {
                this.unit.value = this.values.unit
                this.number = this.values.value
                this.unit.label = this.getLabelByValue(this.unit.value)
            }
        },
        saveSLA () {
            const sla = {
                unit: this.unit.value ? this.unit.value : this.unit,
                value: this.number,
            }
            this.$emit('SLAConfig', { data: sla, type: 'sla' })
        },
        getLabelByValue (value) {
            const option = this.slaUnits.find(opt => opt.value === value)
            return option ? option.label : ''
        },
    },
}
</script>
<style lang="scss" scoped>
.SlaSettings {
    top: $subheader-height;
    min-height: 300px;
    max-width: 750px;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    width: 60%;
    .SLA {
        margin: 20px;
    }
    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 20px;
    }

    .buttons {
        width: 80px;
    }

    .ButtonContainer {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
