<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('visualization.renameVariantModal.title') }}
            </WText>
        </template>
        <template #body>
            <QForm
                class="align-center q-gutter-md q-ma-md"
                onSubmit="return false;"
                @submit="handleRenameVariant"
            >
                <QInput
                    ref="name"
                    v-model="newVariantName"
                    :label="$t('visualization.renameVariantModal.name')"
                    outlined
                    stackLabel
                    dense
                    :rules="[val => !!val || $t('visualization.variantRenameRequired'),
                             val => (val.length >= 1 && val.length <= 100) || $t('visualization.variantRenameLengthError')]"
                />
                <QBtn
                    :label="$t('visualization.renameVariantModal.submit')"
                    noCaps
                    color="primary"
                    unelevated
                    @click="handleRenameVariant"
                />
            </QForm>
        </template>
    </Modal>
</template>

<script>
import { Modal } from '@/components'

export default {
    name: 'Rename',
    emits: [
        'save',
    ],
    components: {
        Modal,
    },
    data () {
        return {
            opened: false,
            newVariantName: undefined,
        }
    },
    computed: {
    },
    methods: {
        open () {
            this.reset()
            this.opened = true
        },
        close () {
            this.opened = false
        },
        reset () {
            this.newVariantName = undefined
        },
        handleRenameVariant () {
            if (this.$refs.name.validate()) {
                this.$emit('save', {
                    newVariantName: this.newVariantName,
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &::v-deep .Dialog {
        width: 100%;
        max-width: 400px;
    }
}
</style>
