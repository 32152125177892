export default {
    organization: 'Grupo de trabajo',
    project: 'Proyecto',
    dataset: 'Fuente',
    report: 'Informe',
    visualization: 'Visualización',
    download: 'Descargar zip',
    events: 'Eventos',
    processing: 'Procesando...',

    date: 'Fecha',
    createdAt: 'Creado',
    updateDate: 'Última actualización',
    deleteDate: 'Eliminado',
    startAt: 'Inicio',
    endAt: 'Final',
    seenAt: 'Visto',
    content: 'Contenido',

    code: 'Código',
    title: 'Título',
    name: 'Nombre',
    description: 'Descripción',
    status: 'Estado',
    model: 'Modelo',
    email: 'Correo electrónico',
    amount: 'Cantidad',
    actions: 'Acciones',
    type: 'Tipo',
    file: 'Fichero',
    characters: 'Caracteres',

    readOnly: 'Solo lectura',
    archived: 'Archivados',
    optional: 'Opcional',
    uploadData: 'Subir fichero',
    comingSoon: 'Próximamente',

    account: 'Cuenta',
    apis: 'APIs',
    signout: 'Cerrar sesión',
}
