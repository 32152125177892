export default {
    required: '{attribute} es obligatorio.',
    integer: '{attribute} debe ser un número entero.',
    numeric: '{attribute} debe ser un número.',
    decimal: '{attribute} debe ser un número decimal.',
    minValue: '{attribute} debe ser mayor que {min}.',
    maxValue: '{attribute} debe ser menor que {max}.',
    minLength: '{attribute} debe ser mayor que {min}.',
    maxLength: '{attribute} debe ser menor que {max}.',
    rangeLength: 'Deber incluir entre {min} y {max} caracteres.',
    between: 'Deber estar entre {min} y {max}.',
    email: '{attribute} formato de e-mail inválido.',
    sameAs: '{attribute} debe ser igual que {eq}.',
    requiredUppercase: '{attribute} debe incluir una letra mayúscula.',
    allowedChars: '{attribute} sólo puede incluir números, letras, espacios, guiones y barras bajas.',
}
