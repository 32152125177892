import MockAdapter from 'axios-mock-adapter'
import { get } from 'lodash'
import Config from '@/config'
import Setup from './setup'
import { normalizeMocks } from './utils'

let mock = null

const mocks = {}
export const defaultResponses = {}

const generateMockData = ({ name, endpoints }) => {
    if (!mock) {
        console.warn(`[MockResponse] Mock is not initialized.`)
        return
    }
    const { mocks: mocksObject, defaultResponses: defaultResponsesObject } = normalizeMocks(endpoints)
    if (!name || !mocks || !defaultResponses) {
        console.warn(`[MockResponse] Mock has not exists.`)
        return
    }
    mocks[name] = {}
    defaultResponses[name] = {}
    Object.keys(mocksObject).forEach((key) => {
        if (!defaultResponsesObject[key]) {
            console.warn(`[MockResponse] Mock item has no response by default.`)
            return
        }
        mocks[name][key] = mocksObject[key](mock)
        defaultResponses[name][key] = defaultResponsesObject[key]
    })
}

export const mockResponse = (key, response) => {
    if (!key) {
        console.warn(`[MockResponse] Key is required.`)
        return null
    }
    const mockInstance = get(mocks, key)
    if (!mockInstance) {
        console.warn(`[MockResponse] The key '${key}' has no mock.`)
        return null
    }
    if (response) {
        const [status, data] = response
        return mockInstance.reply(status, data)
    }
    const defaultResponse = get(defaultResponses, key)
    if (!defaultResponse) {
        console.warn(`[MockResponse] The key '${key}' has no response by default.`)
        return null
    }
    return defaultResponse(mockInstance)
}

export default (axios) => {
    // This sets the mock adapter on the default instance
    mock = new MockAdapter(axios, { delayResponse: !Config.isTest ? 500 : 0 })

    // Init mocks
    Setup.mocks.map(generateMockData)

    if (Config.useMock) {
        // Init default responses
        Setup.endpoints.map(e => mockResponse(e))
        mock.onAny().passThrough()
    }
}
