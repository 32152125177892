import Transforms from './transforms'

const endpoints = {
    list: {
        path: '/conformance-queries',
        transform: Transforms.list,
    },
    create: {
        method: 'POST',
        path: `/conformance-queries`,
    },
    modes: {
        path: `/conformance-queries/modes`,
        transform: Transforms.modes,
    },
    modeReportTypes: {
        path: `/conformance-queries/modes/:modeId/report-types`,
        transform: Transforms.modeReportTypes,
    },
    get: {
        path: `/conformance-queries/:queryId`,
        transform: Transforms.get,
    },
    update: {
        method: 'PUT',
        path: `/conformance-queries/:queryId`,
    },
    delete: {
        method: 'DELETE',
        path: `/conformance-queries/:queryId`,
    },
    cancel: {
        method: 'POST',
        path: `/conformance-queries/:queryId/cancel`,
    },
    download: {
        path: `/conformance-queries/:queryId/download`,
    },
}

export default {
    name: 'queries',
    endpoints,
}
