<template>
    <div>
        <div
            style="display:flex;"
            class="selectContainer"
        >
            <QSelect
                v-model="attribute"
                dense
                class="attrSelect"
                :options="optionAttributes"
                :label="$t('visualization.customPlotPanels.attributesInfo.selectAttribute')"
                @input="getData"
            />
            <div style="width:auto">
                <WIcon
                    key="dropdown"
                    icon="more-vertical"
                    :size="30"
                    class="openIcon"
                    color="primary"
                    clickable
                    @click="setSelected"
                />
                <Dropdown
                    v-if="selected"
                    v-model="title"
                    direction="bottomRight"
                    :opened="!!selected"
                    :options="metricOptions"
                    @onClick="handleClickOption"
                />
            </div>
        </div>
        <div class="flex flex-center q-ma-xs">
            <WText
                :size="14"
                style="margin-right:10px"
            >
                {{ $t('visualization.customPlotPanels.attributesInfo.sortBy') }}
            </WText>
            <QBtnToggle
                v-model="currentSel.value"
                toggleColor="primary"
                noCaps
                rounded
                padding="2px 10px"
                :options="freqDur"
                @input="getData"
            />
        </div>
        <div class="chart-container">
            <v-chart
                :option="barChartOptions"
                class="chart"
                autoresize
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { filtersMixin } from '@/mixins'
import { Dropdown } from '@/components'
import {
    Api,
    apiRequest,
    notifyError,
} from '@/api'

export default {
    name: 'AttributeInformationPanel',
    emits: ['title'],
    components: { Dropdown },
    mixins: [filtersMixin],
    props: {
        currentTitle: VueTypes.object,
        attributes: VueTypes.array,
        processId: VueTypes.string,
        filters: VueTypes.array,
        chart: VueTypes.object,
    },
    data () {
        return {
            title: this.currentTitle,
            selected: false,
            range: '',
            model: null,
            modifiedFilters: [],
            duration: null,
            stringOptions: '',
            metricOptions: [{
                key: 'FD', label: this.$t('visualization.customPlotPanels.frequencyAndDuration'), disabled: false, icon: 'timelapse',
            },
            {
                key: 'AI', label: this.$t('visualization.customPlotPanels.attributeInfo'), disabled: true, icon: 'query_stats',
            }],
            attribute: null,
            optionAttributes: [],
            maxDur: 'days',
            currentSel: {
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            barChartOptions: {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div style="max-width: 15em;">'
                        tooltipContent += `${this.lineBreak(params[0].name)}`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            if (seriesName === this.$t('visualization.customPlotPanels.duration')) {
                                tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]',
                                    { largest: 2, trim: false })} <br>`
                            } else {
                                tooltipContent += `${seriesName}: ${value} <br>`
                            }
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 8
                            if (value.length > maxLength) {
                                return `${value.slice(0, maxLength) }...`
                            }
                            return `${value}`
                        },
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => this.formatNumber(value),
                        },
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: value => `${this.formatNumber(value)}${this.range}`,
                        },
                    },
                ],
                dataZoom: [
                    {
                        type: 'inside',
                        show: true,
                        start: 0,
                        end: 100,
                        handleSize: 8,
                    },
                ],
                grid: {
                    left: 45,
                    right: 45,
                    bottom: 23,
                    top: 20,
                },
                series: [
                    {
                        name: this.$t('visualization.customPlotPanels.frequency'),
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            color: '#6C97BE',
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: this.$t('visualization.customPlotPanels.duration'),
                        type: 'line',
                        data: [],
                        itemStyle: {
                            color: '#33699A',
                        },
                        yAxisIndex: 1,
                    },
                ],
            },
        }
    },
    computed: {
        freqDur () {
            return [{
                value: 'FREQUENCY',
                label: this.$t('visualization.customPlotPanels.frequency'),
            },
            {
                value: 'DURATION',
                label: this.$t('visualization.customPlotPanels.duration'),
            }]
        },
    },
    watch: {
        filters (newValue, oldValue) {
            let equal = false
            if (newValue.length === oldValue.length) {
                if (newValue.length === 0) {
                    equal = true
                } else {
                // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < newValue.length; i++) {
                        if (newValue[i] !== oldValue[i]) {
                            equal = true
                        }
                    }
                }
            }
            if (equal === false) {
                this.getData()
            }
        },
        chart (newValue, oldValue) {
            this.getData()
        },
    },
    mounted () {
        this.attrSel()
    },
    methods: {
        attrSel () {
            const optionAttr = this.attributes.filter(item => item.type === 'STRING')
            this.optionAttributes = optionAttr.map(item => item.name)
            const attr = this.optionAttributes[0]
            this.attribute = attr
            this.getData()
        },
        formatNumber (num) {
            switch (true) {
                case num >= 1000000:
                    return `${(num / 1000000).toFixed(1)}M`
                case num >= 100000:
                    return `${(num / 100000).toFixed(0)}k`
                case num >= 1000:
                    return `${(num / 1000).toFixed(1)}k`
                default:
                    return num.toString()
            }
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.range = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.range = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.range = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.range = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        setSelected () {
            this.selected = !this.selected
        },
        handleClickOption (sel) {
            this.$emit('title', sel)
            this.setSelected()
        },
        getData () {
            const data = {
                attribute: this.attribute,
                sortBy: this.currentSel.value,
                eventAttribute: false,
                filters: this.filters ? this.filters : [],
                limit: 8,
                start: 0,
            }
            if (this.chart.variants) {
                data.variantCount = this.chart.variants
            }
            if (this.chart.variantId) {
                data.variantUid = this.chart.variantId
            }
            if (this.chart.loopId || this.chart.loopId === 0) {
                data.loopUid = this.chart.loopId
            }
            apiRequest(Api().visualizations.attributePanel({ processId: this.processId, params: data }))
                .then((returnData) => {
                    this.maxDur = this.rangeDuration(Math.max.apply(null, [returnData.rows.map(item => (item.duration))][0]))
                    const frequencyArr = [returnData.rows.map(item => item.frequency)][0]
                    const commonAttributes = [returnData.rows.map(item => item.attribute)][0]
                    const durationArr = [returnData.rows.map(item => this.formatSeconds(item.duration, this.maxDur))][0]
                    this.barChartOptions.series[0].data = frequencyArr
                    this.barChartOptions.series[1].data = durationArr
                    this.barChartOptions.xAxis.data = commonAttributes
                })
                .catch(() => {
                    notifyError()
                    this.barChartOptions.series[0].data = []
                    this.barChartOptions.series[1].data = []
                    this.barChartOptions.xAxis.data = []
                })
                .finally(() => {
                })
        },
        lineBreak (inputString) {
            let resultado = ''
            for (let i = 0; i < inputString.length; i += 22) {
                const linea = inputString.substr(i, 22)
                resultado += `${linea }<br>`
            }
            return resultado
        },
    },
}
</script>
<style lang="scss" scoped>
  .attrSelect{
    width: 17em;
    height: auto;
    margin: 1em;
    margin-top: 0em;
    margin-bottom: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .chart-container {
    margin: 0 auto;
    overflow: auto;
    .chart {
        width: 285px;
        height: 180px;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
  .openIcon {
    height: fit-content;
    padding-bottom: 0px;
    margin-left: 0 auto;
  }
}
</style>
