var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Wizard" },
    [
      _vm.config.steps
        ? _c(
            "div",
            {
              staticClass:
                "Header flex row-stretch-center pt-0-5 pb-0-5 pl-1 pr-1 mb-1"
            },
            [
              _c(
                "div",
                { staticClass: "Left flex row-center" },
                [
                  _vm.config.exit
                    ? _c(
                        "WButton",
                        {
                          attrs: { outline: "" },
                          on: { click: _vm.handleClickExit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("wizard.cancel")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-1 row-center" },
                [
                  _vm.config.title
                    ? _c(
                        "WText",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            tag: "h2",
                            weight: "semi",
                            color: "primary",
                            size: 18
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.config.title) + " ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.step
                ? _c(
                    "div",
                    { staticClass: "Right flex row-center" },
                    [
                      _c(
                        "Transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [
                          _vm.$DEV && _vm.step.mock
                            ? _c(
                                "div",
                                { staticClass: "Mock pr-1 mr-1" },
                                [
                                  _c(
                                    "WButton",
                                    {
                                      attrs: {
                                        outline: "",
                                        disabled: _vm.config.isNavigate
                                      },
                                      on: { click: _vm.config.mockStep }
                                    },
                                    [_vm._v(" Mock ")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "Transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [
                          _vm.step.prev
                            ? _c(
                                "WButton",
                                {
                                  staticClass: "mr-1",
                                  attrs: {
                                    outline: "",
                                    disabled: _vm.config.isNavigate
                                  },
                                  on: { click: _vm.config.prevStep }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("wizard.back")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "Transition",
                        { attrs: { name: "fade", mode: "out-in" } },
                        [
                          _vm.step.next && !_vm.step.end
                            ? _c(
                                "WButton",
                                {
                                  attrs: { disabled: _vm.config.isNavigate },
                                  on: { click: _vm.config.nextStep }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("wizard.continue")) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.step.end
                            ? _c(
                                "WButton",
                                {
                                  attrs: { disabled: _vm.config.isNavigate },
                                  on: { click: _vm.config.nextStep }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("wizard.finish")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              staticClass: "row justify-between q-pa-sm",
              staticStyle: { "font-size": "xx-large" }
            },
            [
              _c("h5", { staticClass: "q-px-xl" }, [
                _vm._v(" " + _vm._s(_vm.datasetName) + " ")
              ]),
              _c("QIcon", {
                staticStyle: { cursor: "pointer" },
                attrs: { name: "close" },
                on: {
                  click: function($event) {
                    return _vm.handleExitNoConfig()
                  }
                }
              })
            ],
            1
          ),
      _vm.projectId
        ? _c(
            "Transition",
            { attrs: { name: "main-router", mode: "out-in" } },
            [
              _c("RouterView", {
                key: _vm.projectId,
                staticClass: "Screen",
                attrs: { projectId: _vm.projectId, datasetId: _vm.datasetId },
                on: { onChangeDatasetId: _vm.handleChangeDatasetId }
              })
            ],
            1
          )
        : _vm._e(),
      _c("ModalRequest", {
        ref: "exitDialog",
        attrs: {
          title: _vm.config.title,
          text: _vm.$t("wizard.modals.cancel.text")
        },
        on: { onAccept: _vm.handleExit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }