var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Step" },
    [
      _c(
        "QForm",
        { ref: "form" },
        [
          _c(
            "div",
            { staticClass: "Wrapper flex col-stretch mb-0-5" },
            [
              _c("QInput", {
                staticClass: "Search",
                attrs: {
                  value: _vm.search,
                  label: _vm.$t("actions.search"),
                  outlined: "",
                  type: "search",
                  disable: _vm.isLoading
                },
                on: { input: _vm.handleFilter },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _vm.search
                          ? _c("WIcon", {
                              attrs: { icon: "close", size: 16, clickable: "" },
                              on: { click: _vm.handleCleanSearch }
                            })
                          : _vm._e(),
                        _c("WIcon", {
                          attrs: {
                            icon: "search1",
                            size: 20,
                            color: "grayMedium",
                            clickable: ""
                          },
                          on: { click: _vm.handleFilter }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "Workflows flex flex-nowrap col-top-stretch mb-1" },
            [
              _c(
                "Transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _vm.isLoading
                    ? _c("WLoading", { attrs: { visible: "" } })
                    : _c(
                        "fragment",
                        [
                          !(_vm.options || []).length
                            ? _c(
                                "div",
                                { staticClass: "Empty flex row-center" },
                                [
                                  _c(
                                    "EmptyList",
                                    _vm._b(
                                      { staticClass: "mt-2 mb-2" },
                                      "EmptyList",
                                      _vm.$t(
                                        "wizard.connection.notifications.empty"
                                      ),
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._l(_vm.options, function(option, index) {
                            return _c("QCheckbox", {
                              key: "option-" + index,
                              staticClass: "Checkbox pl-1 pr-1 pt-0-5 pb-0-5",
                              attrs: {
                                value: _vm.checkedOptions(option.key),
                                label: option.label,
                                color: "secondary"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.handleInput(option, $event)
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                ],
                1
              )
            ],
            1
          ),
          _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
            (((_vm.value || {}).workflow || {}).workflows || []).length &&
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "Wrapper Tags flex mb-1" },
                  _vm._l(_vm.value.workflow.workflows, function(tag, index) {
                    return _c(
                      "div",
                      {
                        key: "tag-" + index,
                        staticClass: "Tag flex row-center mr-0-5 mb-0-5"
                      },
                      [
                        _c(
                          "WText",
                          { attrs: { color: "white", weight: "semi" } },
                          [_vm._v(" " + _vm._s(tag.label) + " ")]
                        ),
                        _c("WIcon", {
                          staticClass: "ml-0-5",
                          attrs: {
                            size: 18,
                            icon: "x",
                            color: "white",
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleInput(tag, false)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm.enabledFilters
            ? _c("fragment", [
                _c("div", { staticClass: "Separator" }),
                _c(
                  "div",
                  {
                    staticClass: "Wrapper row justify-center items-start mt-0-5"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-1 col-top-left pl-1 pr-1" },
                      [
                        _c("QCheckbox", {
                          attrs: {
                            label: _vm.$t("wizard.connection.filterTraces")
                          },
                          model: {
                            value: ((_vm.value || {}).workflow || {}).traces,
                            callback: function($$v) {
                              _vm.$set(
                                (_vm.value || {}).workflow || {},
                                "traces",
                                $$v
                              )
                            },
                            expression: "((value || {}).workflow || {}).traces"
                          }
                        }),
                        _c("QCheckbox", {
                          attrs: {
                            label: _vm.$t("wizard.connection.filterEvents")
                          },
                          model: {
                            value: ((_vm.value || {}).workflow || {}).events,
                            callback: function($$v) {
                              _vm.$set(
                                (_vm.value || {}).workflow || {},
                                "events",
                                $$v
                              )
                            },
                            expression: "((value || {}).workflow || {}).events"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }