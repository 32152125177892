var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "WCard",
        { staticClass: "ModelCard", attrs: { flat: "", bordered: "" } },
        [
          _c(
            "div",
            { staticClass: "flex row-left-top flex-nowrap q-pa-sm h-full" },
            [
              _c(
                "div",
                {
                  staticClass: "AvatarTitle cursor-pointer",
                  on: { click: _vm.handleGoToVisualization }
                },
                [_c("p", [_vm._v(_vm._s(_vm.getInitial(_vm.row.modelName)))])]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "DatasetContent q-px-sm flex-1 flex flex-col col-between-left h-full cursor-pointer",
                  on: { click: _vm.handleGoToVisualization }
                },
                [
                  [
                    _c(
                      "p",
                      { staticClass: "DatasetCardName text-weight-bold" },
                      [_vm._v(" " + _vm._s(_vm.row.modelName) + " ")]
                    )
                  ],
                  _c(
                    "div",
                    { staticClass: "flex items-center q-mt-sm" },
                    [
                      _c("QBadge", {
                        staticClass: "q-pa-sm q-mr-sm",
                        attrs: {
                          label: _vm.sourceLabel,
                          color: _vm.sourceColor,
                          textColor: "white"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _c("WIcon", {
                key: "dropdown",
                attrs: {
                  icon: "more-vertical",
                  size: 24,
                  color: "primary",
                  clickable: ""
                },
                on: {
                  click: function($event) {
                    return _vm.setSelected(_vm.row, $event)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.selected
        ? _c("Dropdown", {
            attrs: {
              opened: !!_vm.selected,
              options: (_vm.selected.options || {}).dropdown,
              coords: _vm.selected.coords
            },
            on: { onClose: _vm.cleanSelected, onClick: _vm.handleClickOption }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }