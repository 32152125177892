import {
    Organizations, Events, Account, Doc,
} from '@/screens'

import Dashboard from '@/layouts/Dashboard.vue'

export default meta => [
    {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        redirect: { name: 'Organizations' },
        children: [
            {
                path: 'organizations',
                name: 'Organizations',
                component: Organizations,
                meta: {
                    header: true,
                    search: true,
                },
            },
            {
                path: 'events',
                name: 'Events',
                component: Events,
            },
            {
                path: 'account',
                name: 'Account',
                component: Account,
            },
            {
                path: 'doc',
                name: 'Doc',
                component: Doc,
            },
        ],
    },
]
