const endpoints = {
    portalSession: {
        path: '/payments/portal-session',
        transform: [200],
    },
    activateSubscription: {
        method: 'POST',
        path: '/payments/subscription/activate',
        transform: [200],
    },
    cancelSubscription: {
        method: 'POST',
        path: '/payments/subscription/cancel',
        transform: [200],
    },
}

export default {
    name: 'payments',
    endpoints,
}
