var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "chat-box",
      on: {
        submit: function($event) {
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("QInput", {
        staticClass: "EnterText",
        attrs: {
          placeholder: _vm.$t("assistant.writeMessagePlaceholder"),
          type: "text"
        },
        model: {
          value: _vm.text,
          callback: function($$v) {
            _vm.text = $$v
          },
          expression: "text"
        }
      }),
      _c(
        "QBtn",
        {
          attrs: {
            disabled: _vm.text === "",
            noCaps: "",
            unelevated: "",
            color: "primary"
          },
          on: {
            click: function($event) {
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "row items-center no-wrap" },
            [
              _c("div", { staticClass: "text-center" }, [
                _vm._v(" " + _vm._s(_vm.$t("assistant.send")) + " ")
              ]),
              _c("QIcon", { attrs: { right: "", name: "send" } })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }