var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("QTable", {
        staticClass: "my-sticky-header-table",
        attrs: {
          data: _vm.rows,
          columns: _vm.columns,
          rowKey: "name",
          flat: "",
          filter: _vm.filter,
          outlined: "",
          rowsPerPageOptions: _vm.rowsPerPageOptions,
          separator: "vertical",
          bordered: "",
          wrapCells: "",
          loading: _vm.loading,
          pagination: _vm.pagination
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          },
          request: _vm.emitRequest
        },
        scopedSlots: _vm._u([
          {
            key: "body-cell-start",
            fn: function(props) {
              return [
                _c("QTd", { attrs: { props: props } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.App.dateLocationFormat(props.value, "long")) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "body-cell-end",
            fn: function(props) {
              return [
                _c("QTd", { attrs: { props: props } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.App.dateLocationFormat(props.value, "long")) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "top-left",
            fn: function() {
              return [
                _c(
                  "WText",
                  { attrs: { size: 14, color: "primary" } },
                  [
                    _c(
                      "WText",
                      {
                        attrs: {
                          size: 16,
                          tag: "span",
                          weight: "bold",
                          color: "primary"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("visualization.traces.table.total", {
                                total: _vm.total,
                                filteredTraces: _vm.filteredTraces
                              })
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("visualization.traces.table.totalDesc")) +
                        " "
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "top-right",
            fn: function() {
              return [
                _c("QBtn", {
                  staticClass: "q-mr-sm",
                  attrs: {
                    color: "primary",
                    icon: "scatter_plot",
                    unelevated: "",
                    noCaps: "",
                    label: _vm.$t("visualization.traces.toPlot")
                  },
                  on: { click: _vm.switchGraphicalView }
                }),
                _c(
                  "QBtn",
                  {
                    staticClass: "q-mr-sm",
                    attrs: {
                      color: "primary",
                      icon: "add_circle",
                      noCaps: "",
                      outline: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.prompt = true
                      }
                    }
                  },
                  [
                    _c("QTooltip", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("visualization.traces.toDataSet")) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "QBtn",
                  {
                    staticClass: "q-mr-sm",
                    attrs: {
                      color: "primary",
                      icon: "archive",
                      noCaps: "",
                      outline: ""
                    },
                    on: { click: _vm.downloadData }
                  },
                  [
                    _c("QTooltip", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("visualization.traces.downloadCSV")) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "QInput",
                  {
                    staticStyle: { width: "220px" },
                    attrs: {
                      dense: "",
                      debounce: "300",
                      color: "primary",
                      outlined: "",
                      clearable: "",
                      clearIcon: "close",
                      placeholder: _vm.$t(
                        "visualization.traces.table.search.placeholder"
                      )
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  },
                  [
                    _c("QIcon", {
                      attrs: { slot: "append", name: "search" },
                      slot: "append"
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "no-data",
            fn: function(ref) {
              var message = ref.message
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "full-width row flex-center text-accent q-gutter-sm"
                  },
                  [_c("span", [_vm._v(" " + _vm._s(message) + " ")])]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "QDialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.prompt,
            callback: function($$v) {
              _vm.prompt = $$v
            },
            expression: "prompt"
          }
        },
        [
          _c(
            "QCard",
            { staticStyle: { "min-width": "350px" } },
            [
              _c(
                "QToolbar",
                [
                  _c("QToolbarTitle", { staticClass: "text-subtitle1" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("visualization.traces.createDataSet")) +
                        " "
                    )
                  ]),
                  _c("QBtn", {
                    attrs: { flat: "", round: "", dense: "", icon: "close" },
                    on: {
                      click: function($event) {
                        _vm.prompt = false
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "QCardSection",
                { staticClass: "q-pt-none" },
                [
                  _c("QInput", {
                    ref: "newDatasetName",
                    attrs: {
                      dense: "",
                      autofocus: "",
                      rules: [
                        function(val) {
                          return (
                            !!val || _vm.$t("visualization.traces.nameRequired")
                          )
                        },
                        function(val) {
                          return (
                            val.length > 2 ||
                            _vm.$t("visualization.traces.nameLength")
                          )
                        }
                      ]
                    },
                    model: {
                      value: _vm.newDatasetName,
                      callback: function($$v) {
                        _vm.newDatasetName = $$v
                      },
                      expression: "newDatasetName"
                    }
                  })
                ],
                1
              ),
              _c(
                "QCardActions",
                { staticClass: "text-primary", attrs: { align: "right" } },
                [
                  _c("QBtn", {
                    attrs: {
                      flat: "",
                      noCaps: "",
                      label: _vm.$t("visualization.traces.cancel")
                    },
                    on: {
                      click: function($event) {
                        _vm.prompt = false
                      }
                    }
                  }),
                  _c("QBtn", {
                    attrs: {
                      color: "primary",
                      unelevated: "",
                      noCaps: "",
                      label: _vm.$t("visualization.traces.create")
                    },
                    on: { click: _vm.createDataset }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }