import { noop } from 'lodash'

const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
    inject: ['Wizard'],
    data () {
        const settings = (this.$options.wizard || noop).call(this)
        const cached = this.getStorageWizard() || {}
        const { config, data, mocks } = settings || {}

        return {
            config: { ...(cached.config || config) },
            data: { ...(cached.data || data) },
            mocks,
        }
    },
    beforeMount () {
        this.Wizard.store = {
            config: {
                ...(this.config || {}),
                isNavigate: false,
                exit: this.exitWizard,
                mockStep: this.mockStep,
                prevStep: this.prevStep,
                nextStep: this.nextStep,
            },
        }
    },
    computed: {
        step () {
            return (this.Wizard.store.config || {}).step
        },
        steps () {
            return (this.Wizard.store.config || {}).steps || []
        },
        currentStep () {
            return this.steps.find(s => s.name === this.step)
        },
        currentIndex () {
            return this.steps.findIndex(s => s.name === this.step)
        },
        component () {
            const [instance] = this.$refs[this.step] || []
            return instance
        },
    },
    methods: {
        /**
         * Manager
         */
        async prevStep () {
            const { prev } = this.currentStep
            const fn = () => this.goToStep(this.steps[this.currentIndex - 1]?.name)
            this.setWizard({ isNavigate: true })

            // Manage prev method middleware
            if (typeof prev === 'string' && this[prev]) {
                const { ok, data, success } = await this[prev]() || {}
                if (ok) {
                    if (data) this.setData(data)
                    success && typeof success === 'function' ? success() : fn()
                }
            } else {
                fn()
            }

            await sleep(500)
            this.setWizard({ isNavigate: false })
        },
        async nextStep () {
            const { next } = this.currentStep
            const fn = () => this.goToStep(this.steps[this.currentIndex + 1]?.name)
            this.setWizard({ isNavigate: true })

            // Manage next method middleware
            if (typeof next === 'string' && this[next]) {
                const { ok, data, success } = await this[next]() || {}
                if (ok) {
                    if (data) this.setData(data)
                    success && typeof success === 'function' ? success() : fn()
                }
            } else {
                fn()
            }

            await sleep(500)
            this.setWizard({ isNavigate: false })
        },
        async goToStep (step) {
            this.setWizard({ step })
        },
        async mockStep () {
            const { mocks } = this
            const { mock } = this.currentStep
            if (typeof mock === 'string' && (mocks || {})[mock]) {
                this.setData({ [mock]: (mocks || {})[mock] })
            }
        },
        async exitWizard () {
            if (this.exit) this.exit()
        },
        /**
         * Storage
         */
        getStorageWizard () {
            return this.$storage.get(this.storageKey)
        },
        setStorageWizard (data) {
            return this.$storage.set(this.storageKey, data)
        },
        resetStorageWizard (data) {
            return this.$storage.remove(this.storageKey)
        },
        /**
         * Wizard Instance
         */
        resetWizard () {
            this.Wizard.store = {}
            this.resetStorageWizard()
        },
        setWizard (options) {
            const { store = {} } = this.Wizard
            const config = {
                ...(store.config || {}),
                ...(options || {}),
            }
            this.Wizard.store = { config }
            this.setStorageWizard({ config, data: this.data })
        },
        /**
         * Wizard Data
         */
        setData (data = {}) {
            const newData = {
                ...(this.data || {}),
                ...data,
            }

            this.data = newData
            this.setStorageWizard({ config: this.config, data: newData })
        },
    },
}
