<template>
    <div>
        <QTable
            class="my-sticky-header-table"
            :data="rows"
            :columns="columns"
            rowKey="name"
            flat
            :filter="filter"
            outlined
            :rowsPerPageOptions="rowsPerPageOptions"
            separator="vertical"
            bordered
            wrapCells
            :loading="loading"
            :pagination.sync="pagination"
            @request="emitRequest"
        >
            <template v-slot:body-cell-start="props">
                <QTd :props="props">
                    {{ App.dateLocationFormat(props.value,'long') }}
                </QTd>
            </template>
            <template v-slot:body-cell-end="props">
                <QTd :props="props">
                    {{ App.dateLocationFormat(props.value, 'long') }}
                </QTd>
            </template>
            <template v-slot:top-left>
                <WText
                    :size="14"
                    color="primary"
                >
                    <WText
                        :size="16"
                        tag="span"
                        weight="bold"
                        color="primary"
                    >
                        {{ $t('visualization.traces.table.total', { total, filteredTraces }) }}
                    </WText>
                    {{ $t('visualization.traces.table.totalDesc') }}
                </WText>
            </template>
            <template v-slot:top-right>
                <QBtn
                    color="primary"
                    icon="scatter_plot"
                    unelevated
                    noCaps
                    :label="$t('visualization.traces.toPlot')"
                    class="q-mr-sm"
                    @click="switchGraphicalView"
                />
                <QBtn
                    color="primary"
                    icon="add_circle"
                    noCaps
                    outline
                    class="q-mr-sm"
                    @click="prompt = true"
                >
                    <QTooltip>
                        {{ $t('visualization.traces.toDataSet') }}
                    </QTooltip>
                </QBtn>
                <QBtn
                    color="primary"
                    icon="archive"
                    noCaps
                    outline
                    class="q-mr-sm"
                    @click="downloadData"
                >
                    <QTooltip>
                        {{ $t('visualization.traces.downloadCSV') }}
                    </QTooltip>
                </QBtn>
                <QInput
                    v-model="filter"
                    dense
                    debounce="300"
                    color="primary"
                    style="width: 220px"
                    outlined
                    clearable
                    clearIcon="close"
                    :placeholder="$t('visualization.traces.table.search.placeholder')"
                >
                    <QIcon
                        slot="append"
                        name="search"
                    />
                </QInput>
            </template>
            <template v-slot:no-data="{ message }">
                <div class="full-width row flex-center text-accent q-gutter-sm">
                    <span>
                        {{ message }}
                    </span>
                </div>
            </template>
        </QTable>
        <QDialog
            v-model="prompt"
            persistent
        >
            <QCard
                style="min-width: 350px"
            >
                <QToolbar>
                    <QToolbarTitle
                        class="text-subtitle1"
                    >
                        {{ $t('visualization.traces.createDataSet') }}
                    </QToolbarTitle>
                    <QBtn
                        flat
                        round
                        dense
                        icon="close"
                        @click="prompt = false"
                    />
                </QToolbar>
                <QCardSection class="q-pt-none">
                    <QInput
                        ref="newDatasetName"
                        v-model="newDatasetName"
                        dense
                        autofocus
                        :rules="[val => !!val || $t('visualization.traces.nameRequired'),
                                 val => val.length > 2 || $t('visualization.traces.nameLength')]"
                    />
                </QCardSection>
                <QCardActions
                    align="right"
                    class="text-primary"
                >
                    <QBtn
                        flat
                        noCaps
                        :label="$t('visualization.traces.cancel')"
                        @click="prompt = false"
                    />
                    <QBtn
                        color="primary"
                        unelevated
                        noCaps
                        :label="$t('visualization.traces.create')"
                        @click="createDataset"
                    />
                </QCardActions>
            </QCard>
        </QDialog>
    </div>
</template>

<script>
import { exportFile } from 'quasar'
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'

function wrapCsvValue (val, formatFn) {
    let formatted = formatFn !== undefined
        ? formatFn(val)
        : val

    formatted = formatted === undefined || formatted === null
        ? ''
        : String(formatted)

    formatted = formatted.split('"').join('""')
    /**
    * Excel accepts \n and \r in strings, but some other CSV parsers do not
    * Uncomment the next two lines to escape new lines
    */
    // .split('\n').join('\\n')
    // .split('\r').join('\\r')

    return `"${formatted}"`
}

const ROWS_PER_PAGE_OPTIONS = [
    30, 50, 75, 100, 250, 500,
]

export default {
    name: 'TracesTable',
    emits: ['download', 'switchView', 'datasetFromFiltered'],
    inject: ['App'],
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        filteredTraces: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        rowsPerPageOptions: VueTypes.arrayOf([VueTypes.number]).def(ROWS_PER_PAGE_OPTIONS),
        loading: VueTypes.bool.def(false),
    },
    data () {
        return {
            filter: '',
            page: 1,
            rowsPerPage: 30,
            prompt: false,
            newDatasetName: undefined,
        }
    },
    computed: {
        pagination: {
            get () {
                return {
                    page: this.page,
                    rowsPerPage: this.rowsPerPage,
                    rowsNumber: this.total,
                }
            },
            set (value) {
                this.page = value.page
                this.rowsPerPage = value.rowsPerPage
            },
        },
    },
    methods: {
        emitRequest (props) {
            // check for limit = All, just changing the event data so the pagination remains as All
            const rowsPerPage = props.pagination.rowsPerPage === 0 ? 9999 : props.pagination.rowsPerPage
            this.pagination = props.pagination
            this.$emit('table-request', { ...props.pagination, rowsPerPage, search: props.filter })
        },
        getPagination () {
            return this.pagination
        },
        downloadData () {
            this.$emit('download')
        },
        exportTable () {
            // naive encoding to csv format
            const content = [this.columns.map(col => wrapCsvValue(col.label))].concat(
                this.rows.map(row => this.columns.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === undefined ? col.name : col.field],
                    col.format,
                )).join(',')),
            ).join('\r\n')

            const status = exportFile(
                'table_export.csv',
                content,
                'text/csv',
            )

            if (status !== true) {
                this.$q.notify({
                    message: this.$t('visualization.traces.downloadDenied'),
                    color: 'negative',
                    icon: 'warning',
                })
            }
        },
        switchGraphicalView () {
            this.$emit('switchView', 'TABLE')
        },
        createDataset () {
            if (this.$refs.newDatasetName.validate()) {
                const { newDatasetName } = this
                this.$emit('datasetFromFiltered', newDatasetName)
                this.prompt = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.my-sticky-header-table {
    height: fit-content;
    max-height: 85vh;
    max-width: calc(100vw - 350px);
    &::v-deep thead {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        background-color: #c1f4cd;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}
</style>
