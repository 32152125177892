<template>
    <div class="Step">
        <QForm
            ref="form"
            class="Form flex-1 pr-2 pl-2"
            @submit.prevent
        >
            <div class="row items-start">
                <QSelect
                    :value="mode"
                    class="col-12"
                    clearable
                    lazyRules
                    outlined
                    behavior="menu"
                    :label="$t('wizard.query.mode')"
                    :options="normalizeModes"
                    :rules="[
                        value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.query.mode') }),
                    ]"
                    @input="handleSelectMode"
                />
            </div>
            <div class="row items-start">
                <div class="col-12 pb-1">
                    <QRadio
                        v-model="option"
                        :val="options.NEW"
                        :label="$t('wizard.query.options.one')"
                        @input="handleChangeQuery"
                    />
                    <QRadio
                        v-model="option"
                        disable
                        :val="options.CHOOSE"
                        :label="$t('wizard.query.options.two')"
                        @input="handleChangeModel"
                    />
                </div>
            </div>
            <div class="row items-start">
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <WCard
                        v-if="option === options.NEW"
                        class="mb-2 col-12"
                    >
                        <template #header>
                            <WButtonGroup
                                :options="buttonGroupOptions"
                                @input="handleClickButtonGroup($event)"
                            />
                        </template>
                        <Dropdown
                            v-if="opened"
                            :opened="opened"
                            :options="dropdownOptions"
                            :maxHeight="500"
                            @onClose="opened = false"
                            @onClick="handleClickOption"
                        />
                        <QInput
                            v-model="value.definition.query"
                            class="pb-0"
                            type="textarea"
                            clearable
                            outlined
                            :label="$t('wizard.query.query')"
                            lazyRules
                            :rules="[
                                value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.query.query') }),
                                value => validators.betweenLength(value, 5, 500) || $t('validation.rangeLength', { attribute: $t('wizard.query.query'), min: 5, max: 100 }),
                            ]"
                        />
                    </WCard>
                    <QSelect
                        v-else
                        v-model="value.definition.query"
                        class="col-md-4 col-lg-3 q-mt-md mb-1"
                        clearable
                        lazyRules
                        outlined
                        behavior="menu"
                        :label="$t('wizard.query.model')"
                        :disable="!mode"
                        :options="normalizeModels"
                        :rules="[
                            value => validators.required(value) || $t('validation.required', { attribute: $t('wizard.query.model') }),
                        ]"
                    />
                </Transition>
            </div>
            <div class="row items-start">
                <QInput
                    v-model="value.definition.restrictions"
                    type="textarea"
                    class="col-12"
                    clearable
                    outlined
                    :label="$t('wizard.query.restrictions')"
                    lazyRules
                />
            </div>
        </QForm>
        <div class="Info pl-2 pr-2">
            <CollapseItem
                :opened="openedInsightModel"
                :style="{'max-height': openedInsightRestrictions ?'300px': '500px'}"
                :title="$t('wizard.query.insight.model.title')"
                color="primary"
                @onClick="openedInsightModel = !openedInsightModel"
            >
                <InsightModel />
            </CollapseItem>
            <CollapseItem
                :opened="openedInsightRestrictions"
                class="mt-2"
                :style="{'max-height': openedInsightModel ?'300px': '500px'}"
                :title="$t('wizard.query.insight.restrictions.title')"
                color="primary"
                @onClick="openedInsightRestrictions = !openedInsightRestrictions"
            >
                <InsightRestrictions />
            </CollapseItem>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { Dropdown, CollapseItem } from '@/components'
import { validatorsMixin } from '@/mixins'
import { InsightModel, InsightRestrictions } from '../components'

const OPTIONS = {
    NEW: 'NEW',
    CHOOSE: 'CHOOSE',
}

export default {
    name: 'StepQuery',
    components: {
        CollapseItem,
        InsightModel,
        InsightRestrictions,
        Dropdown,
    },
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            definition: VueTypes.any.isRequired,
        }).loose,
        datasetId: VueTypes.string,
    },
    data () {
        return {
            mode: undefined,
            modes: undefined,
            query: this.value.definition.query || '',
            opened: false,
            openedInsightModel: true,
            openedInsightRestrictions: true,

            // model: undefined,
            models: [],

            option: OPTIONS.NEW,
            options: OPTIONS,

            isLoading: false,
            dropdownOptions: [],
        }
    },
    computed: {
        normalizeModes () {
            return (this.modes || []).map(({ name }) => name)
        },
        normalizeModels () {
            return (this.models || []).map(({ name }) => name)
        },
    },
    watch: {
        'value.definition.mode': {
            handler (mode) {
                if (mode) this.updateMode(mode)
            },
        },
    },
    beforeMount () {
        this.buttonGroupOptions = [
            { type: 'button', label: this.$t('wizard.query.queries.seq'), value: 'SEQ()' },
            { type: 'button', label: this.$t('wizard.query.queries.switch'), value: 'SWITCH()' },
            { type: 'button', label: this.$t('wizard.query.queries.fork'), value: 'FORK()' },
            { type: 'button', label: this.$t('wizard.query.queries.loop'), value: 'LOOP()' },
            {
                type: 'dropdown', label: this.$t('wizard.query.queries.activities'), value: this.$t('wizard.query.queries.activities'), color: 'primary', iconRight: 'chevron-down',
            },
        ]
    },
    async mounted () {
        await this.getData()
        await this.getActivities()
        this.getConfigurations()
    },
    methods: {
        handleClickButtonGroup (option) {
            if (option.type === 'dropdown') {
                this.opened = true
            } else {
                this.setQuery(option.value)
            }
        },
        handleClickOption ({ label }) {
            this.opened = false
            this.setQuery(label)
        },
        setQuery (value) {
            const query = `${this.value?.definition?.query ?? ''}${value}`
            this.$emit('input', { ...this.value, definition: { ...this.value?.definition, query } })
        },
        handleSelectMode (mode) {
            const value = this.findModeBy({ key: 'name', value: mode })
            if ((value || {}).identifier) {
                this.mode = mode
                this.$emit('input', { ...this.value, definition: { ...this.value?.definition, mode: value.identifier } })
            }
        },
        handleChangeModel () {
            const { mode } = this.value
            this.handleChangeQuery()
            if (mode) this.getModels(mode)
        },
        handleChangeQuery () {
            this.$emit('input', { ...this.value, definition: { ...this.value?.definition, query: undefined } })
        },
        findModeBy ({ key, value }) {
            return this.modes.find(m => m[key] === value)
        },
        updateMode (value) {
            const mode = this.findModeBy({ key: 'identifier', value })
            if (mode) this.mode = mode.name
        },
        isValid () {
            return this.$refs.form.validate().then(result => result)
        },
        getData () {
            this.getModes()
        },
        async getConfigurations () {
            this.isLoading = true
            const datasetId = this.value.datasetId || this.datasetId
            apiRequest(Api().datasets.getConfigurations({ datasetId }))
                .then(({ data }) => {
                    const [datasetConfig] = data
                    this.config = {
                        ...this.config,
                        start: datasetConfig.startTimeColumn.column,
                        activity: datasetConfig.activityColumn.column,
                        endFormat: datasetConfig.endTimeColumn?.format,
                        end: datasetConfig.endTimeColumn?.column,
                        name: 'default',
                        startFormat: datasetConfig.startTimeColumn?.format,
                        traceId: datasetConfig.traceColumn.column,
                        uid: datasetConfig.uuid,
                    }
                    this.$emit('input', { ...this.value, config: this.config })
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async getModes () {
            this.isLoading = true
            apiRequest(Api().queries.modes())
                .then((data) => {
                    const { mode } = this.value?.definition
                    this.modes = data
                    if (mode) this.updateMode(mode)
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async getActivities () {
            this.isLoading = true
            const datasetId = this.value.datasetId || this.datasetId
            const params = { filters: [] }
            apiRequest(Api().datasets.activities({ datasetId, params }))
                .then((data) => { this.dropdownOptions = this.formatOptions(data) })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        formatOptions (options) {
            return options.map(({ id: key, name: label }) => ({ key, label }))
        },
        async getModels (modeId) {
            this.isLoading = true
            apiRequest(Api().queries.modeReportTypes({ modeId }))
                .then(data => (this.models = data))
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">

.Step {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 0 30px;
    @media screen and (max-width: $md) {
        flex-direction: column;
         padding: 0;
         margin-bottom: 30px;
        .Info {
            margin-top: 16px;
        }
    }
}
.Form, .Info {
    width: 50%;
    @media screen and (max-width: $md) {
        width: 90%;
    }
}
.CollapseItem {
    max-height: 300px;
    overflow: auto;
    border: 1px solid $border-color;
    &::v-deep .Header {
        position: sticky;
        z-index: 999;
        top: 0;
        background: white;
    }
}
.WCard {
    &::v-deep .Body {
        padding: 0;
        .q-field__control {
            border-radius: unset;
        }
    }
    &::v-deep .Header {
        padding: 0;
    }
}
.WButtonGroup {
    &::v-deep {
        border-radius: unset;
    }
}
.Separator {
    margin: 30px 0 15px 0;
    border-bottom: 1px solid $border-color;
}
</style>
