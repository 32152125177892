export default {
    rows: [
        {
            transition: {
                name: 'START-END',
                source: 'START',
                target: 'END',
            },
            value: 9999,
        },
        {
            transition: {
                name: 'START-STEP2',
                source: 'START',
                target: 'STEP2',
            },
            value: 8888,
        },
        {
            transition: {
                name: 'STEP3-END',
                source: 'STEP3',
                target: 'END',
            },
            value: 7777,
        },
        {
            transition: {
                name: 'STEP2-END',
                source: 'STEP2',
                target: 'END',
            },
            value: 6666,
        },
    ],
}
