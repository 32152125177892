var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fetching
    ? _c(
        "QCard",
        { staticClass: "Card" },
        [
          _c(
            "QCardSection",
            { staticClass: "CardSection" },
            [
              _c("QBtn", {
                staticClass: "CloseButton",
                attrs: { icon: "close", flat: "", round: "", dense: "" },
                on: { click: _vm.closeModal }
              }),
              _c("div", { staticClass: "text-h6 TitleContainer" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.powerBIDialog.title")) +
                    " "
                ),
                _c(
                  "div",
                  { staticClass: "HelpIconContainer" },
                  [
                    _c("QIcon", {
                      attrs: {
                        name: "help_outline",
                        color: "primary",
                        size: "1.5em"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("QCardSection", [
            _c("div", { staticClass: "text-h6" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("visualization.powerBIDialog.chooseConnection")
                  ) +
                  " "
              )
            ]),
            _c("div", { staticClass: "Padding" }, [
              _c("div", { staticClass: "text-h6 ProcessIdContainer" }, [
                _c("strong", { staticClass: "DatasetTitle" }, [
                  _vm._v(
                    _vm._s(_vm.$t("visualization.powerBIDialog.currentDataset"))
                  )
                ]),
                _vm._v(" " + _vm._s(_vm.processId) + " "),
                _c(
                  "div",
                  { staticClass: "CopyClipboardContainer" },
                  [
                    _c("WIcon", {
                      attrs: {
                        icon: "copy",
                        size: 24,
                        color: "primary",
                        clickable: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleCopyToClipboard(_vm.processId)
                        }
                      }
                    }),
                    _c(
                      "QTooltip",
                      { attrs: { contentClass: "bg-secondary" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("visualization.powerBIDialog.clipboard")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm.token
                ? _c("div", { staticClass: "text-h6 TokenContainer" }, [
                    _c("strong", { staticClass: "TokenAvailable" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("visualization.powerBIDialog.tokenAvailable")
                        )
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "CopyClipboardContainer" },
                      [
                        _c("WIcon", {
                          attrs: {
                            icon: "copy",
                            size: 24,
                            color: "primary",
                            clickable: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleCopyToClipboard(_vm.token)
                            }
                          }
                        }),
                        _c(
                          "QTooltip",
                          { attrs: { contentClass: "bg-secondary" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.powerBIDialog.clipboard"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              !_vm.token && _vm.fetching
                ? _c(
                    "div",
                    { staticClass: "text-h6 Padding" },
                    [
                      _c("strong", { staticClass: "TokenNonAvailable" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "visualization.powerBIDialog.noTokenAvailable"
                            )
                          )
                        )
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("visualization.powerBIDialog.generateToken1")
                          )
                        )
                      ]),
                      _c("RouterLink", { attrs: { to: "/doc" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "visualization.powerBIDialog.generateToken2"
                              )
                            )
                          )
                        ])
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("visualization.powerBIDialog.generateToken3")
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "ButtonsContainer" }, [
              _c(
                "div",
                { staticClass: "ButtonContainer" },
                [
                  _c(
                    "WButton",
                    {
                      staticClass: "ButtonDialog",
                      attrs: { outline: "" },
                      on: { click: _vm.downloadTemplate }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ButtonContent" },
                        [
                          _c("QIcon", {
                            attrs: { name: "save_alt", size: "2em" }
                          }),
                          _c("div", { staticClass: "ButtonTitle" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.powerBIDialog.downloadButton1"
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.powerBIDialog.downloadButton2"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "ExplanationContainer" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.powerBIDialog.downloadExplanation"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ButtonContainer" },
                [
                  _c(
                    "WButton",
                    {
                      staticClass: "ButtonDialog",
                      attrs: { outline: "", disabled: "true" },
                      on: { click: _vm.inverbisDashboard }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "ButtonContent" },
                        [
                          _c("QIcon", {
                            attrs: { name: "cached", size: "2em" }
                          }),
                          _c("div", { staticClass: "ButtonTitle" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.powerBIDialog.installButton1"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.powerBIDialog.installButton2"
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "ExplanationContainer" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.powerBIDialog.installExplanation"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }