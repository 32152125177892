var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Title" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("wizard.boosterWizard.chooseApproach.title")) + " "
      )
    ]),
    _c("div", { staticClass: "Options" }, [
      _c("div", { staticClass: "Option" }, [
        _c("h6", [
          _vm._v(
            _vm._s(
              _vm.$t("wizard.boosterWizard.chooseApproach.performanceBooster")
            )
          )
        ])
      ]),
      _c("div", { staticClass: "Option" }, [
        _c("h6", [
          _vm._v(
            _vm._s(_vm.$t("wizard.boosterWizard.chooseApproach.customAnalysis"))
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "Options" }, [
      _c(
        "div",
        { staticClass: "Option Images" },
        [
          _c("q-btn", { on: { click: _vm.handleGoToWizardBooster } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/performance_booster.png") }
            })
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Option Images" },
        [
          _c("q-btn", { on: { click: _vm.handleGoToVisualization } }, [
            _c("img", {
              attrs: { src: require("@/assets/images/manual_analysis.png") }
            })
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "Options" }, [
      _c("div", { staticClass: "OptionDescription" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "wizard.boosterWizard.chooseApproach.performanceBoosterDescription"
              )
            ) +
            " "
        )
      ]),
      _c("div", { staticClass: "OptionDescription" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "wizard.boosterWizard.chooseApproach.customAnalysisDescription"
              )
            ) +
            " "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }