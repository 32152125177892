<template>
    <img
        v-if="id"
        :height="height"
        :width="width"
        :src="require(`@/assets/images/filters/${id}.svg`)"
        alt="filter"
    >
</template>

<script>
import VueTypes from 'vue-types'
import { FILTERS_TYPES } from '../filtersEntity'

export default {
    name: 'FilterIcon',
    props: {
        id: VueTypes.oneOf(Object.values(FILTERS_TYPES)).isRequired,
        height: VueTypes.number.def(40),
        width: VueTypes.number.def(40),
    },
}
</script>
