export default {
    configurationUid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    minFitness: 0,
    mode: 'FAST',
    query: {
        platformQuery: {
            query: 'string',
            restrictions: 'string',
        },
    },
    report: {
        description: 'string',
        title: 'string',
        type: 'STANDARD_CONFORMANCE',
    },
}
