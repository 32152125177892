var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.id
    ? _c("img", {
        attrs: {
          height: _vm.height,
          width: _vm.width,
          src: require("@/assets/images/filters/" + _vm.id + ".svg"),
          alt: "filter"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }