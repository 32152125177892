<template>
    <div class="WButtonGroup flex row-stretch-center">
        <WButton
            v-for="option in options"
            :key="`options-${option.value}`"
            :color="option.color || 'none'"
            size="sm"
            class="Option flex-1"
            :disabled="option.disabled || disabled"
            :iconRight="option.iconRight || ''"
            :class="{ active: value === option.value }"
            @click="handleClick(option)"
        >
            {{ option.label }}
        </WButton>
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'WButtonGroup',
    props: {
        value: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        options: VueTypes.arrayOf([VueTypes.shape({
            label: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
            value: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
            disabled: VueTypes.bool,
            color: VueTypes.string,
            iconRight: VueTypes.string,
        }).loose]),
        disabled: VueTypes.bool.def(false),
    },
    methods: {
        handleClick (option) {
            this.$emit('input', option)
        },
    },
}
</script>

<style scoped lang="scss">
.WButtonGroup {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;

    .WButton {
        border: 0;
        padding: 7px;
        border-radius: 0;
    }
}
</style>
