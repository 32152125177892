var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AccountScreen" },
    [
      _c(
        "WCard",
        { staticClass: "Personal mb-2", attrs: { bordered: "" } },
        [
          _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
            _vm._v(" " + _vm._s(_vm.$t("settings.sections.personal")) + " ")
          ]),
          _c(
            "div",
            { staticClass: "Wrapper" },
            [
              _c("WText", {
                staticClass: "pb-0-5 mb-1",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.personal.text", { name: _vm.project })
                  )
                }
              }),
              _c("UserForm", {
                attrs: { disabled: _vm.isLoading },
                on: { onSubmit: _vm.update },
                model: {
                  value: _vm.user,
                  callback: function($$v) {
                    _vm.user = $$v
                  },
                  expression: "user"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "WCard",
        { staticClass: "Password mb-2", attrs: { bordered: "" } },
        [
          _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
            _vm._v(" " + _vm._s(_vm.$t("settings.sections.security")) + " ")
          ]),
          _c(
            "div",
            { staticClass: "Wrapper" },
            [
              _c("WText", { staticClass: "pb-0-5 mb-1" }, [
                _vm._v(" " + _vm._s(_vm.$t("settings.security.text")) + " ")
              ]),
              _c(
                "WButton",
                {
                  attrs: { disabled: _vm.isLoading },
                  on: { click: _vm.handleClickRecoveryPassword }
                },
                [_vm._v(" " + _vm._s(_vm.$t("settings.security.btn")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.enviroment.public
        ? _c("Payments", {
            attrs: { value: _vm.App.user },
            on: { onUpdatedSubscription: _vm.handleUpdatedSubscription }
          })
        : _vm._e(),
      _c("ModalRequest", {
        ref: "recoveryDialog",
        attrs: {
          title: _vm.$t("settings.modals.recovery.title"),
          text: _vm.$t("settings.modals.recovery.text")
        },
        on: { onAccept: _vm.recoveryPassword }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }