<template>
    <Modal
        :isOpen="opened"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('visualization.traces.exportDialog.title') }}
            </WText>
        </template>
        <template #body>
            <div>{{ $t('visualization.traces.exportDialog.subtitle') }}</div>
            <div class="column q-gutter-md q-ma-md">
                <QTable
                    class="my-sticky-header-table"
                    :data="attrs"
                    :columns="columns"
                    rowKey="name"
                    flat
                    outlined
                    separator="none"
                    bordered
                    tableHeaderClass="bg-primary text-white"
                    :selectedRowsLabel="getSelectedString"
                    selection="multiple"
                    :selected.sync="selected"
                    :rowsPerPageOptions="[0]"
                    hidePagination
                >
                    <template v-slot:no-data="{ message }">
                        <div class="full-width row flex-center text-accent q-gutter-sm">
                            <span>
                                {{ message }}
                            </span>
                        </div>
                    </template>
                </QTable>
                <div
                    class="flex items-center justify-center"
                >
                    <QBtn
                        :label="$t('visualization.traces.exportDialog.export')"
                        noCaps
                        color="primary"
                        unelevated
                        iconRight="download"
                        @click="handleExportData"
                    />
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { Modal } from '@/components'

export default {
    name: 'AttributeSelector',
    emits: [
        'export',
    ],
    components: {
        Modal,
    },
    props: {
        attrs: VueTypes.array,
    },
    data () {
        return {
            opened: false,
            modelName: undefined,
            file: undefined,
            selected: [],
        }
    },
    computed: {
        columns () {
            return [{
                name: 'NAME', label: this.$t('visualization.traces.exportDialog.name'), field: 'name', align: 'left',
            },
            {
                name: 'TYPE', label: this.$t('visualization.traces.exportDialog.type'), field: 'type', align: 'left',
            }]
        },
    },
    mounted () {
    },
    methods: {
        open () {
            this.opened = true
        },
        close () {
            this.opened = false
        },
        handleExportData () {
            const exportAttrs = this.selected.map(attr => attr.name)
            this.$emit('export', exportAttrs)
            this.opened = false
        },
        getSelectedString () {
            return this.selected.length === 0 ? '' : `${this.selected.length} ${this.$t('visualization.traces.exportDialog.attribute')}${this.selected.length > 1 ? 's' : ''} 
            ${this.$t('visualization.traces.exportDialog.selected')} ${this.attrs.length}`
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &::v-deep .Dialog {
        max-width: 1000px;
    }
}
.my-sticky-header-table {
    height: 500px;
    max-height: 75vh;
    max-width: calc(100vw - 350px);
    &::v-deep thead {
        position: sticky;
        top: 0;
        background: white;
        z-index: 99;
    }
    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
        background-color: #c1f4cd;
    }

    thead tr th {
        position: sticky;
        z-index: 1;
    }
    thead tr:first-child th {
        top: 0;
    }

    &.q-table--loading thead tr:last-child th {
        top: 48px;
    }
}
</style>
