var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      _c(
        "div",
        { staticClass: "SettingsModal" },
        [
          _c(
            "div",
            { staticClass: "row justify-between items-center q-pr-md" },
            [
              _c("WText", { staticClass: "mb-1", attrs: { size: 20 } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("projects.datasets.settings.title")) +
                    ": "
                ),
                _c("span", { staticClass: "titleName" }, [
                  _vm._v(" " + _vm._s(_vm.datasetInfo.name) + " ")
                ])
              ]),
              _c(
                "QBtn",
                {
                  attrs: { square: "", icon: "arrow_back", color: "primary" },
                  on: { click: _vm.closeSettings }
                },
                [
                  _c(
                    "QTooltip",
                    {
                      attrs: {
                        contentClass: "bg-primary",
                        anchor: "center left",
                        self: "center right"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("projects.datasets.settings.back")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "QTabs",
            {
              staticClass: "text-primary",
              attrs: { dense: "", align: "left" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("QTab", {
                attrs: {
                  name: "GENERAL",
                  noCaps: "",
                  label: _vm.$t("projects.datasets.settings.tabs.general")
                }
              }),
              _c("QTab", {
                attrs: {
                  name: "RT_UPDATES",
                  noCaps: "",
                  label: _vm.$t("projects.datasets.settings.tabs.updates")
                }
              }),
              _c("QTab", {
                attrs: { name: "ERRORS", noCaps: "", label: _vm.errorsName }
              })
            ],
            1
          ),
          _c(
            "QTabPanels",
            {
              attrs: {
                animated: "",
                transitionNext: "fade",
                transitionPrev: "fade"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "QTabPanel",
                { attrs: { name: "GENERAL" } },
                [
                  _c("GeneralSettings", {
                    attrs: { datasetInfo: _vm.datasetInfo },
                    on: { UpdateDatasetName: _vm.handleUpdateDatasetName }
                  })
                ],
                1
              ),
              _c(
                "QTabPanel",
                { attrs: { name: "RT_UPDATES" } },
                [
                  _c("Updates", {
                    attrs: { datasetInfo: _vm.datasetInfo },
                    on: { SetUpdates: _vm.handleSetRealTimeSettings }
                  })
                ],
                1
              ),
              _c(
                "QTabPanel",
                { attrs: { name: "ERRORS" } },
                [_c("Errors", { attrs: { datasetInfo: _vm.datasetInfo } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }