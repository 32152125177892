var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "CaseCompletionSettings" }, [
        _c(
          "div",
          { staticClass: "CaseCompletion" },
          [
            _c(
              "WText",
              {
                staticClass: "title",
                attrs: { weight: "bold", color: "primary" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("visualization.settings.caseCompletion.name")
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("WText", { attrs: { weight: "bold", color: "primary" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.settings.startingActivities")
                      ) +
                      " "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "OptionsGroup" },
                  [
                    _c("QSelect", {
                      staticClass: "Options",
                      attrs: {
                        outlined: "",
                        multiple: "",
                        dense: "",
                        hideSelected: "",
                        labelColor: "primary",
                        color: "primary",
                        label: _vm.$t("visualization.settings.activities"),
                        options: _vm.activities
                      },
                      on: { input: _vm.saveWithoutButton },
                      model: {
                        value: _vm.startingA,
                        callback: function($$v) {
                          _vm.startingA = $$v
                        },
                        expression: "startingA"
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.startingA, function(selected, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "QChip",
                        {
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteIndexValue(index, "startingA")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(selected) + " ")]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("WText", { attrs: { weight: "bold", color: "primary" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.settings.occurringActivities")
                      ) +
                      " "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "OptionsGroup" },
                  [
                    _c("QSelect", {
                      staticClass: "Options",
                      attrs: {
                        outlined: "",
                        multiple: "",
                        dense: "",
                        hideSelected: "",
                        labelColor: "primary",
                        color: "primary",
                        label: _vm.$t("visualization.settings.activities"),
                        options: _vm.activities
                      },
                      on: { input: _vm.saveWithoutButton },
                      model: {
                        value: _vm.occurringA,
                        callback: function($$v) {
                          _vm.occurringA = $$v
                        },
                        expression: "occurringA"
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.occurringA, function(selected, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "QChip",
                        {
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteIndexValue(index, "occurringA")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(selected) + " ")]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("WText", { attrs: { weight: "bold", color: "primary" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.settings.endingActivities")
                      ) +
                      " "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "OptionsGroup" },
                  [
                    _c("QSelect", {
                      staticClass: "Options",
                      attrs: {
                        outlined: "",
                        multiple: "",
                        dense: "",
                        hideSelected: "",
                        labelColor: "primary",
                        color: "primary",
                        label: _vm.$t("visualization.settings.activities"),
                        options: _vm.activities
                      },
                      on: { input: _vm.saveWithoutButton },
                      model: {
                        value: _vm.endingA,
                        callback: function($$v) {
                          _vm.endingA = $$v
                        },
                        expression: "endingA"
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.endingA, function(selected, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "QChip",
                        {
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteIndexValue(index, "endingA")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(selected) + " ")]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm.saveButton
              ? _c(
                  "div",
                  { staticClass: "row justify-end mt-1" },
                  [
                    _c(
                      "WButton",
                      {
                        staticClass: "buttons",
                        attrs: { outline: "", color: "primary" },
                        on: { click: _vm.saveWithButton }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("visualization.filters.save")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }