const queries = [
    {
        code: 'Code 1',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 1',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: null,
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'FINISHED',
            name: 'FINISHED',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        code: 'Code 2',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 2',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: {
            description: 'lorem ipsum',
            extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            title: 'Report Title',
            type: {
                description: 'lorem ipsum',
                identifier: 'STANDARD_COMPLIANCE',
                name: 'string',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'SUBMITTED',
            name: 'SUBMITTED',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        code: 'Code 3',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 3',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: {
            description: 'lorem ipsum',
            extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            title: 'Report Title',
            type: {
                description: 'lorem ipsum',
                identifier: 'STANDARD_COMPLIANCE',
                name: 'string',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'CANCELLED',
            name: 'CANCELLED',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        code: 'Code 4',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 3',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: {
            description: 'lorem ipsum',
            extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            title: 'Report Title',
            type: {
                description: 'lorem ipsum',
                identifier: 'STANDARD_COMPLIANCE',
                name: 'string',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'RUNNING',
            name: 'RUNNING',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        code: 'Code 5',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 3',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: {
            description: 'lorem ipsum',
            extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            title: 'Report Title',
            type: {
                description: 'lorem ipsum',
                identifier: 'STANDARD_COMPLIANCE',
                name: 'string',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'ERROR',
            name: 'ERROR',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        code: 'Code 6',
        createDate: '2020-05-21T10:18:39',
        deleteDate: undefined,
        tst: 'string',
        description: 'lorem ipsum',
        finishedAt: '2020-05-21T10:18:39',
        minFitness: 0,
        mode: {
            description: 'string',
            identifier: 'FAST',
            name: 'string',
        },
        name: 'Query 3',
        query: {
            platformQuery: {
                query: 'string',
                restrictions: 'string',
            },
        },
        report: {
            description: 'lorem ipsum',
            extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            title: 'Report Title',
            type: {
                description: 'lorem ipsum',
                identifier: 'STANDARD_COMPLIANCE',
                name: 'string',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
        result: {
            averageFitness: 0,
            compliant: {
                percentageCompliantTraces: 0,
                totalCompliantTraces: 0,
            },
        },
        state: {
            description: 'lorem ipsum',
            identifier: 'TIMEOUT',
            name: 'TIMEOUT',
        },
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
]

export default {
    data: queries,
    total: queries.length,
}
