<template>
    <div class="Project">
        <div class="Container flex row-stretch flex-nowrap">
            <Sidebar
                :value="$route.name"
                :tabs="tabs"
                @input="handleChangeTab"
            />

            <div class="Wrapper">
                <div class="Header flex row-stretch-center">
                    <div class="Breadcrumbs">
                        <Breadcrumb />
                    </div>
                    <div class="Actions flex">
                        <HeaderList
                            v-model="filters"
                            :disabled="isProjectArchived"
                            @input="handleChangeQuery"
                        />
                    </div>
                </div>

                <div
                    class="Screen pb-5"
                    :class="{ isDesktop: !isMobile }"
                >
                    <Transition
                        v-if="projectId"
                        name="main-router"
                        mode="out-in"
                    >
                        <RouterView
                            :projectId="projectId"
                            :query="query"
                            :filters="filters"
                            :isProjectArchived="isProjectArchived"
                        />
                    </Transition>
                </div>
            </div>
        </div>

        <ModalRequest
            ref="delPrDialog"
            :title="$t('projects.delete')"
            :text="$t('projects.modals.delete.text', { name: (project || {}).name })"
            :loading="isLoading"
            @onAccept="archiveProject"
        />
        <ModalNameDesc
            ref="editPrDialog"
            :action="$t('actions.save')"
            :title="$t('projects.edit')"
            :loading="isLoading"
            @onSubmit="updateProject"
        />
    </div>
</template>

<script>
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { mediaQueryMixin } from '@/mixins'
import {
    Breadcrumb, ModalRequest, ModalNameDesc, HeaderList, Sidebar,
} from '@/components'

const FILTERS = {
    search: '',
    archived: false,
}

export default {
    name: 'Project',
    components: {
        Breadcrumb,
        ModalRequest,
        ModalNameDesc,
        HeaderList,
        Sidebar,
    },
    mixins: [mediaQueryMixin],
    data () {
        return {
            projectId: undefined,
            project: undefined,
            isLoading: false,

            query: undefined,

            filters: { ...FILTERS },
        }
    },
    computed: {
        tabs () {
            return [
                {
                    id: 'ProjectDatasets',
                    icon: 'database',
                    label: this.$t('projects.sections.datasets'),
                },
                {
                    id: 'ProjectModels',
                    icon: 'diagram-1',
                    label: this.$t('projects.sections.models'),
                },
            ]
        },
        isProjectArchived () {
            const { deleteDate } = this.project || {}
            return !!deleteDate
        },
    },
    watch: {
        '$route.name': {
            handler () {
                this.filters = { ...FILTERS }
            },
        },
    },
    mounted () {
        const { params, query = {} } = this.$route
        const { projectId } = params

        this.projectId = projectId
        this.query = {
            archived: query.archived === 'true',
            search: query.search || '',
        }

        this.getProject()
    },
    methods: {
        handleChangeTab (name) {
            const tab = this.tabs.find(({ id }) => id === name)
            if (tab && !tab.disabled) {
                this.query = undefined
                this.$router.push({ name, params: { projectId: this.projectId } }).catch((e) => {})
            }
        },
        handleChangeQuery ({ archived, search } = {}) {
            if (!archived && !search) {
                this.$router.replace(this.$router).catch(() => {})
            } else {
                const query = {
                    ...(archived ? { archived } : {}),
                    ...(search ? { search } : {}),
                }
                this.$router.replace({ ...this.$router, query }).catch(() => {})
            }
        },
        handleConfirmDelete () {
            this.$refs.delPrDialog.open()
        },
        handleEditProject () {
            const { name, description } = this.project
            this.$refs.editPrDialog.open({ name, description })
        },
        async getProject (props = null) {
            this.isLoading = true
            apiRequest(Api().projects.get({ projectId: this.projectId }))
                .then(({ data }) => {
                    const [project] = data
                    this.project = project
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async updateProject ({ name, description }) {
            this.isLoading = true
            apiRequest(Api().projects.update({ projectId: this.projectId, params: { name, description } }))
                .then(() => {
                    notifySuccess(this.$t('projects.notifications.deleted.text'))
                    this.getProject()
                    this.$refs.editPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async archiveProject () {
            this.isLoading = true
            apiRequest(Api().projects.delete({ projectId: this.projectId }))
                .then(() => {
                    notifySuccess(this.$t('projects.notifications.archived.text'))
                    this.getProject()
                    this.$refs.delPrDialog.close()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>

<style scoped lang="scss">
.Header {
    position: sticky;
    top: 0;
    z-index: 50;
    background: $white;
    height: $subheader-height;
    padding: 0 20px;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;
}
.ModalRequest {
    z-index: 999999 !important;
}
.Container {
    min-height: calc(100vh);
}

.Screen {
    width: calc(100vw - #{$sidebar-min-width});
    padding: 25px;

    &.isDesktop {
        width: calc(100vw - #{$sidebar-width});
    }
}
.Sidebar {
    position: sticky;
    height: 100vh;
    top: 0;
}
</style>
