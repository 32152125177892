var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Breadcrumbs flex row-left-center" },
    _vm._l(_vm.breadcrumbs, function(ref, index) {
      var label = ref.label
      var icon = ref.icon
      var badge = ref.badge
      var to = ref.to
      return _c(
        "div",
        {
          key: "breadcrumb-" + index,
          staticClass: "BreadcrumbsItem flex row-left-center",
          class: { "ml-0-5": index }
        },
        [
          _c(
            "RouterLink",
            {
              staticClass: "flex row-left-center",
              attrs: { id: "breadcrumb-" + index, to: to }
            },
            [
              icon
                ? _c("WIcon", {
                    attrs: { icon: icon, color: "primary", size: 18 }
                  })
                : _vm._e(),
              _c("WText", { attrs: { weight: "semi" } }, [
                _vm._v(" " + _vm._s(label) + " ")
              ]),
              badge
                ? _c(
                    "Badge",
                    _vm._b({ staticClass: "ml-0-5" }, "Badge", badge, false)
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.length > 1 && index + 1 < _vm.length
            ? _c("WIcon", {
                staticClass: "ml-0-5",
                attrs: { icon: _vm.separator, color: "primary" }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }