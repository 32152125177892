import Notify from '@/services/notify'
import i18n from '@/lang/i18n'

export const notify = ({ message, type = 'negative' }) => Notify.create({
    message,
    color: type,
})

export const notifySuccess = message => notify({ message, type: 'positive' })

const manageError = ({ status, data }) => {
    const { errorMessage: message } = data || {}
    const types = {
        400: () => ({ message }),
        404: () => ({ message }),
        405: () => ({ message }),
        500: () => ({ message }),
        503: () => ({ message }),
        default: () => ({ message: i18n.t('error.general.text') }),
    }
    return ((!!message && types[status]) || types.default)()
}

export const notifyError = (error) => {
    const { result: { response: { data, status } = {} } = {} } = error || {}
    const { errorCode: code } = data || {}
    const { message } = manageError({ data, status })
    return notify({ code, message })
}

export const returnError = (error) => {
    const { result: { response: { data, status } = {} } = {} } = error || {}
    const { message } = manageError({ data, status })
    return { status, message }
}

export default {
    notifySuccess,
    notifyError,
}
