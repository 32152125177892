<template>
    <div class="WizardQuery">
        <QStepper
            v-if="(steps || []).length"
            :value="step"
            alternativeLabels
            animated
            flat
        >
            <QStep
                v-for="(item, index) in steps"
                :key="`step-${index}`"
                :name="item.name"
                :title="item.title"
                :icon="item.icon"
                :done="currentIndex > index"
                :headerNav="currentIndex > index"
            >
                <Component
                    :is="item.component"
                    :ref="item.name"
                    v-model="data"
                    :projectId="projectId"
                    :datasetId="datasetId"
                    :steps="steps"
                />
            </QStep>
        </QStepper>
    </div>
</template>
<script>
/* eslint-disable consistent-return */
import VueTypes from 'vue-types'
import cloneDeep from 'lodash/cloneDeep'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import { wizardMixin } from '@/mixins'
import { WIZARD } from '@/entities'
import Steps from './Steps'
import Setup from './setup'

export default {
    name: 'WizardQuery',
    components: Steps,
    mixins: [wizardMixin],
    props: {
        projectId: VueTypes.string.isRequired,
        datasetId: VueTypes.string,
        storageKey: VueTypes.string.def(WIZARD.KEYS.QUERY),
    },
    wizard () {
        const { config, initialState, mocks } = Setup({ datasetId: this.datasetId })

        return {
            config,
            data: cloneDeep(initialState),
            mocks,
        }
    },
    methods: {
        exit () {
            this.$router.push({ name: 'ProjectDatasets', params: { projectId: this.projectId } }).then(this.resetWizard)
        },
        finish () {
            this.$router.push({ name: 'ProjectQueries', params: { projectId: this.projectId } }).then(this.resetWizard)
        },
        async submitStep0 () {
            const isValid = await this.component.isValid()
            const { datasetId } = this.data
            if (isValid) {
                this.$emit('onChangeDatasetId', datasetId)
                return { ok: true, data: { datasetId } }
            }
        },
        async submitStep1 () {
            const isValid = await this.component.isValid()
            const { definition, config, report } = this.data
            if (isValid) {
                const params = {
                    configurationUid: config.uid,
                    mode: definition.mode,
                    query: {
                        platformQuery: {
                            query: definition.query,
                            restrictions: definition.restrictions,
                        },
                    },
                    report: {
                        ...(report.name ? { title: report.name } : {}),
                        ...(report.description ? { description: report.description } : {}),
                        type: report.type.value,
                    },
                }
                apiRequest(Api().queries.create(params))
                    .then(({ data }) => {
                        notifySuccess(this.$t('wizard.notifications.launched.query.text'))
                        this.finish()
                    })
                    .catch(notifyError)
            }
        },
    },
}
</script>
