var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "WButtonGroup flex row-stretch-center" },
    _vm._l(_vm.options, function(option) {
      return _c(
        "WButton",
        {
          key: "options-" + option.value,
          staticClass: "Option flex-1",
          class: { active: _vm.value === option.value },
          attrs: {
            color: option.color || "none",
            size: "sm",
            disabled: option.disabled || _vm.disabled,
            iconRight: option.iconRight || ""
          },
          on: {
            click: function($event) {
              return _vm.handleClick(option)
            }
          }
        },
        [_vm._v(" " + _vm._s(option.label) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }