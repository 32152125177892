<template>
    <div class="Overview Container flex flex-1">
        <Sidebar
            ref="Sidebar"
            :tab="tab"
            @tab="handleTabChange"
        />
        <div class="Screen flex-1">
            <div class="Titles">
                <div class="Title">
                    <div class="TitleText">
                        {{ $t('booster.performanceBoosterTitle') }}
                        <div class="Description">
                            {{ $t('booster.boosterDescription') }}
                        </div>
                    </div>
                    <QSpace />
                    <div class="RightText">
                        {{ tabTitle }}
                    </div>
                </div>
            </div>
            <div
                v-if="tab === 'OVERVIEW'"
                class="ScreenOptions"
            >
                <StateView
                    ref="StateView"
                />
            </div>
            <div
                v-if="tab === 'PREDICTION'"
                class="ScreenOptions"
            >
                <Prediction
                    ref="Prediction"
                    :processId="processId"
                />
            </div>
            <div
                v-if="tab === 'IMPROVEMENT'"
                class="ScreenOptions"
            >
                <Improvement />
            </div>
        </div>
    </div>
</template>
<script>

import Sidebar from './components/Sidebar.vue'
import Prediction from './sections/prediction/Prediction.vue'
import StateView from './sections/stateView/StateView.vue'
import Improvement from './sections/improvement/Improvement.vue'

const DEFAULT_TAB = 'OVERVIEW'

export default {
    name: 'Overview',
    components: {
        Sidebar,
        Prediction,
        Improvement,
        StateView,
    },
    mixins: {

    },
    props: {

    },
    data () {
        return {
            tab: DEFAULT_TAB,
            processId: this.$route.params.processId,
            viewMode: 'booster',
            tabTitle: this.$t('booster.overview.process'),
            slaCompliance: true,
        }
    },
    mounted () {
        this.tabChangeByRoute()
    },
    methods: {
        handleTabChange (newTab) {
            this.tab = newTab
            this.handleRouteChange(newTab)
            this.handleTabTitleChange(newTab)
        },
        handleRouteChange (newTab) {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            const index = segments.indexOf('booster')
            if (index !== -1) {
                segments.splice(index, 2, `booster/${newTab}`)
                const newRoute = segments.join('/')
                this.$router.push(newRoute)
            }
        },
        handleTabTitleChange (newTab) {
            switch (newTab) {
                case 'OVERVIEW':
                    this.tabTitle = this.$t('booster.overview.process')
                    break
                case 'PREDICTION':
                    this.tabTitle = this.$t('booster.prediction.process')
                    break
                case 'IMPROVEMENT':
                    this.tabTitle = this.$t('booster.improvement.process')
                    break
                default:
                    this.tabTitle = this.$t('booster.overview.process')
                    break
            }
        },
        tabChangeByRoute () {
            const currentRoute = this.$route.fullPath
            const segments = currentRoute.split('/')
            if (segments.includes('OVERVIEW')) {
                this.tab = 'OVERVIEW'
                this.handleTabTitleChange('OVERVIEW')
            } else if (segments.includes('PREDICTION')) {
                this.tab = 'PREDICTION'
                this.handleTabTitleChange('PREDICTION')
            } else if (segments.includes('IMPROVEMENT')) {
                this.tab = 'IMPROVEMENT'
                this.handleTabTitleChange('IMPROVEMENT')
            }
        },
    },
}
</script>
<style scoped lang="scss">
    $visualization-sidebar-width: 60px;
    .Overview {
        overflow: visible;
    }
    .Container {
        flex-wrap: nowrap;
        min-height: 100vh;
    }
    .Screen {
        margin-top: $subheader-height;
        height: calc(100vh - $subheader-height);
        padding-left: 1vw;
        padding-right: 1vw;
        position: relative;
    }
    .Sidebar {
        width: 100%;
        height: calc(100vh - $subheader-height);
        top: $subheader-height;
        padding-left: 10px;
        position: relative;
        overflow-x: hidden;
        max-width: $visualization-sidebar-width;
        min-width: $visualization-sidebar-width;
        border-right: 1px solid $border-color;
        transition: $transition;
        @media screen and (min-width: $md) {
            max-width: $visualization-sidebar-width;
            min-width: $visualization-sidebar-width;
        }
    }
    .ScreenOptions {
        height: 90%;
    }
    .Titles {
       height: 9%;
    }
    .Title {
        display: flex;
    }
    .TitleText {
        font-size: 3vh;
        font-weight: bolder;
    }
    .RightText {
        font-size: 3vh;
    }
    .toggle {
        font-size:2vh;
        display:flex;
        justify-content: right;
    }
    .Description {
        color: $primary;
        width:fit-content;
        font-size: 1.7vh;
        font-weight: normal;
    }
    @media (max-width: 1150px) {
        .Overview {
            overflow: visible;
        }
    }
</style>
