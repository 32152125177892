<template>
    <div class="WizardImport">
        <QStepper
            ref="stepper"
            :value="step"
            alternativeLabels
            animated
            flat
        >
            <QStep
                v-for="(item, index) in steps"
                :key="`WizardImportStep-${index}`"
                :name="item.name"
                :title="item.title"
                :icon="item.icon"
                :done="currentIndex > index"
                :headerNav="currentIndex > index"
            >
                <Component
                    :is="item.component"
                    :ref="item.name"
                    v-model="data"
                    :datasetId="datasetId"
                    :steps="steps"
                    :importer="importer"
                />
            </QStep>
        </QStepper>
    </div>
</template>
<script>
/* eslint-disable consistent-return */
import VueTypes from 'vue-types'
import cloneDeep from 'lodash/cloneDeep'
import {
    Api, apiRequest, notifySuccess, notifyError,
} from '@/api'
import { wizardMixin } from '@/mixins'
import { WIZARD } from '@/entities'
import Steps from './Steps'
import Setup from './setup'

export default {
    name: 'WizardImport',
    components: Steps,
    mixins: [wizardMixin],
    props: {
        projectId: VueTypes.string.isRequired,
        datasetId: VueTypes.string,
        storageKey: VueTypes.string.def(WIZARD.KEYS.IMPORT),
    },
    wizard () {
        const { config, initialState, mocks } = Setup()

        return {
            config,
            data: cloneDeep(initialState),
            mocks,
        }
    },
    computed: {
        importer () {
            return ((this.data || {}).service || {}).identifier
        },
        disableConnection () {
            const { service } = this.data
            return !(service || {}).allowJdbc && !(service || {}).allowRest
        },
    },
    methods: {
        exit () {
            const { projectId } = this.$route.params
            this.$router.push({ name: 'ProjectDatasets', params: { projectId } }).then(this.resetWizard)
        },
        async submitStep1 () {
            const isValid = await this.component.isValid()
            const { service } = this.data
            const success = this.disableConnection ? () => this.goToStep('workflow') : undefined
            if (service.identifier === 'SAP') {
                const { projectId } = this.$route.params
                this.$router.push({ name: 'SAPImport', params: { projectId } }).then(this.resetWizard)
            }
            if (isValid) return { ok: true, data: { service }, success }
        },
        async submitStep2 () {
            const isValid = await this.component.isValid()
            const { connection } = this.data
            if (isValid) return { ok: true, data: { connection } }
        },
        async prevStep3 () {
            const success = this.disableConnection ? () => this.goToStep('service') : undefined
            return { ok: true, success }
        },
        async submitStep3 () {
            const isValid = await this.component.isValid()
            const { workflow } = this.data
            if (isValid) return { ok: true, data: { workflow } }
        },
        async submitStep4 () {
            const { connection, service, workflow } = this.data
            const { connectionChain, restService, ...connectionConfig } = connection || {}
            const connectedByJDBC = service.allowJdbc
            const connectedByHttp = !service.allowJdbc && service.allowRest
            const connectedByIntegratedDenodo = !service.allowJdbc && !service.allowRest

            let params = {
                projectUid: this.projectId,
                workflows: (workflow.workflows || []).map(({ value }) => value),
            }
            if (connectedByJDBC) {
                params = {
                    ...params,
                    jdbcParameters: {
                        ...(connectionChain
                            ? {
                                connectionChain,
                            }
                            : {
                                ...connectionConfig,
                                driver: (connectionConfig.driver || {}).key,
                            }),
                    },
                }
            } else if (connectedByHttp) {
                const { password, username } = connection || {}
                params = {
                    ...params,
                    url: restService,
                    username,
                    password,
                }
            }
            this.isLoading = true

            let endpoint = ''
            if (connectedByJDBC) endpoint = 'importJdbcWorkflows'
            else if (connectedByHttp) endpoint = 'importHttpworkflows'
            else if (connectedByIntegratedDenodo) endpoint = 'importDenodoIntegratedworkflows'

            apiRequest(Api().datasets[endpoint]({ importer: this.importer, params }))
                .then(({ data }) => {
                    notifySuccess(this.$t('wizard.notifications.launched.import.text'))
                    this.exit()
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
