import Transforms from './transforms'

const endpoints = {
    list: {
        path: `/projects`,
        transform: Transforms.list,
    },
    create: {
        method: 'POST',
        path: `/projects`,
    },
    get: {
        path: `/projects/:projectId`,
        transform: Transforms.get,
    },
}

export default {
    name: 'projects',
    endpoints,
}
