const KEYS = {
    DATASET: 'wizard-dataset',
    DISCOVERY: 'wizard-discovery',
    QUERY: 'wizard-query',
    IMPORT: 'wizard-import',
    VIEWSETTIGNS: 'wizard-viewSettings',
}

export default {
    KEYS,
}
