<template>
    <div class="Breadcrumbs flex row-left-center">
        <div
            v-for="({ label, icon, badge, to}, index) in breadcrumbs"
            :key="`breadcrumb-${index}`"
            class="BreadcrumbsItem flex row-left-center"
            :class="{ 'ml-0-5': index }"
        >
            <RouterLink
                :id="`breadcrumb-${index}`"
                :to="to"
                class="flex row-left-center"
            >
                <WIcon
                    v-if="icon"
                    :icon="icon"
                    color="primary"
                    :size="18"
                />
                <WText weight="semi">
                    {{ label }}
                </WText>
                <Badge
                    v-if="badge"
                    v-bind="badge"
                    class="ml-0-5"
                />
            </RouterLink>
            <WIcon
                v-if="(length > 1 && index + 1 < length)"
                :icon="separator"
                color="primary"
                class="ml-0-5"
            />
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest } from '@/api'
import { mediaQueryMixin } from '@/mixins'
import { Badge } from '@/components'

export default {
    name: 'Breadcrumbs',
    components: {
        Badge,
    },
    mixins: [mediaQueryMixin],
    props: {
        separator: VueTypes.string.def('chevron-right'),
    },
    data () {
        return {
            organization: undefined,
            project: undefined,
            query: undefined,
            processs: undefined,
            breadcrumbs: [],
        }
    },
    computed: {
        length () {
            return this.breadcrumbs.length
        },
        expanded () {
            return !this.isMobile
        },
    },
    beforeMount () {
        const {
            organizationId, projectId, processId, queryId,
        } = this.$route.params || {}
        this.organizationId = organizationId
        this.projectId = projectId
        this.processId = processId
        this.queryId = queryId
    },
    async mounted () {
        if (this.organizationId) this.getOrganization()
        if (this.projectId) this.getProject()
        if (this.queryId) this.getQuery()
        if (this.processId) this.getDataset()
    },
    methods: {
        formatBreadcrumps () {
            const {
                organization, organizationId, project, expanded, query, process, projectId,
            } = this
            this.breadcrumbs = expanded ? [
                ...(organization ? [{
                    icon: 'home',
                    to: { name: 'Dashboard' },
                }, {
                    label: organization.name,
                    to: { name: 'Organization', params: { organizationId } },
                }] : []),
                ...(project ? [{
                    label: project.name,
                    to: { name: 'Project', params: { projectId: project.uuid } },
                    ...(project.deleteDate
                        ? {
                            badge: {
                                label: this.$t('general.readOnly'),
                                icon: 'alert-triangle',
                                color: 'warning',
                                textColor: 'black',
                            },
                        }
                        : {}),
                }] : []),
                ...(query ? [{
                    label: query.name,
                    to: { name: 'Query', params: { queryId: query.uuid } },
                }] : []),
                ...(process && project ? [{
                    label: process.name,
                    to: { name: 'ProcessVisualization', params: { projectId, processId: process.uuid, tab: 'MODEL' } },
                }] : []),
            ] : []
        },
        getOrganization () {
            apiRequest(Api().organizations.get({ organizationUid: this.organizationId }))
                .then(({ data }) => {
                    const [organization] = data
                    this.organization = organization
                    this.formatBreadcrumps()
                })
        },
        getProject () {
            apiRequest(Api().projects.get({ projectId: this.projectId }))
                .then(({ data }) => {
                    const [project] = data
                    this.project = project
                    this.formatBreadcrumps()
                })
        },
        getQuery () {
            apiRequest(Api().queries.get({ queryId: this.queryId }))
                .then(({ data }) => {
                    const [query] = data || []
                    this.query = query
                    this.formatBreadcrumps()
                })
        },
        getDataset () {
            apiRequest(Api().datasets.get({ datasetId: this.processId }))
                .then(({ data }) => {
                    const [process] = data || []
                    this.process = process
                    this.formatBreadcrumps()
                })
        },
    },
}
</script>
