var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-4 q-pa-sm" },
      [
        _vm.transitions && !_vm.isLoadingTransitions
          ? _c(
              "QCard",
              { attrs: { flat: "", bordered: "" } },
              [
                _c("QCardSection", [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.conformance.issuesView.transitionsTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "text-subtitle text-italic" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.App.numberLocationFormat(
                            _vm.transitions.unfulfillingArcsTotal
                          ) +
                            " " +
                            _vm.occurenceLiteral(
                              _vm.transitions.unfulfillingArcsTotal
                            ) +
                            " " +
                            _vm.$t(
                              "visualization.conformance.issuesView.outOf"
                            ) +
                            " " +
                            _vm.App.numberLocationFormat(
                              _vm.transitions.totalArcs
                            )
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("QSeparator", { attrs: { inset: "" } }),
                _c(
                  "QCardSection",
                  { staticClass: "IssueListing" },
                  [
                    _vm.transitions.unfulfillingArcsTotal > 0
                      ? _c(
                          "QList",
                          _vm._l(_vm.transitions.unfulfillingArcs, function(
                            item,
                            idx
                          ) {
                            return _c(
                              "QItem",
                              { key: idx },
                              [
                                _c(
                                  "QItemSection",
                                  [
                                    _c("QItemLabel", [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.source) +
                                          " → " +
                                          _vm._s(item.target) +
                                          " "
                                      )
                                    ]),
                                    _c("QLinearProgress", {
                                      attrs: {
                                        value: _vm.elementPercentage(
                                          item.total,
                                          _vm.transitions.unfulfillingArcsTotal
                                        ),
                                        size: "20px",
                                        rounded: "",
                                        color: "secondary"
                                      }
                                    }),
                                    _c(
                                      "QItemLabel",
                                      {
                                        staticClass: "text-caption text-italic"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.App.numberLocationFormat(
                                                item.total
                                              ) +
                                                " " +
                                                _vm.occurenceLiteral(item.total)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c("div", { staticClass: "text-subtitle" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "visualization.conformance.issuesView.noResultsTransitions"
                                )
                              ) +
                              " "
                          )
                        ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm.isLoadingTransitions
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("QLinearProgress", {
                    attrs: { indeterminate: "", color: "secondary" }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-4 q-pa-sm" },
      [
        _vm.start && !_vm.isLoadingActivities["start"]
          ? _c(
              "QCard",
              { attrs: { flat: "", bordered: "" } },
              [
                _c("QCardSection", [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.conformance.issuesView.startTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "text-subtitle text-italic" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.App.numberLocationFormat(
                            _vm.start.unfulfillingActivitiesTotal
                          ) +
                            " " +
                            _vm.occurenceLiteral(
                              _vm.start.unfulfillingActivitiesTotal
                            ) +
                            " " +
                            _vm.$t(
                              "visualization.conformance.issuesView.outOf"
                            ) +
                            " " +
                            _vm.App.numberLocationFormat(_vm.start.total)
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("QSeparator", { attrs: { inset: "" } }),
                _c(
                  "QCardSection",
                  { staticClass: "IssueListing" },
                  [
                    _vm.start.unfulfillingActivitiesTotal > 0
                      ? _c(
                          "QList",
                          _vm._l(_vm.start.unfulfillingActivities, function(
                            item,
                            idx
                          ) {
                            return _c(
                              "QItem",
                              { key: idx },
                              [
                                _c(
                                  "QItemSection",
                                  [
                                    _c("QItemLabel", [
                                      _vm._v(
                                        " " + _vm._s(item.activityName) + " "
                                      )
                                    ]),
                                    _c("QLinearProgress", {
                                      attrs: {
                                        value: _vm.elementPercentage(
                                          item.total,
                                          _vm.start.unfulfillingActivitiesTotal
                                        ),
                                        size: "20px",
                                        rounded: "",
                                        color: "secondary"
                                      }
                                    }),
                                    _c(
                                      "QItemLabel",
                                      {
                                        staticClass: "text-caption text-italic"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.App.numberLocationFormat(
                                                item.total
                                              ) +
                                                " " +
                                                _vm.occurenceLiteral(item.total)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c("div", { staticClass: "text-subtitle" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "visualization.conformance.issuesView.noResultsStart"
                                )
                              ) +
                              " "
                          )
                        ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm.isLoadingActivities["start"]
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("QLinearProgress", {
                    attrs: { indeterminate: "", color: "secondary" }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-4 q-pa-sm" },
      [
        _vm.end && !_vm.isLoadingActivities["end"]
          ? _c(
              "QCard",
              { attrs: { flat: "", bordered: "" } },
              [
                _c("QCardSection", [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.conformance.issuesView.endTitle"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("div", { staticClass: "text-subtitle text-italic" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.App.numberLocationFormat(
                            _vm.end.unfulfillingActivitiesTotal
                          ) +
                            " " +
                            _vm.occurenceLiteral(
                              _vm.end.unfulfillingActivitiesTotal
                            ) +
                            " " +
                            _vm.$t(
                              "visualization.conformance.issuesView.outOf"
                            ) +
                            " " +
                            _vm.App.numberLocationFormat(_vm.end.total)
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("QSeparator", { attrs: { inset: "" } }),
                _c(
                  "QCardSection",
                  { staticClass: "IssueListing" },
                  [
                    _vm.end.unfulfillingActivitiesTotal > 0
                      ? _c(
                          "QList",
                          _vm._l(_vm.end.unfulfillingActivities, function(
                            item,
                            idx
                          ) {
                            return _c(
                              "QItem",
                              { key: idx },
                              [
                                _c(
                                  "QItemSection",
                                  [
                                    _c("QItemLabel", [
                                      _vm._v(
                                        " " + _vm._s(item.activityName) + " "
                                      )
                                    ]),
                                    _c("QLinearProgress", {
                                      attrs: {
                                        size: "20px",
                                        rounded: "",
                                        value: _vm.elementPercentage(
                                          item.total,
                                          _vm.end.unfulfillingActivitiesTotal
                                        ),
                                        color: "secondary"
                                      }
                                    }),
                                    _c(
                                      "QItemLabel",
                                      {
                                        staticClass: "text-caption text-italic"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.App.numberLocationFormat(
                                                item.total
                                              ) +
                                                " " +
                                                _vm.occurenceLiteral(item.total)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c("div", { staticClass: "text-subtitle" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "visualization.conformance.issuesView.noResultsEnd"
                                )
                              ) +
                              " "
                          )
                        ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm.isLoadingActivities["end"]
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("QLinearProgress", {
                    attrs: { indeterminate: "", color: "secondary" }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }