/* eslint-disable max-len */
export default {
    data: [
        {
            elements: {
                alternative: {
                    absoluteFrequency: 1203.0,
                    activities: [
                        {
                            name: 'START_PROCESS',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'O_Created',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'O_Sent (mail and online)',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'O_Returned',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'O_Accepted',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'End_process',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                        {
                            name: 'END_PROCESS',
                            statistics: { durationAvg: 0.0, durationSd: 0.0, frequency: 1203.0 },
                        },
                    ],
                    arcs: [
                        {
                            name: 'START_PROCESS - O_Created',
                            source: 'START_PROCESS',
                            statistics: { durationAvg: 1.0, durationSd: 0.0, frequency: 1203.0 },
                            target: 'O_Created',
                        },
                        {
                            name: 'O_Created - O_Sent (mail and online)',
                            source: 'O_Created',
                            statistics: {
                                durationAvg: 1378633.9343308397,
                                durationSd: 1.6258336232726254e7,
                                frequency: 1203.0,
                            },
                            target: 'O_Sent (mail and online)',
                        },
                        {
                            name: 'O_Sent (mail and online) - O_Returned',
                            source: 'O_Sent (mail and online)',
                            statistics: {
                                durationAvg: 8.076221048212801e8,
                                durationSd: 4.609161824002391e8,
                                frequency: 1203.0,
                            },
                            target: 'O_Returned',
                        },
                        {
                            name: 'O_Returned - O_Accepted',
                            source: 'O_Returned',
                            statistics: {
                                durationAvg: 4.084017036791355e8,
                                durationSd: 5.3548592398085886e8,
                                frequency: 1203.0,
                            },
                            target: 'O_Accepted',
                        },
                        {
                            name: 'O_Accepted - End_process',
                            source: 'O_Accepted',
                            statistics: { durationAvg: 1.0, durationSd: 0.0, frequency: 1203.0 },
                            target: 'End_process',
                        },
                        {
                            name: 'End_process - END_PROCESS',
                            source: 'End_process',
                            statistics: { durationAvg: 1.0, durationSd: 0.0, frequency: 1203.0 },
                            target: 'END_PROCESS',
                        },
                    ],
                    duration: 5182716894,
                    endTime: 1485864918597,
                    relativeFrequency: 0.35792918774174354,
                    startTime: 1480682201703,
                    uuid: '67a12c50-e0cf-4b30-b8a2-b494d65557d1',
                },
                duration: {
                    cases_histogram: [
                        [671.0, 6.0],
                        [809.0, 2.0],
                        [258.0, 5.0],
                        [643.0, 7.0],
                        [371.0, 4.0],
                        [144.0, 11.0],
                        [881.0, 1.0],
                        [239.0, 16.0],
                        [803.0, 1.0],
                        [283.0, 16.0],
                        [357.0, 11.0],
                        [90.0, 8.0],
                        [216.0, 13.0],
                        [496.0, 3.0],
                        [492.0, 4.0],
                        [308.0, 10.0],
                        [787.0, 2.0],
                        [140.0, 9.0],
                        [473.0, 4.0],
                        [828.0, 1.0],
                        [209.0, 11.0],
                        [573.0, 7.0],
                        [238.0, 19.0],
                        [786.0, 3.0],
                        [145.0, 7.0],
                        [183.0, 5.0],
                        [350.0, 5.0],
                        [259.0, 11.0],
                        [143.0, 18.0],
                        [598.0, 8.0],
                        [326.0, 7.0],
                        [194.0, 9.0],
                        [402.0, 9.0],
                        [379.0, 9.0],
                        [167.0, 14.0],
                        [188.0, 15.0],
                        [449.0, 4.0],
                        [138.0, 6.0],
                        [206.0, 3.0],
                        [909.0, 2.0],
                        [219.0, 9.0],
                        [325.0, 8.0],
                        [309.0, 14.0],
                        [211.0, 17.0],
                        [1155.0, 1.0],
                        [547.0, 4.0],
                        [331.0, 8.0],
                        [208.0, 6.0],
                        [662.0, 2.0],
                        [589.0, 3.0],
                        [815.0, 2.0],
                        [285.0, 9.0],
                        [334.0, 15.0],
                        [168.0, 13.0],
                        [111.0, 2.0],
                        [264.0, 11.0],
                        [404.0, 9.0],
                        [373.0, 2.0],
                        [332.0, 10.0],
                        [164.0, 19.0],
                        [477.0, 2.0],
                        [763.0, 1.0],
                        [122.0, 3.0],
                        [135.0, 4.0],
                        [314.0, 5.0],
                        [193.0, 21.0],
                        [352.0, 8.0],
                        [118.0, 4.0],
                        [715.0, 1.0],
                        [182.0, 6.0],
                        [502.0, 6.0],
                        [337.0, 7.0],
                        [220.0, 6.0],
                        [166.0, 21.0],
                        [119.0, 5.0],
                        [485.0, 2.0],
                        [136.0, 5.0],
                        [117.0, 4.0],
                        [263.0, 6.0],
                        [497.0, 2.0],
                        [398.0, 5.0],
                        [149.0, 3.0],
                        [732.0, 38.0],
                        [635.0, 3.0],
                        [400.0, 6.0],
                        [501.0, 5.0],
                        [232.0, 5.0],
                        [1338.0, 3.0],
                        [215.0, 11.0],
                        [212.0, 14.0],
                        [262.0, 13.0],
                        [192.0, 18.0],
                        [335.0, 14.0],
                        [284.0, 11.0],
                        [312.0, 9.0],
                        [93.0, 6.0],
                        [328.0, 2.0],
                        [2.0, 3.0],
                        [338.0, 7.0],
                        [307.0, 3.0],
                        [172.0, 6.0],
                        [330.0, 13.0],
                        [171.0, 13.0],
                        [146.0, 6.0],
                        [636.0, 3.0],
                        [69.0, 6.0],
                        [453.0, 7.0],
                        [173.0, 4.0],
                        [214.0, 12.0],
                        [619.0, 3.0],
                        [207.0, 6.0],
                        [170.0, 9.0],
                        [790.0, 4.0],
                        [644.0, 3.0],
                        [380.0, 8.0],
                        [286.0, 12.0],
                        [162.0, 19.0],
                        [124.0, 1.0],
                        [593.0, 2.0],
                        [430.0, 4.0],
                        [163.0, 15.0],
                        [139.0, 4.0],
                        [142.0, 11.0],
                        [382.0, 11.0],
                        [951.0, 3.0],
                        [187.0, 10.0],
                        [639.0, 3.0],
                        [244.0, 6.0],
                        [186.0, 10.0],
                        [210.0, 6.0],
                        [1104.0, 1.0],
                        [234.0, 15.0],
                        [1238.0, 1.0],
                        [178.0, 1.0],
                        [548.0, 4.0],
                        [364.0, 6.0],
                        [179.0, 3.0],
                        [255.0, 5.0],
                        [185.0, 14.0],
                        [73.0, 4.0],
                        [835.0, 1.0],
                        [217.0, 7.0],
                        [620.0, 2.0],
                        [18.0, 8.0],
                        [431.0, 3.0],
                        [381.0, 7.0],
                        [645.0, 2.0],
                        [189.0, 13.0],
                        [324.0, 7.0],
                        [612.0, 1.0],
                        [766.0, 3.0],
                        [690.0, 4.0],
                        [782.0, 1.0],
                        [860.0, 2.0],
                        [358.0, 7.0],
                        [23.0, 4.0],
                        [397.0, 8.0],
                        [141.0, 9.0],
                        [302.0, 7.0],
                        [169.0, 14.0],
                        [287.0, 6.0],
                        [360.0, 1.0],
                        [152.0, 1.0],
                        [409.0, 6.0],
                        [480.0, 2.0],
                        [522.0, 3.0],
                        [783.0, 2.0],
                        [383.0, 9.0],
                        [361.0, 6.0],
                        [377.0, 4.0],
                        [432.0, 2.0],
                        [261.0, 9.0],
                        [289.0, 8.0],
                        [498.0, 4.0],
                        [96.0, 9.0],
                        [422.0, 3.0],
                        [351.0, 4.0],
                        [161.0, 10.0],
                        [235.0, 18.0],
                        [92.0, 5.0],
                        [399.0, 8.0],
                        [37.0, 1.0],
                        [861.0, 2.0],
                        [260.0, 5.0],
                        [413.0, 3.0],
                        [329.0, 10.0],
                        [236.0, 12.0],
                        [213.0, 11.0],
                        [834.0, 2.0],
                        [323.0, 3.0],
                        [191.0, 13.0],
                        [474.0, 5.0],
                        [228.0, 5.0],
                        [572.0, 9.0],
                        [976.0, 1.0],
                        [195.0, 8.0],
                        [359.0, 4.0],
                        [549.0, 4.0],
                        [475.0, 5.0],
                        [218.0, 11.0],
                        [241.0, 8.0],
                        [451.0, 5.0],
                        [240.0, 12.0],
                        [1091.0, 1.0],
                        [812.0, 4.0],
                        [204.0, 5.0],
                        [115.0, 5.0],
                        [1050.0, 2.0],
                        [231.0, 4.0],
                        [280.0, 5.0],
                        [310.0, 12.0],
                        [571.0, 3.0],
                        [71.0, 5.0],
                        [455.0, 5.0],
                        [317.0, 5.0],
                        [407.0, 5.0],
                        [305.0, 6.0],
                        [804.0, 1.0],
                        [356.0, 8.0],
                        [762.0, 3.0],
                        [622.0, 3.0],
                        [615.0, 3.0],
                        [820.0, 1.0],
                        [282.0, 6.0],
                        [476.0, 5.0],
                        [304.0, 7.0],
                        [982.0, 1.0],
                        [403.0, 7.0],
                        [160.0, 5.0],
                        [198.0, 1.0],
                        [131.0, 1.0],
                        [291.0, 2.0],
                        [540.0, 2.0],
                        [362.0, 2.0],
                        [46.0, 1.0],
                        [62.0, 7.0],
                        [523.0, 5.0],
                        [954.0, 2.0],
                        [165.0, 17.0],
                        [68.0, 4.0],
                        [421.0, 1.0],
                        [270.0, 3.0],
                        [627.0, 2.0],
                        [1076.0, 1.0],
                        [640.0, 3.0],
                        [197.0, 8.0],
                        [624.0, 4.0],
                        [426.0, 2.0],
                        [205.0, 5.0],
                        [406.0, 6.0],
                        [565.0, 5.0],
                        [378.0, 8.0],
                        [148.0, 5.0],
                        [174.0, 3.0],
                        [447.0, 6.0],
                        [618.0, 3.0],
                        [649.0, 7.0],
                        [89.0, 2.0],
                        [77.0, 1.0],
                        [292.0, 2.0],
                        [1075.0, 1.0],
                        [29.0, 2.0],
                        [158.0, 2.0],
                        [1099.0, 1.0],
                        [493.0, 1.0],
                        [267.0, 1.0],
                        [734.0, 42.0],
                        [196.0, 4.0],
                        [814.0, 2.0],
                        [478.0, 5.0],
                        [394.0, 1.0],
                        [242.0, 9.0],
                        [764.0, 4.0],
                        [613.0, 1.0],
                        [577.0, 2.0],
                        [181.0, 2.0],
                        [374.0, 4.0],
                        [156.0, 2.0],
                        [665.0, 3.0],
                        [545.0, 3.0],
                        [306.0, 11.0],
                        [737.0, 66.0],
                        [265.0, 3.0],
                        [788.0, 2.0],
                        [401.0, 7.0],
                        [576.0, 3.0],
                        [429.0, 2.0],
                        [642.0, 2.0],
                        [101.0, 2.0],
                        [569.0, 3.0],
                        [738.0, 50.0],
                        [424.0, 4.0],
                        [450.0, 1.0],
                        [591.0, 3.0],
                        [456.0, 2.0],
                        [575.0, 2.0],
                        [420.0, 2.0],
                        [780.0, 1.0],
                        [190.0, 9.0],
                        [436.0, 5.0],
                        [718.0, 1.0],
                        [311.0, 7.0],
                        [95.0, 5.0],
                        [254.0, 2.0],
                        [546.0, 2.0],
                        [336.0, 9.0],
                        [1047.0, 1.0],
                        [199.0, 1.0],
                        [354.0, 6.0],
                        [375.0, 2.0],
                        [221.0, 3.0],
                        [230.0, 7.0],
                        [102.0, 3.0],
                        [245.0, 6.0],
                        [116.0, 7.0],
                        [349.0, 1.0],
                        [125.0, 3.0],
                        [602.0, 3.0],
                        [184.0, 8.0],
                        [666.0, 2.0],
                        [596.0, 4.0],
                        [427.0, 2.0],
                        [363.0, 2.0],
                        [503.0, 6.0],
                        [567.0, 1.0],
                        [21.0, 5.0],
                        [1086.0, 1.0],
                        [830.0, 1.0],
                        [735.0, 74.0],
                        [386.0, 1.0],
                        [388.0, 4.0],
                        [621.0, 3.0],
                        [838.0, 3.0],
                        [327.0, 9.0],
                        [679.0, 3.0],
                        [459.0, 1.0],
                        [719.0, 5.0],
                        [597.0, 2.0],
                        [253.0, 5.0],
                        [147.0, 5.0],
                        [594.0, 2.0],
                        [229.0, 4.0],
                        [595.0, 4.0],
                        [505.0, 2.0],
                        [97.0, 1.0],
                        [791.0, 3.0],
                        [313.0, 7.0],
                        [233.0, 7.0],
                        [113.0, 2.0],
                        [274.0, 2.0],
                        [479.0, 2.0],
                        [452.0, 5.0],
                        [50.0, 4.0],
                        [99.0, 3.0],
                        [482.0, 4.0],
                        [886.0, 1.0],
                        [574.0, 1.0],
                        [123.0, 11.0],
                        [72.0, 4.0],
                        [454.0, 2.0],
                        [25.0, 2.0],
                        [384.0, 4.0],
                        [237.0, 7.0],
                        [279.0, 1.0],
                        [301.0, 2.0],
                        [664.0, 2.0],
                        [927.0, 1.0],
                        [339.0, 5.0],
                        [469.0, 4.0],
                        [266.0, 4.0],
                        [405.0, 6.0],
                        [641.0, 4.0],
                        [159.0, 8.0],
                        [499.0, 5.0],
                        [387.0, 1.0],
                        [48.0, 3.0],
                        [257.0, 7.0],
                        [570.0, 2.0],
                        [14.0, 3.0],
                        [315.0, 3.0],
                        [243.0, 6.0],
                        [365.0, 2.0],
                        [564.0, 2.0],
                        [437.0, 1.0],
                        [833.0, 3.0],
                        [841.0, 1.0],
                        [150.0, 2.0],
                        [983.0, 1.0],
                        [521.0, 2.0],
                        [544.0, 1.0],
                        [568.0, 2.0],
                        [43.0, 1.0],
                        [550.0, 2.0],
                        [348.0, 1.0],
                        [859.0, 1.0],
                        [252.0, 3.0],
                        [342.0, 4.0],
                        [353.0, 8.0],
                        [533.0, 1.0],
                        [760.0, 1.0],
                        [281.0, 2.0],
                        [268.0, 1.0],
                        [623.0, 1.0],
                        [470.0, 2.0],
                        [256.0, 1.0],
                        [541.0, 3.0],
                        [663.0, 1.0],
                        [376.0, 5.0],
                        [600.0, 2.0],
                        [67.0, 4.0],
                        [819.0, 2.0],
                        [98.0, 8.0],
                        [481.0, 2.0],
                        [555.0, 3.0],
                        [1148.0, 1.0],
                        [695.0, 1.0],
                        [906.0, 2.0],
                        [278.0, 2.0],
                        [340.0, 3.0],
                        [19.0, 2.0],
                        [510.0, 1.0],
                        [370.0, 1.0],
                        [104.0, 1.0],
                        [1098.0, 3.0],
                        [742.0, 43.0],
                        [626.0, 1.0],
                        [526.0, 2.0],
                        [1011.0, 1.0],
                        [494.0, 1.0],
                        [837.0, 4.0],
                        [272.0, 3.0],
                        [1126.0, 1.0],
                        [952.0, 1.0],
                        [616.0, 1.0],
                        [614.0, 1.0],
                        [652.0, 1.0],
                        [531.0, 2.0],
                        [45.0, 4.0],
                        [471.0, 3.0],
                        [333.0, 5.0],
                        [740.0, 65.0],
                        [391.0, 2.0],
                        [599.0, 3.0],
                        [908.0, 1.0],
                        [736.0, 77.0],
                        [739.0, 58.0],
                        [733.0, 25.0],
                        [741.0, 71.0],
                        [103.0, 2.0],
                        [275.0, 3.0],
                        [731.0, 52.0],
                        [1074.0, 3.0],
                        [75.0, 3.0],
                        [65.0, 4.0],
                        [888.0, 1.0],
                        [761.0, 2.0],
                        [529.0, 2.0],
                        [1146.0, 1.0],
                        [112.0, 1.0],
                        [290.0, 5.0],
                        [1119.0, 1.0],
                        [88.0, 3.0],
                        [484.0, 1.0],
                        [0.0, 231.0],
                        [810.0, 2.0],
                        [730.0, 3.0],
                        [7.0, 1.0],
                        [784.0, 1.0],
                        [94.0, 4.0],
                        [15.0, 2.0],
                        [438.0, 1.0],
                        [457.0, 2.0],
                        [744.0, 1.0],
                        [1004.0, 1.0],
                        [999.0, 1.0],
                        [890.0, 2.0],
                        [288.0, 3.0],
                        [74.0, 1.0],
                        [856.0, 3.0],
                        [506.0, 2.0],
                        [578.0, 2.0],
                        [42.0, 5.0],
                        [779.0, 1.0],
                        [759.0, 2.0],
                        [743.0, 1.0],
                        [30.0, 2.0],
                        [22.0, 2.0],
                        [91.0, 3.0],
                        [4.0, 1.0],
                        [1.0, 2.0],
                        [55.0, 2.0],
                        [721.0, 1.0],
                        [785.0, 1.0],
                        [49.0, 1.0],
                        [372.0, 2.0],
                        [774.0, 1.0],
                        [755.0, 1.0],
                        [367.0, 1.0],
                        [720.0, 2.0],
                        [26.0, 2.0],
                        [27.0, 1.0],
                        [880.0, 2.0],
                        [792.0, 1.0],
                        [1102.0, 1.0],
                        [28.0, 2.0],
                        [513.0, 1.0],
                        [647.0, 1.0],
                        [224.0, 1.0],
                        [114.0, 3.0],
                        [137.0, 2.0],
                        [1006.0, 2.0],
                        [756.0, 1.0],
                        [984.0, 1.0],
                        [817.0, 2.0],
                        [31.0, 1.0],
                        [516.0, 1.0],
                        [977.0, 1.0],
                        [1077.0, 1.0],
                        [396.0, 1.0],
                        [34.0, 1.0],
                        [556.0, 1.0],
                        [434.0, 3.0],
                        [794.0, 3.0],
                        [935.0, 1.0],
                        [560.0, 1.0],
                        [1142.0, 1.0],
                        [120.0, 1.0],
                        [1022.0, 1.0],
                        [448.0, 1.0],
                        [1030.0, 1.0],
                        [53.0, 1.0],
                        [808.0, 1.0],
                        [964.0, 1.0],
                        [858.0, 1.0],
                        [552.0, 2.0],
                        [3.0, 5.0],
                        [799.0, 1.0],
                        [554.0, 2.0],
                        [51.0, 2.0],
                        [1219.0, 1.0],
                        [693.0, 1.0],
                        [44.0, 1.0],
                        [106.0, 1.0],
                        [625.0, 1.0],
                        [722.0, 1.0],
                        [293.0, 2.0],
                        [831.0, 2.0],
                        [100.0, 2.0],
                        [1123.0, 1.0],
                        [579.0, 2.0],
                        [672.0, 1.0],
                        [66.0, 2.0],
                        [175.0, 1.0],
                        [410.0, 4.0],
                        [517.0, 1.0],
                        [604.0, 2.0],
                        [134.0, 1.0],
                        [1317.0, 1.0],
                        [1009.0, 1.0],
                        [504.0, 1.0],
                        [553.0, 2.0],
                        [530.0, 1.0],
                        [527.0, 1.0],
                        [507.0, 1.0],
                        [675.0, 2.0],
                        [347.0, 2.0],
                        [939.0, 1.0],
                        [428.0, 3.0],
                        [528.0, 1.0],
                        [711.0, 1.0],
                        [891.0, 1.0],
                        [246.0, 1.0],
                        [277.0, 2.0],
                        [524.0, 1.0],
                        [765.0, 1.0],
                        [433.0, 1.0],
                        [646.0, 2.0],
                        [772.0, 1.0],
                        [412.0, 2.0],
                        [957.0, 1.0],
                        [500.0, 1.0],
                        [840.0, 1.0],
                        [223.0, 1.0],
                        [416.0, 1.0],
                        [717.0, 2.0],
                        [509.0, 1.0],
                        [318.0, 1.0],
                        [726.0, 1.0],
                        [1052.0, 1.0],
                        [907.0, 1.0],
                        [300.0, 1.0],
                        [689.0, 2.0],
                        [385.0, 1.0],
                        [303.0, 3.0],
                        [914.0, 1.0],
                        [953.0, 1.0],
                        [343.0, 1.0],
                        [683.0, 1.0],
                        [1036.0, 1.0],
                        [696.0, 2.0],
                        [435.0, 1.0],
                        [319.0, 1.0],
                        [345.0, 1.0],
                        [845.0, 1.0],
                        [987.0, 1.0],
                        [472.0, 1.0],
                        [948.0, 1.0],
                        [299.0, 1.0],
                        [729.0, 1.0],
                        [1020.0, 1.0],
                        [697.0, 1.0],
                        [769.0, 1.0],
                        [713.0, 1.0],
                        [829.0, 1.0],
                        [758.0, 1.0],
                        [836.0, 2.0],
                        [677.0, 1.0],
                        [638.0, 1.0],
                        [1145.0, 1.0],
                        [40.0, 5.0],
                        [20.0, 8.0],
                        [133.0, 1.0],
                        [70.0, 1.0],
                        [8.0, 1.0],
                        [78.0, 1.0],
                        [16.0, 1.0],
                        [39.0, 2.0],
                        [17.0, 1.0],
                        [276.0, 1.0],
                        [41.0, 1.0],
                        [414.0, 1.0],
                        [132.0, 1.0],
                        [121.0, 1.0],
                        [466.0, 1.0],
                        [495.0, 1.0],
                        [295.0, 1.0],
                        [355.0, 2.0],
                        [534.0, 1.0],
                        [47.0, 1.0],
                        [6.0, 1.0],
                        [203.0, 1.0],
                        [648.0, 1.0],
                        [670.0, 1.0],
                        [853.0, 1.0],
                        [1049.0, 1.0],
                        [1181.0, 1.0],
                        [617.0, 2.0],
                        [958.0, 2.0],
                        [839.0, 1.0],
                        [940.0, 1.0],
                        [38.0, 1.0],
                        [52.0, 1.0],
                        [998.0, 1.0],
                        [590.0, 1.0],
                        [366.0, 1.0],
                        [423.0, 1.0],
                        [691.0, 2.0],
                        [1080.0, 2.0],
                        [532.0, 1.0],
                        [668.0, 1.0],
                        [698.0, 1.0],
                        [650.0, 1.0],
                        [12.0, 1.0],
                        [1293.0, 1.0],
                        [24.0, 1.0],
                        [5.0, 1.0],
                    ],
                    topFiveActivities: {
                        avg: {
                            data: [0.0, 0.0, 0.0, 0.0, 0.0],
                            names: [
                                'O_Created',
                                'O_Sent (mail and online)',
                                'O_Returned',
                                'O_Accepted',
                                'O_Refused',
                            ],
                        },
                        sd: {
                            data: [0.0, 0.0, 0.0, 0.0, 0.0],
                            names: [
                                'O_Created',
                                'O_Sent (mail and online)',
                                'O_Returned',
                                'O_Accepted',
                                'O_Refused',
                            ],
                        },
                    },
                    topFiveAlternatives: {
                        avg: {
                            data: [
                                2.0704952750194519e9,
                                1.7944862953419354e9,
                                1.4962248528e9,
                                1.435882364898734e9,
                                1.25135005e9,
                            ],
                            names: ['Variant 3', 'Variant 8', 'Variant 15', 'Variant 9', 'Variant 10'],
                        },
                        sd: {
                            data: [
                                1.6275947956275978e9,
                                1.0300182768615941e9,
                                9.45623436138927e8,
                                9.408766924469098e8,
                                8.818403746842756e8,
                            ],
                            names: ['Variant 15', 'Variant 9', 'Variant 3', 'Variant 11', 'Variant 8'],
                        },
                    },
                    topFiveTransitions: {
                        avg: {
                            data: [
                                1.435737420987342e9,
                                1.0288735648354838e9,
                                2.0686405799257295e9,
                                9.897241702117647e8,
                                1.0465441035714285e9,
                            ],
                            names: [
                                'O_Sent (mail and online) - O_Cancelled',
                                'O_Sent (mail and online) - O_Refused',
                                'O_Sent (online only) - O_Refused',
                                'O_Returned - O_Cancelled',
                                'O_Sent (online only) - O_Cancelled',
                            ],
                        },
                        sd: {
                            data: [
                                1.0299418656271677e9,
                                9.524864008893139e8,
                                9.46644387563855e8,
                                9.283258959622127e8,
                                8.219308035179516e8,
                            ],
                            names: [
                                'O_Sent (mail and online) - O_Refused',
                                'O_Returned - O_Cancelled',
                                'O_Sent (mail and online) - O_Cancelled',
                                'O_Sent (online only) - O_Cancelled',
                                'O_Sent (online only) - O_Refused',
                            ],
                        },
                    },
                },
                frequency: {
                    topFiveActivities: {
                        data: [3361.0, 3012.0, 1835.0, 1467.0, 1330.0],
                        names: [
                            'O_Created',
                            'O_Sent (mail and online)',
                            'O_Returned',
                            'O_Cancelled',
                            'O_Accepted',
                        ],
                    },
                    topFiveAlternatives: {
                        data: [1203.0, 1131.0, 300.0, 155.0, 127.0],
                        names: ['Variant 1', 'Variant 3', 'Variant 2', 'Variant 8', 'Variant 4'],
                    },
                },
                general: {
                    processAvgDuration: 1.4284000327917287e9,
                    processBehaviour: { percentage: 0.6944361797084202, variants: 2 },
                },
            },
            text: {
                language: 'en',
                texts: {
                    duration:
            'The process has an average duration of 2.4 week(s), with a deviation of +- 2.4 week(s). 1963 of the analyzed cases conform to this average. The remaining 1398 are composed of 498 cases that can be considered clearly shorter, and 900 clearly longer. The charts below show information about the process elements that are responsible for the longer execution times. The activities have no apparent registered duration, which means that they are instant or that either the starting or the ending timestamp is not available in the source data. In this case, it is strongly suggested to review the following transitions between activities, as they incur a high duration and/or deviation: O_Sent (mail and online) - O_Cancelled, O_Returned - O_Cancelled, O_Sent (online only) - O_Cancelled, O_Sent (online only) - O_Refused and O_Sent (mail and online) - O_Refused. The process alternatives Variant 9, Variant 8, Variant 3 and Variant 15 are especially relevant in terms of duration and/or deviation.',
                    frequency:
            'Since activities do not have a registered duration, only frequency can be used to measure the importance of activities in terms of repetition throughout all the analyzed process cases. In particular, the most frequents activities are: O_Returned, O_Accepted, O_Sent (mail and online), O_Cancelled and O_Created. Likewise, these process alternatives should be revised: Variant 1, Variant 8 and Variant 3.',
                    general:
            '69.44% of the events in the log correspond to 2 different alternative executions of the process. On average, the process completes in 2.4 week(s), with a duration that ranges from 6.7 second(s) to 8.0 week(s).',
                },
                type: 'discovery',
            },
            title: 'Test2',
            type: {
                description: 'STANDARD DISCOVERY PROCESS REPORT',
                identifier: 'STANDARD_DISCOVERY',
                name: 'STANDARD_DISCOVERY',
            },
            uuid: 'd1e1efff-b5bf-49a0-a0c3-618a320da515',
        },
    ],
    total: 1,
}
