export default {
    general: {
        text: 'There was an unexpected error, please contact with the system administrator.',
    },
    unauthorized: {
        text: 'Authorization error. Please, login again.',
        title: 'Oops...',
    },
    unknown: {
        text: 'Unknown error. Please try again or contact us.',
        title: 'Oops...',
    },
    notfound: {
        text: 'Resource not found.',
        title: 'Oops...',
    },
    conflict: {
        text: 'Invalid resource.',
        title: 'Oops...',
    },
    auth: {
        text: 'User name or password are incorrect.',
        title: 'Error',
    },
    404: {
        title: 'Page not found',
    },
    select: {
        noOptions: 'No elements found.',
    },
}
