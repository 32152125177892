import { get as lodashGet } from 'lodash'

// //////////////////////////////////////////////////////////////////////////////
// API ERROR

export const ERROR_TYPE = {
    BAD_REQUEST: 'BAD_REQUEST',
    MALFORMED_RESPONSE: 'MALFORMED_RESPONSE',
    TRANSFORMATION: 'TRANSFORMATION',
    UNEXPECTED: 'UNEXPECTED',
}
export function ApiError ({
    type = ERROR_TYPE.UNEXPECTED,
    message = 'Unexpected Error',
    result,
}) {
    this.name = 'ApiError'
    this.type = type
    this.message = `${type}: ${message}`
    this.stack = (new Error()).stack
    this.result = result
}
ApiError.prototype = Object.create(Error.prototype)
ApiError.prototype.constructor = ApiError

// //////////////////////////////////////////////////////////////////////////////
// API REQUEST

/**
 * Return a promise that will perform the request. If something is wrong, it will be rejected with an ApiError
 * @param {promise} request the request (promise)
 * @param {Object} [options] options
 * @param {number|number[]} [options.allow] indicates a status number of a list of them. They will be considered as accepted responses, so the response will be resolved.
 * @param {boolean} [options.rawAxiosResult=false] indicates if the returned result is the original given by apisauce (axios). If true, it will return the data object directly.
 * @returns a function that given any result, it will return a promise rejection with a new ApiError
 * @example
 *      apiRequest(Api().users.list())
 *          .then(users => console.log(users))
 *          .catch(error => console.error(error))
 */
export const apiRequest = (request, options) => {
    const { allow, rawAxiosResult = false } = options || {}
    const normalizedAllow = (Array.isArray(allow) ? allow : [allow]).filter(status => typeof status === 'number')
    return request.then(
        result => (result &&
              ((result.status >= 200 && result.status <= 300) || normalizedAllow.includes(result.status))
            ? Promise.resolve(rawAxiosResult ? result : result.data)
            : Promise.reject(
                new ApiError({
                    type: ERROR_TYPE.BAD_REQUEST,
                    message: `${result.problem} in ${result.url}`,
                    result,
                }),
            )),
    )
}

// //////////////////////////////////////////////////////////////////////////////
// VALIDATIONS

export const validate = (checkFn, errorMsg) => result => (checkFn(result)
    ? Promise.resolve(result)
    : Promise.reject(new ApiError({
        type: ERROR_TYPE.UNEXPECTED,
        message: errorMsg,
        result,
    })))

export const existsInResponse = path => result => (lodashGet(result, path) !== undefined
    ? Promise.resolve(result)
    : Promise.reject(new ApiError({
        type: ERROR_TYPE.MALFORMED_RESPONSE,
        message: `"${path}" not found in ${result.url}`,
        result,
    })))

// //////////////////////////////////////////////////////////////////////////////
// THROWING ERRORS

/**
 * Throws an ApiError
 * @param {string} [message] the message of the error
 * @param {string} [type] the ERROR_TYPE
 * @returns a function that given any result, it will return a promise rejection with a new ApiError
 */
export const throwError = (message, type) => result => Promise.reject(new ApiError({ type, message, result }))

/**
 * Perform an action with the given error and re-throw it again.
 * @param {function} fn the function (let's call it 'action')
 * @returns a function that given any error, it will execute the action and re-throw it again
 */
export const rethrowError = fn => (error) => {
    fn(error)
    throw error
}

// //////////////////////////////////////////////////////////////////////////////
// MISCELANEA

export const sleep = ms => result => new Promise(resolve => setTimeout(() => resolve(result), ms))

// do one or a list of functions returning finally the input result (synchronouly)
export const task = (...functionList) => (result) => {
    functionList.forEach(fn => fn(result))
    return result
}
// do one or a list of functions returning finally the input result (asynchronouly)
export const asyncTask = (...functionList) => (result) => {
    // eslint-disable-next-line no-new
    new Promise(() => {
        task(...functionList)(result)
    })
    return result
}

export const pipe = (...functionOrPromiseList) => result => functionOrPromiseList.reduce(
    (prev, current) => prev.then(r => current(r)),
    new Promise(resolve => resolve(result)),
)
