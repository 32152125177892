<template>
    <div class="ActivitiesContainer">
        <WText class="mb-1">
            {{ title }}
        </WText>
        <div class="Activities flex row-left-center">
            <WCard
                v-for="({ label, id, readOnly }) in options"
                :key="id"
                shadow
                :disabled="readOnly"
            >
                <div
                    class="Item flex row-left-center flex-nowrap"
                    @click="handleClickFilter({ label, id, readOnly })"
                >
                    <FilterIcon :id="id" />
                    <WText
                        class="pl-1"
                        tag="p"
                        weight="medium"
                    >
                        {{ label }}
                    </WText>
                </div>
            </WCard>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import FilterIcon from './FilterIcon.vue'

export default {
    name: 'FilterModalSection',
    components: { FilterIcon },
    props: {
        options: VueTypes.array,
        title: VueTypes.string,
    },
    methods: {
        handleClickFilter ({ label, id, readOnly }) {
            if (!readOnly) this.$emit('onClickFilter', { label, id })
        },
    },
}
</script>
<style lang="scss" scoped>
    .ActivitiesContainer {
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
    }
    .Activities  {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
        .WCard {
            height: 100%;
            display: flex;
            align-items: center;
            width: 100%;
            &::v-deep .Body {
                width: 100%;
                padding: 0;
                .Item {
                    padding: 20px;
                }
            }
        }
        .WCard:hover{
            box-shadow: 0px 0px 5px 5px $alpha-primary;
            border: solid 1px rgba(68, 68, 68, 0.2);
        }
        @media screen and (min-width: $md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (min-width: $lg) {
            grid-template-columns: repeat(3, minmax(10px, 280px));
        }
        @media screen and (min-width: $xl) {
            grid-template-columns: repeat(4, minmax(10px, 280px));
        }
    }
    .WCard {
        cursor: pointer;
        width: fit-content;
    }
</style>
