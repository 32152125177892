export default {
    data: [
        {
            activities: [
                {
                    attributes: {

                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: -1,
                    name: 'START',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 0,
                    name: 'Start_process',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 4,
                    name: 'O_Create Offer',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 3,
                    name: 'O_Created',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 5,
                    name: 'O_Sent (mail and online)',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 8,
                    name: 'O_Cancelled',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {
                        MonthlyCost: {
                            avg: 234.19235294117644,
                            max: 400.0,
                            min: 120.0,
                            sd: 100.52935436270003,
                        },
                        FirstWithdrawalAmount: {
                            avg: 4505.527058823529,
                            max: 35000.0,
                            min: 0.0,
                            sd: 8997.57650102042,
                        },
                        CreditScore: {
                            avg: 0.0,
                            max: 0.0,
                            min: 0.0,
                            sd: 0.0,
                        },
                        OfferedAmount: {
                            avg: 18000.0,
                            max: 40000.0,
                            min: 5000.0,
                            sd: 11003.26154855379,
                        },
                        NumberOfTerms: {
                            avg: 90.94117647058823,
                            max: 127.0,
                            min: 36.0,
                            sd: 32.44990816308095,
                        },
                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: 9,
                    name: 'End_process',
                    relativeFrequency: 0.0,
                },
                {
                    attributes: {

                    },
                    duration: {
                        avg: 0.0,
                        max: 0.0,
                        min: 0.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    id: -2,
                    name: 'END',
                    relativeFrequency: 0.0,
                },
            ],
            arcs: [
                {
                    duration: {
                        avg: 1.0,
                        max: 1.0,
                        min: 1.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    name: 'START - Start_process',
                    relativeFrequency: 0.0,
                    source: -1,
                    target: 0,
                },
                {
                    duration: {
                        avg: 1.0,
                        max: 1.0,
                        min: 1.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    name: 'Start_process - O_Create Offer',
                    relativeFrequency: 0.0,
                    source: 0,
                    target: 4,
                },
                {
                    duration: {
                        avg: 1315.5294117647059,
                        max: 1551.0,
                        min: 1157.0,
                        sd: 107.24963603232294,
                    },
                    frequency: 17,
                    name: 'O_Create Offer - O_Created',
                    relativeFrequency: 0.0,
                    source: 4,
                    target: 3,
                },
                {
                    duration: {
                        avg: 108142.70588235294,
                        max: 711012.0,
                        min: 10075.0,
                        sd: 180843.3961512052,
                    },
                    frequency: 17,
                    name: 'O_Created - O_Sent (mail and online)',
                    relativeFrequency: 0.0,
                    source: 3,
                    target: 5,
                },
                {
                    duration: {
                        avg: 2.6532842741764708E9,
                        max: 5.002735835E9,
                        min: 1152820.0,
                        sd: 1.786970586273535E9,
                    },
                    frequency: 17,
                    name: 'O_Sent (mail and online) - O_Cancelled',
                    relativeFrequency: 0.0,
                    source: 5,
                    target: 8,
                },
                {
                    duration: {
                        avg: 1.0,
                        max: 1.0,
                        min: 1.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    name: 'O_Cancelled - End_process',
                    relativeFrequency: 0.0,
                    source: 8,
                    target: 9,
                },
                {
                    duration: {
                        avg: 1.0,
                        max: 1.0,
                        min: 1.0,
                        sd: 0.0,
                    },
                    frequency: 17,
                    name: 'End_process - END',
                    relativeFrequency: 0.0,
                    source: 9,
                    target: -2,
                },
            ],
            duration: {
                avg: 0.0,
                max: 0.0,
                min: 0.0,
                sd: 0.0,
            },
            frequency: 0,
            name: '',
            relativeFrequency: 0.40476190476190477,
        },
    ],
    total: 1,
}
