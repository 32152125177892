var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("QTable", {
          ref: "myTable",
          staticClass: "my-sticky-header-table",
          attrs: {
            data: _vm.rows,
            columns: _vm.columns,
            flat: "",
            filter: _vm.filter,
            outlined: "",
            separator: "vertical",
            bordered: "",
            virtualScroll: "",
            pagination: _vm.pagination,
            rowsPerPageOptions: _vm.rowsPerPageOptions
          },
          on: {
            "update:pagination": function($event) {
              _vm.pagination = $event
            },
            request: _vm.emitRequest
          },
          scopedSlots: _vm._u([
            {
              key: "body",
              fn: function(props) {
                return [
                  _c(
                    "QTr",
                    {
                      staticClass: "cursor-pointer",
                      class: {
                        "selected-row":
                          props.row.caseId == _vm.selectedRow.caseId
                      },
                      attrs: { props: props },
                      on: {
                        click: function($event) {
                          return _vm.selectRow(props.row)
                        }
                      }
                    },
                    [
                      _c(
                        "QTd",
                        { key: "caseId", staticClass: "caseId" },
                        [
                          _vm._v(" " + _vm._s(props.row.caseId) + " "),
                          _c("QTooltip", [
                            _vm._v(" " + _vm._s(props.row.caseId) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("QTd", { key: "lastActivity" }, [
                        _vm._v(" " + _vm._s(props.row.lastActivity) + " ")
                      ]),
                      _c(
                        "QTd",
                        { key: "riskLevel", staticClass: "text-center" },
                        [
                          _vm._v(" " + _vm._s(props.row.riskLevel) + " "),
                          _c("QIcon", {
                            attrs: {
                              name: "flag",
                              color: _vm.flagColor(props.row.riskLevel),
                              size: "20px"
                            }
                          })
                        ],
                        1
                      ),
                      _c("QTd", { key: "estimatedVariant" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.row.estimatedVariant === "New variant"
                                ? _vm.$t(
                                    "visualization.predictions.table.newVariant"
                                  )
                                : props.row.estimatedVariant
                            ) +
                            " "
                        )
                      ]),
                      _c("QTd", { key: "currentDuration" }, [
                        _vm._v(" " + _vm._s(props.row.currentDuration) + " ")
                      ]),
                      _c("QTd", { key: "estimatedDuration" }, [
                        _vm._v(" " + _vm._s(props.row.estimatedDuration) + " ")
                      ])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "bottom-left",
              fn: function(ref) {
                var pagination = ref.pagination
                return [
                  _c("QSelect", {
                    attrs: {
                      options: _vm.rowsPerPageOptions,
                      outlined: "",
                      dense: ""
                    },
                    model: {
                      value: pagination.rowsPerPage,
                      callback: function($$v) {
                        _vm.$set(pagination, "rowsPerPage", $$v)
                      },
                      expression: "pagination.rowsPerPage"
                    }
                  })
                ]
              }
            },
            {
              key: "bottom-right",
              fn: function(ref) {
                var pagination = ref.pagination
                return [
                  _c("QPagination", {
                    attrs: { min: 1, max: _vm.total, dense: "" },
                    model: {
                      value: pagination.page,
                      callback: function($$v) {
                        _vm.$set(pagination, "page", $$v)
                      },
                      expression: "pagination.page"
                    }
                  })
                ]
              }
            },
            {
              key: "no-data",
              fn: function(ref) {
                var message = ref.message
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-width row flex-center text-accent q-gutter-sm"
                    },
                    [_c("span", [_vm._v(" " + _vm._s(message) + " ")])]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }