var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-2" },
    [
      _c(
        "WText",
        {
          staticClass: "pb-1",
          attrs: { align: "left", typo: "h2", weight: "bold" }
        },
        [_vm._v(" " + _vm._s(_vm.selectedFilter.label) + " ")]
      ),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.handleAddAttribute.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c("QSelect", {
                ref: "activty",
                staticClass: "Field flex-1",
                attrs: {
                  dense: "",
                  outlined: "",
                  behavior: "menu",
                  label: _vm.$t("visualization.filters.activities.label"),
                  rules: [
                    function(value) {
                      return (
                        _vm.validators.required(value) ||
                        _vm.$t("validation.required", {
                          attribute: _vm.$t(
                            "visualization.filters.activities.title"
                          )
                        })
                      )
                    }
                  ],
                  options: _vm.categoriesOptions
                },
                model: {
                  value: _vm.attrFilter.activity,
                  callback: function($$v) {
                    _vm.$set(_vm.attrFilter, "activity", $$v)
                  },
                  expression: "attrFilter.activity"
                }
              }),
              _c("QSelect", {
                ref: "attribute",
                staticClass: "Field flex-1",
                attrs: {
                  dense: "",
                  outlined: "",
                  behavior: "menu",
                  label: _vm.$t("visualization.filters.attributes.label"),
                  rules: [
                    function(value) {
                      return (
                        _vm.validators.required(value) ||
                        _vm.$t("validation.required", {
                          attribute: _vm.$t(
                            "visualization.filters.attributes.label"
                          )
                        })
                      )
                    }
                  ],
                  options: _vm.attributesOptions
                },
                on: { input: _vm.setAttributeOptionValues },
                model: {
                  value: _vm.attrFilter.attribute,
                  callback: function($$v) {
                    _vm.$set(_vm.attrFilter, "attribute", $$v)
                  },
                  expression: "attrFilter.attribute"
                }
              }),
              _c("QSelect", {
                ref: "constraints",
                staticClass: "Field flex-1",
                attrs: {
                  dense: "",
                  outlined: "",
                  behavior: "menu",
                  label: _vm.$t("visualization.filters.constraints.label"),
                  rules: [
                    function(value) {
                      return (
                        _vm.validators.required(value) ||
                        _vm.$t("validation.required", {
                          attribute: _vm.$t(
                            "visualization.filters.constraints.title"
                          )
                        })
                      )
                    }
                  ],
                  options: _vm.ATTRIBUTTE_TYPES[_vm.selectedFilter.id]
                },
                model: {
                  value: _vm.attrFilter.constraints,
                  callback: function($$v) {
                    _vm.$set(_vm.attrFilter, "constraints", $$v)
                  },
                  expression: "attrFilter.constraints"
                }
              }),
              _vm.selectedFilter.id !== _vm.FILTERS_TYPES.ATTRIB_BOOLEAN
                ? _c(
                    "div",
                    [
                      _vm.selectedFilter.id === _vm.FILTERS_TYPES.ATTRIB_DATE
                        ? _c("QInput", {
                            ref: "date",
                            staticClass: "Field",
                            attrs: {
                              type: "date",
                              rules: [
                                function(value) {
                                  return (
                                    _vm.validators.required(value) ||
                                    _vm.$t("validation.required", {
                                      attribute: _vm.$t(
                                        "visualization.filters.attributes.value.label"
                                      )
                                    })
                                  )
                                }
                              ],
                              outlined: ""
                            },
                            model: {
                              value: _vm.attrFilter.value,
                              callback: function($$v) {
                                _vm.$set(_vm.attrFilter, "value", $$v)
                              },
                              expression: "attrFilter.value"
                            }
                          })
                        : _vm.selectedFilter.id ===
                          _vm.FILTERS_TYPES.ATTRIB_NUMBERS
                        ? _c("QInput", {
                            ref: "numberInput",
                            staticClass: "Field",
                            attrs: {
                              type: "number",
                              label: _vm.$t(
                                "visualization.filters.attributes.value.label"
                              ),
                              rules: [
                                function(value) {
                                  return (
                                    _vm.validators.required(value) ||
                                    _vm.$t("validation.required", {
                                      attribute: _vm.$t(
                                        "visualization.filters.attributes.value.label"
                                      )
                                    })
                                  )
                                }
                              ],
                              outlined: ""
                            },
                            model: {
                              value: _vm.attrFilter.value,
                              callback: function($$v) {
                                _vm.$set(_vm.attrFilter, "value", $$v)
                              },
                              expression: "attrFilter.value"
                            }
                          })
                        : _vm.selectedFilter.id ===
                            _vm.FILTERS_TYPES.ATTRIB_TEXT && !_vm.isMultiple
                        ? _c("QSelect", {
                            ref: "textSingleInput",
                            staticClass: "Field",
                            attrs: {
                              label: _vm.$t(
                                "visualization.filters.attributes.value.label"
                              ),
                              rules: [
                                function(value) {
                                  return (
                                    _vm.validators.required(value) ||
                                    _vm.$t("validation.required", {
                                      attribute: _vm.$t(
                                        "visualization.filters.attributes.value.label"
                                      )
                                    })
                                  )
                                }
                              ],
                              outlined: "",
                              dense: "",
                              useInput: "",
                              lazyRules: "",
                              lazy: "",
                              behavior: "menu",
                              options: _vm.attrValueOptionsFiltered
                            },
                            on: { filter: _vm.attributeFilterFn },
                            model: {
                              value: _vm.attrFilter.value,
                              callback: function($$v) {
                                _vm.$set(_vm.attrFilter, "value", $$v)
                              },
                              expression: "attrFilter.value"
                            }
                          })
                        : _vm.selectedFilter.id ===
                            _vm.FILTERS_TYPES.ATTRIB_TEXT && _vm.isMultiple
                        ? _c("QSelect", {
                            ref: "textMultipleInput",
                            staticClass: "Field",
                            attrs: {
                              label: _vm.$t(
                                "visualization.filters.attributes.value.label"
                              ),
                              rules: [
                                function(value) {
                                  return (
                                    _vm.validators.required(value.length > 0) ||
                                    _vm.$t("validation.required", {
                                      attribute: _vm.$t(
                                        "visualization.filters.attributes.value.label"
                                      )
                                    })
                                  )
                                }
                              ],
                              multiple: true,
                              outlined: "",
                              dense: "",
                              useInput: "",
                              lazyRules: "",
                              lazy: "",
                              behavior: "menu",
                              options: _vm.attrValueOptionsFiltered
                            },
                            on: { filter: _vm.attributeFilterFn },
                            model: {
                              value: _vm.attrFilter.values,
                              callback: function($$v) {
                                _vm.$set(_vm.attrFilter, "values", $$v)
                              },
                              expression: "attrFilter.values"
                            }
                          })
                        : _vm._e(),
                      _vm.isMultiple && _vm.attrFilter.values.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "chip-container" },
                            _vm._l(_vm.attrFilter.values, function(
                              selected,
                              index
                            ) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _c(
                                    "q-chip",
                                    {
                                      attrs: {
                                        label: selected,
                                        removable: "",
                                        color: "primary",
                                        textColor: "white"
                                      },
                                      on: {
                                        remove: function($event) {
                                          return _vm.deleteIndexValue(
                                            index,
                                            _vm.attrFilter.values
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(selected.label) + " ")]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ButtonContainer mt-1" },
            [
              _c("WButton", { attrs: { outline: "", type: "submit" } }, [
                _vm._v(" " + _vm._s(_vm.$t("visualization.filters.add")) + " ")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "TableContainer mt-1" },
        [
          _c("QTable", {
            attrs: {
              title: _vm.$t("visualization.filters.attributes.table.title"),
              data: _vm.rows,
              rowsPerPageOptions: [0],
              pagination: { page: 1, rowsPerPage: 0 },
              hideBottom: !!_vm.rows.length,
              columns: _vm.columns,
              rowKey: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "no-data",
                fn: function(ref) {
                  var message = ref.message
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "full-width row flex-center text-accent q-gutter-sm"
                      },
                      [_c("span", [_vm._v(" " + _vm._s(message) + " ")])]
                    )
                  ]
                }
              },
              {
                key: "body-cell-constraints",
                fn: function(props) {
                  return [
                    _c("QTd", { attrs: { props: props } }, [
                      _vm._v(" " + _vm._s(props.row.constraints.label) + " ")
                    ])
                  ]
                }
              },
              {
                key: "body-cell-activity",
                fn: function(props) {
                  return [
                    _c("QTd", { attrs: { props: props } }, [
                      _vm._v(" " + _vm._s(props.row.activity.label) + " ")
                    ])
                  ]
                }
              },
              {
                key: "body-cell-attribute",
                fn: function(props) {
                  return [
                    _c("QTd", { attrs: { props: props } }, [
                      _vm._v(" " + _vm._s(props.row.attribute.label) + " ")
                    ])
                  ]
                }
              },
              {
                key: "body-cell-value",
                fn: function(props) {
                  return [
                    _c("QTd", { attrs: { props: props } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatAttributeValue(props.row.value)) +
                          " "
                      )
                    ])
                  ]
                }
              },
              {
                key: "body-cell-delete",
                fn: function(props) {
                  return [
                    _c("QTd", { key: "delete", staticClass: "td-center" }, [
                      _c(
                        "div",
                        { staticClass: "Delete" },
                        [
                          _c("WIcon", {
                            attrs: {
                              icon: "trash",
                              size: 24,
                              color: "secondary",
                              clickable: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteAttribute(props)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ButtonContainer mt-1" },
        [
          _c(
            "WButton",
            {
              attrs: { disabled: !(_vm.rows || []).length },
              on: { click: _vm.handleSaveFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("visualization.filters.save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }