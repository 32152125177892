var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "QCard",
    { attrs: { flat: "", bordered: "" } },
    [
      _c(
        "QCardSection",
        [
          _c("QSelect", {
            attrs: { options: _vm.usableAttribs },
            on: { input: _vm.notifyAttrChange },
            model: {
              value: _vm.selectedValue,
              callback: function($$v) {
                _vm.selectedValue = $$v
              },
              expression: "selectedValue"
            }
          })
        ],
        1
      ),
      _c(
        "QCardSection",
        { staticClass: "CauseListing" },
        [
          _vm.infoData
            ? _c(
                "QList",
                _vm._l(_vm.infoData.data, function(item, idx) {
                  return _c(
                    "QItem",
                    { key: idx },
                    [
                      _c(
                        "QItemSection",
                        [
                          _c("QItemLabel", [
                            _vm._v(" " + _vm._s(item.attributeValue) + " ")
                          ]),
                          _c("QLinearProgress", {
                            attrs: {
                              value: _vm.elementPercentage(
                                item.attributeCount,
                                _vm.total
                              ),
                              size: "20px",
                              rounded: "",
                              color: "secondary"
                            }
                          }),
                          _c(
                            "QItemLabel",
                            { staticClass: "text-caption text-italic" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.App.numberLocationFormat(
                                      item.attributeCount
                                    ) +
                                      " " +
                                      _vm.occurenceLiteral(item.attributeCount)
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }