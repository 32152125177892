<template>
    <Transition
        name="modal"
        :duration="500"
    >
        <div
            v-if="isOpen"
            class="Modal"
        >
            <div
                class="Dialog"
            >
                <div
                    v-if="$slots.header"
                    class="Header"
                >
                    <slot
                        name="header"
                    />
                    <WIcon
                        v-if="closeBtn && dissmisable"
                        clickable
                        icon="close"
                        color="primary"
                        class="Close"
                        @click="$emit('close')"
                    />
                </div>
                <div
                    v-if="$slots.body"
                    class="Body"
                >
                    <slot name="body" />
                </div>
                <div
                    v-if="$slots.footer"
                    class="Footer"
                >
                    <slot name="footer" />
                </div>
            </div>
            <div
                class="Backdrop"
                @click="dissmisable ? $emit('close') : null"
            />
        </div>
    </Transition>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Modal',
    props: {
        isOpen: VueTypes.bool.def(true),
        closeBtn: VueTypes.bool.def(true),
        dissmisable: VueTypes.bool.def(true),
    },
    watch: {
        isOpen (val) {
            if (val) document.body.classList.add('no-scroll')
            else document.body.classList.remove('no-scroll')
        },
    },
    beforeDestroy () {
        document.body.classList.remove('no-scroll')
    },
}
</script>
<style scoped lang="scss">
$space: 30px;

.Modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $modal-backdrop;
    z-index: 9997;
}

.Dialog {
    position: relative;
    width: auto;
    max-width: 100%;
    margin: 0px auto;
    border-radius: $border-radius;
    background: $modal-background;
    // box-shadow: $shadow;
    z-index: 9998;

    @media screen and (min-width: $md) {
        max-width: 550px;
    }
}
.Close {
    position: absolute;
    top: $space;
    right: $space;
    color: $black;
    font-size: 24px;
    opacity: .65;
    cursor: pointer;
}
.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space $space 0 $space;
}
.Body {
    padding: 0 $space;
}
.Footer {
    padding: $space;
}

.modal-enter-active,
.modal-leave-active {
    .Dialog {
        transition-duration: 0.5s;
        transition-property: opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    }
    .Backdrop {
        transition: opacity 0.5s ease-in-out;
    }
}

.modal-enter,
.modal-leave-to {
    .Backdrop {
        opacity: 0;
    }
    .Dialog {
        opacity: 0;
        transform: translateY(-50px);
    }
}
</style>
