const notifications = [
    {
        content: 'Content of notification',
        createdAt: '2020-05-21T10:18:39',
        organization: {
            description: 'Organization description',
            isOwner: true,
            name: 'Organization 1',
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            owner: true,
        },
        seenAt: '2020-05-21T10:18:39',
        title: 'Notification 1',
        type: 'type 1',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
    {
        content: '<p> Content of notification </p>',
        createdAt: '2020-05-21T10:18:39',
        organization: {
            description: 'Organization description',
            isOwner: true,
            name: 'Organization 1',
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            owner: true,
        },
        seenAt: '',
        title: 'Notification 1',
        type: 'type 1',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
]

export default {
    data: notifications,
    total: notifications.length,
}
