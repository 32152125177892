var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "QTabPanels",
        {
          attrs: {
            animated: "",
            transitionNext: "fade",
            transitionPrev: "fade"
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "QTabPanel",
            { attrs: { name: "TABLE" } },
            [
              _c("TracesTable", {
                ref: "tracesTable",
                staticClass: "TracesTable mt-1",
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.rows,
                  loading: _vm.isLoading,
                  total: _vm.total,
                  filteredTraces: _vm.filteredTraces
                },
                on: {
                  "table-request": _vm.handleFetchData,
                  download: _vm.openExport,
                  switchView: _vm.toggleView,
                  datasetFromFiltered: _vm.generateDataset
                }
              }),
              _c("AttributeSelector", {
                ref: "attrSelector",
                attrs: { attrs: _vm.attributes },
                on: { export: _vm.handleDownload }
              })
            ],
            1
          ),
          _c(
            "QTabPanel",
            { attrs: { name: "PLOT" } },
            [
              _c("TracesPlot", {
                attrs: {
                  processId: _vm.processId,
                  activities: _vm.activities,
                  colors: _vm.colors
                },
                on: { switchView: _vm.toggleView }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }