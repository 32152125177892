import { render, staticRenderFns } from "./SlaSettings.vue?vue&type=template&id=5cd9d2b4&scoped=true&"
import script from "./SlaSettings.vue?vue&type=script&lang=js&"
export * from "./SlaSettings.vue?vue&type=script&lang=js&"
import style0 from "./SlaSettings.vue?vue&type=style&index=0&id=5cd9d2b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd9d2b4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/porto/inverbis/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5cd9d2b4')) {
      api.createRecord('5cd9d2b4', component.options)
    } else {
      api.reload('5cd9d2b4', component.options)
    }
    module.hot.accept("./SlaSettings.vue?vue&type=template&id=5cd9d2b4&scoped=true&", function () {
      api.rerender('5cd9d2b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/screens/Project/Process/Visualization/sections/SettingsModal/components/SlaSettings.vue"
export default component.exports