export default {
    process: {
        summary: 'Resumen general',
        alternative: 'Camino principal',
        duration: 'Análisis de duración',
        frequency: 'Análisis de frecuencia',

        processAlternatives: 'Caminos alternativos de proceso',
        activities: 'Actividades',
        transitions: 'Transiciones',
        durationHours: 'Duración (horas)',
        numberOfCases: 'Número de casos',
        numberOfCasesDuration: 'Número de casos por duración',
        caseDuration: 'Histograma de duración del caso',

        fequentActivities: 'Actividades más frecuentes',
        fequentAlternatives: 'Alternativas de proceso más frecuentes',

        alternatives: 'alternativas',
        avg: 'tiempo de ciclo medio',

        averageDuration: 'Duración media de las actividades',
        standardDeviation: 'Desviación estándar de actividades',

        averageDurationAlertnatives: 'Duración media de las alternativas de proceso',
        standardDeviationAlertnatives: 'Desviación temporal de alternativas de proceso',

        averageTransition: 'Tiempo promedio de transición',
        transitionDeviations: 'Desviación temporal de transición',
    },

    query: {
        conformance: 'Resumen de proceso',
        duration: 'Duración',
        traces: 'trazas',
        averageFitness: 'Grado de ajuste medio',
        averageDuration: 'Duración media',
        nonCompliantTracesPercentage: 'No cumple',
        compliantTracesPercentage: 'Cumple',
        complianceIndicator: 'Indicador de cumplimiento: {complianceIndicator}',
        casesQt: 'Número de casos: {casesQt}',
        insightResults: 'Resultados de conocimiento',
        insightModel: 'Modelo de conocimiento',
        insightConstraints: 'Restricciones de conocimiento',
        casesAnalyzed: 'Casos analizados',
    },

    notifications: {
        download: {
            text: 'Informe descargado correctamente.',
        },
    },
}
