var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "WText",
            { staticClass: "Title", attrs: { typo: "h4", tag: "h1" } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _c("WText", { attrs: { tag: "p" } }, [
        _vm._v(" " + _vm._s(_vm.description) + " ")
      ]),
      _c("InsightExamples", { attrs: { examples: _vm.examples } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }