var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvitationsList" },
    [
      _c("Table", {
        ref: "table",
        staticClass: "Table",
        attrs: {
          columns: _vm.cols,
          data: _vm.normalizeRows,
          total: _vm.total,
          loading: _vm.loading,
          hideHeader: _vm.hideHeader,
          rowKey: "uuid"
        },
        on: { "table-request": _vm.handleFetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "QTr",
                  [
                    _c(
                      "QTd",
                      [
                        _c("Badge", {
                          attrs: {
                            label: _vm.$t("invitations." + row.status),
                            color: _vm.getBadgeType(row.status)
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "email", staticClass: "td-email" },
                      [
                        _c("WText", { attrs: { size: 14 } }, [
                          _vm._v(" " + _vm._s(row.email) + " ")
                        ])
                      ],
                      1
                    ),
                    _c("QTd", { key: "date", staticClass: "td-date" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.App.dateLocationFormat(
                                _vm.getDate(row) | _vm.date(),
                                "long"
                              )
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c(
                      "QTd",
                      { key: "actions", staticClass: "Actions align-right" },
                      [
                        row.action
                          ? _c(
                              "WButton",
                              {
                                attrs: {
                                  color: "error",
                                  outline: "",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEmmit(row)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(row.action.label) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c("EmptyList", {
                  staticClass: "mt-2 mb-2",
                  attrs: {
                    title: _vm.$t("invitations.notifications.noResult.title"),
                    text: _vm.$t("invitations.notifications.noResult.text")
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function() {
                        return [
                          _c(
                            "WButton",
                            {
                              staticClass: "mt-1",
                              attrs: { iconLeft: "plus-circle" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("onNewItem")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("invitations.new")) + " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }