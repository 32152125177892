var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "QCard",
        { staticClass: "Card" },
        [
          _c(
            "QTabs",
            {
              attrs: {
                dense: "",
                inlineLabel: "",
                noCaps: "",
                activeColor: "primary",
                indicatorColor: "primary",
                align: "justify"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("QTab", {
                attrs: {
                  name: "activity",
                  label: _vm.$t("booster.stateView.activity"),
                  icon: "format_shapes"
                }
              }),
              _c("QTab", {
                attrs: {
                  name: "transition",
                  label: _vm.$t("booster.stateView.transition"),
                  icon: "arrow_forward"
                }
              }),
              _c("QTab", {
                attrs: {
                  name: "variant",
                  label: _vm.$t("booster.stateView.variant"),
                  icon: "device_hub"
                }
              })
            ],
            1
          ),
          _c("QSeparator"),
          _c(
            "QTabPanels",
            {
              staticClass: "Panel",
              staticStyle: { height: "65%", "margin-top": "1%" },
              attrs: { dense: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            _vm._l(_vm.attributes, function(tabName, index) {
              return _c(
                "QTabPanel",
                { key: index, attrs: { name: tabName, label: tabName } },
                [
                  _c("PerformanceBy", {
                    attrs: { selected: tabName },
                    on: { selectedOptions: _vm.selectedOptions }
                  })
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "SelectFilteringTitle" },
            [
              _c(
                "div",
                { staticStyle: { margin: "auto 10px" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("booster.stateView.selectFilteringElements")
                      ) +
                      " "
                  ),
                  _c(
                    "QIcon",
                    {
                      attrs: { color: "primary", size: "1.2em", name: "help" }
                    },
                    [
                      _c("QTooltip", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("booster.stateView.performanceByClick")
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("QSpace"),
              _c(
                "div",
                [
                  _c("QBtn", {
                    attrs: {
                      flat: "",
                      round: "",
                      size: "ml",
                      color: "primary",
                      icon: "restart_alt"
                    },
                    on: { click: _vm.reset }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "ChipContainer" }, [
            _c("div", { staticStyle: { width: "95%" } }, [
              _vm.selectedOpt.activity.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.selectedOpt.activity, function(activity, index) {
                      return _c(
                        "QChip",
                        {
                          key: index,
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteChip("activity", index)
                            }
                          }
                        },
                        [
                          _c("QIcon", {
                            staticClass: "icon",
                            staticStyle: {
                              "margin-right": "3px",
                              size: "1.4em"
                            },
                            attrs: { name: "format_shapes" }
                          }),
                          _vm._v(" " + _vm._s(activity) + " ")
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.selectedOpt.transition.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.selectedOpt.transition, function(
                      transition,
                      index
                    ) {
                      return _c(
                        "QChip",
                        {
                          key: index,
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteChip("transition", index)
                            }
                          }
                        },
                        [
                          _c("QIcon", {
                            staticClass: "icon",
                            staticStyle: {
                              "margin-right": "3px",
                              size: "1.4em"
                            },
                            attrs: { name: "arrow_forward" }
                          }),
                          _vm._v(" " + _vm._s(transition) + " ")
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.selectedOpt.variant.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.selectedOpt.variant, function(variant, index) {
                      return _c(
                        "QChip",
                        {
                          key: index,
                          attrs: {
                            removable: "",
                            color: "primary",
                            textColor: "white"
                          },
                          on: {
                            remove: function($event) {
                              return _vm.deleteChip("variant", index)
                            }
                          }
                        },
                        [
                          _c("QIcon", {
                            staticClass: "icon",
                            staticStyle: {
                              "margin-right": "3px",
                              size: "1.4em"
                            },
                            attrs: { name: "device_hub" }
                          }),
                          _vm._v(" " + _vm._s(variant) + " ")
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }