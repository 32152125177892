var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "NameDescForm" }, [
    _c(
      "div",
      { staticClass: "Form mb-1" },
      [
        _c("QInput", {
          ref: "name",
          staticClass: "Field",
          attrs: {
            lazyRules: "",
            dense: _vm.dense,
            maxlength: "100",
            outlined: "",
            label: _vm.$t("general.name"),
            hint: "5 - 100 " + _vm.$t("general.characters"),
            rules: [
              function(value) {
                return (
                  _vm.validators.betweenLength(value, 5, 100) ||
                  _vm.$t("validation.rangeLength", {
                    attribute: _vm.$t("general.name"),
                    min: 5,
                    max: 100
                  })
                )
              },
              function(value) {
                return (
                  _vm.validators.allowedChars(value) ||
                  _vm.$t("validation.allowedChars", {
                    attribute: _vm.$t("general.name")
                  })
                )
              }
            ]
          },
          model: {
            value: _vm.value.name,
            callback: function($$v) {
              _vm.$set(_vm.value, "name", $$v)
            },
            expression: "value.name"
          }
        }),
        !_vm.withoutDesc
          ? _c("QInput", {
              staticClass: "Field mt-1",
              attrs: {
                outlined: "",
                autogrow: "",
                dense: _vm.dense,
                maxlength: "500",
                lazyRules: "",
                label: _vm.$t("general.description"),
                hint: "Max. 500 " + _vm.$t("general.characters"),
                rules: [
                  function(value) {
                    return (
                      _vm.validators.optional(value) ||
                      _vm.validators.maxLength(value, 500) ||
                      _vm.$t("validation.maxLength", {
                        attribute: _vm.$t("general.description"),
                        max: 500
                      })
                    )
                  }
                ]
              },
              model: {
                value: _vm.value.description,
                callback: function($$v) {
                  _vm.$set(
                    _vm.value,
                    "description",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "value.description"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.action
      ? _c(
          "div",
          { staticClass: "flex row-right-center" },
          [
            _c(
              "WButton",
              {
                staticClass: "Reset",
                attrs: { id: "reset", color: "none" },
                on: { click: _vm.handleReset }
              },
              [_vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")]
            ),
            _c(
              "WButton",
              {
                staticClass: "ml-1",
                attrs: {
                  id: "submit",
                  loading: _vm.loading,
                  disabled: _vm.loading
                },
                on: { click: _vm.handleSubmit }
              },
              [_vm._v(" " + _vm._s(_vm.action) + " ")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }