var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "PredictionAlgorithmSettings" }, [
        _c(
          "div",
          { staticClass: "PredictionAlgorithm" },
          [
            _c(
              "WText",
              {
                staticClass: "mb-1",
                attrs: { weight: "bold", color: "primary" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("visualization.settings.predictionAlgorithm.name")
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "OptionsGroup row justify-start" },
              [
                _c("WText", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "visualization.settings.predictionAlgorithm.chooseAlgorithm"
                        )
                      ) +
                      " "
                  )
                ]),
                _c("QSelect", {
                  staticClass: "col",
                  attrs: {
                    outlined: "",
                    labelColor: "primary",
                    color: "primary",
                    options: _vm.algorithms
                  },
                  on: { input: _vm.handleAlgorithmChange },
                  model: {
                    value: _vm.algorithm,
                    callback: function($$v) {
                      _vm.algorithm = $$v
                    },
                    expression: "algorithm"
                  }
                })
              ],
              1
            ),
            _vm.algorithm === "Machine Learning" ||
            _vm.algorithm.value === "Machine Learning"
              ? _c("WText", [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.settings.predictionAlgorithm.algorithms.machineLearning.caseDurationCheck"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        class: _vm.getStatusColorClass(
                          _vm.values.durationModelStatus
                        )
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.values.durationModelStatus) + " "
                        )
                      ]
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "visualization.settings.predictionAlgorithm.algorithms.machineLearning.activitySequenceCheck"
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        class: _vm.getStatusColorClass(
                          _vm.values.sequenceModelStatus
                        )
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.values.sequenceModelStatus) + " "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm.algorithm
              ? _c("WText", [
                  _vm.algorithm === "Standard" ||
                  _vm.algorithm.value === "Standard"
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "visualization.settings.predictionAlgorithm.algorithms.standard.description"
                              )
                            ) +
                            " "
                        )
                      ])
                    : _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "visualization.settings.predictionAlgorithm.algorithms.machineLearning.description"
                              )
                            ) +
                            " "
                        )
                      ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row justify-end mt-1" },
              [
                _c(
                  "WButton",
                  {
                    staticClass: "buttons",
                    attrs: { outline: "", type: "submit", color: "primary" },
                    on: { click: _vm.savePredictionAlgorithm }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("visualization.filters.save")) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }