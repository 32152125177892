var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "WCard",
        { staticClass: "Password mb-2", attrs: { bordered: "" } },
        [
          _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
            _vm._v(" " + _vm._s(_vm.$t("settings.sections.apis")) + " ")
          ]),
          _c(
            "div",
            { staticClass: "Wrapper" },
            [
              _c("WText", { staticClass: "pb-0-5 mb-1" }, [
                _vm._v(" " + _vm._s(_vm.$t("settings.apis.text")) + " ")
              ]),
              _c(
                "div",
                [
                  _c("WButton", { on: { click: _vm.handleGenerate } }, [
                    _vm._v(" " + _vm._s(_vm.$t("settings.apis.generate")) + " ")
                  ]),
                  _c(
                    "WButton",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        outline: "",
                        color: "error",
                        disabled: !_vm.total
                      },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("settings.apis.cancel")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.total
            ? _c(
                "WCard",
                { staticClass: "Personal mb-2", attrs: { bordered: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex row-stretch-center mb-1" },
                    [
                      _c("WText", { attrs: { size: 18 } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("settings.sections.token")) + " "
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c("WIcon", {
                            attrs: {
                              icon: "copy",
                              size: 24,
                              color: "secondary",
                              clickable: ""
                            },
                            on: { click: _vm.handleCopyToClipboard }
                          }),
                          _c(
                            "QTooltip",
                            { attrs: { contentClass: "bg-secondary" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("settings.token.tooltip")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "TokenWrapper" },
                    [
                      _c("WText", { staticClass: "pb-0-5 mb-1" }, [
                        _vm._v(" " + _vm._s(_vm.token) + " ")
                      ])
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("DocModal", {
        ref: "DocModal",
        on: {
          close: function($event) {
            _vm.isModalOpened = false
          },
          onSubmit: _vm.handleSubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }