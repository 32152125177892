var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "QExpansionItem",
    {
      staticClass: "overflow-hidden q-ma-md bg-grey-2",
      staticStyle: { border: "grey solid 1px", "border-radius": "5px" },
      attrs: {
        icon: _vm.getSeverityIcon(_vm.row.severity),
        label: _vm.row.name,
        headerClass: "bg-grey-2",
        expandIconClass: ""
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "QItemSection",
                { attrs: { avatar: "" } },
                [
                  _c("QAvatar", {
                    staticClass: "text-white",
                    attrs: {
                      icon: _vm.getSeverityIcon(_vm.row.severity),
                      color: "" + _vm.getSeverityColor(_vm.row.severity)
                    }
                  })
                ],
                1
              ),
              _c("QItemSection", { staticClass: "text-weight-bold" }, [
                _vm._v(" " + _vm._s(_vm.row.name) + " ")
              ]),
              _c("QItemSection", { attrs: { side: "" } }, [
                _c(
                  "div",
                  { staticClass: "row items-center" },
                  [
                    _c(
                      "QAvatar",
                      {
                        staticClass: "text-white",
                        attrs: { color: "" + _vm.getTypeColor(_vm.row.type) }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.getTypeInitial(_vm.row.type)) + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c("QItemSection", { attrs: { side: "" } }, [
                _c(
                  "div",
                  { staticClass: "row items-center" },
                  _vm._l(_vm.getSeverityLevel(_vm.row.severity), function(n) {
                    return _c("QIcon", {
                      key: n,
                      attrs: {
                        name: "circle",
                        color: "" + _vm.getSeverityColor(_vm.row.severity),
                        size: "20px"
                      }
                    })
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row items-center" },
                  _vm._l(_vm.getSeverityLevel(_vm.row.risk), function(n) {
                    return _c("QIcon", {
                      key: n,
                      attrs: {
                        name: "circle",
                        color: "" + _vm.getSeverityColor(_vm.row.risk),
                        size: "20px"
                      }
                    })
                  }),
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "QCard",
        [
          _c("QCardSection", [
            _c("div", { staticClass: "row q-gutter-md" }, [
              _c("div", { staticClass: "column col" }, [
                _c("div", { staticClass: "col text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("visualization.alerts.detected.affected")) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "col text-primary" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.App.numberLocationFormat(_vm.row.affectedCases)
                      ) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "column col" }, [
                _c("div", { staticClass: "col text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.alerts.detected.lastDetected")
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "col text-primary" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.App.dateLocationFormat(_vm.row.timestamp, "long")
                      ) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "column col" }, [
                _c("div", { staticClass: "col text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("visualization.alerts.detected.severity")) +
                      " "
                  )
                ]),
                _vm.row.type === "STANDARD"
                  ? _c(
                      "div",
                      {
                        class:
                          "col text-" +
                          _vm.getSeverityColor(_vm.row.severity) +
                          "\n                        text-weight-bolder"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "visualization.alerts.detected.severityRiskLabels." +
                                  _vm.row.severity
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _c(
                      "div",
                      {
                        class:
                          "col text-" +
                          _vm.getSeverityColor(_vm.row.severity) +
                          "\n                        text-weight-bolder"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "visualization.alerts.detected.predictionRiskLabels." +
                                  _vm.row.severity
                              )
                            ) +
                            " "
                        )
                      ]
                    )
              ]),
              _c("div", { staticClass: "column col" }, [
                _c("div", { staticClass: "col text-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.alerts.detected.filterSet")
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "col text-primary" }, [
                  _vm._v(" " + _vm._s(Object.values(_vm.row.filters)[0]) + " ")
                ])
              ])
            ])
          ]),
          _c(
            "QCardActions",
            { attrs: { align: "right" } },
            [
              _c("QBtn", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: {
                  label: _vm.$t("visualization.alerts.detected.filterCases"),
                  noCaps: "",
                  color: "primary"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("filter", _vm.row)
                  }
                }
              }),
              !_vm.row.dismissed
                ? _c("QBtn", {
                    attrs: {
                      label: _vm.$t("visualization.alerts.detected.dismiss"),
                      noCaps: "",
                      color: "primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("dismiss", _vm.row)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }