<template>
    <div class="DatasetsList">
        <Table
            ref="table"
            :columns="cols"
            :data="normalizeRows"
            :loading="loading"
            :total="total"
            :hideHeader="hideHeader"
            rowKey="uuid"
            class="Table"
            @table-request="handleFetchData"
        >
            <template #content="{ row }">
                <QTr
                    :class="{'cursor-pointer': row.uploaded && row.state.identifier === 'FINISHED' }"
                >
                    <QTd
                        key="name"
                        class="td-name"
                        @click.native="handleGoToVisualization(row)"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.name }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            {{ $t('general.createdAt') }} {{ row.createDate|date }}
                        </WText>
                    </QTd>
                    <QTd
                        key="filename"
                        @click.native="handleGoToVisualization(row)"
                    >
                        <WText
                            ellipsis
                            class="ellipsis"
                        >
                            {{ row.uploaded ? row.fileName : '-' }}

                            <QTooltip
                                v-if="row.uploaded"
                                contentClass="bg-secondary"
                            >
                                {{ row.fileName }}
                            </QTooltip>
                        </WText>
                    </QTd>
                    <QTd key="common">
                        <fragment>
                            <div
                                v-if="manageActionsOptions(row) === ACTION_TYPES.UPLOADED"
                                class="flex row-left-center flex-nowrap"
                            >
                                <WButton
                                    v-for="(option, index) in (row.options || {}).common"
                                    :key="option.key"
                                    color="link"
                                    :class="{ 'ml-0-5': index}"
                                    @click="handleEmmit(option, row)"
                                >
                                    <WIcon
                                        :icon="option.icon"
                                        :size="24"
                                        color="secondary"
                                    />
                                    <QTooltip contentClass="bg-secondary">
                                        {{ option.label }}
                                    </QTooltip>
                                </WButton>
                            </div>
                            <Badge
                                v-else-if="manageActionsOptions(row) === ACTION_TYPES.PROCESSING"
                                :label="$t('general.processing')"
                                color="info"
                            />
                            <WText
                                v-else-if="ACTION_TYPES.WITHOUT_ACTION"
                                :size="12"
                                color="gray02"
                            >
                                -
                            </WText>
                        </fragment>
                    </QTd>
                    <QTd
                        key="description"
                        class="td-desc"
                        @click.native="handleGoToVisualization(row)"
                    >
                        <span class="ellipsis">{{ row.description || '-' }}</span>
                        <QTooltip
                            v-if="row.description"
                            contentClass="bg-secondary"
                            maxWidth="300px"
                        >
                            {{ row.description }}
                        </QTooltip>
                    </QTd>
                    <QTd
                        key="tst"
                        class="td-tst"
                        @click.native="handleGoToVisualization(row)"
                    >
                        <span v-if="row.tst">{{ row.tst|date }}</span>
                        <span v-else>-</span>
                    </QTd>
                    <QTd @click.native="handleGoToVisualization(row)">
                        <QLinearProgress
                            size="35px"
                            :indeterminate="getIndeterminateProgressState(row.state.identifier, row.uploaded)"
                            :value="1"
                            :color="getProgressColor(row.state.identifier, row.uploaded)"
                            rounded
                            trackColor="white"
                        >
                            <div class="absolute-full flex flex-center">
                                <QBadge
                                    :label="getBadgeLabel(row.state.identifier, row.uploaded)"
                                    :color="getProgressColor(row.state.identifier, row.uploaded)"
                                    textColor="white"
                                />
                            </div>
                        </QLinearProgress>
                    </QTd>
                    <QTd
                        key="dropdown"
                        class="Actions align-right"
                    >
                        <WIcon
                            icon="more-vertical"
                            :size="24"
                            color="primary"
                            clickable
                            @click="setSelected(row, $event)"
                        />
                    </QTd>
                </QTr>
            </template>

            <template #empty>
                <EmptyList
                    v-bind="emptyMessage"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            class="mt-1"
                            iconLeft="plus-circle"
                            :disabled="archived"
                            @click="$emit('onNewItem')"
                        >
                            {{ $t('projects.datasets.new') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>

        <Dropdown
            v-if="selected"
            :opened="!!selected"
            :options="(selected.options || {}).dropdown"
            :coords="selected.coords"
            @onClose="cleanSelected"
            @onClick="handleClickOption"
        />
    </div>
</template>

<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import {
    Table, EmptyList, Dropdown, Badge,
} from '@/components'

const STATES = {
    RUNNING: 'RUNNING',
    SUBMITTED: 'SUBMITTED',
    FINISHED: 'FINISHED',
    ERROR: 'ERROR',
    CANCELLED: 'CANCELLED',
    TIMEOUT: 'TIMEOUT',
}

const ACTIONS = {
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    DISCOVERY: 'DISCOVERY',
    QUERY: 'QUERY',
    CONFORMANCE: 'CONFORMANCE',
    MONITORING: 'MONITORING',
}

const ACTION_TYPES = {
    PROCESSING: 'PROCESSING',
    UPLOADED: 'UPLOADED',
    WITHOUT_ACTION: 'WITHOUT_ACTION',
}

export default {
    name: 'DatasetsList',
    components: {
        Table,
        EmptyList,
        Dropdown,
        Badge,
    },
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
        archived: VueTypes.bool.def(false),
    },
    data () {
        return {
            selected: undefined,
        }
    },
    computed: {
        cols () {
            return [
                ...this.columns,
                {
                    name: 'name',
                    label: this.$t('general.name'),
                    align: 'left',
                },
                {
                    name: 'file',
                    label: this.$t('general.file'),
                    align: 'left',
                    width: 100,
                },
                {
                    name: 'common',
                    label: this.$t('general.actions'),
                    align: 'left',
                },
                {
                    name: 'description',
                    label: this.$t('general.description'),
                    align: 'left',
                },
                {
                    name: 'updateDate',
                    label: this.$t('general.updateDate'),
                    align: 'left',
                },
                {
                    name: 'state',
                    label: this.$t('general.status'),
                    align: 'left',
                },
                {
                    name: 'dropdown',
                    label: '',
                },
            ]
        },
        hideHeader () {
            return !(this.rows || []).length
        },
        emptyMessage () {
            return this.$t(`projects.datasets.notifications.${this.search ? 'noResult' : 'empty'}`)
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    beforeMount () {
        this.ACTION_TYPES = ACTION_TYPES
    },
    methods: {
        manageActionsOptions ({ state }) {
            if (state.identifier === STATES.FINISHED) return ACTION_TYPES.UPLOADED
            return ACTION_TYPES.WITHOUT_ACTION
        },
        generateOptionsRow () {
            return {
                common: [{
                    key: ACTIONS.QUERY, icon: 'zoom-in', iconColor: 'primary', label: this.$t('projects.datasets.query'), event: 'onWizardQuery',
                }],
                dropdown: [
                    {
                        key: ACTIONS.EDIT, icon: 'edit-2', iconColor: 'primary', label: this.$t('actions.edit'), event: 'onUpdate',
                    },
                    {
                        key: ACTIONS.DELETE, icon: 'trash', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                    },
                ],
            }
        },
        setSelected (row, event) {
            const { clientX: x, clientY: y } = event
            setTimeout(() => (this.selected = { coords: { x, y }, ...row }), 100)
        },
        cleanSelected () {
            this.selected = undefined
        },
        handleClickOption (option) {
            if (option.event) this.handleEmmit(option, this.selected)
            this.cleanSelected()
        },
        handleEmmit ({ event }, row) {
            this.$emit(event, row)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
        handleGoToVisualization (row) {
            const { uploaded, state } = row
            if (uploaded && state.identifier === STATES.FINISHED) this.$emit('onVisualization', row)
        },
        getIndeterminateProgressState (value, uploaded) {
            return uploaded && (value === STATES.SUBMITTED || value === STATES.RUNNING)
        },
        getProgressColor (value, uploaded) {
            const types = {
                [STATES.RUNNING]: '$primary',
                [STATES.SUBMITTED]: 'blue',
                [STATES.FINISHED]: 'green',
                [STATES.ERROR]: 'red',
                [STATES.CANCELLED]: 'red',
                [STATES.TIMEOUT]: 'yellow',
                default: '$primary',
            }
            return value === STATES.SUBMITTED && !uploaded ? types[STATES.ERROR] : types[value] || types.default
        },
        getBadgeLabel (value, uploaded) {
            const STATE_LABELS = {
                RUNNING: this.$t('projects.datasets.states.running'),
                SUBMITTED: this.$t('projects.datasets.states.submitted'),
                FINISHED: this.$t('projects.datasets.states.finished'),
                ERROR: this.$t('projects.datasets.states.error'),
                CANCELLED: this.$t('projects.datasets.states.cancelled'),
                TIMEOUT: this.$t('projects.datasets.states.timeout'),
            }
            // Force label "SUBMITTED" to be "ERROR" when file is not uploaded
            return value === STATES.SUBMITTED && !uploaded ? STATE_LABELS[STATES.ERROR] : STATE_LABELS[value]
        },
    },
}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 165px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
