const organizations = [
    {
        description: '',
        isOwner: true,
        name: 'Organization 1',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        owner: true,
    },
    {
        description: 'Description of organization 2',
        isOwner: true,
        name: 'Organization 2',
        uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
        owner: true,
    },
]

export default {
    data: organizations,
    total: organizations.length,
}
