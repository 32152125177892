import Config from '@/config'

const { isDev } = Config
let currentTime = 0

function log (args) {
    if (!isDev) return
    const currentDelay = Date.now() - currentTime
    console.table({
        ...args,
        time: `+${currentDelay}ms`,
    })
    currentTime = Date.now()
}

export default log
