var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("div", { staticClass: "Tittle" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("booster.prediction.predictionHighlights.name")) +
              " "
          )
        ]),
        _c("PredictionBoosterMetricsPanel")
      ],
      1
    ),
    _c("div", [_c("QCard")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }