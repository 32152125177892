var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "WSwitch",
      class: [_vm.color, { error: _vm.error, disabled: _vm.disabled }]
    },
    [
      _c(
        "input",
        _vm._g(
          {
            class: ["Input", _vm.inputStyles],
            attrs: {
              id: _vm.id,
              name: _vm.name,
              disabled: _vm.disabled,
              type: "checkbox"
            },
            domProps: { checked: _vm.value }
          },
          _vm.inputListeners
        )
      ),
      _c("span", { staticClass: "Slider" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }