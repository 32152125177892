/* eslint-disable import/prefer-default-export */
import downloader from 'downloadjs'

const headersDefault = {
    'content-type': 'text/plain;charset=utf-8',
}

export const download = ({
    name, format = 'csv', data, headers = headersDefault,
}) => {
    const file = `${name}.${format}`
    downloader(new Blob([data]), file, headers['content-type'])
}
