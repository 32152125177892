var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Invitations" },
    [
      _c(
        "div",
        { staticClass: "Actions flex row-stretch-center mb-1" },
        [
          _c(
            "WButton",
            {
              attrs: { color: "primary", iconLeft: "plus-circle" },
              on: { click: _vm.handleNewItem }
            },
            [_vm._v(" " + _vm._s(_vm.$t("invitations.new")) + " ")]
          ),
          _vm.availableInvitations
            ? _c(
                "div",
                { staticClass: "flex row-left-center" },
                [
                  _c("WIcon", {
                    staticClass: "mr-0-5",
                    attrs: { icon: "info1", size: 18, color: "blue" }
                  }),
                  _c(
                    "WText",
                    {
                      attrs: {
                        size: 14,
                        align: "center",
                        tag: "span",
                        weight: "bold"
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.availableInvitations) + " "),
                      _c("WText", { attrs: { size: 12, tag: "span" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("invitations.available")) + " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Container" },
        [
          _c("InvitationsList", {
            ref: "table",
            attrs: {
              rows: _vm.invitations,
              total: _vm.total,
              loading: _vm.isFetching
            },
            on: {
              onNewItem: _vm.handleNewItem,
              onFetchData: _vm.fetchData,
              onRevokeInvitation: _vm.handleRevokeInvitation,
              onCancelInvitation: _vm.cancelInvitation
            }
          })
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "RevokeRequestModal",
        attrs: {
          title: _vm.$t("invitations.modal.title"),
          text: _vm.$t("invitations.modal.text"),
          loading: _vm.isLoading
        },
        on: { onAccept: _vm.revokeInvitation }
      }),
      _c("InvitationDialog", {
        ref: "newInvDialog",
        staticClass: "InvitationDialog",
        attrs: {
          title: _vm.$t("invitations.new"),
          accept: _vm.$t("actions.create"),
          loading: _vm.isLoading
        },
        on: { onSubmit: _vm.createInvitation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }