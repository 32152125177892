<template>
    <Modal
        :isOpen="opened"
        class="Upload"
        @close="close"
    >
        <template #header>
            <WText
                tag="h3"
                :size="16"
                weight="semi"
                class="mb-0"
            >
                {{ $t('projects.modals.upload.title') }}
            </WText>
        </template>
        <template #body>
            <div class="Wrapper">
                <div>{{ $t('projects.modals.upload.text') }}</div>
                <QUploader
                    class="Uploader mt-1 mb-2"
                    flat
                    bordered
                    accept=".csv"
                    sendRaw
                    color="grey-1"
                    textColor="primary"
                    :autoUpload="true"
                    :headers="headers"
                    :method="method"
                    :url="url"
                    @finish="uploadFinished"
                    @start="uploadStarted"
                    @uploaded="uploadSuccess"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import { getAuthorization } from '@/api'
import { Modal } from '@/components'
import Config from '@/config'

export default {
    name: 'Upload',
    components: {
        Modal,
    },
    data () {
        return {
            method: 'POST',
            uploaded: false,
            loading: false,
            uuid: '',
            opened: false,
        }
    },
    computed: {
        label () {
            return this.uploaded ? 'Close' : 'Cancel'
        },
        url () {
            const { baseURL } = Config.api
            return `${baseURL}/uploads/datasets/${this.uuid}`
        },
    },
    methods: {
        headers (files) {
            const { name } = files[0]

            return [
                { name: 'Authorization', value: getAuthorization() },
                { name: 'Content-Type', value: 'application/octet-stream' },
                { name: 'X-File-Name', value: name },
            ]
        },
        open (uuid) {
            this.opened = true
            this.uuid = uuid
        },
        close () {
            if (this.uploaded) this.$emit('upload-action')

            this.loading = false
            this.opened = false
            this.uploaded = false
        },
        uploadFinished () {
            this.loading = false
        },
        uploadStarted () {
            this.loading = true
        },
        uploadSuccess () {
            this.uploaded = true
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &::v-deep .Dialog {
        width: 100%;
        max-width: 400px;
    }

    .Wrapper {
        margin: 30px 0;
    }
}
.Uploader {
    width: 100%;
}
</style>
