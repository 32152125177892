export default {
    data: [
        {
            createDate: '2020-05-21T10:18:39',
            deleteDate: undefined,
            tst: 'string',
            description: 'lorem ipsum',
            finishedAt: '2020-05-21T10:18:39',
            minFitness: 0,
            mode: {
                description: 'string',
                identifier: 'FAST',
                name: 'string',
            },
            name: 'Query 1',
            query: {
                platformQuery: {
                    query: 'string',
                    restrictions: 'string',
                },
            },
            report: {
                description: 'lorem ipsum',
                extUuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                title: 'Report Title',
                type: {
                    description: 'lorem ipsum',
                    identifier: 'STANDARD_COMPLIANCE',
                    name: 'string',
                },
                uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            },
            result: {
                averageFitness: 0,
                compliant: {
                    percentageCompliantTraces: 0,
                    totalCompliantTraces: 0,
                },
            },
            state: {
                description: 'lorem ipsum',
                identifier: 'RUNNING',
                name: 'Running',
            },
            uuid: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
    ],
    total: 0,
}
