var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WText", {
    attrs: {
      tag: _vm.tag,
      typo: _vm.typo,
      align: _vm.align,
      color: _vm.color,
      underline: _vm.underline,
      italic: _vm.italic,
      size: _vm.size,
      weight: _vm.weight
    },
    domProps: { innerHTML: _vm._s(_vm.formatValue(Number(_vm.animatedValue))) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }