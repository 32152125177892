var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    staticClass: "ModalNameDesc",
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("NameDescForm", {
              attrs: {
                loading: _vm.loading,
                action: _vm.action,
                withoutDesc: _vm.withoutDesc
              },
              on: { onSubmit: _vm.handleSubmit, onReset: _vm.close },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }