var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Examples pt-1 pb-1" },
    _vm._l(_vm.examples, function(example) {
      return _c(
        "div",
        { key: example, staticClass: "flex" },
        [
          _c(
            "Badge",
            { attrs: { label: example, color: "secondary" } },
            [
              _c("WIcon", {
                attrs: {
                  icon: "copy",
                  size: 24,
                  color: "white",
                  clickable: ""
                },
                on: {
                  click: function($event) {
                    return _vm.handleCopyToClipboard(example)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }