var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column q-col-gutter-md q-pa-md" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("QSelect", {
          attrs: {
            options: _vm.datasetsName,
            label: _vm.$t("visualization.contextComparison.datasets"),
            dense: true
          },
          on: { input: _vm.getDatasetFilters },
          model: {
            value: _vm.datasetsNameSelected,
            callback: function($$v) {
              _vm.datasetsNameSelected = $$v
            },
            expression: "datasetsNameSelected"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _vm.setsName.length === 0
          ? _c(
              "div",
              [
                _c("QSelect", {
                  attrs: {
                    disable: "",
                    dense: true,
                    options: _vm.datasetsName,
                    hint: _vm.$t(
                      "visualization.contextComparison.noFiltersets"
                    ),
                    label: _vm.$t("visualization.contextComparison.filtersets")
                  },
                  model: {
                    value: _vm.setSelected,
                    callback: function($$v) {
                      _vm.setSelected = $$v
                    },
                    expression: "setSelected"
                  }
                })
              ],
              1
            )
          : _c("QSelect", {
              attrs: {
                dense: true,
                options: _vm.setsName,
                label: _vm.$t("visualization.contextComparison.filtersets")
              },
              on: {
                "popup-show": _vm.refreshFilterSet,
                input: _vm.getModelInfoFiltered
              },
              model: {
                value: _vm.setSelected,
                callback: function($$v) {
                  _vm.setSelected = $$v
                },
                expression: "setSelected"
              }
            })
      ],
      1
    ),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row q-col-gutter-sm" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("span", { staticClass: "ContextualElementName" }, [
              _vm._v(_vm._s(_vm.$t("visualization.contextComparison.cases")))
            ]),
            _c(
              "QCard",
              { staticClass: "bg-grey-2 metric", attrs: { flat: "" } },
              [
                _c(
                  "QCardSection",
                  {
                    staticClass: "text-weight-bold",
                    attrs: { horizontal: "" }
                  },
                  [_vm._v(" " + _vm._s(_vm.cases) + " ")]
                ),
                _vm.casesDifference.number !== null
                  ? _c(
                      "QCardSection",
                      {
                        staticClass:
                          "q-pa-sm q-ml-sm bg-white row items-center",
                        attrs: { horizontal: "" }
                      },
                      [
                        _c("QIcon", {
                          staticClass: "metricIcon",
                          attrs: {
                            name: _vm.casesDifference.direction,
                            color: "primary"
                          }
                        }),
                        _c("span", { staticClass: "text-weight-bold" }, [
                          _vm._v(" " + _vm._s(_vm.casesDifference.number) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("span", { staticClass: "ContextualElementName" }, [
              _vm._v(_vm._s(_vm.$t("visualization.contextComparison.variants")))
            ]),
            _c(
              "QCard",
              { staticClass: "bg-grey-2 metric", attrs: { flat: "" } },
              [
                _c(
                  "QCardSection",
                  {
                    staticClass: "text-weight-bold",
                    attrs: { horizontal: "" }
                  },
                  [_vm._v(" " + _vm._s(_vm.variants) + " ")]
                ),
                _vm.variantsDifference.number !== null
                  ? _c(
                      "QCardSection",
                      {
                        staticClass:
                          "q-pa-sm q-ml-sm bg-white row items-center",
                        attrs: { horizontal: "" }
                      },
                      [
                        _c("QIcon", {
                          staticClass: "metricIcon",
                          attrs: {
                            name: _vm.variantsDifference.direction,
                            color: "primary"
                          }
                        }),
                        _c("span", { staticClass: "text-weight-bold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.variantsDifference.number) + " "
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("span", { staticClass: "ContextualElementName" }, [
        _vm._v(_vm._s(_vm.$t("visualization.contextComparison.duration")))
      ]),
      _c("div", { staticClass: "row q-col-gutter-sm items-center" }, [
        _c(
          "div",
          { staticClass: "col-sm-8" },
          [
            _c(
              "QCard",
              { staticClass: "bg-grey-2 metricDuration", attrs: { flat: "" } },
              [
                _c(
                  "QCardSection",
                  {
                    staticClass: "text-weight-bold",
                    attrs: { horizontal: "" }
                  },
                  [_vm._v(" " + _vm._s(_vm.duration) + " ")]
                ),
                _vm.durationDifference.metric
                  ? _c(
                      "QCardSection",
                      {
                        staticClass:
                          "q-pa-sm q-ml-sm bg-white row items-center",
                        attrs: { horizontal: "" }
                      },
                      [
                        _c("QIcon", {
                          staticClass: "metricIcon",
                          attrs: {
                            name: _vm.durationDifference.direction,
                            color: _vm.getColorClass(
                              _vm.durationDifference.direction
                            )
                          }
                        }),
                        _c("span", { staticClass: "text-weight-bold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.durationDifference.metric) + " "
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-4 text-center" },
          [
            _c(
              "QFab",
              {
                attrs: {
                  label: _vm.metricsDurationSelected.value,
                  square: "",
                  flat: "",
                  labelPosition: "left",
                  color: "grey-2",
                  textColor: "primary",
                  icon: "keyboard_arrow_down",
                  direction: "up",
                  padding: "sm"
                },
                model: {
                  value: _vm.displayDurationOptions,
                  callback: function($$v) {
                    _vm.displayDurationOptions = $$v
                  },
                  expression: "displayDurationOptions"
                }
              },
              _vm._l(_vm.metricsDuration, function(option) {
                return _c("QFabAction", {
                  key: option.value,
                  attrs: {
                    textColor: "primary",
                    color: "grey-2",
                    label: option.label,
                    padding: "xs"
                  },
                  on: {
                    click: function($event) {
                      return _vm.refreshDuration(option)
                    }
                  }
                })
              }),
              1
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row q-col-gutter-sm" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("div", [
              _c("span", { staticClass: "ContextualElementName" }, [
                _vm._v(_vm._s(_vm.$t("visualization.contextComparison.start")))
              ]),
              _c("span", { staticClass: "dateDifference" }, [
                _vm._v(_vm._s(_vm.startDateDifference))
              ])
            ]),
            _c(
              "QCard",
              { staticClass: "bg-grey-2 metric", attrs: { flat: "" } },
              [
                _c(
                  "div",
                  { staticClass: "q-pa-sm text-center text-weight-bold" },
                  [_vm._v(" " + _vm._s(_vm.startDate) + " ")]
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("div", [
              _c("span", { staticClass: "ContextualElementName" }, [
                _vm._v(_vm._s(_vm.$t("visualization.contextComparison.end")))
              ]),
              _c("span", { staticClass: "dateDifference" }, [
                _vm._v(_vm._s(_vm.endDateDifference))
              ])
            ]),
            _c(
              "QCard",
              { staticClass: "bg-grey-2 metric", attrs: { flat: "" } },
              [
                _c(
                  "div",
                  { staticClass: "q-pa-sm text-center text-weight-bold" },
                  [_vm._v(" " + _vm._s(_vm.endDate) + " ")]
                )
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }