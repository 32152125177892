export default {
    new: 'Invitar a un usuario',
    available: 'Invitaciones disponibles',
    revoke: 'Anular notificación',
    cancel: 'Cancelar notificación',
    showActives: 'Mostrar notificaciones leídas',

    notifications: {
        noResult: {
            title: 'No se han encontrado resultados',
            text: 'No hemos encontrado ninguna notificación para tu búsqueda.',
        },
        empty: {
            title: 'No se han encontrado notificaciones',
            text: 'Las notificaciones generadas por la plataforma InVerbis aparecerán en esta vista.',
        },
        seen: {
            text: 'Notificación marcada como vista correctamente.',
        },
    },
}
