export default {
    general: {
        text: 'Ha ocurrido un error inesperado, por favor contacte con el administrador de la plataforma.',
    },
    unauthorized: {
        text: 'Error de autorización. Por favor, inicia sesión de nuevo.',
        title: 'Uups...',
    },
    unknown: {
        text: 'Error desconocido. Por favor, vuelve a intentarlo o contacta con nosotros.',
        title: 'Uups...',
    },
    notfound: {
        text: 'No se ha encontrado el recurso.',
        title: 'Uups...',
    },
    conflict: {
        text: 'Recurso inválido.',
        title: 'Uups...',
    },
    auth: {
        text: 'El usuario o la contraseña son incorrectos.',
        title: 'Error',
    },
    404: {
        title: 'Página no encontrada',
    },
    select: {
        noOptions: 'No se han encontrado elementos.',
    },
}
