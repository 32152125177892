var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ContextualInfo" },
    [
      _vm.noFinished && _vm.noRisk === undefined && _vm.risk === undefined
        ? _c(
            "QCard",
            { attrs: { flat: "" } },
            [
              _c(
                "QCardSection",
                { staticClass: "text-h6 text-weight-bold text-center" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("visualization.predictions.unifinishedCases")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "QCardSection",
                {
                  staticClass:
                    "text-center text-weight-bold text-h4 q-pt-none text-grey-8"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.App.numberLocationFormat(_vm.noFinished)) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.noRisk != undefined
        ? _c(
            "QCard",
            { attrs: { flat: "" } },
            [
              _c(
                "QCardSection",
                { staticClass: "text-h6 text-weight-bold text-center" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("visualization.predictions.noRisk")) +
                      " "
                  )
                ]
              ),
              _c(
                "QCardSection",
                {
                  staticClass:
                    "text-h4 text-center text-weight-bold q-pt-none text-grey-8"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.App.numberLocationFormat(_vm.noRisk) +
                          " (" +
                          (100 * _vm.noRiskPercentage).toFixed(0) +
                          "%)"
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "QCardSection",
                { staticClass: "q-pa-none" },
                [
                  _c("QLinearProgress", {
                    attrs: {
                      size: "5px",
                      value: _vm.noRiskPercentage,
                      color: "primary",
                      trackColor: "transparent"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.risk != undefined
        ? _c(
            "QCard",
            { attrs: { flat: "" } },
            [
              _c(
                "QCardSection",
                { staticClass: "text-h6 text-weight-bold text-center" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("visualization.predictions.risk")) + " "
                  )
                ]
              ),
              _c(
                "QCardSection",
                {
                  staticClass:
                    "text-h4 text-center text-weight-bold q-pt-none text-negative"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.App.numberLocationFormat(_vm.risk) +
                          " (" +
                          (100 * _vm.riskPercentage).toFixed(0) +
                          "%)"
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "QCardSection",
                { staticClass: "q-pa-none" },
                [
                  _c("QLinearProgress", {
                    attrs: {
                      size: "5px",
                      value: _vm.riskPercentage,
                      color: "negative",
                      trackColor: "transparent"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }