const modes = [
    {
        name: 'Mode 1',
        description: 'Mode 1 description',
        identifier: 'FAST',
    },
    {
        name: 'Mode 2',
        description: 'Mode 2 description',
        identifier: 'SLOW',
    },
    {
        name: 'Mode 3',
        description: 'Mode 3 description',
        identifier: 'MEDIUM',
    },
]

export default modes
