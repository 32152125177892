import StepService from './StepService.vue'
import StepConnection from './StepConnection.vue'
import StepWorkflow from './StepWorkflow.vue'
import StepSummary from './StepSummary.vue'

export default {
    StepService,
    StepConnection,
    StepWorkflow,
    StepSummary,
}
