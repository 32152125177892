export default {
    new: 'Invitar a un usuario',
    available: 'Invitaciones disponibles',
    revoke: 'Anular invitación',
    cancel: 'Cancelar invitación',
    hint: 'Dirección de e-mail del nuevo usuario',
    valid: 'Introduce un e-mail válido',
    modal: {
        title: 'Anular invitación',
        text: '¿Está seguro que quiere revocar la invitación?',
    },
    notifications: {
        noResult: {
            title: 'No se han encontrado resultados',
            text: 'No hemos encontrado ninguna invitación para tu búsqueda.',
        },
        empty: {
            title: 'No se han encontrado invitaciones.',
            text: 'Puedes crear tu primera invitación.',
        },
        canceled: {
            text: 'Invitación cancelada correctamente.',
        },
        revoked: {
            text: 'Invitación revocada correctamente.',
        },
        registered: {
            text: 'Invitación registrada correctamente.',
        },
    },

    INVITED: 'INVITADO',
    ACCEPTED: 'ACEPTADA',
    CANCELLED: 'CANCELADA',
    REVOKED: 'REVOCADA',
}
