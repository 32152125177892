function separateByThousands (numStr, separator) {
    if (numStr.length > 3) {
        return separateByThousands(numStr.slice(0, -3), separator) + separator + numStr.slice(-3)
    }
    return numStr
}
export function compressNumber (value, symbol) {
    if (symbol === 'k') {
        return { value: value / 1e3, symbol: 'k' }
    }
    if (symbol === 'M') {
        return { value: value / 1e6, symbol: 'M' }
    }
    if (symbol === 'auto') {
        const absolute = Math.abs(value)
        if (absolute >= 1e6) return compressNumber(value, 'M')
        if (absolute >= 1e3) return compressNumber(value, 'k')
    }
    return { value, symbol: undefined }
}

export function formatNumber (value, options) {
    const {
        decimals = 2, decimalSeparator = ',', thousandsSeparator = '', trimRight = false, compress, preNumberTransformFn, postStringFormatFn,
    } = options || {}
    let number = typeof value === 'string' ? parseFloat(value) : value
    if (value < 0) {
        return `-${formatNumber(-value, options)}`
    }
    if (Number.isNaN(number)) {
        return value
    }
    let suffix = ''
    if (preNumberTransformFn) {
        number = preNumberTransformFn(number)
    }
    if (compress) {
        const { value: compressed, symbol } = compressNumber(number, compress === true ? 'auto' : compress)
        number = compressed
        suffix = symbol || ''
    }
    const rounded = Math.round(number * 10 ** decimals)
    const roundedStr = rounded.toString()
    let numStr = decimals > 0 ? roundedStr.slice(0, -decimals) || '0' : roundedStr
    if (thousandsSeparator) {
        numStr = separateByThousands(numStr, thousandsSeparator)
    }
    let decStr = ''
    if (decimals > 0) {
        decStr = roundedStr.slice(-decimals)
        if (decStr.length < decimals) {
            decStr = new Array(decimals - roundedStr.length + 1).join('0') + decStr
        }
        if (trimRight) {
            decStr = decStr.replace(/0+$/, '')
        }
        if (decStr.length > 0) {
            decStr = decimalSeparator + decStr
        }
        if (numStr === '-' && (decStr || [])[0] === ',') {
            decStr = `0${decStr}`
        }
    }
    let result = numStr + decStr + suffix
    if (postStringFormatFn) {
        result = postStringFormatFn(result)
    }
    return result
}
export function formatPercent (value, options) {
    const { isDecimal = false, ...moreOptions } = options || {}
    return formatNumber(value, {
        preNumberTransformFn: x => (isDecimal ? x * 100 : x),
        postStringFormatFn: x => `${x}%`,
        ...moreOptions,
    })
}
export function formatAmount (value, options) {
    const { currencySymbol = '$', currencyOnLeft = false, ...moreOptions } = options || {}
    return formatNumber(value, {
        postStringFormatFn: currencyOnLeft ? str => currencySymbol + str : str => str + currencySymbol,
        ...moreOptions,
    })
}

const defaultOptions = {
    decimals: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    trimRight: false,
}
const defaultOptionsForNumber = {
    ...defaultOptions,
    trimRight: true,
}
const defaultOptionsForPercent = {
    ...defaultOptions,
    isDecimal: false,
    trimRight: false,
}
const defaultOptionsForDecimalAsPercent = {
    ...defaultOptions,
    isDecimal: true,
    trimRight: false,
}
const defaultOptionsForAmount = {
    ...defaultOptions,
    currencySymbol: '$',
    currencyOnLeft: false,
    trimRight: false,
}

/* Options params
options = {
    decimals = 2,
    decimalSeparator = ',',
    thousandsSeparator = '',
    trimRight = false, // 100.00 remove decimals
    compress   // 100k
}
*/

const format = {
    number: (x, options) => formatNumber(x, { ...defaultOptionsForNumber, ...(options || {}) }),
    compressedNumber: (x, options) => formatNumber(x, {
        ...defaultOptionsForNumber,
        compress: true,
        trimRight: true,
        ...(options || {}),
    }),
    formatNumber: (x, options) => formatNumber(x, options),
    numberTrim: (x, options) => formatNumber(x, { ...defaultOptionsForNumber, trimRight: true, ...(options || {}) }),
    percent: (x, options) => formatPercent(x, { ...defaultOptionsForPercent, ...(options || {}) }),
    decimalAsPercent: (x, options) => formatPercent(x, { ...defaultOptionsForDecimalAsPercent, ...(options || {}) }),
    amount: (x, options) => formatAmount(x, { ...defaultOptionsForAmount, ...(options || {}) }),
    compressedAmount: (x, options) => formatAmount(x, { ...defaultOptionsForAmount, compress: true, ...(options || {}) }),
}
export default format
