<template>
    <div class="NameDescForm">
        <div class="Form mb-1">
            <QInput
                ref="name"
                v-model="value.name"
                lazyRules
                :dense="dense"
                maxlength="100"
                outlined
                class="Field"
                :label="$t('general.name')"
                :hint="`5 - 100 ${$t('general.characters')}`"
                :rules="[
                    value => validators.betweenLength(value, 5, 100) || $t('validation.rangeLength', { attribute: $t('general.name'), min: 5, max: 100 }),
                    value => validators.allowedChars(value) || $t('validation.allowedChars', { attribute: $t('general.name') }),
                ]"
            />
            <QInput
                v-if="!withoutDesc"
                v-model.trim="value.description"
                outlined
                autogrow
                :dense="dense"
                maxlength="500"
                lazyRules
                class="Field mt-1"
                :label="$t('general.description')"
                :hint="`Max. 500 ${$t('general.characters')}`"
                :rules="[value => (
                    validators.optional(value) ||
                    validators.maxLength(value, 500)) ||
                    $t('validation.maxLength', { attribute: $t('general.description'), max: 500 })
                ]"
            />
        </div>
        <div
            v-if="action"
            class="flex row-right-center"
        >
            <WButton
                id="reset"
                color="none"
                class="Reset"
                @click="handleReset"
            >
                {{ $t('actions.cancel') }}
            </WButton>
            <WButton
                id="submit"
                class="ml-1"
                :loading="loading"
                :disabled="loading"
                @click="handleSubmit"
            >
                {{ action }}
            </WButton>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { validatorsMixin } from '@/mixins'

export default {
    name: 'NameDescForm',
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            uuid: VueTypes.string,
            name: VueTypes.string,
            description: VueTypes.string,
        }).loose,
        action: VueTypes.any,
        loading: VueTypes.bool.def(false),
        withoutDesc: VueTypes.bool.def(false),
        dense: VueTypes.bool.def(false),
    },
    methods: {
        isValid () {
            const { name } = this.$refs
            return name.validate()
        },
        handleReset () {
            this.$emit('onReset')
        },
        handleSubmit () {
            if (this.isValid()) {
                this.$emit('onSubmit', this.value)
            }
        },
    },
}
</script>
