<template>
    <div class="DatasetsListCards">
        <Table
            ref="table"
            :data="normalizeRows"
            :loading="loading"
            :total="total"
            hideHeader
            rowKey="uuid"
            @table-request="handleFetchData"
        >
            <template #content="{ row }">
                <DatasetCard
                    :row="row"
                    @handleEmmit="handleEmmit($event, row)"
                />
            </template>
            <template #empty>
                <EmptyList
                    v-bind="emptyMessage"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            class="mt-1"
                            iconLeft="plus-circle"
                            :disabled="archived"
                            @click="$emit('onNewItem')"
                        >
                            {{ $t('projects.datasets.new') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import DatasetCard from './DatasetCard.vue'
import { Table, EmptyList } from '@/components'

const ACTIONS = {
    DELETE: 'DELETE',
    EDIT: 'EDIT',
    DISCOVERY: 'DISCOVERY',
    QUERY: 'QUERY',
    CONFORMANCE: 'CONFORMANCE',
    MONITORING: 'MONITORING',
    INFO: 'INFO',
}

const ACTION_TYPES = {
    PROCESSING: 'PROCESSING',
    UPLOADED: 'UPLOADED',
    WITHOUT_ACTION: 'WITHOUT_ACTION',
}

export default {
    name: 'DatasetsListCards',
    components: {
        DatasetCard,
        Table,
        EmptyList,
    },
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
        archived: VueTypes.bool.def(false),
    },
    computed: {
        emptyMessage () {
            return this.$t(`projects.datasets.notifications.${this.search ? 'noResult' : 'empty'}`)
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    beforeMount () {
        this.ACTION_TYPES = ACTION_TYPES
    },
    methods: {
        generateOptionsRow () {
            return {
                common: [{
                    key: ACTIONS.QUERY, icon: 'zoom-in', iconColor: 'primary', label: this.$t('projects.datasets.query'), event: 'onWizardQuery',
                }],
                dropdown: [
                    {
                        key: ACTIONS.INFO, icon: 'settings', iconColor: 'primary', label: this.$t('actions.info'), event: 'onSettings',
                    },
                    {
                        key: ACTIONS.DELETE, icon: 'trash', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                    },
                ],
            }
        },
        handleEmmit (event, row) {
            this.$emit(event, row)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
    },
}
</script>
<style scoped lang="scss">
.Table::v-deep {
  border: none !important;
  .q-table__bottom {
    margin-top: 20px;
    border: 1px solid $table-border-color;
  }
  tbody {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 16px;
    @media screen and (min-width: $md) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width: $xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (min-width: $xxxl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
</style>
