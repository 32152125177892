var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.website
    ? _c("WText", { staticClass: "Powered", attrs: { size: 12 } }, [
        _vm._v(" Powered By "),
        _c(
          "a",
          { attrs: { href: _vm.website, target: "_blank" } },
          [
            _c("WText", { attrs: { tag: "span", weight: "semi", size: 12 } }, [
              _vm._v(" " + _vm._s(_vm.powered) + " ")
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }