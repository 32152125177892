import JSCookie from 'js-cookie'

const sessionPrefix = process.env.VUE_APP_SESSION_PREFIX
const langCookieName = `${sessionPrefix}:lang`

export const LangSession = {
    set: token => JSCookie.set(langCookieName, token, { secure: true }),
    unset: () => JSCookie.remove(langCookieName),
    get: () => JSCookie.get(langCookieName),
}

export default {
    Lang: LangSession,
}
