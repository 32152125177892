var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "HeaderList flex row-stretch-center" }, [
    _c("div", { staticClass: "HeaderLeft" }, [_vm._t("headerLeft")], 2),
    _c(
      "div",
      { staticClass: "HeaderRight flex row-stretch-center" },
      [
        _vm._t("headerRight"),
        _vm.enableArchived
          ? _c(
              "label",
              { staticClass: "flex row-left-center" },
              [
                _c("WText", { staticClass: "mr-1", attrs: { size: 12 } }, [
                  _vm._v(" " + _vm._s(_vm.$t("general.archived")) + " ")
                ]),
                _c("WSwitch", {
                  attrs: {
                    id: "visibility",
                    name: "visibility",
                    value: _vm.value.archived,
                    disable: _vm.disabled
                  },
                  on: {
                    input: function($event) {
                      return _vm.handleInput("archived", $event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "Transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _vm.search
              ? _c("QInput", {
                  staticClass: "Search search-input float-left",
                  attrs: {
                    dense: "",
                    outlined: "",
                    type: "search",
                    label: _vm.$t("actions.search")
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleInput("search", _vm.value.search)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _vm.value.search
                              ? _c("WIcon", {
                                  attrs: {
                                    id: "clean",
                                    icon: "close",
                                    size: 20,
                                    clickable: ""
                                  },
                                  on: { click: _vm.handleClean }
                                })
                              : _vm._e(),
                            _c("WIcon", {
                              attrs: {
                                id: "search",
                                icon: "search1",
                                size: 20,
                                clickable: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleInput(
                                    "search",
                                    _vm.value.search
                                  )
                                }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2749292125
                  ),
                  model: {
                    value: _vm.value.search,
                    callback: function($$v) {
                      _vm.$set(_vm.value, "search", $$v)
                    },
                    expression: "value.search"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }