<template>
    <div>
        <WCard
            flat
            bordered
            class="ModelCard"
        >
            <div class="flex row-left-top flex-nowrap q-pa-sm h-full">
                <div
                    class="AvatarTitle cursor-pointer"
                    @click="handleGoToVisualization"
                >
                    <p>{{ getInitial(row.modelName) }}</p>
                </div>
                <div
                    class="DatasetContent q-px-sm flex-1 flex flex-col col-between-left h-full cursor-pointer"
                    @click="handleGoToVisualization"
                >
                    <template>
                        <p class="DatasetCardName text-weight-bold">
                            {{ row.modelName }}
                        </p>
                    </template>
                    <div class="flex items-center q-mt-sm">
                        <QBadge
                            :label="sourceLabel"
                            :color="sourceColor"
                            textColor="white"
                            class="q-pa-sm q-mr-sm"
                        />
                    </div>
                </div>
                <WIcon
                    key="dropdown"
                    icon="more-vertical"
                    :size="24"
                    color="primary"
                    clickable
                    @click="setSelected(row, $event)"
                />
            </div>
        </WCard>
        <Dropdown
            v-if="selected"
            :opened="!!selected"
            :options="(selected.options || {}).dropdown"
            :coords="selected.coords"
            @onClose="cleanSelected"
            @onClick="handleClickOption"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Dropdown } from '@/components'
import { filtersMixin } from '@/mixins'

export default {
    name: 'ModelCard',
    components: {
        Dropdown,
    },
    mixins: [filtersMixin],
    props: {
        row: VueTypes.any,
    },
    data () {
        return {
            selected: undefined,
        }
    },
    computed: {
        sourceLabel () {
            if (this.row.datasetId === null) {
                return this.$t('projects.models.source.uploaded')
            }
            return this.$t('projects.models.source.dataset')
        },
        sourceColor () {
            if (this.row.datasetId === null) {
                return 'primary'
            }
            return 'secondary'
        },
    },
    methods: {
        getInitial (name) {
            const [letter] = name || ''
            return letter || '-'
        },
        setSelected (row, event) {
            const { clientX: x, clientY: y } = event
            setTimeout(() => (this.selected = { coords: { x, y }, ...row }), 100)
        },
        cleanSelected () {
            this.selected = undefined
        },
        handleClickOption (option) {
            if (option.event) {
                this.handleEmit(option.event, this.selected)
            }
            this.cleanSelected()
        },
        handleEmit (event) {
            this.$emit('handleEmit', event)
        },
        handleGoToVisualization () {
            this.handleEmit('onVisualization', this.row)
        },
    },
}
</script>
<style scoped lang="scss">
.ModelCard {
  overflow: hidden;
  height: 100%;
  background: $gray-06;
}
.AvatarTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    min-width: 80px;
    min-height: 80px;
    height: 100%;
    background: $primary;
    border-radius: $border-radius;
    p {
      font-size: 32px;
      line-height: 0;
      font-weight: 700;
      text-transform: uppercase;
      color: $white;
    }
}
.WCard::v-deep .Body {
  height: 100%;
  position: relative;
}
.QBadge {
  padding: 10px
}
.h-full {
  height: 100%;
}
.ToggleCard {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $gray-06;
  display: flex;
  justify-content: space-between;
  transition: all .3s ease-in-out;
  color: $white;
}
.DatasetContent {
  max-width: 100%;
  overflow: hidden;
}
.DatasetCardName {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
