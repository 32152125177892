var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "Visualization Container flex row-stretch" },
      [
        _c("Sidebar", {
          ref: "Sidebar",
          attrs: {
            showAvatar: false,
            addMode: _vm.openFilterModal,
            infoTotal: _vm.infoTotal,
            infoFiltered: _vm.infoFiltered,
            displayedInfo: _vm.displayedInfo,
            processId: _vm.processId,
            readOnly: !_vm.disabledButton
          },
          on: {
            onManageFilter: _vm.handleManageFilters,
            onDeleteClick: _vm.handleDeleteFilter,
            onApply: _vm.handleApplyFilters,
            onReplaceFilters: _vm.handleApplyFilters,
            onCancel: _vm.handleCancelAddFilters,
            onReset: _vm.handleResetFilters
          }
        }),
        _c(
          "div",
          { staticClass: "Screen pos-r flex-1" },
          [
            _c(
              "QTabs",
              {
                staticClass: "Tabs text-grey-8",
                attrs: {
                  align: "left",
                  dense: "",
                  noCaps: "",
                  activeColor: "primary",
                  indicatorColor: "primary"
                },
                on: { input: _vm.handleChangeTab },
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              },
              _vm._l(_vm.getTabs, function(crtab, idx) {
                return _c(
                  "QTab",
                  {
                    key: idx,
                    attrs: {
                      name: crtab.id,
                      label: crtab.label,
                      icon: crtab.icon
                    }
                  },
                  [
                    _c(
                      "QTooltip",
                      { attrs: { contentClass: "bg-grey-3 text-black" } },
                      [_vm._v(" " + _vm._s(crtab.tooltip) + " ")]
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm.tab === "DASHBOARD"
              ? _c("DataNavigator", {
                  staticClass: "DataNavigator",
                  attrs: { processId: _vm.processId }
                })
              : _vm.tab === "ALERTS"
              ? _c("Alerts", {
                  staticClass: "Wrapper",
                  attrs: { processId: _vm.processId }
                })
              : _vm.tab === "COMPARISON"
              ? _c("ContextComparison", {
                  staticClass: "Wrapper",
                  attrs: {
                    processId: _vm.processId,
                    filteredInfo: _vm.infoFiltered,
                    sections: _vm.sectionsContext
                  },
                  on: { sectionList: _vm.updateSectionsList }
                })
              : _c(
                  "div",
                  {
                    staticClass: "Wrapper flex row-center-top",
                    attrs: { id: "view-wrapper" }
                  },
                  [
                    _vm.tab === "TRACES"
                      ? _c("TracesSection", {
                          staticClass: "TracesSection",
                          attrs: {
                            processId: _vm.processId,
                            filteredTraces: _vm.filteredTraces,
                            attributes: _vm.attributes
                          }
                        })
                      : _vm.tab === "CONFORMANCE"
                      ? _c("Conformance", {
                          staticClass: "Conformance",
                          attrs: {
                            processId: _vm.processId,
                            projectId: _vm.$route.params.projectId,
                            attributes: _vm.attributes
                          }
                        })
                      : _vm.tab === "PREDICTIONS"
                      ? _c("Predictions", {
                          ref: "Predictions",
                          attrs: { processId: _vm.processId },
                          model: {
                            value: _vm.model,
                            callback: function($$v) {
                              _vm.model = $$v
                            },
                            expression: "model"
                          }
                        })
                      : _vm.tab === "ASSISTANT"
                      ? _c("Assistant", {
                          staticClass: "Wrapper",
                          attrs: { processId: _vm.processId }
                        })
                      : _vm.tab === "SETTINGS"
                      ? _c("SettingsModal", {
                          ref: "SettingsModal",
                          attrs: {
                            processId: _vm.processId,
                            settings: _vm.settings
                          },
                          on: { settingsSaved: _vm.getCosts }
                        })
                      : _c(
                          "div",
                          {
                            ref: "mainContainer",
                            staticClass: "flex w-full ChargingHeight"
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "panelsContainer",
                                staticClass: "FixedPanels column q-gutter-xs"
                              },
                              [
                                _c("ChartContextualInfo", {
                                  staticClass: "FixedBar col",
                                  attrs: {
                                    totalInfo: _vm.infoTotal,
                                    filteredInfo: _vm.infoFiltered,
                                    displayedInfo: _vm.displayedInfo,
                                    percentageMode: _vm.percentageDisplay.value,
                                    currency: _vm.currency,
                                    costPerHour: _vm.costPerHour
                                  }
                                }),
                                _vm.tab === "LOOPS"
                                  ? _c("LoopMetricsPanel", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.toggleLoopMetrics,
                                          expression: "toggleLoopMetrics"
                                        }
                                      ],
                                      staticClass: "FixedBar col",
                                      attrs: {
                                        displayedInfo: _vm.displayedInfo,
                                        chartInfo: _vm.chart,
                                        currency: _vm.currency,
                                        costPerHour: _vm.costPerHour
                                      }
                                    })
                                  : _vm._e(),
                                _vm.attributes && _vm.chart
                                  ? _c("CustomPlotPanels", {
                                      attrs: {
                                        attributes: _vm.attributes,
                                        processId: _vm.processId,
                                        chart: _vm.chart,
                                        title: _vm.attributePanelOption,
                                        filters: _vm.formatFiltersParam(
                                          _vm.visualizationFilters
                                        )
                                      },
                                      on: { titleSel: _vm.changeOptionPanel1 }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ChartContainer flex-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "Actions flex row-stretch-center"
                                  },
                                  [
                                    _vm.chart
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex row-stretch-center"
                                          },
                                          [
                                            _vm.tab === "LOOPS"
                                              ? _c(
                                                  "QBtn",
                                                  {
                                                    staticClass: "q-mr-sm",
                                                    attrs: {
                                                      icon: "rotate_left",
                                                      unelevated: "",
                                                      color: _vm.toggleLoopMetrics
                                                        ? "primary"
                                                        : "white",
                                                      textColor: _vm.toggleLoopMetrics
                                                        ? "white"
                                                        : "primary"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.toggleLoopMetricsPanel
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "QTooltip",
                                                      {
                                                        attrs: {
                                                          contentClass:
                                                            "bg-grey-3 text-black"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "visualization.showLoopMetrics"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "QFab",
                                              {
                                                ref: "percentageFab",
                                                staticClass: "q-mr-sm",
                                                attrs: {
                                                  label: "%",
                                                  labelPosition: "left",
                                                  flat: "",
                                                  square: "",
                                                  icon:
                                                    _vm.percentageDisplay.icon,
                                                  activeIcon:
                                                    _vm.percentageDisplay.icon,
                                                  color: "grey-2",
                                                  textColor: "primary",
                                                  direction: "down",
                                                  padding: "sm"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleFab(
                                                      "percentageFab"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "tooltip",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "QTooltip",
                                                            {
                                                              attrs: {
                                                                contentClass:
                                                                  "bg-grey-3 text-black"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "visualization.changePercentage"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  4238325422
                                                )
                                              },
                                              _vm._l(_vm.percOptions, function(
                                                action,
                                                idx
                                              ) {
                                                return _c("QFabAction", {
                                                  key: idx,
                                                  attrs: {
                                                    color: "grey-2",
                                                    textColor: "primary",
                                                    icon: action.icon,
                                                    label: _vm.$t(action.label),
                                                    labelPosition: "left",
                                                    externalLabel: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setPercentageMode(
                                                        action
                                                      )
                                                    }
                                                  }
                                                })
                                              }),
                                              1
                                            ),
                                            _c(
                                              "QFab",
                                              {
                                                ref: "directionFab",
                                                staticClass: "q-mr-sm",
                                                attrs: {
                                                  flat: "",
                                                  square: "",
                                                  icon:
                                                    _vm.directionDisplay.icon,
                                                  activeIcon:
                                                    _vm.directionDisplay.icon,
                                                  color: "grey-2",
                                                  textColor: "primary",
                                                  direction: "down",
                                                  padding: "sm"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleFab(
                                                      "directionFab"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "tooltip",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "QTooltip",
                                                            {
                                                              attrs: {
                                                                contentClass:
                                                                  "bg-grey-3 text-black"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "visualization.changeGraphDirection"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1402965407
                                                )
                                              },
                                              _vm._l(
                                                _vm.directionOptions,
                                                function(action, idx) {
                                                  return _c("QFabAction", {
                                                    key: idx,
                                                    attrs: {
                                                      color: "grey-2",
                                                      textColor: "primary",
                                                      icon: action.icon
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setDirectionMode(
                                                          action
                                                        )
                                                      }
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                            _c(
                                              "QFab",
                                              {
                                                ref: "statsFab",
                                                staticClass: "q-mr-sm",
                                                attrs: {
                                                  labelPosition: "left",
                                                  flat: "",
                                                  square: "",
                                                  label:
                                                    _vm.statsDisplay
                                                      .abbreviation,
                                                  icon: "schedule",
                                                  activeIcon: "schedule",
                                                  color: "grey-2",
                                                  textColor: "primary",
                                                  direction: "down",
                                                  padding: "sm"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleFab(
                                                      "statsFab"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "tooltip",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "QTooltip",
                                                            {
                                                              attrs: {
                                                                contentClass:
                                                                  "bg-grey-3 text-black"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "visualization.changeGraphMetric"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  570927582
                                                )
                                              },
                                              _vm._l(_vm.statsOptions, function(
                                                action,
                                                idx
                                              ) {
                                                return _c("QFabAction", {
                                                  key: idx,
                                                  attrs: {
                                                    labelPosition: "left",
                                                    label: action.label,
                                                    color: "grey-2",
                                                    textColor: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setStatsMode(
                                                        action
                                                      )
                                                    }
                                                  }
                                                })
                                              }),
                                              1
                                            ),
                                            _c(
                                              "QBtn",
                                              {
                                                staticClass: "q-mr-sm",
                                                attrs: {
                                                  icon: "adjust",
                                                  unelevated: "",
                                                  color: "white",
                                                  textColor: "primary"
                                                },
                                                on: {
                                                  click: _vm.handleCenterChart
                                                }
                                              },
                                              [
                                                _c(
                                                  "QTooltip",
                                                  {
                                                    attrs: {
                                                      contentClass:
                                                        "bg-grey-3 text-black"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "visualization.centerChart"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "QBtn",
                                              {
                                                staticClass: "q-mr-sm",
                                                attrs: {
                                                  icon: "schema",
                                                  unelevated: "",
                                                  color: "white",
                                                  textColor: "primary"
                                                },
                                                on: { click: _vm.saveBpmnModel }
                                              },
                                              [
                                                _c(
                                                  "QTooltip",
                                                  {
                                                    attrs: {
                                                      contentClass:
                                                        "bg-grey-3 text-black"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "visualization.bpmnSave"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                !_vm.settedChart || _vm.variantsLoading
                                  ? _c("WLoading", { attrs: { visible: "" } })
                                  : _vm.chart &&
                                    (_vm.chart.activities || []).length &&
                                    (Object.keys(_vm.chart) || []).length
                                  ? _c("Graph", {
                                      ref: "chart",
                                      staticClass: "Graph",
                                      attrs: {
                                        data: _vm.chart,
                                        model: _vm.model,
                                        color: _vm.color,
                                        parent: "view-wrapper"
                                      }
                                    })
                                  : _c("div", { staticClass: "EmptyModel" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "visualization.filters.emptyModel"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "Options",
                                class: { visible: _vm.visibleOptions }
                              },
                              [
                                _vm.processId
                                  ? _c(
                                      "QTabPanels",
                                      {
                                        attrs: {
                                          animated: "",
                                          transitionNext: "fade",
                                          transitionPrev: "fade"
                                        },
                                        model: {
                                          value: _vm.tab,
                                          callback: function($$v) {
                                            _vm.tab = $$v
                                          },
                                          expression: "tab"
                                        }
                                      },
                                      [
                                        _c(
                                          "QTabPanel",
                                          { attrs: { name: "MODEL" } },
                                          [
                                            _c("Variants", {
                                              ref: "MODEL",
                                              attrs: {
                                                processId: _vm.processId,
                                                info: _vm.infoTotal,
                                                filteredInfo: _vm.infoFiltered,
                                                percentageMode:
                                                  _vm.percentageDisplay.value,
                                                disabledButton: !_vm.disabledButton
                                              },
                                              on: {
                                                onCenterChart:
                                                  _vm.handleCenterChart,
                                                onLoadChart: _vm.handleSetChart,
                                                onColorLoops:
                                                  _vm.handleColorChartLoops,
                                                onReset: _vm.handleResetModel,
                                                variantsLoading:
                                                  _vm.loadingControl,
                                                onOpenRenameModal:
                                                  _vm.handleOpenRenameModal,
                                                onChangedVariant: function(
                                                  $event
                                                ) {
                                                  _vm.settedChart = false
                                                },
                                                onChangedVariantTab:
                                                  _vm.handleChangeVariantTab
                                              },
                                              model: {
                                                value: _vm.model,
                                                callback: function($$v) {
                                                  _vm.model = $$v
                                                },
                                                expression: "model"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "QTabPanel",
                                          { attrs: { name: "LOOPS" } },
                                          [
                                            _c("Loops", {
                                              attrs: {
                                                processId: _vm.processId,
                                                info: _vm.infoTotal,
                                                filteredInfo: _vm.infoFiltered,
                                                percentageMode:
                                                  _vm.percentageDisplay.value,
                                                disabledButton: !_vm.disabledButton
                                              },
                                              on: {
                                                onCenterChart:
                                                  _vm.handleCenterChart,
                                                onLoadChart: _vm.handleSetChart,
                                                onReset: _vm.handleResetModel,
                                                variantsLoading:
                                                  _vm.loadingControl
                                              },
                                              model: {
                                                value: _vm.model,
                                                callback: function($$v) {
                                                  _vm.model = $$v
                                                },
                                                expression: "model"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                  ],
                  1
                ),
            _vm.processId
              ? _c("FilterModal", {
                  ref: "FilterModal",
                  attrs: {
                    processId: _vm.processId,
                    attributes: _vm.attributes,
                    isOpen: _vm.openFilterModal
                  },
                  on: {
                    close: _vm.handleCancelAddFilters,
                    onSaveFilter: _vm.handleAddFilter
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("CreateModel", {
          ref: "modelModal",
          on: { save: _vm.generateModel }
        }),
        _c("RenameModel", {
          ref: "renameVariantModal",
          on: { save: _vm.renameVariant }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }