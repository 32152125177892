var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "EventsBox", class: [_vm.color] },
    [
      _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
        _vm._v(" " + _vm._s(_vm.title) + " ")
      ]),
      _c(
        "div",
        { staticClass: "Amount" },
        [
          _c("Counter", {
            attrs: {
              size: 32,
              value: _vm.value,
              duration: _vm.duration,
              color: "primary",
              formatValue: _vm.formatNumber
            }
          }),
          _c(
            "WText",
            {
              attrs: {
                size: 12,
                color: "gray02",
                weight: "semi",
                uppercase: ""
              }
            },
            [_vm._v(" " + _vm._s(_vm.caption) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }