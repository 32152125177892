<template>
    <div class="Step">
        <div class="Wrapper">
            <div>{{ $t('projects.modals.upload.text') }}</div>
            <QUploader
                v-if="token"
                flat
                bordered
                accept=".csv"
                sendRaw
                color="grey-1"
                textColor="primary"
                class="Uploader mt-1 mb-0-5"
                :label="$t('actions.uploadFile')"
                :class="{ error, length: !!length, uploaded: isUploaded }"
                autoUpload
                :headers="headers"
                :method="method"
                :multiple="false"
                :url="url"
                @added="handleAdd"
                @removed="handleRemove"
                @start="handleStarted"
                @finish="handleFinished"
                @uploaded="handleSuccess"
            />
            <WText
                v-if="error"
                :size="12"
                color="red"
            >
                {{ $t('validation.required', { attribute: $t('general.file') }) }}
            </WText>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, notifyError, apiRequest } from '@/api'

import Config from '@/config'

export default {
    name: 'StepUpload',
    props: {
        value: VueTypes.shape({
            datasetId: VueTypes.any.isRequired,
            file: VueTypes.any,
        }).loose,
        steps: VueTypes.any,
    },
    data () {
        return {
            method: 'POST',
            token: undefined,
            length: 0,
            isInvalid: false,
            isLoading: false,
            isUploaded: false,
        }
    },
    computed: {
        label () {
            return this.$t(`actions.${this.isUploaded ? `close` : `cancel`}`)
        },
        url () {
            const { baseURL } = Config.api
            const { value, token } = this
            const { datasetId } = value
            return `${baseURL}/uploads/datasets/${datasetId}/${token}`
        },
        error () {
            return this.isInvalid || (this.isInvalid && !this.length)
        },
    },
    mounted () {
        this.generateToken()
    },
    methods: {
        headers (files) {
            const { name } = files[0]
            return [
                { name: 'Content-Type', value: 'application/octet-stream' },
                { name: 'X-File-Name', value: name },
            ]
        },
        handleAdd () {
            this.length += 1
        },
        generateToken () {
            const { datasetId } = this.value
            apiRequest(Api().datasets.generateUploadToken({ datasetId }))
                .then(({ token }) => { this.token = token })
                .catch(notifyError)
        },
        handleRemove () {
            this.length -= 1
        },
        handleStarted () {
            this.isInvalid = false
            this.isLoading = true
        },
        handleFinished () {
            this.isLoading = false
        },
        handleSuccess ({ files = [] }) {
            const [file] = files
            const { name, type, size } = file || {}

            this.isUploaded = true
            this.$emit('input', {
                ...this.value,
                file: {
                    name,
                    type,
                    size,
                },
            })
        },
        isValid () {
            this.isInvalid = !this.isUploaded
            return this.isUploaded
        },
    },
}
</script>
<style scoped lang="scss">
$grid-width: 575px;

.Wrapper {
    width: 100%;
    max-width: $grid-width;
    margin: 0 auto;
}

.Uploader {
    width: 100%;

    &::v-deep {
        .q-uploader__header {
            padding: 5px 10px;

            // Hide list button in quasar component
            .q-uploader__header-content a:first-child {
                display: none;
            }
        }

        .q-uploader__list {
            min-height: 0;
            padding: 0;
        }
        .q-uploader__file-header {
            padding: 10px;
        }
    }

    &.error:before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        border-radius: 4px;
        border: 2px solid $danger;
    }
    &.uploaded {
        &::v-deep {
        .q-uploader__header {
            .q-uploader__header-content a:last-child {
                display: none;
            }
        }
        }
    }
    &.length {
        &::v-deep {
            .q-uploader__list {
                padding: 15px;
            }
        }
    }
}
</style>
