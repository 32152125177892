
<template>
    <div
        v-if="isOpen"
        class="CostCurrencySettings"
    >
        <div class="CostCurrency">
            <WText
                class="mb-1"
                weight="bold"
                color="primary"
            >
                {{ $t('visualization.settings.costs.name') }}
            </WText>
            <div class="OptionsGroup row justify-start">
                <QInput
                    v-model="number"
                    standout
                    outlined
                    class="col"
                    type="number"
                    labelColor="primary"
                    :label="$t('visualization.settings.costs.value')"
                    color="primary"
                    :autogrow="inputOptions"
                    @input="saveWithoutButton"
                />
                <QSelect
                    v-model="unit"
                    outlined
                    class="col"
                    labelColor="primary"
                    color="primary"
                    :label="$t('visualization.settings.costs.unit')"
                    :options="curOptions"
                    @input="saveWithoutButton"
                />
            </div>
            <div
                v-if="saveButton"
                class="flex justify-end mt-1"
            >
                <WButton
                    class="buttons"
                    outline
                    color="primary"
                    @click="saveWithButton"
                >
                    {{ $t('visualization.filters.save') }}
                </WButton>
            </div>
        </div>
    </div>
</template>

<script>
import { QInput } from 'quasar'
import VueTypes from 'vue-types'

export default {
    name: 'CostCurrencySettings',
    emits: ['CostCurrencyConfig'],
    components: { QInput },
    mixins: [],
    props: {
        saveButton: VueTypes.bool.def(true),
        isOpen: VueTypes.bool.def(false),
        attributes: VueTypes.array,
        values: VueTypes.object,
    },
    data () {
        return {
            inputOptions: false,
            unit: { value: 'euro', label: this.$t('visualization.settings.costs.units.euro') },
            number: 0,
            timeout: null,
        }
    },
    computed: {
        curOptions () {
            return [
                {
                    label: this.$t('visualization.settings.costs.units.euro'),
                    value: 'euro',
                },
                {
                    label: this.$t('visualization.settings.costs.units.dollar'),
                    value: 'dollar',
                },
                {
                    label: this.$t('visualization.settings.costs.units.pound'),
                    value: 'pound',
                },
            ]
        },
    },
    watch: {
    },
    beforeMount () {
    },
    mounted () {
        this.checkIfValues()
        this.saveWithoutButton()
    },
    methods: {
        checkIfValues () {
            if (this.values && this.values.unit && this.values.value) {
                this.unit.value = this.values.unit
                this.number = this.values.value
                this.unit.label = this.getLabelByValue(this.unit.value)
            }
        },
        saveWithoutButton () {
            if (!this.saveButton) {
                this.saveCostCurrency()
            }
        },
        saveWithButton () {
            this.saveCostCurrency()
        },
        saveCostCurrency () {
            const cost = {
                unit: this.unit.value ? this.unit.value : this.unit,
                value: this.number,
            }
            this.$emit('CostCurrencyConfig', { data: cost, type: 'cost' })
        },
        getLabelByValue (value) {
            const option = this.curOptions.find(opt => opt.value === value)
            return option ? option.label : ''
        },

    },
}
</script>
<style lang="scss" scoped>
.CostCurrencySettings {
    top: $subheader-height;
    min-height: 300px;
    max-width: 750px;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    z-index: 2002;
    background: white;
    border: solid 1px rgba($black, 0.2);
    box-shadow: 0 2px 5px 0 rgba(68, 68, 68, 0.2);
    border-radius: $border-radius;
    width: 60%;
    .CostCurrency {
        margin: 20px;
    }
    .OptionsGroup {
        gap: 10px;
    }
    .WText{
        margin-top: 20px;
    }
    .buttons {
        width: 80px;
    }
}
</style>
