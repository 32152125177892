import Data from './data'

export default {
    get: ({ url, baseURL, regex }) => {
        const path = url.replace(baseURL, '')
        const [,, type] = path.match(regex)
        const data = type === 'STANDARD_CONFORMANCE' ? Data.query : Data.discovery
        return [200, data]
    },
}
