var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WButton",
    {
      attrs: { color: "none" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("WIcon", { attrs: { icon: _vm.icon, size: 24, color: "primary" } }),
      _c(
        "QTooltip",
        { attrs: { contentClass: "bg-secondary", maxWidth: "500px" } },
        [
          _c("WText", {
            staticClass: "pt-0-5 pb-0-5 pl-0-5 pr-0-5",
            attrs: { color: "white" },
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }