<template>
    <WButton
        color="none"
        @click="$emit('click')"
    >
        <WIcon
            :icon="icon"
            :size="24"
            color="primary"
        />
        <QTooltip
            contentClass="bg-secondary"
            maxWidth="500px"
        >
            <WText
                color="white"
                class="pt-0-5 pb-0-5 pl-0-5 pr-0-5"
                v-html="text"
            />
        </QTooltip>
    </WButton>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'Help',
    props: {
        icon: VueTypes.string.def('help-circle'),
        text: VueTypes.string,
    },
}
</script>
