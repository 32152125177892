var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      !_vm.showCreate
        ? _c(
            "div",
            [
              _c("div", { staticClass: "row justify-between q-mt-md" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("QBtn", {
                      staticClass: "q-ma-sm",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("visualization.alerts.definition.create"),
                        icon: "add",
                        noCaps: ""
                      },
                      on: { click: _vm.toggleCreationView }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col align-right" },
                  [
                    _c("QBtn", {
                      staticClass: "q-ma-sm",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("visualization.alerts.definition.remove"),
                        icon: "delete",
                        noCaps: "",
                        disable: _vm.cantRemove
                      },
                      on: { click: _vm.deleteDefinitions }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row q-mt-md q-justify-end" }, [
                _c("div", { staticClass: "textColumn" }, [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("visualization.alerts.filterBy")) +
                        " "
                    )
                  ])
                ]),
                _c(
                  "div",
                  [
                    _c("q-select", {
                      staticClass: "custom-select",
                      attrs: {
                        options: _vm.filterOptions,
                        outlined: "",
                        dense: ""
                      },
                      on: { input: _vm.fetchData },
                      model: {
                        value: _vm.filterByType,
                        callback: function($$v) {
                          _vm.filterByType = $$v
                        },
                        expression: "filterByType"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("QTable", {
                ref: "table",
                staticClass: "q-mt-sm",
                attrs: {
                  data: _vm.rows,
                  columns: _vm.columns,
                  rowKey: "name",
                  flat: "",
                  outlined: "",
                  separator: "none",
                  bordered: "",
                  tableHeaderClass: "bg-grey-2",
                  selection: "multiple",
                  selectedRowsLabel: _vm.getSelectedString,
                  selected: _vm.selected,
                  loading: _vm.loading,
                  pagination: _vm.pagination,
                  rowsPerPageOptions: _vm.rowsPerPageOptions
                },
                on: {
                  "update:selected": function($event) {
                    _vm.selected = $event
                  },
                  "update:pagination": function($event) {
                    _vm.pagination = $event
                  },
                  request: _vm.handleTableRequest
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "body-cell-severity",
                      fn: function(cell) {
                        return [
                          _c(
                            "QTd",
                            { attrs: { props: cell } },
                            [
                              _c("QIcon", {
                                attrs: {
                                  name: "circle",
                                  color:
                                    "" +
                                    _vm.getSeverityRiskColor(
                                      cell.row.severity || cell.row.riskSLA
                                    ),
                                  size: "20px"
                                }
                              }),
                              cell.row.type === "STANDARD"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.alerts.detected.severityRiskLabels." +
                                              (cell.row.severity ||
                                                cell.row.riskSLA)
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "visualization.alerts.detected.predictionRiskLabels." +
                                              (cell.row.severity ||
                                                cell.row.riskSLA)
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "body-cell-type",
                      fn: function(type) {
                        return [
                          _c(
                            "QTd",
                            { attrs: { props: type } },
                            [
                              _c("QIcon", {
                                attrs: {
                                  name: "circle",
                                  color: "" + _vm.getTypeColor(type.value),
                                  size: "20px"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "visualization.alerts.definition.types." +
                                        type.value
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function(ref) {
                        var message = ref.message
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "full-width row flex-center text-accent q-gutter-sm"
                            },
                            [_c("span", [_vm._v(" " + _vm._s(message) + " ")])]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  752084386
                )
              })
            ],
            1
          )
        : _c("NewAlert", {
            staticClass: "q-ma-md",
            attrs: { processId: _vm.processId, filterSets: _vm.filterSets },
            on: { cancel: _vm.toggleCreationView, create: _vm.createDefinition }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }