<template>
    <div class="row q-ma-md">
        <div v-if="!slaNotCompleted">
            <div v-if="!ready">
                <div v-if="interval">
                    {{ $t('visualization.predictions.loadingMessage') }}
                </div>
                <WLoading
                    visible
                />
            </div>
            <div class="PredictionList col-sm-9">
                <div
                    v-if="ready"
                    class="row q-gutter-sm"
                >
                    <PredictionMetricsPanel
                        class="col"
                        :noFinished="total"
                    />
                    <PredictionMetricsPanel
                        class="col"
                        :noRisk="noRisk"
                        :noFinished="total"
                    />
                    <PredictionMetricsPanel
                        class="col"
                        :risk="risk"
                        :noFinished="total"
                    />
                </div>
            </div>
            <div class="PredictionList col-12 row">
                <PredictionTable
                    v-if="ready"
                    ref="table"
                    class="col-sm-9 mt-1"
                    :columns="columns"
                    :rows="rows"
                    :loading="isLoading"
                    :total="total"
                    @download="handleDownload"
                    @selectedId="caseMetrics"
                    @update="update"
                    @ready="selectFirstRow"
                />
                <div class="col-sm-3 GraphColumn">
                    <div
                        ref="view-wrapper"
                        class="GraphHolder flex items-center q-ml-md"
                    >
                        <Graph
                            v-if="!newVariant && chart && (chart.activities || []).length && (Object.keys(chart) || []).length"
                            ref="chart"
                            :data="chart"
                            :model="model"
                            parent="view-wrapper"
                            class="Graph"
                        />
                        <div
                            v-if="newVariant"
                            class="col text-center text-h6"
                        >
                            {{ $t('visualization.predictions.newVariant') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div>
                {{ slaNotCompleted }}
            </div>
        </div>
    </div>
</template>

<script>

import VueTypes from 'vue-types'
import moment from 'moment'
import {
    Api,
    apiRequest,
    notifyError,
    notifySuccess,
    returnError,
} from '@/api'
import { PredictionMetricsPanel } from '../components'
import PredictionTable from './PredictionTable.vue'
import { filtersStorageMixin } from '@/mixins'
import { download } from '@/services/download'
import Graph from '@/components/Graph/Graph.vue'

const DEFAULT_TAB = 'TABLE'
const INITIAL_MODEL = {
    variant: 1,
    variants: 1,
    heatMapValue: 'none',
    directionValue: 'TB',
    statsValue: 'averages',
    values: 'freq',
}
export default {
    name: 'Predictions',
    components: {
        PredictionMetricsPanel,
        PredictionTable,
        Graph,
    },
    mixins: [filtersStorageMixin],
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    },
    data () {
        return {
            attributes: [],
            columns: [],
            rows: null,
            total: 0,
            isLoading: false,
            tab: DEFAULT_TAB,
            activities: null,
            colors: null,
            ready: false,
            risk: 0,
            noRisk: 0,
            allTraces: [],
            estimatedDuration: '-',
            estimatedVariant: '-',
            riskLevel: '-',
            nextActivity: '-',
            caseId: '-',
            all: '',
            flag: false,
            chart: undefined,
            model: INITIAL_MODEL,
            newVariant: false,
            interval: null,
            slaNotCompleted: null,
            data: {
                sortBy: 'caseId',
                sortDirection: 'DESCENDING',
                limit: '30',
                start: 0,
            },
        }
    },
    computed: {
    },
    mounted () {
        if (this.processId) this.getPrediction()
    },
    methods: {
        update (date) {
            this.data = {
                sortBy: date.sortBy,
                sortDirection: date.descending ? 'DESCENDING' : 'ASCENDING',
                limit: date.rowsPerPage,
                start: date.page - 1,
            }
            this.isLoading = true
            this.getPrediction()
        },
        caseMetrics (id) {
            this.estimatedDuration = id.estimatedDuration
            this.estimatedVariant = id.estimatedVariant
            this.riskLevel = id.riskLevel
            this.caseId = id.caseId
            const nextAc = id.nextActivities[0]
            nextAc === undefined ? this.nextActivity = '-' : this.nextActivity = nextAc
            if (this.riskLevel >= 0) {
                this.newVariant = false
                this.getTraceIdPrediction()
            } else {
                this.handleNewVariant()
            }
            if (this.estimatedVariant === 'New variant') {
                this.handleNewVariant()
            }
        },
        selectFirstRow () {
            this.newVariant = false
            if (this.rows) {
                if (this.rows.table) this.$refs.table.selectRow(this.rows[0])
                this.caseMetrics(this.rows[0])
            }
        },
        handleNewVariant () {
            this.newVariant = true
        },
        getPrediction () {
            apiRequest(Api().visualizations.predictions({ processId: this.$route.params.processId, params: this.data }))
                .then(({
                    data, total, noRisk, risk,
                }) => {
                    if (!data.length) {
                        this.rows = data
                        this.total = total
                    }
                    this.noRisk = noRisk
                    this.risk = risk
                    this.all = data
                    const keys = Object.keys(data[0]).filter(key => key !== 'nextActivities')
                    this.attributes = keys.map(value => ({ name: value }))
                    this.rows = this.formatRows(data)
                    this.columns = this.formatColumns(data)
                    this.total = total
                })
                .catch((error) => {
                    const errorCode = returnError(error)
                    if (errorCode.status === 404) {
                        this.slaNotCompleted = errorCode.message
                    } else if (errorCode.status === 503 && !this.interval) {
                        this.interval = setInterval(this.getPrediction, 5000)
                    }
                })
                .finally(() => {
                    if (this.all) {
                        clearInterval(this.interval)
                        this.ready = true
                    }
                    this.isLoading = false
                })
        },
        getTraceIdPrediction () {
            this.isLoading = true
            apiRequest(Api().visualizations.predictionTrace({ processId: this.$route.params.processId, traceId: this.caseId, params: this.filter }))
                .then(({
                    data,
                }) => {
                    const [destrData] = data
                    const formattedData = this.formatChartData(destrData)
                    this.chart = data ? { ...formattedData } : undefined
                })
                .catch(notifyError)
                .finally(() => {
                    this.ready = true
                    this.isLoading = false
                })
        },
        formatChartData (data = {}) {
            const activitiesIds = (data.activities || []).reduce((acc, { id, name }, i) => ({ ...acc, [id]: name }), {})
            const arcs = (data.arcs || []).map(({ source, target, ...rest }) => ({
                ...rest,
                source: activitiesIds[source],
                target: activitiesIds[target],
                sourceId: source,
                targetId: target,
            }))
            return { ...data, arcs }
        },
        formatRows (data) {
            return data.map((obj) => {
                const formattedObj = {}

                Object.entries(obj).forEach(([key, value]) => {
                    if ((key === 'estimatedDuration') || (key === 'currentDuration')) {
                        formattedObj[key] = this.formatDate(value)
                    } else {
                        formattedObj[key] = value
                    }
                })

                return formattedObj
            })
        },
        formatDate (date) {
            return moment.duration(date, 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false })
        },
        formatColumns (data) {
            if (data.length === 0) {
                return []
            }
            const firstItem = data[0]
            const valueToRemove = 'nextActivities'
            const backgroundValues = ['riskLevel', 'currentDuration', 'estimatedDuration', 'estimatedVariant', 'lastActivity']
            const keys = Object.keys(firstItem)
                .filter(item => item !== valueToRemove)
                .map(item => ({
                    value: item,
                    type: backgroundValues.includes(item) ? 'primary' : 'other',
                }))
            return this.formatField(keys)
        },
        formatField (field) {
            const finalArray = field.map(values => ({
                name: values.value,
                required: true,
                label: this.$t(`visualization.predictions.${values.value}`),
                field: values.value,
                align: 'center',
                sortable: true,
                headerClasses: `${values.type === 'primary' ? 'bg-grey-4' : 'bg-grey-3'}`,
            }))
            return finalArray
        },
        openExport () {
            this.$refs.attrSelector.open()
        },
        convertToCSV (data, selectedColumns) {
            const columnNames = Object.keys(data[0])
                .filter(key => selectedColumns.includes(key))

            const csvRows = [columnNames.map(name => name.charAt(0).toUpperCase() + name.slice(1)).join(',')]
            data.forEach((obj) => {
                const rowValues = columnNames.map(key => obj[key])
                csvRows.push(rowValues.join(','))
            })

            const csvContent = csvRows.join('\n')
            return csvContent
        },
        handleDownload () {
            this.isLoading = true
            const csvData = {
                sortBy: this.data.sortBy,
                sortDirection: this.data.sortDirection,
                limit: this.total,
                start: 0,
            }
            const attributes = this.columns.map(name => name.field)
            apiRequest(Api().visualizations.predictions({ processId: this.$route.params.processId, params: csvData }))
                .then(({
                    data,
                }) => {
                    this.allTraces = data
                })
                .catch(notifyError)
                .finally(() => {
                    download({ name: this.$route.params.processId, data: this.convertToCSV(this.allTraces, attributes) })
                    notifySuccess(this.$t('visualization.traces.downloadOK'), (this.isLoading = false))
                })
        },
    },
}
</script>

<style lang="scss" scoped>

    .WLoading {
        top: 5vh;
        left: 0;
        bottom: 0;
        right: 0;
        &::v-deep .Backdrop {
            opacity: 1 !important;
        }
    }
    .GraphColumn{
        padding-top: 20px;
    }
    .GraphHolder{
        border: 1px solid #e6e6f2;
        height: 100%;
    }
    .PredictionList{
        max-width: 100%;
    }
    .Graph {
        max-height: 70vh;
    }

</style>
