var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StepDataset flex flex-center" },
    [
      _c("Table", {
        ref: "table",
        staticClass: "Table",
        attrs: {
          rowKey: "uuid",
          columns: _vm.cols,
          data: _vm.filteredDatasets,
          total: _vm.total,
          hideHeader: _vm.hideHeader,
          loading: _vm.isFetching
        },
        on: { "table-request": _vm.fetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c(
                  "QTr",
                  {
                    class: {
                      selected: (_vm.value || {}).datasetId === row.uuid
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleClickItem(row)
                      }
                    }
                  },
                  [
                    _c(
                      "QTd",
                      { key: "name", staticClass: "td-name" },
                      [
                        _c("WText", { attrs: { size: 14, weight: "semi" } }, [
                          _vm._v(" " + _vm._s(row.name) + " ")
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("general.createdAt")) +
                              " " +
                              _vm._s(_vm._f("date")(row.createDate)) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "filename" },
                      [
                        row.uploaded
                          ? _c(
                              "WText",
                              {
                                staticClass: "ellipsis",
                                attrs: { ellipsis: "" }
                              },
                              [
                                _vm._v(" " + _vm._s(row.fileName) + " "),
                                _c(
                                  "QTooltip",
                                  { attrs: { contentClass: "bg-secondary" } },
                                  [_vm._v(" " + _vm._s(row.fileName) + " ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "QTd",
                      { key: "description", staticClass: "td-desc" },
                      [
                        _c("span", { staticClass: "ellipsis" }, [
                          _vm._v(_vm._s(row.description || "-"))
                        ]),
                        row.description
                          ? _c(
                              "QTooltip",
                              {
                                attrs: {
                                  contentClass: "bg-secondary",
                                  maxWidth: "300px"
                                }
                              },
                              [_vm._v(" " + _vm._s(row.description) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("QTd", { key: "tst", staticClass: "td-tst" }, [
                      row.tst
                        ? _c("span", [_vm._v(_vm._s(_vm._f("date")(row.tst)))])
                        : _c("span", [_vm._v("-")])
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "EmptyList",
                  _vm._b(
                    {
                      staticClass: "mt-2 mb-2",
                      scopedSlots: _vm._u([
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c(
                                "WButton",
                                {
                                  staticClass: "mt-1",
                                  attrs: { iconLeft: "plus-circle" },
                                  on: { click: _vm.handleCreateItem }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("projects.datasets.new")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    "EmptyList",
                    _vm.$t("projects.datasets.notifications.empty"),
                    false
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }