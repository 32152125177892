<template>
    <div class="Step">
        <QForm ref="form">
            <div class="Wrapper flex col-stretch mb-0-5">
                <QInput
                    :value="search"
                    :label="$t('actions.search')"
                    outlined
                    type="search"
                    class="Search"
                    :disable="isLoading"
                    @input="handleFilter"
                >
                    <template v-slot:append>
                        <WIcon
                            v-if="search"
                            icon="close"
                            :size="16"
                            clickable
                            @click="handleCleanSearch"
                        />
                        <WIcon
                            icon="search1"
                            :size="20"
                            color="grayMedium"
                            clickable
                            @click="handleFilter"
                        />
                    </template>
                </QInput>
            </div>
            <div class="Workflows flex flex-nowrap col-top-stretch mb-1">
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <WLoading
                        v-if="isLoading"
                        visible
                    />
                    <fragment v-else>
                        <div
                            v-if="!(options || []).length"
                            class="Empty flex row-center"
                        >
                            <EmptyList
                                v-bind="$t('wizard.connection.notifications.empty')"
                                class="mt-2 mb-2"
                            >
                                <!-- <WButton
                                    v-if="!search"
                                    class="mt-1 pl-1 pr-1"
                                    @click="importWorkflows"
                                >
                                    {{ $t('actions.retry') }}
                                </WButton> -->
                            </EmptyList>
                        </div>
                        <QCheckbox
                            v-for="(option, index) in options"
                            :key="`option-${index}`"
                            :value="checkedOptions(option.key)"
                            :label="option.label"
                            color="secondary"
                            class="Checkbox pl-1 pr-1 pt-0-5 pb-0-5"
                            @input="handleInput(option, $event)"
                        />
                    </fragment>
                </Transition>
            </div>
            <Transition
                name="fade"
                mode="out-in"
            >
                <div
                    v-if="(((value || {}).workflow || {}).workflows || []).length && !isLoading"
                    class="Wrapper Tags flex mb-1"
                >
                    <div
                        v-for="(tag, index) in value.workflow.workflows"
                        :key="`tag-${index}`"
                        class="Tag flex row-center mr-0-5 mb-0-5"
                    >
                        <WText
                            color="white"
                            weight="semi"
                        >
                            {{ tag.label }}
                        </WText>
                        <WIcon
                            :size="18"
                            icon="x"
                            color="white"
                            class="ml-0-5"
                            clickable
                            @click="handleInput(tag, false)"
                        />
                    </div>
                </div>
            </Transition>
            <fragment v-if="enabledFilters">
                <div class="Separator" />
                <div class="Wrapper row justify-center items-start mt-0-5">
                    <div class="flex flex-1 col-top-left pl-1 pr-1">
                        <QCheckbox
                            v-model="((value || {}).workflow || {}).traces"
                            :label="$t('wizard.connection.filterTraces')"
                        />
                        <QCheckbox
                            v-model="((value || {}).workflow || {}).events"
                            :label="$t('wizard.connection.filterEvents')"
                        />
                    </div>
                </div>
            </fragment>
        </QForm>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Api, apiRequest, notifyError } from '@/api'
import { EmptyList } from '@/components'
import { validatorsMixin } from '@/mixins'

const normalizeWorkFlows = items => items.map(({ identifier, name }) => ({ label: name, value: name, key: name }))

export default {
    name: 'StepWorkflow',
    components: {
        EmptyList,
    },
    mixins: [validatorsMixin],
    props: {
        value: VueTypes.shape({
            workflow: VueTypes.any.isRequired,
            service: VueTypes.any,
            connection: VueTypes.shape({
                restService: VueTypes.oneOfType([VueTypes.string, null]),
                connectionChain: VueTypes.oneOfType([VueTypes.string, null]),
                database: VueTypes.oneOfType([VueTypes.string, null]),
                driver: VueTypes.any,
                host: VueTypes.oneOfType([VueTypes.string, null]),
                password: VueTypes.oneOfType([VueTypes.string, null]),
                port: VueTypes.oneOfType([VueTypes.string, null]),
                username: VueTypes.oneOfType([VueTypes.string, null]),
            }).loose,
        }).loose,
        importer: VueTypes.string,
        enabledFilters: VueTypes.bool.def(false),
    },
    data () {
        return {
            search: '',
            options: undefined,

            workflows: undefined,
            isLoading: false,
        }
    },
    mounted () {
        const { allowJdbc, allowRest, key } = this.value?.service || {}
        if (!allowJdbc && allowRest) this.getImporterHttpWorkflows(key)
        if (!allowJdbc && !allowRest) this.getDenodoIntegratedWorkflows()
        if (allowJdbc && !allowRest) this.getWorkFlows()
    },
    methods: {
        filterWorkflows (data = []) {
            if (this.value?.workflow?.workflows) {
                this.value.workflow.workflows = (this.value?.workflow?.workflows || []).filter(w => data.some(d => d.name === w.key))
            }
        },
        async getDenodoIntegratedWorkflows () {
            this.isLoading = true
            apiRequest(Api().datasets.denodoIntegratedImporter())
                .then(({ data }) => {
                    const workflows = normalizeWorkFlows(data)
                    this.workflows = workflows
                    this.options = workflows
                    this.filterWorkflows(data)
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        async getImporterHttpWorkflows (serviceKey) {
            this.isLoading = true

            const { restService, username, password } = this.value.connection
            apiRequest(Api().datasets.httpImporter({ params: { url: restService, username, password }, importer: serviceKey }))
                .then(({ data }) => {
                    const workflows = normalizeWorkFlows(data)
                    this.workflows = workflows
                    this.options = workflows
                    this.filterWorkflows(data)
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        normalizeWorkFlows (data) {
            return data.map(d => ({
                label: d.name,
                key: d.name,
            }))
        },
        async getWorkFlows () {
            this.isLoading = true
            const {
                connectionChain, driver, host,
                database,
                password,
                port,
                username,
            } = this.value?.connection
            const params = {
                ...(connectionChain ? {
                    connectionChain,
                } : {
                    driver: (driver || {}).key,
                    host,
                    database,
                    password,
                    port,
                    username,
                }),
            }
            apiRequest(Api().datasets.jdbcImporter({ importer: this.importer, params, config: { timeout: 0 } }))
                .then(({ data }) => {
                    const workflows = normalizeWorkFlows(data)
                    this.options = workflows
                    this.workflows = workflows
                    this.filterWorkflows(data)
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        isValid () {
            const { form } = this.$refs
            return form ? form.validate().then(result => result) : true
        },
        checkedOptions (key) {
            const checked = (this.value?.workflow?.workflows || []).find(w => w.key === key)
            return !!checked
        },
        handleCleanSearch () {
            const empty = ''
            this.search = empty
            this.handleFilter(empty)
        },
        handleReset () {
            this.$emit('input', { ...(this.value || {}), workflow: { ...(this.value?.workflow || {}), workflows: [] } })
        },
        handleFilter (value) {
            if (value === '') {
                this.options = [...this.workflows]
                this.search = value
                return
            }

            const needle = value.toLowerCase()
            this.options = this.workflows.filter(({ label }) => label.toLowerCase().indexOf(needle) > -1)
            this.search = value
        },
        handleInput (item, checked) {
            let { workflows = [] } = this.value?.workflow
            if (!workflows) workflows = []

            if (checked) workflows = [...workflows, item]
            else workflows = workflows.filter(w => w.key !== item.key)

            this.$emit('input', { ...(this.value || {}), workflow: { ...(this.value.workflow || {}), workflows } })
        },
    },
}
</script>
<style scoped lang="scss">
$grid-width: 575px;
$results-height: 244px;

.Separator {
    width: 100%;
    max-width: $grid-width;
    margin: 15px auto;
    border-bottom: 1px solid $border-color;
}

.Empty {
    min-height: $results-height;
    overflow: hidden;
}

.Wrapper {
    width: 100%;
    max-width: $grid-width;
    margin: 0 auto;
}

.Tag {
    padding: 7px 10px;
    background: $secondary;
    border-radius: $border-radius;
}

.Workflows {
    position: relative;
    width: 100%;
    max-width: $grid-width;
    height: $results-height;
    margin: 0 auto;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: auto;
    transition: all 330ms ease-in-out;

    .Checkbox {
        box-sizing: border-box;

        &:hover {
            background: $gray-06;
        }
        & + .Checkbox {
            border-top: 1px solid $border-color;
        }
    }
}
</style>
