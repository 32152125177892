export default {
    upload: 'Subir dataset al asistente',
    reuploadWarning: 'Esto resubirá el dataset al asistente y reiniciará la conversación ¿Continuar?',
    restartConversation: 'Reiniciar conversación',
    reuploadDataset: 'Resubir dataset',
    assistantPresentation: 'Hola, soy tu asistente, hazme preguntas relacionadas con el dataset',
    writeMessagePlaceholder: 'Escribe un mensaje',
    send: 'Enviar',
    yes: 'Sí',
    no: 'No',
    thinking: 'Pensando',
    labsDisclaimer: 'Este asistente utiliza tecnología LLM de terceros y las respuestas pueden no ser precisas en algunas situaciones. ' +
    'Bajo cualquier circunstancia, el usuario es responsable de cualquier uso adicional de la información que este asistente proporcione.',
    labs: 'LABS',
    errorSendMessage: 'Error enviando el mensaje, recarga la página o intenta de nuevo más tarde.',
    emptyMessage: '(Respuesta vacía)',
}
