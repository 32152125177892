export default {
    rows: [
        {
            activity: 'START',
            value: 9999,
        },
        {
            activity: 'CONTINUE',
            value: 7893,
        },
        {
            activity: 'STEP33',
            value: 5966,
        },
        {
            activity: 'STEP32',
            value: 4968,
        },
        {
            activity: 'STEP87',
            value: 3243,
        },
        {
            activity: 'CONTINU2',
            value: 2435,
        },
    ],
}
