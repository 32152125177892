import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    // Look for files in the current directory
    '.',
    // Do not look in subdirectories
    true,
    // Only include "W" prefixed .vue files
    /W[\w-]+\.vue$/,
)

const register = context => (fileName) => {
    // Get the component config
    const componentConfig = context(fileName)
    // Get the PascalCase version of the component name
    const componentName = componentConfig.default ? componentConfig.default.name : componentConfig.name
    // Globally register the component
    if (componentName) Vue.component(componentName, componentConfig.default || componentConfig)
}

// For each matching file name...
requireComponent.keys().forEach(register(requireComponent))
