var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.handleClickAway,
          expression: "handleClickAway"
        }
      ],
      staticClass: "Dropdown",
      class: _vm.dropdownClass
    },
    [
      _c(
        "a",
        {
          staticClass: "Btn",
          attrs: { id: "btn", href: "#", "data-cy": "dropdownBtn" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.handleClickBtn.apply(null, arguments)
            }
          }
        },
        [
          _vm._t("default", function() {
            return [_vm._v(" " + _vm._s(_vm.text) + " ")]
          }),
          _vm.icon
            ? _c("WIcon", {
                attrs: {
                  color: _vm.iconColor,
                  icon: _vm.icon,
                  size: _vm.iconSize
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c("Transition", { attrs: { name: "fade", duration: _vm.duration } }, [
        _c(
          "div",
          { style: _vm.coordinatesStyles },
          [
            _vm.openedInternal
              ? _c(
                  "WCard",
                  {
                    staticClass: "Menu",
                    style: {
                      maxHeight: _vm.maxHeight
                        ? _vm.maxHeight + "px"
                        : "initial"
                    }
                  },
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "a",
                      {
                        key: option.key,
                        staticClass: "Link Option",
                        class: [
                          option.style,
                          {
                            separator: option.separator,
                            disabled: option.disabled
                          }
                        ],
                        attrs: {
                          id: option.key,
                          "data-cy": option.key,
                          disabled: option.disabled,
                          href: "#"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleClickOption(option, $event)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex row-left-center" },
                          [
                            option.icon
                              ? _c("WIcon", {
                                  attrs: {
                                    icon: option.icon,
                                    color: option.iconColor,
                                    size: 18
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "WText",
                              {
                                staticClass: "mb-0",
                                attrs: { tag: "span", size: 14, weight: "semi" }
                              },
                              [_vm._v(_vm._s(option.label))]
                            ),
                            option.iconRight
                              ? _c("WIcon", {
                                  attrs: { icon: option.iconRight, size: 14 }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }