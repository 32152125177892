export default {
    sections: {
        personal: 'Personal Settings',
        security: 'Security',
        apis: 'Manage tokens',
        token: 'Available token',
    },

    personal: {
        text: `Edit your personal information and visibility settings through your <strong>{name}</strong> profile.`,
    },

    security: {
        text: `We will send a message with instructions to reset your password to your e-mail address.`,
        btn: 'Reset password',
    },

    apis: {
        text: 'Here you can easily manage your tokens',
        generate: 'Generate token',
        cancel: 'Revoke token',
    },

    token: {
        tooltip: 'Copy to clipboard',
        success: 'Token copied successfully',
        cancelled: 'Token successfully revoked',
        modal: {
            title: 'User information',
            password: 'Password',
            btn: 'Send',
        },
    },

    subscription: {
        text: 'Subscription',
        active: {
            yes: 'Your subscription is currently <strong>ACTIVE</strong>.',
            no: 'Subscription <strong>INACTIVE</strong>, you can activate it by clicking below.',
        },
        goTo: 'Access your subscription portal',
        reactivate: 'Reactivate',
        cancel: 'Cancel',
        modal: {
            title: 'Cancel subscription',
            text: 'The account will be cancelled, please note that any pending event consumption will be billed',
        },
        notifications: {
            reactivate: 'Subscription reactivated successfully',
            cancel: 'Subscription cancelled successfully',
        },
    },

    notifications: {
        personal: {
            text: 'Personal info saved successfully.',
        },
        resetPassword: {
            text: 'We have sent you a message with a new password to your e-mail address.',
        },
    },

    modals: {
        recovery: {
            title: 'Reset Password',
            text: 'Are you sure you want to reset your password?',
        },
    },
}
