import createDatasets from './datasets'
import createOrganizations from './organizations'
import createProjects from './projects'
import createReports from './reports'
import createVisualizations from './visualizations'
import createQueries from './queries'
import createNotifications from './notifications'
import createEventConsumption from './eventConsumption'
import createInvitations from './userManagement'
import createUsers from './users'
import createPayments from './payments'
import createEnviroments from './enviroments'
import createDataNavigator from './dataNavigator'
import createAlerts from './alerts'
import createModels from './models'
import createConformance from './conformance'
import createAssistantChat from './assistantChat'
import createBooster from './booster'

const create = api => ({
    datasets: createDatasets(api),
    organizations: createOrganizations(api),
    projects: createProjects(api),
    reports: createReports(api),
    visualizations: createVisualizations(api),
    queries: createQueries(api),
    notifications: createNotifications(api),
    eventConsumption: createEventConsumption(api),
    userManagement: createInvitations(api),
    users: createUsers(api),
    payments: createPayments(api),
    enviroments: createEnviroments(api),
    dataNavigator: createDataNavigator(api),
    alerts: createAlerts(api),
    models: createModels(api),
    conformance: createConformance(api),
    booster: createBooster(api),
    assistantChat: createAssistantChat(api),
})
const createLLM = api => ({
    assistantChat: createAssistantChat(api),
})

export default {
    create,
    createLLM,
}
