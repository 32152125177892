var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Step" }, [
    _c(
      "div",
      { staticClass: "row justify-center" },
      [
        (_vm.steps || []).length
          ? _c(
              "WCard",
              {
                staticClass: "col-md-8 col-lg-4",
                attrs: { flat: "", bordered: "" }
              },
              [
                _c(
                  "CollapseItem",
                  {
                    attrs: {
                      icon: "database",
                      title: _vm.steps[0].title,
                      opened: _vm.collapses[_vm.steps[0].name]
                    },
                    on: {
                      onClick: function($event) {
                        return _vm.handleClick(_vm.steps[0].name)
                      }
                    }
                  },
                  [
                    _c(
                      "WCard",
                      {
                        staticClass: "ma-1 pa-0-5",
                        attrs: { flat: "", bordered: "" }
                      },
                      [
                        _c("WText", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("wizard.connection.service")) +
                              " "
                          )
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(" " + _vm._s(_vm.value.service.label) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                (_vm.value || {}).connection.host ||
                (_vm.value || {}).connection.connectionChain
                  ? _c(
                      "fragment",
                      [
                        _c(
                          "CollapseItem",
                          {
                            attrs: {
                              icon: "key",
                              title: _vm.steps[1].title,
                              opened: _vm.collapses[_vm.steps[1].name]
                            },
                            on: {
                              onClick: function($event) {
                                return _vm.handleClick(_vm.steps[1].name)
                              }
                            }
                          },
                          [
                            _vm.value.connection
                              ? _c(
                                  "div",
                                  { staticClass: "pa-1" },
                                  [
                                    _c(
                                      "WCard",
                                      {
                                        staticClass: "pa-0-5",
                                        attrs: { flat: "", bordered: "" }
                                      },
                                      [
                                        _vm.value.connection.connectionChain
                                          ? _c(
                                              "fragment",
                                              [
                                                _c("WText", [_vm._v(" JDBC ")]),
                                                _c(
                                                  "WText",
                                                  {
                                                    staticClass: "Connection",
                                                    attrs: {
                                                      size: 12,
                                                      color: "gray02"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.value.connection
                                                            .connectionChain
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "fragment",
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(" Driver ")
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (
                                                                _vm.value
                                                                  .connection
                                                                  .driver || {}
                                                              ).label
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("Divider"),
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(" Host ")
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.value
                                                                .connection.host
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("Divider"),
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "wizard.connection.database"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.value
                                                                .connection
                                                                .database
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("Divider"),
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "wizard.connection.port"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.value
                                                                .connection.port
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("Divider"),
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "wizard.connection.user"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.value
                                                                .connection
                                                                .username
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("Divider"),
                                                _c(
                                                  "div",
                                                  { staticClass: "pa-0-5" },
                                                  [
                                                    _c("WText", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "wizard.connection.password"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c(
                                                      "WText",
                                                      {
                                                        attrs: {
                                                          size: 12,
                                                          color: "gray02"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.normalizePassword
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "CollapseItem",
                  {
                    attrs: {
                      icon: "file-text",
                      title: _vm.steps[2].title,
                      opened: _vm.collapses[_vm.steps[2].name]
                    },
                    on: {
                      onClick: function($event) {
                        return _vm.handleClick(_vm.steps[2].name)
                      }
                    }
                  },
                  [
                    _c(
                      "WCard",
                      {
                        staticClass: "ma-1 pa-0-5",
                        attrs: { flat: "", bordered: "" }
                      },
                      [
                        _c("WText", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("wizard.connection.workflows")) +
                              " "
                          )
                        ]),
                        _c("WText", { attrs: { size: 12, color: "gray02" } }, [
                          _vm._v(" " + _vm._s(_vm.normalizeWorkFlows) + " ")
                        ]),
                        _vm.enabledFilters
                          ? _c(
                              "fragment",
                              [
                                _c("Divider"),
                                _c(
                                  "div",
                                  { staticClass: "pa-0-5" },
                                  [
                                    _c("WText", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "wizard.connection.filterTraces"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "WText",
                                      { attrs: { size: 12, color: "gray02" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "wizard.connection." +
                                                  (_vm.value.workflow.traces
                                                    ? "enable"
                                                    : "disable")
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("Divider"),
                                _c(
                                  "div",
                                  { staticClass: "pa-0-5" },
                                  [
                                    _c("WText", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "wizard.connection.filterEvents"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c(
                                      "WText",
                                      { attrs: { size: 12, color: "gray02" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "wizard.connection." +
                                                  (_vm.value.workflow.events
                                                    ? "enable"
                                                    : "disable")
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }