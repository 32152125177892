var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c("div", { staticClass: "SlaSettings" }, [
        _c(
          "div",
          { staticClass: "SLA" },
          [
            _c(
              "WText",
              {
                staticClass: "mb-1",
                attrs: { weight: "bold", color: "primary" }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("visualization.settings.sla.name")) + " "
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "OptionsGroup row justify-start" },
              [
                _c("QInput", {
                  staticClass: "col",
                  attrs: {
                    standout: "",
                    outlined: "",
                    type: "number",
                    labelColor: "primary",
                    color: "primary",
                    label: _vm.$t("visualization.settings.sla.number"),
                    autogrow: _vm.inputOptions
                  },
                  model: {
                    value: _vm.number,
                    callback: function($$v) {
                      _vm.number = $$v
                    },
                    expression: "number"
                  }
                }),
                _c("QSelect", {
                  staticClass: "col",
                  attrs: {
                    outlined: "",
                    labelColor: "primary",
                    color: "primary",
                    label: _vm.$t("visualization.settings.sla.unit"),
                    options: _vm.slaUnits
                  },
                  model: {
                    value: _vm.unit,
                    callback: function($$v) {
                      _vm.unit = $$v
                    },
                    expression: "unit"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row justify-end mt-1" },
              [
                _c(
                  "WButton",
                  {
                    staticClass: "buttons",
                    attrs: { outline: "", type: "submit", color: "primary" },
                    on: { click: _vm.saveSLA }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("visualization.filters.save")) + " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }