/* eslint-disable */
import list from './list'
import get from './get'
import create from './new'
import modeReportTypes from './modeReportTypes'
import modes from './modes'

export default {
    list,
    get,
    create,
    modes,
    modeReportTypes,
}
