var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      _c(
        "div",
        { staticClass: "Alerts" },
        [
          _c(
            "div",
            { staticClass: "flex flex-center q-ma-sm" },
            [
              _c("QBtnToggle", {
                staticClass: "q-mt-sm border-toggle",
                attrs: {
                  toggleColor: "primary",
                  noCaps: "",
                  unelevated: "",
                  options: _vm.tabs
                },
                model: {
                  value: _vm.tab,
                  callback: function($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab"
                }
              })
            ],
            1
          ),
          _c(
            "QTabPanels",
            {
              attrs: {
                animated: "",
                transitionNext: "fade",
                transitionPrev: "fade"
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "QTabPanel",
                { attrs: { name: "ACTIVE" } },
                [
                  _c("AlertList", {
                    staticClass: "q-mt-md",
                    attrs: { processId: _vm.processId, showDismissed: false }
                  })
                ],
                1
              ),
              _c(
                "QTabPanel",
                { attrs: { name: "HISTORY" } },
                [
                  _c("AlertList", {
                    staticClass: "q-mt-md",
                    attrs: { processId: _vm.processId, showDismissed: true }
                  })
                ],
                1
              ),
              _c(
                "QTabPanel",
                { attrs: { name: "DEFINITIONS" } },
                [
                  _c("AlertDefinition", { attrs: { processId: _vm.processId } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }