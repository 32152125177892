var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AlertList" },
    [
      _c("div", { staticClass: "row q-mt-md q-mb-sm q-justify-end" }, [
        _c("div", { staticClass: "textColumn" }, [
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("visualization.alerts.filterBy")) + " ")
          ])
        ]),
        _c(
          "div",
          [
            _c("q-select", {
              staticClass: "custom-select",
              attrs: { options: _vm.filterOptions, outlined: "", dense: "" },
              on: { input: _vm.fetchData },
              model: {
                value: _vm.filterByType,
                callback: function($$v) {
                  _vm.filterByType = $$v
                },
                expression: "filterByType"
              }
            })
          ],
          1
        )
      ]),
      _c("Table", {
        ref: "table",
        attrs: {
          data: _vm.normalizeRows,
          total: _vm.total,
          hideHeader: "",
          rowKey: "uuid"
        },
        on: { "table-request": _vm.fetchData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("AlertElement", {
                  attrs: { row: row },
                  on: { dismiss: _vm.dismissAlert }
                })
              ]
            }
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c("EmptyList", {
                  staticClass: "mt-2 mb-2",
                  attrs: { title: _vm.emptyMessage }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }