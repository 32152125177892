var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "WizardImport" },
    [
      _c(
        "QStepper",
        {
          ref: "stepper",
          attrs: {
            value: _vm.step,
            alternativeLabels: "",
            animated: "",
            flat: ""
          }
        },
        _vm._l(_vm.steps, function(item, index) {
          return _c(
            "QStep",
            {
              key: "WizardImportStep-" + index,
              attrs: {
                name: item.name,
                title: item.title,
                icon: item.icon,
                done: _vm.currentIndex > index,
                headerNav: _vm.currentIndex > index
              }
            },
            [
              _c(item.component, {
                ref: item.name,
                refInFor: true,
                tag: "Component",
                attrs: {
                  datasetId: _vm.datasetId,
                  steps: _vm.steps,
                  importer: _vm.importer
                },
                model: {
                  value: _vm.data,
                  callback: function($$v) {
                    _vm.data = $$v
                  },
                  expression: "data"
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }