var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Collapse", {
    staticClass: "CollapseItem",
    attrs: { opened: _vm.opened },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "button",
                {
                  staticClass: "Header flex row-stretch-center",
                  attrs: { disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.$emit("onClick")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "Title flex row-left-center" },
                    [
                      _vm.icon
                        ? _c("WIcon", {
                            staticClass: "mr-1",
                            attrs: {
                              icon: _vm.icon,
                              size: 24,
                              color: "secondary"
                            }
                          })
                        : _vm._e(),
                      _vm.title
                        ? _c(
                            "WText",
                            { attrs: { tag: "span", color: _vm.color } },
                            [_vm._v(" " + _vm._s(_vm.title) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("WIcon", {
                    staticClass: "Arrow",
                    attrs: {
                      icon: "chevron-" + (_vm.opened ? "down" : "up"),
                      size: 24,
                      color: "grayMedium"
                    }
                  })
                ],
                1
              ),
              _vm._t("header")
            ]
          },
          proxy: true
        },
        {
          key: "default",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }