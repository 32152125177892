var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Step" }, [
    _c(
      "div",
      { staticClass: "row justify-center" },
      [
        (_vm.steps || []).length
          ? _c(
              "WCard",
              {
                staticClass: "col-8 col-lg-4",
                attrs: { flat: "", bordered: "" }
              },
              [
                _c(
                  "CollapseItem",
                  {
                    attrs: {
                      icon: "key",
                      title: "Data set",
                      opened: _vm.collapses[_vm.steps[0].name]
                    },
                    on: {
                      onClick: function($event) {
                        return _vm.handleClick(_vm.steps[0].name)
                      }
                    }
                  },
                  [
                    _vm.value.config
                      ? _c(
                          "div",
                          { staticClass: "pa-1" },
                          [
                            _c(
                              "WCard",
                              { attrs: { flat: "", bordered: "" } },
                              [
                                _vm.config.traceId
                                  ? _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("wizard.trace")) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.config.traceId) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("Divider"),
                                _vm.config.activity
                                  ? _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("wizard.activity")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.config.activity) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("Divider"),
                                _vm.config.start
                                  ? _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("wizard.startTime")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.config.start) +
                                                " (" +
                                                _vm._s(_vm.config.startFormat) +
                                                ") "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.endFormat
                                  ? _c(
                                      "fragment",
                                      [
                                        _c("Divider"),
                                        _c(
                                          "div",
                                          { staticClass: "pa-0-5" },
                                          [
                                            _c("WText", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("wizard.endTime")
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c(
                                              "WText",
                                              {
                                                attrs: {
                                                  size: 12,
                                                  color: "gray02"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.end || "") +
                                                    " " +
                                                    _vm._s(_vm.endFormat) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _vm.steps[1]
                  ? _c(
                      "CollapseItem",
                      {
                        attrs: {
                          icon: "file-text",
                          title: _vm.steps[1].title,
                          opened: _vm.collapses[_vm.steps[1].name]
                        },
                        on: {
                          onClick: function($event) {
                            return _vm.handleClick(_vm.steps[1].name)
                          }
                        }
                      },
                      [
                        _c(
                          "WCard",
                          {
                            staticClass: "ma-1",
                            attrs: { flat: "", bordered: "" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pa-0-5" },
                              [
                                _c("WText", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("wizard.query.mode")) +
                                      " "
                                  )
                                ]),
                                _c(
                                  "WText",
                                  { attrs: { size: 12, color: "gray02" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.value.definition.mode) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("Divider"),
                            _c(
                              "div",
                              { staticClass: "pa-0-5" },
                              [
                                _c("WText", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("wizard.query.query")) +
                                      " "
                                  )
                                ]),
                                _c(
                                  "WText",
                                  { attrs: { size: 12, color: "gray02" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.value.definition.query) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm.value.definition.restrictions
                              ? _c(
                                  "fragment",
                                  [
                                    _c("Divider"),
                                    _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "wizard.query.restrictions"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.value.definition
                                                    .restrictions
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.steps[2]
                  ? _c(
                      "CollapseItem",
                      {
                        attrs: {
                          icon: "check-circle",
                          title: _vm.steps[2].title,
                          opened: _vm.collapses[_vm.steps[2].name]
                        },
                        on: {
                          onClick: function($event) {
                            return _vm.handleClick(_vm.steps[2].name)
                          }
                        }
                      },
                      [
                        _vm.value.report
                          ? _c(
                              "div",
                              { staticClass: "pa-1" },
                              [
                                _c(
                                  "WCard",
                                  { attrs: { flat: "", bordered: "" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("wizard.report.name")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.value.report.name) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("Divider"),
                                    _c(
                                      "div",
                                      { staticClass: "pa-0-5" },
                                      [
                                        _c("WText", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("general.description")
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c(
                                          "WText",
                                          {
                                            attrs: { size: 12, color: "gray02" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (_vm.value.report || {})
                                                    .description || ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.value.report.type
                                      ? _c(
                                          "fragment",
                                          [
                                            _c("Divider"),
                                            _c(
                                              "div",
                                              { staticClass: "pa-0-5" },
                                              [
                                                _c("WText", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "wizard.report.type"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c(
                                                  "WText",
                                                  {
                                                    attrs: {
                                                      size: 12,
                                                      color: "gray02"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.value.report.type
                                                            .label
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }