var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ContextualInfo" },
    [
      _c(
        "QExpansionItem",
        {
          attrs: {
            defaultOpened: "",
            dark: "",
            dense: "",
            denseToggle: "",
            icon: "info",
            label: _vm.$t("visualization.filters.modelMetrics")
          }
        },
        [
          _c("div", { staticClass: "row q-col-gutter-sm" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("span", { staticClass: "ContextualElementName" }, [
                  _vm._v(
                    _vm._s(_vm.$t("visualization.filters.contextualInfo.cases"))
                  )
                ]),
                _c(
                  "QCard",
                  { staticClass: "bg-grey-2", attrs: { flat: "" } },
                  [
                    _c(
                      "QLinearProgress",
                      {
                        attrs: {
                          size: "40px",
                          value: parseFloat(
                            _vm.dictFormattedData.cases[
                              "displayedDataPercentage"
                            ]
                          ),
                          color: "secondary",
                          trackColor: "transparent"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "absolute-full flex flex-center" },
                          [
                            _c(
                              "QBadge",
                              {
                                staticClass: "flex flex-center",
                                attrs: {
                                  color: "white",
                                  textColor: "secondary"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "q-px-sm text-center text-weight-bold text-caption text-black"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dictFormattedData.cases[
                                            "displayedData"
                                          ]
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.dictFormattedData.cases[
                                          "displayedDataPercentage"
                                        ] * 100
                                      ).toFixed(0)
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("span", { staticClass: "ContextualElementName" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("visualization.filters.contextualInfo.variants")
                    )
                  )
                ]),
                _c(
                  "QCard",
                  { staticClass: "bg-grey-2", attrs: { flat: "" } },
                  [
                    _c(
                      "QLinearProgress",
                      {
                        attrs: {
                          size: "40px",
                          value: parseFloat(
                            _vm.dictFormattedData.variants[
                              "displayedDataPercentage"
                            ]
                          ),
                          color: "secondary",
                          trackColor: "transparent"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "absolute-full flex flex-center" },
                          [
                            _c(
                              "QBadge",
                              {
                                staticClass: "flex flex-center",
                                attrs: {
                                  color: "white",
                                  textColor: "secondary"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "q-px-sm text-center text-weight-bold text-caption text-black"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dictFormattedData.variants[
                                            "displayedData"
                                          ]
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        _vm.dictFormattedData.variants[
                                          "displayedDataPercentage"
                                        ] * 100
                                      ).toFixed(0)
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-sm-12" }, [
            _c("span", { staticClass: "ContextualElementName" }, [
              _vm._v(
                _vm._s(_vm.$t("visualization.filters.contextualInfo.duration"))
              )
            ]),
            _c("div", { staticClass: "row q-col-gutter-sm items-center" }, [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c(
                    "QCard",
                    { staticClass: "bg-grey-2", attrs: { flat: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "q-pa-sm text-center text-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.dictFormattedData["" + _vm.durMode.value][
                                  "displayedData"
                                ]
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-6 text-center" },
                [
                  _c(
                    "QFab",
                    {
                      attrs: {
                        label: _vm.durMode.value,
                        square: "",
                        flat: "",
                        labelPosition: "left",
                        color: "grey-2",
                        textColor: "white",
                        icon: "keyboard_arrow_right",
                        direction: "right",
                        padding: "sm"
                      },
                      model: {
                        value: _vm.displayDurationOptions,
                        callback: function($$v) {
                          _vm.displayDurationOptions = $$v
                        },
                        expression: "displayDurationOptions"
                      }
                    },
                    _vm._l(_vm.durOptions, function(option) {
                      return _c("QFabAction", {
                        key: option.value,
                        attrs: {
                          textColor: "primary",
                          color: "grey-2",
                          label: option.label,
                          padding: "xs"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setDurationOption(option)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "QExpansionItem",
            {
              attrs: {
                defaultOpened: "",
                dark: "",
                dense: "",
                denseToggle: "",
                headerStyle: "display: none;",
                label: _vm.$t("visualization.filters.modelMetrics")
              },
              model: {
                value: _vm.advancedOptionsToggle,
                callback: function($$v) {
                  _vm.advancedOptionsToggle = $$v
                },
                expression: "advancedOptionsToggle"
              }
            },
            [
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "row q-col-gutter-sm" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("span", { staticClass: "ContextualElementName" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "visualization.filters.contextualInfo.minStartDateTime"
                            )
                          )
                        )
                      ]),
                      _c(
                        "QCard",
                        { staticClass: "bg-grey-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-pa-sm text-center text-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dictFormattedData.minStartDateTime
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("span", { staticClass: "ContextualElementName" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "visualization.filters.contextualInfo.maxEndDateTime"
                            )
                          )
                        )
                      ]),
                      _c(
                        "QCard",
                        { staticClass: "bg-grey-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-pa-sm text-center text-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.dictFormattedData.maxEndDateTime) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "col-sm-12" }, [
                _c("div", { staticClass: "row q-col-gutter-sm" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("span", { staticClass: "ContextualElementName" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "visualization.filters.contextualInfo.estimatedCost"
                            )
                          )
                        )
                      ]),
                      _c(
                        "QCard",
                        { staticClass: "bg-grey-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-pa-sm text-center text-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.costs[_vm.durMode.value].single) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("span", { staticClass: "ContextualElementName" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "visualization.filters.contextualInfo.totalEstimatedCost"
                            )
                          )
                        )
                      ]),
                      _c(
                        "QCard",
                        { staticClass: "bg-grey-2", attrs: { flat: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "q-pa-sm text-center text-weight-bold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.costs[_vm.durMode.value].total) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              {
                staticClass: "row cursor-pointer",
                on: {
                  click: function($event) {
                    _vm.advancedOptionsToggle = !_vm.advancedOptionsToggle
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "col-sm-12 q-pt-sm text-center text-white" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.advancedOptionsToggle
                            ? _vm.$t(
                                "visualization.filters.contextualInfo.expandLess"
                              )
                            : _vm.$t(
                                "visualization.filters.contextualInfo.expandMore"
                              )
                        ) +
                        " "
                    ),
                    _c("QIcon", {
                      attrs: {
                        name: _vm.advancedOptionsToggle
                          ? "expand_less"
                          : "expand_more"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }