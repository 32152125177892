var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Organizations" },
    [
      _c(
        "div",
        { staticClass: "Actions flex row-stretch-center mb-1" },
        [
          _c(
            "WButton",
            {
              attrs: { iconLeft: "plus-circle" },
              on: { click: _vm.handleNewItem }
            },
            [_vm._v(" " + _vm._s(_vm.$t("organizations.new")) + " ")]
          ),
          _c("WButtonGroup", {
            attrs: { value: _vm.groupOwnerType, options: _vm.groupOwnerTypes },
            on: { input: _vm.handleChageGroupOwnerType }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Container" },
        [
          _c("OrganizationsLists", {
            ref: "table",
            attrs: {
              rows: _vm.organizations,
              total: _vm.total,
              search: _vm.header.search,
              loading: _vm.isFetching
            },
            on: {
              onNewItem: _vm.handleNewItem,
              onDelete: _vm.handleDeleteItem,
              onUpdate: _vm.handleUpdateItem,
              onClick: _vm.handleClickItem,
              onFetchData: _vm.fetchData
            }
          })
        ],
        1
      ),
      _c("ModalRequest", {
        ref: "delPrDialog",
        attrs: {
          title: _vm.$t("organizations.delete"),
          text: _vm.$t("organizations.modals.delete.text", {
            name: (_vm.selected || {}).name
          }),
          loading: _vm.isLoading
        },
        on: { onAccept: _vm.deleteOrganization }
      }),
      _c("ModalNameDesc", {
        ref: "editPrDialog",
        attrs: {
          withoutDesc: "",
          title: _vm.$t("organizations.edit"),
          action: _vm.$t("actions.save"),
          loading: _vm.isLoading
        },
        on: { onSubmit: _vm.updateOrganization }
      }),
      _c("ModalNameDesc", {
        ref: "newPrDialog",
        attrs: {
          withoutDesc: "",
          title: _vm.$t("organizations.new"),
          action: _vm.$t("actions.create"),
          loading: _vm.isLoading
        },
        on: { onSubmit: _vm.createOrganization }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }