<template>
    <div class="OrganizationsLists">
        <Table
            ref="table"
            :columns="cols"
            :data="normalizeRows"
            :total="total"
            :loading="loading"
            :hideHeader="hideHeader"
            rowKey="uuid"
            class="Table"
            @table-request="handleFetchData"
        >
            <template v-slot:content="{ row }">
                <QTr
                    :class="{'cursor-pointer': !row.deleteDate }"
                >
                    <QTd
                        key="name"
                        class="td-name"
                        @click.native="handleGoToOrganization(row)"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.name }}
                        </WText>
                    </QTd>
                    <QTd
                        key="createDate"
                        class="td-deleted"
                        @click.native="handleGoToOrganization(row)"
                    >
                        <span>{{ App.dateLocationFormat(row.createDate, 'long') }}</span>
                    </QTd>
                    <QTd
                        key="deleteDate"
                        class="td-deleted"
                        @click.native="handleGoToOrganization(row)"
                    >
                        <span>{{ !!row.deleteDate ? App.dateLocationFormat(row.deleteDate, 'long') : '-' }}</span>
                    </QTd>
                    <QTd
                        key="actions"
                        class="Actions align-right"
                    >
                        <WIcon
                            icon="more-vertical"
                            :size="24"
                            color="primary"
                            clickable
                            @click="setSelected(row, $event)"
                        />
                    </QTd>
                </QTr>
            </template>

            <template #empty>
                <EmptyList
                    v-bind="emptyMessage"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            iconLeft="plus-circle"
                            class="mt-1"
                            @click="$emit('onNewItem')"
                        >
                            {{ $t('organizations.new') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>
        <Dropdown
            v-if="selected"
            :opened="!!selected"
            :options="selected.options"
            :coords="selected.coords"
            @onClose="cleanSelected"
            @onClick="handleClickOption"
        />
    </div>
</template>

<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { Table, EmptyList, Dropdown } from '@/components'
import { filtersMixin } from '@/mixins'

const ACTIONS = {
    DELETE: 'DELETE',
    EDIT: 'EDIT',
}

export default {
    name: 'OrganizationsLists',
    components: {
        Table,
        EmptyList,
        Dropdown,
    },
    inject: ['App'],
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
    },
    data () {
        return {
            selected: undefined,
        }
    },
    computed: {
        cols () {
            return [
                ...this.columns,
                {
                    name: 'name',
                    label: this.$t('general.organization'),
                    align: 'left',
                },
                {
                    name: 'createDate',
                    label: this.$t('general.createdAt'),
                    align: 'left',
                },
                {
                    name: 'deleteDate',
                    label: this.$t('general.deleteDate'),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: '',
                },
            ]
        },
        hideHeader () {
            return !(this.rows || []).length
        },
        emptyMessage () {
            return this.$t(`organizations.notifications.${this.search ? 'noResult' : 'empty'}`)
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(),
            }))
        },
    },
    methods: {
        generateOptionsRow () {
            return [
                {
                    key: ACTIONS.EDIT, icon: 'edit-2', iconColor: 'primary', label: this.$t('actions.edit'), event: 'onUpdate',
                },
                {
                    key: ACTIONS.DELETE, icon: 'trash', iconColor: 'primary', label: this.$t('actions.delete'), event: 'onDelete',
                },
            ]
        },
        setSelected (row, event) {
            const { clientX: x, clientY: y } = event
            setTimeout(() => (this.selected = { coords: { x, y }, ...row }), 100)
        },
        cleanSelected () {
            this.selected = undefined
        },
        handleClickOption ({ event }) {
            if (event) this.$emit(event, this.selected)
            this.cleanSelected()
        },
        handleGoToOrganization (org) {
            if (!org.deleteDate) this.$emit('onClick', org)
        },
        handleFetchData (props) {
            this.$emit('onFetchData', props)
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
    },
}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 152px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .Date {
        padding-top: 5px;
    }
}
</style>
