<template>
    <Table
        ref="table"
        :columns="columns"
        :data="rows"
        :total="total"
        :loading="isLoading"
        :hideHeader="!(rows || []).length"
        rowKey="id"
        class="Table"
        @table-request="fetchData"
    >
        <template #content="{ row }">
            <QTr :noHover="true">
                <QTd
                    key="organization"
                    class="td-organization"
                >
                    <WText
                        :size="14"
                        weight="semi"
                    >
                        {{ row.organization.name }}
                    </WText>
                </QTd>
                <QTd
                    key="project"
                    class="td-project"
                >
                    <WText
                        :size="14"
                        weight="semi"
                    >
                        {{ row.project.name }}
                    </WText>
                    <!-- <WText
                        :size="12"
                        color="gray02"
                    >
                        Created on {{ row.project.createDate|date }}
                    </WText> -->
                </QTd>
                <QTd
                    key="dataset"
                    class="td-dataset"
                >
                    <WText
                        ellipsis
                        class="ellipsis"
                    >
                        {{ row.dataset.fileName }}
                    </WText>
                </QTd>
                <QTd
                    key="events"
                    class="td-project"
                >
                    <WText
                        :size="14"
                    >
                        {{ row.measurement }}
                    </WText>
                </QTd>
                <QTd
                    key="date"
                    class="td-date"
                >
                    <WText
                        :size="14"
                        color="gray02"
                    >
                        {{ row.date|date('DD/MM/YYYY') }}
                    </WText>
                </QTd>
            </QTr>
        </template>

        <template #empty>
            <EmptyList
                :title="$t('events.emptyListTitle')"
                :text="$t('events.emptyListText')"
                class="mt-2 mb-2"
            />
        </template>
    </Table>
</template>

<script>
import { Api, apiRequest, notifyError } from '@/api'
import { Table, EmptyList } from '@/components'
import { filtersMixin } from '@/mixins'

export default {
    name: 'EventsHistoric',
    components: {
        Table,
        EmptyList,
    },
    mixins: [filtersMixin],
    data () {
        const columns = [
            {
                name: 'organization',
                label: this.$t('general.organization'),
                align: 'left',
            },
            {
                name: 'project',
                label: this.$t('general.project'),
                align: 'left',
            },
            {
                name: 'dataset',
                label: this.$t('general.dataset'),
                align: 'left',
            },
            {
                name: 'events',
                label: this.$t('events.lines'),
                align: 'left',
            },
            {
                name: 'date',
                label: this.$t('general.date'),
                align: 'left',
            },
        ]

        return {
            columns,
            rows: undefined,
            total: undefined,
            isLoading: false,
        }
    },
    mounted () {
        const { table } = this.$refs
        if (table) this.fetchData(table.getPagination())
    },
    methods: {
        async fetchData ({ page, rowsPerPage } = {}) {
            const params = {
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
            }

            if (!(this.rows || []).length) this.isLoading = true
            apiRequest(Api().eventConsumption.detail(params))
                .then(({ data, total } = {}) => {
                    this.rows = data
                    this.total = total
                    this.$emit('onReady')
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 150px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
