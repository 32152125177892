<template>
    <div class="Examples pt-1 pb-1">
        <div
            v-for="example in examples"
            :key="example"
            class="flex"
        >
            <Badge
                :label="example"
                color="secondary"
            >
                <WIcon
                    icon="copy"
                    :size="24"
                    color="white"
                    clickable
                    @click="handleCopyToClipboard(example)"
                />
            </Badge>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { Badge } from '@/components'

export default {
    name: 'InsightExamples',
    components: {
        Badge,
    },
    props: {
        examples: VueTypes.arrayOf(VueTypes.string),
    },
}
</script>
<style lang="scss" scoped>
.Examples {
    display: flex;
    flex-wrap: wrap;
    .Badge {
        width: 100%;
        margin: 5px;
        &::v-deep > div {
            justify-content: space-between;
            .WIcon {
                padding-left: 15px;
            }
        }
    }
}
</style>
