<template>
    <apexchart
        ref="eventsChart"
        v-bind="chartConfig"
        class="Chart"
    />
</template>

<script>
import VueTypes from 'vue-types'
import Colors from '@/styles/colors'

export default {
    name: 'EventsChart',
    props: {
        categories: VueTypes.any,
        values: VueTypes.any,
        width: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def('100%'),
        height: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).def('auto'),
    },
    inject: ['App'],
    computed: {
        events () {
            return {
                events: {
                    mounted: (chart, config) => {
                        this.$emit('onMounted', { chart, config })
                    },
                    updated: (chart, config) => {
                        this.$emit('onUpdated', { chart, config })
                    },
                    dataPointSelection: (event, chart, item) => {
                        this.$emit('onSelect', { item, chart, event })
                    },
                },
            }
        },
        chartConfig () {
            return {
                width: this.width,
                height: this.height,
                options: {
                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: true,
                            tools: {
                                selection: true,
                                reset: true,
                            },
                        },
                        locales: [{
                            name: 'en',
                            options: {
                                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                                shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                                shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                                toolbar: {
                                    exportToSVG: 'Download SVG',
                                    exportToPNG: 'Download PNG',
                                    exportToCSV: 'Download CSV',
                                },
                            },
                        }, {
                            name: 'es',
                            options: {
                                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                                days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                                shortDays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
                                toolbar: {
                                    exportToSVG: 'Descargar SVG',
                                    exportToPNG: 'Descargar PNG',
                                    exportToCSV: 'Descargar CSV',
                                },
                            },
                        }],
                        defaultLocale: this.App.user.locale,
                        ...this.events,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: 15,
                            startingShape: 'rounded',
                            endingShape: 'rounded',
                            barHeight: '100%',
                            distributed: true,
                            dataLabels: {
                                position: 'top',
                                hideOverflowingLabels: true,
                            },
                            colors: {
                                backgroundBarColors: ['#eee'],
                                backgroundBarOpacity: 1,
                                backgroundBarRadius: 5,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent'],
                    },
                    fill: {
                        colors: [Colors.primary],
                    },
                    grid: {
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            show: true,
                        },
                    },
                    xaxis: {
                        ...(this.categories ? { categories: this.categories } : {}),
                        labels: {
                            show: true,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    tooltip: {
                        fillSeriesColor: false,
                        marker: {
                            show: false,
                        },
                        x: {
                            show: true,
                        },
                    },
                    legend: {
                        show: false,
                        labels: {
                            colors: [Colors.primary],
                            useSeriesColors: false,
                        },
                        onItemClick: {
                            toggleDataSeries: true,
                        },
                        onItemHover: {
                            highlightDataSeries: true,
                        },
                    },
                },
                series: [{
                    name: this.$t('general.amount'),
                    data: this.values,
                }],
            }
        },
    },
}
</script>
<style scoped lang="scss">
.Chart {
   &::v-deep path:hover {
       cursor: pointer
   }
   &::v-deep .apexcharts-toolbar {
        top: -45px !important;
   }
}
</style>
