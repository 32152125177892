var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.attribsData && !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.numberAttribs, function(idx) {
          return _c(
            "div",
            { key: idx, staticClass: "col q-pa-sm" },
            [
              _c("CategoricalCause", {
                attrs: {
                  usableAttribs: _vm.usableAttribs,
                  defaultValue: _vm.attribsUsed[idx - 1],
                  totalEvents: _vm.attribsData.totalUnfullfilling,
                  attrData: _vm.getCurrAttribData(_vm.attribsUsed[idx - 1]),
                  causeIndex: idx - 1
                },
                on: { changed: _vm.refreshAttributeData }
              })
            ],
            1
          )
        }),
        0
      )
    : !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "flex justify-center items-cemter q-ma-md" },
        [
          _c("WText", { attrs: { size: 18 } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("visualization.conformance.rootCause.noAttributes")
                ) +
                " "
            )
          ])
        ],
        1
      )
    : _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("QLinearProgress", {
              attrs: { indeterminate: "", color: "secondary" }
            })
          ],
          1
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }