<template>
    <div
        class="ContextualInfo"
    >
        <QExpansionItem
            denseToggle
            defaultOpened
            expandSeparator
            dense
            v-bind="{ icon: title.icon }"
            :label="title.label"
        >
            <div v-if="title.key === 'FD'">
                <FrequencyDurationPanel
                    :currentTitle="title"
                    :processId="processId"
                    :filters="filters"
                    :chart="chart"
                    @title="handleClickOption"
                />
            </div>
            <div v-if="title.key === 'AI'">
                <AttributeInformationPanel
                    :currentTitle="title"
                    :attributes="attributes"
                    :processId="processId"
                    :filters="filters"
                    :chart="chart"
                    @title="handleClickOption"
                />
            </div>
        </QExpansionItem>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import { filtersMixin } from '@/mixins'
import FrequencyDurationPanel from './FrequencyDurationPanel.vue'
import AttributeInformationPanel from './AttributeInformationPanel.vue'

export default {
    name: 'CustomPlotPanels',
    emits: [],
    components: { FrequencyDurationPanel, AttributeInformationPanel },
    mixins: [filtersMixin],
    props: {
        attributes: VueTypes.array,
        processId: VueTypes.string,
        filters: VueTypes.array,
        title: VueTypes.object,
        chart: VueTypes.object,
    },
    data () {
        return {
            currentTitle: this.title,
        }
    },
    computed: {
    },
    methods: {
        handleClickOption (sel) {
            this.currentTitle = sel
            this.$emit('titleSel', this.currentTitle)
        },
    },
}
</script>
<style lang="scss" scoped>
.ContextualInfo {
    position: relative;
    background-color: #FFFFFF;
    padding:5px;
    color: $primary;
    z-index: 99;
    width: 300px;
    max-width: 300px;
    border: 1px solid $primary;
    border-radius: 5px;
    font-weight: 600;
  }
</style>
