var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Container" }, [
    _c(
      "div",
      { staticClass: "Card" },
      [
        _c(
          "QCard",
          { staticClass: "MeetingBox Section", attrs: { flat: "" } },
          [
            _c(
              "QCardSection",
              { staticClass: "text-h6 text-weight-bold text-center" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.numFormatter(_vm.meetingSLA)) +
                    " " +
                    _vm._s(
                      _vm.$t("booster.prediction.predictionHighlights.cases")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "MeetingPercent" }, [
                  _vm._v(_vm._s(_vm.meetingSLAPercent) + "%")
                ])
              ]
            )
          ],
          1
        ),
        _c("span", { staticClass: "Section TextSection text-h6" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.ongoingCases")
              ) +
              " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.meetingSLA")
              ) +
              " "
          ),
          _c("span", { staticClass: "MeetingColor" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "booster.prediction.predictionHighlights.meetingSLAColor"
                  )
                ) +
                " "
            )
          ])
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Card" },
      [
        _c(
          "QCard",
          { staticClass: "UnderRiskBox Section", attrs: { flat: "" } },
          [
            _c(
              "QCardSection",
              { staticClass: "text-h6 text-weight-bold text-center" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.numFormatter(_vm.underRisk)) +
                    " " +
                    _vm._s(
                      _vm.$t("booster.prediction.predictionHighlights.cases")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "UnderRiskPercent" }, [
                  _vm._v(_vm._s(_vm.underRiskPercent) + "%")
                ])
              ]
            )
          ],
          1
        ),
        _c("span", { staticClass: "Section TextSection text-h6" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.ongoingCases")
              ) +
              " "
          ),
          _c("span", { staticClass: "UnderRiskColor" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "booster.prediction.predictionHighlights.missingSLAColor"
                  )
                ) +
                " "
            )
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.missingSLA")
              ) +
              " "
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "Card" },
      [
        _c(
          "QCard",
          { staticClass: "ExceedingBox Section", attrs: { flat: "" } },
          [
            _c(
              "QCardSection",
              { staticClass: "text-h6 text-weight-bold text-center" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.numFormatter(_vm.exceeding)) +
                    " " +
                    _vm._s(
                      _vm.$t("booster.prediction.predictionHighlights.cases")
                    ) +
                    " "
                ),
                _c("span", { staticClass: "ExceedingPercent" }, [
                  _vm._v(_vm._s(_vm.exceedingPercent) + "%")
                ])
              ]
            )
          ],
          1
        ),
        _c("span", { staticClass: "Section TextSection text-h6" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.ongoingCases")
              ) +
              " " +
              _vm._s(
                _vm.$t("booster.prediction.predictionHighlights.exceeding")
              ) +
              " "
          ),
          _c("span", { staticClass: "ExceedingColor" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "booster.prediction.predictionHighlights.exceedingColor"
                  )
                ) +
                " "
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }