import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import 'moment/locale/es'

import { LangSession } from '@/services/session.service'
import Config from '@/config'
import messages from './locales'

Vue.use(VueI18n)

const sessionLocale = LangSession.get()
const defaultLocale = sessionLocale || Config.i18n.locale
moment.locale(Config.i18n.moment[defaultLocale])

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: Config.i18n.fallbackLocale,
    messages,
    dateTimeFormats: {
        en: {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
        'de-DE': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
        'es-ES': {
            short: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
    },
    numberFormats: {
        en: {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
        },
        'es-ES': {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
        },
        'de-DE': {
            decimal: {
                style: 'decimal', maximumFractionDigits: 2,
            },
        },
    },
})

export const changeLocale = (locale) => {
    if (Config.i18n.locales.includes(locale)) {
        i18n.locale = locale
        moment.locale(Config.i18n.moment[locale])
        LangSession.set(locale)
    } else {
        // eslint-disable-next-line no-console
        console.warn(`'${locale}' locale is not available.`)
    }
}

export default i18n
