<template>
    <div class="NotificationList">
        <div class="flex row-stretch-center mb-1">
            <label class="flex row-left-center">
                <WSwitch
                    name="visibility"
                    :value="activateVisibility"
                    @input="handleVisibility"
                />
                <WText
                    :size="12"
                    class="ml-1"
                >
                    {{ $t('notifications.showActives') }}
                </WText>
            </label>
            <div class="flex">
                <QInput
                    v-model="filters.startDate"
                    outlined
                    type="date"
                    :label="$t('general.startAt')"
                    stackLabel
                    maxlength="500"
                    @input="handleSelectedDate"
                />
                <QInput
                    v-model="filters.endDate"
                    outlined
                    class="pl-1"
                    stackLabel
                    type="date"
                    :label="$t('general.endAt')"
                    @input="handleSelectedDate"
                />
            </div>
        </div>
        <Table
            ref="table"
            :columns="cols"
            :data="normalizeRows"
            :total="total"
            :loading="loading"
            :hideHeader="hideHeader"
            rowKey="uuid"
            class="Table"
            @table-request="handleFetchData"
        >
            <template v-slot:content="{ row }">
                <QTr>
                    <QTd
                        key="type"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.type }}
                        </WText>
                    </QTd>
                    <QTd
                        key="title"
                        class="td-title"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.title }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            Created on {{ App.dateLocationFormat(row.createdAt, 'long') }}
                        </WText>
                    </QTd>
                    <QTd
                        key="description"
                        class="td-desc"
                    >
                        <span class="td-desc ellipsis">{{ row.organization || '-' }}</span>
                        <QTooltip
                            v-if="row.organization"
                            contentClass="bg-secondary"
                            maxWidth="300px"
                        >
                            {{ row.organization }}
                        </QTooltip>
                    </QTd>
                    <QTd
                        key="seenAt"
                        class="td-seenAt"
                    >
                        <span>{{ !!row.seenAt ? App.dateLocationFormat(row.seenAt, 'long') : '-' }}</span>
                    </QTd>
                    <QTd
                        key="content"
                        class="td-content"
                    >
                        <WIcon
                            icon="file-text"
                            :size="24"
                            color="secondary"
                            clickable
                            @click="handleClick(row)"
                        />
                    </QTd>
                    <QTd
                        key="common"
                        class="td-common"
                    >
                        <fragment v-if="row.options">
                            <WButton
                                color="link"
                                :disabled="!!row.seenAt"
                                @click="handleEmmit(getOption(row), row)"
                            >
                                <WIcon
                                    :icon="getOption(row).icon"
                                    :size="24"
                                    color="secondary"
                                />
                                <QTooltip
                                    v-if="!!getOption(row).label"
                                    contentClass="bg-secondary"
                                >
                                    {{ getOption(row).label }}
                                </QTooltip>
                            </WButton>
                        </fragment>
                    </QTd>
                </QTr>
            </template>
            <template #empty>
                <EmptyList
                    v-bind="$t('notifications.notifications.empty')"
                    class="mt-2 mb-2"
                />
            </template>
        </Table>
    </div>
</template>

<script>
/* eslint-disable func-names */
import VueTypes from 'vue-types'
import { Table, EmptyList } from '@/components'
import { filtersMixin } from '@/mixins'

const ACTIONS = {
    VISUALIZATION: 'VISUALIZATION',
}

export default {
    name: 'NotificationList',
    components: {
        Table,
        EmptyList,
    },
    inject: ['App'],
    mixins: [filtersMixin],
    props: {
        columns: VueTypes.array,
        rows: VueTypes.array,
        total: VueTypes.number,
        search: VueTypes.string,
        loading: VueTypes.bool.def(false),
    },
    data () {
        return {
            activateVisibility: false,
            filters: {
                startDate: '',
                endDate: '',
            },
        }
    },
    computed: {
        cols () {
            return [
                ...this.columns,
                {
                    name: 'type',
                    label: this.$t('general.type'),
                    align: 'left',
                    width: 100,
                },
                {
                    name: 'title',
                    label: this.$t('general.title'),
                    align: 'left',
                },
                {
                    name: 'organization',
                    label: this.$t('general.organization'),
                    align: 'left',
                },
                {
                    name: 'seenAt',
                    label: this.$t('general.seenAt'),
                    align: 'left',
                },
                {
                    name: 'content',
                    label: this.$t('general.content'),
                    align: 'left',
                },
                {
                    name: 'common',
                    label: this.$t('general.actions'),
                    align: 'center',
                },
            ]
        },
        hideHeader () {
            return !(this.rows || []).length
        },
        normalizeRows () {
            return (this.rows || []).map(row => ({
                ...row,
                options: this.generateOptionsRow(row),
            }))
        },
    },
    methods: {
        handleSelectedDate () {
            const { filters } = this
            this.$emit('onFilterDate', filters)
        },
        generateOptionsRow () {
            return {
                alreadySeen: {
                    key: ACTIONS.VISUALIZATION, icon: 'eye-off', iconColor: 'primary', disabled: true,
                },
                unseen: {
                    key: ACTIONS.VISUALIZATION, icon: 'eye', iconColor: 'primary', label: 'Mark as seen', event: 'onVisualization',
                },
            }
        },
        handleVisibility (value) {
            this.activateVisibility = !this.activateVisibility
            this.resetDate()
            this.$emit('onChangeVisibility', { alreadySeen: this.activateVisibility })
        },
        handleEmmit ({ event }, row) {
            this.$emit(event, row)
        },
        resetDate () {
            this.filters.startDate = ''
            this.filters.endDate = ''
        },
        getPagination () {
            return this.$refs.table.getPagination()
        },
        handleFetchData (props) {
            this.$emit('onFetchData', { ...props, alreadySeen: this.activateVisibility })
        },
        getOption (row) {
            return row.seenAt ? row.options.alreadySeen : row.options.unseen
        },
        handleClick (row) {
            this.$emit('onClickContent', row)
        },
    },

}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 135px;

    .Actions {
        position: sticky;
        right: 0;
        background: $table-bg;
    }

    .ellipsis {
        display: inline-block;
        vertical-align: middle;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .td-common {
        text-align: center;
    }
}

.EmptyList {
    width: 100%;
}
</style>
