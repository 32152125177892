var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.traces.exportDialog.title")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("visualization.traces.exportDialog.subtitle"))
              )
            ]),
            _c(
              "div",
              { staticClass: "column q-gutter-md q-ma-md" },
              [
                _c("QTable", {
                  staticClass: "my-sticky-header-table",
                  attrs: {
                    data: _vm.attrs,
                    columns: _vm.columns,
                    rowKey: "name",
                    flat: "",
                    outlined: "",
                    separator: "none",
                    bordered: "",
                    tableHeaderClass: "bg-primary text-white",
                    selectedRowsLabel: _vm.getSelectedString,
                    selection: "multiple",
                    selected: _vm.selected,
                    rowsPerPageOptions: [0],
                    hidePagination: ""
                  },
                  on: {
                    "update:selected": function($event) {
                      _vm.selected = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-data",
                      fn: function(ref) {
                        var message = ref.message
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "full-width row flex-center text-accent q-gutter-sm"
                            },
                            [_c("span", [_vm._v(" " + _vm._s(message) + " ")])]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "div",
                  { staticClass: "flex items-center justify-center" },
                  [
                    _c("QBtn", {
                      attrs: {
                        label: _vm.$t(
                          "visualization.traces.exportDialog.export"
                        ),
                        noCaps: "",
                        color: "primary",
                        unelevated: "",
                        iconRight: "download"
                      },
                      on: { click: _vm.handleExportData }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }