var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_vm.isLoading ? _c("WLoading", { attrs: { visible: "" } }) : _vm._e()],
        1
      ),
      !_vm.isLoading
        ? _c("div", { staticClass: "Step" }, [
            _c(
              "div",
              { staticClass: "Services flex row wrap" },
              _vm._l(_vm.services, function(ref) {
                var key = ref.key
                var label = ref.label
                var disabled = ref.disabled
                var description = ref.description
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "myCard",
                    attrs: { color: "secondary", disabled: disabled }
                  },
                  [
                    _c(
                      "button",
                      {
                        key: key,
                        staticClass: "buttonConnector",
                        attrs: {
                          value: ((_vm.value || {}).service || {}).identifier,
                          val: key,
                          label: label,
                          disable: disabled
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleInput(key)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "buttonIconContainer" }, [
                          _c("img", {
                            staticClass: "buttonIcon",
                            attrs: {
                              src: require("@/assets/images/connectors/" +
                                key +
                                ".svg"),
                              alt: label
                            }
                          })
                        ]),
                        _c("div", { staticClass: "containerName" }, [
                          _c("div", { staticClass: "title" }, [
                            _c("h3", { staticClass: "titleContainer" }, [
                              _vm._v(" " + _vm._s(label) + " ")
                            ])
                          ]),
                          _c("div", { staticClass: "description" }, [
                            _c("p", { staticClass: "descriptionContainer" }, [
                              _vm._v(" " + _vm._s(description) + " ")
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }