import moment from 'moment'
import Validators from '@/utils/validators'

export default {
    beforeMount () {
        this.validators = Validators
    },
    methods: {
        validateDateFormat (value, format) {
            if ((format === '' || format === null)) return false
            if (value && format) {
                const checked = moment(value, format, true).isValid()
                return checked || !checked
            }

            return true
        },
    },
}
