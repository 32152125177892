var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Plot q-ma-md" }, [
    _c(
      "div",
      { staticClass: "flex justify-between items-end UpperBar" },
      [
        _c(
          "QCard",
          { attrs: { flat: "" } },
          [
            _c(
              "QCardSection",
              { attrs: { horizontal: "" } },
              [
                _c("QCardSection", { staticClass: "bg-grey-2" }, [
                  _c("span", { staticClass: "text-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("visualization.traces.plot.start")))
                  ]),
                  _vm._v(" " + _vm._s(_vm.startDate) + " ")
                ]),
                _c("QSeparator", {
                  staticClass: "bg-white",
                  attrs: { vertical: "" }
                }),
                _c("QCardSection", { staticClass: "bg-grey-2" }, [
                  _c("span", { staticClass: "text-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$t("visualization.traces.plot.end")))
                  ]),
                  _vm._v(" " + _vm._s(_vm.endDate) + " ")
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("QBtn", {
              staticClass: "q-mr-md",
              attrs: {
                color: "primary",
                icon: "table_view",
                unelevated: "",
                noCaps: "",
                label: _vm.$t("visualization.traces.toTable")
              },
              on: { click: _vm.switchGraphicalView }
            }),
            _c(
              "QInput",
              {
                staticStyle: { width: "220px" },
                attrs: {
                  dense: "",
                  debounce: "300",
                  color: "primary",
                  outlined: "",
                  clearable: "",
                  clearIcon: "close",
                  placeholder: _vm.$t(
                    "visualization.traces.table.search.placeholder"
                  )
                },
                on: { input: _vm.getEvents },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              },
              [
                _c("QIcon", {
                  attrs: { slot: "append", name: "search" },
                  slot: "append"
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _vm.numEvents > 0
      ? _c(
          "div",
          { staticClass: "q-mt-md" },
          [
            _c(
              "QCard",
              { attrs: { flat: "", bordered: "" } },
              [
                _c("v-chart", {
                  staticClass: "chart",
                  attrs: { option: _vm.scatterChartData, autoresize: "" }
                })
              ],
              1
            ),
            _c("QSlider", {
              attrs: { min: 1, max: _vm.numPages, markers: "", reverse: "" },
              on: { change: _vm.getEvents },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            }),
            _c(
              "QCard",
              { attrs: { flat: "" } },
              [
                _c("QCardSection", { staticClass: "text-center bg-grey-2" }, [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("visualization.traces.plot.eventsShown")
                        ) +
                        " "
                    )
                  ]),
                  _c("span", { staticClass: "text-weight-bold" }, [
                    _vm._v(" " + _vm._s(_vm.startEvent) + " ")
                  ]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("visualization.traces.plot.eventsTo")) +
                        " "
                    )
                  ]),
                  _c("span", { staticClass: "text-weight-bold" }, [
                    _vm._v(" " + _vm._s(_vm.endEvent) + " ")
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      : !_vm.isLoading
      ? _c("div", [
          _c(
            "div",
            { staticClass: "flex justify-center items-cemter q-mt-lg" },
            [
              _c("WText", { attrs: { size: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("visualization.traces.plot.noEvents")) +
                    " "
                )
              ])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }