export default {
    rows: [
        {
            variantId: '#1',
            value: 9800,
        },
        {
            variantId: '#2',
            value: 9567,
        },
        {
            variantId: '#34',
            value: 7854,
        },
        {
            variantId: '#435',
            value: 6324,
        },
        {
            variantId: '#561',
            value: 5342,
        },
        {
            variantId: '#1567',
            value: 4523,
        },
    ],
}
