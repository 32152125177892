var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "column q-col-gutter-md q-pa-md" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c(
            "WText",
            { attrs: { align: "center", weight: "bold", color: "primary" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("visualization.filters.analysisContext")) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("QBtnToggle", {
            attrs: {
              noCaps: "",
              spread: "",
              unelevated: "",
              toggleColor: "primary",
              options: [
                { value: "total", slot: "one" },
                { value: "filtered", slot: "two" }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "one",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "row items-center" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "visualization.filters.contextualInfo.total"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("QIcon", { attrs: { right: "", name: "storage" } })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "two",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "row items-center" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "visualization.filters.contextualInfo.filtered"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("QIcon", {
                          attrs: { right: "", name: "filter_alt" }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.displayMode,
              callback: function($$v) {
                _vm.displayMode = $$v
              },
              expression: "displayMode"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "row q-col-gutter-sm" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("span", { staticClass: "text-weight-bold" }, [
                _vm._v(
                  _vm._s(_vm.$t("visualization.filters.contextualInfo.cases"))
                )
              ]),
              _c(
                "QCard",
                { staticClass: "bg-grey-2", attrs: { flat: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "q-pa-sm text-center text-h6 text-weight-bold"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dictFormattedData.cases[
                              _vm.displayMode + "Data"
                            ]
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "QLinearProgress",
                    {
                      attrs: {
                        size: "25px",
                        value: parseFloat(
                          _vm.dictFormattedData.cases[
                            _vm.displayMode + "DataPercentage"
                          ]
                        ),
                        color: "primary",
                        trackColor: "transparent"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "absolute-full flex flex-center" },
                        [
                          _c("QBadge", {
                            attrs: {
                              color: "white",
                              textColor: "primary",
                              label:
                                (
                                  _vm.dictFormattedData.cases[
                                    _vm.displayMode + "DataPercentage"
                                  ] * 100
                                ).toFixed(0) + "%"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("span", { staticClass: "text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("visualization.filters.contextualInfo.variants")
                  )
                )
              ]),
              _c(
                "QCard",
                { staticClass: "bg-grey-2", attrs: { flat: "" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "q-pa-sm text-center text-h6 text-weight-bold"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.dictFormattedData.variants[
                              _vm.displayMode + "Data"
                            ]
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "QLinearProgress",
                    {
                      attrs: {
                        size: "25px",
                        value: parseFloat(
                          _vm.dictFormattedData.variants[
                            _vm.displayMode + "DataPercentage"
                          ]
                        ),
                        color: "primary",
                        trackColor: "transparent"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "absolute-full flex flex-center" },
                        [
                          _c("QBadge", {
                            attrs: {
                              color: "white",
                              textColor: "primary",
                              label:
                                (
                                  _vm.dictFormattedData.variants[
                                    _vm.displayMode + "DataPercentage"
                                  ] * 100
                                ).toFixed(0) + "%"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("span", { staticClass: "text-weight-bold" }, [
          _vm._v(
            _vm._s(_vm.$t("visualization.filters.contextualInfo.duration"))
          )
        ]),
        _c("div", { staticClass: "row q-col-gutter-sm items-center" }, [
          _c(
            "div",
            { staticClass: "col-sm-7" },
            [
              _c("QCard", { staticClass: "bg-grey-2", attrs: { flat: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "q-pa-sm text-center text-h6 text-weight-bold"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dictFormattedData["" + _vm.durMode.value][
                            _vm.displayMode + "Data"
                          ]
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-5 text-center" },
            [
              _c(
                "QFab",
                {
                  attrs: {
                    label: _vm.durMode.value,
                    square: "",
                    flat: "",
                    labelPosition: "left",
                    color: "grey-2",
                    textColor: "primary",
                    icon: "keyboard_arrow_down",
                    direction: "down",
                    padding: "sm"
                  },
                  model: {
                    value: _vm.displayDurationOptions,
                    callback: function($$v) {
                      _vm.displayDurationOptions = $$v
                    },
                    expression: "displayDurationOptions"
                  }
                },
                _vm._l(_vm.durOptions, function(option) {
                  return _c("QFabAction", {
                    key: option.value,
                    attrs: {
                      textColor: "primary",
                      color: "grey-2",
                      label: option.label,
                      padding: "xs"
                    },
                    on: {
                      click: function($event) {
                        return _vm.setDurationOption(option)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "row q-col-gutter-sm" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("span", { staticClass: "text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "visualization.filters.contextualInfo.minStartDateTime"
                    )
                  )
                )
              ]),
              _c(
                "QInput",
                {
                  staticClass: "custom-cursor",
                  attrs: { disable: _vm.readOnly, readonly: "" },
                  on: {
                    click: function($event) {
                      _vm.openStartDateModal = true
                    }
                  },
                  model: {
                    value:
                      _vm.dictFormattedData.minStartDateTime[
                        _vm.displayMode + "Data"
                      ],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dictFormattedData.minStartDateTime,
                        _vm.displayMode + "Data",
                        $$v
                      )
                    },
                    expression:
                      "dictFormattedData.minStartDateTime[`${displayMode}Data`]"
                  }
                },
                [
                  _c(
                    "QIcon",
                    {
                      staticClass: "cursor-pointer self-center",
                      attrs: { name: "event" }
                    },
                    [
                      _c(
                        "QPopupProxy",
                        {
                          attrs: {
                            transitionShow: "scale",
                            transitionHide: "scale"
                          },
                          model: {
                            value: _vm.openStartDateModal,
                            callback: function($$v) {
                              _vm.openStartDateModal = $$v
                            },
                            expression: "openStartDateModal"
                          }
                        },
                        [
                          _vm.datetimeFilters
                            ? _c(
                                "QDate",
                                {
                                  attrs: {
                                    mask: "YYYY/MM/DD",
                                    options: function(date) {
                                      return (
                                        date < _vm.filterDate.end &&
                                        date >
                                          _vm.formatDateString(
                                            _vm.dictFormattedData
                                              .minStartDateTime.totalData
                                          )
                                      )
                                    }
                                  },
                                  on: {
                                    input: function(date) {
                                      return _vm.inputDate(
                                        date,
                                        "TRACE_STARTS_AFTER"
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.filterDate.start,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filterDate, "start", $$v)
                                    },
                                    expression: "filterDate.start"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row items-center justify-end"
                                    },
                                    [
                                      _c(
                                        "WButton",
                                        {
                                          attrs: {
                                            label:
                                              "$t('visualization.filters.reset')",
                                            size: "sm",
                                            iconRight: "rotate-ccw",
                                            iconSize: 10,
                                            disabled: _vm.readOnly
                                          },
                                          on: { click: _vm.resetFilters }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "visualization.filters.reset"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("span", { staticClass: "text-weight-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "visualization.filters.contextualInfo.maxEndDateTime"
                    )
                  )
                )
              ]),
              _c(
                "QInput",
                {
                  staticClass: "custom-cursor",
                  attrs: { disable: _vm.readOnly, readonly: "" },
                  on: {
                    click: function($event) {
                      _vm.openEndDateModal = true
                    }
                  },
                  model: {
                    value:
                      _vm.dictFormattedData.maxEndDateTime[
                        _vm.displayMode + "Data"
                      ],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dictFormattedData.maxEndDateTime,
                        _vm.displayMode + "Data",
                        $$v
                      )
                    },
                    expression:
                      "dictFormattedData.maxEndDateTime[`${displayMode}Data`]"
                  }
                },
                [
                  _c(
                    "QIcon",
                    {
                      staticClass: "cursor-pointer self-center",
                      attrs: { name: "event" }
                    },
                    [
                      _c(
                        "QPopupProxy",
                        {
                          attrs: {
                            transitionShow: "scale",
                            transitionHide: "scale"
                          },
                          model: {
                            value: _vm.openEndDateModal,
                            callback: function($$v) {
                              _vm.openEndDateModal = $$v
                            },
                            expression: "openEndDateModal"
                          }
                        },
                        [
                          _vm.datetimeFilters
                            ? _c(
                                "QDate",
                                {
                                  attrs: {
                                    mask: "YYYY/MM/DD",
                                    options: function(date) {
                                      return (
                                        date > _vm.filterDate.start &&
                                        date <
                                          _vm.formatDateString(
                                            _vm.dictFormattedData.maxEndDateTime
                                              .totalData
                                          )
                                      )
                                    }
                                  },
                                  on: {
                                    input: function(date) {
                                      return _vm.inputDate(
                                        date,
                                        "TRACE_ENDS_BEFORE"
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.filterDate.end,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filterDate, "end", $$v)
                                    },
                                    expression: "filterDate.end"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row items-center justify-end"
                                    },
                                    [
                                      _c(
                                        "WButton",
                                        {
                                          attrs: {
                                            label:
                                              "$t('visualization.filters.reset')",
                                            size: "sm",
                                            iconRight: "rotate-ccw",
                                            iconSize: 10,
                                            disabled: _vm.readOnly
                                          },
                                          on: { click: _vm.resetFilters }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "visualization.filters.reset"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }