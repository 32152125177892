import { select } from 'd3'
import Utils from './components/utils'

/**
 * Use dagree-d3 library
 * Repository: https://github.com/dagrejs/dagre-d3
 * Examples: https://github.com/dagrejs/dagre-d3/wiki#demos
 *
 * Svg element instance
 */
export const Svg = {
    scale: 0.85,
    stroke: {
        min: 1,
        max: 3,
    },
    directions: {
        LR: {
            nodesep: 30,
            ranksep: 30,
            marginx: 15,
            marginy: 15,
        },
        TB: {
            nodesep: 25,
            ranksep: 25,
            marginx: 10,
            marginy: 10,
        },
    },
    activities: {
        rx: 10,
        ry: 10,
        labelType: 'svg',
        class: `activity-node`,
    },
    arcs: {
        arrowheadClass: 'arrow-head',
        labelType: 'svg',
        class: `arc-node`,
    },
    loops: {
        rectColor: '#f29304',
        dotColor: '#d68203',
        arrowColor: '#f29304',
        pathColor: '#f29304',
    },
    overlap: {
        arrowColor: '#ef5350',
        pathColor: '#ef5350',
    },
    executed: {
        rectColor: '#201457',
    },
    clean (el) {
        if (el) {
            // Cleanup old graph
            select(el)
                .selectAll('*')
                .remove()
            // Reset height (0px doesn't work in Firefox)
            el.setAttribute('height', '1px')
        }
    },
    // Chrome with devicePixelRatio > 1 fix
    // https://github.com/dagrejs/dagre-d3/issues/263
    // can't be done via this.svg
    initalizeZoom (el) {
        if (window.devicePixelRatio > 1 && el) {
            el.style.zoom = 1 / window.devicePixelRatio
        }
    },
    // Reset zoom (fix)
    resetZoom (el) {
        if (window.devicePixelRatio > 1 && el) {
            el.style.zoom = 1
        }
    },
    // Show heat colors
    enableLoopsAndOverlaps (data, model, onlyColor) {
        if (onlyColor) {
            enableLOActivities(data, model)
            enableLOArcs(data, model)
        } else {
            enableLOActivities(data)
            enableLOArcs(data, model)
        }
    },
    // Add listenners and manage class names
    listenEdgeHover () {
        this.listEdgePathHover()
    },
    ListNodeHover () {
        const nodes = document.getElementsByClassName('node')

        if (nodes.length) {
            nodes.forEach((node) => {
                const nodeLabel = document.getElementById(node.id)
                node.addEventListener('mouseover', () => addHighlight(node, nodeLabel))
                node.addEventListener('mouseout', () => removeHighlight(node, nodeLabel))
                nodeLabel.addEventListener('mouseover', () => addHighlight(node, nodeLabel))
                nodeLabel.addEventListener('mouseout', () => removeHighlight(node, nodeLabel))
            })
        }
    },
    listEdgePathHover () {
        const edges = document.getElementsByClassName('edgePath')
        if (edges.length) {
            edges.forEach((edge, index) => {
                const label = document.getElementById(`arc-node-${index}-label`)
                console.log(`arc-node-${index}-label`)
                console.log(label)
                edge.addEventListener('mouseover', () => addHighlight(edge, label))
                edge.addEventListener('mouseout', () => removeHighlight(edge, label))
                label.addEventListener('mouseover', () => addHighlight(edge, label))
                label.addEventListener('mouseout', () => removeHighlight(edge, label))
            })
        }
    },
    createElement: (component) => {
        const wrapper = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        const { width, height } = getElementSize(component.$el)
        wrapper.appendChild(component.$el)
        wrapper.setAttribute('width', width)
        wrapper.setAttribute('height', height)
        return wrapper
    },
}

/**
 * Show heat map activities
 * Get color from data- attributes (stored in format function when drawing the graph)
 */
export const enableLOActivities = (
    {
        activities,
        frequency,
        loops_activities: loopsActivities,
        activitiesExecuted: executed,
    },
    model,
) => {
    const nodes = document.querySelectorAll(`.${Svg.activities.class}`)
    const loopsActivitiesIds = (loopsActivities || []).map(act => act.name)
    const onlyExecutedActivities = Object.entries(executed || []).filter(([key, value]) => value === true).map(([key, value]) => Number(key))
    const executedNames = activities.filter(act => onlyExecutedActivities.includes(act.id)).map(act => act.name)
    nodes.forEach((node) => {
        const color = ''
        const rect = node.querySelector('rect')
        const dot = node.querySelector('.Dot')

        if (node.id === 'START') dot.style.background = '#01C38E'
        else if (node.id === 'END') dot.style.background = '#FABFC5'
        else {
            const nodeActivity = activities.find(activity => activity.name === node.getAttribute('id'))
            const activityHeatMap = {
                duration: 0.098,
                frequency: nodeActivity.frequency / frequency,
            }
            const modelType = (model || {}).values || Utils.graphValues.BOTH
            const dotColor = Utils.getHeatMapColors(activityHeatMap)[modelType !== Utils.graphValues.BOTH ? modelType : Utils.graphValues.FREQ]
            dot.style.background = dotColor
        }

        if (rect) {
            rect.style.fill = color
            rect.style.stroke = color
            if (loopsActivitiesIds.includes(node.id) && dot) {
                rect.style.fill = Svg.loops.rectColor
                rect.style.stroke = Svg.loops.rectColor
                dot.style.background = Svg.loops.dotColor
            }
            if (executedNames.includes(node.id)) {
                rect.style.stroke = Svg.executed.rectColor
            }
        }
    })
}
export const nameFormatter = (name) => {
    const sanitizedName = name.replace(/[^\w\s]/gi, '')
    return sanitizedName.replace(/\s/g, '-')
}
/**
 * Show heat map arcs
 * Get color from data- attributes (stored in format function when drawing the graph)
 */
export const enableLOArcs = ({ loop_arcs: loopArcs, arcs: allArcs }, { statsValue: durMode, values: dataMode }) => {
    const loopsArcsId = (loopArcs || []).map(arc => `arc-node-${nameFormatter(arc.name)}`)
    const overlapArcsId =
        dataMode !== Utils.graphValues.FREQ ? (allArcs || [])
            .filter(arc => Utils.getStatValue(arc.duration, durMode) < 0).map(arc => `arc-node-${nameFormatter(arc.name)}`) : []
    const nodes = document.querySelectorAll(`.${Svg.arcs.class}`)
    nodes.forEach((node) => {
        const path = document.querySelector(`#${node.id} > path`)
        const arrow = document.querySelector(`#${node.id} .${Svg.arcs.arrowheadClass}`)
        const color = ''
        if (arrow) {
            arrow.style.fill = color
            arrow.style.stroke = color
            if (loopsArcsId.includes(node.id)) {
                arrow.style.fill = Svg.loops.arrowColor
                arrow.style.stroke = Svg.loops.arrowColor
            } else if (overlapArcsId.includes(node.id)) {
                arrow.style.stroke = Svg.overlap.arrowColor
                arrow.style.fill = Svg.overlap.arrowColor
            }
        }
        if (path) {
            path.style.stroke = color
            if (loopsArcsId.includes(node.id)) {
                path.style.stroke = Svg.loops.pathColor
            } else if (overlapArcsId.includes(node.id)) {
                path.style.stroke = Svg.overlap.pathColor
            }
        }
    })
}
/**
 * Return width and height of element DOM
 * Hotfix on calculate size of svg element in tablet devices and IE
 * @param {*} element
 */
export const getElementSize = (element) => {
    const body = document.querySelector('body')
    if (!body) return {}
    body.appendChild(element)
    const noop = body.querySelector(`#${element.id}`)
    const { clientWidth: width, clientHeight: height } = noop
    noop.remove()
    return { width, height }
}

/**
 * Add `hightlight` class in element
 */
export const addHighlight = (path, label) => {
    path.classList.add('highlight')
    label.classList.add('highlight')
}

/**
 * Remove `hightlight` class in element
 */
export const removeHighlight = (path, label) => {
    path.classList.remove('highlight')
    label.classList.remove('highlight')
}

export default Svg
