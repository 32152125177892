<template>
    <div class="AlertList">
        <div class="row q-mt-md q-mb-sm q-justify-end">
            <div class="textColumn">
                <p>
                    {{ $t('visualization.alerts.filterBy') }}
                </p>
            </div>
            <div>
                <q-select
                    v-model="filterByType"
                    :options="filterOptions"
                    outlined
                    dense
                    class="custom-select"
                    @input="fetchData"
                />
            </div>
        </div>
        <Table
            ref="table"
            :data="normalizeRows"
            :total="total"
            hideHeader
            rowKey="uuid"
            @table-request="fetchData"
        >
            <template #content="{ row }">
                <AlertElement
                    :row="row"
                    @dismiss="dismissAlert"
                />
            </template>
            <template #empty>
                <EmptyList
                    class="mt-2 mb-2"
                    :title="emptyMessage"
                />
            </template>
        </Table>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import AlertElement from './AlertElement.vue'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { Table, EmptyList } from '@/components'

export default {
    name: 'AlertList',
    components: {
        AlertElement,
        Table,
        EmptyList,
    },
    props: {
        processId: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        showDismissed: VueTypes.bool,
    },
    data () {
        return {
            alerts: [],
            total: null,
            filterByType: null,
            filterOptions: [
                { label: this.$t('visualization.alerts.definition.types.ALL'), value: null },
                { label: this.$t('visualization.alerts.definition.types.STANDARD'), value: 'STANDARD' },
                { label: this.$t('visualization.alerts.definition.types.PREDICTION'), value: 'PREDICTION' },
            ],
        }
    },
    computed: {
        normalizeRows () {
            return (this.alerts || []).filter(row => row.dismissed === this.showDismissed).map(row => ({ ...row }))
        },
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return {
                ...pagination,
            }
        },
        emptyMessage () {
            return this.$t('visualization.alerts.detected.noAlerts')
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        getPagination () {
            return this.$refs.table.getPagination()
        },
        isArchived ({ deletedAt }) {
            return !!deletedAt
        },
        dismissAlert (alertRow) {
            this.updateAlert(alertRow.uuid)
        },
        async fetchData ({ polling = false } = {}) {
            const datasetId = this.processId
            const {
                page, rowsPerPage,
            } = this.params
            const params = {
                filterType: this.filterByType ? this.filterByType.value : null,
                ...(rowsPerPage ? { start: (page - 1) } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                polling,
                dismissed: this.showDismissed,
            }
            apiRequest(Api().alerts.historyList({ datasetId, params }))
                .then(({ data, total }) => {
                    this.alerts = data
                    this.total = total
                })
                .catch(notifyError)
        },
        async updateAlert (alertId) {
            const datasetId = this.processId
            apiRequest(Api().alerts.historyDismiss({ datasetId, alertId }))
                .then(() => {
                    this.fetchData()
                })
                .catch(notifyError)
        },
    },
}
</script>
<style scoped lang="scss">
    .AlertList {
        height: fit-content;
        max-height: 80vh;
        overflow: auto;
    }

    .row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .textColumn {
        margin-right: 16px; /* Espacio entre el texto y el select */
    }

    .custom-select {
    width: 200px; /* Ancho personalizado del QSelect */
    }
</style>
