export default {
    visualizationToBooster: 'Process Booster',
    customAnalysis: 'Custom Analysis',
    performanceBoosterTitle: 'Performance Booster',
    boosterDescription: 'Understand how your process currently performs',
    overview: {
        process: 'Process Overview',
    },
    prediction: {
        process: 'Process Prediction',
        predictionHighlights: {
            name: 'Prediction highlights',
            cases: 'cases',
            ongoingCases: 'ongoing cases ',
            meetingSLA: 'in condition of ',
            meetingSLAColor: 'meeting SLA',
            missingSLAColor: 'under risk ',
            missingSLA: 'of missing SLA',
            exceeding: 'already ',
            exceedingColor: 'exceeding SLA',
        },
        predictedPerformance: {
            name: 'Predicted performance by',
        },
    },
    stateView: {
        performanceBy: 'Performance by ',
        rightClick: 'Right click on a bar to view graph',
        leadTimesBy: 'Lead times by ',
        timeSpentPerCase: 'Average time spent per case',
        performanceComparison: 'performance comparison',
        leadTimes: ' lead times',
        cases: ' cases',
        selectFilteringElements: 'Currently selected elements for resource filtering',
        evolutionOverTime: 'Performance evolution over time',
        overallPerformance: 'Overall performance by:',
        activity: 'Activity',
        transition: 'Transition',
        variant: 'Variant',
        months: 'months',
        weeks: 'weeks',
        all: 'All',
        below: 'Below SLA',
        above: 'Above SLA',
        slaCompliance: 'SLA compliance',
        noTraces: 'No traces found for the current filters',
        performanceByClick: 'Click a left chart bar to select',
    },
    improvement: {
        process: 'Process Improvement',
    },
    tabs: {
        overview: 'Overview',
        prediction: 'Prediction',
        improvement: 'Improvement',
    },
}
