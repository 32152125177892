<template>
    <div class="InsightModel pa-1">
        <WText
            tag="p"
            class="pb-1"
        >
            {{ $t('wizard.query.insight.model.description') }}
        </WText>

        <InsightModelSection
            :title="$t('wizard.query.insight.model.seq.title')"
            :description="$t('wizard.query.insight.model.seq.description')"
            :examples="$t('wizard.query.insight.model.seq.examples')"
        />
        <InsightModelSection
            :title="$t('wizard.query.insight.model.switch.title')"
            :description="$t('wizard.query.insight.model.switch.description')"
            :examples="$t('wizard.query.insight.model.switch.examples')"
        />
        <InsightModelSection
            :title="$t('wizard.query.insight.model.fork.title')"
            :description="$t('wizard.query.insight.model.fork.description')"
            :examples="$t('wizard.query.insight.model.fork.examples')"
        />
        <InsightModelSection
            :title="$t('wizard.query.insight.model.loop.title')"
            :description="$t('wizard.query.insight.model.loop.description')"
            :examples="$t('wizard.query.insight.model.loop.examples')"
        />
        <InsightModelSection
            class="custom"
            :description="$t('wizard.query.insight.model.custom.description')"
            :examples="$t('wizard.query.insight.model.custom.examples')"
        />
    </div>
</template>

<script>
import InsightModelSection from './InsightModelSection.vue'

export default {
    name: 'InsightModel',
    components: {
        InsightModelSection,
    },
}
</script>
