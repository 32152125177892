/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
const lightenDarkenColor = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
}

export default {
    autumn: [
        [0.0, [1.0, 0.0, 0.0]],
        [0.002, [1.0, 0.0, 0.0]],
        [0.004, [1.0, 0.004, 0.0]],
        [0.006, [1.0, 0.004, 0.0]],
        [0.008, [1.0, 0.008, 0.0]],
        [0.01, [1.0, 0.008, 0.0]],
        [0.012, [1.0, 0.012, 0.0]],
        [0.014, [1.0, 0.012, 0.0]],
        [0.016, [1.0, 0.016, 0.0]],
        [0.018, [1.0, 0.016, 0.0]],
        [0.02, [1.0, 0.02, 0.0]],
        [0.022, [1.0, 0.02, 0.0]],
        [0.023, [1.0, 0.024, 0.0]],
        [0.025, [1.0, 0.024, 0.0]],
        [0.027, [1.0, 0.027, 0.0]],
        [0.029, [1.0, 0.027, 0.0]],
        [0.031, [1.0, 0.031, 0.0]],
        [0.033, [1.0, 0.031, 0.0]],
        [0.035, [1.0, 0.035, 0.0]],
        [0.037, [1.0, 0.035, 0.0]],
        [0.039, [1.0, 0.039, 0.0]],
        [0.041, [1.0, 0.039, 0.0]],
        [0.043, [1.0, 0.043, 0.0]],
        [0.045, [1.0, 0.043, 0.0]],
        [0.047, [1.0, 0.047, 0.0]],
        [0.049, [1.0, 0.047, 0.0]],
        [0.051, [1.0, 0.051, 0.0]],
        [0.053, [1.0, 0.051, 0.0]],
        [0.055, [1.0, 0.055, 0.0]],
        [0.057, [1.0, 0.055, 0.0]],
        [0.059, [1.0, 0.059, 0.0]],
        [0.061, [1.0, 0.059, 0.0]],
        [0.063, [1.0, 0.063, 0.0]],
        [0.065, [1.0, 0.063, 0.0]],
        [0.067, [1.0, 0.067, 0.0]],
        [0.068, [1.0, 0.067, 0.0]],
        [0.07, [1.0, 0.071, 0.0]],
        [0.072, [1.0, 0.071, 0.0]],
        [0.074, [1.0, 0.075, 0.0]],
        [0.076, [1.0, 0.075, 0.0]],
        [0.078, [1.0, 0.078, 0.0]],
        [0.08, [1.0, 0.078, 0.0]],
        [0.082, [1.0, 0.082, 0.0]],
        [0.084, [1.0, 0.082, 0.0]],
        [0.086, [1.0, 0.086, 0.0]],
        [0.088, [1.0, 0.086, 0.0]],
        [0.09, [1.0, 0.09, 0.0]],
        [0.092, [1.0, 0.09, 0.0]],
        [0.094, [1.0, 0.094, 0.0]],
        [0.096, [1.0, 0.094, 0.0]],
        [0.098, [1.0, 0.098, 0.0]],
        [0.1, [1.0, 0.098, 0.0]],
        [0.102, [1.0, 0.102, 0.0]],
        [0.104, [1.0, 0.102, 0.0]],
        [0.106, [1.0, 0.106, 0.0]],
        [0.108, [1.0, 0.106, 0.0]],
        [0.11, [1.0, 0.11, 0.0]],
        [0.112, [1.0, 0.11, 0.0]],
        [0.114, [1.0, 0.114, 0.0]],
        [0.115, [1.0, 0.114, 0.0]],
        [0.117, [1.0, 0.118, 0.0]],
        [0.119, [1.0, 0.118, 0.0]],
        [0.121, [1.0, 0.122, 0.0]],
        [0.123, [1.0, 0.122, 0.0]],
        [0.125, [1.0, 0.125, 0.0]],
        [0.127, [1.0, 0.125, 0.0]],
        [0.129, [1.0, 0.129, 0.0]],
        [0.131, [1.0, 0.129, 0.0]],
        [0.133, [1.0, 0.133, 0.0]],
        [0.135, [1.0, 0.133, 0.0]],
        [0.137, [1.0, 0.137, 0.0]],
        [0.139, [1.0, 0.137, 0.0]],
        [0.141, [1.0, 0.141, 0.0]],
        [0.143, [1.0, 0.141, 0.0]],
        [0.145, [1.0, 0.145, 0.0]],
        [0.147, [1.0, 0.145, 0.0]],
        [0.149, [1.0, 0.149, 0.0]],
        [0.151, [1.0, 0.149, 0.0]],
        [0.153, [1.0, 0.153, 0.0]],
        [0.155, [1.0, 0.153, 0.0]],
        [0.157, [1.0, 0.157, 0.0]],
        [0.159, [1.0, 0.157, 0.0]],
        [0.16, [1.0, 0.161, 0.0]],
        [0.162, [1.0, 0.161, 0.0]],
        [0.164, [1.0, 0.165, 0.0]],
        [0.166, [1.0, 0.165, 0.0]],
        [0.168, [1.0, 0.169, 0.0]],
        [0.17, [1.0, 0.169, 0.0]],
        [0.172, [1.0, 0.173, 0.0]],
        [0.174, [1.0, 0.173, 0.0]],
        [0.176, [1.0, 0.176, 0.0]],
        [0.178, [1.0, 0.176, 0.0]],
        [0.18, [1.0, 0.18, 0.0]],
        [0.182, [1.0, 0.18, 0.0]],
        [0.184, [1.0, 0.184, 0.0]],
        [0.186, [1.0, 0.184, 0.0]],
        [0.188, [1.0, 0.188, 0.0]],
        [0.19, [1.0, 0.188, 0.0]],
        [0.192, [1.0, 0.192, 0.0]],
        [0.194, [1.0, 0.192, 0.0]],
        [0.196, [1.0, 0.196, 0.0]],
        [0.198, [1.0, 0.196, 0.0]],
        [0.2, [1.0, 0.2, 0.0]],
        [0.202, [1.0, 0.2, 0.0]],
        [0.204, [1.0, 0.204, 0.0]],
        [0.205, [1.0, 0.204, 0.0]],
        [0.207, [1.0, 0.208, 0.0]],
        [0.209, [1.0, 0.208, 0.0]],
        [0.211, [1.0, 0.212, 0.0]],
        [0.213, [1.0, 0.212, 0.0]],
        [0.215, [1.0, 0.216, 0.0]],
        [0.217, [1.0, 0.216, 0.0]],
        [0.219, [1.0, 0.22, 0.0]],
        [0.221, [1.0, 0.22, 0.0]],
        [0.223, [1.0, 0.224, 0.0]],
        [0.225, [1.0, 0.224, 0.0]],
        [0.227, [1.0, 0.227, 0.0]],
        [0.229, [1.0, 0.227, 0.0]],
        [0.231, [1.0, 0.231, 0.0]],
        [0.233, [1.0, 0.231, 0.0]],
        [0.235, [1.0, 0.235, 0.0]],
        [0.237, [1.0, 0.235, 0.0]],
        [0.239, [1.0, 0.239, 0.0]],
        [0.241, [1.0, 0.239, 0.0]],
        [0.243, [1.0, 0.243, 0.0]],
        [0.245, [1.0, 0.243, 0.0]],
        [0.247, [1.0, 0.247, 0.0]],
        [0.249, [1.0, 0.247, 0.0]],
        [0.25, [1.0, 0.251, 0.0]],
        [0.252, [1.0, 0.251, 0.0]],
        [0.254, [1.0, 0.255, 0.0]],
        [0.256, [1.0, 0.255, 0.0]],
        [0.258, [1.0, 0.259, 0.0]],
        [0.26, [1.0, 0.259, 0.0]],
        [0.262, [1.0, 0.263, 0.0]],
        [0.264, [1.0, 0.263, 0.0]],
        [0.266, [1.0, 0.267, 0.0]],
        [0.268, [1.0, 0.267, 0.0]],
        [0.27, [1.0, 0.271, 0.0]],
        [0.272, [1.0, 0.271, 0.0]],
        [0.274, [1.0, 0.275, 0.0]],
        [0.276, [1.0, 0.275, 0.0]],
        [0.278, [1.0, 0.278, 0.0]],
        [0.28, [1.0, 0.278, 0.0]],
        [0.282, [1.0, 0.282, 0.0]],
        [0.284, [1.0, 0.282, 0.0]],
        [0.286, [1.0, 0.286, 0.0]],
        [0.288, [1.0, 0.286, 0.0]],
        [0.29, [1.0, 0.29, 0.0]],
        [0.292, [1.0, 0.29, 0.0]],
        [0.294, [1.0, 0.294, 0.0]],
        [0.295, [1.0, 0.294, 0.0]],
        [0.297, [1.0, 0.298, 0.0]],
        [0.299, [1.0, 0.298, 0.0]],
        [0.301, [1.0, 0.302, 0.0]],
        [0.303, [1.0, 0.302, 0.0]],
        [0.305, [1.0, 0.306, 0.0]],
        [0.307, [1.0, 0.306, 0.0]],
        [0.309, [1.0, 0.31, 0.0]],
        [0.311, [1.0, 0.31, 0.0]],
        [0.313, [1.0, 0.314, 0.0]],
        [0.315, [1.0, 0.314, 0.0]],
        [0.317, [1.0, 0.318, 0.0]],
        [0.319, [1.0, 0.318, 0.0]],
        [0.321, [1.0, 0.322, 0.0]],
        [0.323, [1.0, 0.322, 0.0]],
        [0.325, [1.0, 0.325, 0.0]],
        [0.327, [1.0, 0.325, 0.0]],
        [0.329, [1.0, 0.329, 0.0]],
        [0.331, [1.0, 0.329, 0.0]],
        [0.333, [1.0, 0.333, 0.0]],
        [0.335, [1.0, 0.333, 0.0]],
        [0.337, [1.0, 0.337, 0.0]],
        [0.339, [1.0, 0.337, 0.0]],
        [0.341, [1.0, 0.341, 0.0]],
        [0.342, [1.0, 0.341, 0.0]],
        [0.344, [1.0, 0.345, 0.0]],
        [0.346, [1.0, 0.345, 0.0]],
        [0.348, [1.0, 0.349, 0.0]],
        [0.35, [1.0, 0.349, 0.0]],
        [0.352, [1.0, 0.353, 0.0]],
        [0.354, [1.0, 0.353, 0.0]],
        [0.356, [1.0, 0.357, 0.0]],
        [0.358, [1.0, 0.357, 0.0]],
        [0.36, [1.0, 0.361, 0.0]],
        [0.362, [1.0, 0.361, 0.0]],
        [0.364, [1.0, 0.365, 0.0]],
        [0.366, [1.0, 0.365, 0.0]],
        [0.368, [1.0, 0.369, 0.0]],
        [0.37, [1.0, 0.369, 0.0]],
        [0.372, [1.0, 0.373, 0.0]],
        [0.374, [1.0, 0.373, 0.0]],
        [0.376, [1.0, 0.376, 0.0]],
        [0.378, [1.0, 0.376, 0.0]],
        [0.38, [1.0, 0.38, 0.0]],
        [0.382, [1.0, 0.38, 0.0]],
        [0.384, [1.0, 0.384, 0.0]],
        [0.386, [1.0, 0.384, 0.0]],
        [0.387, [1.0, 0.388, 0.0]],
        [0.389, [1.0, 0.388, 0.0]],
        [0.391, [1.0, 0.392, 0.0]],
        [0.393, [1.0, 0.392, 0.0]],
        [0.395, [1.0, 0.396, 0.0]],
        [0.397, [1.0, 0.396, 0.0]],
        [0.399, [1.0, 0.4, 0.0]],
        [0.401, [1.0, 0.4, 0.0]],
        [0.403, [1.0, 0.404, 0.0]],
        [0.405, [1.0, 0.404, 0.0]],
        [0.407, [1.0, 0.408, 0.0]],
        [0.409, [1.0, 0.408, 0.0]],
        [0.411, [1.0, 0.412, 0.0]],
        [0.413, [1.0, 0.412, 0.0]],
        [0.415, [1.0, 0.416, 0.0]],
        [0.417, [1.0, 0.416, 0.0]],
        [0.419, [1.0, 0.42, 0.0]],
        [0.421, [1.0, 0.42, 0.0]],
        [0.423, [1.0, 0.424, 0.0]],
        [0.425, [1.0, 0.424, 0.0]],
        [0.427, [1.0, 0.427, 0.0]],
        [0.429, [1.0, 0.427, 0.0]],
        [0.431, [1.0, 0.431, 0.0]],
        [0.432, [1.0, 0.431, 0.0]],
        [0.434, [1.0, 0.435, 0.0]],
        [0.436, [1.0, 0.435, 0.0]],
        [0.438, [1.0, 0.439, 0.0]],
        [0.44, [1.0, 0.439, 0.0]],
        [0.442, [1.0, 0.443, 0.0]],
        [0.444, [1.0, 0.443, 0.0]],
        [0.446, [1.0, 0.447, 0.0]],
        [0.448, [1.0, 0.447, 0.0]],
        [0.45, [1.0, 0.451, 0.0]],
        [0.452, [1.0, 0.451, 0.0]],
        [0.454, [1.0, 0.455, 0.0]],
        [0.456, [1.0, 0.455, 0.0]],
        [0.458, [1.0, 0.459, 0.0]],
        [0.46, [1.0, 0.459, 0.0]],
        [0.462, [1.0, 0.463, 0.0]],
        [0.464, [1.0, 0.463, 0.0]],
        [0.466, [1.0, 0.467, 0.0]],
        [0.468, [1.0, 0.467, 0.0]],
        [0.47, [1.0, 0.471, 0.0]],
        [0.472, [1.0, 0.471, 0.0]],
        [0.474, [1.0, 0.475, 0.0]],
        [0.476, [1.0, 0.475, 0.0]],
        [0.477, [1.0, 0.478, 0.0]],
        [0.479, [1.0, 0.478, 0.0]],
        [0.481, [1.0, 0.482, 0.0]],
        [0.483, [1.0, 0.482, 0.0]],
        [0.485, [1.0, 0.486, 0.0]],
        [0.487, [1.0, 0.486, 0.0]],
        [0.489, [1.0, 0.49, 0.0]],
        [0.491, [1.0, 0.49, 0.0]],
        [0.493, [1.0, 0.494, 0.0]],
        [0.495, [1.0, 0.494, 0.0]],
        [0.497, [1.0, 0.498, 0.0]],
        [0.499, [1.0, 0.498, 0.0]],
        [0.501, [1.0, 0.502, 0.0]],
        [0.503, [1.0, 0.502, 0.0]],
        [0.505, [1.0, 0.506, 0.0]],
        [0.507, [1.0, 0.506, 0.0]],
        [0.509, [1.0, 0.51, 0.0]],
        [0.511, [1.0, 0.51, 0.0]],
        [0.513, [1.0, 0.514, 0.0]],
        [0.515, [1.0, 0.514, 0.0]],
        [0.517, [1.0, 0.518, 0.0]],
        [0.519, [1.0, 0.518, 0.0]],
        [0.521, [1.0, 0.522, 0.0]],
        [0.523, [1.0, 0.522, 0.0]],
        [0.524, [1.0, 0.525, 0.0]],
        [0.526, [1.0, 0.525, 0.0]],
        [0.528, [1.0, 0.529, 0.0]],
        [0.53, [1.0, 0.529, 0.0]],
        [0.532, [1.0, 0.533, 0.0]],
        [0.534, [1.0, 0.533, 0.0]],
        [0.536, [1.0, 0.537, 0.0]],
        [0.538, [1.0, 0.537, 0.0]],
        [0.54, [1.0, 0.541, 0.0]],
        [0.542, [1.0, 0.541, 0.0]],
        [0.544, [1.0, 0.545, 0.0]],
        [0.546, [1.0, 0.545, 0.0]],
        [0.548, [1.0, 0.549, 0.0]],
        [0.55, [1.0, 0.549, 0.0]],
        [0.552, [1.0, 0.553, 0.0]],
        [0.554, [1.0, 0.553, 0.0]],
        [0.556, [1.0, 0.557, 0.0]],
        [0.558, [1.0, 0.557, 0.0]],
        [0.56, [1.0, 0.561, 0.0]],
        [0.562, [1.0, 0.561, 0.0]],
        [0.564, [1.0, 0.565, 0.0]],
        [0.566, [1.0, 0.565, 0.0]],
        [0.568, [1.0, 0.569, 0.0]],
        [0.569, [1.0, 0.569, 0.0]],
        [0.571, [1.0, 0.573, 0.0]],
        [0.573, [1.0, 0.573, 0.0]],
        [0.575, [1.0, 0.576, 0.0]],
        [0.577, [1.0, 0.576, 0.0]],
        [0.579, [1.0, 0.58, 0.0]],
        [0.581, [1.0, 0.58, 0.0]],
        [0.583, [1.0, 0.584, 0.0]],
        [0.585, [1.0, 0.584, 0.0]],
        [0.587, [1.0, 0.588, 0.0]],
        [0.589, [1.0, 0.588, 0.0]],
        [0.591, [1.0, 0.592, 0.0]],
        [0.593, [1.0, 0.592, 0.0]],
        [0.595, [1.0, 0.596, 0.0]],
        [0.597, [1.0, 0.596, 0.0]],
        [0.599, [1.0, 0.6, 0.0]],
        [0.601, [1.0, 0.6, 0.0]],
        [0.603, [1.0, 0.604, 0.0]],
        [0.605, [1.0, 0.604, 0.0]],
        [0.607, [1.0, 0.608, 0.0]],
        [0.609, [1.0, 0.608, 0.0]],
        [0.611, [1.0, 0.612, 0.0]],
        [0.613, [1.0, 0.612, 0.0]],
        [0.614, [1.0, 0.616, 0.0]],
        [0.616, [1.0, 0.616, 0.0]],
        [0.618, [1.0, 0.62, 0.0]],
        [0.62, [1.0, 0.62, 0.0]],
        [0.622, [1.0, 0.624, 0.0]],
        [0.624, [1.0, 0.624, 0.0]],
        [0.626, [1.0, 0.627, 0.0]],
        [0.628, [1.0, 0.627, 0.0]],
        [0.63, [1.0, 0.631, 0.0]],
        [0.632, [1.0, 0.631, 0.0]],
        [0.634, [1.0, 0.635, 0.0]],
        [0.636, [1.0, 0.635, 0.0]],
        [0.638, [1.0, 0.639, 0.0]],
        [0.64, [1.0, 0.639, 0.0]],
        [0.642, [1.0, 0.643, 0.0]],
        [0.644, [1.0, 0.643, 0.0]],
        [0.646, [1.0, 0.647, 0.0]],
        [0.648, [1.0, 0.647, 0.0]],
        [0.65, [1.0, 0.651, 0.0]],
        [0.652, [1.0, 0.651, 0.0]],
        [0.654, [1.0, 0.655, 0.0]],
        [0.656, [1.0, 0.655, 0.0]],
        [0.658, [1.0, 0.659, 0.0]],
        [0.659, [1.0, 0.659, 0.0]],
        [0.661, [1.0, 0.663, 0.0]],
        [0.663, [1.0, 0.663, 0.0]],
        [0.665, [1.0, 0.667, 0.0]],
        [0.667, [1.0, 0.667, 0.0]],
        [0.669, [1.0, 0.671, 0.0]],
        [0.671, [1.0, 0.671, 0.0]],
        [0.673, [1.0, 0.675, 0.0]],
        [0.675, [1.0, 0.675, 0.0]],
        [0.677, [1.0, 0.678, 0.0]],
        [0.679, [1.0, 0.678, 0.0]],
        [0.681, [1.0, 0.682, 0.0]],
        [0.683, [1.0, 0.682, 0.0]],
        [0.685, [1.0, 0.686, 0.0]],
        [0.687, [1.0, 0.686, 0.0]],
        [0.689, [1.0, 0.69, 0.0]],
        [0.691, [1.0, 0.69, 0.0]],
        [0.693, [1.0, 0.694, 0.0]],
        [0.695, [1.0, 0.694, 0.0]],
        [0.697, [1.0, 0.698, 0.0]],
        [0.699, [1.0, 0.698, 0.0]],
        [0.701, [1.0, 0.702, 0.0]],
        [0.703, [1.0, 0.702, 0.0]],
        [0.705, [1.0, 0.706, 0.0]],
        [0.706, [1.0, 0.706, 0.0]],
        [0.708, [1.0, 0.71, 0.0]],
        [0.71, [1.0, 0.71, 0.0]],
        [0.712, [1.0, 0.714, 0.0]],
        [0.714, [1.0, 0.714, 0.0]],
        [0.716, [1.0, 0.718, 0.0]],
        [0.718, [1.0, 0.718, 0.0]],
        [0.72, [1.0, 0.722, 0.0]],
        [0.722, [1.0, 0.722, 0.0]],
        [0.724, [1.0, 0.725, 0.0]],
        [0.726, [1.0, 0.725, 0.0]],
        [0.728, [1.0, 0.729, 0.0]],
        [0.73, [1.0, 0.729, 0.0]],
        [0.732, [1.0, 0.733, 0.0]],
        [0.734, [1.0, 0.733, 0.0]],
        [0.736, [1.0, 0.737, 0.0]],
        [0.738, [1.0, 0.737, 0.0]],
        [0.74, [1.0, 0.741, 0.0]],
        [0.742, [1.0, 0.741, 0.0]],
        [0.744, [1.0, 0.745, 0.0]],
        [0.746, [1.0, 0.745, 0.0]],
        [0.748, [1.0, 0.749, 0.0]],
        [0.75, [1.0, 0.749, 0.0]],
        [0.751, [1.0, 0.753, 0.0]],
        [0.753, [1.0, 0.753, 0.0]],
        [0.755, [1.0, 0.757, 0.0]],
        [0.757, [1.0, 0.757, 0.0]],
        [0.759, [1.0, 0.761, 0.0]],
        [0.761, [1.0, 0.761, 0.0]],
        [0.763, [1.0, 0.765, 0.0]],
        [0.765, [1.0, 0.765, 0.0]],
        [0.767, [1.0, 0.769, 0.0]],
        [0.769, [1.0, 0.769, 0.0]],
        [0.771, [1.0, 0.773, 0.0]],
        [0.773, [1.0, 0.773, 0.0]],
        [0.775, [1.0, 0.776, 0.0]],
        [0.777, [1.0, 0.776, 0.0]],
        [0.779, [1.0, 0.78, 0.0]],
        [0.781, [1.0, 0.78, 0.0]],
        [0.783, [1.0, 0.784, 0.0]],
        [0.785, [1.0, 0.784, 0.0]],
        [0.787, [1.0, 0.788, 0.0]],
        [0.789, [1.0, 0.788, 0.0]],
        [0.791, [1.0, 0.792, 0.0]],
        [0.793, [1.0, 0.792, 0.0]],
        [0.795, [1.0, 0.796, 0.0]],
        [0.796, [1.0, 0.796, 0.0]],
        [0.798, [1.0, 0.8, 0.0]],
        [0.8, [1.0, 0.8, 0.0]],
        [0.802, [1.0, 0.804, 0.0]],
        [0.804, [1.0, 0.804, 0.0]],
        [0.806, [1.0, 0.808, 0.0]],
        [0.808, [1.0, 0.808, 0.0]],
        [0.81, [1.0, 0.812, 0.0]],
        [0.812, [1.0, 0.812, 0.0]],
        [0.814, [1.0, 0.816, 0.0]],
        [0.816, [1.0, 0.816, 0.0]],
        [0.818, [1.0, 0.82, 0.0]],
        [0.82, [1.0, 0.82, 0.0]],
        [0.822, [1.0, 0.824, 0.0]],
        [0.824, [1.0, 0.824, 0.0]],
        [0.826, [1.0, 0.827, 0.0]],
        [0.828, [1.0, 0.827, 0.0]],
        [0.83, [1.0, 0.831, 0.0]],
        [0.832, [1.0, 0.831, 0.0]],
        [0.834, [1.0, 0.835, 0.0]],
        [0.836, [1.0, 0.835, 0.0]],
        [0.838, [1.0, 0.839, 0.0]],
        [0.84, [1.0, 0.839, 0.0]],
        [0.841, [1.0, 0.843, 0.0]],
        [0.843, [1.0, 0.843, 0.0]],
        [0.845, [1.0, 0.847, 0.0]],
        [0.847, [1.0, 0.847, 0.0]],
        [0.849, [1.0, 0.851, 0.0]],
        [0.851, [1.0, 0.851, 0.0]],
        [0.853, [1.0, 0.855, 0.0]],
        [0.855, [1.0, 0.855, 0.0]],
        [0.857, [1.0, 0.859, 0.0]],
        [0.859, [1.0, 0.859, 0.0]],
        [0.861, [1.0, 0.863, 0.0]],
        [0.863, [1.0, 0.863, 0.0]],
        [0.865, [1.0, 0.867, 0.0]],
        [0.867, [1.0, 0.867, 0.0]],
        [0.869, [1.0, 0.871, 0.0]],
        [0.871, [1.0, 0.871, 0.0]],
        [0.873, [1.0, 0.875, 0.0]],
        [0.875, [1.0, 0.875, 0.0]],
        [0.877, [1.0, 0.878, 0.0]],
        [0.879, [1.0, 0.878, 0.0]],
        [0.881, [1.0, 0.882, 0.0]],
        [0.883, [1.0, 0.882, 0.0]],
        [0.885, [1.0, 0.886, 0.0]],
        [0.886, [1.0, 0.886, 0.0]],
        [0.888, [1.0, 0.89, 0.0]],
        [0.89, [1.0, 0.89, 0.0]],
        [0.892, [1.0, 0.894, 0.0]],
        [0.894, [1.0, 0.894, 0.0]],
        [0.896, [1.0, 0.898, 0.0]],
        [0.898, [1.0, 0.898, 0.0]],
        [0.9, [1.0, 0.902, 0.0]],
        [0.902, [1.0, 0.902, 0.0]],
        [0.904, [1.0, 0.906, 0.0]],
        [0.906, [1.0, 0.906, 0.0]],
        [0.908, [1.0, 0.91, 0.0]],
        [0.91, [1.0, 0.91, 0.0]],
        [0.912, [1.0, 0.914, 0.0]],
        [0.914, [1.0, 0.914, 0.0]],
        [0.916, [1.0, 0.918, 0.0]],
        [0.918, [1.0, 0.918, 0.0]],
        [0.92, [1.0, 0.922, 0.0]],
        [0.922, [1.0, 0.922, 0.0]],
        [0.924, [1.0, 0.925, 0.0]],
        [0.926, [1.0, 0.925, 0.0]],
        [0.928, [1.0, 0.929, 0.0]],
        [0.93, [1.0, 0.929, 0.0]],
        [0.932, [1.0, 0.933, 0.0]],
        [0.933, [1.0, 0.933, 0.0]],
        [0.935, [1.0, 0.937, 0.0]],
        [0.937, [1.0, 0.937, 0.0]],
        [0.939, [1.0, 0.941, 0.0]],
        [0.941, [1.0, 0.941, 0.0]],
        [0.943, [1.0, 0.945, 0.0]],
        [0.945, [1.0, 0.945, 0.0]],
        [0.947, [1.0, 0.949, 0.0]],
        [0.949, [1.0, 0.949, 0.0]],
        [0.951, [1.0, 0.953, 0.0]],
        [0.953, [1.0, 0.953, 0.0]],
        [0.955, [1.0, 0.957, 0.0]],
        [0.957, [1.0, 0.957, 0.0]],
        [0.959, [1.0, 0.961, 0.0]],
        [0.961, [1.0, 0.961, 0.0]],
        [0.963, [1.0, 0.965, 0.0]],
        [0.965, [1.0, 0.965, 0.0]],
        [0.967, [1.0, 0.969, 0.0]],
        [0.969, [1.0, 0.969, 0.0]],
        [0.971, [1.0, 0.973, 0.0]],
        [0.973, [1.0, 0.973, 0.0]],
        [0.975, [1.0, 0.976, 0.0]],
        [0.977, [1.0, 0.976, 0.0]],
        [0.978, [1.0, 0.98, 0.0]],
        [0.98, [1.0, 0.98, 0.0]],
        [0.982, [1.0, 0.984, 0.0]],
        [0.984, [1.0, 0.984, 0.0]],
        [0.986, [1.0, 0.988, 0.0]],
        [0.988, [1.0, 0.988, 0.0]],
        [0.99, [1.0, 0.992, 0.0]],
        [0.992, [1.0, 0.992, 0.0]],
        [0.994, [1.0, 0.996, 0.0]],
        [0.996, [1.0, 0.996, 0.0]],
        [0.998, [1.0, 1.0, 0.0]],
        [1.0, [1.0, 1.0, 0.0]],
    ],
    lightenDarkenColor,
}
