export default {
    organization: 'Working Group',
    project: 'Project',
    dataset: 'Dataset',
    report: 'Report',
    visualization: 'Visualization',
    download: 'Download zip',
    events: 'Events',
    processing: 'Processing...',

    date: 'Date',
    createdAt: 'Created at',
    updateDate: 'Last update',
    deleteDate: 'Delete on',
    startAt: 'Start at',
    endAt: 'End at',
    seenAt: 'Seen at',
    content: 'Content',

    code: 'Code',
    title: 'Title',
    name: 'Name',
    description: 'Description',
    status: 'Status',
    model: 'Model',
    email: 'Email',
    amount: 'Amount',
    actions: 'Actions',
    type: 'Type',
    file: 'File',
    characters: 'characters',

    readOnly: 'Read-only',
    archived: 'Archived',
    optional: 'Optional',
    uploadData: 'Upload Data',
    comingSoon: 'Coming soon',

    account: 'Account',
    apis: 'APIs',
    signout: 'Sign out',
}
