<template>
    <div class="Notifications">
        <div class="Container">
            <NotificationList
                ref="table"
                :rows="notifications"
                :total="total"
                :loading="isFetching"
                @onFetchData="fetchData"
                @onVisualization="handleVisualization"
                @onChangeVisibility="fetchFilteredData"
                @onFilterDate="fetchFilteredData"
                @onClickContent="handleClickContent"
            />
            <Modal
                :isOpen="!!notificationInfo"
                @close="handleCloseModal"
            >
                <template #header>
                    <WText
                        tag="h3"
                        :size="16"
                        weight="semi"
                        class="mb-0"
                    >
                        {{ $t('general.content') }}
                    </WText>
                </template>
                <template #body>
                    <WText v-html="notificationInfo.content" />
                </template>
            </Modal>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError, notifySuccess,
} from '@/api'
import Modal from '@/components/Modal/Modal.vue'
import { NotificationList } from './sections'

export default {
    name: 'Notifications',
    components: {
        NotificationList,
        Modal,
    },
    props: {
        headers: VueTypes.shape({
            search: VueTypes.string.def(''),
            archived: VueTypes.bool.def(false),
        }),
    },
    data () {
        return {
            notifications: undefined,
            total: undefined,
            isFetching: false,
            notificationInfo: undefined,
        }
    },
    computed: {
        params () {
            const pagination = this.$refs.table.getPagination()
            return {
                ...pagination,
                ...this.headers,
                alreadySeen: this.$refs.table.activateVisibility,
            }
        },
    },
    watch: {
        headers (value) {
            if (value) this.fetchData(this.params)
        },
    },
    beforeMount () {
        const { organizationId } = this.$route.params || {}
        this.organizationId = organizationId
    },
    mounted () {
        this.fetchData(this.params)
    },
    methods: {
        handleUpdateItem ({ uuid, name, description }) {
            this.$refs.editPrDialog.open({ uuid, name, description })
        },
        handleNewItem () {
            this.$refs.newPrDialog.open()
        },
        normalizeNotifications (notifications) {
            return notifications.map(({ organization = '', ...rest }) => ({
                ...rest,
                organization: organization.name,
            }))
        },
        async handleVisualization ({ uuid }) {
            this.isFetching = true
            apiRequest(Api().notifications.create({ notificationUid: uuid }))
                .then(() => {
                    notifySuccess(this.$t('notifications.notifications.seen.text'))
                    this.fetchData(this.params)
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        fetchFilteredData (parameters) {
            const params = { ...this.params, ...parameters }
            this.fetchData(params)
        },
        async fetchData (parameters) {
            this.isFetching = true
            const params = this.normalizeParams(parameters)
            apiRequest(Api().notifications.list(params))
                .then(({ data, total }) => {
                    this.notifications = this.normalizeNotifications(data)
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
        normalizeParams (parameters) {
            const {
                search, page, rowsPerPage, endDate, startDate, alreadySeen,
            } = parameters
            return {
                organizationUid: this.organizationId,
                ...(this.getStartParam(page, rowsPerPage)),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
                ...(endDate ? { endDate } : {}),
                ...(startDate ? { startDate } : {}),
                alreadySeen,
                search: search || null,
            }
        },
        getStartParam (page, rowsPerPage) {
            return rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}
        },
        handleCloseModal () {
            this.notificationInfo = undefined
        },
        handleClickContent (row) {
            this.notificationInfo = row
        },
    },
}
</script>
<style scoped lang="scss">
.Modal {
    &::v-deep .Dialog {
        width: 100%;
        max-width: 500px;
    }
    &::v-deep .Body {
        margin: 30px 0;
    }
}
</style>
