import moment from 'moment'

// https://github.com/moment/moment/issues/348#issuecomment-6535794
export default () => {
    moment.defineLocale('precise-en', {
        relativeTime: {
            s: '%d<span class="small">s</span>', // see https://github.com/timrwood/moment/pull/232#issuecomment-
            ss: '%d<span class="small">s</span>',
            m: '%d<span class="small">m</span>',
            mm: '%d<span class="small">m</span>',
            h: '%d<span class="small">h</span>',
            hh: '%d<span class="small">h</span>',
            d: '%d<span class="small">d</span>',
            dd: '%d<span class="small">d</span>',
            M: '%d<span class="small">M</span>',
            MM: '%d<span class="small">M</span>',
            y: '%d<span class="small">y</span>',
            yy: '%d<span class="small">y</span>',
        },
    })

    moment.relativeTimeThreshold('s', 60)
    moment.relativeTimeThreshold('ss', 0) // must be after 's', disables "few seconds"
    moment.relativeTimeThreshold('m', 60)
    moment.relativeTimeThreshold('h', 24)
    moment.relativeTimeThreshold('d', 31)
    moment.relativeTimeThreshold('M', 12)
    moment.relativeTimeThreshold('y', 365)
}
