<template>
    <div class="Wizard">
        <div
            v-if="config.steps"
            class="Header flex row-stretch-center pt-0-5 pb-0-5 pl-1 pr-1 mb-1"
        >
            <div class="Left flex row-center">
                <WButton
                    v-if="config.exit"
                    outline
                    @click="handleClickExit"
                >
                    {{ $t('wizard.cancel') }}
                </WButton>
            </div>

            <div class="flex flex-1 row-center">
                <WText
                    v-if="config.title"
                    tag="h2"
                    weight="semi"
                    color="primary"
                    :size="18"
                    class="mb-0"
                >
                    {{ config.title }}
                </WText>
            </div>

            <div
                v-if="step"
                class="Right flex row-center"
            >
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        v-if="$DEV && step.mock"
                        class="Mock pr-1 mr-1"
                    >
                        <WButton
                            outline
                            :disabled="config.isNavigate"
                            @click="config.mockStep"
                        >
                            Mock
                        </WButton>
                    </div>
                </Transition>
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <WButton
                        v-if="step.prev"
                        outline
                        class="mr-1"
                        :disabled="config.isNavigate"
                        @click="config.prevStep"
                    >
                        {{ $t('wizard.back') }}
                    </WButton>
                </Transition>
                <Transition
                    name="fade"
                    mode="out-in"
                >
                    <WButton
                        v-if="step.next && !step.end"
                        :disabled="config.isNavigate"
                        @click="config.nextStep"
                    >
                        {{ $t('wizard.continue') }}
                    </WButton>
                    <WButton
                        v-if="step.end"
                        :disabled="config.isNavigate"
                        @click="config.nextStep"
                    >
                        {{ $t('wizard.finish') }}
                    </WButton>
                </Transition>
            </div>
        </div>

        <div
            v-else
            style="font-size: xx-large;"
            class="row justify-between q-pa-sm"
        >
            <h5 class="q-px-xl">
                {{ datasetName }}
            </h5>
            <QIcon
                name="close"
                style="cursor: pointer;"
                @click="handleExitNoConfig()"
            />
        </div>

        <Transition
            v-if="projectId"
            name="main-router"
            mode="out-in"
        >
            <RouterView
                :key="projectId"
                :projectId="projectId"
                :datasetId="datasetId"
                class="Screen"
                @onChangeDatasetId="handleChangeDatasetId"
            />
        </Transition>

        <ModalRequest
            ref="exitDialog"
            :title="config.title"
            :text="$t('wizard.modals.cancel.text')"
            @onAccept="handleExit"
        />
    </div>
</template>

<script>
import { ModalRequest } from '@/components'
import {
    Api,
    apiRequest,
} from '@/api'

export default {
    name: 'Wizard',
    components: {
        ModalRequest,
    },
    provide () {
        return {
            Wizard: this,
        }
    },
    data () {
        return {
            store: {},
            projectId: undefined,
            datasetId: undefined,
            datasetName: undefined,
        }
    },
    computed: {
        config () {
            const { config } = this.store
            return config || {}
        },
        step () {
            const { step, steps = [] } = this.config
            return steps.find(s => s.name === step)
        },
    },
    mounted () {
        const { projectId, processId } = this.$route.params
        this.projectId = projectId
        this.datasetId = processId

        apiRequest(Api().datasets.get({ datasetId: processId }))
            .then(({ data }) => {
                this.datasetName = data[0].name
            })
    },
    methods: {
        handleChangeDatasetId (datasetId) {
            this.datasetId = datasetId
        },
        handleClickExit () {
            const { exitDialog } = this.$refs
            if (exitDialog) exitDialog.open()
        },
        handleExit () {
            this.config.exit()
        },
        handleExitNoConfig () {
            const { organizationId } = this.$route.params || {}
            this.$router.push({ name: 'Project', params: { organizationId, projectId: this.projectId } })
        },
    },
}
</script>
<style scoped lang="scss">
.Header {
    position: relative;
    height: $subheader-height;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;

    .Left, .Right {
        position: absolute;
        top: 0;
        height: 100%;
    }

    .Left {
        left: 20px;
    }

    .Right {
        right: 20px;
    }

    .Mock {
        border-right: 1px solid $border-color;
    }
}

.Screen {
    &::v-deep {
        .q-stepper {
            background: transparent;
        }
        .q-stepper__tab {
            .q-stepper__dot {
                background: transparent;
                color: $secondary;
                border: 2px solid $secondary;
                width: 40px;
                height: 40px;
            }
            .q-icon {
                color: $secondary;
            }

            &--active, &--done {
                .q-stepper__dot {
                    background: $secondary;
                    color: $white;
                    border: 2px solid $white;
                }
                .q-icon {
                    color: $white;
                }
            }
        }
        // Fix scroll between steps in Firefox
        .q-stepper__content .q-panel.scroll {
            overflow: hidden;
        }
    }
}
</style>
