<template>
    <div>
        <div
            class="column q-col-gutter-md q-pa-md"
        >
            <div class="col-sm-12">
                <WText
                    align="center"
                    weight="bold"
                    color="primary"
                >
                    {{ $t('visualization.filters.analysisContext') }}
                </WText>
            </div>
            <div class="col-sm-12">
                <QBtnToggle
                    v-model="displayMode"
                    noCaps
                    spread
                    unelevated
                    toggleColor="primary"
                    :options="[
                        {value: 'total', slot: 'one'},
                        {value: 'filtered', slot: 'two'},
                    ]"
                >
                    <template v-slot:one>
                        <div class="row items-center">
                            <div class="text-center">
                                {{ $t('visualization.filters.contextualInfo.total') }}
                            </div>
                            <QIcon
                                right
                                name="storage"
                            />
                        </div>
                    </template>
                    <template v-slot:two>
                        <div class="row items-center">
                            <div class="text-center">
                                {{ $t('visualization.filters.contextualInfo.filtered') }}
                            </div>
                            <QIcon
                                right
                                name="filter_alt"
                            />
                        </div>
                    </template>
                </QBtnToggle>
            </div>
            <div
                class="col-sm-12"
            >
                <div class="row q-col-gutter-sm">
                    <div class="col-sm-6">
                        <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.cases`) }}</span>
                        <QCard
                            flat
                            class="bg-grey-2"
                        >
                            <div class="q-pa-sm text-center text-h6 text-weight-bold">
                                {{ dictFormattedData.cases[`${displayMode}Data`] }}
                            </div>
                            <QLinearProgress
                                size="25px"
                                :value="parseFloat(dictFormattedData.cases[`${displayMode}DataPercentage`])"
                                color="primary"
                                trackColor="transparent"
                            >
                                <div class="absolute-full flex flex-center">
                                    <QBadge
                                        color="white"
                                        textColor="primary"
                                        :label="`${(dictFormattedData.cases[`${displayMode}DataPercentage`]*100).toFixed(0)}%`"
                                    />
                                </div>
                            </QLinearProgress>
                        </QCard>
                    </div>
                    <div class="col-sm-6">
                        <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.variants`) }}</span>
                        <QCard
                            flat
                            class="bg-grey-2"
                        >
                            <div class="q-pa-sm text-center text-h6 text-weight-bold">
                                {{ dictFormattedData.variants[`${displayMode}Data`] }}
                            </div>
                            <QLinearProgress
                                size="25px"
                                :value="parseFloat(dictFormattedData.variants[`${displayMode}DataPercentage`])"
                                color="primary"
                                trackColor="transparent"
                            >
                                <div class="absolute-full flex flex-center">
                                    <QBadge
                                        color="white"
                                        textColor="primary"
                                        :label="`${(dictFormattedData.variants[`${displayMode}DataPercentage`]*100).toFixed(0)}%`"
                                    />
                                </div>
                            </QLinearProgress>
                        </QCard>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.duration`) }}</span>
                <div class="row q-col-gutter-sm items-center">
                    <div class="col-sm-7">
                        <QCard
                            flat
                            class="bg-grey-2"
                        >
                            <div class="q-pa-sm text-center text-h6 text-weight-bold">
                                {{ dictFormattedData[`${durMode.value}`][`${displayMode}Data`] }}
                            </div>
                        </QCard>
                    </div>
                    <div class="col-sm-5 text-center">
                        <QFab
                            v-model="displayDurationOptions"
                            :label="durMode.value"
                            square
                            flat
                            labelPosition="left"
                            color="grey-2"
                            textColor="primary"
                            icon="keyboard_arrow_down"
                            direction="down"
                            padding="sm"
                        >
                            <QFabAction
                                v-for="option in durOptions"
                                :key="option.value"
                                textColor="primary"
                                color="grey-2"
                                :label="option.label"
                                padding="xs"
                                @click="setDurationOption(option)"
                            />
                        </QFab>
                    </div>
                </div>
            </div>
            <div
                class="col-sm-12"
            >
                <div class="row q-col-gutter-sm">
                    <div class="col-sm-6">
                        <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.minStartDateTime`) }}</span>
                        <QInput
                            v-model="dictFormattedData.minStartDateTime[`${displayMode}Data`]"
                            :disable="readOnly"
                            readonly
                            class="custom-cursor"
                            @click="openStartDateModal=true"
                        >
                            <QIcon
                                name="event"
                                class="cursor-pointer self-center"
                            >
                                <QPopupProxy
                                    v-model="openStartDateModal"
                                    transitionShow="scale"
                                    transitionHide="scale"
                                >
                                    <QDate
                                        v-if="datetimeFilters"
                                        v-model="filterDate.start"
                                        mask="YYYY/MM/DD"
                                        :options="date => date < filterDate.end && date > formatDateString(dictFormattedData.minStartDateTime.totalData)"
                                        @input="((date)=> inputDate(date, 'TRACE_STARTS_AFTER'))"
                                    >
                                        <div class="row items-center justify-end">
                                            <WButton
                                                label="$t('visualization.filters.reset')"
                                                size="sm"
                                                iconRight="rotate-ccw"
                                                :iconSize="10"
                                                :disabled="readOnly"
                                                @click="resetFilters"
                                            >
                                                {{ $t(`visualization.filters.reset`) }}
                                            </WButton>
                                        </div>
                                    </QDate>
                                </QPopupProxy>
                            </QIcon>
                        </QInput>
                    </div>
                    <div class="col-sm-6">
                        <span class="text-weight-bold">{{ $t(`visualization.filters.contextualInfo.maxEndDateTime`) }}</span>
                        <QInput
                            v-model="dictFormattedData.maxEndDateTime[`${displayMode}Data`]"
                            :disable="readOnly"
                            readonly
                            class="custom-cursor"
                            @click="openEndDateModal=true"
                        >
                            <QIcon
                                name="event"
                                class="cursor-pointer self-center"
                            >
                                <QPopupProxy
                                    v-model="openEndDateModal"
                                    transitionShow="scale"
                                    transitionHide="scale"
                                >
                                    <QDate
                                        v-if="datetimeFilters"
                                        v-model="filterDate.end"
                                        mask="YYYY/MM/DD"
                                        :options="date => date > filterDate.start && date < formatDateString(dictFormattedData.maxEndDateTime.totalData)"
                                        @input="((date) => inputDate(date, 'TRACE_ENDS_BEFORE'))"
                                    >
                                        <div class="row items-center justify-end">
                                            <WButton
                                                label="$t('visualization.filters.reset')"
                                                size="sm"
                                                iconRight="rotate-ccw"
                                                :iconSize="10"
                                                :disabled="readOnly"
                                                @click="resetFilters"
                                            >
                                                {{ $t(`visualization.filters.reset`) }}
                                            </WButton>
                                        </div>
                                    </QDate>
                                </QPopupProxy>
                            </QIcon>
                        </QInput>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import 'moment-duration-format'
import { filtersMixin, filtersStorageMixin } from '@/mixins'
import { DATE_FILTERS } from '../../sections/FilterModal/filtersEntity'
import { Api, apiRequest, notifyError } from '@/api'

export default {
    name: 'ContextualInfo',
    components: {
    },
    inject: ['App'],
    mixins: [filtersMixin, filtersStorageMixin],
    props: {
        totalInfo: VueTypes.object,
        filteredInfo: VueTypes.object,
        totalFilters: VueTypes.number,
        readOnly: VueTypes.bool.def(false),
    },
    data () {
        return {
            displayMode: 'total',
            openStartDateModal: false,
            openEndDateModal: false,
            datetimeFilters: undefined,
            selectedFilter: null,
            filterDate: {
                start: null,
                end: null,
            },
            durMode: {
                label: this.$t('visualization.filters.contextualInfo.avg'),
                value: 'avg',
            },
            displayDurationOptions: false,
        }
    },
    computed: {
        formattedData () {
            const {
                totalInfo, filteredInfo, getDurationFormattedData, getFormattedData,
            } = this
            return [
                {
                    name: 'cases',
                    icon: 'trace',
                    ...getFormattedData('cases'),
                },
                {
                    name: 'variants',
                    icon: 'variant',
                    ...getFormattedData('variants'),
                },
                {
                    name: 'avg',
                    icon: 'meanDuration',
                    ...getDurationFormattedData('avg'),
                },
                {
                    name: 'sd',
                    icon: 'deviationDuration',
                    ...getDurationFormattedData('sd'),
                },
                {
                    name: 'min',
                    icon: 'minDuration',
                    ...getDurationFormattedData('min'),
                },
                {
                    name: 'max',
                    icon: 'maxDuration',
                    ...getDurationFormattedData('max'),
                },
                {
                    name: 'minStartDateTime',
                    icon: 'start_date',
                    totalData: this.App.dateLocationFormat(totalInfo.minStartDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.minStartDateTime, 'short'),
                },
                {
                    name: 'maxEndDateTime',
                    icon: 'end_date',
                    totalData: this.App.dateLocationFormat(totalInfo.maxEndDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.maxEndDateTime, 'short'),
                },
            ]
        },
        dictFormattedData () {
            const {
                totalInfo, filteredInfo, getDurationFormattedData, getFormattedData,
            } = this
            return {
                cases: getFormattedData('cases'),
                variants: getFormattedData('variants'),
                med: getDurationFormattedData('med'),
                avg: getDurationFormattedData('avg'),
                sd: getDurationFormattedData('sd'),
                min: getDurationFormattedData('min'),
                max: getDurationFormattedData('max'),
                minStartDateTime: {
                    totalData: this.App.dateLocationFormat(totalInfo.minStartDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.minStartDateTime, 'short'),
                },
                maxEndDateTime: {
                    totalData: this.App.dateLocationFormat(totalInfo.maxEndDateTime, 'short'),
                    filteredData: this.App.dateLocationFormat(filteredInfo.maxEndDateTime, 'short'),
                },
            }
        },
        durOptions () {
            return [
                {
                    label: this.$t('visualization.filters.contextualInfo.med'),
                    value: 'med',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.avg'),
                    value: 'avg',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.sd'),
                    value: 'sd',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.min'),
                    value: 'min',
                },
                {
                    label: this.$t('visualization.filters.contextualInfo.max'),
                    value: 'max',
                },
            ]
        },
    },
    watch: {
        totalFilters: {
            handler (newValue, oldValue) {
                if (newValue === 0) this.displayMode = 'total'
                else this.displayMode = 'filtered'
            },
            immediate: true,
        },
        'dictFormattedData.minStartDateTime.totalData': {
            handler (newValue, oldValue) {
                if (newValue !== 'No data') {
                    this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
                }
            },
        },
        'dictFormattedData.maxEndDateTime.totalData': {
            handler (newValue, oldValue) {
                if (newValue !== 'No data') {
                    this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
                }
            },
        },
    },
    mounted () {
        this.getDateFilters()
        this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
        this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
    },
    methods: {
        setDurationOption (option) {
            this.durMode = option
        },
        getDurationFormattedData (key) {
            const { totalInfo, filteredInfo } = this
            return {
                totalData: totalInfo.duration ? moment.duration(totalInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
                filteredData: filteredInfo.duration ? moment.duration(filteredInfo.duration[key], 'seconds').format('d[d] h[h]:m[m]:s[s]', { largest: 2, trim: false }) : '-',
            }
        },
        getFormattedData (key) {
            const {
                totalInfo, filteredInfo, numFormatter, formatPercentage,
            } = this
            return {
                totalData: this.App.numberLocationFormat(numFormatter(totalInfo[key])),
                totalDataPercentage: `${formatPercentage(totalInfo[key], totalInfo[key])}`,
                totalDataTooltip: totalInfo[key] !== numFormatter(totalInfo[key]) ? totalInfo[key] : '',
                filteredData: this.App.numberLocationFormat(numFormatter(filteredInfo[key])),
                filteredDataPercentage: `${formatPercentage(filteredInfo[key], totalInfo[key])}`,
                filteredDataTooltip: filteredInfo[key] !== numFormatter(filteredInfo[key]) ? filteredInfo[key] : '',
            }
        },
        formatPercentage (value, total) {
            return value ? ((value / total)).toFixed(2) : 0
        },
        numFormatter (num) {
            if (num > 9999 && num < 1000000) {
                return `${(num / 1000).toFixed(1) }K`
            } if (num >= 1000000) {
                return `${(num / 1000000).toFixed(1) }M`
            }
            return num
        },
        addDateFilter (selectedDate, filterType) {
            if (this.filterDate.start >= this.filterDate.end) { return }

            this.selectedFilter = filterType
            const { visualizationFilters } = this

            if (DATE_FILTERS.includes(this.selectedFilter.id) && selectedDate) {
                const dateFilter = {
                    ...this.selectedFilter,
                    key: selectedDate,
                    uuid: `${this.selectedFilter.id}_${Math.floor(Math.random() * 10000)}`,
                    hidden: true,
                }
                const oldDateFilter = visualizationFilters.find(filter => filter.id === this.selectedFilter.id)
                if (oldDateFilter) {
                    this.updateFilters(oldDateFilter)
                }
                this.addVisualizationFilters(dateFilter)
                this.$emit('onApplyFilter')
                this.openDateModal = false
            }
        },
        resetFilters () {
            const { visualizationFilters } = this
            const afterStartDateFilter = visualizationFilters.find(filter => filter.id === 'TRACE_STARTS_AFTER')
            const beforeEndDateFilter = visualizationFilters.find(filter => filter.id === 'TRACE_ENDS_BEFORE')
            afterStartDateFilter && this.updateFilters(afterStartDateFilter)
            beforeEndDateFilter && this.updateFilters(beforeEndDateFilter)
            this.openStartDateModal = false
            this.openEndDateModal = false
            this.$emit('onApplyFilter')
            this.$nextTick(() => {
                this.filterDate.start = this.formatDateString(this.dictFormattedData.minStartDateTime[`${this.displayMode}Data`])
                this.filterDate.end = this.formatDateString(this.dictFormattedData.maxEndDateTime[`${this.displayMode}Data`])
            })
        },
        getDateFilters () {
            apiRequest(Api().visualizations.filters())
                .then((res) => {
                    const filtersIds = (this.visualizationFilters || []).map(({ id }) => id)
                    const sanitizedFilters = this.formatFilters(res)
                    const formattedFilters = sanitizedFilters.map(filter => (filtersIds.includes(filter.id) && !filter.repeatable ? { ...filter, readOnly: true } : filter))
                    const datetimeFilters = formattedFilters.filter(filter => filter.kind === 'DATETIME')
                    this.datetimeFilters = datetimeFilters
                })
                .catch(notifyError)
                .finally(() => (this.isLoading = false))
        },
        formatFilters (filters = []) {
            return filters.map(({
                id, label, kind, repeatable,
            }) => ({
                kind, id, label, repeatable,
            }))
        },
        inputDate (dateString, filterType) {
            this.addDateFilter(dateString, this.datetimeFilters.find(filter => filter.id === filterType))
            filterType === 'TRACE_STARTS_AFTER'
                ? this.openStartDateModal = false
                : this.openEndDateModal = false
        },
        formatDateString (dateString) {
            const parts = dateString.split('/')
            const day = parts[0]
            const month = parts[1]
            const year = parts[2]
            return `${year}/${month}/${day}`
        },
    },
}
</script>
<style lang="scss">
     .custom-cursor.q-field--readonly.q-field--float .q-field__native, .custom-cursor.q-field--readonly.q-field--float .q-field__input {
        cursor: pointer;
    }
</style>
