import Transforms from './transforms'

const endpoints = {
    list: {
        path: `/user-management`,
        transform: Transforms.list,
    },
    create: {
        method: 'POST',
        path: `/user-management`,
    },
    cancel: {
        method: 'POST',
        path: `/user-management/:userInvitationUid/cancel`,
    },
    revoke: {
        method: 'POST',
        path: `/user-management/:userInvitationUid/revoke`,
    },
}

export default {
    name: 'userManagement',
    endpoints,
}
