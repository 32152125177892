export default {
    user: {
        name: {
            label: 'Nombre',
            placeholder: '',
        },
        surname: {
            label: 'Apellidos',
            placeholder: '',
        },
        email: {
            label: 'Correo electrónico',
            placeholder: '',
        },
        locale: {
            label: 'Idioma',
            placeholder: '',
        },
        locationFormat: {
            label: 'Formato de fechas y números',
            placeholder: '',
        },

        locales: {
            en: 'Inglés',
            es: 'Español',
        },
        locationFormats: {
            en: 'Americano',
            eu: 'Europeo',
        },

        date: 'Fecha',
        number: 'Número',

        submit: 'Guardar',
    },
}
