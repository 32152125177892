<template>
    <AppProvider #default="{ loading }">
        <main class="Container">
            <div
                v-if="!loading"
                class="Wrapper"
            >
                <Transition
                    name="main-router"
                    mode="out-in"
                >
                    <RouterView />
                </Transition>
            </div>
            <WLoading
                v-else
                visible
                position="fixed"
            />
        </main>
    </AppProvider>
</template>
<script>
import { AppProvider } from '@/providers'

export default {
    name: 'MainLayout',
    components: {
        AppProvider,
    },
}
</script>
<style scoped lang="scss">
.Container {
    position: relative;
    width: 100%;
    min-height: 100vh;
}
</style>
