export default api => ({
    list: params => api.get('/conformance-queries', { params }),
    create: params => api.post(`/conformance-queries`, params),
    get: ({ queryId }) => api.get(`/conformance-queries/${queryId}`),
    update: ({ queryId, params }) => api.put(`/conformance-queries/${queryId}`, params),
    delete: ({ queryId }) => api.delete(`/conformance-queries/${queryId}`),
    cancel: ({ queryId }) => api.post(`/conformance-queries/${queryId}/cancel`),
    download: ({ queryId }) => api.get(`/conformance-queries/${queryId}/download`, null, { timeout: 1500000 }),
    dashboard: ({ queryId }) => api.get(`/conformance-queries/${queryId}/dashboard`),
    conformanceChart: ({ queryId }) => api.get(`/data-navigator/views/conformance/${queryId}`),
    modes: () => api.get(`/conformance-queries/modes`),
    modeReportTypes: ({ modeId }) => api.get(`/conformance-queries/modes/${modeId}/report-types`),
})
