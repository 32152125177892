<template>
    <div class="MainContainer">
        <div
            class="PerformanceEvolutionContainer"
        >
            <WLoading
                v-if="isLoadingEvCont"
                class="WLoading"
                visible
            />
            <div
                v-else
                class="PerformanceEvolutionTop"
            >
                <strong class="GraphTitle">{{ $t('booster.stateView.evolutionOverTime') }}</strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="selectedRange.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="range"
                        @input="performanceEvolution"
                    />
                </div>
            </div>
            <div class="PerformanceEvolutionChartContainer">
                <v-chart
                    v-if="!emptyEvolutionOT"
                    :option="performanceEvolutionOptions"
                    class="Chart"
                    autoresize
                />
                <div
                    v-if="emptyEvolutionOT"
                    class="Empty"
                >
                    {{ $t('booster.stateView.noTraces') }}
                </div>
            </div>
        </div>
        <div class="GraphChartContainers">
            <WLoading
                v-if="isLoadingPerfComp"
                class="WLoading"
                visible
            />
            <div
                v-else
                class="GraphChartContainersTop"
            >
                <strong class="GraphTitle"> {{ tab }} {{ $t('booster.stateView.performanceComparison') }} </strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="sortDirectionPerfComp.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="sort"
                        @input="asignData(perfCompResult)"
                    />
                </div>
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="optionSel"
                        toggleColor="primary"
                        dense
                        noCaps
                        size="0.8em"
                        padding="2px 5px"
                        :options="options"
                        @input="asignData(perfCompResult)"
                    />
                </div>
            </div>
            <div class="ChartContainer">
                <v-chart
                    v-if="!emptyPerfComparison"
                    ref="echartsRef"
                    :option="performanceCompOptions"
                    autoresize
                    class="Chart"
                />
                <div
                    v-if="emptyPerfComparison"
                    class="Empty"
                >
                    {{ $t('booster.stateView.noTraces') }}
                </div>
            </div>
        </div>
        <div class="GraphChartContainers BottomContainer">
            <WLoading
                v-if="isLoadingLeadTimes"
                class="WLoading"
                visible
            />
            <div
                v-else
                class="GraphChartContainersTop"
            >
                <strong class="GraphTitle"> {{ tab }} {{ $t('booster.stateView.leadTimes') }} </strong>
                <QSpace />
                <div class="FilterValuesContainer">
                    <QBtnToggle
                        v-model="sortDirectionLeadTimes.value"
                        toggleColor="primary"
                        noCaps
                        dense
                        size="0.8em"
                        padding="2px 2px"
                        :options="sort"
                        @input="leadTimes"
                    />
                </div>
            </div>
            <div class="ChartContainer">
                <v-chart
                    v-if="!emptyLeadTimes"
                    autoresize
                    :option="leadTimesOptions"
                    class="Chart"
                />
                <div
                    v-if="emptyLeadTimes"
                    class="Empty"
                >
                    {{ $t('booster.stateView.noTraces') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import moment from 'moment'
import { Api, apiRequest, notifyError } from '@/api'

const bluePalette = ['#33699a', '#84b6f4', '#c4dafa', '#023047', '#a9d3ff']

export default {
    name: 'PerformanceByResource',
    components: {
    },
    props: {
        tab: VueTypes.string,
        selectedOptions: VueTypes.object,
    },
    data () {
        return {
            isLoadingEvCont: false,
            isLoadingPerfComp: false,
            isLoadingLeadTimes: false,
            maxDur: undefined,
            leftPerformanceCom: undefined,
            emptyPerfComparison: false,
            emptyLeadTimes: false,
            emptyEvolutionOT: false,
            perfCompResult: undefined,
            newPerformanceComparison: undefined,
            sortDirectionPerfComp: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sortDirectionLeadTimes: {
                value: 'ASC',
                icon: 'arrow_upward',
            },
            sort: [{
                value: 'ASC',
                icon: 'arrow_upward',
            },
            {
                value: 'DESC',
                icon: 'arrow_downward',
            },
            ],
            selectedRange: {
                value: 'MONTHS',
                label: this.$t('booster.stateView.months'),
            },
            range: [{
                value: 'MONTHS',
                label: this.$t('booster.stateView.months'),
            },
            {
                value: 'WEEKS',
                label: this.$t('booster.stateView.weeks'),
            },
            ],
            currentUnit: undefined,
            graphResponse: {},
            slaData: [],
            optionSel: 'SLACOMPLIANCEFREQ',
            options: [
                {
                    value: 'SLACOMPLIANCEFREQ',
                    label: 'abs',
                },
                {
                    value: 'SLACOMPLIANCEPERCENTAGE',
                    label: '%',
                },
            ],
            performanceEvolutionOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div style="max-width: 15em;">'
                        tooltipContent += `${this.lineBreak(params[0].name)}`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            let color
                            // eslint-disable-next-line prefer-destructuring
                            param.seriesName === 'SLA' ? color = 'red' : color = param.color
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${seriesName}: ${moment.duration(value, this.maxDur).format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                legend: {
                    formatter (name) {
                        if (name.length > 10) {
                            return `${name.substring(0, 10) }...`
                        }
                        return name
                    },
                },
                grid: {
                    left: 50,
                    right: 50,
                    bottom: 20,
                    top: 30,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    splitLine: {
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                series: [],
            },
            leadTimesOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 60)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            tooltipContent += `${seriesName}: ${moment.duration(value, 'seconds').format('d[d] h[h]:m[m]:s[s]',
                                { largest: 2, trim: false })} <br>`
                            tooltipContent += '</div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    left: 95,
                    right: 10,
                    bottom: 20,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        minValueSpan: 3,
                        maxValueSpan: 3,
                        width: 15,
                        showDetail: false,
                        showDataShadow: false,
                        handleSize: '100%',
                        start: 100,
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                    },
                ],
                series: [
                    {
                        name: this.$t('booster.stateView.above'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        silent: true,
                    },
                    {
                        name: this.$t('booster.stateView.below'),
                        type: 'bar',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#7FFEA8',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 10,
                        silent: true,
                    },
                ],
            },
            performanceCompOptions: {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    formatter: (params) => {
                        let tooltipContent = '<div>'
                        const name = this.createSubstring(params[0].name, 60)
                        tooltipContent += `${(name.join('<br>'))}<br>`
                        params.forEach((param) => {
                            const { seriesName } = param
                            const { value } = param
                            const { color } = param
                            tooltipContent += `<div style="width: 10px; height: 10px; background-color: ${color};border-radius: 50%; display: inline-block; margin-right: 3px;">
                                </div>`
                            this.optionSel === 'SLACOMPLIANCEFREQ' ? tooltipContent += `${seriesName}: ${value} ${this.$t('booster.stateView.cases')}`
                                : tooltipContent += `${seriesName}: ${value} %`
                            tooltipContent += '<br></div>'
                        })
                        return tooltipContent
                    },
                },
                grid: {
                    right: 10,
                    bottom: 20,
                    top: 15,
                },
                xAxis: {
                    show: false,
                    max: 100,
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter (value) {
                            const maxLength = 12
                            if (value.length > maxLength) {
                                return `${value.substring(0, maxLength) }...`
                            // eslint-disable-next-line no-else-return
                            } else {
                                return value
                            }
                        },
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        yAxisIndex: [0],
                        minValueSpan: 3,
                        maxValueSpan: 3,
                        width: 15,
                        showDetail: false,
                        showDataShadow: false,
                        handleSize: '100%',
                        start: 100,
                    },
                    {
                        type: 'inside',
                        yAxisIndex: [0],
                    },
                ],
                series: [
                    {
                        name: this.$t('booster.stateView.above'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            borderColor: '#F40019',
                            color: '#FF8084',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 20,
                        silent: true,
                    },
                    {
                        name: this.$t('booster.stateView.below'),
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                            formatter: {},
                        },
                        itemStyle: {
                            borderColor: '#00C383',
                            color: '#7FFEA8',
                            borderRadius: [3, 3, 3, 3],
                        },
                        data: [],
                        barWidth: 20,
                        silent: true,
                    },
                ],
            },
        }
    },
    watch: {
        // eslint-disable-next-line func-names
        selectedOptions: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.performanceEvolution()
            this.performanceComparison()
            this.leadTimes()
        },
        performanceEvolution () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                temporalUnit: this.selectedRange.value,
            }
            const { processId } = this.$route.params
            this.isLoadingEvCont = true
            apiRequest(Api().booster.evolutionOverTime({ processId, params }))
                .then((data) => {
                    if (data.rows.length > 0) {
                        this.emptyEvolutionOT = false
                        this.graphResponse = data
                        const { rows } = data
                        const series = []
                        rows.forEach((row, i) => {
                            const seriesData = {
                                name: row.value,
                                type: 'line',
                                data: [],
                                lineStyle: {
                                    color: bluePalette[i % bluePalette.length],
                                },
                                itemStyle: {
                                    color: bluePalette[i % bluePalette.length],
                                },
                            }
                            row.rows.sort((a, b) => {
                                const dateA = new Date(a.timeString)
                                const dateB = new Date(b.timeString)
                                return dateA - dateB
                            })
                            this.maxDur = this.rangeDuration(Math.max.apply(null, [row.rows.map(item => (item.duration))][0]))
                            row.rows.forEach((item) => {
                                seriesData.data.push(this.formatSeconds(item.duration, this.maxDur))
                            })
                            series.push(seriesData)
                        })
                        this.performanceEvolutionOptions.series = series
                        this.performanceEvolutionOptions.xAxis.data = rows[0].rows.map(item => item.timeString)
                        this.performanceEvolutionOptions.series.push({
                            name: 'SLA',
                            type: 'line',
                            symbol: 'none',
                            lineStyle: {
                                color: 'red',
                                type: 'dotted',
                            },
                            itemStyle: {
                                color: 'red',
                            },
                            data: Array.from({ length: rows[0].rows.length }, () => this.formatSeconds(data.sla, this.maxDur)),
                        })
                    } else {
                        this.emptyEvolutionOT = true
                    }
                })
                .catch()
                .finally(() => (this.isLoadingEvCont = false))
        },
        performanceComparison () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                sortBy: this.optionSel,
                sortDirection: this.sortDirectionPerfComp.value,
            }
            const { processId } = this.$route.params
            this.isLoadingPerfComp = true
            apiRequest(Api().booster.performanceComparison({ processId, params }))
                .then((data) => {
                    this.perfCompResult = data
                    if (data.rows.length > 0) {
                        this.emptyPerfComparison = false
                        this.asignData(data)
                        const maxLetters = this.performanceCompOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                        this.leftPerformanceCom = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                        this.performanceCompOptions.grid.left = this.leftPerformanceCom + 16
                    } else {
                        this.emptyPerfComparison = true
                    }
                })
                .catch()
                .finally(() => (this.isLoadingPerfComp = false))
        },
        asignData (data) {
            if (this.optionSel === 'SLACOMPLIANCEFREQ') {
                if (this.sortDirectionPerfComp.value === 'ASC') {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => a.slaNonComplianceAbsolute - b.slaNonComplianceAbsolute)
                } else {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => b.slaNonComplianceAbsolute - a.slaNonComplianceAbsolute)
                }
                this.performanceCompOptions.yAxis.data = this.newPerformanceComparison.map(item => item.content)
                this.performanceCompOptions.series[1].data = this.newPerformanceComparison.map(item => item.slaComplianceAbsolute)
                this.performanceCompOptions.series[0].data = this.newPerformanceComparison.map(item => item.slaNonComplianceAbsolute)
                this.performanceCompOptions.xAxis.max = undefined
            } else {
                // case percentage
                if (this.sortDirectionPerfComp.value === 'ASC') {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => a.slaNonCompliancePercentage - b.slaNonCompliancePercentage)
                } else {
                    this.newPerformanceComparison = data.rows.slice().sort((a, b) => b.slaNonCompliancePercentage - a.slaNonCompliancePercentage)
                }
                this.performanceCompOptions.yAxis.data = this.newPerformanceComparison.map(item => item.content)
                this.performanceCompOptions.series[1].data = this.newPerformanceComparison.map(item => item.slaCompliancePercentage)
                this.performanceCompOptions.series[0].data = this.newPerformanceComparison.map(item => item.slaNonCompliancePercentage)
                this.performanceCompOptions.xAxis.max = 100
            }
        },
        leadTimes () {
            const params = {
                attribute: this.tab,
                activities: this.selectedOptions.activity ?? [],
                variants: this.selectedOptions.variant ?? [],
                transitions: this.selectedOptions.transition ?? [],
                sortBy: 'SLANONCOMPLIANCEAVG',
                sortDirection: this.sortDirectionLeadTimes.value,
            }
            const { processId } = this.$route.params
            this.isLoadingLeadTimes = true
            apiRequest(Api().booster.attributeLeadTimes({ processId, params }))
                .then((data) => {
                    if (data.rows.length > 0) {
                        this.emptyLeadTimes = false
                        this.leadTimesOptions.yAxis.data = data.rows.map(item => item.content)
                        const maxLetters = this.leadTimesOptions.yAxis.data.reduce((max, current) => Math.max(max, current.length), 0)
                        this.leftPerformanceCom = maxLetters > 12 ? 12 * 8 : maxLetters * 8
                        this.leadTimesOptions.grid.left = this.leftPerformanceCom + 16
                        this.leadTimesOptions.series[1].data = data.rows.map(item => item.slaComplianceAvg)
                        this.leadTimesOptions.series[0].data = data.rows.map(item => item.slaNonComplianceAvg)
                        this.leadTimesOptions.xAxis.max = undefined
                    } else {
                        this.emptyLeadTimes = true
                    }
                })
                .catch(notifyError)
                .finally(() => (this.isLoadingLeadTimes = false))
        },
        rangeDuration (seconds) {
            let rangeDur = ''
            switch (true) {
                case seconds >= 60 * 60 * 24:
                    rangeDur = 'days'
                    this.currentUnit = 'd'
                    break
                case seconds >= 60 * 60:
                    rangeDur = 'hours'
                    this.currentUnit = 'h'
                    break
                case seconds >= 60:
                    rangeDur = 'minutes'
                    this.currentUnit = 'm'
                    break
                default:
                    rangeDur = 'seconds'
                    this.currentUnit = 's'
            }
            return rangeDur
        },
        formatSeconds (seconds, rangeDur) {
            let dur = 0
            switch (rangeDur) {
                case 'days':
                    dur = moment.duration(seconds, 'seconds').asDays()
                    break
                case 'hours':
                    dur = moment.duration(seconds, 'seconds').asHours()
                    break
                case 'minutes':
                    dur = moment.duration(seconds, 'seconds').asMinutes()
                    break
                default:
                    dur = seconds
            }
            return dur
        },
        lineBreak (inputString) {
            let resultado = ''
            for (let i = 0; i < inputString.length; i += 22) {
                const linea = inputString.substr(i, 22)
                resultado += `${linea }<br>`
            }
            return resultado
        },
        createSubstring (text, large) {
            const substrings = []
            let start = 0
            while (start < text.length) {
                let end = start + large
                if (end >= text.length) {
                    end = text.length
                } else {
                    const space = text.indexOf(' ', start + large)
                    const dash = text.indexOf('-', start + large)
                    const underscore = text.indexOf('-', start + large)
                    if (space !== -1 && space < end) {
                        end = space
                    } else if (dash !== -1 && dash < end) {
                        end = dash
                    } else if (underscore !== -1 && underscore < end) {
                        end = underscore
                    } else {
                        end = start + large
                    }
                }
                substrings.push(text.substring(start, end))
                start = end
            }
            return substrings
        },
    },
}
</script>
<style scoped lang="scss">
.MainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.Select {
    width: fit-content;
}
.Empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ChartContainer {
    width: 100%;
    margin:0 auto;
    flex-grow: 1;
    white-space: nowrap;
    background-color: #fbfbfb;
    overflow-x: hidden;
    overflow-y: scroll;
    .Chart {
        width: 100%;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }
}
.WLoading {
    max-height: 33%;
}
.PerformanceEvolutionContainer {
    flex-grow: 1;
    margin-top:2%;
    max-height: 33%;
    background-color: #fbfbfb;
}
.PerformanceEvolutionTop {
    display: flex;
    padding:1vh
}
.PerformanceEvolutionChartContainer {
    width: 100%;
    margin:0 auto;
    height: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: scroll;
    .Chart {
        width: 100%;
        height: 77%;
        & + .chart {
          margin-left: 30px;
          @media screen and (max-width: $lg) {
               margin-left: 0px;
          }
        }
  }

}
.BottomContainer {
    margin-bottom: 10px;
}
.GraphTitle {
    color:#33699a;
}
.GraphChartContainers {
    height: 31%;
    margin-top:1.5%;
    display:flex;
    flex-direction:column;
}
.GraphChartContainersTop {
    display: flex;
    height: fit-content;
    background-color: #fbfbfb;
    padding: 1vh;
}
.FilterValuesContainer {
    display: flex;
    align-items:center;
    justify-content:center;
    margin-right: 10px;
}
::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #DDDDDD;
    border-radius: 10px;
  }
</style>
