var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SettingsContainer" }, [
    _c(
      "div",
      {
        staticClass: "Settings flex row-left-center",
        class: {
          closeSettings:
            !_vm.openSlaSettings &&
            !_vm.openCCSettings &&
            !_vm.openCaseCompletionSettings &&
            !_vm.openPredictionAlgorithms,
          openSettings:
            _vm.openSlaSettings ||
            _vm.openCCSettings ||
            _vm.openCaseCompletionSettings ||
            _vm.openPredictionAlgorithms
        }
      },
      [
        _c(
          "WCard",
          {
            class: { "container-open": _vm.openSlaSettings },
            attrs: { shadow: "" }
          },
          [
            _c(
              "div",
              {
                staticClass: "CardInfo row-left-center",
                on: {
                  click: function($event) {
                    _vm.handleSLASettings()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "Item flex row-left-center flex-nowrap card-container"
                  },
                  [
                    _c("WIcon", {
                      attrs: { icon: "clock", color: "primary", size: 40 }
                    }),
                    _c(
                      "div",
                      { staticClass: "flex-wrap text-card-container" },
                      [
                        _c(
                          "WText",
                          {
                            staticClass: "pl-1",
                            attrs: { typo: "h3", tag: "p", weight: "bold" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("visualization.settings.sla.name")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.values.sla
                          ? _c(
                              "WText",
                              {
                                staticClass: "pl-1 slaCost",
                                attrs: {
                                  color: "primary",
                                  tag: "p",
                                  weight: "medium"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.values.sla.value) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.sla.units." +
                                          _vm.values.sla.unit
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "Item rightIcon" },
                  [
                    !_vm.openSlaSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                      : _vm._e(),
                    _vm.openSlaSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "WCard",
          {
            class: { "container-open": _vm.openCaseCompletionSettings },
            attrs: { shadow: "" }
          },
          [
            _c(
              "div",
              {
                staticClass: "CardInfo row-left-center",
                on: {
                  click: function($event) {
                    _vm.handleCaseCompletionSettings()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "Item flex row-left-center flex-nowrap card-container"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "caseCompletionIconContainer" },
                      [
                        _c("WIcon", {
                          attrs: { icon: "trace", color: "primary", size: 50 }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "caseCompletionContainer" },
                      [
                        _c(
                          "WText",
                          {
                            staticClass: "pl-1",
                            attrs: { typo: "h3", tag: "p", weight: "bold" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.settings.caseCompletion.name"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.values.caseCompletion &&
                        _vm.values.caseCompletion.startingActivities.length ===
                          0 &&
                        _vm.values.caseCompletion.occurringActivities.length ===
                          0 &&
                        _vm.values.caseCompletion.endingActivities.length === 0
                          ? _c(
                              "WText",
                              {
                                staticClass: "pl-1 textCost",
                                attrs: {
                                  color: "primary",
                                  tag: "p",
                                  weight: "medium"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.caseCompletionConditionsNoConf"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _c(
                              "WText",
                              {
                                staticClass: "pl-1 textCost",
                                attrs: {
                                  color: "primary",
                                  tag: "p",
                                  weight: "medium"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.caseCompletionConditionsConf"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "Item rightIcon" },
                  [
                    !_vm.openCaseCompletionSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                      : _vm._e(),
                    _vm.openCaseCompletionSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "WCard",
          {
            class: { "container-open": _vm.openCCSettings },
            attrs: { shadow: "" }
          },
          [
            _c(
              "div",
              {
                staticClass: "CardInfo row-left-center",
                on: {
                  click: function($event) {
                    _vm.handleCCSettings()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "Item flex row-left-center flex-nowrap card-container"
                  },
                  [
                    _c("WIcon", {
                      attrs: {
                        icon: "money_increase",
                        color: "primary",
                        size: 40
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "flex-wrap" },
                      [
                        _c(
                          "WText",
                          {
                            staticClass: "pl-1",
                            attrs: { typo: "h3", tag: "p", weight: "bold" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("visualization.settings.costs.name")
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.values.cost
                          ? _c(
                              "WText",
                              {
                                staticClass: "pl-1 textCost",
                                attrs: {
                                  color: "primary",
                                  tag: "p",
                                  weight: "medium"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.App.numberLocationFormat(
                                        _vm.values.cost.value
                                      )
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "visualization.settings.costs.units." +
                                          _vm.values.cost.unit
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "Item rightIcon" },
                  [
                    !_vm.openCCSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                      : _vm._e(),
                    _vm.openCCSettings
                      ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "WCard",
          {
            class: { "container-open": _vm.openPredictionAlgorithms },
            attrs: { shadow: "" }
          },
          [
            _c(
              "div",
              {
                staticClass: "CardInfo row-left-center",
                on: {
                  click: function($event) {
                    _vm.handlePredictionAlgorithm()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "Item flex row-left-center flex-nowrap card-container"
                  },
                  [
                    _c("QIcon", {
                      attrs: {
                        size: "xl",
                        color: "primary",
                        name: "batch_prediction"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "flex-wrap text-card-container" },
                      [
                        _c(
                          "WText",
                          {
                            staticClass: "pl-1",
                            attrs: { typo: "h3", tag: "p", weight: "bold" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "visualization.settings.predictionAlgorithm.name"
                                  )
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.values.sla
                          ? _c(
                              "WText",
                              {
                                staticClass: "pl-1 slaCost",
                                attrs: {
                                  color: "primary",
                                  tag: "p",
                                  weight: "medium"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.values.predictionAlgorithm) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "Item rightIcon" },
                  [
                    !_vm.openPredictionAlgorithms
                      ? _c("WIcon", { attrs: { icon: "chevron-right" } })
                      : _vm._e(),
                    _vm.openPredictionAlgorithms
                      ? _c("WIcon", { attrs: { icon: "chevron-left" } })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c("SlaSettings", {
          ref: "SlaSettings",
          staticClass: "SlaSettings",
          attrs: { isOpen: _vm.openSlaSettings, values: _vm.values.sla },
          on: { SLAConfig: _vm.saveSettings }
        }),
        _c("CostCurrencySettings", {
          ref: "CostCurrencySettings",
          staticClass: "SlaSettings",
          attrs: { values: _vm.values.cost, isOpen: _vm.openCCSettings },
          on: { CostCurrencyConfig: _vm.saveSettings }
        }),
        _c("CaseCompletionConditions", {
          ref: "CaseCompletionConditions",
          staticClass: "caseCompletionSettings",
          attrs: {
            isOpen: _vm.openCaseCompletionSettings,
            values: _vm.values.caseCompletion
          },
          on: { CaseCompletionConditionsConfig: _vm.saveSettings }
        }),
        _c("PredictionAlgorithmSettings", {
          ref: "PredictionAlgorithm",
          staticClass: "SlaSettings",
          attrs: {
            isOpen: _vm.openPredictionAlgorithms,
            values: {
              predictionAlgorithm: _vm.values.predictionAlgorithm,
              sequenceModelStatus: _vm.values.sequenceModelStatus,
              durationModelStatus: _vm.values.durationModelStatus
            }
          },
          on: { PredictionAlgorithm: _vm.saveSettings }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }