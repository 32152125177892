var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    staticClass: "Upload",
    attrs: { isOpen: _vm.opened },
    on: { close: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "WText",
              {
                staticClass: "mb-0",
                attrs: { tag: "h3", size: 16, weight: "semi" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("projects.models.modals.upload.title")) +
                    " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "QForm",
              { staticClass: "align-center q-gutter-md q-ma-md" },
              [
                _c("QInput", {
                  attrs: {
                    label: _vm.$t("projects.models.modals.upload.name"),
                    outlined: "",
                    stackLabel: "",
                    dense: "",
                    rules: [
                      function(val) {
                        return !!val || "Field is required"
                      }
                    ]
                  },
                  model: {
                    value: _vm.modelName,
                    callback: function($$v) {
                      _vm.modelName = $$v
                    },
                    expression: "modelName"
                  }
                }),
                _c("QFile", {
                  attrs: {
                    label: _vm.$t("projects.models.modals.upload.file"),
                    dense: "",
                    stackLabel: "",
                    accept: ".bpmn",
                    maxFileSize: "1048576",
                    rules: [
                      function(val) {
                        return !!val || "Field is required"
                      }
                    ]
                  },
                  on: { rejected: _vm.onRejected },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function() {
                        return [_c("QIcon", { attrs: { name: "attach_file" } })]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.file,
                    callback: function($$v) {
                      _vm.file = $$v
                    },
                    expression: "file"
                  }
                }),
                _c("QBtn", {
                  attrs: {
                    label: _vm.$t("projects.models.modals.upload.submit"),
                    noCaps: "",
                    color: "primary",
                    unelevated: ""
                  },
                  on: { click: _vm.handleUploadModel }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }