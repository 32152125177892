import Transforms from './transforms'

const endpoints = {
    get: {
        path: `/reports/:reportId/:type`,
        transform: Transforms.get,
    },
}

export default {
    name: 'reports',
    endpoints,
}
