var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "UserForm" }, [
    _c("div", { staticClass: "Row" }, [
      _c(
        "div",
        { staticClass: "Field" },
        [
          _c("QInput", {
            ref: "name",
            attrs: {
              lazyRules: "",
              dense: "",
              outlined: "",
              maxlength: "100",
              label: _vm.$t("forms.user.name.label"),
              placeholder: _vm.$t("forms.user.name.placeholder"),
              rules: [
                function(value) {
                  return (
                    _vm.validators.required(value) ||
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("forms.user.name.label")
                    })
                  )
                }
              ]
            },
            model: {
              value: _vm.value.name,
              callback: function($$v) {
                _vm.$set(_vm.value, "name", $$v)
              },
              expression: "value.name"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Field" },
        [
          _c("QInput", {
            ref: "surname",
            attrs: {
              lazyRules: "",
              dense: "",
              outlined: "",
              maxlength: "100",
              label: _vm.$t("forms.user.surname.label"),
              placeholder: _vm.$t("forms.user.surname.placeholder"),
              rules: [
                function(value) {
                  return (
                    _vm.validators.required(value) ||
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("forms.user.surname.label")
                    })
                  )
                }
              ]
            },
            model: {
              value: _vm.value.surname,
              callback: function($$v) {
                _vm.$set(_vm.value, "surname", $$v)
              },
              expression: "value.surname"
            }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "Row" }, [
      _c(
        "div",
        { staticClass: "Field" },
        [
          _c("QInput", {
            ref: "email",
            attrs: {
              lazyRules: "",
              dense: "",
              outlined: "",
              type: "email",
              label: _vm.$t("forms.user.email.label"),
              placeholder: _vm.$t("forms.user.email.placeholder"),
              rules: [
                function(value) {
                  return (
                    _vm.validators.required(value) ||
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("forms.user.email.label")
                    })
                  )
                },
                function(value) {
                  return (
                    _vm.validators.validEmail(value) ||
                    _vm.$t("validation.email", {
                      attribute: _vm.$t("forms.user.email.label")
                    })
                  )
                }
              ]
            },
            model: {
              value: _vm.value.email,
              callback: function($$v) {
                _vm.$set(_vm.value, "email", $$v)
              },
              expression: "value.email"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Field" },
        [
          _c("QSelect", {
            ref: "locale",
            staticClass: "Locales",
            attrs: {
              value: _vm.locale,
              lazyRules: "",
              dense: "",
              outlined: "",
              behavior: "menu",
              label: _vm.$t("forms.user.locale.label"),
              placeholder: _vm.$t("forms.user.locale.placeholder"),
              options: _vm.locales,
              rules: [
                function(value) {
                  return (
                    _vm.validators.required(value) ||
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("forms.user.locale.label")
                    })
                  )
                }
              ]
            },
            on: { input: _vm.handleChangeLocale }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "Row" }, [
      _c(
        "div",
        { staticClass: "Field" },
        [
          _c("QSelect", {
            ref: "locationFormat",
            staticClass: "LocationFormats",
            attrs: {
              value: _vm.locationFormat,
              lazyRules: "",
              dense: "",
              outlined: "",
              behavior: "menu",
              label: _vm.$t("forms.user.locationFormat.label"),
              placeholder: _vm.$t("forms.user.locationFormat.placeholder"),
              options: _vm.locationFormats,
              rules: [
                function(value) {
                  return (
                    _vm.validators.required(value) ||
                    _vm.$t("validation.required", {
                      attribute: _vm.$t("forms.user.locationFormat.label")
                    })
                  )
                }
              ]
            },
            on: { input: _vm.handleChangeLocationFormat },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function(scope) {
                  return [
                    _c(
                      "QItem",
                      _vm._g(
                        _vm._b({}, "QItem", scope.itemProps, false),
                        scope.itemEvents
                      ),
                      [
                        _c(
                          "QItemSection",
                          [
                            _c("QItemLabel", [_vm._v(_vm._s(scope.opt.label))]),
                            _c("QItemLabel", { attrs: { caption: "" } }, [
                              _vm._v(" " + _vm._s(scope.opt.description) + " ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex row-right-center" },
        [
          _c(
            "WButton",
            {
              attrs: { id: "submit", disabled: _vm.disabled },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(" " + _vm._s(_vm.$t("forms.user.submit")) + " ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }