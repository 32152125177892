var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.datasetInfo.state.identifier === "FINISHED"
    ? _c(
        "div",
        [
          _c(
            "WCard",
            { staticClass: "Personal mb-2", attrs: { bordered: "" } },
            [
              _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("projects.datasets.settings.updates.pushTitle")
                    ) +
                    " "
                )
              ]),
              _c("QToggle", {
                attrs: {
                  color: "primary",
                  label: _vm.pushLabel,
                  keepColor: "",
                  disable: ""
                },
                model: {
                  value: _vm.pushValue,
                  callback: function($$v) {
                    _vm.pushValue = $$v
                  },
                  expression: "pushValue"
                }
              })
            ],
            1
          ),
          _vm.datasetInfo.source !== "CSV"
            ? _c(
                "WCard",
                { staticClass: "Personal mb-2", attrs: { bordered: "" } },
                [
                  _c("WText", { staticClass: "mb-1", attrs: { size: 18 } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("projects.datasets.settings.updates.pullTitle")
                        ) +
                        " "
                    )
                  ]),
                  _c("QToggle", {
                    attrs: {
                      color: "primary",
                      label: _vm.$t(
                        "projects.datasets.settings.updates.pullToggle"
                      ),
                      keepColor: ""
                    },
                    model: {
                      value: _vm.pullValue,
                      callback: function($$v) {
                        _vm.pullValue = $$v
                      },
                      expression: "pullValue"
                    }
                  }),
                  _c(
                    "Transition",
                    { attrs: { name: "fade", mode: "out-in", appear: "" } },
                    [
                      _vm.pullValue
                        ? _c("div", { staticClass: "q-pa-md" }, [
                            _c(
                              "div",
                              { staticClass: "row q-gutter-md" },
                              [
                                _c("QInput", {
                                  ref: "freqField",
                                  staticClass: "col-3",
                                  attrs: {
                                    type: "number",
                                    lazyRules: "",
                                    dense: "",
                                    outlined: "",
                                    maxlength: "2",
                                    label: _vm.$t(
                                      "projects.datasets.settings.updates.frequency"
                                    ),
                                    placeholder: _vm.$t(
                                      "projects.datasets.settings.updates.value"
                                    ),
                                    rules: [
                                      function(value) {
                                        return (
                                          _vm.validators.required(value) ||
                                          _vm.$t("validation.required", {
                                            attribute: _vm.$t(
                                              "projects.datasets.settings.updates.value"
                                            )
                                          })
                                        )
                                      },
                                      function(val) {
                                        return (
                                          (val > 0 && val <= 60) ||
                                          _vm.$t(
                                            "projects.datasets.settings.updates.evalValue"
                                          )
                                        )
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.freqValue,
                                    callback: function($$v) {
                                      _vm.freqValue = _vm._n($$v)
                                    },
                                    expression: "freqValue"
                                  }
                                }),
                                _c("QSelect", {
                                  ref: "timeSelect",
                                  staticClass: "col-3",
                                  attrs: {
                                    filled: "",
                                    options: _vm.options,
                                    label: _vm.$t(
                                      "projects.datasets.settings.updates.unit"
                                    ),
                                    stackLabel: "",
                                    dense: "",
                                    rules: [
                                      function(value) {
                                        return (
                                          _vm.validators.required(value) ||
                                          _vm.$t("validation.required", {
                                            attribute: _vm.$t(
                                              "projects.datasets.settings.updates.value"
                                            )
                                          })
                                        )
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.freqGranularity,
                                    callback: function($$v) {
                                      _vm.freqGranularity = $$v
                                    },
                                    expression: "freqGranularity"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.datasetInfo.source !== "CSV"
            ? _c(
                "div",
                { staticClass: "flex row-right-center" },
                [
                  _c("WButton", { on: { click: _vm.handleSubmit } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "projects.datasets.settings.updates.saveChanges"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "QBanner",
            {
              staticClass: "bg-warning text-white",
              scopedSlots: _vm._u([
                {
                  key: "avatar",
                  fn: function() {
                    return [
                      _c("QIcon", {
                        attrs: { name: "warning", color: "white" }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("projects.datasets.settings.updates.notReady")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }