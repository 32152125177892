var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "modal", duration: 500 } }, [
    _vm.isOpen
      ? _c("div", { staticClass: "Modal" }, [
          _c("div", { staticClass: "Dialog" }, [
            _vm.$slots.header
              ? _c(
                  "div",
                  { staticClass: "Header" },
                  [
                    _vm._t("header"),
                    _vm.closeBtn && _vm.dissmisable
                      ? _c("WIcon", {
                          staticClass: "Close",
                          attrs: {
                            clickable: "",
                            icon: "close",
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm.$slots.body
              ? _c("div", { staticClass: "Body" }, [_vm._t("body")], 2)
              : _vm._e(),
            _vm.$slots.footer
              ? _c("div", { staticClass: "Footer" }, [_vm._t("footer")], 2)
              : _vm._e()
          ]),
          _c("div", {
            staticClass: "Backdrop",
            on: {
              click: function($event) {
                _vm.dissmisable ? _vm.$emit("close") : null
              }
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }