var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var loading = ref.loading
          return [
            _c(
              "main",
              { staticClass: "Container" },
              [
                !loading
                  ? _c(
                      "div",
                      { staticClass: "Wrapper" },
                      [
                        _c(
                          "Transition",
                          { attrs: { name: "main-router", mode: "out-in" } },
                          [_c("RouterView")],
                          1
                        )
                      ],
                      1
                    )
                  : _c("WLoading", {
                      attrs: { visible: "", position: "fixed" }
                    })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }