import StepSettings from './StepSettings.vue'
import StepUpload from './StepUpload.vue'
import StepSummary from './StepSummary.vue'
import Common from '../../common'

export default {
    ...Common,
    StepSettings,
    StepUpload,
    StepSummary,
}
