var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Loops pr-1 pl-1 pt-1" },
    [
      _c(
        "div",
        { staticClass: "flex row-stretch-center flex-nowrap" },
        [
          _c("WText", { staticClass: "pr-0-5" }, [
            _vm._v(" " + _vm._s(_vm.$t("visualization.nLoops")) + " ")
          ]),
          _c("QInput", {
            staticClass: "Search search-input float-left",
            attrs: {
              dense: "",
              outlined: "",
              type: "search",
              label: _vm.$t("actions.search"),
              disable: _vm.disabledButton
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSearchLoops(_vm.search)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _vm.search
                      ? _c("WIcon", {
                          attrs: {
                            id: "clean",
                            icon: "close",
                            size: 20,
                            clickable: ""
                          },
                          on: { click: _vm.handleResetSearch }
                        })
                      : _vm._e(),
                    _c("WIcon", {
                      attrs: {
                        id: "search",
                        icon: "search1",
                        size: 20,
                        clickable: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleSearchLoops(_vm.search)
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("Tabs", {
        staticClass: "Tabs",
        attrs: { tabs: _vm.tabs, horizontal: "" },
        on: { input: _vm.handleChangeTab },
        model: {
          value: _vm.tab,
          callback: function($$v) {
            _vm.tab = $$v
          },
          expression: "tab"
        }
      }),
      _c(
        "QTabPanels",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "QTabPanel",
            { staticClass: "model-tab-panel", attrs: { name: _vm.tab } },
            [
              _c(
                "div",
                { staticClass: "scroll" },
                [
                  _vm.isLoading
                    ? _c("WLoading", { attrs: { visible: "" } })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showLoops,
                          expression: "showLoops"
                        }
                      ]
                    },
                    _vm._l(_vm.loops, function(item) {
                      return _c(
                        "a",
                        {
                          key: item.id,
                          staticClass: "Bar mt-0-5",
                          class: { selected: item.id === _vm.activeLoop },
                          attrs: { disabled: _vm.disabledButton, href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleChangeLoop(item.id)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex row-stretch-center" },
                            [
                              _c(
                                "WText",
                                { attrs: { weight: "semi", size: 12 } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.name.replace(
                                          "Loop",
                                          _vm.$t("visualization.loop")
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "WText",
                                { attrs: { weight: "semi", size: 12 } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.App.numberLocationFormat(
                                          _vm.getAbsFrequency(
                                            item.absFrequency
                                          ) * 100
                                        )
                                      ) +
                                      "% "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("QLinearProgress", {
                            staticClass: "LinearProgress",
                            attrs: {
                              size: "10px",
                              value: _vm.getAbsFrequency(item.absFrequency)
                            }
                          }),
                          _c(
                            "QTooltip",
                            {
                              staticClass: "Tooltip",
                              attrs: {
                                maxWidth: "500px",
                                contentClass: "text-primary",
                                contentStyle:
                                  "font-size:13px; background-color:white; border: solid 1px; border-radius: 5px;",
                                anchor: "center right",
                                self: "center left"
                              }
                            },
                            [
                              _c("WText", { attrs: { color: "primary" } }, [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.name.replace(
                                          "Loop",
                                          _vm.$t("visualization.loop")
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _vm._v(
                                  ": " +
                                    _vm._s(_vm.$t("visualization.loopsFound")) +
                                    " " +
                                    _vm._s(item.absFrequency) +
                                    " " +
                                    _vm._s(_vm.$t("visualization.cases")) +
                                    " (" +
                                    _vm._s(
                                      _vm._f("percentage")(
                                        _vm.getAbsFrequency(item.absFrequency)
                                      )
                                    ) +
                                    "%) "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  !_vm.showLoops
                    ? _c(
                        "div",
                        { staticClass: "flex col-center mt-2" },
                        [
                          _c("Badge", {
                            staticClass: "row-center pt-0-5 pb-0-5 mb-0-5",
                            attrs: {
                              icon: "alert-triangle",
                              label: _vm.$t(
                                "visualization.notifications.notFound.text",
                                { type: _vm.$t("visualization.loops") }
                              ),
                              color: "warning",
                              textColor: "black"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.showLoops
                ? _c(
                    "div",
                    { staticClass: "flex row-center pt-1" },
                    [
                      _c("QPagination", {
                        attrs: {
                          max: _vm.pagination.max,
                          directionLinks: true,
                          maxPages: 4,
                          disable: _vm.disabledButton,
                          size: "11.5px",
                          boundaryNumbers: true
                        },
                        on: { input: _vm.handleChangePagination },
                        model: {
                          value: _vm.pagination.start,
                          callback: function($$v) {
                            _vm.$set(_vm.pagination, "start", $$v)
                          },
                          expression: "pagination.start"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c(
                    "WButton",
                    {
                      staticClass: "w-full",
                      class: { "active-filter": _vm.isLoopFilterActive },
                      staticStyle: { "justify-content": "center" },
                      attrs: {
                        outline: "",
                        size: "sm",
                        iconLeft: "filter",
                        iconSize: 10,
                        disabled:
                          _vm.isLoopFilterActive ||
                          _vm.disabledButton ||
                          _vm.emptyLoops
                      },
                      on: { click: _vm.toggleLoopFilter }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isLoopFilterActive
                              ? _vm.$t("visualization.filterLoopApplied")
                              : _vm.$t("visualization.filterLoop")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }