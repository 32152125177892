var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Step" },
    [
      _c("QForm", { ref: "form" }, [
        _c(
          "div",
          { staticClass: "row justify-center items-start" },
          [
            _c("QInput", {
              staticClass: "col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md",
              attrs: {
                autogrow: "",
                clearable: "",
                outlined: "",
                label: _vm.$t("wizard.report.name"),
                lazyRules: "",
                rules: [
                  function(value) {
                    return (
                      _vm.validators.required(value) ||
                      _vm.$t("validation.required", {
                        attribute: _vm.$t("wizard.report.name")
                      })
                    )
                  },
                  function(value) {
                    return (
                      _vm.validators.betweenLength(value, 5, 100) ||
                      _vm.$t("validation.rangeLength", {
                        attribute: _vm.$t("wizard.report.name"),
                        min: 5,
                        max: 100
                      })
                    )
                  },
                  function(value) {
                    return (
                      _vm.validators.allowedChars(value) ||
                      _vm.$t("validation.allowedChars", {
                        attribute: _vm.$t("wizard.report.name")
                      })
                    )
                  }
                ]
              },
              model: {
                value: _vm.value.report.name,
                callback: function($$v) {
                  _vm.$set(_vm.value.report, "name", $$v)
                },
                expression: "value.report.name"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row justify-center items-start" },
          [
            _c("QSelect", {
              staticClass: "col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md",
              attrs: {
                lazyRules: "",
                outlined: "",
                behavior: "menu",
                label: _vm.$t("wizard.report.type"),
                options: _vm.normalizeReportTypes
              },
              model: {
                value: _vm.value.report.type,
                callback: function($$v) {
                  _vm.$set(_vm.value.report, "type", $$v)
                },
                expression: "value.report.type"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row justify-center items-start" },
          [
            _c("QInput", {
              staticClass: "col-12 col-sm-6 col-md-4 col-lg-3 q-mt-md",
              attrs: {
                type: "textarea",
                outlined: "",
                hint: _vm.$t("general.optional"),
                label: _vm.$t("general.description"),
                lazyRules: "",
                maxlength: "500",
                rules: [
                  function(value) {
                    return (
                      _vm.validators.optional(value) ||
                      _vm.validators.maxLength(value, 500) ||
                      _vm.$t("validation.maxLength", {
                        attribute: _vm.$t("general.description"),
                        max: 500
                      })
                    )
                  }
                ]
              },
              model: {
                value: _vm.value.report.description,
                callback: function($$v) {
                  _vm.$set(
                    _vm.value.report,
                    "description",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "value.report.description"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }