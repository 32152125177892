<template>
    <div class="InsightRestrictions pa-1">
        <WText
            tag="span"
        >
            {{ $t('wizard.query.insight.restrictions.description') }}
        </WText>
        <InsightExamples :examples="$t('wizard.query.insight.restrictions.operations')" />
        <WText
            tag="p"
            weight="bold"
            class="mt-1 mb-1"
        >
            {{ $t('wizard.query.insight.restrictions.examples.title') }}
        </WText>
        <WCard shadow>
            <WText
                v-for="e in $t('wizard.query.insight.restrictions.examples.items')"
                :key="e"
                tag="p"
                weight="medium"
            >
                {{ e }}
            </WText>
        </WCard>
    </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import { notifySuccess } from '@/api'
import InsightExamples from './InsightExamples.vue'

export default {
    name: 'InsightRestrictions',
    components: {
        InsightExamples,
    },
    methods: {
        handleCopyToClipboard (example) {
            copy(example)
            notifySuccess(this.$t('wizard.query.insight.restrictions.copy'))
        },
    },
}
</script>
