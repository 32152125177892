export default {
    process: {
        summary: 'General summary',
        alternative: 'Main alternative',
        duration: 'Duration analysis',
        frequency: 'Frequency analysis',

        processAlternatives: 'Process alternatives',
        activities: 'Activities',
        transitions: 'Transitions',
        durationHours: 'Duration (hours)',
        numberOfCases: 'Number of cases ',
        numberOfCasesDuration: 'Number of cases per duration',
        caseDuration: 'Case duration histogram',

        fequentActivities: 'Most frequent activities',
        fequentAlternatives: 'Most frequent process alternatives',

        alternatives: 'alternatives',
        avg: 'avg. cycle time',

        averageDuration: 'Average duration of activities',
        standardDeviation: 'Standard deviation of activities',

        averageDurationAlertnatives: 'Average duration of process alternatives',
        standardDeviationAlertnatives: 'Standard deviation of process alternatives',

        averageTransition: 'Average transition times',
        transitionDeviations: 'Transition time deviations',
    },

    query: {
        conformance: 'Conformance summary',
        duration: 'Duration information',
        traces: 'traces',
        averageDuration: 'Avg duration',
        nonCompliantTracesPercentage: 'Non compliant',
        compliantTracesPercentage: 'Compliant',
        complianceIndicator: 'Compliance indicator: {complianceIndicator}',
        casesQt: 'Number of cases: {casesQt}',
        insightResults: 'Insight Results',
        insightModel: 'Insight model',
        insightConstraints: 'Insight constraints',
        casesAnalyzed: 'Cases analyzed',
        averageFitness: 'Average trace fitness value',
    },

    notifications: {
        download: {
            text: 'Report downloaded successfully.',
        },
    },
}
