<template>
    <div class="Tabs">
        <div class="Container">
            <div
                class="Tabs"
                :class="{ horizontal }"
            >
                <button
                    v-for="(tab, index) in tabs"
                    :key="`tab-${index}`"
                    class="Tab"
                    :class="[type, { active: value === tab.id, disabled: tab.disabled, horizontal }]"
                    :disabled="tab.disabled"
                    @click="handleClickItem(tab)"
                >
                    <WIcon
                        v-if="tab.icon"
                        :icon="tab.icon"
                        :size="24"
                        class="mr-1"
                    />
                    <span>{{ tab.label }}</span>
                    <Badge
                        v-if="tab.badge"
                        class="Badge"
                        :color="tab.badge.color"
                        :label="tab.badge.label"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VueTypes from 'vue-types'
import Badge from '../Badge/Badge.vue'

export default {
    name: 'Tabs2',
    components: { Badge },
    props: {
        value: VueTypes.string,
        tabs: VueTypes.arrayOf([
            VueTypes.shape({
                id: VueTypes.string,
                icon: VueTypes.string,
                label: VueTypes.string,
                disabled: VueTypes.bool,
            }).loose,
        ]).isRequired,
        type: VueTypes.oneOf(['', 'secondary']).def(''),
        horizontal: VueTypes.bool.def(false),
    },
    methods: {
        handleClickItem (tab) {
            if (!tab.disabled) {
                this.$emit('input', tab.id)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.Tabs {
    width: 100%;
    transition: $transition;

    &.horizontal {
        display: flex;
        height: 35px;
    }
}

.Container {
    padding: 15px 0;
}

.Badge {
  margin-left: 10px;
}

.Tab {
    position: relative;
    color: $primary;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 40px;
    height: 100%;
    padding: 20px 22px;
    background: transparent;
    border: 0;
    outline: none;
    font-size: 10px;
    font-weight: $font-weight-semibold;
    opacity: 0.5;
    transition: $transition;
    cursor: pointer;

    @media screen and (min-width: $md) {
        font-size: 12px;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 0px;
        background: $primary;
        border-radius: $border-radius;
        transition: all $transition;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
        opacity: 1;
        &:after {
            height: 100%;
        }
    }

    &.disabled {
        cursor: not-allowed;
    }

    &:hover {
        background: rgba($primary, 0.1);
    }

    .WIcon {
        color: $primary;
    }

    span {
        display: none;

        @media screen and (min-width: $md) {
            display: inline-block;
        }
    }

    &.secondary {
        color: $white;

        .WIcon {
            color: $white;
        }
    }

    &.horizontal {
        justify-content: center;

        &:after {
            top: initial;
            bottom: 0;
            width: 0px;
            height: 3px;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
            &:after {
                width: 100%;
            }
        }
    }
}
</style>
