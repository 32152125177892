export default {
    currentEventConsumption: 0,
    maxAllowedEvents: 10000,
    histogram: [
        { date: '2020-07-09T10:58:19', measurement: 3400 },
        { date: '2020-07-09T10:58:19', measurement: 4400 },
        { date: '2020-07-09T10:58:19', measurement: 5500 },
        { date: '2020-07-09T10:58:19', measurement: 5700 },
        { date: '2020-07-09T10:58:19', measurement: 5600 },
        { date: '2020-07-09T10:58:19', measurement: 6100 },
        { date: '2020-07-09T10:58:19', measurement: 5800 },
        { date: '2020-07-09T10:58:19', measurement: 6300 },
        { date: '2020-07-09T10:58:19', measurement: 6000 },
        { date: '2020-07-09T10:58:19', measurement: 6600 },
        { date: '2020-07-09T10:58:19', measurement: 1600 },
        { date: '2020-07-09T10:58:19', measurement: 3600 },
    ],
}
