<template>
    <div class="WIconSwitch">
        <WIcon
            v-for="(option, index) in options"
            :key="index"
            :icon="option.icon"
            :size="16"
            :color="value === option.value ? 'white' : 'black'"
            :class="{ 'selected': value === option.value }"
            @click="value !== option.value ? $emit('input', option.value) : ''"
        />
    </div>
</template>

<script>
import VueTypes from 'vue-types'

export default {
    name: 'WIconSwitch',
    props: {
        options: VueTypes.array,
        value: VueTypes.string.isRequired,
    },
}
</script>

<style scoped lang="scss">
.WIconSwitch {
	width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background: $gray-light;
  border-radius: 12px;
  overflow: hidden;
  .WIcon {
    padding: 12px;
    margin: 0;
    border-radius: 8px;
    cursor: pointer;
    background: $gray-light;

    &.selected {
      background: $primary;
      cursor: not-allowed;
    }
  }
}
</style>
