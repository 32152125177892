import Vue from 'vue/dist/vue'
import VueTypes from 'vue-types'
import Utils from '../utils'

const styles = {
    container: {
        display: 'inline-block',
        'white-space': 'nowrap',
    },
    dot: {
        width: '22px',
        height: '22px',
        border: '2px solid #FFF',
        borderRadius: '50%',
    },
}

export default Vue.extend({
    name: 'Activity',
    props: {
        id: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
        name: VueTypes.string,
        statistics: VueTypes.any,
        model: VueTypes.shape({
            values: VueTypes.any,
        }).loose,
    },
    computed: {
        modelType () {
            return (this.model || {}).values || Utils.graphValues.BOTH
        },
        heatmapType () {
            return (this.model || {}).heatMapValue || Utils.heatmapValues.NONE
        },
        isFeatured () {
            return this.heatmapType === Utils.heatmapValues.FREQ
        },
        value () {
            return Utils.getInfoModel(this.statistics, this.model)
        },
        colors () {
            return Utils.getHeatMapColors(this.statistics?.heatMap)
        },
        color () {
            return this.colors[this.modelType !== Utils.graphValues.BOTH ? this.modelType : Utils.graphValues.FREQ]
        },
        dataSets () {
            return Object.keys(this.colors || []).reduce(
                (acc, key) => ({
                    ...acc,
                    [`data-${key}`]: this.colors[key],
                }),
                {},
            )
        },
        dotStyle () {
            return {
                ...this.styles.dot,
                background: this.color,
            }
        },
    },
    beforeMount () {
        this.styles = styles
    },
    template: `
        <div :id="id" ref="content" class="Activity node-activity" :class="{ featured: isFeatured }" :style="styles.container" v-bind="dataSets">
            <div class="flex no-wrap row-left-center">
                <div class="Dot mr-0-5" :style="dotStyle" />
                <div class="Wrapper">
                    <div class="node-title mr-0-5">{{ name }}</div>
                    <div v-if="statistics && value" class="node-value" :class="['node-' + modelType]">
                        <span :class="[ 'span-' + modelType ]" v-html="value"></span>
                    </div>
                </div>
            </div>
        </div>
    `,
})
