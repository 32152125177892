<template>
    <div class="StepDataset flex flex-center">
        <Table
            ref="table"
            rowKey="uuid"
            class="Table"
            :columns="cols"
            :data="filteredDatasets"
            :total="total"
            :hideHeader="hideHeader"
            :loading="isFetching"
            @table-request="fetchData"
        >
            <template #content="{ row }">
                <QTr
                    :class="{ selected: (value || {}).datasetId === row.uuid }"
                    @click="handleClickItem(row)"
                >
                    <QTd
                        key="name"
                        class="td-name"
                    >
                        <WText
                            :size="14"
                            weight="semi"
                        >
                            {{ row.name }}
                        </WText>
                        <WText
                            :size="12"
                            color="gray02"
                        >
                            {{ $t('general.createdAt') }} {{ row.createDate|date }}
                        </WText>
                    </QTd>
                    <QTd
                        key="filename"
                    >
                        <WText
                            v-if="row.uploaded"
                            ellipsis
                            class="ellipsis"
                        >
                            {{ row.fileName }}

                            <QTooltip contentClass="bg-secondary">
                                {{ row.fileName }}
                            </QTooltip>
                        </WText>
                    </QTd>
                    <QTd
                        key="description"
                        class="td-desc"
                    >
                        <span class="ellipsis">{{ row.description || '-' }}</span>
                        <QTooltip
                            v-if="row.description"
                            contentClass="bg-secondary"
                            maxWidth="300px"
                        >
                            {{ row.description }}
                        </QTooltip>
                    </QTd>
                    <QTd
                        key="tst"
                        class="td-tst"
                    >
                        <span v-if="row.tst">{{ row.tst|date }}</span>
                        <span v-else>-</span>
                    </QTd>
                </QTr>
            </template>

            <template #empty>
                <EmptyList
                    v-bind="$t(`projects.datasets.notifications.empty`)"
                    class="mt-2 mb-2"
                >
                    <template #footer>
                        <WButton
                            class="mt-1"
                            iconLeft="plus-circle"
                            @click="handleCreateItem"
                        >
                            {{ $t('projects.datasets.new') }}
                        </WButton>
                    </template>
                </EmptyList>
            </template>
        </Table>
    </div>
</template>
<script>
import VueTypes from 'vue-types'
import {
    Api, apiRequest, notifyError,
} from '@/api'
import { Table, EmptyList } from '@/components'
import { filtersMixin } from '@/mixins'

export default {
    name: 'StepDataset',
    components: {
        Table,
        EmptyList,
    },
    mixins: [filtersMixin],
    props: {
        projectId: VueTypes.string.isRequired,
        value: VueTypes.shape({
            datasetId: VueTypes.any,
        }).loose,
        steps: VueTypes.any,
    },
    data () {
        return {
            datasets: undefined,
            total: 0,
            isFetching: false,
        }
    },
    computed: {
        cols () {
            return [
                {
                    name: 'name',
                    label: this.$t('general.name'),
                    align: 'left',
                },
                {
                    name: 'file',
                    label: this.$t('general.file'),
                    align: 'left',
                    width: 100,
                },
                {
                    name: 'description',
                    label: this.$t('general.description'),
                    align: 'left',
                },
                {
                    name: 'updateDate',
                    label: this.$t('general.updateDate'),
                    align: 'left',
                },
            ]
        },
        filteredDatasets () {
            return (this.datasets || []).filter(dataset => dataset.uploaded && dataset.state.identifier === 'FINISHED')
        },
        params () {
            const { table } = this.$refs
            const pagination = table ? table.getPagination() : {}
            return { ...pagination }
        },
        hideHeader () {
            return !(this.datasets || []).length
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        isValid () {
            return !!this.value?.datasetId
        },
        handleCreateItem () {
            const { organizationId } = this.$route.params || {}
            this.$router.push({ name: 'Project', params: { organizationId, projectId: this.projectId } })
        },
        handleClickItem (row) {
            this.$emit('input', {
                ...this.value,
                datasetId: this.value?.datasetId !== row.uuid ? row.uuid : undefined,
            })
        },
        async fetchData () {
            const { page, rowsPerPage } = this.params
            const params = {
                projectUid: this.projectId,
                ...(rowsPerPage ? { start: (page - 1) * rowsPerPage } : {}),
                ...(rowsPerPage ? { limit: rowsPerPage } : {}),
            }

            if (!(this.datasets || []).length) this.isFetching = true
            apiRequest(Api().datasets.list(params))
                .then(({ data, total }) => {
                    this.datasets = data
                    this.total = total
                })
                .catch(notifyError)
                .finally(() => (this.isFetching = false))
        },
    },
}
</script>
<style scoped lang="scss">
.Table {
    position: relative;
    min-height: 165px;

    .q-tr {
        cursor: pointer;
    }
    .q-tr.selected {
        background: rgba($primary, 0.1)
    }
    tbody td:after {
        background: transparent;
    }
}
</style>
